import AccountSetupScreen from "../../components/account-setup-screen/account-setup-screen";
import BaseCardLayout from "../../components/base-card-layout/base-card-layout";
import { LanguageSelectorUserMenu } from "../../components/language-selector-user-menu/language-selector-user-menu";
import { useTranslation } from 'react-i18next';

const AccountSetup: React.FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="user-menu">
                <LanguageSelectorUserMenu userPortal={false} />
            </div>

            <BaseCardLayout>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "3rem"
                }}>
                    <h4><strong>{t('buttons.btn_getStarted')}</strong></h4>
                </div>
                <AccountSetupScreen />
            </BaseCardLayout>
        </>
    );
};

export default AccountSetup;
