/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import DataExportUtil from "../../utilities/data-export-util";
import UserService from '../../utilities/services/user-service';
import OrganizationService from '../../utilities/services/organization-service';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import { Button } from '../../components/button/button';
import { useTranslation } from 'react-i18next';

const ExportPage: React.FC = () => {
    const { state } = useAuthState();

    const [hubspotPortalUsersCsv, setHubspotPortalUsersCsv] = useState<string>();
    const [hubspotAppUsersCsv, setHubspotAppUsersCsv] = useState<string>();
    const [generatingHubspotUsersCsv, setGeneratingHubspotUsersCsv] = useState<boolean>(false);
    const { t } = useTranslation();

    const handleGenerateHubspotUsersClick = async () => {
        setGeneratingHubspotUsersCsv(true);
        const users = await UserService.getAll();
        const orgs = await OrganizationService.getAll();

        const portalRows = DataExportUtil.getHubspotUserDataRows(users, orgs, true);
        const portalCsv = DataExportUtil.generateHubspotUserCsv(portalRows);
        const portalBlob = new Blob([portalCsv], { type: 'text/csv;charset=utf-9,' });
        const portalCsvUrl = URL.createObjectURL(portalBlob);
        setHubspotPortalUsersCsv(portalCsvUrl);

        const appRows = DataExportUtil.getHubspotUserDataRows(users, orgs, false);
        const appCsv = DataExportUtil.generateHubspotUserCsv(appRows);
        const appBlob = new Blob([appCsv], { type: 'text/csv;charset=utf-9,' });
        const appCsvUrl = URL.createObjectURL(appBlob);
        setHubspotAppUsersCsv(appCsvUrl);


        setGeneratingHubspotUsersCsv(false);
    };

    // const handleHasRole = async () => {
    //     const users = UserService.getAll().then((x) => {
    //         x.forEach(y => {
    //             y.symmioAccess = "Web Assessment Only";
    //             UserService.save(y);
    //         })
    //     })
    //     // // const user = UserService.getBy([
    //     // //     {
    //     // //         field: "email",
    //     // //         operator: "==",
    //     // //         value: "ytseby97+testcc6@gmail.com",
    //     // //     },
    //     // // ]).then((x) => {
    //     // //     x.forEach(y => {
    //     // //         if (!y.roles) {
    //     // //             y.roles = ["client"]
    //     // //             UserService.save(y);
    //     // //         }
    //     // //         else if (y.roles && y.roles.length === 0) {
    //     // //         }
    //     // //         else if (y.roles[0] === null) {
    //     // //             y.roles = ["client"]
    //     // //             UserService.save(y);
    //     // //         }
    //     // //         else {
    //     // //         }
    //     // //     });
    //     // // })
    //     // const users = UserService.getAll().then((x) => {
    //     //     x.forEach(y => {
    //     //         if (!y.roles) {
    //     //             // y.roles = ["client"]
    //     //             // UserService.save(y);
    //     //         }
    //     //         else if (y.roles && y.roles.length === 0) {
    //     //             // y.roles = ["client"]
    //     //             // UserService.save(y);
    //     //         }
    //     //         else if (y.roles[0] === null) {
    //     //             // y.roles = ["client"]
    //     //             // UserService.save(y);
    //     //         }
    //     //         // else {
    //     //         // }
    //     //     });
    //     // })
    // }
    if (!state.user?.isSuperAdmin) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <div>
                <h3>{t('pages.dataExport.title')}</h3>
                <Button
                    onClick={handleGenerateHubspotUsersClick}
                    disabled={generatingHubspotUsersCsv}
                    buttonText={t('buttons.btn_generate')} />
                <div
                    style={{
                        display: 'flex',
                        flexFlow: 'wrap',
                        gap: '1.5rem',
                        maxWidth: '100%',
                    }}>
                    {
                        (hubspotPortalUsersCsv) &&
                        <a
                            style={{
                                background: 'rgb(18, 22, 34)',
                                borderRadius: '900px',
                                padding: '6px 20px',
                                color: 'white',
                            }}
                            href={hubspotPortalUsersCsv}
                            download={'hubspotPortalUsersData.csv'}
                        >
                            {t('pages.dataExport.downloadPortalUsers')}
                        </a>
                    }
                    {
                        (hubspotAppUsersCsv) &&
                        <a
                            style={{
                                background: 'rgb(18, 22, 34)',
                                borderRadius: '900px',
                                padding: '6px 20px',
                                color: 'white',
                            }}
                            href={hubspotAppUsersCsv}
                            download={'hubspotAppUsersData.csv'}
                        >
                            {t('pages.dataExport.downloadAppUsers')}
                        </a>
                    }
                </div>
            </div >
        </>
    );
}

export default ExportPage;
