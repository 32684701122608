import { ReportDetailData } from "../../models/interfaces/reports/report-detail-data";
import GroupUtil from "../../utilities/group-util";
import { Utils } from "../../utilities/utils";
import ReportGenericHeader from "./report-generic-header";
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "c-report";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportDetailProps {
    data: ReportDetailData;
}

// #endregion Intefaces


// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportDetail: React.FC<ReportDetailProps> = (props: ReportDetailProps) => {
    const { data } = props;
    const { t, i18n } = useTranslation();

    const formatter = new Intl.ListFormat(i18n.language, {
        style: 'long',
        type: 'unit',
    });

    data.users.sort(function compareFn(a, b): number {
        if (a?.name == null) {
            return -1;
        }
        if (b?.name == null) {
            return 1;
        }
        const nameA = a.name.toLocaleLowerCase();
        const nameB = b.name.toLocaleLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    return (
        <div className={`${COMPONENT_CLASS} -detail`}>
            <ReportGenericHeader companyName={data.organizationName} reportName={t('reports.detail.title')} />
            <div className="flex gap-6 pt-6 pb-6 px-10 sub-header" style={{ backgroundColor: "#F7F9FC" }}>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>{t('reports.usersInReport')}</p>
                    <p>{data.totalUsers}</p>
                </div>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>{t('reports.groups')}</p>
                    {data.groupNames && data.groupNames.length > 0 ?
                        <p>{data.groupNames.join(", ")}</p> :
                        <p>{data.organizationName}</p>
                    }
                </div>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>{t('reports.tags')}</p>
                    {data.tagNames && data.tagNames.length > 0 &&
                        <p>{data.tagNames.join(", ")}</p>
                    }
                </div>
                <div className="w-1/4">
                    <p style={{ fontWeight: "500" }}>{t('reports.filters')}</p>
                    {data.filterNames && data.filterNames.length > 0 &&
                        <p>{data.filterNames.join(", ")}</p>
                    }
                </div>
            </div>
            <div className={`${COMPONENT_CLASS}__section`}>
                <table className={`${COMPONENT_CLASS}__table`}>
                    <thead>
                        <tr>
                            <td>{t('reports.detail.table.name')}</td>
                            <td className="center">{t('reports.detail.table.age')}</td>
                            <td>{t('reports.detail.table.group')}</td>
                            <td className="center">{t('reports.detail.table.score')}</td>
                            <td>{t('reports.detail.table.msk')}</td>
                            <td className="center">{t('reports.detail.table.pain')}</td>
                            <td>{t('reports.detail.table.focus')}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.users.map((user, index) => (
                            <tr
                                className={`${COMPONENT_CLASS}__table__row ${user.inPain ? "-warning" : ""}`}
                                key={index}>
                                <td>{user.name}</td>
                                <td className="center">{Utils.getAge(user.dob)}</td>
                                <td>{user.groupName}</td>
                                <td className="center">{user.mskScore}</td>
                                <td>{t(Utils.getHealthRiskTranslationReference(user.healthCategory))}</td>
                                <td className="center">{user.inPain ? t('reports.detail.table.inPain.yes') : t('reports.detail.table.inPain.no')}</td>
                                {user.focusAreas && user.focusAreas.length > 0 &&
                                    <td>{formatter.format(user.focusAreas?.map(fa => fa = t(GroupUtil.getNameTranslationReference(fa))))}</td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}


export default ReportDetail;
