/* eslint-disable no-useless-escape */
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { useAuthState } from "../../../utilities/contexts/auth-state-context";
import UserService from '../../../utilities/services/user-service';
import { enqueueSnackbar } from "notistack";
import { getAuth, updateEmail, reauthenticateWithCredential, EmailAuthProvider } from "@firebase/auth";
import { User } from '../../../models/interfaces/user';
import { TextTypeInput } from '../../forms';
import { EmailTypeInput } from '../../forms/email-type-input';
import { Button } from '../../button/button';
import { useTranslation } from 'react-i18next';

interface EditAccountProps {
  onClose: any,
}
const COMPONENT_CLASS = "c-subscriptions";
const FORM_COMPONENT_CLASS = "p-link-create-account";

const EditAccount: React.FC<EditAccountProps> = (props: EditAccountProps) => {
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const { state, setState } = useAuthState();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [firstName, setFirstName] = useState(state.user?.firstName);
  const [lastName, setLastName] = useState(state.user?.lastName);
  const [email, setEmail] = useState(state.user?.email);
  const auth = getAuth();
  const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
  const { t } = useTranslation();

  const handleError = (error: any) => {
    if (error?.code == null) {
      setError("formErrors", {
        type: "custom",
        message: t('forms.authErrors.custom')
      });
    }

    switch (error.code) {
      case "auth/internal-error":
        setError("formErrors", {
          type: "custom",
          message: t('forms.authErrors.internal')
        });
        break;
      case "auth/wrong-password":
        setError("formErrors", {
          type: "custom",
          message: t('forms.authErrors.wrongPassword')
        });
        break;
      case "auth/email-already-in-use":
        setError("formErrors", {
          type: "custom",
          message: t('settings.account.error.alreadyInUse')
        });
        break;
      case "auth/too-many-requests":
        setError("formErrors", {
          type: "custom",
          message: t('settings.account.error.tooMany')
        });
        break;
      case "auth/requires-recent-login":
        setError("formErrors", {
          type: "custom",
          message: t('settings.account.error.loginAgain')
        });
        break;
      default:
        setError("formErrors", {
          type: "custom",
          message: t('forms.authErrors.custom')
        });
        break;
    }
  }

  const handleEmailCheck = (value: any) => {
    (value !== null && value !== state.user?.email) ? setShowPassword(true) : setShowPassword(false);
    setEmail(value);
  }

  const onSubmit = async (data: any) => {
    if (!auth.currentUser || !auth.currentUser.email) return;
    let submitting = true;
    if (data && state.user && state.user.id) {
      const user = await UserService.get(state.user.id);
      const stateUser = state.user;
      if (user) {
        if (data.firstName !== state.user.firstName) { user.firstName = data.firstName; stateUser.firstName = data.firstName; }
        if (data.lastName !== state.user.lastName) { user.lastName = data.lastName; stateUser.lastName = data.lastName; }
        if (data.email !== state.user.email) {
          user.email = data.email;
          const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            data.password
          );
          await reauthenticateWithCredential(auth.currentUser, credential).then(async () => {
            if (!auth.currentUser || !auth.currentUser.email) return;
            await updateEmail(auth.currentUser, data.email).then(() => {
              stateUser.email = data.email;
              updateUser(user, stateUser);
              submitting = false;
            }).catch((error: any) => {
              submitting = false;
              handleError(error);
              return;
            });

          }).catch((error: any) => {
            submitting = false;
            handleError(error);
            return;
          });
        }
        if (submitting) {
          if (user.email !== state.user?.email) {
            const customer = await stripe.customers.list({
              limit: 1,
              email: `${state.user?.email}`,
            })
            if (customer.data.length > 0) {
              await stripe.customers.update(customer.data[0].id,
                {
                  email: `${user.email}`
                }
              );
            }
          }
          updateUser(user, stateUser);
        }
      } else {
        setError("formErrors", {
          type: "custom",
          message: t('forms.authErrors.internal')
        });
      }
    }
  }

  const updateUser = async (user: User, stateUser: any) => {
    await UserService.update(user, state.user).then(() => {
      if (stateUser) {
        setState((state) => ({
          ...state, ...{ user: stateUser }
        }));
      }
      reset();
      enqueueSnackbar(t('settings.infoUpdated'), { variant: "toast", width: "450px", autoHideDuration: 5000 });
      props.onClose();
    }).catch((error: any) => {
      handleError(error);
      return;
    });
  }

  return (
    <div className={`${COMPONENT_CLASS}`}>
      <div className={`${COMPONENT_CLASS}__wrapper`}>
        <div style={{ width: '90%', marginTop: '2rem' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${FORM_COMPONENT_CLASS}__field`}>
              {errors.firstName && (
                <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                  {t('forms.firstName_val')}
                </div>
              )}
              <TextTypeInput
                id='firstName'
                label={t('forms.firstName')}
                style={{
                  marginLeft: '0',
                  width: '90%',
                  marginBottom: '1.25rem'
                }}
                hideLabel={true}
                registerHook={register}
                registerOptions={{
                  value: firstName,
                  required: true,
                  onChange: (e) => setFirstName(e.target.value),
                }}
                errorState={errors.firstName} />
            </div>
            <div className={`${FORM_COMPONENT_CLASS}__field`}>
              {errors.lastName && (
                <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                  {t('forms.lastName_val')}
                </div>
              )}
              <TextTypeInput
                id='lastName'
                label={t('forms.lastName')}
                style={{
                  marginLeft: '0',
                  width: '90%',
                  marginBottom: '1.25rem'
                }}
                hideLabel={true}
                registerHook={register}
                registerOptions={{
                  value: lastName,
                  required: true,
                  onChange: (e) => setLastName(e.target.value),
                }}
                errorState={errors.lastName} />
            </div>
            <div className={`${FORM_COMPONENT_CLASS}__field`}>
              <EmailTypeInput
                id='email'
                label={t('forms.email')}
                hideLabel={true}
                style={{
                  marginLeft: '0',
                  width: '90%',
                  marginBottom: '1.25rem'
                }}
                registerHook={register}
                registerOptions={{
                  onChange: (e) => handleEmailCheck(e.target.value),
                  value: email,
                }}
                errorState={errors.email}
              />
            </div>
            {showPassword && (
              <div className={`${FORM_COMPONENT_CLASS}__field`}>
                {errors.password && (
                  <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                    {t('settings.account.enterCurrentPassword')}
                  </div>
                )}
                <TextTypeInput
                  id='password'
                  type='password'
                  label={t('settings.account.currentPassword')}
                  style={{
                    marginLeft: '0',
                    width: '90%',
                    marginBottom: '1.25rem'
                  }}
                  hideLabel={true}
                  registerHook={register}
                  registerOptions={{
                    required: true
                  }}
                  errorState={errors.password}
                />
              </div>
            )}
            <div {...register("formErrors")}>
              {errors.formErrors &&
                <span className="error-style" role="alert">
                  {`${errors.formErrors.message}`}
                </span>
              }
            </div>
          </form>
        </div>
        <div className={'c-login-modal__button-area'} style={{ gap: "50px" }}>
          <Button
            type="link"
            onClick={props.onClose}
            buttonText={t('buttons.btn_cancel')}
          />

          <Button
            buttonStyle='primary'
            onClick={handleSubmit(onSubmit)}
            buttonText={t('buttons.btn_saveChanges')} />
        </div>
      </div>
    </div >
  );
}

export default EditAccount;
