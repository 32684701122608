
import "./leadlinks-list.styles.scss";
import { QRCodeSVG } from "qrcode.react";
import { LeadLink } from "../../models/interfaces/lead-link";
import { Deeplink } from "../../models/interfaces/deeplink";
import {
  CheckCircleIcon,
  ClipboardIcon,
  DownloadIcon,
} from '@heroicons/react/outline';
import { useState } from "react";
import { elementToImage } from '../../utilities/element-to-image';
import { UserTag } from "../../models/interfaces/user-tag";
import { Button } from "../button/button";
import { useTranslation } from 'react-i18next';

interface LinkInfoProps {
  linkData: any;
}
const LinkInfo: React.FC<LinkInfoProps> = (props) => {
  const { linkData } = props;
  const [copiedLinkId, setCopiedLinkId] = useState('');
  const [downloadStarted, setDownloadStarted] = useState(false);
  const { t } = useTranslation();

  const handleLinkCopy = (deeplink: Deeplink | LeadLink | undefined) => {
    if (!deeplink?.url || !deeplink?.id) {
      console.error('Copy link failed. No id or URL available');
      return;
    }
    // TODO : Global exception handling
    // https://app.clickup.com/t/2219993/FMS-1236

    navigator.clipboard.writeText(deeplink.url);
    setCopiedLinkId(deeplink.id);
    setTimeout(() => {
      setCopiedLinkId('');
    }, 2000);
  }
  const COMPONENT_CLASS = "c-link-info";

  const handleDownloadClick = async (elementId: string, deeplink: Deeplink | LeadLink | undefined) => {

    if (deeplink == null) {
      console.error('deeplink is null');
      return;
    }
    // TODO : Global exception handling
    // https://app.clickup.com/t/2219993/FMS-1236

    const fileName = `Symmio QR Code-${deeplink?.name}`;
    setDownloadStarted(true);
    elementToImage(elementId, fileName);
    setTimeout(() => {
      setDownloadStarted(false);
    }, 2000);
  }

  return (
    <div className={`${COMPONENT_CLASS}__container`}>
      <div className={`${COMPONENT_CLASS}__qr-container`}>
        {linkData?.url ?
          <div id='qr-code'>
            <QRCodeSVG value={linkData?.url} size={150} />
          </div>
          :
          <div>{t('leadlinks.qrCodeUnavailable')}</div>
        }

        <span onClick={() => { handleDownloadClick('qr-code', linkData) }}>
          {downloadStarted ?
            <Button
              customIcon={<CheckCircleIcon className="h-5 w-5 mr-2" />}
              buttonText={t('buttons.btn_downloadStarted')}
              buttonStyle="secondary" />
            :
            <Button
              customIcon={<DownloadIcon className="h-5 w-5" />}
              buttonText={t('buttons.btn_download')}
              buttonStyle="secondary" />
          }
        </span>
      </div>
      <div className={`${COMPONENT_CLASS}__text-container`} >
        <div className={`${COMPONENT_CLASS}__title`}>{linkData?.name}</div>
        <div className={`${COMPONENT_CLASS}__info`}>
          <p style={{ fontWeight: 'bold' }}>{t('deeplinks.edit.url')}</p>
          <div className={`${COMPONENT_CLASS}__url-container`}>
            <p>{linkData.url}</p>
            <span onClick={() => { handleLinkCopy(linkData) }}>
              {(copiedLinkId === linkData?.id) ?
                <Button
                  customIcon={<CheckCircleIcon className="h-5 w-5" />}
                  buttonText={t('buttons.btn_linkCopied')}
                  buttonStyle="secondary" />
                :
                <Button
                  customIcon={<ClipboardIcon className="h-5 w-5" />}
                  buttonText={t('buttons.btn_copyLink')}
                  buttonStyle="secondary" />

              }
            </span>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '20%' }}>
          <div className={`${COMPONENT_CLASS}__info`}>
            <div>
              <p style={{ fontWeight: 'bold' }}>{t('deeplinks.list.group')}</p>
              <div>{(linkData?.group && linkData?.group.label) ? (linkData.group.label) :
                (linkData?.groupName ? linkData.groupName : "")}</div>
            </div>
          </div>
          <div className={`${COMPONENT_CLASS}__info`}>
            <div>
              <p style={{ fontWeight: 'bold' }}>{t('deeplinks.list.tags')}</p>
              {linkData.tags?.map((tag: UserTag) => tag.name).join(',')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LinkInfo;