/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import AssessmentResponse from "../../models/interfaces/assessment-response.entity";
import MskScoreService from "../../utilities/services/msk-score-service";
import moment from "moment";
import { GroupIds } from "../../models/enumerations/group-ids";
import { QuestionIds } from "../../models/enumerations/question-ids";
import UserProfileAssessmentHeader from "./user-profile-assessment-header";
import NonLinearLineChart from "../charts/non-linear-line-chart";
import LineChartJS from "../charts/line-chart-js";
import RiskChartVertical from "../charts/risk-chart-vertical";
import { useTranslation } from "react-i18next";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfilePhysicalActivityProps {
    mskScore?: MskScore;
    user: User;
    assessment?: any;
    assessmentResponses: AssessmentResponse[];
    allMskScores: MskScore[];
    setAllMskScores: Function;
    setSpecificAssessment: Function;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfilePhysicalActivity: React.FC<UserProfilePhysicalActivityProps> = (props: UserProfilePhysicalActivityProps) => {
    const { setSpecificAssessment, mskScore, assessment, allMskScores, setAllMskScores, user, assessmentResponses } = props;
    const [physicalActivityHistory, setPhysicalActivityHistory] = useState<{ x: string, y: number }[]>();
    const [moderateActivityHistory, setModerateActivityHistory] = useState<{ x: string, y: number }[]>();
    const [vigorousActivityHistory, setVigorousActivityHistory] = useState<{ x: string, y: number }[]>();
    const [muscleStrengthTrainingHistory, setMuscleStrengthTrainingHistory] = useState<{ x: string, y: number }[]>();
    const [customMuscleStrengthTrainingHistory, setCustomMuscleStrengthTrainingHistory] = useState<{ x: string, y: number }[]>();
    const { t } = useTranslation();

    useEffect(() => {
        const getAllMskScores = async () => {
            if (!allMskScores) {
                const mskScores = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }])

                setAllMskScores(mskScores);
            }
        }
        getAllMskScores();
    }, [])
    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempPhysicalActivityHistory = []
                let tempModerateActivityHistory = []
                let tempVigorousActivityHistory = []
                let tempMuscleStrengthTrainingHistory = []
                let tempCustomMuscleStrengthTrainingHistoryLabels = []

                for (const mskScore of allMskScores) {
                    tempPhysicalActivityHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.lifestyleScore?.physicalActivityScore?.percentage ?? 0
                    })
                    if (mskScore.lifestyleScore?.physicalActivityScore?.moderateActivity || mskScore.lifestyleScore?.physicalActivityScore?.moderateActivity === 0) {
                        tempModerateActivityHistory.push({
                            x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                            y: mskScore.lifestyleScore?.physicalActivityScore?.moderateActivity
                        })
                    }
                    if (mskScore.lifestyleScore?.physicalActivityScore?.vigorousActivity || mskScore.lifestyleScore?.physicalActivityScore?.vigorousActivity === 0)
                        tempVigorousActivityHistory.push({
                            x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                            y: mskScore.lifestyleScore?.physicalActivityScore?.vigorousActivity
                        })
                    if (mskScore.lifestyleScore?.physicalActivityScore?.muscleStrengthening || mskScore.lifestyleScore?.physicalActivityScore?.muscleStrengthening === 0) {
                        tempMuscleStrengthTrainingHistory.push({
                            x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                            y: mskScore.lifestyleScore?.physicalActivityScore?.muscleStrengthening === 0 ? 0.5 : mskScore.lifestyleScore?.physicalActivityScore?.muscleStrengthening === 1 ? 1.5 : mskScore.lifestyleScore?.physicalActivityScore?.muscleStrengthening ?? 0
                        })
                        tempCustomMuscleStrengthTrainingHistoryLabels.push({
                            x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                            y: mskScore.lifestyleScore?.physicalActivityScore?.muscleStrengthening
                        })
                    }
                }

                if (tempModerateActivityHistory.length === 0) {
                    const moderateActivity = getModerateActivityResponse();
                    if (moderateActivity) {
                        tempModerateActivityHistory.push(moderateActivity);
                    }
                }
                if (tempVigorousActivityHistory.length === 0) {
                    const vigorousActivity = getVigorousActivityResponse();
                    if (vigorousActivity) {
                        tempVigorousActivityHistory.push(vigorousActivity)
                    }
                }
                if (tempMuscleStrengthTrainingHistory.length === 0) {
                    const strengthTraining = getStrengthTrainingResponse();
                    if (strengthTraining) {
                        tempMuscleStrengthTrainingHistory.push(strengthTraining);
                    }
                }
                setPhysicalActivityHistory(tempPhysicalActivityHistory);
                setModerateActivityHistory(tempModerateActivityHistory);
                setVigorousActivityHistory(tempVigorousActivityHistory);
                setMuscleStrengthTrainingHistory(tempMuscleStrengthTrainingHistory);
                setCustomMuscleStrengthTrainingHistory(tempCustomMuscleStrengthTrainingHistoryLabels);
            }
        }
        getHistory();
    }, [allMskScores])

    const getStrengthTrainingResponse = () => {
        if (!assessmentResponses) {
            return;
        }
        const activityResponse: any = assessmentResponses.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.PHYSICAL_ACTIVITY)
        if (activityResponse.length > 0) {
            const strength = activityResponse && activityResponse.length > 0 ? activityResponse[0].responses.find((response: any) => response.questionId === QuestionIds.PHYSICAL_ACTIVITY_STRENGTH_NUM) : undefined
            if (strength) {
                return {
                    x: moment(activityResponse[0] && activityResponse[0].created?._seconds ? activityResponse[0].created._seconds * 1000 : NaN).format("MMM DD, YY"),
                    y: parseInt(strength.answerResponse) ?? 0
                }
            }
        }
    }

    const getModerateActivityResponse = () => {
        if (!assessmentResponses) {
            return;
        }
        const activityResponse: any = assessmentResponses.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.PHYSICAL_ACTIVITY)
        if (activityResponse.length > 0) {
            const moderate = activityResponse && activityResponse.length > 0 ? activityResponse[0].responses.find((response: any) => response.questionId === QuestionIds.PHYSICAL_ACTIVITY_MODERATE) : undefined
            if (moderate) {
                return {
                    x: moment(activityResponse[0] && activityResponse[0].created?._seconds ? activityResponse[0].created._seconds * 1000 : NaN).format("MMM DD, YY"),
                    y: parseInt(moderate.answerResponse) ?? 0
                }
            }
        }
    }

    const getVigorousActivityResponse = () => {
        if (!assessmentResponses) {
            return;
        }
        const activityResponse: any = assessmentResponses.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.PHYSICAL_ACTIVITY)
        if (activityResponse.length > 0) {
            const vigorous = activityResponse && activityResponse.length > 0 ? activityResponse[0].responses.find((response: any) => response.questionId === QuestionIds.PHYSICAL_ACTIVITY_VIGOROUS) : undefined
            if (vigorous) {
                return {
                    x: moment(activityResponse[0] && activityResponse[0].created?._seconds ? activityResponse[0].created._seconds * 1000 : NaN).format("MMM DD, YY"),
                    y: parseInt(vigorous.answerResponse) ?? 0
                }
            }
        }
    }

    return (
        <>
            <div>
                <UserProfileAssessmentHeader
                    assessment={assessment}
                    mskScore={mskScore}
                    setSpecificAssessment={setSpecificAssessment}
                    mainTextHeader={t('userProfile.assessments.activityHeader')}
                    mainText={t('userProfile.assessments.activityText')}
                    whyImportantText={t('userProfile.assessments.activityWhy')}
                />
            </div>

            {physicalActivityHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper my-5`}>
                    <LineChartJS
                        data={physicalActivityHistory.map(history => history.y)}
                        title={t('userProfile.assessments.activityHistory')}
                        labels={physicalActivityHistory.map(history => history.x)}
                        height={"200px"}
                        horizontalScrolling={true}
                        pointRadius={7}
                        pointBorderWidth={3}
                        borderWidth={5}
                        pointFontSize={16}
                        fontFamily={'pragmatica-extended'}
                        pointLabelOffset={0}
                        xLabelFontSize={14}
                        paddingTop={30}
                        paddingBottom={20}
                        maintainAspectRatio={false}
                    />
                </div>
            )}

            {moderateActivityHistory && moderateActivityHistory.length > 0 && (
                <div className="flex gap-5 mb-5">
                    <div className={`${COMPONENT_CLASS}__score-section-card min-w-72 max-w-72`}>
                        <h3 className="flex items-center justify-center mb-4">
                            {t('reports.wellness.coloredCells.moderateActivity.title')}
                        </h3>
                        <div className="w-full h-64">
                            <RiskChartVertical
                                score={moderateActivityHistory[moderateActivityHistory.length - 1].y}
                                breakpoints={[
                                    { min: 150, max: 250, label: t('userProfile.assessments.recommendedActivity'), bgColor: "#8BE3CE", subLabel: t('reports.wellness.coloredCells.moderateActivity.greenSub') },
                                    { min: 75, max: 149, label: t('userProfile.assessments.someActivity'), bgColor: "#FFC79C", subLabel: t('reports.wellness.coloredCells.moderateActivity.orangeSub') },
                                    { min: 0, max: 74, label: t('userProfile.assessments.littleActivity'), bgColor: "#FFA998", subLabel: t('reports.wellness.coloredCells.moderateActivity.redSub') },
                                ]}
                                decimals={false}
                            />
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                        <NonLinearLineChart
                            dataPoints={moderateActivityHistory.map(history => history.y)}
                            labels={moderateActivityHistory.map(history => history.x)}
                            title={t('userProfile.assessments.moderateHistory')}
                            sectionDetails={{
                                sections: 3,
                                rangeOne: { start: 0, end: 74.9 },
                                rangeTwo: { start: 75, end: 149.9 },
                                rangeThree: { start: 150, end: 300 },
                                backgroundColorOrder: { first: "#FFA998", second: "#FFE9CC", third: "#8BE3CE", },
                                dataPointColorOrder: { first: "#E7514F", second: "#FFC79C", third: "#00DFBC" },
                                yAxisLabels: { first: t('userProfile.assessments.little'), second: t('userProfile.assessments.some'), third: t('userProfile.assessments.recommended') },
                                decimals: false
                            }}
                            toolTipLabel={true}
                        />
                    </div>
                </div>
            )}
            {vigorousActivityHistory && vigorousActivityHistory.length > 0 && (
                <div className="flex gap-5 mb-5">
                    <div className={`${COMPONENT_CLASS}__score-section-card min-w-72 max-w-72`}>
                        <h3 className="flex items-center justify-center mb-4">
                            {t('reports.wellness.coloredCells.vigorousActivity.title')}
                        </h3>
                        <div className="w-full h-64">
                            <RiskChartVertical
                                score={vigorousActivityHistory[vigorousActivityHistory.length - 1].y}
                                breakpoints={[
                                    { min: 75, max: 250, label: t('userProfile.assessments.recommendedActivity'), bgColor: "#8BE3CE", subLabel: t('reports.wellness.coloredCells.vigorousActivity.greenSub') },
                                    { min: 50, max: 74, label: t('userProfile.assessments.someActivity'), bgColor: "#FFC79C", subLabel: t('reports.wellness.coloredCells.vigorousActivity.orangeSub') },
                                    { min: 0, max: 49, label: t('userProfile.assessments.littleActivity'), bgColor: "#FFA998", subLabel: t('reports.wellness.coloredCells.vigorousActivity.redSub') },
                                ]}
                                decimals={false}
                            />
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                        <NonLinearLineChart
                            dataPoints={vigorousActivityHistory.map(history => history.y)}
                            labels={vigorousActivityHistory.map(history => history.x)}
                            title={t('userProfile.assessments.vigorousHistory')}
                            sectionDetails={{
                                sections: 3,
                                rangeOne: { start: 0, end: 49.9 },
                                rangeTwo: { start: 50, end: 74.9 },
                                rangeThree: { start: 75, end: 150 },
                                backgroundColorOrder: { first: "#FFA998", second: "#FFE9CC", third: "#8BE3CE", },
                                dataPointColorOrder: { first: "#E7514F", second: "#FFC79C", third: "#00DFBC" },
                                yAxisLabels: { first: t('userProfile.assessments.little'), second: t('userProfile.assessments.some'), third: t('userProfile.assessments.recommended') },
                                decimals: false
                            }}
                            toolTipLabel={true}
                        />
                    </div>
                </div>

            )}
            {muscleStrengthTrainingHistory && muscleStrengthTrainingHistory.length > 0 && (
                <div className="flex gap-5">
                    <div className={`${COMPONENT_CLASS}__score-section-card w-72`}>
                        <h3 className="flex items-center justify-center mb-4">
                            {t('reports.wellness.coloredCells.strengthTraining.title')}
                        </h3>
                        <div className="w-full h-64">
                            <RiskChartVertical
                                score={muscleStrengthTrainingHistory[muscleStrengthTrainingHistory.length - 1].y}
                                breakpoints={[
                                    { min: 2, max: 7, label: t('userProfile.assessments.recommendedStrength'), bgColor: "#8BE3CE", subLabel: t('reports.wellness.coloredCells.strengthTraining.greenSub') },
                                    { min: 1, max: 1.9, label: t('userProfile.assessments.someStrength'), bgColor: "#FFC79C", subLabel: t('reports.wellness.coloredCells.strengthTraining.orangeSub') },
                                    { min: 0, max: 0.9, label: t('userProfile.assessments.noStrength'), bgColor: "#FFA998", subLabel: t('reports.wellness.coloredCells.strengthTraining.redSub') },
                                ]}
                                decimals={true}
                            />
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                        <NonLinearLineChart
                            dataPoints={muscleStrengthTrainingHistory.map(history => history.y)}
                            customDataPointLabels={customMuscleStrengthTrainingHistory ? customMuscleStrengthTrainingHistory.map(history => history.y) : undefined}
                            labels={muscleStrengthTrainingHistory.map(history => history.x)}
                            title={t('userProfile.assessments.strengthHistory')}
                            sectionDetails={{
                                sections: 3,
                                rangeOne: { start: 0, end: 0.9 },
                                rangeTwo: { start: 1, end: 1.9 },
                                rangeThree: { start: 2, end: 7 },
                                backgroundColorOrder: { first: "#FFA998", second: "#FFE9CC", third: "#8BE3CE", },
                                dataPointColorOrder: { first: "#E7514F", second: "#FFC79C", third: "#00DFBC" },
                                yAxisLabels: { first: t('userProfile.assessments.none'), second: t('userProfile.assessments.some'), third: t('userProfile.assessments.recommended') },
                                decimals: true
                            }}
                            toolTipLabel={true}
                        />
                    </div>
                </div>

            )}
        </>
    );
}

export default UserProfilePhysicalActivity;
