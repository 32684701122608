import { DateTime } from "luxon";
import { GroupIds } from "../models/enumerations/group-ids";
import DuplicateEntityFoundException from "../models/exceptions/duplicate-entity-found";
import EntityNotFoundException from "../models/exceptions/entity-not-found";
import { MskScore } from "../models/interfaces/scores/msk-score";
import { UserInfo } from "../models/interfaces/scores/user-info";
import { IUser } from "../models/interfaces/assessment-user";
import DataAssessmentsUtil from "./data-assesments-util";
import DateUtil from "./date-util";
import { FirebaseUtils } from "./firebase-utils";
import GroupUtil from "./group-util";
import UserService from "./services/user-service-assessments";
import { User } from "../models/interfaces/user";
import { BiologicalSexValue } from "../models/biological-sex";
const md5 = require("md5");

export default class UserUtilAssessments {
    /**
     * Find the users by the specific authentication Id
     * @param {string} authenticationId - The key authentication Id that is used for the user
     * @returns {Promise<IUser | null>} A promise for the specific user found by the authentication Id
     */
    public static async getByAuthenticationId(
        authenticationId: string
    ): Promise<IUser | null> {
        const users = await UserService.getBy(
            FirebaseUtils.createFirebaseConditions(
                "authenticationId",
                "==",
                authenticationId
            )
        );

        if (users.length === 1) {
            return users[0];
        }

        return null;
    }

    /**
     * Creates a user info object for the user
     * @param {string} userId - User identifier
     * @returns {Promise<UserInfo>} A promise for the user info
     */
    public static async getUserInfo(userId: string): Promise<UserInfo> {
        if (userId == null || userId.length === 0) {
            throw new Error("User ID was not specified to get user info");
        }

        const user = await UserService.get(userId);
        if (user == null) {
            return {};
        }

        const isFemale =
            user.biologicalSex === "Female"
                ? true
                : user.biologicalSex === "Male"
                    ? false
                    : undefined;

        let age = -1;
        if (user.birthdate != null) {
            // ceil to round negatives properly
            age = -Math.ceil(
                DateTime.fromISO(user.birthdate).diffNow("years").years
            );
        }

        return {
            userDeIdentifiedId: md5(userId),
            age: age,
            isFemale: isFemale,
        };
    }

    /**
     * Creates a user info object for the user
     * @param {string} userId - User identifier
     * @returns {Promise<UserInfo>} A promise for the user info
     */
    public static async getUserInfoForLinks(userInfo: User): Promise<UserInfo> {
        if (!userInfo) {
            throw new Error("User was not specified to get user info");
        }


        const isFemale =
            userInfo.biologicalSex === BiologicalSexValue.Female
                ? true
                : userInfo.biologicalSex === BiologicalSexValue.Male
                    ? false
                    : undefined;

        let age = -1;
        if (userInfo.birthdate != null) {
            // ceil to round negatives properly
            age = -Math.ceil(
                DateTime.fromISO(userInfo.birthdate).diffNow("years").years
            );
        }


        return {
            userDeIdentifiedId: md5(userInfo.id),
            age: age,
            isFemale: isFemale,
        };
    }

    /**
     * Gets single user matching the email
     * @param {string} email - Email to search
     * @returns {Promise<IUser>}
     * @throws {EntityNotFoundException} An entity with that email was not found
     * @throws {DuplicateEntityFoundException} More than one entity was found for this query
     */
    public static async getByEmail(email: string): Promise<IUser> {
        const users = await UserService.getBy(
            FirebaseUtils.createFirebaseConditions("email", "==", email)
        );

        if (users.length === 0) {
            throw new EntityNotFoundException(
                "An entity with that email was not found"
            );
        }

        if (users.length >= 2) {
            throw new DuplicateEntityFoundException();
        }

        return users[0];
    }

    // /**
    //  * Marks intro dismissed for the user
    //  * @param userId
    //  * @returns void
    //  */
    // public static async dismissIntro(userId: string): Promise<void> {
    //     const user = await UserService.get(userId);
    //     if (user == null) {
    //         return;
    //     }

    //     if (user.introDismissed !== true) {
    //         user.introDismissed = true;
    //         await UserService.updateFields(user, { introDismissed: user.introDismissed }, user);
    //     }
    // }

    // /**
    // * Marks important message dismissed for the user
    // * @param userId
    // * @returns void
    // */
    // public static async dismissImportantMessage(userId: string): Promise<void> {
    //     const user = await UserService.get(userId);
    //     if (user == null) {
    //         return;
    //     }

    //     if (user.importantMessageDismissed !== true) {
    //         user.importantMessageDismissed = true;
    //         await UserService.update(user);
    //     }
    // }

    // /**
    //  * Marks intro dismissed for the user
    //  * @param userId
    //  * @returns void
    //  */
    // public static async dismissCongratulatoryMessage(userId: string): Promise<void> {
    //     const user = await UserService.get(userId);
    //     if (user == null) {
    //         return;
    //     }

    //     if (user.congratulatoryMessageDismissed !== true) {
    //         user.congratulatoryMessageDismissed = true;
    //         await UserService.updateFields(
    //             user,
    //             { congratulatoryMessageDismissed: user.congratulatoryMessageDismissed },
    //             user
    //         );
    //     }
    // }

    /**
     * Handle new MSK Scores to update movement & lifestyle retakes
     * @param userId User to modify message
     * @param importantAreaGroupId Group that needs handled
     * @param isAdd Add or remove important message
     * @returns True if the user record was updated
     */
    public static async handleNewMskScore(
        userId: string,
        settings?: NewMskScoreSettings
    ): Promise<boolean> {
        if (userId == null || settings?.mskScore == null) {
            _log(`handleNewMskScore("${userId}") - bad input(s)`);
            return false;
        }

        let user = await UserService.get(userId);
        if (user == null) {
            _log(`handleNewMskScore("${userId}") - user was not found`);
            return false;
        }

        let wasUpdated = false;

        // remove movement retake - Jan 31st 2025 -> we don't have retake cards anymore
        // if (
        //     settings.wasMovementRetake &&
        //     settings.mskScore.movementScore?.percentage != null
        // ) {
        //     _log(`handleNewMskScore() - movement retake removed`);
        //     if (await this._setRetakeMovementAssessment(user, false)) {
        //         wasUpdated = true;
        //     }
        // }

        // set lifestyle completed Jan 31st 2025 -> we don't have retake cards anymore
        // if (
        //     settings.lifestyleGroupId != null &&
        //     settings.mskScore.lifestyleScore?.percentage != null
        // ) {
        //     _log(
        //         `handleNewMskScore() - lifestyle ${settings.lifestyleGroupId} completed`
        //     );
        //     if (
        //         await this._modifyRetakeLifestyleAssessments(
        //             user,
        //             settings.lifestyleGroupId,
        //             false
        //         ) && queryParams.get("retake") !== "true"
        //     ) {
        //         wasUpdated = true;
        //     }
        // }

        // important message: pain
        const riskFactors =
            settings.mskScore.lifestyleScore?.injuryHistoryScore?.riskFactors;
        const hasInjuryPain =
            riskFactors?.hadPain === true &&
            riskFactors?.hadSeenPhysician === false;
        const hasMovementPain = DataAssessmentsUtil.hasMovementPain(
            settings.mskScore?.movementScore
        );
        const needsPainFlag = hasInjuryPain || hasMovementPain;
        _log(
            `handleNewMskScore() - needsPainFlag=${needsPainFlag} hasInjuryPain=${hasInjuryPain} hasMovementPain=${hasMovementPain}`
        );
        if (this._modifyImportantMessage(user, GroupIds.PAIN, needsPainFlag)) {
            wasUpdated = true;
        }

        // important message: behavioral health
        const behavioralPercent =
            settings.mskScore.lifestyleScore?.behavioralScore?.percentage;
        const needsBehavioralFlag =
            behavioralPercent == null ? false : behavioralPercent <= 66;
        _log(
            `handleNewMskScore() - needsBehavioralFlag=${needsBehavioralFlag}`
        );
        if (
            this._modifyImportantMessage(
                user,
                GroupIds.BEHAVIORAL_HEALTH,
                needsBehavioralFlag
            )
        ) {
            wasUpdated = true;
        }

        if (wasUpdated) {
            _log(`handleNewMskScore() - user update`);
            await UserService.update(user);
        }
        return wasUpdated;
    }

    /**
     * Modify user to add or remove important messages
     * @param userId User to modify message
     * @param importantAreaGroupId Group that needs handled
     * @param isAdd Add or remove important message
     * @returns True if the user record was updated
     */
    public static async modifyImportantMessages(
        userId: string,
        importantAreaGroupIds: string[],
        isAdd: boolean
    ): Promise<boolean> {
        if (
            userId == null ||
            importantAreaGroupIds == null ||
            importantAreaGroupIds.length === 0 ||
            isAdd == null
        ) {
            _log(
                `modifyImportantMessages("${userId}", "${importantAreaGroupIds.join(
                    ", "
                )}", ${isAdd}) - bad input(s)`
            );
            return false;
        }

        let user = await UserService.get(userId);
        if (user == null) {
            _log(
                `modifyImportantMessages("${userId}", "${importantAreaGroupIds.join(
                    ", "
                )}", ${isAdd}) - user was not found`
            );
            return false;
        }

        let wasUpdated = false;
        importantAreaGroupIds.forEach((groupId) => {
            if (this._modifyImportantMessage(user!, groupId, isAdd)) {
                wasUpdated = true;
            }
        });

        _log(
            `modifyImportantMessages() groups=${importantAreaGroupIds.join(
                ", "
            )} isAdd=${isAdd} - wasUpdated=${wasUpdated}`
        );
        if (wasUpdated) {
            await UserService.update(user);
        }
        return wasUpdated;
    }

    /**
     * @returns True if the user record was modifed (it is not saved)
     */
    private static _modifyImportantMessage(
        user: IUser,
        importantAreaGroupId: string,
        isAdd: boolean
    ): boolean {
        const importantAreas = user.importantMessages || [];
        const exists =
            importantAreas.find((x) => x.groupId === importantAreaGroupId) !=
            null;
        _log(
            `_modifyImportantMessage() groupId=${importantAreaGroupId} isAdd=${isAdd} exists=${exists}`
        );

        if (exists && isAdd) {
            return false;
        }
        if (!exists && !isAdd) {
            return false;
        }

        if (isAdd) {
            importantAreas.push({
                groupId: importantAreaGroupId,
                groupName: GroupUtil.getName(importantAreaGroupId),
                createdDateMs: DateUtil.getCurrentMsDate(),
            });
            user.importantMessages = importantAreas;
        } else {
            user.importantMessages = user.importantMessages?.filter(
                (x) => x.groupId !== importantAreaGroupId
            );
        }

        return true;
    }

    /**
     * Updates user lifestyle assessment retake status
     * @param userId User to modify message
     * @param retake True if should retake the specific assessment
     * @returns True if the user record was updated
     */
    // public static async modifyRetakeLifestyleAssessments(
    //     userId: string,
    //     lifestyleGroupId: string,
    //     isAdd: boolean
    // ): Promise<boolean> {
    //     _log(
    //         `modifyRetakeLifestyleAssessments("${userId}", "${lifestyleGroupId}", ${isAdd})`
    //     );
    //     if (userId == null || lifestyleGroupId == null || isAdd == null) {
    //         return false;
    //     }

    //     const user = await UserService.get(userId);
    //     if (user == null) {
    //         return false;
    //     }

    //     const wasUpdated = await this._modifyRetakeLifestyleAssessments(
    //         user,
    //         lifestyleGroupId,
    //         isAdd
    //     );
    //     if (wasUpdated) {
    //         await UserService.update(user);
    //     }

    //     return true;
    // }

    /**
     * @returns True if the user record was modifed (it is not saved)
     */
    // private static async _modifyRetakeLifestyleAssessments(
    //     user: IUser,
    //     lifestyleGroupId: string,
    //     isAdd: boolean
    // ): Promise<boolean> {
    //     const assessments = user.retakeLifestyleAssessments || [];
    //     const retestAssessment = assessments.find(
    //         (x) => x.assessmentGroupId === lifestyleGroupId
    //     );
    //     const exists = retestAssessment != null;

    //     if (exists && isAdd) {
    //         return false;
    //     }

    //     const createRetake = isAdd || (!isAdd && !exists);
    //     if (createRetake) {
    //         assessments.push({
    //             assessmentGroupId: lifestyleGroupId,
    //             assessmentGroupName: GroupUtil.getName(lifestyleGroupId),
    //             createdMs: DateUtil.getCurrentMsDate(),
    //         });
    //         user.retakeLifestyleAssessments = assessments;
    //     }

    //     if (!isAdd) {
    //         const index = user.retakeLifestyleAssessments?.findIndex(
    //             (x) => x.assessmentGroupId === lifestyleGroupId
    //         );
    //         if (index != null && index !== -1) {
    //             user.retakeLifestyleAssessments![index].completedDateMs =
    //                 DateUtil.getCurrentMsDate();
    //         }
    //     }

    //     return true;
    // }

    /**
     * Updates user movement retake status
     * @param userId User to modify message
     * @param retake True if should retake the movement assessment
     * @returns True if the user record was updated
     */
    // public static async setRetakeMovementAssessment(
    //     userId: string,
    //     retake: boolean
    // ): Promise<boolean> {
    //     if (userId == null) {
    //         throw new Error("Invalid Retake Movement User ID");
    //     }
    //     if (retake == null) {
    //         throw new Error("Invalid Retake Movement Status");
    //     }

    //     const user = await UserService.get(userId);
    //     if (user == null) {
    //         _log(`setRetakeMovementAssessment() user "${userId}" is null`);
    //         return false;
    //     }

    //     const wasUpdated = await this._setRetakeMovementAssessment(
    //         user,
    //         retake
    //     );
    //     if (wasUpdated) {
    //         await UserService.update(user);
    //     }

    //     return wasUpdated;
    // }

    /**
     * @returns True if the user record was modifed (it is not saved)
     */
    // private static async _setRetakeMovementAssessment(
    //     user: IUser,
    //     retake: boolean
    // ): Promise<boolean> {
    //     const exists = user.retakeMovementAssessment != null;

    //     if (exists && retake) {
    //         return false;
    //     }
    //     if (!exists && !retake) {
    //         return false;
    //     }

    //     if (retake) {
    //         user.retakeMovementAssessment = {
    //             assessmentGroupId: GroupIds.MOVEMENT_HEALTH,
    //             assessmentGroupName: GroupUtil.getName(
    //                 GroupIds.MOVEMENT_HEALTH
    //             ),
    //             createdMs: DateUtil.getCurrentMsDate(),
    //         };
    //         _log(
    //             `_setRetakeMovementAssessment() add retakeMovementAssessment:`,
    //             user.retakeMovementAssessment
    //         );
    //     } else if (!retake) {
    //         user.retakeMovementAssessment!.completedDateMs =
    //             DateUtil.getCurrentMsDate();
    //         _log(
    //             `_setRetakeMovementAssessment() retake completed:`,
    //             user.retakeMovementAssessment
    //         );
    //     }

    //     return true;
    // }
}

interface NewMskScoreSettings {
    mskScore?: MskScore;
    wasMovementRetake?: boolean;
    lifestyleGroupId?: string;
}

const _log = (message?: any, ...optionalParams: any[]): void => {
    if (false) {
        console.log(message, ...optionalParams);
    }
};
