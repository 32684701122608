/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { QuestionInformation } from "../../models/interfaces/questions/question-information";
import AssessmentHelpModal from "../assessment-help-modal/assessment-help-modal";
import { useTranslation } from "react-i18next";

const COMPONENT_CLASS = "c-input-option";
const CONVERSION = 2.54;
enum UNIT {
    IN,
    CM
}

export interface InchInputOptionProps {
    onChange: (newAnswer: string) => void;
    answer?: string;
    questionInfo?: QuestionInformation;
    open?: boolean;
    closeParent?: Function;
}

const InchInputOption: React.FC<InchInputOptionProps> = (props) => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const inchRef = useRef(null) as any;
    const [unit, setUnit] = useState(i18n.language === "en" ? UNIT.IN : UNIT.CM);

    const inchesToCm = (inch: number) => {
        return inch * CONVERSION;
    };

    const cmToInches = (centimeters: number) => {
        return centimeters / CONVERSION;
    };

    const [length, setLength] = useState(props.answer
        ? unit === UNIT.IN ? parseInt(props.answer).toFixed(0) : unit === UNIT.CM
            ? inchesToCm(parseInt(props.answer)).toFixed(0) : "" : "");

    useEffect(() => {
        setTimeout(() => {
            if (inchRef?.current) {
                inchRef.current.setFocus();
            }
        }, 100);
    }, [props.questionInfo]);

    useEffect(() => {
        if (props.open === true) {
            handlePopupOpen();
        }
    }, [props.open]);

    useEffect(() => {
        let newAnswer = 0;

        if (unit === UNIT.IN) {
            newAnswer = parseInt(length.length > 0 ? length : "0");
            if (length.length > 2) {
                setLength(length.substring(0, length.length - 1));
            }
        }
        else if (unit === UNIT.CM) {
            newAnswer = Math.round(cmToInches(parseInt(length.length > 0 ? length : "0")));
        }

        if (newAnswer <= 0) {
            props.onChange("");
            return;
        }

        props.onChange(newAnswer.toString());
    }, [unit, length]);

    const handlePopupOpen = () => {
        if (!isOpen) {
            setIsOpen(true);
        }
    };

    const handlePopupClose = () => {
        if (isOpen && props.closeParent) {
            props.closeParent();
            setIsOpen(false);
        }
    };

    const integerOnlyValidation = (event: any) => {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    };

    return (
        <div>
            <button
                color="primary"
                onClick={handlePopupOpen}
                className={`${COMPONENT_CLASS}__pop-button`}>
                {t('general.informationButton')}
            </button>
            <AssessmentHelpModal
                open={isOpen}
                questionInfo={props.questionInfo}
                onClose={handlePopupClose} />

            <div className={`${COMPONENT_CLASS} -inches`}>
                <div className={`${COMPONENT_CLASS}__inches-input-container`}>
                    <div className={`${COMPONENT_CLASS}__inches-input`}>
                        <input className={`${COMPONENT_CLASS}__input`}
                            autoFocus
                            onChange={(e: any) => {
                                let lengthStr = e.target.value;

                                if (unit === UNIT.IN && lengthStr.length > 2) {
                                    lengthStr = lengthStr.substring(
                                        0,
                                        lengthStr.length - 1
                                    );
                                }
                                else if (unit === UNIT.CM && lengthStr.length > 3) {
                                    lengthStr = lengthStr.substring(
                                        0,
                                        lengthStr.length - 1
                                    );
                                }

                                setLength(lengthStr);
                            }}
                            type="number"
                            value={length}
                            maxLength={unit === UNIT.IN ? 2 : 3}
                            onKeyPress={(e: any) => integerOnlyValidation(e)}
                        >
                        </input>
                    </div>
                    <div className={`${COMPONENT_CLASS}__unit-types`}>
                        <input
                            name="weight-type"
                            type="radio"
                            id="IN"
                            value="IN"
                            checked={unit === UNIT.IN}
                            onClick={() => setUnit(UNIT.IN)}
                        />
                        <label className={`in-radio-label __radio ${COMPONENT_CLASS}__weight-button ${unit === UNIT.IN ? "-active" : ""}`} htmlFor="IN" >{t('general.height.in')}</label>
                        <input
                            name="weight-type"
                            type="radio"
                            id="CM"
                            value="CM"
                            checked={unit === UNIT.CM}
                            onClick={() => setUnit(UNIT.CM)}
                        />
                        <label className={`in-radio-label __radio ${COMPONENT_CLASS}__weight-button ${unit === UNIT.CM ? "-active" : ""}`} htmlFor="CM">{t('general.height.cm')}</label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InchInputOption;
