import { Dialog } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { Configure, connectInfiniteHits, InstantSearch, SearchBox } from 'react-instantsearch-dom';
import { OrganizationStatus } from '../../models/enumerations/organization-status';
import { useStore } from '../../store/useStore';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import searchClient, { SearchIndexes } from '../../utilities/search-client';
import OrganizationService from '../../utilities/services/organization-service';
import { Button } from '../button/button';
import { Loader } from '../loader/loader';
import { Modal } from '../modal/modal';
import UserUtil from '../../utilities/user-util';
import { useTranslation } from 'react-i18next';

const MODAL_COMPONENT_CLASS = "c-modal";
const COMPONENT_CLASS = "c-application-header";

type SwitchhOrganizationModalProps = {
    open: boolean,
    setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void,
    setIsSwitching: (value: boolean | ((prevVar: boolean) => boolean)) => void
}


export const SwitchOrganizationModal = (props: SwitchhOrganizationModalProps) => {
    const {
        open,
        setOpen,
        setIsSwitching,
    } = props;

    const { t } = useTranslation();
    const [algoliaFilters, setAlgoliaFilters] = useState<string>();
    const [searchState, setSearchState] = useState({
        sortBy: SearchIndexes.organizationsByNameAsc,
    });
    const { state, setState } = useAuthState();
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(state.organization?.id);
    const isSuperAdmin = state?.claims?.superAdmin === true;
    const isAccountHolder = UserUtil.isAccountHolder(state.user);

    const { markTagsStale, markGroupsStale, markDeeplinksStale, setOrganization } = useStore((state) => ({
        markTagsStale: state.markTagsStale,
        markGroupsStale: state.markGroupsStale,
        setOrganization: state.setOrganization,
        markDeeplinksStale: state.markDeeplinksStale,
    }))

    const handleSwitchOrganizationClick = async () => {
        if (selectedOrganizationId == null) {
            // TODO : Global exception handling
            // https://app.clickup.com/t/2219993/FMS-1236
            return;
        }

        setIsSwitching(true);
        const org = await OrganizationService.get(selectedOrganizationId);
        if (org != null) {
            setState((state) => ({
                ...state, ...{ organization: org }
            }));
            markTagsStale();
            markGroupsStale();
            markDeeplinksStale();
            await setOrganization(org);
        }

        setIsSwitching(false);
        setOpen(false);
    }

    useEffect(() => {
        const setFilters = async () => {
            if (isSuperAdmin) {
                setAlgoliaFilters('');
            }
            if (isAccountHolder) {
                setAlgoliaFilters(await UserUtil.getAlgoliaAccountHolderOrganizationFilter(state.user));
            }
        };

        setFilters();
    }, [state.user, isAccountHolder, isSuperAdmin])

    const customHits = ({
        hits,
        hasPrevious,
        refinePrevious,
        hasMore,
        refineNext,
    }: any) => {
        return (
            <div className={`${COMPONENT_CLASS}__organization-list__wrapper`}>
                <div className={`${COMPONENT_CLASS}__organization-list__action ${!hasPrevious ? "hidden" : ""}`}>
                    <Button
                        type='link'
                        disabled={!hasPrevious}
                        onClick={refinePrevious}
                        buttonText={t('buttons.btn_showPrev')} />
                </div>
                <ul>
                    {hits.map((h: any, i: number) => (
                        <li
                            className={`${COMPONENT_CLASS}__organization-list__item -${OrganizationStatus[h.status].toLowerCase()} ${selectedOrganizationId === h.id ? "-selected" : ""}`}
                            onClick={() => setSelectedOrganizationId(h.id)}
                            key={`organization-${i}`}>
                            <div className={`${COMPONENT_CLASS}__organization-list__item__name`}>
                                {h.name}
                            </div>
                            <div className={`${COMPONENT_CLASS}__organization-list__item__status`}>
                                {OrganizationStatus[h.status]}
                            </div>
                        </li>
                    ))}
                </ul>
                <div className={`${COMPONENT_CLASS}__organization-list__action ${!hasMore ? "hidden" : ""}`}>
                    <Button
                        type='link'
                        disabled={!hasMore}
                        onClick={refineNext}
                        buttonText={t('buttons.btn_showMore')} />
                </div>
            </div>
        )
    }

    const CustomHits = connectInfiniteHits(customHits);

    return (
        <Modal
            isOpen={open}
            isLoading={false}
            onClose={setOpen}
            title={t('switchOrganizationModal.switchOrganizations')}
            defaultModalActions={true}
            submitButtonText={t('switchOrganizationModal.switchToThis')}
            onSubmit={() => handleSwitchOrganizationClick()}
            onCancel={() => setOpen(false)}
        >

            <div className={`${COMPONENT_CLASS}__organization-list`}>
                <Dialog.Title
                    as="h4">
                    {t('switchOrganizationModal.select')}
                </Dialog.Title>
                {open &&
                    <InstantSearch
                        indexName={SearchIndexes.organizationsByNameAsc}
                        searchClient={searchClient}
                        searchState={searchState}
                        onSearchStateChange={searchState => {
                            setSearchState(searchState);
                        }}>
                        <Configure
                            filters={algoliaFilters}
                            hitsPerPage={50}
                        />
                        <SearchBox
                            autoFocus
                            className={`${MODAL_COMPONENT_CLASS}__search`}
                            searchAsYouType={true}
                            showLoadingIndicator={true}
                            loadingIndicator={<Loader isVisible={true} />}
                            translations={{
                                placeholder: t('general.search')
                            }}
                        />
                        <CustomHits />
                    </InstantSearch>
                }
            </div>
        </Modal>
    );
}
