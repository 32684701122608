/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import AssessmentResponse from "../../models/interfaces/assessment-response.entity";
import MskScoreService from "../../utilities/services/msk-score-service";
import moment from "moment";
import { GroupIds } from "../../models/enumerations/group-ids";
import { QuestionIds } from "../../models/enumerations/question-ids";
import UserProfileAssessmentHeader from "./user-profile-assessment-header";
import NonLinearLineChart from "../charts/non-linear-line-chart";
import LineChartJS from "../charts/line-chart-js";
import { ReactComponent as DotIcon } from "../../assets/icons/solid/icon_dot.svg";
import RiskChartVertical from "../charts/risk-chart-vertical";
import { useTranslation } from "react-i18next";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileBreathingProps {
    mskScore?: MskScore;
    user: User;
    assessment?: any;
    assessmentResponses: AssessmentResponse[];
    allMskScores: MskScore[];
    setAllMskScores: Function;
    setSpecificAssessment: Function;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileBreathing: React.FC<UserProfileBreathingProps> = (props: UserProfileBreathingProps) => {
    const { setSpecificAssessment, mskScore, assessment, allMskScores, setAllMskScores, user, assessmentResponses } = props;
    const [breathingHistory, setBreathingHistory] = useState<{ x: string, y: number }[]>();
    const [breathHoldHistory, setBreathHoldHistory] = useState<{ x: string, y: number }[]>();
    const [breathingQuestionnaireHistory, setBreathingQuestionnaireHistory] = useState<{ x: string, y: number }[]>();
    const [customBreathingQuestionnaireHistoryLabels, setCustomBreathingQuestionnaireHistoryLabels] = useState<{ x: string, y: number }[]>();
    const [smokeNow, setSmokeNow] = useState<boolean>();
    const { t } = useTranslation();

    useEffect(() => {
        const getAllMskScores = async () => {
            if (!allMskScores) {
                const mskScores = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }])

                setAllMskScores(mskScores);
            }
        }
        getAllMskScores();
    }, [])
    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempBreathingHistory = []
                let tempBreathHoldHistory = []
                let tempBreathingQuestionnaireHistory = []
                let tempCustomBreathingQuestionnaireHistoryLabels = []

                for (const mskScore of allMskScores) {
                    tempBreathingHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.lifestyleScore?.breathingScore?.percentage ?? 0
                    })
                    if (mskScore.lifestyleScore?.breathingScore?.breathHoldTime || mskScore.lifestyleScore?.breathingScore?.breathHoldTime === 0) {
                        tempBreathHoldHistory.push({
                            x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                            y: mskScore.lifestyleScore?.breathingScore?.breathHoldTime
                        })
                    }


                    if (mskScore.lifestyleScore?.breathingScore?.breathingQuestionnaireScore || mskScore.lifestyleScore?.breathingScore?.breathingQuestionnaireScore === 0) {
                        tempBreathingQuestionnaireHistory.push({
                            x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                            y: mskScore.lifestyleScore?.breathingScore?.breathingQuestionnaireScore === 0 ? 0.45 : mskScore.lifestyleScore?.breathingScore?.breathingQuestionnaireScore === 1 ? 1.45 : mskScore.lifestyleScore?.breathingScore?.breathingQuestionnaireScore ?? 0.5
                        })

                        tempCustomBreathingQuestionnaireHistoryLabels.push({
                            x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                            y: mskScore.lifestyleScore?.breathingScore?.breathingQuestionnaireScore
                        })
                    }

                }
                if (tempBreathHoldHistory.length === 0) {
                    const breathHold = getBreathHoldResponse();
                    if (breathHold) {
                        tempBreathHoldHistory.push(breathHold)
                    }
                }
                if (tempBreathingQuestionnaireHistory.length === 0) {
                    const questionnaireScore = getBreathingQuestionnaireResponse();
                    if (questionnaireScore) {
                        tempBreathingQuestionnaireHistory.push(questionnaireScore);
                        tempCustomBreathingQuestionnaireHistoryLabels.push({
                            x: questionnaireScore.x,
                            y: questionnaireScore.y
                        })
                    }
                }
                setBreathingHistory(tempBreathingHistory);
                setBreathHoldHistory(tempBreathHoldHistory);
                setBreathingQuestionnaireHistory(tempBreathingQuestionnaireHistory);
                setCustomBreathingQuestionnaireHistoryLabels(tempCustomBreathingQuestionnaireHistoryLabels);
            }
        }
        getHistory();
    }, [allMskScores])

    useEffect(() => {
        if (assessmentResponses) {
            getSmokeResponse();
        }
    }, [assessmentResponses])

    const getBreathingQuestionnaireResponse = () => {
        if (!assessmentResponses) {
            return;
        }
        const breathingResponse: any = assessmentResponses.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.BREATHING)
        if (breathingResponse.length > 0) {

            let breathingQuestionnaireScore = 0;
            // breathingQuestionnaire
            // Find each response that is scored
            const cold = breathingResponse && breathingResponse.length > 0 ? breathingResponse[0].responses.find((response: any) => response.questionId === QuestionIds.BREATHING_COLD) : undefined
            if (cold) {
                switch (cold?.answerId) {
                    case 'A':
                        break;
                    case 'B':
                        breathingQuestionnaireScore = breathingQuestionnaireScore + 1
                        break;
                    case 'C':
                        breathingQuestionnaireScore = breathingQuestionnaireScore + 2
                        break;
                    case 'D':
                        breathingQuestionnaireScore = breathingQuestionnaireScore + 3
                        break;
                    default:
                        break;
                }
            }

            const mouthBreath = breathingResponse && breathingResponse.length > 0 ? breathingResponse[0].responses.find((response: any) => response.questionId === QuestionIds.BREATHING_MOUTH_BREATHE) : undefined
            if (mouthBreath) {
                switch (mouthBreath?.answerId) {
                    case 'A':
                        break;
                    case 'B':
                        breathingQuestionnaireScore = breathingQuestionnaireScore + 1
                        break;
                    case 'C':
                        breathingQuestionnaireScore = breathingQuestionnaireScore + 2
                        break;
                    case 'D':
                        breathingQuestionnaireScore = breathingQuestionnaireScore + 3
                        break;
                    default:
                        break;
                }
            }
            const tense = breathingResponse && breathingResponse.length > 0 ? breathingResponse[0].responses.find((response: any) => response.questionId === QuestionIds.BREATHING_TENSE) : undefined
            if (tense) {
                switch (tense?.answerId) {
                    case 'A':
                        break;
                    case 'B':
                        breathingQuestionnaireScore = breathingQuestionnaireScore + 1
                        break;
                    case 'C':
                        breathingQuestionnaireScore = breathingQuestionnaireScore + 2
                        break;
                    case 'D':
                        breathingQuestionnaireScore = breathingQuestionnaireScore + 3
                        break;
                    default:
                        break;
                }
            }
            const yawn = breathingResponse && breathingResponse.length > 0 ? breathingResponse[0].responses.find((response: any) => response.questionId === QuestionIds.BREATHING_YAWNING) : undefined
            if (yawn) {
                switch (yawn?.answerId) {
                    case 'A':
                        break;
                    case 'B':
                        breathingQuestionnaireScore = breathingQuestionnaireScore + 1
                        break;
                    case 'C':
                        breathingQuestionnaireScore = breathingQuestionnaireScore + 2
                        break;
                    case 'D':
                        breathingQuestionnaireScore = breathingQuestionnaireScore + 3
                        break;
                    default:
                        break;
                }
            }

            return {
                x: moment(breathingResponse[0] && breathingResponse[0].created?._seconds ? breathingResponse[0].created._seconds * 1000 : NaN).format("MMM DD, YY"),
                y: breathingQuestionnaireScore === 0 ? 0.45 : breathingQuestionnaireScore === 1 ? 1.45 : breathingQuestionnaireScore ?? 0.5
            }
        }
    }

    const getBreathHoldResponse = () => {
        if (!assessmentResponses) {
            return;
        }
        const breathingResponse: any = assessmentResponses.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.BREATHING)
        if (breathingResponse.length > 0) {

            // breathBreath
            const breath = breathingResponse && breathingResponse.length > 0 ? breathingResponse[0].responses.find((response: any) => response.questionId === QuestionIds.BREATHING_BREATH) : undefined
            if (breath) {
                return {
                    x: moment(breathingResponse[0] && breathingResponse[0].created?._seconds ? breathingResponse[0].created._seconds * 1000 : NaN).format("MMM DD, YY"),
                    y: parseInt(breath.answerResponse) ?? 0
                }
            }
        }
    }

    const getSmokeResponse = () => {
        if (!assessmentResponses) {
            return;
        }
        const breathingResponse: any = assessmentResponses.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.BREATHING)
        if (breathingResponse.length) {
            // smoking
            const smoke = breathingResponse && breathingResponse.length > 0 ? breathingResponse[0].responses.find((response: any) => response.questionId === QuestionIds.BREATHING_SMOKE_NOW) : undefined
            if (smoke) {
                if (smoke.answerId === "D") {
                    setSmokeNow(false);
                }
                else {
                    setSmokeNow(true);
                }
            }
        }
    }

    return (
        <>
            <div>
                <UserProfileAssessmentHeader
                    assessment={assessment}
                    mskScore={mskScore}
                    setSpecificAssessment={setSpecificAssessment}
                    mainTextHeader={t('userProfile.assessments.breathingHeader')}
                    mainText={t('userProfile.assessments.breathingText')}
                    whyImportantText={t('userProfile.assessments.breathingWhy')}
                />
            </div>

            {breathingHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper my-5`}>
                    <LineChartJS
                        data={breathingHistory.map(history => history.y)}
                        title={t('userProfile.assessments.breathingHistory')}
                        labels={breathingHistory.map(history => history.x)}
                        height={"200px"}
                        horizontalScrolling={true}
                        pointRadius={7}
                        pointBorderWidth={3}
                        borderWidth={5}
                        pointFontSize={16}
                        fontFamily={'pragmatica-extended'}
                        pointLabelOffset={0}
                        xLabelFontSize={14}
                        paddingTop={30}
                        paddingBottom={20}
                        maintainAspectRatio={false}
                    />
                </div>
            )}

            {breathHoldHistory && breathHoldHistory.length > 0 && (
                <div className="flex gap-5 mb-5">
                    <div className={`${COMPONENT_CLASS}__score-section-card min-w-72 max-w-72`}>
                        <h3 className="flex items-center justify-center mb-4">
                            {t('reports.wellness.coloredCells.breathHold.title')}
                        </h3>
                        <div className="w-full h-64">
                            <RiskChartVertical
                                score={breathHoldHistory[breathHoldHistory.length - 1].y}
                                breakpoints={[
                                    { min: 20, max: 40, label: t('reports.wellness.coloredCells.breathHold.greenMain'), bgColor: "#8BE3CE", subLabel: t('reports.wellness.coloredCells.breathHold.greenSub') },
                                    { min: 15, max: 19, label: t('reports.wellness.coloredCells.breathHold.orangeMain'), bgColor: "#FFC79C", subLabel: t('reports.wellness.coloredCells.breathHold.orangeSub') },
                                    { min: 0, max: 14, label: t('reports.wellness.coloredCells.breathHold.redMain'), bgColor: "#FFA998", subLabel: t('reports.wellness.coloredCells.breathHold.redSub') },
                                ]}
                                decimals={true}
                            />
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                        <NonLinearLineChart
                            dataPoints={breathHoldHistory.map(history => history.y)}
                            labels={breathHoldHistory.map(history => history.x)}
                            title={t('userProfile.assessments.holdTimeHistory')}
                            sectionDetails={{
                                sections: 3,
                                rangeOne: { start: 0, end: 14 },
                                rangeTwo: { start: 15, end: 19 },
                                rangeThree: { start: 20, end: 40 },
                                backgroundColorOrder: { first: "#FFA998", second: "#FFE9CC", third: "#8BE3CE", },
                                dataPointColorOrder: { first: "#E7514F", second: "#FFC79C", third: "#00DFBC" },
                                yAxisLabels: { first: t('reports.wellness.coloredCells.breathHold.redMain').toUpperCase(), second: t('reports.wellness.coloredCells.breathHold.orangeMain').toUpperCase(), third: t('reports.wellness.coloredCells.breathHold.greenMain').toUpperCase() },
                                decimals: true
                            }}
                            toolTipLabel={true}
                        />
                    </div>
                </div>

            )}
            {breathingQuestionnaireHistory && breathingQuestionnaireHistory.length > 0 && (
                <div className="flex gap-5 mb-5">
                    <div className={`${COMPONENT_CLASS}__score-section-card min-w-72 max-w-72`}>
                        <h3 className="flex items-center justify-center mb-4">
                            {t('reports.wellness.coloredCells.breathingQuestionnaire.title')}
                        </h3>
                        <div className="w-full h-64">
                            <RiskChartVertical
                                score={breathingQuestionnaireHistory[breathingQuestionnaireHistory.length - 1].y}
                                breakpoints={[
                                    { min: 2, max: 12, label: t('reports.wellness.coloredCells.breathingQuestionnaire.redMain'), bgColor: "#FFA998", subLabel: t('reports.wellness.coloredCells.breathingQuestionnaire.redSub') },
                                    { min: 1, max: 1.9, label: t('reports.wellness.coloredCells.breathingQuestionnaire.orangeMain'), bgColor: "#FFC79C", subLabel: t('reports.wellness.coloredCells.breathingQuestionnaire.orangeSub') },
                                    { min: 0, max: 0.9, label: t('reports.wellness.coloredCells.breathingQuestionnaire.greenMain'), bgColor: "#8BE3CE", subLabel: t('reports.wellness.coloredCells.breathingQuestionnaire.greenSub') },
                                ]}
                                decimals={true}
                            />
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                        <NonLinearLineChart
                            dataPoints={breathingQuestionnaireHistory.map(history => history.y)}
                            customDataPointLabels={customBreathingQuestionnaireHistoryLabels ? customBreathingQuestionnaireHistoryLabels.map(history => history.y) : undefined}
                            labels={breathingQuestionnaireHistory.map(history => history.x)}
                            title={t('userProfile.assessments.breathingQuestionnaireHistory')}
                            sectionDetails={{
                                sections: 3,
                                rangeOne: { start: 0, end: 0.9 },
                                rangeTwo: { start: 1, end: 1.9 },
                                rangeThree: { start: 2, end: 12 },
                                backgroundColorOrder: { first: "#8BE3CE", second: "#FFE9CC", third: "#FFA998" },
                                dataPointColorOrder: { first: "#00DFBC", second: "#FFC79C", third: "#E7514F" },
                                yAxisLabels: { first: t('reports.wellness.coloredCells.breathingQuestionnaire.greenMain').toUpperCase(), second: t('reports.wellness.coloredCells.breathingQuestionnaire.orangeMain').toUpperCase(), third: t('reports.wellness.coloredCells.breathingQuestionnaire.redMain').toUpperCase() },
                                decimals: true,
                            }}
                            toolTipLabel={true}
                        />
                    </div>
                </div>

            )}
            <div className={`${COMPONENT_CLASS}__score-section-card`}>
                <div>
                    <h3 className="mb-4">{t('userProfile.assessments.tobaccoUse')}</h3>
                </div>
                <div className="flex gap-2 items-center text-sm font-light">
                    <DotIcon color={smokeNow ? "#E7514F" : "#00DFBC"} />
                    <div>{smokeNow ? t('general.yes') : t('general.no')}</div>
                </div>
            </div>
        </>
    );
}

export default UserProfileBreathing;
