import React from "react";
import { useTranslation } from 'react-i18next';

export interface RequestModalMessageProps {
    newRequest: boolean,
}

/**
 * Represents the message that is shown in the request modal.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const RequestModalMessage: React.FC<RequestModalMessageProps> = (props) => {
    const { newRequest } = props;
    const { t } = useTranslation();

    let message = '';

    if (newRequest) {
        message = t('login.modal.request.message.newRequest');
    } else {
        message = t('login.modal.request.message.oldRequest');
    }

    return (
        <p className="block" style={{ textAlign: 'center', margin: '0 81px 0 81px' }}>{message}</p>
    );
};

export default RequestModalMessage;