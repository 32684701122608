/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { useIntercom } from "react-use-intercom";
import { useTranslation } from "react-i18next";

export interface IntercomAuthCheckProps {
}

/**
 * A modal that shows up when the user's subscription was cancelled due to a failed payment.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const IntercomAuthCheck: React.FC<IntercomAuthCheckProps> = (props) => {
    const { state } = useAuthState();
    const { boot, shutdown } = useIntercom();
    var CryptoJS = require("crypto-js");
    const { t } = useTranslation();

    useEffect(() => {
        if (state.authenticated) {
            const customAttributes: any = {}
            if (state.user?.roles && state.user.roles.length > 0) {
                customAttributes["Symmio Role"] = state.user.roles[0];
            }
            if (state.organization && state.organization.plan?.label) {
                customAttributes["Plan Type"] = t(state.organization.plan.label)
            }
            const hmac = generateHmac();
            boot({
                email: state.user?.email,
                userHash: hmac,
                customAttributes: customAttributes,
            })
        }
        else {
            shutdown()
        }
    }, [state.authenticated])

    const generateHmac = () => {

        // Ensure message and secretKey are not empty
        if (!state.user?.email || !process.env.REACT_APP_INTERCOM_VERIFICATION) {
            return;
        }
        // Calculate HMAC using CryptoJS
        const hmac = CryptoJS.HmacSHA256(state.user.email, process.env.REACT_APP_INTERCOM_VERIFICATION).toString();

        return hmac;
    };

    return (
        <></>
    );
};

export default IntercomAuthCheck;