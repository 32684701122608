import { DateTime } from "luxon";
import { ReportSummaryData } from "../../models/interfaces/reports/report-summary-data";
import PillChartArray from "../charts/pill-chart/pill-chart-array";
import ReportGenericHeader from "./report-generic-header";
import { useTranslation } from 'react-i18next';
import GroupUtil from "../../utilities/group-util";

const COMPONENT_CLASS = "c-report";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportSummaryProps {
    data: ReportSummaryData;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportSummary: React.FC<ReportSummaryProps> = (props: ReportSummaryProps) => {
    const { data } = props;
    const { t, i18n } = useTranslation();

    const riskSummaries = [{
        name: "Health",
        displayAs: t('reports.summary.riskSummaries.health.displayAs'),
        description: t('reports.summary.riskSummaries.health.description'),
    }, {
        name: "Wellness",
        displayAs: t('reports.summary.riskSummaries.wellness.displayAs'),
        description: t('reports.summary.riskSummaries.wellness.description'),
    }, {
        name: "Fitness",
        displayAs: t('reports.summary.riskSummaries.fitness.displayAs'),
        description: t('reports.summary.riskSummaries.fitness.description'),
    }, {
        name: "Performance",
        displayAs: t('reports.summary.riskSummaries.performance.displayAs'),
        description: t('reports.summary.riskSummaries.performance.description'),
    }]

    return (
        <div className={`${COMPONENT_CLASS} -summary`}>
            <ReportGenericHeader companyName={data.organizationName} reportName={t('reports.summary.reportName')} />
            <div className="flex gap-6 pt-6 pb-6 px-10 sub-header" style={{ backgroundColor: "#F7F9FC" }}>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>{t('reports.usersInReport')}</p>
                    <p>{data.totalUsers}</p>
                </div>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>{t('reports.groups')}</p>
                    {data.groupNames && data.groupNames.length > 0 ?
                        <p>{data.groupNames.join(", ")}</p> :
                        <p>{data.organizationName}</p>
                    }
                </div>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>{t('reports.tags')}</p>
                    {data.tagNames && data.tagNames.length > 0 &&
                        <p>{data.tagNames.join(", ")}</p>
                    }
                </div>

            </div>
            <div className={`${COMPONENT_CLASS}__history ${COMPONENT_CLASS}__section`}>
                <h2>{t('reports.summary.wellnessHistory')}</h2>
                <div className={`${COMPONENT_CLASS}__history__wrapper`}>
                    {data.history.map((item, index) => {
                        let className = `${COMPONENT_CLASS}__history__item `;
                        const date = DateTime.fromISO(item.date);
                        if (index === 0 && data.history.length > 2) {
                            const diff = item.percent - data.history[1].percent;
                            className += diff > 0 ? "positive" : diff < 0 ? "negative" : "neutral";
                        }
                        if (item.percent <= 0) {
                            className += " not-available";
                        }

                        return (
                            <div
                                className={className}
                                key={`history-${index}`}>
                                <div className={`${COMPONENT_CLASS}__history__item__score`}>{item.percent > 0 ? item.percent : t('general.notApplicable')}</div>
                                <div className={`${COMPONENT_CLASS}__history__item__date`}>{index === 0 && date.diffNow("month").months > -1 ? t('reports.summary.current') : date.setLocale(i18n.language).toFormat("MMM").toUpperCase()}</div>
                            </div>
                        );
                    })}
                </div>
                <PillChartArray
                    forReport
                    data={data?.scoreBreakdown.map((score) => ({ range: `${score.name}`, value: score.count }))}
                />
            </div>
            <hr />
            <div className={`${COMPONENT_CLASS}__risk ${COMPONENT_CLASS}__section`}>
                <h2>{t('reports.summary.injuryRiskBreakdown')}</h2>
                <div className={`${COMPONENT_CLASS}__risk__wrapper`}>
                    {data.riskFactorCodeCounts.map((risk, index) => (
                        <div
                            className={`${COMPONENT_CLASS}__risk__item ${risk.name.toLowerCase()}`}
                            key={`risk-${index}`}>
                            <div className={`${COMPONENT_CLASS}__risk__item__count`}>{risk.count}</div>
                            <div className={`${COMPONENT_CLASS}__risk__item__name`}>
                                <p>
                                    {riskSummaries.find((r) => r.name === risk.name)?.displayAs ?? risk.name}
                                </p>
                            </div>
                            <div className={`${COMPONENT_CLASS}__risk__item__description`}>
                                <p>
                                    {riskSummaries.find((r) => r.name === risk.name)?.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <hr />
            <div className={`${COMPONENT_CLASS}__focus ${COMPONENT_CLASS}__section`}>
                <div>
                    <h2>{t('reports.summary.focusAreas')}</h2>
                    <div className={`${COMPONENT_CLASS}__focus__wrapper`}>
                        {data.focusAreas.map((focus, index) => (
                            <div
                                className={`${COMPONENT_CLASS}__focus__item`}
                                key={`focus-${index}`}>
                                <div className={`${COMPONENT_CLASS}__focus__item__count`}>{focus.count}</div>
                                <div className={`${COMPONENT_CLASS}__focus__item__name`}>{t(GroupUtil.getNameTranslationReference(focus.groupId))}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <h2>{t('reports.summary.movementFocus')}</h2>
                    <div className={`${COMPONENT_CLASS}__focus__wrapper`}>
                        {data.movementFocusAreas.map((focus, index) => (
                            <div
                                className={`${COMPONENT_CLASS}__focus__item`}
                                key={`focus-${index}`}>
                                <div className={`${COMPONENT_CLASS}__focus__item__count`}>{focus.count}</div>
                                <div className={`${COMPONENT_CLASS}__focus__item__name`}>{t(GroupUtil.getNameTranslationReference(focus.groupId))}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReportSummary;