import { useTranslation } from 'react-i18next';
import Zapier from './integrations/zapier';
import { useState } from 'react';
import { Button } from '../button/button';
import { ReactComponent as ZapierIcon } from "../../assets/icons/integrations/zapier.svg";
import { ReactComponent as FmsProIcon } from "../../assets/icons/integrations/fms-pro.svg";
import { ReactComponent as MakeIcon } from "../../assets/icons/integrations/make.svg";

interface IntegrationsProps {
}

const COMPONENT_CLASS = "c-integrations";

enum IntegrationPages {
  Zapier,
  FmsPro,
  Make,
}

const Integrations: React.FC<IntegrationsProps> = (props: IntegrationsProps) => {
  const { t } = useTranslation();
  const [specificIntegration, setSpecificIntegration] = useState<IntegrationPages>();

  const getSpecificIntegration = (integrationPage: IntegrationPages) => {
    switch (integrationPage) {
      case IntegrationPages.Zapier:
        return <Zapier />
    }
  };

  return (
    <div className={COMPONENT_CLASS}>
      {specificIntegration !== undefined
        ?
        <div>
          <div className='my-10'>
            <Button
              type="back"
              buttonText={t('buttons.btn_allIntegrations')}
              buttonStyle="secondary"
              onClick={() => setSpecificIntegration(undefined)}
            />
          </div>
          {getSpecificIntegration(specificIntegration)}
        </div>
        :
        <div>
          <h2>{t('settings.integrations.title')}</h2>
          <p className='font-light'>{t('settings.integrations.description')}</p>
          <div className='mt-10 flex gap-5'>
            <div className={`${COMPONENT_CLASS}__card`}>
              <div>
                <ZapierIcon />
              </div>
              <div className='text-base font-normal'>{t('settings.integrations.integrationNames.zapier')}</div>
              <div className='text-xs font-light'>{t('settings.integrations.integrationNames.zapier_desc')}</div>
              <Button
                type="plus"
                buttonText={t('settings.integrations.buttons.createZaps')}
                onClick={() => setSpecificIntegration(IntegrationPages.Zapier)}
              />
            </div>

            <div className={`${COMPONENT_CLASS}__card`}>
              <div>
                <FmsProIcon />
              </div>
              <div className='text-base font-normal'>{t('settings.integrations.integrationNames.fmsPro')}</div>
              <div className='text-xs font-light'>{t('settings.integrations.integrationNames.fmsPro_desc')}</div>
              <Button
                type="default"
                disabled
                buttonText={t('settings.integrations.buttons.comingSoon')}
                onClick={() => setSpecificIntegration(IntegrationPages.FmsPro)}
              />
            </div>

            <div className={`${COMPONENT_CLASS}__card`}>
              <div>
                <MakeIcon />
              </div>
              <div className='text-base font-normal'>{t('settings.integrations.integrationNames.make')}</div>
              <div className='text-xs font-light'>{t('settings.integrations.integrationNames.make_desc')}</div>
              <Button
                type="default"
                disabled
                buttonText={t('settings.integrations.buttons.comingSoon')}
                onClick={() => setSpecificIntegration(IntegrationPages.Make)}
              />
            </div>
          </div>
        </div>
      }
    </div>
  );
}
export default Integrations;
