/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReportType } from "../../models/enumerations/report-type";
import { Report } from "../../models/interfaces/report";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import ReportService from "../../utilities/services/report-service";
import { Loader } from "../loader/loader";
import ReportDataService from "../../utilities/services/report-data-service";
import ReportDataUtil from "../../utilities/report-data-util";
import { ReportAssessmentData } from "../../models/interfaces/reports/report-assessment-data";
import { ReportComplianceData } from "../../models/interfaces/reports/report-compliance-data";
import { ReportDetailData } from "../../models/interfaces/reports/report-detail-data";
import { ReportSummaryData } from "../../models/interfaces/reports/report-summary-data";
import { useTranslation } from "react-i18next";
import ReportTypes from "../../models/report-types";

const COMPONENT_CLASS = "c-report-viewer";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportViewerProps {
    reportId: string;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportViewer: React.FC<ReportViewerProps> = (props: ReportViewerProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [report, setReport] = useState<Report>();
    const [csv, setCsv] = useState<string>();
    const [fileName, setFileName] = useState<string>();
    const history = useHistory();
    const { state } = useAuthState();
    const { t } = useTranslation();

    useEffect(() => {
        const setCsvUrl = async () => {
            if (report && report.id) {
                const data = await ReportDataService.getBy([{
                    field: 'reportId',
                    operator: '==',
                    value: report?.id
                }]);

                if (data && data.length > 0) {
                    // if there is no data to export, return now and the export
                    // button will not appear
                    if (data[0].totalUsers === 0) {
                        return;
                    }
                    let genData: any;
                    switch (report.type) {
                        case ReportType.Assessment:
                            let temp1 = data[0] as unknown as ReportAssessmentData;
                            if (temp1) {
                                genData = ReportDataUtil.generateAssessment(temp1);
                                setFileName(`Assessment Report ${temp1.reportDate}.csv`);
                            }
                            break;
                        case ReportType.Compliance:
                            let temp2 = data[0] as unknown as ReportComplianceData;
                            if (temp2) {
                                genData = ReportDataUtil.generateCompliance(temp2);
                                setFileName(`Compliance Report ${temp2.reportDate}.csv`);
                            }
                            break;
                        case ReportType.Detail:
                            let temp3 = data[0] as unknown as ReportDetailData;
                            if (temp3) {
                                genData = ReportDataUtil.generateDetail(temp3);
                                setFileName(`Detail Report ${temp3.reportDate}.csv`);
                            }
                            break;
                        case ReportType.Summary:
                            let temp4 = data[0] as unknown as ReportSummaryData;
                            if (temp4) {
                                genData = ReportDataUtil.generateSummary(temp4);
                                setFileName(`Summary Report ${temp4.reportDate}.csv`);
                            }
                            break;
                        case ReportType.Score:
                            let temp5 = data[0] as unknown as ReportDetailData;
                            if (temp5) {
                                genData = ReportDataUtil.generateScore(temp5);
                                setFileName(`Score Report ${temp5.reportDate}.csv`);
                            }
                            break;
                    }
                    const blob = new Blob([genData], { type: 'text/csv;charset=utf-9,' });
                    const csvUrl = URL.createObjectURL(blob);
                    setCsv(csvUrl);
                }
            }
        }
        setCsvUrl();
    }, [report]);

    useEffect(() => {
        if (state.organization?.id == null || state.user?.id == null) {
            return;
        }

        ReportService.getSnapshot(props.reportId, (data: Report) => {
            setReport(data);

            if (!data) {
                history.push("/reports");
                return;
            }

            if (data.status === "complete" || data.status === "error") {
                setIsLoading(false);
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={COMPONENT_CLASS}>
            <Loader
                isVisible={isLoading} />
            {report &&
                <React.Fragment>
                    <div className={`${COMPONENT_CLASS}__header`}>
                        <h1>{report ? ReportTypes.ALL_TYPES_WITH_REPORT_WORD.find((type) => type.value === report.type.toString())?.label : ""}</h1>
                        {
                            (csv && fileName) &&
                            <a
                                className={`${COMPONENT_CLASS}__export`}
                                href={csv}
                                download={fileName}
                            >
                                {t('buttons.btn_exportCSV')}
                            </a>
                        }
                    </div>


                    <div className={`${COMPONENT_CLASS}__report`}>
                        {report.status === "error" && report.errorMessage &&
                            <p className="text-red italic">
                                {report.errorMessage}
                            </p>
                        }
                        {report.url &&
                            <iframe title={t('reports.report')} src={report.url} />
                        }
                    </div>
                </React.Fragment>
            }
        </div>
    );
}

export default ReportViewer;