import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './locales/en/en.json';
import jpTranslation from './locales/ja/ja.json';
import esTranslation from './locales/es/es.json';
import elTranslation from './locales/el/el.json';
import bsTranslation from './locales/bs_BA/bs_BA.json';
import koTranslation from './locales/ko/ko.json';

import moment from "moment";
import 'moment/locale/ja';
import 'moment/locale/es';
import 'moment/locale/el';
import 'moment/locale/bs';
import 'moment/locale/ko';

/**
 * Provides localization/multi-language support.
 *
 * For further details on this library, including how to configure and use it,
 * refer to: https://react.i18next.com/
 */
i18n


  /**
   * Detects the language used by the users browser. For more information, refer
   * to: https://github.com/i18next/i18next-browser-languageDetector
   */
  .use(LanguageDetector)

  /**
   * Initialize the library for use with React.
   */
  .use(initReactI18next)

  .on("languageChanged", (lng) => {
    moment.locale(lng)
  })

  /**
   * Refer to https://www.i18next.com/overview/configuration-options for a list
   * of all options.
   */
  .init({
    resources: {
      en: { translation: enTranslation },
      ja: { translation: jpTranslation },
      es: { translation: esTranslation },
      el: { translation: elTranslation },
      bs: { translation: bsTranslation },
      ko: { translation: koTranslation },
    },

    /**
     * Specifies the language to use when the user-specific language is not
     * available.
     */
    fallbackLng: ["en", "es", "ja", "el", "bs", "ko"],

    /**
     * Specifies the languages that are supported. This should be populated with
     * the languages that exist in the /public/locales folder. If this is not
     * populated, the library will attempt to load the users detected language,
     * even if it is unsupported. This would be wasteful. For example, if you
     * add French, then 'fr' would be added to the array below. If the user is
     * Spanish, it won't even attempt to load a resource for that language,
     * since only English and French would be supported below.
     */
    supportedLngs: ['en', 'el', 'ja', 'es', 'bs', 'ko'],

    /**
     * Determines whether logging of info-level messages appear in the console
     * output. This should be disabled for production purposes.
     */
    debug: false,

    /**
     * Refer to
     * https://www.i18next.com/translation-function/interpolation#all-interpolation-options
     * for more information.
     */
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
