import { useEffect, useState } from 'react';
import { Configure, connectInfiniteHits, InstantSearch, SearchBox } from 'react-instantsearch-dom';
import { useHistory } from 'react-router-dom';
import { Tag } from '../../models/interfaces/tag';
import { User } from '../../models/interfaces/user';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import searchClient, { SearchIndexes } from '../../utilities/search-client';
import TagService from '../../utilities/services/tag-service';
import UserService from '../../utilities/services/user-service';
import StringUtil from '../../utilities/string-util';
import { Utils } from '../../utilities/utils';
import { Button } from '../button/button';
import EmailList from '../email-list/email-list';
import { Loader } from '../loader/loader';
import { Modal } from '../modal/modal';
import * as TagComponent from '../tag/tag';
import { enqueueSnackbar } from 'notistack';
import { TextTypeInput } from '../forms';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "c-tag-list";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface TagListProps { }

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TagList: React.FC<TagListProps> = (props: TagListProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingTagUsers, setIsLoadingTagUsers] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [pendingTagEmails, setPendingTagEmails] = useState([] as string[]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [tagUsers, setTagUsers] = useState<User[]>([]);
    const [editTag, setEditTag] = useState<Tag>();
    const [tagToDelete, setTagToDelete] = useState<Tag>();
    const [errorMessages, setErrorMessages] = useState([] as string[]);
    const { t } = useTranslation();
    const [searchState, setSearchState] = useState({
        sortBy: SearchIndexes.usersByNameAsc
    });
    const { state } = useAuthState();
    const history = useHistory();

    const {
        register,
        setValue,
        formState: { errors },
    } = useForm({
        mode: 'onBlur', reValidateMode: 'onSubmit', defaultValues: {
            name: "", emails: undefined
        }
    });

    const handleAddTagClick = () => {
        setEditTag({ organizationId: state.organization?.id } as Tag);
        setErrorMessages([]);
        setOpen(true);
    }

    const handleDeleteTagClick = async () => {
        if (tagToDelete?.id == null) {
            return;
        }

        setIsSaving(true);

        const usersToUpdate = await UserService.getBy([{
            field: "tagIds",
            operator: "array-contains",
            value: tagToDelete.id,
        }]);

        for (const user of usersToUpdate) {
            if (!user.tagIds || user.tagIds.length === 0) {
                continue;
            }
            user.tags = user.tags?.filter(t => t.id !== tagToDelete.id) || [];
            user.tagIds = user.tags.map(t => t.id) || [];

            await UserService.update(user, state.user);
        }

        await TagService.deleteById(tagToDelete.id);

        enqueueSnackbar(t('tags.tagDeleted'), { variant: "toast", width: "450px" });
        setOpenDelete(false);
        setTagToDelete(undefined);
        setIsSaving(false);
    }

    const handleSaveTagClick = async (create: boolean) => {
        if (!editTag) {
            return;
        }
        if (!editTag.name || editTag.name.length === 0) {
            setErrorMessages([t('forms.err_noName')]);
            return;
        }

        const tag = await TagService.save(editTag, state.user);

        if (pendingTagEmails != null && pendingTagEmails.length > 0) {
            const emails = pendingTagEmails.filter((email) => email.trim().length > 0 && Utils.validateEmailAddress(email.trim()));

            if (emails.length === 0) {
                setErrorMessages([t('forms.err_noEmails')]);
            } else {
                for (const email of emails) {
                    const user = (await UserService.getBy([{
                        field: "email",
                        operator: "==",
                        value: email,
                    }], [], 1))?.[0];

                    if (user == null) {
                        continue;
                    }

                    if (user.tagIds == null) {
                        user.tagIds = [];
                    }

                    if (user.tags == null) {
                        user.tags = [];
                    }

                    user.tagIds.push(tag.id);

                    user.tags.push({
                        id: tag.id,
                        name: tag.name,
                    });

                    await UserService.update(user, state.user);
                }

                tag.userCount = await UserService.getCountBy([{
                    field: "tagIds",
                    operator: "array-contains",
                    value: tag.id,
                }]);
                await TagService.save(tag, state.user);
            }
        }

        if (create) {
            enqueueSnackbar(t('groups.edit.customization_tab.tagCreated'), { variant: "toast", width: "450px" });
        }
        else {
            enqueueSnackbar(t('groups.edit.customization_tab.tagUpdated'), { variant: "toast", width: "450px" });
        }

        setOpen(false);
        setEditTag({} as Tag);
    }

    useEffect(() => {
        if (!state.organization?.id) {
            return;
        }

        TagService.getSnapshotBy([{
            field: "organizationId",
            operator: "==",
            value: state.organization?.id,
        }], [{
            field: "name",
            direction: "asc"
        }], (tags: Tag[]) => {
            setTags(tags);
            setIsLoading(false);
        });
    }, [state]);

    useEffect(() => {
        if (!open) {
            setEditTag(undefined);
            setTagUsers([]);
            setIsLoadingTagUsers(false);
            setPendingTagEmails([]);
            setSearchState({
                sortBy: SearchIndexes.usersByNameAsc
            });
            return;
        }

        if (!editTag?.id) {
            return;
        }
        setIsLoadingTagUsers(true);

        UserService.getBy([{
            field: "tagIds",
            operator: "array-contains",
            value: editTag.id,
        }], [{
            field: "lastName",
            direction: "asc"
        }]).then((tagUsers: User[]) => {
            setTagUsers(tagUsers);
            setIsLoadingTagUsers(false);
        })
    }, [editTag?.id, open]);

    useEffect(() => {
        if (!openDelete) {
            setTagToDelete(undefined);
        }
    }, [openDelete]);

    useEffect(() => {
        setValue("name", editTag?.name || "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editTag?.name]);

    const customHits = ({
        hits,
        hasPrevious,
        refinePrevious,
        hasMore,
        refineNext,
    }: any) => {
        return (
            <div className={`${COMPONENT_CLASS}__user-list__wrapper`}>
                <div className={`${COMPONENT_CLASS}__user-list__action ${!hasPrevious ? "hidden" : ""}`}>
                    <Button
                        type='link'
                        disabled={!hasPrevious}
                        onClick={refinePrevious}
                        buttonText={t('buttons.btn_showPrev')} />

                </div>
                <ul>
                    <li className={`${COMPONENT_CLASS}__user-list__header ${COMPONENT_CLASS}__user-list__item`}>
                        <div className={`${COMPONENT_CLASS}__user-list__item__name font-bold`}>{t('general.user')}</div>
                        <div className={`${COMPONENT_CLASS}__user-list__item__email font-bold`}>{t('general.email')}</div>
                    </li>
                    {hits.map((h: any, i: number) => {
                        const cssClass = h.authenticationId == null ? "-unregistered" : "";

                        return (
                            <li
                                className={`${COMPONENT_CLASS}__user-list__item ${cssClass}`}
                                onClick={() => history.push(`/users/${h.id}`)}
                                key={`user-${i}`}>
                                <div className={`${COMPONENT_CLASS}__user-list__item__name`}>
                                    <span>
                                        {h.authenticationId == null ? t('tags.unregUser') : `${h.firstName} ${h.lastName}`}
                                    </span>
                                    {h.roles?.map((role: string, tagIndex: number) => (<TagComponent.Tag
                                        id={tagIndex.toString()}
                                        key={`role-${i}-${tagIndex}`}
                                    >{Utils.getRoleNameFromValue(role)}</TagComponent.Tag>))}
                                </div>
                                <div className={`${COMPONENT_CLASS}__user-list__item__email`}>
                                    {h.email}
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <div className={`${COMPONENT_CLASS}__user-list__action ${!hasMore ? "hidden" : ""}`}>
                    <Button
                        type='link'
                        disabled={!hasMore}
                        onClick={refineNext}
                        buttonText={t('buttons.btn_showMore')} />
                </div>
            </div>
        )
    }

    const CustomHits = connectInfiniteHits(customHits);

    return (
        <div className={COMPONENT_CLASS}>
            <Loader
                isVisible={isLoading} />
            <div className={`${COMPONENT_CLASS}__header`}>
                <div className={`${COMPONENT_CLASS}__actions`}>
                    <Button
                        type="default"
                        buttonText={t('buttons.btn_addTag')}
                        onClick={() => handleAddTagClick()} />
                </div>
            </div>
            <div className={`${COMPONENT_CLASS}__list`}>
                <h3>{t('tags.title')}</h3>
                <p className="opacity-60 mb-8 font-light text-sm">{t('tags.desc')}</p>
                {tags.map((tag, i) => (
                    <div className={`${COMPONENT_CLASS}__list__border`}>
                        <div
                            className={`${COMPONENT_CLASS}__list__item`}
                            key={`tag-${i}`}>
                            <div className={`${COMPONENT_CLASS}__list__item__data-container`}>
                                <div className={`${COMPONENT_CLASS}__list__item__title`}>
                                    {tag.name}
                                </div>
                                <div className={`${COMPONENT_CLASS}__list__item__data`}>
                                    {tag.userCount != null ? tag.userCount === 1 ? t('tags.user.singular', { numOfUsers: tag.userCount }) : t('tags.user.plural', { numOfUsers: tag.userCount }) : ""}
                                </div>
                            </div>
                            <div className={`${COMPONENT_CLASS}__list__item__actions`}>
                                <Button
                                    type="table-action"
                                    buttonStyle="white"
                                    buttonText={t('buttons.btn_edit')}
                                    onClick={(e) => {
                                        setEditTag(tag);
                                        setOpen(true);
                                        e.stopPropagation();
                                    }} />
                                <Button
                                    type="table-action"
                                    buttonStyle="white"
                                    buttonText={t('buttons.btn_delete')}
                                    onClick={(e) => {
                                        setTagToDelete(tag);
                                        setOpenDelete(true);
                                        e.stopPropagation();
                                    }} />
                            </div>
                        </div>
                    </div>
                ))}

            </div>
            <Modal
                isOpen={open}
                isLoading={isSaving || isLoadingTagUsers}
                onClose={setOpen}
                title={`${editTag?.id == null ? t('tags.edit.addTag') : t('tags.edit.editTag')}`}
                defaultModalActions={true}
                submitButtonText={`${editTag?.id == null ? t('buttons.btn_saveTag') : t('buttons.btn_saveChanges')}`}
                onSubmit={() => {
                    editTag?.id == null ? handleSaveTagClick(true) : handleSaveTagClick(false)
                }}
                onCancel={() => setOpen(false)}>
                <div>
                    <TextTypeInput
                        id='name'
                        type='text'
                        inputClassName='appearance-none border border-gray rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline'
                        label={t('tags.name')}
                        registerHook={register}
                        registerOptions={{
                            value: editTag?.name || "",
                            onChange: (e) => setEditTag(Object.assign({}, editTag, { name: e.target.value })),
                            required: true,
                        }}
                        errorState={errors.name}
                    />
                </div>
                {(editTag?.userCount || 0) > 0 &&
                    <div className={`${COMPONENT_CLASS}__user-list`}>
                        <label>
                            {t('tags.edit.usersInTag')}
                        </label>
                        {open &&
                            <InstantSearch
                                indexName={SearchIndexes.usersByNameAsc}
                                searchClient={searchClient}
                                searchState={searchState}
                                onSearchStateChange={searchState => {
                                    setSearchState(searchState);
                                }}>
                                <Configure
                                    filters={`organizationId:${state.organization?.id} AND tagIds:${editTag?.id}`}
                                    hitsPerPage={50}
                                />
                                <SearchBox
                                    autoFocus
                                    className={`${COMPONENT_CLASS}__search`}
                                    searchAsYouType={true}
                                    showLoadingIndicator={true}
                                    loadingIndicator={<Loader isVisible={true} />}
                                    translations={{
                                        placeholder: t('general.search')
                                    }}
                                />
                                <CustomHits />
                            </InstantSearch>
                        }
                    </div>
                }
                <div className={`${COMPONENT_CLASS}__user-emails`}>
                    <label>
                        {t('tags.edit.addUsersToTag')}
                    </label>
                    <div className={errorMessages.length > 0 ? `c-user-list__fieldgroup-error` : `c-user-list__fieldgroup`}>
                        {!isLoadingTagUsers &&
                            <EmailList
                                existingUsers={tagUsers && tagUsers.length > 0 ? tagUsers.filter(user => user.id != null).map(user => user.id!) : undefined}
                                onEmailsAdded={(emails) => {
                                    emails = StringUtil.getUniqueValues(emails, true);
                                    let pendingEmails = [...new Set(pendingTagEmails.concat(emails))];
                                    if (tagUsers && tagUsers.length > 0) {
                                        const tagEmails = tagUsers.map(user => user.email).filter(email => email && email.trim().length > 0);
                                        pendingEmails = pendingEmails.filter(email => !StringUtil.existsInArray(email, tagEmails, true));
                                    }
                                    setPendingTagEmails(pendingEmails);
                                    setErrorMessages([]);
                                }}
                                onEmailsError={(errors) => { errorMessages.length = 0; errorMessages.push(...new Set(errorMessages.concat(errors))); setErrorMessages(errorMessages); }}
                                enableSuggestions
                            />
                        }
                        { // if
                            errorMessages.length > 0 && errors.emails &&
                            <div className={`c-user-list__email-errors`}>
                                {errorMessages.map((error, i) => <div className={`c-user-list__email-errors__item`} key={`error-${i}`}>{error}</div>)}
                            </div>
                        }
                    </div>
                </div>

                {
                    pendingTagEmails != null && pendingTagEmails.length > 0 &&
                    <div className={`${COMPONENT_CLASS}__email-invites`}>
                        <div>
                            {pendingTagEmails.map((email, i) => (<TagComponent.Tag
                                id={i.toString()}
                                onCloseClick={(index) => {
                                    const emails = [...pendingTagEmails];
                                    emails.splice(parseInt(index), 1);
                                    setPendingTagEmails(emails);
                                }}
                                key={`email-${i}`}
                            >{email}</TagComponent.Tag>))}
                        </div>
                    </div>
                }

            </Modal >
            <Modal
                isOpen={openDelete}
                isLoading={isSaving}
                onClose={setOpenDelete}
                title={t('tags.delete_modal.title')}
                defaultModalActions={true}
                submitButtonText={t('buttons.btn_deleteTag')}
                onSubmit={() => handleDeleteTagClick()}
                onCancel={() => setOpenDelete(false)}>
                <div>
                    <p>
                        {t('tags.delete_modal.del_confirm', { tagName: tagToDelete?.name })}
                    </p>
                </div>
            </Modal>
        </div >
    );
}

export default TagList;