import React from "react";
import { useTranslation } from 'react-i18next';
export interface PillChartTitleProps {
    forReport?: boolean;
    text?: string;
}

const PillChartTitle: React.FC<PillChartTitleProps> = (props) => {
    const {
        forReport,
        text,
    } = props;
    const { t } = useTranslation();


    let titleText = text ? text : t('dashboard.scoreBreakOrg');

    if (forReport) titleText = t('dashboard.currentScoreBreak');

    if (forReport === true) {
        return (
            <h3
                style={{
                    textAlign: 'left',
                    marginBottom: '0.75rem',
                    fontSize: '1rem',
                    lineHeight: '1.5rem',
                    fontWeight: '300',
                }}
            >
                {titleText}
            </h3>
        );
    }

    return (
        <h3
        >
            {titleText}
        </h3>
    );
};



export default PillChartTitle;