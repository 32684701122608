/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import MskScoreService from "../../utilities/services/msk-score-service";
import UserService from "../../utilities/services/user-service";
import { ReportType } from "../../models/enumerations/report-type";
import { DateTime } from "luxon";
import ReportService from "../../utilities/services/report-service";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { Report } from "../../models/interfaces/report";
import moment from "moment";
import { Button } from "../button/button";
import { MskScoreLevels } from "../../models/enumerations/msk-score-levels";
import AssessmentBarChart from "../assessment-bar-chart/assessment-bar-chart";
import { useTranslation } from "react-i18next";

const COMPONENT_CLASS = "c-user-profile-history";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileHistoryProps {
    allMskScores: MskScore[];
    setAllMskScores: Function;
    user: User;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileHistory: React.FC<UserProfileHistoryProps> = (props: UserProfileHistoryProps) => {
    const { t } = useTranslation();
    const { user, allMskScores, setAllMskScores } = props;
    const [validMskScores, setValidMskScores] = useState<MskScore[]>();
    const [downloadingReportSnackBar, setDownloadingReportSnackBar] = useState<any>();
    const [, setReportError] = useState<string | undefined>();

    useEffect(() => {
        const getAllMskScores = async () => {
            if (!allMskScores) {
                const mskScores = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }])

                setAllMskScores(mskScores);
            }
        }
        getAllMskScores();
    }, [])

    useEffect(() => {
        const initialLoad = () => {
            const tempValidMskScores = [];
            for (const mskScore of allMskScores) {
                if (checkMskScore(mskScore)) {
                    tempValidMskScores.push(mskScore);
                }
            }
            setValidMskScores(tempValidMskScores);
        }

        if (allMskScores) {
            initialLoad();
        }
    }, [allMskScores])

    const checkMskScore = (latestMskData?: MskScore) => {
        if (!latestMskData) {
            return false;
        }
        if (latestMskData.movementScore &&
            latestMskData.lifestyleScore &&
            "frontToeLeft" in latestMskData.movementScore.toeTouchScore &&
            "backToeLeft" in latestMskData.movementScore.toeTouchScore &&
            "frontToeRight" in latestMskData.movementScore.toeTouchScore &&
            "backToeRight" in latestMskData.movementScore.toeTouchScore &&
            "painSeverity" in latestMskData.movementScore.toeTouchScore &&
            "paperPassLeft" in latestMskData.movementScore.shoulderMobilityScore &&
            "paperPassRight" in latestMskData.movementScore.shoulderMobilityScore &&
            "fingerTipsLeft" in latestMskData.movementScore.shoulderMobilityScore &&
            "fingerTipsRight" in latestMskData.movementScore.shoulderMobilityScore &&
            "painSeverity" in latestMskData.movementScore.shoulderMobilityScore &&
            "feetTogetherLeft" in latestMskData.movementScore.rotationScore &&
            "feetTogetherRight" in latestMskData.movementScore.rotationScore &&
            "rightFootBackLeft" in latestMskData.movementScore.rotationScore &&
            "rightFootBackRight" in latestMskData.movementScore.rotationScore &&
            "painSeverity" in latestMskData.movementScore.rotationScore &&
            "squat" in latestMskData.movementScore.squatScore &&
            "fingerTips" in latestMskData.movementScore.squatScore &&
            "fists" in latestMskData.movementScore.squatScore &&
            "painSeverity" in latestMskData.movementScore.squatScore &&
            "oneFootRight" in latestMskData.movementScore.balanceScore &&
            "oneFootLeft" in latestMskData.movementScore.balanceScore &&
            "attempts" in latestMskData.movementScore.balanceScore.twoFeetLeft &&
            "success" in latestMskData.movementScore.balanceScore.twoFeetLeft &&
            "attempts" in latestMskData.movementScore.balanceScore.twoFeetRight &&
            "success" in latestMskData.movementScore.balanceScore.twoFeetRight &&
            "attempts" in latestMskData.movementScore.balanceScore.threeFeetLeft &&
            "success" in latestMskData.movementScore.balanceScore.threeFeetRight &&
            "painSeverity" in latestMskData.movementScore.balanceScore &&
            "shoulderClearingPainSeverity" in latestMskData.movementScore &&
            "spineClearingPainSeverity" in latestMskData.movementScore &&
            latestMskData.lifestyleScore.bodyCompositionScore &&
            "bmi" in latestMskData.lifestyleScore.bodyCompositionScore &&
            latestMskData.lifestyleScore.breathingScore &&
            "breathHoldTime" in latestMskData.lifestyleScore.breathingScore &&
            "breathingQuestionnaireScore" in latestMskData.lifestyleScore.breathingScore &&
            "isSmoking" in latestMskData.lifestyleScore.breathingScore &&
            latestMskData.lifestyleScore.injuryHistoryScore &&
            "previousInjury" in latestMskData.lifestyleScore.injuryHistoryScore &&
            "currentPain" in latestMskData.lifestyleScore.injuryHistoryScore &&
            "seenPhysician" in latestMskData.lifestyleScore.injuryHistoryScore &&
            "recoveryScore" in latestMskData.lifestyleScore.injuryHistoryScore &&
            latestMskData.lifestyleScore.sleepScore &&
            "psqiScore" in latestMskData.lifestyleScore.sleepScore
        ) {
            return true;
        }
        return false;
    }

    const handleDownloadReport = async (emailResults: boolean, mskScore: MskScore) => {
        if (!user.id) return;
        const userInFirebase = await UserService.get(user.id);
        if (!userInFirebase || !userInFirebase.organizationId) return;
        const latestOrgId = userInFirebase.organizationId;
        const userId = userInFirebase.id;

        if (!userId) {
            return;
        }
        let type = ReportType.Wellness
        const date = DateTime.utc().toISO();
        const report = await ReportService.getBy(
            [
                {
                    field: "userId",
                    operator: "==",
                    value: userId,
                },
                {
                    field: "organizationId",
                    operator: "==",
                    value: latestOrgId,
                },
                {
                    field: "status",
                    operator: "==",
                    value: "complete",
                },
                {
                    field: "type",
                    operator: "==",
                    value: type,
                },
                {
                    field: "expiration",
                    operator: ">",
                    value: date,
                },
                {
                    field: "mskScoreId",
                    operator: "==",
                    value: mskScore.id
                }
            ], [
            {
                field: "expiration",
                direction: "desc",
            },
            {
                field: "updated",
                direction: "desc",
            }]);

        if (report.length <= 0) {
            setDownloadingReportSnackBar(enqueueSnackbar(t('general.reportDownload.pleaseWait'), { variant: "info", autoHideDuration: 30000 }));
            handleGenerateWellnessReport(userId, latestOrgId, type, mskScore);
            return;
        }

        if (!report[0].id) {
            return;
        }

        const reportUnsubscribe = ReportService.getSnapshot(
            report[0].id,
            (r: Report) => {
                if (r.status === "complete" && r.url) {
                    reportUnsubscribe();

                    //Loads in the System browser
                    closeSnackbar(downloadingReportSnackBar);
                    window.open(r.url);
                }
                if (r.status === "error") {
                    closeSnackbar(downloadingReportSnackBar);
                    setReportError(r.errorMessage);
                    reportUnsubscribe();
                }
            }
        );
    };

    const handleGenerateWellnessReport = async (userId: string, organizationId: string, type: number, mskScore: MskScore) => {
        const date = DateTime.now().toISODate();

        const report: Report = {
            dateEnd: date,
            dateStart: date,
            emailResults: false,
            organizationId: organizationId,
            status: "scheduled",
            type: type,
            userId: userId,
            mskScoreId: mskScore.id,
        };

        const user = await UserService.get(userId);

        const scheduledReport = await ReportService.save(
            report,
            user
        );

        if (!scheduledReport.id) {
            setReportError(
                t('general.reportDownload.tryAgain')
            );
            return;
        }

        const reportUnsubscribe = ReportService.getSnapshot(
            scheduledReport.id,
            (r: Report) => {
                if (r.status === "complete" && r.url) {
                    reportUnsubscribe();

                    //Loads in the System browser
                    window.open(r.url);
                }
                if (r.status === "error") {
                    setReportError(r.errorMessage);
                    reportUnsubscribe();
                }
            }
        );
    };

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <table
                role={"table"}
                className={"dark list__table-container"}
                data-testid="deeplinks-list">
                <thead className="hide-on-mobile">
                    <tr className="uppercase">
                        <th>{t('userProfile.date')}</th>
                        <th>{t('userProfile.wellnessScore')}</th>
                    </tr>
                </thead>
                <tbody>
                    {validMskScores?.map((mskScore, index) => {
                        return (
                            <tr
                                className="table-row-container"
                                key={index}
                            >
                                <td>
                                    <strong className="show-on-mobile mr-1 uppercase">{t('userProfile.dateColon')}</strong>
                                    <span className="text-sm font-medium">
                                        {moment(mskScore.created?.seconds ? mskScore.created?.seconds * 1000 : NaN).format("MMM DD, YYYY")}
                                    </span>
                                </td>
                                <td>
                                    <strong className="show-on-mobile mr-1 uppercase">{t('userProfile.currentColon')}</strong>
                                    <div className="flex items-center gap-4">
                                        <span className={`${COMPONENT_CLASS} ${mskScore.percentage <= MskScoreLevels.Low ? "text-red-dark" : ""} text-xl font-bold leading-8 min-w-14 max-w-fit text-right`}>{mskScore.percentage}</span>
                                        <div style={{ width: "250px" }}>
                                            <AssessmentBarChart percentage={mskScore.percentage || 0} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flex justify-end">
                                        <Button
                                            type="default"
                                            buttonText={t('buttons.btn_downloadReport')}
                                            onClick={() => handleDownloadReport(false, mskScore)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default UserProfileHistory;
