import React, { useEffect, useState } from "react";
import { TabsObject } from "../../models/interfaces/tabs-object";
import { useIntercom } from "react-use-intercom";
import { ReactComponent as ExclamationIcon } from "../../assets/icons/solid/exclamation.svg";
//import { useTranslation } from 'react-i18next';
import { Tabs } from "../../models/enumerations/tabs";

interface PageTabsProps {
    components: TabsObject<any>[],
    initialTab: number;
    highlightLineId?: string; // It's needed in case there are multiple PageTabs on one page
    showRoundTabs?: boolean;
    hideTabs?: boolean;
}

/**
 * A small badge representing the status of an organization.
 */
export const PageTabs: React.FC<PageTabsProps> = (props) => {
    const { trackEvent } = useIntercom();
    const params = new URLSearchParams(window.location.search);
    const active = params.get("active");
    //const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(active ? Number(active) : props.initialTab ? props.initialTab : props.components[0].tab);
    const labelRefs: { [key: string]: React.RefObject<HTMLLabelElement | null> } = {};
    const handleTabClick = (tab: Tabs) => {
        setActiveTab(tab);
        // This isn't universal and was used for the first iteration of intercom events
        // When we do more intercom events, we need to compartmentalize these events.
        if (tab === Tabs.HighRiskRecommendation) {
            trackEvent('high-risk-rec')
        }
        else if (tab === Tabs.Billing) {
            trackEvent('billing-page')
        }
        else if (tab === Tabs.Notifications) {
            trackEvent('notifications')
        }
        else if (tab === Tabs.AddOns) {
            trackEvent('add-ons')
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateLineWidth = () => {
        const tabLabel = labelRefs[activeTab]?.current;
        const lineWidth = tabLabel ? tabLabel.getBoundingClientRect().width : 0;
        const leftPosition = tabLabel ? tabLabel.offsetLeft : 0;
        const highlightLine = document.getElementById(props.highlightLineId ? props.highlightLineId : 'highlight-line');
        if (highlightLine) {
            highlightLine.style.width = `${lineWidth}px`;
            highlightLine.style.left = `${leftPosition}px`;
        }
    }


    useEffect(() => {
        updateLineWidth();
    }, [activeTab, updateLineWidth]);

    return (
        <>
            <div className={`c-tabs-container ${props.showRoundTabs ? "round-tabs" : ""}`}>
                {!props.hideTabs &&
                    <div className="c-tabs">
                        {props.components.map(({ tab, tabLabel, showExclamation }) => (
                            <div
                                key={tab}
                                className={`c-tab-label ${activeTab === tab ? 'active' : ""}`}
                                onClick={() => handleTabClick(tab)}
                            >
                                <label ref={(ref) => (labelRefs[tab] = { current: ref })} id={tab.toString()}>
                                    {showExclamation && <div className="icon icon__20px"><ExclamationIcon /></div>}{tabLabel}
                                </label>
                            </div>
                        ))}
                    </div>
                }

                {!props.showRoundTabs &&
                    <div className="c-highlight-container">
                        <div className="c-subline"></div>
                        <div className="c-highlight-line" id={props.highlightLineId ? props.highlightLineId : "highlight-line"}></div>
                    </div>
                }
            </div>
            <div className="c-tab-content mt-4">
                {props.components.map(({ component: Component, tab, props }) => (
                    activeTab === tab && <Component {...props} />
                ))}
            </div>
        </>
    )
};
