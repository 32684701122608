import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

export interface InactivityMessageProps {
    handleLogout: Function,
    isModalOpen: boolean;
}

/**
 * Represents the message that is shown when a Inactivity error occurs.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const InactivityMessage: React.FC<InactivityMessageProps> = (props) => {
    const { handleLogout, isModalOpen } = props;
    const [timeRemaining, setTimeRemaining] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        let countdownInterval: NodeJS.Timeout | null = null;

        if (isModalOpen) {
            let targetTime = parseInt(localStorage.getItem("logoutWarningTimestamp") || "0", 10);

            // If logoutWarningTimestamp doesn't exist, set it to 15 minutes from now
            if (!targetTime || targetTime < Date.now()) {
                targetTime = Date.now() + 15 * 60 * 1000; // 15 minutes
                localStorage.setItem("logoutWarningTimestamp", targetTime.toString());
            }

            const updateCountdown = () => {
                const remainingTime = targetTime - Date.now();
                setTimeRemaining(remainingTime > 0 ? remainingTime : 0);

                // Trigger logout if time is up
                if (remainingTime <= 0 && countdownInterval) {
                    clearInterval(countdownInterval);
                    handleLogout();
                }
            };

            // Start the countdown interval
            const startCountdown = () => {
                // Update countdown immediately and start interval
                updateCountdown();
                countdownInterval = setInterval(updateCountdown, 1000);
            };

            startCountdown();

            // Clear interval on cleanup
            return () => {
                if (countdownInterval) clearInterval(countdownInterval);
            };
        }
    }, [isModalOpen, handleLogout]);

    const formatTimeRemaining = (milliseconds: number) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(totalSeconds / 60).toString().padStart(2, '0');
        const seconds = (totalSeconds % 60).toString().padStart(2, '0');
        return { minutes, seconds };
    };

    const { minutes, seconds } = formatTimeRemaining(timeRemaining);


    const message = t('inactivity.message');

    return (
        <div className="modal-message" style={{}}>
            <p className="block" style={{ textAlign: 'center', margin: '0 81px 0 81px' }}>{message}
                <span style={{ marginLeft: "0.25ch", width: "2ch", fontWeight: "700", display: "inline-block" }}>
                    {minutes}
                </span>
                <span style={{ marginLeft: "0.25ch", fontWeight: "700" }}>{t('general.time.min_low')}</span>
                <span style={{ marginLeft: "0.25ch", width: "2ch", fontWeight: "700", display: "inline-block" }}>
                    {seconds}
                </span>
                <span style={{ marginLeft: "0.25ch", fontWeight: "700" }}>{t('general.time.sec_low')}</span>
            </p>
        </div>

    );
};

export default InactivityMessage;

