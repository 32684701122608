import { useHistory, useParams } from "react-router";
import BaseCardLayout from "../../components/base-card-layout/base-card-layout";
import { Button } from '../../components/button/button';
import logo from "../../assets/images/symmio-logos/symmio-logo-text-white-side.png"
import headerImage from "../../assets/images/header-landing.png"
import landingMskScoreImage from "../../assets/images/landing-msk-score.png"
import landingFocusAreaImage from "../../assets/images/landing-focus-area.png"
import landingImprovementPlanImage from "../../assets/images/landing-improvement-plan.png"
import { useEffect, useState } from "react";
import DeeplinkService from "../../utilities/services/deeplink-service";
import { DateTime } from "luxon";
import { Deeplink } from "../../models/interfaces/deeplink";
import { DeeplinkStatus } from "../../models/enumerations/deeplink-status";
import OrganizationService from "../../utilities/services/organization-service";
import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import errorMessageLogo from "../../assets/images/symmio-logos/symmio-logo-horizontal-dark.png";
import { useTranslation } from 'react-i18next';
import { LanguageSelectorUserMenu } from "../../components/language-selector-user-menu/language-selector-user-menu";

export interface LandingPageProps {

}

interface LandingPageParams {
    id: string;
}

const COMPONENT_CLASS = "p-landing";

const LandingPage: React.FunctionComponent<LandingPageProps> = () => {
    const { id } = useParams<LandingPageParams>();
    const history = useHistory();
    const { t } = useTranslation();
    const [isDeeplinkValid, setIsDeeplinkValid] = useState(true);
    const [linkErrorMessageHeader, setLinkErrorMessageHeader] = useState(t('pages.landingPage.errors.default_head'));
    const [linkErrorMessageBody, setLinkErrorMessageBody] = useState(t('pages.landingPage.errors.default_body'));

    useEffect(() => {
        const getDeeplink = async () => {
            let fetchedDeeplink: Deeplink | null = null;

            try {
                fetchedDeeplink = await DeeplinkService.get(id);
            } catch (err) {
                console.error("Cannot fetch the deeplink ID");
                setIsDeeplinkValid(false);
            } finally {
                await checkDeeplinkValidity(fetchedDeeplink);
            }
        }

        getDeeplink();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const checkDeeplinkValidity = async (deeplink: Deeplink | null) => {
        const nowUtc = DateTime.now().toUTC();

        if (!deeplink) {
            setIsDeeplinkValid(false);
            return;
        }

        const startDate = deeplink.startDate ? DateTime.fromISO(deeplink.startDate) : null;
        const endDate = deeplink.endDate ? DateTime.fromISO(deeplink.endDate) : null;

        const errorFutureHeader = t('pages.landingPage.errors.future_head');
        const errorFutureBody = t('pages.landingPage.errors.future_body', { startDate: startDate?.toLocaleString(DateTime.DATE_FULL) });

        const errorExpiredHeader = t('pages.landingPage.errors.expired_head');
        const errorExpiredBody = t('pages.landingPage.errors.expired_body');

        const errorInactiveHeader = t('pages.landingPage.errors.inactive_head');
        const errorInactiveBody = t('pages.landingPage.errors.inactive_body');

        const errorLimitHeader = t('pages.landingPage.errors.limit_head');
        const errorLimitBody = t('pages.landingPage.errors.limit_body');

        const errorOrganizationLimitHeader = t('pages.landingPage.errors.limit_head');
        const errorOrganizationLimitBody = t('pages.landingPage.errors.orgLimit_body');

        const errorLicenseLimitHeader = t('pages.landingPage.errors.limit_head');
        const errorLicenseLimitBody = t('pages.landingPage.errors.licenseLimit_body');


        // If start date is a future date
        if (startDate && nowUtc <= startDate.toUTC().startOf("day")) {
            setLinkErrorMessageHeader(errorFutureHeader);
            setLinkErrorMessageBody(errorFutureBody);
            setIsDeeplinkValid(false);
            return;
        }

        // If end date has passed
        else if (endDate && nowUtc >= endDate.toUTC().endOf("day")) {
            setLinkErrorMessageHeader(errorExpiredHeader);
            setLinkErrorMessageBody(errorExpiredBody);
            setIsDeeplinkValid(false);
            return;
        }

        // If the deeplink is not active
        else if (deeplink.status !== DeeplinkStatus.Active) {
            setLinkErrorMessageHeader(errorInactiveHeader);
            setLinkErrorMessageBody(errorInactiveBody);
            setIsDeeplinkValid(false);
            return;
        }

        // If the link has reached its usage limit
        else if (deeplink.used && deeplink.limit && deeplink.used >= deeplink.limit) {
            setLinkErrorMessageHeader(errorLimitHeader);
            setLinkErrorMessageBody(errorLimitBody);
            setIsDeeplinkValid(false);
            return;
        }

        else if (deeplink.organizationId) {
            const org = await OrganizationService.get(deeplink.organizationId);

            // If the organization has reached its user limit
            if (org && org.userLimit && org.userCount && org.userCount >= org.userLimit) {
                setLinkErrorMessageHeader(errorOrganizationLimitHeader);
                setLinkErrorMessageBody(errorOrganizationLimitBody);
                setIsDeeplinkValid(false);
                return;
            }

            // If the organization has reached its license limit
            else if (deeplink.linkType === SymmioAccessType.AppLicense && org && org.mySymmioLicense && org.mySymmioLicenseCount && org.mySymmioLicenseCount >= org.mySymmioLicense) {
                setLinkErrorMessageHeader(errorLicenseLimitHeader);
                setLinkErrorMessageBody(errorLicenseLimitBody);
                setIsDeeplinkValid(false);
                return;
            }
        }

        setIsDeeplinkValid(true);
    }

    const errorMessage = (
        <BaseCardLayout>
            <div className={`${COMPONENT_CLASS}__error`}>
                <div className={`${COMPONENT_CLASS}__error-content-container`}>
                    <div className={`${COMPONENT_CLASS}__error-image-container`}>
                        <img src={errorMessageLogo} alt="Symmio Logo" />
                    </div>

                    <div className={`${COMPONENT_CLASS}__error-message-container`}>
                        <h1>{linkErrorMessageHeader}</h1>
                        <p className="whitespace-pre-line" dangerouslySetInnerHTML={{ __html: linkErrorMessageBody }}></p>
                    </div>
                </div>
            </div>
        </BaseCardLayout>
    );

    const assessmentButton = (
        <Button
            type="default"
            buttonText={t('buttons.btn_startAssessment')}
            onClick={() => history.push(`/link-account-creation/${id}`)} />
    );

    return (
        <>
            <div className="user-menu">
                <LanguageSelectorUserMenu userPortal={false} />
            </div>
            <div className={`${COMPONENT_CLASS}`}>
                {isDeeplinkValid
                    ?
                    <BaseCardLayout>
                        <header>
                            <div className={`${COMPONENT_CLASS}__logo`}>
                                <img src={logo} alt="Symmio Logo" />
                            </div>
                            <div className={`${COMPONENT_CLASS}__header`}>
                                <img src={headerImage} alt="Header" />
                            </div>
                        </header>
                        <main>
                            <div className={`${COMPONENT_CLASS}__welcome-text-container`}>
                                <h1>{t('pages.landingPage.header')}</h1>
                                <p dangerouslySetInnerHTML={{ __html: t('pages.landingPage.body') }}>
                                </p>
                                <p>
                                    <span className={`${COMPONENT_CLASS}__paragraph-header`}>
                                        {t('pages.landingPage.assessments')}
                                    </span>
                                    <br />
                                    {t('assessments.movement')}
                                    <br />
                                    {t('assessments.lifestyle')}
                                </p>
                                <p>
                                    <span className={`${COMPONENT_CLASS}__paragraph-header`}>
                                        {t('assessmentIntro.duration')}
                                    </span>
                                    <br />
                                    {t('assessmentIntro.duration_desc')}
                                </p>
                                {assessmentButton}
                            </div>
                            <div className={`${COMPONENT_CLASS}__cards-container`}>
                                <div className={`${COMPONENT_CLASS}__card`}>
                                    <img className={`${COMPONENT_CLASS}__card-image`} src={landingMskScoreImage} alt="MSK Score" />
                                    <div className={`${COMPONENT_CLASS}__card-text`}>
                                        <h4>{t('pages.landingPage.wellnessScore')}</h4>
                                        <p>{t('pages.landingPage.wellnessScore_desc')}</p>
                                    </div>
                                </div>
                                <div className={`${COMPONENT_CLASS}__card right`}>
                                    <div className={`${COMPONENT_CLASS}__card-text`}>
                                        <h4>{t('pages.landingPage.identifyPriorities')}</h4>
                                        <p>{t('pages.landingPage.identifyPriorities_desc')}</p>
                                    </div>
                                    <img className={`${COMPONENT_CLASS}__card-image`} src={landingFocusAreaImage} alt="Assessment Score" />
                                </div>
                                <div className={`${COMPONENT_CLASS}__card`}>
                                    <img className={`${COMPONENT_CLASS}__card-image`} src={landingImprovementPlanImage} alt={t('focus.focusAreas')} />
                                    <div className={`${COMPONENT_CLASS}__card-text`}>
                                        <h4>{t('pages.landingPage.startYourJourney')}</h4>
                                        <p>{t('pages.landingPage.startYourJourney_desc')}</p>
                                    </div>
                                </div>
                            </div>
                        </main>
                        <footer>
                            <div className={`${COMPONENT_CLASS}__welcome-text-container`}>
                                <h4>
                                    {t('pages.landingPage.discoverPotential')}
                                </h4>
                                <p>
                                    <span className={`${COMPONENT_CLASS}__paragraph-header`}>
                                        {t('pages.landingPage.assessments')}
                                    </span>
                                    <br />
                                    {t('assessments.movement')}
                                    <br />
                                    {t('assessments.lifestyle')}
                                </p>
                                <p>
                                    <span className={`${COMPONENT_CLASS}__paragraph-header`}>
                                        {t('assessmentIntro.duration')}
                                    </span>
                                    <br />
                                    {t('assessmentIntro.duration_desc')}
                                </p>
                                {assessmentButton}
                            </div>
                        </footer>
                    </BaseCardLayout >
                    :
                    errorMessage
                }
            </div >
        </>
    );
};

export default LandingPage;
