import i18n from "../../i18n";

export enum OrganizationType {
    Paid,
    Trial,
}

export const OrganizationTypeLabel = {
    [OrganizationType.Paid]: i18n.t('general.plan.paid'),
    [OrganizationType.Trial]: i18n.t('general.plan.trial'),
}