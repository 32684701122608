/* eslint-disable react-hooks/exhaustive-deps */
import { Menu, Transition } from '@headlessui/react';
import { UserCircleIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import getFirebase from '../../utilities/firebase';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import UserService from '../../utilities/services/user-service';
import { User } from '../../models/interfaces/user';
import UserUtil from '../../utilities/user-util';
import UserRoles from '../../models/user-roles';
import { useTranslation } from 'react-i18next';

const { auth } = getFirebase();

const COMPONENT_CLASS = "c-user-menu";

export interface AssessmentsUserMenuProps {
    profileImage?: boolean;
}

const AssessmentsUserMenu: React.FC<AssessmentsUserMenuProps> = (props) => {
    const { state, setState } = useAuthState();
    const history = useHistory();
    const [user, setUser] = useState<User>();
    const [mouseOverButton, setMouseOverButton] = useState(false)
    const [mouseOverMenu, setMouseOverMenu] = useState(false)
    const { t } = useTranslation();
    useEffect(() => {
        const findUserByEmail = async () => {
            const userByEmail = await UserService.findFirstBy([{
                field: "email",
                operator: "==",
                value: state.user?.email,
            }]);
            setUser(userByEmail);
        }

        findUserByEmail();

    }, [])

    const handleSignoutClick = () => {
        auth.signOut();
        setState((state) => ({ ...state, ...{ authenticated: false, user: null } }));
    }

    const timeoutDuration = 500
    let timeoutButton: string | number | NodeJS.Timeout | undefined;
    let timeoutMenu: string | number | NodeJS.Timeout | undefined;

    const onMouseEnterButton = () => {
        clearTimeout(timeoutButton)
        setMouseOverButton(true)
    }
    const onMouseLeaveButton = () => {
        timeoutButton = setTimeout(() => setMouseOverButton(false), timeoutDuration)
    }

    const onMouseEnterMenu = () => {
        clearTimeout(timeoutMenu)
        setMouseOverMenu(true)
    }
    const onMouseLeaveMenu = () => {
        timeoutMenu = setTimeout(() => setMouseOverMenu(false), timeoutDuration)
    }
    const show = (mouseOverMenu || mouseOverButton)

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <Menu as="div">
                {({ open }) => (
                    <>
                        <div onMouseEnter={onMouseEnterButton}
                            onMouseLeave={onMouseLeaveButton}>
                            <Menu.Button className={`${COMPONENT_CLASS}__button`}>
                                <span className="sr-only">{t('notifications.general.openUserMenu')}</span>
                                <div className={`${COMPONENT_CLASS}__user`}>
                                    <div>{user?.firstName ? user?.firstName : t('general.user')} {user?.lastName ? user?.lastName : ""}</div>
                                    <ChevronDownIcon />
                                </div>
                                { // if
                                    state.user?.profileImage && (props.profileImage === undefined || props.profileImage === true) &&
                                    <img src={state.user.profileImage} alt="User Profile" />
                                }
                                { // if
                                    !state.user?.profileImage && (props.profileImage === undefined || props.profileImage === true) &&
                                    < UserCircleIcon aria-hidden="true" />
                                }
                            </Menu.Button>
                        </div>
                        <Transition
                            show={show}
                        // enter="transition ease-out duration-100"
                        // enterFrom="transform opacity-0 scale-95"
                        // enterTo="transform opacity-100 scale-100"
                        // leave="transition ease-in duration-75"
                        // leaveFrom="transform opacity-100 scale-100"
                        // leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className={`${COMPONENT_CLASS}__menu`}
                                onMouseEnter={onMouseEnterMenu}
                                onMouseLeave={onMouseLeaveMenu}
                            >
                                <Menu.Item>
                                    <button
                                        onClick={() => history.push("/manage-profile")}
                                        className={`${COMPONENT_CLASS}__item`}>
                                        {t('userMenu.item_account')}
                                    </button>
                                </Menu.Item>
                                {(UserUtil.belongsToRole(user, UserRoles.CLIENT_ID) && state.user?.subscription?.subscriptionId) && <Menu.Item>
                                    <button
                                        onClick={() => history.push("/plans-and-payment")}
                                        className={`${COMPONENT_CLASS}__item`}>
                                        {t('userMenu.item_subscription')}
                                    </button>
                                </Menu.Item>}
                                <Menu.Item>
                                    <button
                                        onClick={() => window.open("https://help.symmio.com/en/", '_blank')}
                                        className={`${COMPONENT_CLASS}__item`}>
                                        {t('userMenu.item_helpCenter')}
                                    </button>
                                </Menu.Item>
                                <Menu.Item>
                                    <button
                                        onClick={() => handleSignoutClick()}
                                        className={`${COMPONENT_CLASS}__item`}>
                                        {t('userMenu.item_logOut')}
                                    </button>
                                </Menu.Item>
                                {(UserUtil.isManagerOrTeamMember(user)
                                    || UserUtil.isAdmin(user)
                                    || UserUtil.isAccountHolder(user)
                                    || user?.isSuperAdmin) && <Menu.Item>
                                        <button className={`c-user-menu__toggle_item`}>
                                            <div onClick={() => history.push("/dashboard")} style={{ transform: "rotate(90deg)" }} className={`${COMPONENT_CLASS}__toggle__wrapper -checked`}>
                                                <input
                                                    type="checkbox"
                                                    id="wellnessToggle"
                                                    className="sr-only"
                                                    checked={true}
                                                />
                                                <div className={`c-user-menu__toggle__background`}></div>
                                                <div className={`c-user-menu__toggle__dot__checked`}></div>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginRight: '0.1rem', marginTop: '0.3rem' }}>
                                                <div
                                                    onClick={() => history.push("/dashboard")}
                                                >
                                                    {t('userMenu.item_wellness')}
                                                </div>
                                                <div
                                                    onClick={() => history.push("/dashboard")}
                                                >
                                                    {t('userMenu.item_myAssessment')}
                                                </div>
                                            </div>

                                        </button>
                                    </Menu.Item>}
                            </Menu.Items>
                        </Transition>
                    </>
                )}
            </Menu>
        </div>
    );
}

export { AssessmentsUserMenu };
