import { ListOptions } from "./interfaces/list-options";
import i18n from "../i18n";

// The main reason for separating IndustryValue and IndustryEnglishLabel is that
// IndustryEnglishLabel contains special characters and it might be not error-safe to put them in the URL params
export const IndustryValue = {
    Athletic: "Athletic",
    Corporate: "Corporate",
    Enforcement: "Enforcement",
    Fitness: "Fitness",
    Occupation: "Occupation",
    Rehabilitation: "Rehabilitation",
    School: "School",
    Other: "Other",
}

export const IndustryEnglishLabel = {
    [IndustryValue.Athletic]: i18n.t('pricing.organizationPage.sel_sport', { lng: 'en' }),
    [IndustryValue.Corporate]: i18n.t('pricing.organizationPage.sel_corp', { lng: 'en' }),
    [IndustryValue.Enforcement]: i18n.t('pricing.organizationPage.sel_fireLaw', { lng: 'en' }),
    [IndustryValue.Fitness]: i18n.t('pricing.organizationPage.sel_health', { lng: 'en' }),
    [IndustryValue.Occupation]: i18n.t('pricing.organizationPage.sel_occSafety', { lng: 'en' }),
    [IndustryValue.Rehabilitation]: i18n.t('pricing.organizationPage.sel_rehabilitation', { lng: 'en' }),
    [IndustryValue.School]: i18n.t('pricing.organizationPage.sel_school', { lng: 'en' }),
    [IndustryValue.Other]: i18n.t('pricing.organizationPage.sel_other', { lng: 'en' }),
}

export const IndustryLabel = {
    [IndustryValue.Athletic]: i18n.t('pricing.organizationPage.sel_sport'),
    [IndustryValue.Corporate]: i18n.t('pricing.organizationPage.sel_corp'),
    [IndustryValue.Enforcement]: i18n.t('pricing.organizationPage.sel_fireLaw'),
    [IndustryValue.Fitness]: i18n.t('pricing.organizationPage.sel_health'),
    [IndustryValue.Occupation]: i18n.t('pricing.organizationPage.sel_occSafety'),
    [IndustryValue.Rehabilitation]: i18n.t('pricing.organizationPage.sel_rehabilitation'),
    [IndustryValue.School]: i18n.t('pricing.organizationPage.sel_school'),
    [IndustryValue.Other]: i18n.t('pricing.organizationPage.sel_other'),
};

export const IndustryListOptions: ListOptions[] = [
    {
        value: IndustryValue.Athletic,
        label: IndustryLabel[IndustryValue.Athletic],
    },
    {
        value: IndustryValue.Corporate,
        label: IndustryLabel[IndustryValue.Corporate],
    },
    {
        value: IndustryValue.Enforcement,
        label: IndustryLabel[IndustryValue.Enforcement],
    },
    {
        value: IndustryValue.Fitness,
        label: IndustryLabel[IndustryValue.Fitness],
    },
    {
        value: IndustryValue.Occupation,
        label: IndustryLabel[IndustryValue.Occupation],
    },
    {
        value: IndustryValue.Rehabilitation,
        label: IndustryLabel[IndustryValue.Rehabilitation],
    },
    {
        value: IndustryValue.School,
        label: IndustryLabel[IndustryValue.School],
    },
    {
        value: IndustryValue.Other,
        label: IndustryLabel[IndustryValue.Other],
    },
];