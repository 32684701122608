import { DateTime } from "luxon";
import { ReportComplianceData } from "../../models/interfaces/reports/report-compliance-data";
import DonutChart from "../charts/donut-chart";
import ReportGenericHeader from "./report-generic-header";
import { useTranslation } from 'react-i18next';
const COMPONENT_CLASS = "c-report";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportComplianceProps {
    data: ReportComplianceData;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportCompliance: React.FC<ReportComplianceProps> = (
    props: ReportComplianceProps
) => {
    const { data } = props;
    const { t } = useTranslation();

    const reportDateDifference = DateTime.fromISO(data.reportDate)
        .diff(DateTime.fromISO(data.reportStartDate), ["days", "months"])
        .toObject();
    let reportTimeframe = t('reports.compliance.days', { numbOfDays: Math.ceil(reportDateDifference.days || 0) });
    if ((reportDateDifference.months || 0) >= 1) {
        const months = Math.ceil(reportDateDifference.months || 0);
        reportTimeframe = months > 1 ? t('reports.compliance.month.plural', { numOfMonths: months }) : t('reports.compliance.month.singular', { numOfMonths: months });
    }

    return (
        <div className={`${COMPONENT_CLASS} -compliance`}>
            <ReportGenericHeader companyName={data.organizationName} reportName={t('reports.compliance.reportName')} />
            <div className="flex gap-6 pt-6 pb-6 px-10 sub-header" style={{ backgroundColor: "#F7F9FC" }}>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>{t('reports.usersInReport')}</p>
                    <p>{data.totalUsers}</p>
                </div>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>{t('reports.groups')}</p>
                    {data.groupNames && data.groupNames.length > 0 ?
                        <p>{data.groupNames.join(", ")}</p> :
                        <p>{data.organizationName}</p>
                    }
                </div>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>{t('reports.tags')}</p>
                    {data.tagNames && data.tagNames.length > 0 &&
                        <p>{data.tagNames.join(", ")}</p>
                    }
                </div>

            </div>
            <div
                className={`${COMPONENT_CLASS}__statuses ${COMPONENT_CLASS}__section`}
            >
                <h2>{t('reports.compliance.statuses')}</h2>
                <div className={`${COMPONENT_CLASS}__statuses__wrapper`}>
                    <div className={`${COMPONENT_CLASS}__statuses__item `}>
                        <div
                            className={`${COMPONENT_CLASS}__statuses__item__count`}
                        >
                            {data.totalUsers || t('general.notApplicable')}
                        </div>
                        <div
                            className={`${COMPONENT_CLASS}__statuses__item__name`}
                        >
                            {t('reports.compliance.usersInReportNoColon')}
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__statuses__item `}>
                        <div
                            className={`${COMPONENT_CLASS}__statuses__item__count`}
                        >
                            {data.activeUsers || t('general.notApplicable')}
                        </div>
                        <div
                            className={`${COMPONENT_CLASS}__statuses__item__name`}
                        >
                            {t('reports.compliance.activeUsers')}
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__statuses__item `}>
                        <div
                            className={`${COMPONENT_CLASS}__statuses__item__count`}
                        >
                            {data.inactiveUsers || t('general.notApplicable')}
                        </div>
                        <div
                            className={`${COMPONENT_CLASS}__statuses__item__name`}
                        >
                            {t('reports.compliance.inactiveUsers')}
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__statuses__item `}>
                        <div
                            className={`${COMPONENT_CLASS}__statuses__item__count`}
                        >
                            {data.pendingUsers || t('general.notApplicable')}
                        </div>
                        <div
                            className={`${COMPONENT_CLASS}__statuses__item__name uppercase`}
                        >
                            {t('reports.compliance.pendingUsers')}
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div
                className={`${COMPONENT_CLASS}__activity ${COMPONENT_CLASS}__section`}
            >
                <h2>{reportTimeframe}</h2>
                <div className={`${COMPONENT_CLASS}__activity__wrapper`}>
                    <div className={`${COMPONENT_CLASS}__score-summary`}>
                        {data && data?.usersLoggedIn !== -1 && (
                            <DonutChart
                                percentage={Math.ceil(
                                    (data.usersLoggedIn / data.totalUsers) * 100
                                )}
                                showPercentSymbol={true}
                                showTotal={false}
                                thickness={30}
                            />
                        )}
                        <div
                            className={`${COMPONENT_CLASS}__score-summary__name`}
                        >
                            {t('reports.compliance.loggedInUsers')}
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__score-summary`}>
                        {data && data?.usersAssessment !== -1 && (
                            <DonutChart
                                percentage={Math.ceil(
                                    (data.usersAssessment / data.totalUsers) *
                                    100
                                )}
                                showPercentSymbol={true}
                                showTotal={false}
                                thickness={30}
                            />
                        )}
                        <div
                            className={`${COMPONENT_CLASS}__score-summary__name`}
                        >
                            {t('reports.compliance.assessmentUsers')}
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__score-summary`}>
                        {data && data?.usersCompletedTasks !== -1 && (
                            <DonutChart
                                percentage={Math.ceil(
                                    (data.usersCompletedTasks /
                                        data.totalUsers) *
                                    100
                                )}
                                showPercentSymbol={true}
                                showTotal={false}
                                thickness={30}
                            />
                        )}
                        <div
                            className={`${COMPONENT_CLASS}__score-summary__name`}
                        >
                            {t('reports.compliance.daily50')}
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div
                className={`${COMPONENT_CLASS}__user-list ${COMPONENT_CLASS}__section`}
            >
                <h2>{t('reports.compliance.pendingUsers')}</h2>
                <div className={`${COMPONENT_CLASS}__user-list__wrapper pending-users-content`}>
                    {data.pendingLoginUsers.map((email, index) => (
                        <ul
                            className={`${COMPONENT_CLASS}__user-list__item`}
                            key={`pending-${index}`}
                        >
                            <li className="email">{email}</li>
                        </ul>
                    ))}
                </div>
            </div>
            <hr />
            <div
                className={`${COMPONENT_CLASS}__user-list ${COMPONENT_CLASS}__section`}
            >
                <h2>{t('reports.compliance.pendingUsersInitial')}</h2>
                <div className={`${COMPONENT_CLASS}__user-list__wrapper`}>
                    {data.pendingAssessmentUsers.map((name, index) => (
                        <ul
                            className={`${COMPONENT_CLASS}__user-list__item`}
                            key={`user-assessment-${index}`}
                        >
                            <li className="email">{name}</li>
                        </ul>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ReportCompliance;
