/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { CategoryIds } from "../../models/enumerations/category-ids";
import AssessmentTitle from "../assessment-title/assessment-title";
import BaseCardLayout from "../base-card-layout/base-card-layout";
import ProgressBar from "../progress-bar/progress-bar";
import Plans from "./plans";
import CreateAccount from "./create-account";
import StripePay from "./stripe-stuff";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import CreateOrganization from "./create-organization";
import OrganizationSubscriptionService from "../../utilities/services/organization-subscription-service";
import { useHistory } from "react-router-dom";
import OrganizationService from "../../utilities/services/organization-service";
import { PlanType } from "../../models/enumerations/plan-type";
import { OrganizationSubscription } from "../../models/interfaces/organization-subscription";
import { OrganizationType, OrganizationTypeLabel } from "../../models/enumerations/organization-type";
import { PaymentCycle } from "../../models/enumerations/payment-cycle";
import AccountCreated from "./account-created";
import FunctionService from "../../utilities/services/function-service";
import UserService from "../../utilities/services/user-service";
import { createUserWithEmailAndPassword, deleteUser } from "firebase/auth";
import { User } from '../../models/interfaces/user';
import { Organization } from "../../models/interfaces/organization";
import { UserStatus } from "../../models/enumerations/user-status";
import { UserCredential } from "firebase/auth";
import getFirebase from '../../utilities/firebase';
import { UserLimit } from "../../models/enumerations/user-limit";
import { LicenseLimit } from "../../models/enumerations/license-limit";
import { OrganizationStatus } from "../../models/enumerations/organization-status";
import { useParams } from 'react-router-dom';
import UserRoles from "../../models/user-roles";
import { useTranslation } from "react-i18next";
import { PlanTypeLabels } from "../../models/plan-type";
import { LanguageSelectorUserMenu } from "../language-selector-user-menu/language-selector-user-menu";
import { query, collection, where, getDocs, getFirestore, DocumentData } from 'firebase/firestore';
import { PlanId } from "../../models/enumerations/plan-id";
import { AppContentType } from "../../models/enumerations/app-content-type";
import { AppContent } from "../../models/interfaces/app-content";
import LeadLinksService from "../../utilities/services/lead-links-service";

const COMPONENT_CLASS = "c-subscriptions";
const LEAD_CAPTURE_LINK_TYPE = "leadCapture";
const NPE_FLOW_TYPE = "npe";
const NPE_FLOW_LEAD_TYPE = "npeLead";

interface LinkPaymentParams {
  id: string;
  userId: string;
  type?: string;
}
interface NPEPaymentPageParams {
  npeType: string;
}
const Subscription: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [currentProgress, setCurrentProgress] = useState<number>(1);
  const [slideTitle, setSlideTitle] = useState<string>(t('pricing.slides.plan'));
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);
  const [productInfo, setProductInfo] = useState<any>();
  const [userInfo, setUserInfo] = useState<any>();
  const [organizationInfo, setOrganizationInfo] = useState<any>();
  const { state, setState } = useAuthState();
  const history = useHistory();
  const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
  //const stripe = require('stripe')('sk_test_51Ildh6HoJbt1xrgTackwpgLScozEdruu3qOmzOCebF4LQkQGbSfHs8RYh6xdjr5ORh6BBgeUOpffif21je8smjOM00PLz4GXdQ');
  const [password, setPassword] = useState<string>();
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>();
  const { auth } = getFirebase();
  let authUser: UserCredential;

  const { id, type, userId } = useParams<LinkPaymentParams>();
  const isLeadCaptureLink = type === LEAD_CAPTURE_LINK_TYPE && userId;
  const { npeType } = useParams<NPEPaymentPageParams>();
  const isNPELink = npeType === NPE_FLOW_LEAD_TYPE;
  const params = new URLSearchParams(window.location.search);
  const couponId = params.get("couponId");

  if (npeType === NPE_FLOW_TYPE && state.user?.leadLinkId && state.user?.id) {
    couponId
      ? history.replace(`/lc-pricing/${state.user.leadLinkId}/${LEAD_CAPTURE_LINK_TYPE}/${state.user.id}/npeLead?couponId=${couponId}`)
      : history.replace(`/lc-pricing/${state.user.leadLinkId}/${LEAD_CAPTURE_LINK_TYPE}/${state.user.id}/npeLead`);
  }
  else if (npeType === NPE_FLOW_TYPE && !state.authenticated) {
    couponId
      ? history.replace(`/login?isNpe=true&couponId=${couponId}`)
      : history.replace(`/login`);
  }

  useEffect(() => {
    if (state.temp && state.temp.progress) {
      setCurrentProgress(state.temp.progress);
      setUserInfo(state.temp.user);
      setProductInfo(state.temp.product);
      setOrganizationInfo(state.temp.organization);
    }
  }, []);

  useEffect(() => {
    const getLeadCaptureUser = async () => {
      let leadUser: User | null = null;

      try {
        leadUser = await UserService.get(userId);
      } catch (err) {
        console.error("Cannot fetch the user ID");
      } finally {
        setUserInfo(leadUser);
      }
    }

    if (isLeadCaptureLink && id && userId) {
      getLeadCaptureUser();
    }
  }, [id, isLeadCaptureLink])

  useEffect(() => {
    if (isNPELink && couponId) {
      if (state.authenticated && state.user?.id && state.user.id === state.organization.accountHolderId && state.organization.plan?.value === PlanType.Starter) {
        history.replace(`/settings/upgrade?couponId=${couponId}`);
      }
      else if (state.authenticated && state.user?.id && state.user.id === state.organization.accountHolderId) {
        history.replace("/");
      }
      else if (state.authenticated) {
        grabBusinessPlan();
      }
      else {
        history.replace(`/login?isNpe=true&couponId=${couponId}`);
      }
    } else if (isNPELink && !couponId) {
      setUserInfo(state.user);
    }
  }, [isNPELink]);


  const changeProgress = (progressNumber: number, slideTitle: string) => {
    setCurrentProgress(progressNumber);
    setSlideTitle(slideTitle);
  }

  useEffect(() => {
    setState((state) => ({
      ...state, ...{ temp: { product: productInfo, progress: currentProgress, user: userInfo, organization: organizationInfo } }
    }));
  }, [currentProgress, productInfo, userInfo, organizationInfo, setState]);

  const grabBusinessPlan = async () => {
    const db = getFirestore();
    const q = query(
      collection(db, 'products'),
      where('active', '==', true),
    );
    const querySnapshot = await getDocs(q);
    const productsPromises = querySnapshot.docs.map(async (productDoc, index) => {
      if (productDoc.id === PlanId.Business) {
        let product = productDoc.data();
        const pricesCollection = collection(productDoc.ref, 'prices');
        const priceQuerySnapshot = await getDocs(pricesCollection);

        const priceInfo: DocumentData[] = [];
        // eslint-disable-next-line array-callback-return
        priceQuerySnapshot.docs.map((priceDoc, index) => {
          priceInfo[index] = priceDoc.data();
          product['priceId'] = priceDoc.id;
        });
        product['priceInfo'] = priceInfo.filter(x => x.interval === 'month')[0];
        return product;
      }
    });
    await Promise.all(productsPromises).then((result) => {
      const product = result.filter(productDoc => productDoc && productDoc.id === PlanId.Business)
      handleSelectedProduct({ ...product[0], interval: PaymentCycle.Monthly });
    });
  }

  const handleSelectedProduct = async (data: any) => {
    if (data.name || data === 'STARTER') {
      setProductInfo(data);
      if (!isNPELink) {
        if (state.temp.user && state.temp.organization && data === 'STARTER')
          handleOrganizationCreate(state.temp.organization, data);
        else if (state.temp.user && state.temp.organization && data !== 'STARTER') {
          if (isLeadCaptureLink) {
            changeProgress(3, t('pricing.slides.payment'));
          } else {
            changeProgress(4, t('pricing.slides.payment'));
          }
        }
        else if (state.temp.user && !state.temp.organization) {
          if (isLeadCaptureLink) {
            changeProgress(2, t('pricing.slides.org'));
          } else {
            changeProgress(3, t('pricing.slides.org'));
          }
        }
        else {
          if (!isLeadCaptureLink)
            changeProgress(2, t('pricing.slides.account'));
        }
      } else {
        if (state.user && state.user.id)
          if (data === 'STARTER') {
            let leadLink = id ? await LeadLinksService.get(id) : null;

            const npeOrganization = {
              appContent: {
                [AppContentType.PAIN]: {} as AppContent,
                [AppContentType.BEHAVIORAL_HEALTH]: {} as AppContent,
              },
              status: OrganizationStatus.Active,
              name: state.user.npeTemp!.orgName,
              industry: state.user.npeTemp!.orgIndustry,
              leadLinkId: id ? id : '',
              orgDefaultContentConfig: leadLink ? leadLink.orgDefaultContentConfig : undefined,
            };
            handleOrganizationCreate(npeOrganization, data);
          }
          else
            changeProgress(3, t('pricing.slides.payment'));
      }
    }
  }

  const handleAccountCreate = (data: any, password: string, termsAndConditions: boolean) => {
    if (data) {
      setUserInfo(data);
      setPassword(password);
      setTermsAndConditions(termsAndConditions);
      changeProgress(3, t('pricing.slides.org'));
    } else {
    }
  }

  const handleOrganizationCreate = async (data: any, product: any) => {
    if (data) {
      if (product === 'STARTER') {
        const result = finalizeCreate(state.temp.user, data);
        if (await result === 'SUCCESS') {
          let customer = await stripe.customers.list({
            limit: 1,
            email: `${state.temp.user.email}`,
          })

          // Check if a customer was found
          if (customer.data.length === 0) {
            customer = await stripe.customers.create({
              email: `${state.temp.user.email}`,
              name: `${state.temp.user.firstName} ${state.temp.user.lastName}`,
              preferred_locales: [i18n.language]
            })
          } else {
            await stripe.customers.update(customer.data[0].id,
              {
                name: `${state.temp.user.firstName} ${state.temp.user.lastName}`
              }
            );
          }
          FunctionService.sendAccountOwnerWelcomeEmail(state.temp.user);
          if (isLeadCaptureLink)
            changeProgress(4, t('pricing.slides.confirmation'));
          else
            changeProgress(5, t('pricing.slides.confirmation'));
        } else {
        };

      } else {
        if (isLeadCaptureLink)
          changeProgress(3, t('pricing.slides.payment'));
        else
          changeProgress(4, t('pricing.slides.payment'));
        setOrganizationInfo(data);
      }
    } else {
    }
  }

  const handlePaymentSuccessful = () => {
    setState((state) => ({
      ...state, ...{ temp: { progress: 1, user: null, organization: null, product: null } }
    }));
    setOrganizationInfo(null);
    setProductInfo(null);
    setUserInfo(null);
    history.push("/dashboard");
  };

  const cleanUpState = (user: User, organization: Organization) => {
    setState((state) => ({
      ...state, ...{
        temp: {
          product: productInfo,
          progress: currentProgress,
          user: { ...user },
          organization: { ...organization }
        }
      }
    }));
  }

  const handlePlansBackButton = () => {
    if (isLeadCaptureLink) {
      history.push('/lc-assessment-results');
    }
    else {
      window.location.href = 'https://www.symmio.com/pricing';
    }
  }

  async function finalizeCreate(user: User, organization: Organization): Promise<string> {
    if (!isLeadCaptureLink && !state.user) {
      try { authUser = await createUserWithEmailAndPassword(auth, user.email!.toLowerCase(), password!); }
      catch (error: any) {
        switch (error.code) {
          case "auth/user-not-found":
            cleanUpState(user, organization);
            return t('forms.err_emailDoesNotExist');
          case "auth/email-already-in-use":
            cleanUpState(user, organization);
            return t('forms.err_emailInUse');
          case "auth/invalid-email":
            cleanUpState(user, organization);
            return t('forms.err_emailInvalid');
          case "auth/weak-password":
            cleanUpState(user, organization);
            return t('forms.err_password8');
          default:
            return error.code;
        }
      }
      if (authUser?.user == null) {
        cleanUpState(user, organization);
        return t('forms.err_userNotFound', { email: user.email!.toLowerCase() });
      }

      if (authUser?.user == null) {
        cleanUpState(user, organization);
        return t('forms.err_userNotFound', { email: user.email!.toLowerCase() });
      }

      // Constructing the user for creation.
      const userCreate: User = {
        ...user,
        roles: [UserRoles.ACCOUNT_HOLDER_ID],
        preferredLanguage: i18n.language,
        notificationSettings: {
          inactiveUser: true,
          highRiskUser: true,
          dayStreakMilestone: true,
          newAccount: true,
          completedTasks: true,
          baselineAssessment: true,
        },
        authenticationId: authUser.user.uid,
      };

      // Creating the user.
      await UserService.save(userCreate).then(async (createdUser) => {
        if (createdUser && createdUser.id) {
          // Constructing the organization for creation.
          const organizationCreate: Organization = {
            ...organization,
            accountHolderId: createdUser.id,
            userLimit: UserLimit.Starter,
            userCount: 1,
            organizationLimit: 1,
            mySymmioLicense: LicenseLimit.Starter,
            plan: { value: PlanType.Starter, label: PlanTypeLabels[PlanType.Starter] },
            status: OrganizationStatus.Active,
          };

          // Creating the organization.
          await OrganizationService.add(organizationCreate).then(async (createdOrg) => {
            if (createdOrg && createdOrg.id) {
              // Construct the user for updating. (Add organizationId)
              const updateUser: User = {
                ...createdUser,
                organizationId: createdOrg.id,
                status: UserStatus.Active,
                isSuperAdmin: false,
              }

              await UserService.save(updateUser).catch((updUserError) => {
                UserService.deleteById(createdUser.id!);
                OrganizationService.deleteById(createdOrg.id!);
                if (auth.currentUser && auth.currentUser.uid === createdUser.authenticationId)
                  deleteUser(auth.currentUser);
                cleanUpState(user, organization);
                return t('pricing.general.err_orgSub');
              });

              let orgSub: OrganizationSubscription;

              const startDate = new Date();
              const renewDate = new Date(startDate);
              renewDate.setMonth(startDate.getMonth() + 1);

              orgSub = {
                organizationId: createdOrg.id,
                accountType: {
                  label: OrganizationTypeLabel[OrganizationType.Paid],
                  value: OrganizationType.Paid,
                },
                organizationLimit: 1,
                startDate: startDate.toISOString(),
                renewDate: renewDate.toISOString(),
                userLimit: UserLimit.Starter,
                price: 0,
                plan: {
                  value: PlanType.Starter,
                  label: PlanTypeLabels[PlanType.Starter],
                },
                paymentCycle: {
                  label: PaymentCycle[PaymentCycle.Monthly],
                  value: PaymentCycle.Monthly,
                },
                mySymmioLicense: UserLimit.Starter
              };
              await OrganizationSubscriptionService.save(orgSub).catch((orgSubError) => {
                UserService.deleteById(createdUser.id!);
                OrganizationService.deleteById(createdOrg.id!);
                if (auth.currentUser && auth.currentUser.uid === createdUser.authenticationId)
                  deleteUser(auth.currentUser);
                cleanUpState(user, organization);
                return t('pricing.general.err_orgSub');
              });

              // Set the state to prepare everything for logging in.
              setState((state) => ({
                ...state, ...{
                  user: {
                    ...updateUser,
                    organizationId: createdOrg.id,
                  },
                  organization: {
                    ...createdOrg,
                  },
                  temp: {
                    progress: currentProgress,
                    product: productInfo,
                    user: { ...updateUser },
                    organization: { ...createdOrg },
                  }
                }
              }));
            }
          }).catch((orgError) => {
            // TODO: Delete user and authUser
            UserService.deleteById(createdUser.id!);
            if (auth.currentUser && auth.currentUser.uid === createdUser.authenticationId)
              deleteUser(auth.currentUser);
            cleanUpState(user, organization);
            return t('pricing.general.err_org');
          });

        }
      }).catch((userError) => {
        // TODO: Delete authUser
        if (auth.currentUser)
          deleteUser(auth.currentUser);
        cleanUpState(user, organization);
        return t('pricing.general.err_org');
      });
      return "SUCCESS";
    } else {
      // Constructing the organization for creation.
      const organizationCreate: Organization = {
        ...organization,
        accountHolderId: user && user.id ? user.id : state.user?.id,
        userLimit: UserLimit.Starter,
        userCount: 1,
        organizationLimit: 1,
        mySymmioLicense: LicenseLimit.Starter,
        plan: { value: PlanType.Starter, label: PlanTypeLabels[PlanType.Starter] },
        status: OrganizationStatus.Active,
      };

      // Creating the organization.
      await OrganizationService.add(organizationCreate).then(async (createdOrg) => {
        if (createdOrg && createdOrg.id) {
          // Construct the user for updating. (Add organizationId)
          const updateUser: User = {
            ...user,
            organizationId: createdOrg.id,
            roles: [UserRoles.ACCOUNT_HOLDER_ID],
            status: UserStatus.Active,
            isSuperAdmin: false,
            groupId: null,
            groupName: null,
            tagIds: [],
            tags: [],
            npeTemp: null,
          }

          await UserService.save(updateUser).catch((updUserError) => {
            UserService.deleteById(user.id!);
            OrganizationService.deleteById(createdOrg.id!);
            if (auth.currentUser && auth.currentUser.uid === user.authenticationId)
              deleteUser(auth.currentUser);
            cleanUpState(user, organization);
            return t('pricing.general.err_orgSub');
          });

          let orgSub: OrganizationSubscription;

          const startDate = new Date();
          const renewDate = new Date(startDate);
          renewDate.setMonth(startDate.getMonth() + 1);

          orgSub = {
            organizationId: createdOrg.id,
            accountType: {
              label: OrganizationTypeLabel[OrganizationType.Paid],
              value: OrganizationType.Paid,
            },
            organizationLimit: 1,
            startDate: startDate.toISOString(),
            renewDate: renewDate.toISOString(),
            userLimit: UserLimit.Starter,
            price: 0,
            plan: {
              value: PlanType.Starter,
              label: PlanTypeLabels[PlanType.Starter],
            },
            paymentCycle: {
              label: PaymentCycle[PaymentCycle.Monthly],
              value: PaymentCycle.Monthly,
            },
            mySymmioLicense: UserLimit.Starter
          };
          await OrganizationSubscriptionService.save(orgSub).catch((orgSubError) => {
            UserService.deleteById(user.id!);
            OrganizationService.deleteById(createdOrg.id!);
            if (auth.currentUser && auth.currentUser.uid === user.authenticationId)
              deleteUser(auth.currentUser);
            cleanUpState(user, organization);
            return t('pricing.general.err_orgSub');
          });

          // Set the state to prepare everything for logging in.
          setState((state) => ({
            ...state, ...{
              user: {
                ...updateUser,
                organizationId: createdOrg.id,
              },
              organization: {
                ...createdOrg,
              },
              temp: {
                progress: currentProgress,
                product: productInfo,
                user: { ...updateUser },
                organization: { ...createdOrg },
              }
            }
          }));
        }
      }).catch((orgError) => {
        // TODO: Delete user and authUser
        UserService.deleteById(user.id!);
        if (auth.currentUser && auth.currentUser.uid === user.authenticationId)
          deleteUser(auth.currentUser);
        cleanUpState(user, organization);
        return t('pricing.general.err_org');
      });
      return "SUCCESS";
    }

  }


  return (
    <>
      <div className="user-menu">
        <LanguageSelectorUserMenu userPortal={false} />
      </div>
      <BaseCardLayout>
        <div className={`${COMPONENT_CLASS}`}>
          <div className={`${COMPONENT_CLASS}__wrapper`}>
            <div className={`${COMPONENT_CLASS}__content`}>
              {((isLeadCaptureLink && currentProgress !== 4) || (!isLeadCaptureLink && currentProgress !== 5)) && (
                <>
                  <ProgressBar
                    currentProgress={currentProgress}
                    total={isLeadCaptureLink ? 4 : 5}
                    category={CategoryIds.MOVEMENT}
                    headerText={t('pricing.tabs.createAccount')} />
                  <AssessmentTitle
                    title={slideTitle}
                  />
                </>
              )}

              {currentProgress === 1 && (
                <Plans
                  onProductSelect={handleSelectedProduct}
                  onBackButton={handlePlansBackButton}
                />
              )}
              {isLeadCaptureLink && currentProgress === 2 && (
                <CreateOrganization
                  onBackClick={() => { setProductInfo(null); changeProgress(1, t('pricing.slides.plan')); }}
                  onSubmitClick={() => { }}
                  onOrganizationCreate={handleOrganizationCreate}
                />)}
              {isLeadCaptureLink && (currentProgress === 3 && state.temp.product && state.temp.product !== "STARTER") && (
                <Elements stripe={stripePromise}>
                  <StripePay
                    onInfoCreate={finalizeCreate}
                    onBackClick={() => { changeProgress(3, t('pricing.slides.org')); }}
                    onSubmitClick={() => {
                      FunctionService.sendAccountOwnerWelcomeEmail(state.temp.user);
                      changeProgress(4, t('pricing.slides.confirmation'));
                    }}
                    onChange={() => {
                      setProductInfo(null);
                      changeProgress(1, t('pricing.slides.plan'));
                    }}
                  />
                </Elements>
              )}
              {isLeadCaptureLink && currentProgress === 4 && (
                <AccountCreated
                  welcomeText={productInfo === "STARTER" ? t('pricing.accountCreatedPage.subStarter') : t('pricing.accountCreatedPage.subBusiness')}
                  onSubmitClick={handlePaymentSuccessful}
                />
              )}
              {!isLeadCaptureLink && currentProgress === 2 && (
                <CreateAccount
                  onBackClick={() => { setProductInfo(null); changeProgress(1, t('pricing.slides.plan')); }}
                  onAccountCreate={handleAccountCreate}
                  additionalInfo={{ password: password, termsAndConditions: termsAndConditions }}
                />)}
              {!isLeadCaptureLink && currentProgress === 3 && (
                <CreateOrganization
                  onBackClick={() => { changeProgress(2, t('pricing.slides.account')); }}
                  onSubmitClick={() => { }}
                  onOrganizationCreate={handleOrganizationCreate}
                />)}
              {!isLeadCaptureLink && (currentProgress === 4 && state.temp.product && state.temp.product !== "STARTER") && (
                <Elements stripe={stripePromise}>
                  <StripePay
                    onInfoCreate={finalizeCreate}
                    onBackClick={() => {
                      isNPELink ? changeProgress(1, t('pricing.slides.plan')) :
                        changeProgress(3, t('pricing.slides.org'));
                    }}
                    onSubmitClick={() => {
                      FunctionService.sendAccountOwnerWelcomeEmail(state.temp.user);
                      changeProgress(5, t('pricing.slides.confirmation'));
                    }}
                    onChange={() => {
                      setProductInfo(null);
                      changeProgress(1, t('pricing.slides.plan'));
                    }}
                  />
                </Elements>
              )}
              {!isLeadCaptureLink && currentProgress === 5 && (
                <AccountCreated
                  welcomeText={productInfo === "STARTER" ? t('pricing.accountCreatedPage.subStarter') : t('pricing.accountCreatedPage.subBusiness')}
                  onSubmitClick={handlePaymentSuccessful}
                />
              )}
            </div>
          </div>
        </div>
      </BaseCardLayout>
    </>
  );
}

export default Subscription;