import React, { useState } from "react";
import { Organization } from "../../models/interfaces/organization";
import CancellationDialogBox from "../cancellation-dialog-box/cancellation-dialog-box";
import { useHistory } from 'react-router-dom';
import OrganizationService from "../../utilities/services/organization-service";
import { OrganizationStatus } from "../../models/enumerations/organization-status";
import { PlanType } from "../../models/enumerations/plan-type";
import OrganizationSubscriptionService from "../../utilities/services/organization-subscription-service";
import { UserLimit } from "../../models/enumerations/user-limit";
import { LicenseLimit } from "../../models/enumerations/license-limit";
import UserService from "../../utilities/services/user-service";
import { ReactComponent as GreenCheckMark } from "../../assets/icons/outline/gree-check-mark.svg";
import { ReactComponent as RedCross } from "../../assets/icons/outline/red-cross.svg";
import type { Stripe } from 'stripe'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ReactivateSubscriptionForm from "./reactivate-subscription-form";
import { useTranslation } from 'react-i18next';
import { PlanTypeLabels } from "../../models/plan-type";

export interface ReactivatePlanContentProps {
    COMPONENT_CLASS: string;
    organization: Organization;
    wasAddOnCancelled: boolean;
    products: any[];
}

/**
 * Content for the modal when the user's plan subscription was cancelled due to a failed payment.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const ReactivatePlanContent: React.FC<ReactivatePlanContentProps> = (props) => {
    const {
        COMPONENT_CLASS,
        organization,
        wasAddOnCancelled,
        products,
    } = props;
    const { t } = useTranslation();
    const [openCancellationQuestionnaire, setOpenCancellationQuestionnaire] = useState(false);
    const [openPaymentForm, setOpenPaymentForm] = useState(false);
    const history = useHistory();
    const stripe: Stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

    const handleCancelButtonClick = async () => {
        if (organization.id) {
            await UserService.deactivateAllUsers(organization);

            const orgSubArr = await OrganizationSubscriptionService.getByOrganizationId(organization.id);

            if (orgSubArr && orgSubArr[0]) {
                const orgSub = orgSubArr[0];

                organization.showDeactivatedAccountPlanModal = false;
                organization.showDeactivatedAccountAddOnModal = false;
                organization.status = OrganizationStatus.Active;
                organization.plan = { value: PlanType.Starter, label: PlanTypeLabels[PlanType.Starter] };
                organization.userLimit = UserLimit.Starter;
                organization.mySymmioLicense = LicenseLimit.Starter;

                if (orgSub.stripeAddOnSubscription) {
                    await stripe.subscriptions.cancel(orgSub.stripeAddOnSubscription);
                }

                orgSub.plan = {
                    value: PlanType.Starter,
                    label: PlanTypeLabels[PlanType.Starter],
                }
                orgSub.stripePlanSubscription = undefined;
                orgSub.stripeAddOnSubscription = undefined;
                orgSub.userLimit = UserLimit.Starter;
                orgSub.mySymmioLicense = LicenseLimit.Starter;

                await OrganizationService.save(organization);
                await OrganizationSubscriptionService.save(orgSub);
            }
        }

        history.push("/dashboard?subscriptionStatusModal=showCancelledPlan");
    }

    return (
        <>
            {openPaymentForm
                ?
                <Elements stripe={stripePromise} options={{ mode: 'setup', currency: 'usd' }}>
                    <ReactivateSubscriptionForm
                        returnUrl="/dashboard?subscriptionStatusModal=showReactivatedPlan"
                        organization={organization}
                        products={products}
                        isReactivatingPlan={true}
                        isReactivatingAddOn={wasAddOnCancelled}
                        onBackButtonClick={() => setOpenPaymentForm(false)}
                    />
                </Elements>
                :
                <>
                    <div className={`${COMPONENT_CLASS}__content-container`}>
                        <h1>{t('reactivateSubscription.updateTitle')}</h1>
                        <p>{t('reactivateSubscription.update_desc')}</p>
                        <div className={`${COMPONENT_CLASS}__actions`}>
                            <div className={`${COMPONENT_CLASS}__clickable`} onClick={() => setOpenPaymentForm(true)}>
                                <div className={`${COMPONENT_CLASS}__image-container`}>
                                    <GreenCheckMark />
                                </div>
                                <div className={`${COMPONENT_CLASS}__text-container`}>
                                    <h4>{t('reactivateSubscription.continueTitle')}</h4>
                                    <p>{t('reactivateSubscription.continue_desc')}</p>
                                </div>
                            </div>

                            <div className={`${COMPONENT_CLASS}__clickable`} onClick={() => setOpenCancellationQuestionnaire(true)}>
                                <div className={`${COMPONENT_CLASS}__image-container`}>
                                    <RedCross />
                                </div>
                                <div className={`${COMPONENT_CLASS}__text-container`}>
                                    <h4>{t('reactivateSubscription.cancelTitle')}</h4>
                                    <p>{t('reactivateSubscription.cancel_desc')}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {openCancellationQuestionnaire &&
                        <CancellationDialogBox
                            isOpen={openCancellationQuestionnaire}
                            onClose={setOpenCancellationQuestionnaire}
                            title={t('billing.cancelTitle')}
                            cancelButtonAction={handleCancelButtonClick}
                        />
                    }
                </>
            }
        </>
    );
};

export default ReactivatePlanContent;