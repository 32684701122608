import React, { useState } from "react";
import logo from "../../assets/images/symmio-logos/symmio-logo-vertical-dark.png";
import assessmentsExample from "../../assets/images/assessments-example.png";
import correctiveExample from "../../assets/images/corrective-example.png";
import readingExample from "../../assets/images/reading-example.png";
import todayExample from "../../assets/images/today-example.png";
import googlePlay from "../../assets/images/google-play-button.png";
import appStore from "../../assets/images/app-store-button.png";
import qrCode from "../../assets/images/qr-code-mobile-stores.png";
import symmioIcon from "../../assets/images/symmio-logos/symmio-favicon.png";
import StarIcon from '@mui/icons-material/Star';
import { Modal } from '../modal/modal';
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { Button } from '../../components/button/button';
import { useForm } from "react-hook-form";
import UserService from "../../utilities/services/user-service";
import { AuthErrorCodes, EmailAuthProvider } from "firebase/auth";
import { getAuth, linkWithCredential } from "firebase/auth";
import { TextTypeInput } from "../forms";
import { useTranslation } from 'react-i18next';

export interface BaseCardLayoutProps {
    children: React.ReactNode;
    cardColor?: string;
    returnAppPromo?: boolean;
    setShowMainScreen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const COMPONENT_CLASS = 'c-base-card-layout';

/**
 * Represents the modal that is shown when a Inactivity error occurs.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const BaseCardLayout: React.FC<BaseCardLayoutProps> = (props) => {
    const [openCreatePasswordModal, setOpenCreatePasswordModal] = useState(false);
    const [isClosing] = useState(false);
    const { state } = useAuthState();
    const { t } = useTranslation();
    const [passwordError, setPasswordError] = useState("");
    const [error, setError] = useState("");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const mobileAppInfo = (
        <div className={`${COMPONENT_CLASS}__mobile-app-info`}>
            <div className={`${COMPONENT_CLASS}__promo-info-section`}>
                <img className={`${COMPONENT_CLASS}__promo-image`} src={todayExample} alt="incorrect path" />
                <div className="margin-left promo-text">
                    <h4 className={`${COMPONENT_CLASS}__promo-h4-header`}>{t('baseCard.promo_improvement')}</h4>
                    <p>{t('baseCard.promo_improvement_desc')}</p>
                </div>
            </div>
            <div className={`${COMPONENT_CLASS}__promo-info-section right`}>
                <div className="margin-right promo-text">
                    <h4 className={`${COMPONENT_CLASS}__promo-h4-header`}>{t('baseCard.promo_track')}</h4>
                    <p>{t('baseCard.promo_track_desc')}</p>
                </div>
                <img className={`${COMPONENT_CLASS}__promo-image`} src={assessmentsExample} alt="incorrect path" />
            </div>
            <div className={`${COMPONENT_CLASS}__promo-info-section`}>
                <img className={`${COMPONENT_CLASS}__promo-image`} src={readingExample} alt="incorrect path" />
                <div className="margin-left promo-text">
                    <h4 className={`${COMPONENT_CLASS}__promo-h4-header`}>{t('baseCard.promo_education')}</h4>
                    <p>{t('baseCard.promo_education_desc')}</p>
                </div>
            </div>
            <div className={`${COMPONENT_CLASS}__promo-info-section right`}>
                <div className="margin-right promo-text">
                    <h4 className={`${COMPONENT_CLASS}__promo-h4-header`}>{t('baseCard.promo_anywhere')}</h4>
                    <p>{t('baseCard.promo_anywhere_desc')}</p>
                </div>
                <img className={`${COMPONENT_CLASS}__promo-image`} src={correctiveExample} alt="incorrect path" />
            </div>
            <h4 className={`${COMPONENT_CLASS}__promo-h4-header`} style={{ marginTop: "3rem" }}>{t('baseCard.otherUsers')}</h4>
            <div className={`${COMPONENT_CLASS}__promo-reviews-container`}>
                <div className={`${COMPONENT_CLASS}__promo-review-stack`}>
                    <div className={`${COMPONENT_CLASS}__promo-review`} style={{ height: "100%" }}>
                        <StarIcon sx={{ color: "#FFC79C" }} />
                        <StarIcon sx={{ color: "#FFC79C" }} />
                        <StarIcon sx={{ color: "#FFC79C" }} />
                        <StarIcon sx={{ color: "#FFC79C" }} />
                        <StarIcon sx={{ color: "#FFC79C" }} />
                        <h4>{t('baseCard.reviews.review1_head')}</h4>
                        <p>{t('baseCard.reviews.review1_details')}</p>
                    </div>
                </div>
                <div className={`${COMPONENT_CLASS}__promo-review-stack`}>
                    <div className={`${COMPONENT_CLASS}__promo-review`}>
                        <StarIcon sx={{ color: "#FFC79C" }} />
                        <StarIcon sx={{ color: "#FFC79C" }} />
                        <StarIcon sx={{ color: "#FFC79C" }} />
                        <StarIcon sx={{ color: "#FFC79C" }} />
                        <StarIcon sx={{ color: "#FFC79C" }} />
                        <h4>{t('baseCard.reviews.review2_head')}</h4>
                        <p>{t('baseCard.reviews.review2_details')}</p>
                    </div>
                    <div className={`${COMPONENT_CLASS}__promo-review`}>
                        <StarIcon sx={{ color: "#FFC79C" }} />
                        <StarIcon sx={{ color: "#FFC79C" }} />
                        <StarIcon sx={{ color: "#FFC79C" }} />
                        <StarIcon sx={{ color: "#FFC79C" }} />
                        <StarIcon sx={{ color: "#FFC79C" }} />
                        <h4>{t('baseCard.reviews.review3_head')}</h4>
                        <p>{t('baseCard.reviews.review3_details')}</p>
                    </div>
                </div>
            </div>
        </div>
    );

    const qrCodeElement = (
        <div className={`${COMPONENT_CLASS}__mobile-stores-container`}>
            <p className={`${COMPONENT_CLASS}__promo-intro`} dangerouslySetInnerHTML={{ __html: t('baseCard.qrCode') }}></p>

            <div className="info-container">
                <div className={`${COMPONENT_CLASS}__qr-code-container`}>
                    <img className={`${COMPONENT_CLASS}__qr-code`} src={qrCode} alt="incorrect path" />
                </div>
                <div className="stores-bottom-info">
                    <div className="symmio-icon-container">
                        <img className="stores-symmio-icon" src={symmioIcon} alt="incorrect path" />
                        <h5 className="stores-h5">{t('general.symmio')}</h5>
                    </div>
                    <div className={`${COMPONENT_CLASS}__promo-buttons`}>
                        <a href="https://apps.apple.com/us/app/symmio/id1605214846" target="__blank"><img src={appStore} alt="incorrect path" className="app-store-button" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.functionalmovementsystems.symmio&hl=en_CA&gl=US" target="__blank"><img src={googlePlay} alt="incorrect path" className="google-play-button" /></a>
                    </div>
                </div>
            </div>
        </div>
    );

    const passwordCreatedPage = (
        <div className={`${COMPONENT_CLASS}__promo-container`}>
            <h3 className={`${COMPONENT_CLASS}__promo-h3-header`}>{t('baseCard.congratsMessage')}</h3>
            <p className={`${COMPONENT_CLASS}__promo-intro`}>{t('baseCard.congratsMessage_desc')}</p>
            {qrCodeElement}
            {mobileAppInfo}
            <h4 className={`${COMPONENT_CLASS}__promo-h4-header`}>{t('baseCard.otherUsers')}</h4>
            {qrCodeElement}
        </div>
    );

    const createPasswordPage = (
        <div className={`${COMPONENT_CLASS}__promo-container`}>
            <h3 className={`${COMPONENT_CLASS}__promo-h3-header`}>{t('baseCard.createPassword')}</h3>
            <p className={`${COMPONENT_CLASS}__promo-intro`}>{t('baseCard.congratsMessage_desc')}</p>
            <div className={`${COMPONENT_CLASS}__password-button`}>
                <Button
                    onClick={() => { setOpenCreatePasswordModal(true) }}
                    buttonText={t('buttons.btn_createPassword')}
                    type="default"
                ></Button>
            </div>
            {mobileAppInfo}
        </div>
    );

    const onSubmit = async (data: any) => {
        const auth = getAuth();
        const { password, passwordTwo } = data;

        if (password !== passwordTwo) {
            setPasswordError(t('baseCard.err_password_npMatch'));
            return;
        }

        let user = state.user;

        if (!user || !user.email) return;

        try {
            if (auth.currentUser) {
                if (password.length < 6) throw new Error(AuthErrorCodes.WEAK_PASSWORD);

                const credential = EmailAuthProvider.credential(user.email, password);

                linkWithCredential(auth.currentUser, credential)
                    .then((usercred) => {
                        // const user = usercred.user;
                    }).catch((error) => {
                        console.log("Account linking error", error);
                        return;
                    });
            }
        } catch (error: any) {
            switch (error.message) {
                case "auth/weak-password":
                    setPasswordError(
                        t('baseCard.err_password_minChars')
                    );
                    break;
                default:
                    setError(
                        "Something went wrong. We are working on fixing the problem. Please try again later."
                    );
                    break;
            }
            return;
        }

        user.isAuthenticated = true;

        //don't think we need this data for the admin portal (these are part of global state)
        //setHasMskScore(false);
        //setIdentity(user);
        UserService.update(user);
        setOpenCreatePasswordModal(false);

        // For some reason after creating user it's supposed to log them in
        // Instead its not logging them in but creating an auth session that's 
        // in a weird state. They can't access any pages and are stuck in login screen
        // but can't login because there is already an auth session active.
        // auth.signOut();
        // setState((state) => ({ ...state, ...{ authenticated: false, user: null } }));

        // This signs them in again similar to the createUserWithEmailAndPassword, they aren't quite logged in,
        // but auth thinks they are. They then can't login until refreshing the page.
        // signInWithEmailAndPassword(auth, data.email, data.password)
        //     .catch((error: any) => {
        //         handleError(error);
        //         setModalVisble(true);
        //     });
    }

    if (props.returnAppPromo) {
        return (
            <div className={`grid place-items-center h-screen ${COMPONENT_CLASS}`} style={{ backgroundColor: "#1216220D", width: "100%", height: "100%" }}>
                <Modal
                    isOpen={openCreatePasswordModal}
                    isLoading={isClosing}
                    onClose={setOpenCreatePasswordModal}
                    defaultCenterModal={true}
                    onSubmit={() => { handleSubmit(onSubmit) }}
                    submitButtonText={t('buttons.btn_createPassword')}
                >
                    <form onSubmit={handleSubmit(onSubmit)}
                        className={`${COMPONENT_CLASS}__create-password-container`}>
                        <div className={`${COMPONENT_CLASS}__create-password-container-header`}>
                            <h3>{t('baseCard.createPassword')}</h3>
                            <p>{t('baseCard.createPassword_desc')}</p>
                        </div>
                        <p className={`${COMPONENT_CLASS} -error`}>{error}</p>
                        <div className="field">
                            {errors.password && (
                                <div
                                    role="alert"
                                    className={`${COMPONENT_CLASS} -error`}>
                                    {t('baseCard.err_password_required')}
                                </div>
                            )}
                            {passwordError && (
                                <div
                                    role="alert"
                                    className={`${COMPONENT_CLASS} -error`}>
                                    {passwordError}
                                </div>
                            )}
                            <TextTypeInput
                                id="password"
                                label={t('manageAccount.password')}
                                hideLabel={true}
                                inputClassName={`c-input__input ${errors.password &&
                                    `${COMPONENT_CLASS} -showerror`
                                    }`}
                                type="password"
                                registerHook={register}
                                registerOptions={{
                                    required: true,
                                }} />
                        </div>
                        <div className="field">
                            {errors.passwordTwo && (
                                <div
                                    role="alert"
                                    className={`${COMPONENT_CLASS} -error`}>
                                    {t('baseCard.err_password_required')}
                                </div>
                            )}
                            {passwordError && (
                                <div
                                    role="alert"
                                    className={`${COMPONENT_CLASS} -error`}>
                                    {passwordError}
                                </div>
                            )}
                            <TextTypeInput
                                id="passwordTwo"
                                type="password"
                                label={t('forms.confirmPassword')}
                                hideLabel={true}
                                inputClassName={`c-input__input ${errors.passwordTwo &&
                                    `${COMPONENT_CLASS} -showerror`
                                    }`}
                                registerHook={register}
                                registerOptions={{
                                    required: true,
                                }} />
                        </div>
                    </form>
                </Modal>
                <div className={`${COMPONENT_CLASS}__card-content-container`}>
                    {props.children}
                    {state.user?.isAuthenticated ? passwordCreatedPage : createPasswordPage}
                    {props.setShowMainScreen &&
                        <div className={`${COMPONENT_CLASS}__symmio-home-button-container`}>
                            <Button
                                onClick={() => { props.setShowMainScreen && props.setShowMainScreen(true) }}
                                buttonText={t('buttons.btn_symmioHome')}
                                type="default"
                            ></Button>
                        </div>
                    }
                </div>
                <div className={`${COMPONENT_CLASS}__footer hide-on-mobile`}><img src={logo} alt="Symmio Logo"></img><p>{t('misc.copyright')}</p></div>
            </div>
        )
    }

    return (
        <div className={`grid place-items-center h-screen ${COMPONENT_CLASS} page-container`}>
            <div className={`${COMPONENT_CLASS}__card-content-container`} style={{ backgroundColor: `${props.cardColor ? props.cardColor : "white"}` }}>
                {props.children}
            </div>
            <div className={`${COMPONENT_CLASS}__footer hide-on-mobile`}><img src={logo} alt="Symmio Logo"></img><p>{t('misc.copyright')}</p></div>
        </div>
    );
};

export default BaseCardLayout;