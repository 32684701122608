/* eslint-disable no-useless-escape */

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import UserService from '../../utilities/services/user-service';
import BaseCardLayoutActions from "../base-card-layout/base-card-layout-actions";
import { ReactComponent as CircleIcon } from "../../assets/icons/outline/check-circle-icon.svg";
import { ReactComponent as CheckedIcon } from "../../assets/icons/outline/filled-check-circle-icon.svg";
import UserRoles from '../../models/user-roles';
import { UserStatus } from '../../models/enumerations/user-status';
import { User } from '../../models/interfaces/user';
import { SymmioAccessType } from '../../models/enumerations/symmio-access-type';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import { TextTypeInput } from '../forms';
import { EmailTypeInput } from '../forms/email-type-input';
import { CheckboxTypeInput } from '../forms/checkbox-type-input';
import { useTranslation } from 'react-i18next';

interface CreateAccountProps {
  onBackClick: () => void;
  onAccountCreate: (data: any, password: string, termsAndConditions: boolean) => void;
  additionalInfo: any;
}

const CreateAccount: React.FC<CreateAccountProps> = ({ onBackClick, onAccountCreate, additionalInfo }) => {
  const COMPONENT_CLASS = "c-subscriptions";
  const FORM_COMPONENT_CLASS = "p-link-create-account";

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onSubmit' });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [EmailError, setEmailError] = useState("");
  const [, setIsCreatingAccount] = useState(false);
  const { state } = useAuthState();
  const [isCheckedTerms, setIsCheckedTerms] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({ length: false, uppercase: false, lowercase: false, number: false, special: false });
  const onSubmit = async (data: any) => {
    setIsCreatingAccount(true);
    const { firstName, lastName, email, password, termsAndConditions } = data;

    if (/[A-Z]/.test(email)) {
      setEmailError(t('forms.upper_val'));
      setIsCreatingAccount(false);
      return;
    }

    const existingUser = await UserService.getBy([{ field: "email", operator: "==", value: email }])
    if (existingUser.length > 0 && existingUser[0].organizationId) {
      setEmailError(t('forms.err_emailExists2'));
      setIsCreatingAccount(false);
      return;
    }

    const accHolder: User = {
      //authenticationId: authUser.user.uid,
      firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1), // Capitalize first letter
      lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1), // Capitalize first letter
      email: email,
      status: UserStatus.Active,
      isSuperAdmin: false,
      isAuthenticated: true,
      signUpFlow: true,
      roles: [UserRoles.ACCOUNT_HOLDER_ID],
      symmioAccess: SymmioAccessType.AppLicense,
    };

    onAccountCreate(accHolder, password, termsAndConditions);
  }

  useEffect(() => {
    if (additionalInfo.password) {
      handlePasswordValidation(additionalInfo.password);
    }
  }, [additionalInfo.password])

  function handlePasswordValidation(value: any): void {
    setPasswordValidation({
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /\d/.test(value),
      special: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(value)
    });
  }
  return (
    <>
      <div className={`${COMPONENT_CLASS}__header`}>
        {t('pricing.accountPage.title')}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`${FORM_COMPONENT_CLASS}__sign-up-content`}>
          <div className={`${FORM_COMPONENT_CLASS}__field`}>
            {errors.firstName && (
              <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                {t('forms.firstName_val')}
              </div>
            )}
            <TextTypeInput
              type='text'
              label={t('forms.firstName')}
              id='firstName'
              hideLabel={true}
              inputClassName={`c-input__input ${errors.firstName && `${FORM_COMPONENT_CLASS} -showerror`}`}
              registerHook={register}
              registerOptions={{
                value: state.temp.user ? state.temp.user.firstName : "",
                required: true
              }}
              errorState={errors.firstName}
            />
          </div>
          <div className={`${FORM_COMPONENT_CLASS}__field`}>
            {errors.lastName && (
              <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                {t('forms.lastName_val')}
              </div>
            )}
            <TextTypeInput
              type='text'
              label={t('forms.lastName')}
              hideLabel={true}
              inputClassName={`c-input__input ${errors.lastName && `${FORM_COMPONENT_CLASS} -showerror`}`}
              id='lastName'
              registerHook={register}
              registerOptions={{
                value: state.temp.user ? state.temp.user.lastName : "",
                required: true
              }}
              errorState={errors.lastName} />
          </div>
          <div className={`${FORM_COMPONENT_CLASS}__field`}>
            {errors.email && (
              <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                {t('forms.email_val')}
              </div>
            )}
            {EmailError && (
              <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                {EmailError}
              </div>
            )}
            {(EmailError.includes('already associated')) && (
              <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`} dangerouslySetInnerHTML={{ __html: t('pricing.accountPage.login') }}>
              </div>
            )}
            <EmailTypeInput
              id='email'
              label={t('forms.email')}
              hideLabel={true}
              inputClassName={`c-input__input ${errors.email && `${FORM_COMPONENT_CLASS} -showerror`}`}
              registerHook={register}
              registerOptions={{
                value: state.temp.user ? state.temp.user.email : "",
                required: true,
              }} />
          </div>
          <div className={`${FORM_COMPONENT_CLASS}__field`}>
            {errors.password && (
              <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                {t('forms.password_val')}
              </div>
            )}
            <TextTypeInput
              type='password'
              id='password'
              inputClassName={`c-input__input ${errors.password && `${FORM_COMPONENT_CLASS} -showerror`}`}
              label={t('forms.password')}
              hideLabel={true}
              registerHook={register}
              registerOptions={{
                required: true,
                value: additionalInfo.password ? additionalInfo.password : '',
                onChange: (e) => handlePasswordValidation(e.target.value),
                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\-]{8,}$/
              }} />
            <div style={{ paddingLeft: '1.5em' }}>
              {t('forms.passwordTitle')}
              <div className={`${FORM_COMPONENT_CLASS}__password_container`}>
                <div style={{ width: '50%' }}>
                  <div className={`${FORM_COMPONENT_CLASS}_item`}>
                    {passwordValidation.length ? <CheckedIcon style={{ marginRight: '1.5em' }} /> : <CircleIcon style={{ marginRight: '1.5em' }} />}
                    {t('forms.8chars')}
                  </div>
                  <div className={`${FORM_COMPONENT_CLASS}_item`}>
                    {passwordValidation.lowercase ? <CheckedIcon style={{ marginRight: '1.5em' }} /> : <CircleIcon style={{ marginRight: '1.5em' }} />}
                    {t('forms.1lower')}
                  </div>
                  <div className={`${FORM_COMPONENT_CLASS}_item`}>
                    {passwordValidation.uppercase ? <CheckedIcon style={{ marginRight: '1.5em' }} /> : <CircleIcon style={{ marginRight: '1.5em' }} />}
                    {t('forms.1upper')}
                  </div>
                </div>
                <div style={{ width: '50%' }}>
                  <div className={`${FORM_COMPONENT_CLASS}_item`}>
                    {passwordValidation.number ? <CheckedIcon style={{ marginRight: '1.5em' }} /> : <CircleIcon style={{ marginRight: '1.5em' }} />}
                    {t('forms.1number')}
                  </div>
                  <div className={`${FORM_COMPONENT_CLASS}_item`}>
                    {passwordValidation.special ? <CheckedIcon style={{ marginRight: '1.5em' }} /> : <CircleIcon style={{ marginRight: '1.5em' }} />}
                    {t('forms.1special')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${FORM_COMPONENT_CLASS}__field`}>
            <div className={`${FORM_COMPONENT_CLASS}__terms`} style={{ rowGap: "0px" }}>
              {errors.termsAndConditions && (
                <div
                  role="alert"
                  className={`${FORM_COMPONENT_CLASS} -error`}
                  style={{ gridRow: "1 / 2", gridColumn: "1 / 3" }}>
                  {t('forms.tc_agree_val')}
                </div>
              )}
              <CheckboxTypeInput
                id='termsAndConditions'
                registerHook={register}
                registerOptions={{
                  value: additionalInfo.termsAndConditions ? additionalInfo.termsAndConditions : false,
                  required: true,
                  onChange: (e) => {
                    setIsCheckedTerms(e.target.checked);
                  }
                }}
                // label='I agree to terms and conditions'
                checked={isCheckedTerms}
                errorState={errors.termsAndConditions} />
              <div dangerouslySetInnerHTML={{ __html: t('pricing.accountPage.termsAndConditions') }}></div>
            </div>
          </div>
        </div>
      </form>
      <BaseCardLayoutActions backButton={true} onBack={onBackClick}
        submitButton={true} onSubmit={handleSubmit(onSubmit)} submitButtonText={t('pricing.accountPage.next_button')}></BaseCardLayoutActions>
    </>
  );
}

export default CreateAccount;