/* eslint-disable react-hooks/exhaustive-deps */
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/solid/icon_checkmark-round-edges.svg";
import { ReactComponent as WwwIcon } from "../../assets/icons/outline/icon_www.svg";
import UserService from '../../utilities/services/user-service-assessments';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import { useHistory } from "react-router-dom";
const COMPONENT_CLASS = "c-language-menu";

export interface LanguageSelectorUserMenuProps {
    userPortal: boolean;
}

const LanguageSelectorUserMenu: React.FC<LanguageSelectorUserMenuProps> = (props) => {
    const [mouseOverButton, setMouseOverButton] = useState(false)
    const [mouseOverMenu, setMouseOverMenu] = useState(false)

    const { i18n } = useTranslation();

    const currentLanguageName = new Intl.DisplayNames(i18n.language, { type: 'language' }).of(i18n.language);
    const languages: string[] = [...i18n.languages];
    const { state, setState } = useAuthState();
    const history = useHistory();
    const timeoutDuration = 500
    let timeoutButton: string | number | NodeJS.Timeout | undefined;
    let timeoutMenu: string | number | NodeJS.Timeout | undefined;

    const onMouseEnterButton = () => {
        clearTimeout(timeoutButton)
        setMouseOverButton(true)
    }
    const onMouseLeaveButton = () => {
        timeoutButton = setTimeout(() => setMouseOverButton(false), timeoutDuration)
    }

    const onMouseEnterMenu = () => {
        clearTimeout(timeoutMenu)
        setMouseOverMenu(true)
    }
    const onMouseLeaveMenu = () => {
        timeoutMenu = setTimeout(() => setMouseOverMenu(false), timeoutDuration)
    }
    const show = (mouseOverMenu || mouseOverButton)

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <Menu as="div">
                <div onMouseEnter={onMouseEnterButton}
                    onMouseLeave={onMouseLeaveButton}>
                    <Menu.Button className={`${COMPONENT_CLASS}__button`}>
                        <div className={`${COMPONENT_CLASS}__language`}>
                            <WwwIcon />
                            {!props.userPortal ? <div>{currentLanguageName || "..."}</div> : ''}
                            <ChevronDownIcon />
                        </div>
                    </Menu.Button>
                </div>
                <Transition
                    show={show}
                // enter="transition ease-out duration-100"
                // enterFrom="transform opacity-0 scale-95"
                // enterTo="transform opacity-100 scale-100"
                // leave="transition ease-in duration-75"
                // leaveFrom="transform opacity-100 scale-100"
                // leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className={`${COMPONENT_CLASS}__menu`}
                        onMouseEnter={onMouseEnterMenu}
                        onMouseLeave={onMouseLeaveMenu}
                    >
                        {languages.sort((a, b) => a.localeCompare(b)).map((languageCode) => {
                            const nameGenerator = new Intl.DisplayNames(languageCode, { type: 'language' });
                            const languageName = nameGenerator.of(languageCode);

                            return (
                                <Menu.Item>
                                    <button
                                        onClick={async () => {
                                            i18n.changeLanguage(languageCode);
                                            if (props.userPortal && state.user) {
                                                const updatedUser = {
                                                    ...state.user,
                                                    preferredLanguage: i18n.language
                                                }
                                                await UserService.updateByPortalUser(updatedUser);
                                                setState((state) => ({
                                                    ...state, ...{ user: updatedUser }
                                                }));
                                            }
                                            history.go(0);
                                        }}
                                        className={`${COMPONENT_CLASS}__item show-border justify-between`} style={{ display: "flex" }}>
                                        {languageName || languageCode}
                                        {languageCode === i18n.language ? <CheckmarkIcon className={`${COMPONENT_CLASS}__checkmark-icon`} /> : <></>}
                                    </button>
                                </Menu.Item>
                            )
                        })}
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}

export { LanguageSelectorUserMenu };
