import { CategoryIds } from "../../models/enumerations/category-ids";
import { SlideType } from "../../models/enumerations/slide-types";
import { Question } from "../../models/interfaces/questions/question";
import { QuestionResponse } from "../../models/interfaces/questions/question-response";
import { Slide } from "../../models/interfaces/slide";
import AssessmentTitle from "../assessment-title/assessment-title";
import FullscreenVideo from "../fullscreen-video/fullscreen-video";
import LifestyleIntro from "../lifestyle-intro/lifestyle-intro";
import MovementIntro from "../movement-intro/movement-intro";
import ScreenQuestion from "../question-screen/question-screen";
import ScreenIntro from "../screen-intro/screen-intro";
import LifestyleComplete from "../lifestyle-complete/lifestyle-complete";
import MovementComplete from "../movement-complete/movement-complete";
import MovementTips from "../movement-tips/movement-tips";
import { GroupIds } from "../../models/enumerations/group-ids";
import ScreenTests from "../screen-tests/screen-tests";
import ProgressBar from "../progress-bar/progress-bar";
import { AssessmentUtils } from "../../utilities/assessments/assessment-utils";
import BaseCardLayoutActions from "../base-card-layout/base-card-layout-actions";
import { AnswerFormats } from "../../models/enumerations/answer-formats";
import { useTranslation } from "react-i18next";
import GroupUtil from "../../utilities/group-util";

const COMPONENT_CLASS = "c-screen-slide";

export interface ScreenSlideProps {
    isLinkAccountCreation?: boolean;
    questionGroupNames?: string[];
    page: Slide;
    recordAnswer: (selected: string, question: Question) => void;
    recordedAnswers?: QuestionResponse[];
    errors?: string[];
    totalProgress?: number;
    currentProgress?: number;
    handleOptionalQuestion?: () => void;
    setupSubmit?: Function;
    hasProgressBar?: boolean;
    screenSettings?: any;
    settingsOnBack?: Function;
    isActionsWithContinue?: boolean;
    skipButton?: boolean;
    onSkip?: Function;
    onBack?: Function;
    onSubmit?: Function;
    submitButtonText?: string;
    headerText?: string;
}

const ScreenSlide: React.FC<ScreenSlideProps> = (props) => {
    const { t } = useTranslation();
    let questionAbbr: string | undefined = undefined;
    if (props.page.questions != null && props.page.questions.length >= 1) {
        questionAbbr = props.page.questions[0].questionAbbr;
    }

    return (
        <div
            id="screen-slide"
            className={`${COMPONENT_CLASS} ${props.page.slideType === SlideType.Tips ? "-slider" : ""
                } ${props.page.slideType === SlideType.Question && props.page.categoryId === CategoryIds.LIFESTYLE ? `${COMPONENT_CLASS}__lifestyle` : ""}`}>
            {
                // if
                props.hasProgressBar && (
                    <ProgressBar
                        currentProgress={AssessmentUtils.getProcessCurrent(
                            props.screenSettings
                        )}
                        total={AssessmentUtils.getProcessTotal(
                            props.screenSettings
                        )}
                        category={props.page.categoryId as CategoryIds}
                        headerText={props.headerText || t('assessments.movement')}
                    />
                )
            }
            {
                // if
                props.page.slideType === SlideType.AssessmentIntro &&
                props.page.categoryId === CategoryIds.MOVEMENT && (
                    <>
                        <MovementIntro />
                        <BaseCardLayoutActions submitButton={true} onSubmit={props.onSubmit} submitButtonText={props.submitButtonText} skipButton={props.skipButton}
                            onSkip={() => {
                                if (props.onSkip) {
                                    props.onSkip();
                                }
                            }}></BaseCardLayoutActions>
                    </>
                )
            }
            {
                // if
                props.page.slideType === SlideType.AssessmentIntro &&
                props.page.categoryId === CategoryIds.LIFESTYLE && (
                    <>

                        <LifestyleIntro />
                        <BaseCardLayoutActions submitButton={true} onSubmit={props.onSubmit} submitButtonText={props.submitButtonText} skipButton={props.skipButton}
                            onSkip={() => {
                                if (props.onSkip) {
                                    props.onSkip();
                                }
                            }}></BaseCardLayoutActions>
                    </>

                )
            }
            {
                // if
                props.page.slideType === SlideType.Tips &&
                props.page.categoryId === CategoryIds.MOVEMENT && (
                    <>
                        <MovementTips />
                        <div className={`${COMPONENT_CLASS}__actions`}>
                            <BaseCardLayoutActions backButton={true} onBack={props.settingsOnBack} submitButton={true} onSubmit={props.onSubmit} submitButtonText={props.submitButtonText} skipButton={props.skipButton}
                                onSkip={() => {
                                    if (props.onSkip) {
                                        props.onSkip();
                                    }
                                }}></BaseCardLayoutActions>
                        </div>
                    </>

                )
            }
            {
                // if
                props.page.slideType === SlideType.Tests && (
                    <>
                        <ScreenTests
                            questionGroupNames={props.questionGroupNames}
                        />
                        <BaseCardLayoutActions backButton={true} onBack={props.settingsOnBack} submitButton={true} onSubmit={props.onSubmit} submitButtonText={props.submitButtonText} skipButton={props.skipButton}
                            onSkip={() => {
                                if (props.onSkip) {
                                    props.onSkip();
                                }
                            }}></BaseCardLayoutActions>
                    </>
                )
            }

            {
                // if
                props.page.slideType === SlideType.GroupIntro && (
                    <>
                        <ScreenIntro
                            title={GroupUtil.getName(props.page.groupId)}
                            categoryId={props.page.categoryId}
                            description={props.page.description}
                            groupId={props.page.groupId}
                            progressBarTotal={props.totalProgress}
                            progressBarCurrent={props.currentProgress}
                        />
                        <BaseCardLayoutActions backButton={true} onBack={props.settingsOnBack} submitButton={true} onSubmit={props.onSubmit} submitButtonText={props.submitButtonText} skipButton={props.skipButton}
                            onSkip={() => {
                                if (props.onSkip) {
                                    props.onSkip();
                                }
                            }}></BaseCardLayoutActions>
                    </>

                )
            }
            {
                // if
                props.page.slideType === SlideType.Video &&
                props.page.video && [
                    <AssessmentTitle
                        key="0"
                        title={GroupUtil.getName(props.page.groupId)}
                        subtitle={questionAbbr ? t(questionAbbr) : undefined}
                    />,
                    <FullscreenVideo
                        key="1"
                        instructions={props.page.instructions}
                        video={props.page.video}
                    />,
                    <div style={{ marginTop: "auto" }}>
                        <BaseCardLayoutActions backButton={true} onBack={props.settingsOnBack} submitButton={true} onSubmit={props.onSubmit} submitButtonText={props.submitButtonText} skipButton={props.skipButton}
                            onSkip={() => {
                                if (props.onSkip) {
                                    props.onSkip();
                                }
                            }}></BaseCardLayoutActions>
                    </div>
                ]
            }
            {
                // if
                props.page.slideType === SlideType.Question &&
                props.page.questions != null && (
                    <div className={`${COMPONENT_CLASS}__question`}>
                        {
                            // if
                            props.page.groupId !== GroupIds.SETUP && (
                                <AssessmentTitle
                                    title={GroupUtil.getName(props.page.groupId)}
                                    subtitle={
                                        props.page.questions[0].questionAbbr ? t(props.page.questions[0].questionAbbr) : undefined
                                    }
                                />
                            )
                        }
                        <div
                            className={`${COMPONENT_CLASS}__question__content scroll-container`}>
                            {props.page.questions.map((question, index) => {
                                return (
                                    <>
                                        <ScreenQuestion

                                            key={index}
                                            answer={props.recordedAnswers?.find(
                                                (x) =>
                                                    x.questionId ===
                                                    question.questionId
                                            )}
                                            handleAnswerChange={
                                                props.recordAnswer
                                            }
                                            hideHeader={index !== 0}
                                            question={question!}
                                            handleOptionalClick={
                                                props.handleOptionalQuestion
                                            }
                                            setupSubmit={props.setupSubmit}
                                            settingsOnBack={props.settingsOnBack}
                                            isLifestyle={props.page.categoryId === CategoryIds.LIFESTYLE && question.answerFormat === AnswerFormats.OPTIONS}
                                        />
                                        {
                                            // if
                                            !props.isActionsWithContinue && !(question.questionId === "BALANCE_REACH_2_LEFT" || question.questionId === "BALANCE_REACH_25_LEFT" || question.questionId === "birthdate" || question.questionId === "biologicalSex") && (
                                                <div className={/*`${(question.answerFormat === AnswerFormats.OPTIONS ||
                                                    question.questionId === "PHYSICAL_ACTIVITY_STRENGTH_NUM" ||
                                                    question.questionId === "BREATHING_BREATH") ? */`${COMPONENT_CLASS}__actions-no-continue`/* : ""}`*/}>
                                                    <BaseCardLayoutActions backButton={true} onBack={props.settingsOnBack}></BaseCardLayoutActions>
                                                </div>

                                            )
                                        }
                                        {
                                            // if
                                            props.isActionsWithContinue && (
                                                <div className={`${COMPONENT_CLASS}__actions`}>
                                                    <BaseCardLayoutActions backButton={true} onBack={props.settingsOnBack} submitButton={true} onSubmit={props.onSubmit} submitButtonText={props.submitButtonText} skipButton={props.skipButton}
                                                        onSkip={() => {
                                                            if (props.onSkip) {
                                                                props.onSkip();
                                                            }
                                                        }}></BaseCardLayoutActions>
                                                </div>
                                            )
                                        }


                                    </>
                                );
                            })}
                        </div>
                    </div>
                )
            }
            {
                // if
                props.page.slideType === SlideType.Complete &&
                props.page.categoryId === CategoryIds.MOVEMENT &&
                !props.isLinkAccountCreation && (
                    <MovementComplete errors={props.errors} />

                )
            }
            {
                // if
                props.page.slideType === SlideType.Complete &&
                props.page.categoryId === CategoryIds.LIFESTYLE &&
                !props.isLinkAccountCreation && (
                    <LifestyleComplete errors={props.errors} />
                )
            }
        </div >
    );
};

export default ScreenSlide;
