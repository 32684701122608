import React from "react";
import { LoginError } from "../../../models/enumerations/login-error";
import { useTranslation } from 'react-i18next';

export interface LoginMessageProps {
    error: LoginError,
    organization: string
}

/**
 * Represents the message that is shown when a login error occurs.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const LoginMessage: React.FC<LoginMessageProps> = (props) => {
    const { error, organization } = props;
    const { t } = useTranslation();

    let message = '';

    switch (error) {
        case LoginError.AdminDeactivated:
            message = t('login.modal.message.accDeactivated', { organization: organization });
            break;
        case LoginError.AdminDeactivatedBilling:
            message = t('login.modal.message.adminDeactivatedBilling', { organization: organization });
            break;
        case LoginError.AdminTrialExpired:
            message = t('login.modal.message.adminTrialExpired');
            break;
        case LoginError.TrialExpired:
            message = t('login.modal.message.trialExpired');
            break;
        case LoginError.SubscriptionPaymentFailed:
            message = t('login.modal.message.subscriptionPaymentFailed', { organization: organization });
            break;
        default:
            message = t('login.modal.message.default', { organization: organization });
    }

    return (
        <p className="block" style={{ textAlign: 'center', margin: '0 81px 0 81px' }}>{message}</p>
    );
};

export default LoginMessage;