import { Redirect, useHistory } from "react-router";
import { Button } from "../../components/button/button";
import "./leadlinks-page.styles.scss";
import LeadLinksList from "../../components/lead-links/lead-links-list";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "c-lead-links-list";

const LeadLinksPage: React.FC = () => {
    const { state } = useAuthState();
    const history = useHistory();
    const { t } = useTranslation();

    if (!state.user) {
        throw new Error("You must be a super admin");
    }

    const handleAddLink = () => {
        // TODO: Change url to new add link
        history.push('/lead-capture-link');
    }

    if (!state.user?.isSuperAdmin) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <div className={`${COMPONENT_CLASS}`} data-testid={'lead-links-list'}>
                <div className={`${COMPONENT_CLASS}__header`}>
                    <h1>{t('sitemap.links')}</h1>
                    <div className={`${COMPONENT_CLASS}__actions`}>
                        <Button
                            type="default"
                            buttonText={t('buttons.btn_addLeadCaptureLink')}
                            onClick={handleAddLink}
                        />
                    </div>
                </div>

                <LeadLinksList />

            </div>
        </>
    );
}

export default LeadLinksPage;
