import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Loader } from "../../components/loader/loader";
import { Group } from "../../models/interfaces/group";
import { User } from "../../models/interfaces/user";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import GroupService from "../../utilities/services/group-service";
import UserService from "../../utilities/services/user-service";
import { Utils } from "../../utilities/utils";
import { enqueueSnackbar } from "notistack";
import UserUtil from "../../utilities/user-util";
import { useTranslation } from 'react-i18next';
import { Tabs } from "../../models/enumerations/tabs";
import { PageTabs } from "../../components/page-tabs/page-tabs";
import GroupTab from "./group-tab";
import CustomizationTab from "./customization-tab";

interface GroupEditPageParams {
    organizationId: string;
    id: string;
}

const GroupEditPage: React.FC = () => {
    const COMPONENT_CLASS = "p-group-edit";
    const history = useHistory();
    const { t } = useTranslation();
    const { organizationId, id } = useParams<GroupEditPageParams>();
    const [isNewGroup, setIsNewGroup] = useState(id === undefined);
    const [isLoading, setIsLoading] = useState((isNewGroup ? false : true));
    const [group, setGroup] = useState<Group>({
        organizationId: organizationId,
        id: id
    } as Group);
    const [initialGroup, setInitialGroup] = useState<Group>({
        organizationId: organizationId,
        id: id
    } as Group);
    const [users, setUsers] = useState<User[]>({} as User[]);
    const [pendingGroupEmails, setPendingGroupEmails] = useState([] as string[]);
    const [nameErrorMessages, setNameErrorMessages] = useState([] as string[]);
    const { state, setState } = useAuthState();

    const [isEditingContact, setIsEditingContact] = useState<boolean>(false);

    const params = new URLSearchParams(window.location.search);
    const active = params.get("active");
    const initialTab = active ? Number(active) : Tabs.GroupDetails;

    useEffect(() => {
        const loadGroup = async () => {
            GroupService.get(id)
                .then((groupEntity) => {
                    if (groupEntity) {
                        setGroup(groupEntity);
                        setInitialGroup(groupEntity);
                        loadUsers()
                            .then(() => setIsLoading(false));
                    }
                })
        };

        const loadUsers = async () => {
            UserService.getBy([{
                field: "groupId",
                operator: "==",
                value: id,
            }]).then((groupUsers: User[]) => {
                setUsers(groupUsers);
            })
        }

        if (id) {
            loadGroup();
        }
    }, [id]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        let errorFlag = false;

        // TODO : reactive validation
        if (!group.name || group.name.length === 0) {
            setNameErrorMessages([t('forms.err_noName')]);
            errorFlag = true;
            return;
        }

        const createdGroup = await GroupService.save({
            ...group,
        }, state.user);

        if (pendingGroupEmails != null && pendingGroupEmails.length > 0) {
            const emails = pendingGroupEmails.filter((email) => email.trim().length > 0 && Utils.validateEmailAddress(email.trim()));

            for (const email of emails) {
                const user = await UserService.findFirstBy([{
                    field: "email",
                    operator: "==",
                    value: email,
                }]);

                if (user == null) {
                    continue;
                }

                await UserService.update({
                    ...user,
                    groupId: createdGroup.id,
                    groupName: createdGroup.name
                }, state.user);
            }
        }

        if (state.user && UserUtil.isManager(state.user)) {
            let updatedManager = state.user;

            if (state.user?.managedGroupIds && state.user?.managedGroupIds.length > 0) {
                if (!state.user?.managedGroupIds.includes(createdGroup.id)) {
                    updatedManager.managedGroupIds?.push(createdGroup.id);
                }
            }
            else {
                updatedManager.managedGroupIds = [createdGroup.id];
            }

            updatedManager = await UserService.update(updatedManager);
            setState((state) => ({
                ...state, ...{ user: updatedManager }
            }));
        }

        if (errorFlag) {
            return;
        } else {
            if (isNewGroup) {
                enqueueSnackbar(t('groups.edit.groupCreated'), { variant: "toast", width: "450px" });
                setGroup(createdGroup);
                setIsNewGroup(false);
            }
            else {
                enqueueSnackbar(t('groups.edit.groupUpdated'), { variant: "toast", width: "450px" });
            }

            history.push("/groups");
            setIsEditingContact(false);
        }
    };

    return (
        <>
            <h2>{(isNewGroup) ? t('buttons.btn_add') : t('buttons.btn_edit')} {t('users.group')}</h2>
            <Loader isVisible={isLoading} />

            <div className={`${COMPONENT_CLASS}__tab-content`}>
                <PageTabs components={[
                    {
                        tab: Tabs.GroupDetails, tabLabel: t('groups.edit.groupDetails_tab.header'), component: GroupTab, props: { group: group, setGroup: setGroup, handleSubmit: handleSubmit, isNewGroup: isNewGroup, nameErrorMessages: nameErrorMessages, users: users, pendingGroupEmails: pendingGroupEmails, setPendingGroupEmails: setPendingGroupEmails }
                    },
                    {
                        tab: Tabs.Customization, tabLabel: t('groups.edit.customization_tab.header'), component: CustomizationTab,
                        props: { group: group, setGroup: setGroup, initialGroup: initialGroup, isEditingContact: isEditingContact, setIsEditingContact: setIsEditingContact }
                    },
                ]} initialTab={initialTab} highlightLineId="groupTabsHighlightLine" hideTabs={isEditingContact} />
            </div>
        </>
    );
}

export default GroupEditPage;
