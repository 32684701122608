/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import AssessmentResponse from "../../models/interfaces/assessment-response.entity";
import { useEffect, useState } from "react";
import MskScoreService from "../../utilities/services/msk-score-service";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import wellnessProgramImg from "../../assets/images/wellness-program.png"
import PortalProgressBar from "../portal-progress-bar/portal-progress-bar";
import UserUtil from "../../utilities/user-util";
import { Button } from "../button/button";
import GrayFooterCard from "../gray-footer-card/gray-footer-card";
import { Utils } from "../../utilities/utils";
import TallyChart from "../tally-chart/tally-chart";
import DonutChart from "../charts/donut-chart";
import IToday from "../../models/interfaces/today";
import Card from "../card/card";
import { CardTypes } from "../../models/enumerations/card-types";
import TodayService from "../../utilities/services/today-service";
import { WellnessProgram } from "../../models/interfaces/wellness-program";
import WellnessProgramService from "../../utilities/services/wellness-program-service";
import { DateTime } from "luxon";
import { WellnessProgramActivity } from "../../models/enumerations/wellness-program-activity";
import UserService from "../../utilities/services/user-service";
import Badge from "../badge/badge";
import { ReactComponent as CalendarIcon } from "../../assets/icons/outline/icon_calendar.svg";
import UserArticle from "../../models/interfaces/articles/user-article";
import { CorrectiveExercise } from "../../models/interfaces/corrective-exercises/corrective-exercise";
import { CorrectiveExerciseUtils } from "../../utilities/corrective-exercise-utils";
import ArticleUtils from "../../utilities/article-utils";
import { UserMskFocusArticles } from "../../models/interfaces/articles/user-msk-focus-articles";
import { UserCorrectiveExercise } from "../../models/interfaces/corrective-exercises/user-corrective-exercise";
import UserCorrectiveExercisesService from "../../utilities/services/corrective-exercises-service/user-corrective-exercises-service";
import UserMskFocusArticlesUtil from "../../utilities/user-msk-focus-articles-util";
import { GroupIds } from "../../models/enumerations/group-ids";
import ExerciseModal from "./exercise-modal";
import ArticleModal from "./article-modal";
import UserMskFocusArticlesService from "../../utilities/services/article-service/user-msk-articles-service";
import { useTranslation } from "react-i18next";

const COMPONENT_CLASS = "c-user-profile-wellness-program";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileWellnessProgramProps {
    mskScore?: MskScore;
    validMskScore: boolean;
    allMskScores: MskScore[];
    setAllMskScores: Function;
    user: User;
    assessmentResponses: AssessmentResponse[];
    openLicenseModal: Function;
    hasAvailableLicenses: boolean;
    canAssignLicense: boolean;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileWellnessProgram: React.FC<UserProfileWellnessProgramProps> = (props: UserProfileWellnessProgramProps) => {
    const { user, allMskScores, setAllMskScores, mskScore } = props;
    const { t, i18n } = useTranslation();
    const { state } = useAuthState();
    const isAccountHolder = UserUtil.isAccountHolder(state.user);
    const [, setToday] = useState<IToday>();
    const [wellnessProgram, setWellnessProgram] = useState<WellnessProgram>();
    const [isLoadingWellnessProgram, setIsLoadingWellnessProgram] = useState(true);
    const [articlesCurrent, setArticlesCurrent] = useState<UserArticle[] | null>([]);
    const [articlesNext, setArticlesNext] = useState<UserArticle[] | null>([]);
    const [exercisesCurrent, setExercisesCurrent] = useState<{ exercise: CorrectiveExercise | null, extraExercise: CorrectiveExercise | null } | null>();
    const [exercisesNext, setExercisesNext] = useState<{ exercise: CorrectiveExercise | null, extraExercise: CorrectiveExercise | null } | null>();
    const [userMskCorrectiveExercises, setUserMskCorrectiveExercises] = useState<UserCorrectiveExercise | null>();
    const [userMskFocusArticles, setUserMskFocusArticles] = useState<UserMskFocusArticles | null>();
    const [openExerciseModal, setOpenExerciseModal] = useState(false);
    const [openArticleModal, setOpenArticleModal] = useState(false);
    const [exerciseModalData, setExerciseModalData] = useState<CorrectiveExercise>();
    const [articleModalData, setArticleModalData] = useState<UserArticle>();
    const [isLoadingActivities, setIsLoadingActivities] = useState(false);

    let adherence7 = 0;
    let adherence30 = 0;
    let adherenceAllTime = 0;

    if (user.lastSevenDays) {
        adherence7 = Math.round(user.lastSevenDays.filter(x => x === true).length / 7 * 100);
    }

    if (user.lastThirtyDays) {
        adherence30 = Math.round(user.lastThirtyDays.filter(x => x === true).length / 30 * 100);
    }

    if (user.allTimeAdherence) {
        adherenceAllTime = Math.round(user.allTimeAdherence.totalAdheredDays / user.allTimeAdherence.totalDays * 100);
    }

    useEffect(() => {
        const getAllMskScores = async () => {
            if (!allMskScores) {
                const mskScores = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }])

                setAllMskScores(mskScores);
            }
        }

        getAllMskScores();
    }, [])

    useEffect(() => {
        const getTodayAndWellnessProgram = async () => {
            if (user.id) {
                if (user.wellnessProgramId) {
                    let fetchedWellnessProgram = await WellnessProgramService.get(user.wellnessProgramId);

                    if (fetchedWellnessProgram) {
                        const userTimeZone = DateTime.fromISO(fetchedWellnessProgram.createdAtLocal).zone;

                        const fetchedToday = await TodayService.getToday(user.id, userTimeZone);
                        if (fetchedToday) {
                            setToday(fetchedToday);
                        }

                        // If Today exists it means the user used the mobile app today and to preserve data integrity, we need to avoid changing any data
                        if (!fetchedToday) {
                            if (!fetchedWellnessProgram.startDateLocalDateTimeISO) {
                                const wpCreationDate = DateTime.fromISO(fetchedWellnessProgram.createdAtLocal, { setZone: true }).startOf("day");

                                const dateNow = DateTime.now().startOf("day");

                                const diffInDays = dateNow.diff(wpCreationDate, ["days", "hours"]).toObject().days || 0;

                                // Check if it's been 14 days or more since the creation of the WP
                                if (diffInDays >= 14) {
                                    fetchedWellnessProgram.expirationDateLocalDateTimeISO = DateTime.now().startOf("day").toISO();

                                    await WellnessProgramService.save(fetchedWellnessProgram);
                                }
                            }
                            else {
                                let isWellnessProgramExpired = false;
                                // startOf("day") gets rid of the Time section on DateTime and keep only the date,
                                // e.g. 2001-01-31
                                const wpStartDateTime = DateTime.fromISO(fetchedWellnessProgram.startDateLocalDateTimeISO, { setZone: true }).startOf("day");
                                const userTimeZone = wpStartDateTime.zone;
                                const nowDateTime = DateTime.now().setZone(userTimeZone).startOf("day");

                                if (fetchedWellnessProgram.expirationDateLocalDateTimeISO) {
                                    const expirationDateTime = DateTime.fromISO(fetchedWellnessProgram.expirationDateLocalDateTimeISO, { setZone: true }).startOf("day");
                                    const expirationTimeZone = expirationDateTime.zone
                                    const nowDateTime = DateTime.now().setZone(expirationTimeZone).startOf("day");
                                    if (nowDateTime >= expirationDateTime) {
                                        fetchedWellnessProgram.isCurrent = false;
                                        user.wellnessProgramId = "";
                                        await WellnessProgramService.save(fetchedWellnessProgram);
                                        await UserService.update(user);
                                        const existingFocusArticles =
                                            await UserMskFocusArticlesUtil.getCurrentByUserId(user.id);
                                        if (existingFocusArticles && existingFocusArticles.isCurrent) {
                                            existingFocusArticles.isCurrent = false;
                                            await UserMskFocusArticlesService.update(existingFocusArticles);
                                        }
                                        const existingCorrectives = await UserCorrectiveExercisesService.getCurrentByUserId(user.id);
                                        if (existingCorrectives && existingCorrectives.length > 0) {
                                            for (const existingCorrective of existingCorrectives) {
                                                if (existingCorrective.isCurrent) {
                                                    existingCorrective.isCurrent = false
                                                    await UserCorrectiveExercisesService.update(existingCorrective);
                                                }
                                            }
                                        }
                                        isWellnessProgramExpired = true;
                                    }
                                }

                                // By default it returns a decimal number of days, e.g. 2.5476, but we need a whole number.
                                // That's why the code above cuts off the time part of the ISO datetime.
                                // Also, that's the reason we put [days, hours] as the unit, because if it's just days - the number will be decimal
                                let wpDaysSinceStart = isWellnessProgramExpired ? fetchedWellnessProgram.lengthInDays : nowDateTime.diff(wpStartDateTime, ["days", "hours"]).toObject().days || 0;

                                // Adding 1 because we need to know what day of WP it is: first, second, third, etc.
                                // wpDaysSinceStart += 1;
                                // Subtracting 1 because wpDaysSinceStart includes the current day
                                const missedDays = wpDaysSinceStart - fetchedWellnessProgram.currentProgress.length - (fetchedWellnessProgram.pausedDays ? fetchedWellnessProgram.pausedDays : 0);

                                if (missedDays > 0) {
                                    if (!user.lastSevenDays) {
                                        user.lastSevenDays = [false, false, false, false, false, false, false];
                                    }

                                    if (!user.lastThirtyDays) {
                                        user.lastThirtyDays = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
                                    }

                                    if (!user.allTimeAdherence) {
                                        user.allTimeAdherence = {
                                            totalAdheredDays: 0,
                                            totalDays: wpDaysSinceStart
                                        }
                                    }
                                    else {
                                        user.allTimeAdherence.totalDays += missedDays;
                                    }

                                    for (let i = 0; i < missedDays; i++) {
                                        if (fetchedWellnessProgram.currentProgress.length === fetchedWellnessProgram.lengthInDays) {
                                            break;
                                        }

                                        user.lastSevenDays.shift();
                                        user.lastSevenDays.push(false);

                                        user.lastThirtyDays.shift();
                                        user.lastThirtyDays.push(false);

                                        fetchedWellnessProgram.currentProgress.push(WellnessProgramActivity.Incomplete);
                                        fetchedWellnessProgram.numOfAdherenceAccountedFor += 1
                                    }

                                    fetchedWellnessProgram.currentDay = fetchedWellnessProgram.currentProgress.length + 1;

                                    await WellnessProgramService.save(fetchedWellnessProgram);
                                    await UserService.save(user);
                                }
                            }
                        }

                        setWellnessProgram(fetchedWellnessProgram);
                    }
                }
                else {
                    const wellnessProgram = await WellnessProgramService.getBy(
                        [{
                            field: "userId",
                            operator: "==",
                            value: user.id
                        }],
                        [{
                            field: "created",
                            direction: "desc"
                        }],
                        1);

                    if (wellnessProgram && wellnessProgram.length > 0) {
                        setWellnessProgram(wellnessProgram[0]);
                    }
                }
            }

            setIsLoadingWellnessProgram(false);
        }

        if (!wellnessProgram && isLoadingWellnessProgram) {
            getTodayAndWellnessProgram();
        }
    }, [user])

    useEffect(() => {
        const getExercisesAndArticles = async () => {
            if (wellnessProgram?.isCurrent && mskScore && user.id) {
                setIsLoadingActivities(true);

                if (wellnessProgram.startDateLocalDateTimeISO) {
                    const exercisesCurrentTemp = await UserUtil.getCorrectiveExercise(mskScore, user.id);
                    setExercisesCurrent(exercisesCurrentTemp);

                    const articlesCurrentTemp = await UserUtil.getUserArticle(mskScore, user.id);
                    setArticlesCurrent(articlesCurrentTemp);
                }

                const nextDay = wellnessProgram.startDateLocalDateTimeISO ? wellnessProgram.currentDay + 1 : wellnessProgram.currentDay;

                const exercisesNextTemp = await UserUtil.getCorrectiveExercise(mskScore, user.id, nextDay);
                setExercisesNext(exercisesNextTemp);

                const articlesNextTemp = await UserUtil.getUserArticle(mskScore, user.id, nextDay);
                setArticlesNext(articlesNextTemp);

                const userMskCorrectiveExercisesTemp = await UserCorrectiveExercisesService.getCurrentByUserId(user.id);
                setUserMskCorrectiveExercises(userMskCorrectiveExercisesTemp[0]);

                if (!userMskCorrectiveExercisesTemp || userMskCorrectiveExercisesTemp.length === 0) {
                    const userMskCorrectiveExercisesExtraTemp = await UserCorrectiveExercisesService.getCurrentExtraByUserId(user.id);

                    setUserMskCorrectiveExercises(userMskCorrectiveExercisesExtraTemp[0]);
                }

                const userMskFocusArticlesTemp = await UserMskFocusArticlesUtil.getCurrentByUserId(user.id);
                setUserMskFocusArticles(userMskFocusArticlesTemp);
            }

            setIsLoadingActivities(false);
        };

        if (wellnessProgram?.isCurrent && mskScore && user.id) {
            getExercisesAndArticles();
        }
    }, [wellnessProgram, mskScore, user.id])

    const getTallyMarks = () => {
        if (wellnessProgram) {
            let tallyMarks: any[] = [...wellnessProgram.currentProgress];

            for (let i = 0; i < wellnessProgram.lengthInDays - wellnessProgram.currentProgress.length; i++) {
                tallyMarks.push(null);
            }
            return tallyMarks;
        }

        return Array(30).fill(null);
    }

    const handleExerciseCardClick = (exercise: CorrectiveExercise | null) => {
        if (exercise) {
            setExerciseModalData(exercise);
            setOpenExerciseModal(true);
        }
    };

    const handleArticleCardClick = (userArticle: UserArticle) => {
        setArticleModalData(userArticle);
        setOpenArticleModal(true);
    };

    const getMovementDonutChart = () => {
        if (userMskCorrectiveExercises) {
            const numberCompleted = userMskCorrectiveExercises.completedDateMs.filter(val => val !== 0).length;
            let groupId = GroupIds.MOVEMENT_HEALTH
            if (userMskFocusArticles?.focusStatuses.find((focusStatus) => focusStatus.mskFocusAreaGroupId === GroupIds.BREATHING || focusStatus.mskFocusAreaGroupId === GroupIds.MOVEMENT_HEALTH_BREATHING)) {
                groupId = GroupIds.MOVEMENT_HEALTH_BREATHING;
            }
            return (
                <div className="flex">
                    <div className={`${COMPONENT_CLASS}__wellness-program-icon`}>
                        {Utils.getFocusAreaData(groupId).icon}
                    </div>
                    <div className="flex flex-col justify-center items-center max-w-24">
                        <div className={`${COMPONENT_CLASS}__donut-chart`}>
                            <DonutChart
                                percentage={numberCompleted / userMskCorrectiveExercises.routineDays * 100}
                                mainTextFontSize="16px"
                                mainText={`${numberCompleted}/${userMskCorrectiveExercises.routineDays}`}
                                fillColor="#43B5F8"
                            />
                        </div>
                        <div className="text-10 font-bold uppercase break-words text-center">
                            {Utils.getFocusAreaData(groupId).title}
                        </div>
                    </div>
                </div>
            )
        }

        return <></>;
    };

    return (
        <div className={`${COMPONENT_CLASS}`}>
            {exerciseModalData &&
                <ExerciseModal exercise={exerciseModalData} openExerciseModal={openExerciseModal} setOpenExerciseModal={setOpenExerciseModal} programProgressCurrent={userMskCorrectiveExercises?.completedDateMs.length} programProgressTotal={userMskCorrectiveExercises?.routineDays} />
            }

            {articleModalData &&
                <ArticleModal userArticle={articleModalData} openArticleModal={openArticleModal} setOpenArticleModal={setOpenArticleModal} />
            }

            {user.symmioAccess !== SymmioAccessType.AppLicense
                ?
                <div className="flex flex-wrap-reverse xl:justify-between justify-center items-center border border-black-gray-5 rounded-xl gap-8">
                    <div className="w-3/5 pl-10">
                        <h2 className="mb-4">{t('userProfile.wellnessProgram.noLicenseTitle')}</h2>
                        <p className="text-sm font-light">
                            {t('userProfile.wellnessProgram.noLicenseDescription')}
                        </p>
                        <div className="my-6">
                            <PortalProgressBar total={state.organization.mySymmioLicense!} currentProgress={state.organization.mySymmioLicenseCount!}
                                headerText={t('symmioLicenseCheckbox.progressBarText', {
                                    usedLicenses: state.organization.mySymmioLicenseCount!,
                                    totalLicenses: state.organization.mySymmioLicense!
                                })}
                                link={isAccountHolder ? "/settings" : undefined} linkText={isAccountHolder ? t('symmioLicenseCheckbox.manageAddOns') : undefined} />
                        </div>
                        <div className="mb-4">
                            {props.hasAvailableLicenses && props.canAssignLicense
                                ?
                                <Button
                                    type="default"
                                    buttonText={t('buttons.btn_enrollUser')}
                                    onClick={() => props.openLicenseModal()} />
                                :
                                !props.hasAvailableLicenses &&
                                <div className="text-sm font-medium">
                                    {t('userProfile.wellnessProgram.noLicenseAdd')}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="max-w-96 p-6 bg-blue-light" style={{ borderRadius: "0 10px 10px 0" }}>
                        <img src={wellnessProgramImg} alt="Your Wellness Program" />
                    </div>
                </div>
                :
                <div className="flex flex-wrap gap-6">
                    <div className={`${COMPONENT_CLASS}__section-header`}>
                        <div className="flex gap-2 items-center">
                            <div className="font-bold text-10 uppercase">{t('userProfile.wellnessProgram.programStatus')}</div>
                            {!mskScore || mskScore.movementScore?.percentage === null || mskScore.lifestyleScore?.percentage === null
                                ? <Badge text={t('userProfile.wellnessProgram.pendingAssessment')} bgColor="#D0D0D3" textColor="#121622" />
                                : wellnessProgram?.startDateLocalDateTimeISO && wellnessProgram.isCurrent
                                    ? <Badge text={t('userProfile.wellnessProgram.active')} bgColor="#00DFBC" textColor="#121622" />
                                    : wellnessProgram?.startDateLocalDateTimeISO && !wellnessProgram.isCurrent && !user.administeredRetake
                                        ? <Badge text={t('userProfile.wellnessProgram.ended')} bgColor="#E7514F" textColor="#FFFFFF" />
                                        : !wellnessProgram?.isCurrent && user.administeredRetake
                                            ? <Badge text={t('userProfile.wellnessProgram.pendingRetest')} bgColor="#D0D0D3" textColor="#121622" />
                                            : <Badge text={t('userProfile.wellnessProgram.pending')} bgColor="#D0D0D3" textColor="#121622" />
                            }
                        </div>

                        <div className="flex gap-6">
                            <div className="flex gap-2 items-center">
                                <div className={`${COMPONENT_CLASS}__icon-calendar`}><CalendarIcon /></div>
                                <div className="font-bold text-10 uppercase">{t('userProfile.wellnessProgram.startDate')}</div>
                                <div className="font-light text-xs">{wellnessProgram?.startDateLocalDateTimeISO ? DateTime.fromISO(wellnessProgram.startDateLocalDateTimeISO).setLocale(i18n.language).toLocaleString() : "-"}</div>
                            </div>

                            <div className="flex gap-2 items-center">
                                <div className={`${COMPONENT_CLASS}__icon-calendar`}><CalendarIcon /></div>
                                <div className="font-bold text-10 uppercase">{t('userProfile.wellnessProgram.startDate')}</div>
                                <div className="font-light text-xs">{wellnessProgram?.expirationDateLocalDateTimeISO ? DateTime.fromISO(wellnessProgram.expirationDateLocalDateTimeISO).setLocale(i18n.language).toLocaleString() : "-"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col grow gap-6">
                        <GrayFooterCard mainText={user?.lastLoggedIn ? moment(user?.lastLoggedIn).format("MM/DD/YYYY") : "-"} subText={t('userProfile.wellnessProgram.lastLoggedIn')} footerText={user?.lastLoggedIn ? Utils.getHowManyDaysAgoUsingMoment(user?.lastLoggedIn) : "-"} />

                        <GrayFooterCard mainText={user?.dayStreak !== undefined ? user.dayStreak.toString() : "0"} subText={t('userProfile.wellnessProgram.currentStreak')} footerText={user?.bestDayStreak !== undefined ? t('userProfile.wellnessProgram.bestStreak', { numOfDays: user.bestDayStreak.toString() }) : t('userProfile.wellnessProgram.bestStreak', { numOfDays: 0 })} />
                    </div>

                    <div className={`${COMPONENT_CLASS}__score-section-card justify-center items-center width-410`}>
                        <div className="w-full flex justify-between items-center">
                            <h3 className="m-0">
                                {t('userProfile.wellnessProgram.wpProgress')}
                            </h3>
                            <div className="text-2xl font-bold">
                                {((wellnessProgram?.currentProgress.length || 0) / (wellnessProgram?.lengthInDays || 30) * 100).toFixed(0)}%
                            </div>
                        </div>

                        <div className="w-full my-4">
                            <PortalProgressBar total={wellnessProgram?.lengthInDays || 30} currentProgress={wellnessProgram?.currentProgress.length || 0} headerText="" fillColor="#43B5F8" />
                        </div>

                        <div className={`w-full flex flex-wrap gap-2 ${userMskFocusArticles && userMskFocusArticles.focusStatuses.length > 2 ? "justify-between" : "justify-evenly"}`}>
                            {userMskFocusArticles && userMskFocusArticles.focusStatuses.length > 0 &&
                                userMskFocusArticles.focusStatuses.map((focusStatus) => {
                                    if (focusStatus.mskFocusAreaGroupId !== GroupIds.MOVEMENT_HEALTH && focusStatus.mskFocusAreaGroupId !== GroupIds.MOVEMENT_HEALTH_BREATHING && focusStatus.mskFocusAreaGroupId !== GroupIds.BREATHING) {
                                        const numberCompleted = focusStatus.completedArticles.filter(article => article.completedDateMs !== 0).length;

                                        return (
                                            <div className="flex">
                                                <div className={`${COMPONENT_CLASS}__wellness-program-icon`}>
                                                    {Utils.getFocusAreaData(focusStatus.mskFocusAreaGroupId).icon}
                                                </div>
                                                <div className="flex flex-col justify-center items-center max-w-24">
                                                    <div className={`${COMPONENT_CLASS}__donut-chart`}>
                                                        <DonutChart
                                                            percentage={numberCompleted / focusStatus.numArticles * 100}
                                                            mainTextFontSize="16px"
                                                            mainText={`${numberCompleted}/${focusStatus.numArticles}`}
                                                            fillColor="#43B5F8"
                                                        />
                                                    </div>
                                                    <div className="text-10 font-bold uppercase break-words text-center">
                                                        {Utils.getFocusAreaData(focusStatus.mskFocusAreaGroupId).title}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }

                                    return <></>;
                                })
                            }

                            {getMovementDonutChart()}
                        </div>
                    </div>

                    <div className={`${COMPONENT_CLASS}__score-section-card justify-center items-center width-410`}>
                        <div className="w-full flex justify-between">
                            <h3 className="mb-7">
                                {t('userProfile.wellnessProgram.adherence')}
                            </h3>
                            <div className="text-2xl font-bold">
                                {t('userProfile.wellnessProgram.daysRatio', { current: wellnessProgram?.currentProgress.filter(val => val === WellnessProgramActivity.Complete).length || 0, total: wellnessProgram?.lengthInDays || 30 })}
                            </div>
                        </div>

                        <div className="w-full">
                            <TallyChart tallyMarks={getTallyMarks()} />
                        </div>

                        <div className="w-full flex flex-wrap justify-between gap-4">
                            <div className="flex flex-col justify-center items-center">
                                <div className={`${COMPONENT_CLASS}__donut-chart`}>
                                    <DonutChart
                                        percentage={adherence7}
                                        showPercentSymbol={true}
                                        mainTextFontSize="16px"
                                        fillColor="#43B5F8"
                                    />
                                </div>
                                <div className="text-10 font-bold uppercase">
                                    {t('userProfile.wellnessProgram.adherence7')}
                                </div>
                            </div>

                            <div className="flex flex-col justify-center items-center">
                                <div className={`${COMPONENT_CLASS}__donut-chart`}>
                                    <DonutChart
                                        percentage={adherence30}
                                        showPercentSymbol={true}
                                        mainTextFontSize="16px"
                                        fillColor="#43B5F8"
                                    />
                                </div>
                                <div className="text-10 font-bold uppercase">
                                    {t('userProfile.wellnessProgram.adherence30')}
                                </div>
                            </div>

                            <div className="flex flex-col justify-center items-center">
                                <div className={`${COMPONENT_CLASS}__donut-chart`}>
                                    <DonutChart
                                        percentage={adherenceAllTime}
                                        showPercentSymbol={true}
                                        mainTextFontSize="16px"
                                        fillColor="#43B5F8"
                                    />
                                </div>
                                <div className="text-10 font-bold uppercase">
                                    {t('userProfile.wellnessProgram.adherenceAllTime')}
                                </div>
                            </div>
                        </div>
                    </div>

                    {wellnessProgram?.isCurrent &&
                        <div className="w-full flex flex-wrap gap-6">
                            <div
                                className={`${COMPONENT_CLASS}__score-section-card article-exercise-section`}
                            >
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <h3 style={{ margin: "0" }}>{t('userProfile.wellnessProgram.today')}</h3>
                                    </div>

                                    {wellnessProgram?.startDateLocalDateTimeISO
                                        ?
                                        (exercisesCurrent?.exercise || exercisesCurrent?.extraExercise || (articlesCurrent && articlesCurrent.length > 0))
                                            ?
                                            <>
                                                {exercisesCurrent?.exercise &&
                                                    <div onClick={() => handleExerciseCardClick(exercisesCurrent.exercise)}>
                                                        <Card
                                                            exercise={exercisesCurrent.exercise}
                                                            type={CardTypes.Flow}
                                                            title={
                                                                exercisesCurrent.exercise.name ?
                                                                    t(exercisesCurrent.exercise.name) :
                                                                    t('userProfile.wellnessProgram.dailyExercise')}
                                                            subtitle={t('userProfile.wellnessProgram.dailyCorrective')}
                                                            duration={CorrectiveExerciseUtils.getExerciseDuration(exercisesCurrent.exercise)}
                                                            cancelRedirect={true}
                                                        />
                                                    </div>
                                                }

                                                {exercisesCurrent?.extraExercise &&
                                                    <div onClick={() => handleExerciseCardClick(exercisesCurrent.extraExercise)}>
                                                        <Card
                                                            exercise={exercisesCurrent.extraExercise}
                                                            type={CardTypes.Flow}
                                                            title={
                                                                t('userProfile.wellnessProgram.dailyExercise')
                                                            }
                                                            subtitle={t('userProfile.wellnessProgram.bonus')}
                                                            duration={CorrectiveExerciseUtils.getExerciseDuration(exercisesCurrent.extraExercise)}
                                                            cancelRedirect={true}
                                                        />
                                                    </div>
                                                }

                                                {articlesCurrent && articlesCurrent.length > 0 &&
                                                    articlesCurrent.map((userArticle, index) => {
                                                        if (userArticle.article.translations?.[i18n.language]) {
                                                            return (
                                                                <div key={index} onClick={() => handleArticleCardClick(userArticle)}>
                                                                    <Card
                                                                        userArticle={userArticle}
                                                                        type={CardTypes.Flow}
                                                                        itemId={index}
                                                                        title={userArticle.article.translations[i18n.language].title}
                                                                        subtitle={
                                                                            userArticle.article.videoId
                                                                                ? t('userProfile.wellnessProgram.quickWatch')
                                                                                : t('userProfile.wellnessProgram.quickRead')
                                                                        }
                                                                        duration={
                                                                            userArticle.article.readTimeMin ??
                                                                            ArticleUtils.getArticleDuration(userArticle.article)
                                                                        }
                                                                        cancelRedirect={true}
                                                                    />
                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </>
                                            :
                                            !isLoadingActivities &&
                                            <div>
                                                <Card
                                                    imageUrl="public/rest-day.svg"
                                                    type={CardTypes.Rest}
                                                    title={t('userProfile.wellnessProgram.restDay')}
                                                    subtitle={t('userProfile.wellnessProgram.restDayDescription')}
                                                    cancelRedirect={true}
                                                />
                                            </div>
                                        :
                                        <p className="text-sm font-light">{t('userProfile.wellnessProgram.wpNotStarted')}</p>
                                    }
                                </div>
                            </div>

                            {/* CHECK IF IT'S THE LAST DAY OF WP */}
                            {wellnessProgram.currentProgress.length < wellnessProgram.lengthInDays &&
                                wellnessProgram.currentDay !== wellnessProgram.lengthInDays &&
                                <div
                                    className={`${COMPONENT_CLASS}__score-section-card article-exercise-section`}
                                >
                                    <div className="flex flex-col gap-4">
                                        <div>
                                            <h3 style={{ margin: "0" }}>{t('userProfile.wellnessProgram.upNext')}</h3>
                                        </div>

                                        {(exercisesNext?.exercise || exercisesNext?.extraExercise || (articlesNext && articlesNext.length > 0))
                                            ?
                                            <>
                                                {exercisesNext?.exercise &&
                                                    <div onClick={() => handleExerciseCardClick(exercisesNext.exercise)}>
                                                        <Card
                                                            exercise={exercisesNext.exercise}
                                                            type={CardTypes.Flow}
                                                            title={exercisesNext.exercise.name ?
                                                                t(exercisesNext.exercise.name) :
                                                                t('userProfile.wellnessProgram.dailyExercise')}
                                                            subtitle={t('userProfile.wellnessProgram.dailyCorrective')}
                                                            duration={CorrectiveExerciseUtils.getExerciseDuration(exercisesNext.exercise)}
                                                            cancelRedirect={true}
                                                        />
                                                    </div>
                                                }

                                                {exercisesNext?.extraExercise &&
                                                    <div onClick={() => handleExerciseCardClick(exercisesNext.extraExercise)}>
                                                        <Card
                                                            exercise={exercisesNext.extraExercise}
                                                            type={CardTypes.Flow}
                                                            title={
                                                                exercisesNext.extraExercise.name ?
                                                                    t(exercisesNext.extraExercise.name) :
                                                                    t('userProfile.wellnessProgram.dailyExercise')
                                                            }
                                                            subtitle={t('userProfile.wellnessProgram.bonus')}
                                                            duration={CorrectiveExerciseUtils.getExerciseDuration(exercisesNext.extraExercise)}
                                                            cancelRedirect={true}
                                                        />
                                                    </div>
                                                }

                                                {articlesNext && articlesNext.length > 0 &&
                                                    articlesNext.map((userArticle, index) => {
                                                        if (userArticle.article.translations?.[i18n.language]) {
                                                            return (
                                                                <div key={index} onClick={() => handleArticleCardClick(userArticle)}>
                                                                    <Card
                                                                        userArticle={userArticle}
                                                                        type={CardTypes.Flow}
                                                                        itemId={index}
                                                                        title={userArticle.article.translations[i18n.language].title}
                                                                        subtitle={
                                                                            userArticle.article.videoId
                                                                                ? t('userProfile.wellnessProgram.quickWatch')
                                                                                : t('userProfile.wellnessProgram.quickRead')
                                                                        }
                                                                        duration={
                                                                            userArticle.article.readTimeMin ??
                                                                            ArticleUtils.getArticleDuration(userArticle.article)
                                                                        }
                                                                        cancelRedirect={true}
                                                                    />
                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </>
                                            :
                                            !isLoadingActivities &&
                                            <div>
                                                <Card
                                                    imageUrl="public/rest-day.svg"
                                                    type={CardTypes.Rest}
                                                    title={t('userProfile.wellnessProgram.restDay')}
                                                    subtitle={t('userProfile.wellnessProgram.restDayDescription')}
                                                    cancelRedirect={true}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </div >
    );
}

export default UserProfileWellnessProgram;
