import React from "react";
// import { enUS } from "@mui/x-date-pickers/locales";
import 'dayjs/locale/en';
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from "react-i18next";
import { LanguageDateFormat } from "../../models/language-date-format";

const COMPONENT_CLASS = "c-date-input-option";

export interface DateInputOptionProps {
    onChange: (newAnswer: string) => void;
    answer?: Dayjs | null | string;
    defaultBirthdate?: string;
}

const DateInputOption: React.FC<DateInputOptionProps> = (props) => {
    const { i18n } = useTranslation();

    // TODO: Complaining about changing from controlled to uncontrolled
    return (
        <div className={`${COMPONENT_CLASS}`}>
            <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs}

            >
                <DatePicker
                    sx={{
                        background: "#1216220D",
                        borderRadius: "10px",
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '10px',
                            width: '291px',
                            height: '90px',
                            '& fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                    className="DatePicker"
                    value={props.answer ? dayjs(props.answer) : undefined}
                    format={LanguageDateFormat[i18n.language]}
                    maxDate={dayjs()}
                    onChange={(d) => {
                        let date = dayjs(d).format('MM/DD/YYYY')
                        props.onChange(date)
                        // let date =
                        //     e.detail.value == null
                        //         ? ""
                        //         : e.detail.value.toString();
                        // if (!StringUtils.isEmpty(date)) {
                        //     date = DateTime.fromISO(date).toISODate();
                        // }
                        // props.onChange(date);
                    }}
                // slotProps={{ inputAdornment: { style: { display: 'none' } } }}

                />

            </LocalizationProvider>
            {/* <input id="dateInput" type="date" onClick={() => {
                let date = document.getElementById("dateInput") as HTMLInputElement;
                if (date) {
                    date.showPicker();
                }

            }} placeholder="dd-mm-yyyy" max={today}></input>

            <input id="dateInput" type="text" placeholder="MM/DD/YYYY" max={today} value={props.answer}></input> */}

        </div >
    );
};

export default DateInputOption;
