import { ReportWellnessData } from "../../models/interfaces/reports/report-wellness-data";
import DonutChart from "../charts/donut-chart";
import ReportWellnessHeader from "./report-wellness-header";
import { ReactComponent as BehavioralHealthIcon } from "../../assets/icons/solid/behavioral-health.svg";
import { ReactComponent as MovementIcon } from "../../assets/icons/outline/movement.svg";
import { ReactComponent as MovementBreathingIcon } from "../../assets/icons/solid/movement-and-breathing.svg";
import { ReactComponent as PhysicalActivityIcon } from "../../assets/icons/solid/activity-level.svg";
import { ReactComponent as BodyCompIcon } from "../../assets/icons/solid/body-comp.svg";
import { ReactComponent as BreathingIcon } from "../../assets/icons/solid/breathing-qual.svg";
import { ReactComponent as InjuryHistoryIcon } from "../../assets/icons/solid/injury-history.svg";
import { ReactComponent as NutritionIcon } from "../../assets/icons/solid/nutrition-aware.svg";
import { ReactComponent as SleepIcon } from "../../assets/icons/solid/sleep-well.svg";
import { ReactComponent as RoadToWellnessIcon } from "../../assets/icons/solid/road-to-wellness.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/nav/icon_checkmark.svg";
import { ReactComponent as ExclamationkIcon } from "../../assets/icons/solid/exclamation.svg";
import { ReactComponent as LogoIcon } from "../../assets/icons/symmio-icons/icon_logo.svg";
import { ReactComponent as ImprovementPlanIcon } from "../../assets/icons/solid/icon_checklist.svg";
import { ReactComponent as ProgressTrackingIcon } from "../../assets/icons/solid/icon_graph.svg";
import { ReactComponent as DailyTipsIcon } from "../../assets/icons/solid/icon_lightbulb.svg";
import { ReactComponent as CrossRedIcon } from "../../assets/icons/solid/icon_white-cross-red-background.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/outline/icon_clock.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/outline/icon_calendar.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/general-icons/close.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/solid/check.svg";
import { ReactComponent as ChartArrowUp } from "../../assets/icons/solid/statistics-arrow-up.svg";
import { ReactComponent as ChartArrowDown } from "../../assets/icons/solid/statistics-arrow-down.svg";
import { FocusArea } from "../../models/enumerations/focus-area";
import PillChart from "../charts/pill-chart/pill-chart";
import { ReportAnswer } from "../../models/enumerations/report-answer";
import { InjuryHistoryResponse } from "../../models/interfaces/reports/injury-history-response";
import { HighRiskType } from "../../models/enumerations/high-risk-type";
import { MovementFocusArea } from "../../models/enumerations/movement-focus-area";
import qrCodeImg from "../../assets/images/qr-code-mobile-stores.png";
import qrCodeBehavioralHealth from "../../assets/images/symmio_qr_blog_behaviorial_health.png";
import qrCodeBodyComposition from "../../assets/images/symmio_qr_blog_body_composition.png";
import qrCodeBreathingQuality from "../../assets/images/symmio_qr_blog_breathing_quality.png";
import qrCodeInjuryHistory from "../../assets/images/symmio_qr_blog_injury_history.png";
import qrCodeMovementHealth from "../../assets/images/symmio_qr_blog_movement_health.png";
import qrCodeNutritionalAwareness from "../../assets/images/symmio_qr_blog_nutritional_awareness.png";
import qrCodePhysicalActivity from "../../assets/images/symmio_qr_blog_physical_activity.png";
import qrCodeSleepQuality from "../../assets/images/symmio_qr_blog_sleep_quality.png";
import qrHighRisk from "../../assets/images/symmio_qr_app_high_risk.jpg";
import qrWebMySymmio from "../../assets/images/symmio_qr_web_my_symmio.jpg";
import googlePlayImg from "../../assets/images/google-play-button.png";
import appStoreImg from "../../assets/images/app-store-button.png";
import logoStackedImg from "../../assets/images/symmio-logos/symmio-logo-horizontal-dark.png";
import devicesImg from "../../assets/images/devices.png";

// -----------------------------------------------------------------------------------------
// #region Images for Daily Corrective Exercises
// -----------------------------------------------------------------------------------------

//Toe Touch
import assistedLegLoweringImg from "../../assets/images/focus-area-exercises/assisted-leg-lowering.png";
import singleLegBridgeImg from "../../assets/images/focus-area-exercises/single-leg-bridge.png";
import hipFlexorStretchImg from "../../assets/images/focus-area-exercises/hip-flexor-stretch.png";
import toeTouchProgressionImg from "../../assets/images/focus-area-exercises/toe-touch-progression-toe-touch.png";

// Shoulder Mobility
import ribGrabsImg from "../../assets/images/focus-area-exercises/rib-grabs.png";
import lumbarLockedRotationImg from "../../assets/images/focus-area-exercises/lumbar-locked-rotation.png";
import halfKneelingRotatonWideImg from "../../assets/images/focus-area-exercises/half-kneeling-rotaton-wide.png";
import singleArmToeTouchProgressionShoulderMobilityImg from "../../assets/images/focus-area-exercises/toe-touch-progression-single-arm-shoulder.png";

// Rotation
import brettzel2ElbowsToFloorImg from "../../assets/images/focus-area-exercises/brettzel-2-elbows-to-floor.png";
import halfKneelingRotationImg from "../../assets/images/focus-area-exercises/half-kneeling-rotation.png";
import singleArmToeTouchProgressionRotationImg from "../../assets/images/focus-area-exercises/toe-touch-progression-single-arm-rotation.png";

// Squat
import ankleMobilitySquatImg from "../../assets/images/focus-area-exercises/ankle-mobility-squat.png";
import brettzel3Img from "../../assets/images/focus-area-exercises/brettzel-3.png";
import plankShoulderTapsImg from "../../assets/images/focus-area-exercises/plank-shoulder-taps.png";
import toeTouchProgressionSquatImg from "../../assets/images/focus-area-exercises/toe-touch-progression-squat.png";

// Balance
import ankleMobilityBalanceImg from "../../assets/images/focus-area-exercises/ankle-mobility-balance.png";
import balanceWithRotationImg from "../../assets/images/focus-area-exercises/balance-with-rotation.png";
import straightLegBridgeImg from "../../assets/images/focus-area-exercises/straight-leg-bridge.png";
import splitStanceToeTouchProgressionImg from "../../assets/images/focus-area-exercises/split-stance-toe-touch-progression.png";

// Pain
import figure4LowerBodyImg from "../../assets/images/focus-area-exercises/figure-4-lower-body.png";
import figure4UpperBodyImg from "../../assets/images/focus-area-exercises/figure-4-upper-body.png";
import quadrupedRockImg from "../../assets/images/focus-area-exercises/quadruped-rock.png";
import catCamelImg from "../../assets/images/focus-area-exercises/cat-camel.png";

// Shoulder Clearing
import tallKneelingTurnsImg from "../../assets/images/focus-area-exercises/tall-kneeling-turns.png";

// Spine Clearing
import crocodileBreathingImg from "../../assets/images/focus-area-exercises/crocodile-breathing.png";
import backBreathingImg from "../../assets/images/focus-area-exercises/back-breathing.png";

// Road to Wellness
import lateralLungesImg from "../../assets/images/focus-area-exercises/lateral-lunges.png";
import bearCrawlingImg from "../../assets/images/focus-area-exercises/bear-crawling.png";
import plankWalkoutImg from "../../assets/images/focus-area-exercises/plank-walkout.png";
import splitSquatImg from "../../assets/images/focus-area-exercises/split-squat.png";
import legLoweringImg from "../../assets/images/focus-area-exercises/leg-lowering.png";

// Breathing
import crocodileBreathingV2Img from "../../assets/images/focus-area-exercises/crocodile-breathing-v2.png";
import catCamelLungeImg from "../../assets/images/focus-area-exercises/cat-camel-lunge.png";
import backBreathingV2Img from "../../assets/images/focus-area-exercises/back-breathing-v2.png";

// #endregion Images for Daily Corrective Exercises

import LineChartJS from "../charts/line-chart-js";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { GroupIds } from "../../models/enumerations/group-ids";
import GroupUtil from "../../utilities/group-util";
import { Utils } from "../../utilities/utils";
import { PainSeverity } from "../../models/enumerations/pain-severity";

const COMPONENT_CLASS = "c-report";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportWellnessProps {
    data: ReportWellnessData;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportWellness: React.FC<ReportWellnessProps> = (props: ReportWellnessProps) => {
    const { data } = props;
    const { t, i18n } = useTranslation();

    const formatter = new Intl.ListFormat(i18n.language, {
        style: 'long',
        type: 'conjunction',
    });

    const assessmentScores = [
        { percentage: data.behavioralHealth?.percentage ?? 0, title: t('focus.behavioralHealth'), avg: data.behavioralHealth?.average },
        { percentage: data.movementHealth?.percentage ?? 0, title: t('focus.movementHealth'), avg: data.movementHealth?.average, groupId: GroupIds.MOVEMENT_HEALTH },
        { percentage: data.injuryHistory?.percentage ?? 0, title: t('focus.injuryHistory'), avg: data.injuryHistory?.average },
        { percentage: data.sleepWellness?.percentage ?? 0, title: t('focus.sleepWellness'), avg: data.sleepWellness?.average },
        { percentage: data.nutritionalAwareness?.percentage ?? 0, title: t('focus.nutritionalAwareness'), avg: data.nutritionalAwareness?.average },
        { percentage: data.bodyComposition?.percentage ?? 0, title: t('focus.bodyComposition'), avg: data.bodyComposition?.average },
        { percentage: data.breathingQuality?.percentage ?? 0, title: t('focus.breathingQuality'), avg: data.breathingQuality?.average },
        { percentage: data.physicalActivity?.percentage ?? 0, title: t('focus.physicalActivity'), avg: data.physicalActivity?.average },
    ]
    assessmentScores.sort((a, b) => (a.percentage < b.percentage) ? 1 : ((b.percentage < a.percentage) ? -1 : 0))

    let wellnessScoreLabel = t('reports.wellness.wellnessScoreLabel.low');
    let summary = "";

    if (data.wellnessScore && data.wellnessScore <= 69) {
        wellnessScoreLabel = t('reports.wellness.wellnessScoreLabel.low');
        summary = t('reports.wellness.summary.low');
    }
    else if (data.wellnessScore && data.wellnessScore > 69 && data.wellnessScore < 80) {
        wellnessScoreLabel = t('reports.wellness.wellnessScoreLabel.fair');
        summary = t('reports.wellness.summary.fair');
    }
    else if (data.wellnessScore && data.wellnessScore >= 80 && data.wellnessScore < 90) {
        wellnessScoreLabel = t('reports.wellness.wellnessScoreLabel.good');
        summary = t('reports.wellness.summary.good');
    }
    else if (data.wellnessScore && data.wellnessScore >= 90) {
        wellnessScoreLabel = t('reports.wellness.wellnessScoreLabel.excellent');
        summary = t('reports.wellness.summary.excellent');
    }

    const getAssessmentScoreCard = (title: string, percentage: number = 0, average: number = 0, groupId?: GroupIds) => {
        let isPercentageHigherThanAvg = percentage > average || percentage === 100;
        let showCheckMark = isPercentageHigherThanAvg ? true : false;

        let exclamationClassName = isPercentageHigherThanAvg ? "yellow-exclamation-icon" : "exclamation-icon";

        if (groupId === GroupIds.MOVEMENT_HEALTH && data.movementPain) {
            showCheckMark = false;

            if (data.movementHealth) {
                const painLevels = [data.movementHealth.toeTouchQPain,
                data.movementHealth.shoulderMobilityQPain,
                data.movementHealth.rotationQPain,
                data.movementHealth.squatQPain,
                data.movementHealth.balanceQPain,
                data.movementHealth.shoulderClearingQPain,
                data.movementHealth.spineClearingQPain];

                if (isPercentageHigherThanAvg && Utils.highestPainSeverity(painLevels) === PainSeverity.MILD) {
                    exclamationClassName = "yellow-exclamation-icon";
                }
            }
        }

        const textColorClassName = isPercentageHigherThanAvg ? "green" : "red";

        return (
            <div className={`${COMPONENT_CLASS}__assessment-score-card`}>
                <div className="flex flex-row justify-between items-center">
                    <span className={`text-07 ${textColorClassName}`}>{title}</span>
                    <span className="icon icon__16px">
                        {showCheckMark
                            ?
                            <CheckmarkIcon className="checkmark-icon" />
                            :
                            <ExclamationkIcon className={exclamationClassName} />
                        }
                    </span>
                </div>
                <div className="flex flex-row justify-between items-center">
                    <span className="font-bold text-base">{percentage}%</span>
                    <span className="text-07 font-light">{t('reports.wellness.avg', { average: average })}</span>
                </div>
            </div>
        );
    };

    const getReportDateRange = () => {
        const startDate = new Date();

        const endDate = new Date();
        if (data.currentMonth) {
            startDate.setMonth(startDate.getMonth() - 5)
            return `${startDate.toLocaleDateString(i18n.language, { month: 'long', year: 'numeric' })} - ${endDate.toLocaleDateString(i18n.language, { month: 'long', year: 'numeric' })}`
        }
        else {
            startDate.setMonth(startDate.getMonth() - 6)
            endDate.setMonth(endDate.getMonth() - 1)
            return `${startDate.toLocaleDateString(i18n.language, { month: 'long', year: 'numeric' })} - ${endDate.toLocaleDateString(i18n.language, { month: 'long', year: 'numeric' })}`
        }
    }

    const getFocusAreaData = (focusArea: string) => {
        let focusAreaData = {
            icon: <></>,
            title: "",
            description: "",
            symmioHelpsText: "",
            statisticsHeadline: "",
            statisticsText: "",
        }

        switch (focusArea) {
            case FocusArea.MOVEMENT_HEALTH:
                focusAreaData.icon = <MovementIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = t('focus.movementHealth');
                focusAreaData.description = t('reports.wellness.focusAreaData.movement.description');
                focusAreaData.symmioHelpsText = t('reports.wellness.focusAreaData.movement.symmioHelpsText');
                focusAreaData.statisticsHeadline = t('reports.wellness.focusAreaData.movement.statisticsHeadline');
                focusAreaData.statisticsText = t('reports.wellness.focusAreaData.movement.statisticsText');
                break;
            case FocusArea.BEHAVIORAL_HEALTH:
                focusAreaData.icon = <BehavioralHealthIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = t('focus.behavioralHealth');
                focusAreaData.description = t('reports.wellness.focusAreaData.behavioral.description');
                focusAreaData.symmioHelpsText = t('reports.wellness.focusAreaData.behavioral.symmioHelpsText');
                focusAreaData.statisticsHeadline = t('reports.wellness.focusAreaData.behavioral.statisticsHeadline');
                focusAreaData.statisticsText = t('reports.wellness.focusAreaData.behavioral.statisticsText');
                break;
            case FocusArea.INJURY_HISTORY:
                focusAreaData.icon = <InjuryHistoryIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = t('focus.injuryHistory');
                focusAreaData.description = t('reports.wellness.focusAreaData.injury.description');
                focusAreaData.symmioHelpsText = t('reports.wellness.focusAreaData.injury.symmioHelpsText');
                focusAreaData.statisticsHeadline = t('reports.wellness.focusAreaData.injury.statisticsHeadline');
                focusAreaData.statisticsText = t('reports.wellness.focusAreaData.injury.statisticsText');
                break;
            case FocusArea.BREATHING:
                focusAreaData.icon = <BreathingIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = t('focus.breathingQuality');
                focusAreaData.description = t('reports.wellness.focusAreaData.breathing.description');
                focusAreaData.symmioHelpsText = t('reports.wellness.focusAreaData.breathing.symmioHelpsText');
                focusAreaData.statisticsHeadline = t('reports.wellness.focusAreaData.breathing.statisticsHeadline');
                focusAreaData.statisticsText = t('reports.wellness.focusAreaData.breathing.statisticsText');
                break;
            case FocusArea.SLEEP:
                focusAreaData.icon = <SleepIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = t('focus.sleepWellness');
                focusAreaData.description = t('reports.wellness.focusAreaData.sleep.description');
                focusAreaData.symmioHelpsText = t('reports.wellness.focusAreaData.sleep.symmioHelpsText');
                focusAreaData.statisticsHeadline = t('reports.wellness.focusAreaData.sleep.statisticsHeadline');
                focusAreaData.statisticsText = t('reports.wellness.focusAreaData.sleep.statisticsText');
                break;
            case FocusArea.PHYSICAL_ACTIVITY:
                focusAreaData.icon = <PhysicalActivityIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = t('focus.physicalActivity');
                focusAreaData.description = t('reports.wellness.focusAreaData.activity.description');
                focusAreaData.symmioHelpsText = t('reports.wellness.focusAreaData.activity.symmioHelpsText');
                focusAreaData.statisticsHeadline = t('reports.wellness.focusAreaData.activity.statisticsHeadline');
                focusAreaData.statisticsText = t('reports.wellness.focusAreaData.activity.statisticsText');
                break;
            case FocusArea.BODY_COMPOSITION:
                focusAreaData.icon = <BodyCompIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = t('focus.bodyComposition');
                focusAreaData.description = t('reports.wellness.focusAreaData.bodyComp.description');
                focusAreaData.symmioHelpsText = t('reports.wellness.focusAreaData.bodyComp.symmioHelpsText');
                focusAreaData.statisticsHeadline = t('reports.wellness.focusAreaData.bodyComp.statisticsHeadline');
                focusAreaData.statisticsText = t('reports.wellness.focusAreaData.bodyComp.statisticsText');
                break;
            case FocusArea.NUTRITION:
                focusAreaData.icon = <NutritionIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = t('focus.nutritionalAwareness');
                focusAreaData.description = t('reports.wellness.focusAreaData.nutrition.description');
                focusAreaData.symmioHelpsText = t('reports.wellness.focusAreaData.nutrition.symmioHelpsText');
                focusAreaData.statisticsHeadline = t('reports.wellness.focusAreaData.nutrition.statisticsHeadline');
                focusAreaData.statisticsText = t('reports.wellness.focusAreaData.nutrition.statisticsText');
                break;
            case FocusArea.MOVEMENT_HEALTH_BREATHING:
                // focusAreaData.icon = (
                //     <div className="flex flex-row">
                //         <div className={`${COMPONENT_CLASS}__focus__item__icon-container icon icon__28px flex-col justify-center items-center`} style={{ backgroundColor: "#ADDFFD", padding: "0.3rem", display: "flex" }}>
                //             <MovementIcon style={{ backgroundColor: "#ADDFFD" }} />
                //         </div>
                //         <div className={`${COMPONENT_CLASS}__focus__item__icon-container icon icon__28px flex-col justify-center items-center`} style={{ backgroundColor: "#ADDFFD", padding: "0.3rem", display: "flex", marginLeft: "-0.3rem" }}>
                //             <BreathingIcon style={{ backgroundColor: "#ADDFFD" }} />
                //         </div>
                //     </div>
                // );
                focusAreaData.icon = <MovementBreathingIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = t('focus.movementHealthAndBreathing');
                focusAreaData.description = t('reports.wellness.focusAreaData.movementBreathing.description');
                focusAreaData.symmioHelpsText = t('reports.wellness.focusAreaData.movementBreathing.symmioHelpsText');
                focusAreaData.statisticsHeadline = t('reports.wellness.focusAreaData.movementBreathing.statisticsHeadline');
                focusAreaData.statisticsText = t('reports.wellness.focusAreaData.movementBreathing.statisticsText');
                break;
            case FocusArea.ROAD_TO_WELLNESS:
                focusAreaData.icon = <RoadToWellnessIcon style={{ backgroundColor: "#ADDFFD" }} />;
                focusAreaData.title = t('focus.roadToWellness');
                focusAreaData.description = t('reports.wellness.focusAreaData.roadToWellness.description');
                focusAreaData.symmioHelpsText = t('reports.wellness.focusAreaData.roadToWellness.symmioHelpsText');
                focusAreaData.statisticsHeadline = t('reports.wellness.focusAreaData.roadToWellness.statisticsHeadline');
                focusAreaData.statisticsText = t('reports.wellness.focusAreaData.roadToWellness.statisticsText');
                break;
        }

        return focusAreaData;
    };

    const getFocusAreaSection = (focusArea: string, showBorder: boolean, showPain: boolean = false) => {
        const focusAreaData = getFocusAreaData(focusArea);

        return (
            <div className={`flex flex-row gap-2 pt-5 ${showBorder && "border-gray-blue border-b-2 pb-5"}`}>
                <div className={`${COMPONENT_CLASS}__focus__item__icon-container icon icon__28px flex-col justify-center items-center`} style={{ backgroundColor: "#ADDFFD", padding: "0.3rem", display: "flex" }}>
                    {focusAreaData.icon}
                </div>

                <div className={`${showPain ? "pt-1" : "pt-2"}`}>
                    <div className="flex flex-col gap-2 mb-4">
                        <div className="flex flex-row justify-between items-center">
                            <span className="text-07 font-medium">{focusAreaData.title}</span>
                            <span>{getPainBadge(showPain)}</span>
                        </div>
                        <p className="font-light">{focusAreaData.description}</p>
                    </div>

                    <div className="flex flex-row justify-between gap-3">
                        <div className="w-1/2">
                            <div className="text-06 font-medium flex flex-row items-center mb-1 gap-1"><span className="icon icon__12px"><LogoIcon className="icon__12px" /></span>{t('reports.wellness.howSymmioHelps')}</div>
                            <p>{focusAreaData.symmioHelpsText}</p>
                        </div>
                        <div className="w-1/2 px-3 py-3 border-2 rounded-xl border-gray-blue">
                            <div className="text-xs font-bold mb-2">{focusAreaData.statisticsHeadline}</div>
                            <p>{focusAreaData.statisticsText}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const getCardNumber = (numberToShow: number, color: "red" | "blue") => {
        return (
            <div><span className={`${COMPONENT_CLASS}__card-number ${color}`}>{numberToShow}</span></div>
        );
    };

    const getProfessionalConsulationCard = (highRiskType: string) => {
        let text = "";
        let qrCodeSrc = "";

        if (highRiskType === HighRiskType.PAIN) {
            text = t('reports.wellness.highRiskRecommendations.pain');
            qrCodeSrc = qrHighRisk;
        }
        else if (highRiskType === HighRiskType.BEHAVIORAL) {
            text = t('reports.wellness.highRiskRecommendations.behavioral');
            qrCodeSrc = qrHighRisk;
        }
        else if (highRiskType === HighRiskType.BEHAVIORAL_AND_PAIN) {
            text = t('reports.wellness.highRiskRecommendations.behavioralAndPain');
            qrCodeSrc = qrHighRisk;
        }

        return (
            <div className="flex flex-row gap-2 p-4 background-gray-blue rounded-xl">
                {getCardNumber(1, "red")}
                <div className="pr-11">
                    <h2>{t('reports.wellness.profConsultation')}</h2>
                    <p className="whitespace-pre-line pt-2">
                        {text}
                    </p>
                </div>
                <div className="qr-code-container">
                    <div className="text-05 font-medium">{t('reports.wellness.viewRecs')}</div>
                    <div className="qr-code-wrapper">
                        <img src={qrCodeSrc} alt="QR Code" />
                    </div>
                </div>
            </div>
        );
    };

    const getExercises = () => {
        if (data.painCorrective || data.movementFocusArea || data.roadToWellnessFocusArea) {
            let text = "";
            let time = "";
            let cards: {
                imageSrc: string;
                title: string;
                time: string;
            }[] = [];

            let breathingExercises: { imageSrc: string; title: string; time: string; }[] | undefined;

            if (data.painCorrective) {
                text = t('reports.wellness.exercises.pain');
                time = t('reports.wellness.exercises.time1015');

                cards.push(
                    {
                        imageSrc: figure4LowerBodyImg,
                        title: t('reports.wellness.exercises.cards.figure4Lower'),
                        time: t('reports.wellness.exercises.time1minEach'),
                    },
                    {
                        imageSrc: figure4UpperBodyImg,
                        title: t('reports.wellness.exercises.cards.figure4Upper'),
                        time: t('reports.wellness.exercises.time1minEach'),
                    },
                    {
                        imageSrc: quadrupedRockImg,
                        title: t('reports.wellness.exercises.cards.quadruped'),
                        time: t('reports.wellness.exercises.time1min'),
                    },
                    {
                        imageSrc: catCamelImg,
                        title: t('reports.wellness.exercises.cards.catCamel'),
                        time: t('reports.wellness.exercises.time1min'),
                    },);
            }
            else if (data.roadToWellnessFocusArea) {
                text = t('reports.wellness.exercises.roadToWellness');
                time = t('reports.wellness.exercises.time10');

                cards.push(
                    {
                        imageSrc: legLoweringImg,
                        title: t('reports.wellness.exercises.cards.legLoweringStretch'),
                        time: t('reports.wellness.exercises.time2min'),
                    },
                    {
                        imageSrc: splitSquatImg,
                        title: t('reports.wellness.exercises.cards.splitSquat'),
                        time: t('reports.wellness.exercises.time2min'),
                    },
                    {
                        imageSrc: plankWalkoutImg,
                        title: t('reports.wellness.exercises.cards.plankWalkout'),
                        time: t('reports.wellness.exercises.time2min'),
                    },
                    {
                        imageSrc: bearCrawlingImg,
                        title: t('reports.wellness.exercises.cards.bear'),
                        time: t('reports.wellness.exercises.time1min'),
                    },
                    {
                        imageSrc: lateralLungesImg,
                        title: t('reports.wellness.exercises.cards.lateralLunges'),
                        time: t('reports.wellness.exercises.time2min'),
                    },);
            }
            else {
                switch (data.movementFocusArea) {
                    case MovementFocusArea.BALANCE:
                    case MovementFocusArea.BALANCE_BREATHING:
                        text = t('reports.wellness.exercises.balance');
                        time = t('reports.wellness.exercises.time1015');

                        cards.push(
                            {
                                imageSrc: ankleMobilityBalanceImg,
                                title: t('reports.wellness.exercises.cards.ankle'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },
                            {
                                imageSrc: balanceWithRotationImg,
                                title: t('reports.wellness.exercises.cards.balanceWithRotation'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },
                            {
                                imageSrc: straightLegBridgeImg,
                                title: t('reports.wellness.exercises.cards.straightLegBridge'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },
                            {
                                imageSrc: splitStanceToeTouchProgressionImg,
                                title: t('reports.wellness.exercises.cards.splitStance'),
                                time: t('reports.wellness.exercises.time2minEach'),
                            },);
                        break;
                    case MovementFocusArea.ROTATION:
                    case MovementFocusArea.ROTATION_BREATHING:
                        text = t('reports.wellness.exercises.rotation');
                        time = t('reports.wellness.exercises.time1015');

                        cards.push(
                            {
                                imageSrc: brettzel2ElbowsToFloorImg,
                                title: t('reports.wellness.exercises.cards.brettzel2'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },
                            {
                                imageSrc: halfKneelingRotationImg,
                                title: t('reports.wellness.exercises.cards.halfKneelingRotation'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },
                            {
                                imageSrc: singleArmToeTouchProgressionRotationImg,
                                title: t('reports.wellness.exercises.cards.singleArmToe'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },);
                        break;
                    case MovementFocusArea.SHOULDER_CLEARING:
                    case MovementFocusArea.SHOULDER_CLEARING_BREATHING:
                        text = t('reports.wellness.exercises.shoulderClearing');
                        time = t('reports.wellness.exercises.time1012');

                        cards.push(
                            {
                                imageSrc: ribGrabsImg,
                                title: t('reports.wellness.exercises.cards.ribGrabs'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },
                            {
                                imageSrc: tallKneelingTurnsImg,
                                title: t('reports.wellness.exercises.cards.tallTurns'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },
                            {
                                imageSrc: singleArmToeTouchProgressionShoulderMobilityImg,
                                title: t('reports.wellness.exercises.cards.singleArmToe'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },);
                        break;
                    case MovementFocusArea.SHOULDER_MOBILITY:
                    case MovementFocusArea.SHOULDER_MOBILITY_BREATHING:
                        text = t('reports.wellness.exercises.shoulderMobility');
                        time = t('reports.wellness.exercises.time1015');

                        cards.push(
                            {
                                imageSrc: ribGrabsImg,
                                title: t('reports.wellness.exercises.cards.ribGrabs'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },
                            {
                                imageSrc: lumbarLockedRotationImg,
                                title: t('reports.wellness.exercises.cards.lumbar'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },
                            {
                                imageSrc: halfKneelingRotatonWideImg,
                                title: t('reports.wellness.exercises.cards.halfKneelingRotationWide'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },
                            {
                                imageSrc: singleArmToeTouchProgressionShoulderMobilityImg,
                                title: t('reports.wellness.exercises.cards.singleArmToe'),
                                time: t('reports.wellness.exercises.time2minEach'),
                            },);
                        break;
                    case MovementFocusArea.SPINE_CLEARING:
                    case MovementFocusArea.SPINE_CLEARING_BREATHING:
                        text = t('reports.wellness.exercises.spineClearing');
                        time = t('reports.wellness.exercises.time10');

                        cards.push(
                            {
                                imageSrc: crocodileBreathingImg,
                                title: t('reports.wellness.exercises.cards.crocodile'),
                                time: t('reports.wellness.exercises.time2min'),
                            },
                            {
                                imageSrc: quadrupedRockImg,
                                title: t('reports.wellness.exercises.cards.quadruped'),
                                time: t('reports.wellness.exercises.time1min'),
                            },
                            {
                                imageSrc: backBreathingImg,
                                title: t('reports.wellness.exercises.cards.backBreathing'),
                                time: t('reports.wellness.exercises.time1min'),
                            },);
                        break;
                    case MovementFocusArea.SQUAT:
                    case MovementFocusArea.SQUAT_BREATHING:
                        text = t('reports.wellness.exercises.squat');
                        time = t('reports.wellness.exercises.time1015');

                        cards.push(
                            {
                                imageSrc: ankleMobilitySquatImg,
                                title: t('reports.wellness.exercises.cards.ankle'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },
                            {
                                imageSrc: brettzel3Img,
                                title: t('reports.wellness.exercises.cards.brettzel3'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },
                            {
                                imageSrc: plankShoulderTapsImg,
                                title: t('reports.wellness.exercises.cards.plankShoulder'),
                                time: t('reports.wellness.exercises.time30sec'),
                            },
                            {
                                imageSrc: toeTouchProgressionSquatImg,
                                title: t('reports.wellness.exercises.cards.toeTouchProgression'),
                                time: t('reports.wellness.exercises.time2minEach'),
                            },);
                        break;
                    case MovementFocusArea.TOE_TOUCH:
                    case MovementFocusArea.TOE_TOUCH_BREATHING:
                        text = t('reports.wellness.exercises.toeTouch');
                        time = t('reports.wellness.exercises.time1015');

                        cards.push(
                            {
                                imageSrc: assistedLegLoweringImg,
                                title: t('reports.wellness.exercises.cards.assistedLegLowering'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },
                            {
                                imageSrc: singleLegBridgeImg,
                                title: t('reports.wellness.exercises.cards.singleLegBridge'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },
                            {
                                imageSrc: hipFlexorStretchImg,
                                title: t('reports.wellness.exercises.cards.hipFlexorStretch'),
                                time: t('reports.wellness.exercises.time1minEach'),
                            },
                            {
                                imageSrc: toeTouchProgressionImg,
                                title: t('reports.wellness.exercises.cards.toeTouchProgression'),
                                time: t('reports.wellness.exercises.time2minEach'),
                            },);
                        break;
                }

                if (data.movementFocusArea && (
                    data.movementFocusArea === MovementFocusArea.BALANCE_BREATHING ||
                    data.movementFocusArea === MovementFocusArea.ROTATION_BREATHING ||
                    data.movementFocusArea === MovementFocusArea.SHOULDER_CLEARING_BREATHING ||
                    data.movementFocusArea === MovementFocusArea.SHOULDER_MOBILITY_BREATHING ||
                    data.movementFocusArea === MovementFocusArea.SPINE_CLEARING_BREATHING ||
                    data.movementFocusArea === MovementFocusArea.SQUAT_BREATHING ||
                    data.movementFocusArea === MovementFocusArea.TOE_TOUCH_BREATHING ||
                    data.movementFocusArea === MovementFocusArea.BREATHING
                )) {
                    breathingExercises = [
                        {
                            imageSrc: crocodileBreathingV2Img,
                            title: t('reports.wellness.exercises.cards.crocodile'),
                            time: t('reports.wellness.exercises.time2min'),
                        },
                        {
                            imageSrc: backBreathingV2Img,
                            title: t('reports.wellness.exercises.cards.backBreathing'),
                            time: t('reports.wellness.exercises.time2min'),
                        },
                        {
                            imageSrc: catCamelLungeImg,
                            title: t('reports.wellness.exercises.cards.catCamelLunge'),
                            time: t('reports.wellness.exercises.time2min'),
                        },
                    ];
                }
            }

            return (
                <div>
                    <div className="flex flex-row gap-2 p-4 background-gray-blue rounded-xl">
                        {getCardNumber(data.highRisk ? 3 : 2, "blue")}
                        <div>
                            <div className="flex flex-row justify-between items-center">
                                <h2 className="mb-2">{t('reports.wellness.exercises.preview')}</h2>
                                {time &&
                                    <span className="text-07 font-medium flex flex-row gap-1 items-center"><span><ClockIcon className="icon icon__16px" /></span>{time}</span>
                                }
                                {data.movementFocusAreaDuration ?
                                    <span className="text-07 font-medium flex flex-row gap-1 items-center"><span><CalendarIcon className="icon icon__16px" /></span>{t('reports.wellness.exercises.days', { duration: data.movementFocusAreaDuration })}</span> : ""
                                }
                            </div>
                            <p className="text-08"><span className="font-medium">{t('reports.wellness.exercises.exerciseFocus')}</span> {t(data.readableMovementFocusArea)}</p>
                            <br />
                            <p dangerouslySetInnerHTML={{ __html: text }}></p>
                            <br />

                            <div className="text-07 font-medium mb-3">{t('reports.wellness.exercises.performThese')}</div>

                            {breathingExercises
                                ?
                                <div className="flex flex-row flex-wrap gap-4 items-center">
                                    {breathingExercises.map((breathingExercise) => {
                                        return (
                                            <div className={`${COMPONENT_CLASS}__exercise-card`}>
                                                <div className="w-32" style={{ minWidth: "8rem", maxWidth: "8rem" }}>
                                                    <img src={breathingExercise.imageSrc} className="rounded-md" alt="Daily Exercise" />
                                                </div>
                                                <div className="flex flex-col gap-3">
                                                    <span className="text-07 font-medium">{breathingExercise.title}</span>
                                                    <span className="text-06 font-light flex flex-row gap-1 items-center"><span><ClockIcon className="icon icon__12px" /></span>{breathingExercise.time}</span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                :
                                cards && cards.length > 0 &&
                                <div className="flex flex-row flex-wrap gap-4 items-center">
                                    {cards.map((card) => {
                                        return (
                                            <div className={`${COMPONENT_CLASS}__exercise-card`}>
                                                <div className="w-32" style={{ minWidth: "8rem", maxWidth: "8rem" }}>
                                                    <img src={card.imageSrc} className="rounded-md" alt="Daily Exercise" />
                                                </div>
                                                <div className="flex flex-col gap-3">
                                                    <span className="text-07 font-medium">{card.title}</span>
                                                    <span className="text-06 font-light flex flex-row gap-1 items-center"><span><ClockIcon className="icon icon__12px" /></span>{card.time}</span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                        </div>
                    </div>

                    {breathingExercises && cards && cards.length > 0 &&
                        <>
                            <div className="break-after-page"></div>

                            <h1>{t('reports.wellness.yourNextSteps')}</h1>

                            <div className="flex flex-row gap-2 p-4 background-gray-blue rounded-xl">
                                {getCardNumber(data.highRisk ? 3 : 2, "blue")}
                                <div>
                                    <div className="flex flex-row justify-between items-center">
                                        <h2 className="mb-2">{t('reports.wellness.exercises.preview')}</h2>
                                        <span className="text-07 font-medium flex flex-row gap-1 items-center"><span><ClockIcon className="icon icon__16px" /></span>{time}</span>
                                    </div>

                                    <div className="flex flex-row flex-wrap gap-4 items-center">
                                        {cards.map((card) => {
                                            return (
                                                <div className={`${COMPONENT_CLASS}__exercise-card`}>
                                                    <div className="w-32" style={{ minWidth: "8rem", maxWidth: "8rem" }}>
                                                        <img src={card.imageSrc} className="rounded-md" alt="Daily Exercise" />
                                                    </div>
                                                    <div className="flex flex-col gap-3">
                                                        <span className="text-07 font-medium">{card.title}</span>
                                                        <span className="text-06 font-light flex flex-row gap-1 items-center"><span><ClockIcon className="icon icon__12px" /></span>{card.time}</span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>

            );
        }
    };

    let indexForSvg = 0;

    const getDomainOverviewSection = (icon: JSX.Element, title: string, description: string, whyImportantText: string, qrCode: string, percentage: number = 0, average: number = 0, groupId?: GroupIds) => {
        indexForSvg += 1;
        let isPercentageHigherThanAvg = percentage > average || percentage === 100;
        if (groupId === GroupIds.MOVEMENT_HEALTH && data.movementPain) {
            isPercentageHigherThanAvg = false;
        }

        return (
            <div className="flex flex-row gap-4">
                <div>
                    <div className="flex flex-col mb-4">
                        <div className="flex flex-row gap-3">
                            <div className={`${COMPONENT_CLASS}__focus__item__icon-container icon icon__36px`} style={{ backgroundColor: "#ADDFFD", padding: "0.35rem" }}>
                                {icon}
                            </div>
                            <h1 className="font-medium">{title}</h1>
                        </div>
                        <p className="font-light">{description}</p>
                    </div>

                    <div className="flex flex-row justify-between gap-6">
                        <div>
                            <div className="text-07 font-medium flex flex-row items-center mb-1 gap-1">{t('reports.wellness.domainOverview.whyImportant')}</div>
                            <p>{whyImportantText}</p>
                        </div>
                        <div>
                            <div className="flex flex-row gap-2 items-center border-gray-blue border-2 rounded-xl p-2">
                                <div className="w-16 text-06">{t('reports.wellness.domainOverview.moreAbout', { title: title })}</div>
                                <div className="w-16 h-16">
                                    <img src={qrCode} alt="QR Code" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-center items-center ml-2 background-gray-blue rounded-xl p-5">
                    <div className="flex flex-row items-center justify-center gap-1">
                        {isPercentageHigherThanAvg
                            ?
                            <>
                                <span className="icon icon__16px">
                                    <CheckmarkIcon className="checkmark-icon" />
                                </span>
                                <span className="green-text font-bold text-base">{percentage}%</span>
                            </>
                            :
                            <>
                                <span className="icon icon__16px">
                                    <CrossRedIcon />
                                </span>
                                <span className="red-text font-bold text-base">{percentage}%</span>
                            </>
                        }
                    </div>
                    <div className="max-h-full" style={{ minWidth: "1.25rem", maxWidth: "1.25rem" }}>
                        <svg width="100%" height="100%" viewBox="0 0 34 280" className=" overflow-visible">
                            <PillChart
                                key={indexForSvg}
                                uniqueId={indexForSvg.toString()}
                                index={0}
                                percentage={percentage}
                                linePercentage={average}
                                lineText={`${t('reports.wellness.avgCapital', { average: average })}`}
                                heightPixels={250}
                            />
                        </svg>
                    </div>
                </div>

            </div>
        );
    };

    const getAssessmentBreakdownCell = (text: string, showCheckMark: boolean) => {
        return (
            <span className="flex flex-row" style={{ gap: "2px" }}>
                <span>
                    {showCheckMark
                        ? <CheckIcon className="icon icon__8px" />
                        : <CrossIcon className="icon icon__8px" />}
                </span>
                <span dangerouslySetInnerHTML={{ __html: text }}></span>
            </span>
        );
    };

    const getAssessmentBreakdownColor = (score: number | undefined, goalScore: number) => {
        if (score !== undefined && score >= goalScore) {
            return "background-green";
        }
        else {
            return "background-red";
        }
    }

    const getPainBadge = (painAnswer: string | boolean) => {
        if (painAnswer && painAnswer !== ReportAnswer.A) {
            let text = "";
            let className = "pain-badge";

            switch (painAnswer) {
                case true:
                    text = t('reports.wellness.painBadge.pain');
                    break;
                case ReportAnswer.B:
                    text = t('reports.wellness.painBadge.mild');
                    break;
                case ReportAnswer.C:
                    text = t('reports.wellness.painBadge.moderate');
                    break;
                case ReportAnswer.D:
                    text = t('reports.wellness.painBadge.severe');
                    break;
            }

            return (
                <div className={className}>{text}</div>
            );
        }
    };

    const getClearingPainBadge = (painAnswer: string | boolean, type: number) => {
        if (painAnswer && painAnswer !== ReportAnswer.A && painAnswer !== ReportAnswer.B) {
            let text = "";
            if (type === 1) {
                text = t('reports.wellness.painBadge.shoulderClearing')
                return (
                    <div className="pain-badge">{text}</div>
                );
            }
            else if (type === 2) {
                text = t('reports.wellness.painBadge.spineClearing')
                return (
                    <div className="pain-badge">{text}</div>
                );
            }
        }

    };

    const getChartBadge = (oldScore: number, currentScore: number) => {
        const scoreDifference = currentScore - oldScore;
        let icon: JSX.Element = <></>;
        let text: string = "";
        let badgeColor: string = "";

        if (scoreDifference > 0) {
            icon = <ChartArrowUp className="w-3 h-auto" />;
            text = scoreDifference.toString();
            badgeColor = "green";
        }
        else if (scoreDifference === 0) {
            icon = <></>;
            text = "-";
            badgeColor = "gray";
        }
        else {
            icon = <ChartArrowDown className="w-3 h-auto" />;
            text = (scoreDifference * -1).toString();
            badgeColor = "red";
        }

        return (
            <div className={`chart-badge ${badgeColor} absolute top-5 right-5`}>
                {icon}
                {text}
            </div>
        );
    };

    const get2ColoredCells = (title: string, redMain: string, greenMain: string, isGreen: boolean) => {
        return (
            <div className="mt-3">
                <div className="text-07 font-medium mb-1 uppercase">{title}</div>
                <div className="flex flex-row" style={{ gap: "1px" }}>
                    <div className="w-1/3 relative">
                        <div className="background-red flex flex-col gap-1 justify-center items-center p-1 h-8">
                            <span className="text-07 font-medium">{redMain}</span>
                            <span className="text-07 font-light"></span>
                        </div>
                        {isGreen &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                {t('reports.wellness.you')}
                            </div>
                        }
                    </div>

                    <div className="w-1/3 relative">
                        <div className="background-green flex flex-col gap-1 justify-center items-center p-1 h-8">
                            <span className="text-07 font-medium">{greenMain}</span>
                            <span className="text-07 font-light"></span>
                        </div>
                        {!isGreen &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                {t('reports.wellness.you')}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };

    const get3ColoredCells = (title: string, redMain: string, redSub: string, orangeMain: string, orangeSub: string, greenMain: string, greenSub: string, reportAnswer: ReportAnswer | undefined) => {
        return (
            <div className="mt-3">
                <div className="text-07 font-medium mb-1 uppercase">{title}</div>
                <div className="flex flex-row" style={{ gap: "1px" }}>
                    <div className="w-1/3 relative">
                        <div className="background-red flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{redMain}</span>
                            <span className="text-07 font-light">{redSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.A &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                {t('reports.wellness.you')}
                            </div>
                        }
                    </div>


                    <div className="w-1/3 relative">
                        <div className="background-orange flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{orangeMain}</span>
                            <span className="text-07 font-light">{orangeSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.B &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                {t('reports.wellness.you')}
                            </div>
                        }
                    </div>

                    <div className="w-1/3 relative">
                        <div className="background-green flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{greenMain}</span>
                            <span className="text-07 font-light">{greenSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.C &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                {t('reports.wellness.you')}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };

    const get3ColoredCellsReversed = (title: string, greenMain: string, greenSub: string, orangeMain: string, orangeSub: string, redMain: string, redSub: string, reportAnswer: ReportAnswer) => {
        return (
            <div className="mt-3">
                <div className="text-07 font-medium mb-1 uppercase">{title}</div>
                <div className="flex flex-row" style={{ gap: "1px" }}>
                    <div className="w-1/3 relative">
                        <div className="background-green flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{greenMain}</span>
                            <span className="text-07 font-light">{greenSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.A &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                {t('reports.wellness.you')}
                            </div>
                        }
                    </div>

                    <div className="w-1/3 relative">
                        <div className="background-orange flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{orangeMain}</span>
                            <span className="text-07 font-light">{orangeSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.B &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                {t('reports.wellness.you')}
                            </div>
                        }
                    </div>

                    <div className="w-1/3 relative">
                        <div className="background-red flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{redMain}</span>
                            <span className="text-07 font-light">{redSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.C &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                {t('reports.wellness.you')}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };

    const get3GreyedCells = (title: string, firstMain: string, firstSub: string, secondMain: string, secondSub: string, thirdMain: string, thirdSub: string, reportAnswer: ReportAnswer) => {
        return (
            <div className="mt-3">
                <div className="text-07 font-medium mb-1 uppercase" style={{ color: "rgba(0, 0, 0, 0.25)" }}>{title}</div>
                <div className="flex flex-row" style={{ gap: "1px" }}>
                    <div className="w-1/3 relative">
                        <div className="flex flex-col gap-1 justify-center items-center p-1" style={{ backgroundColor: "#F7F9FC" }}>
                            <span className="text-07 font-medium" style={{ color: "rgba(0, 0, 0, 0.25)" }}>{firstMain}</span>
                            <span className="text-07 font-light" style={{ color: "rgba(0, 0, 0, 0.25)" }}>{firstSub}</span>
                        </div>
                    </div>

                    <div className="w-1/3 relative">
                        <div className="flex flex-col gap-1 justify-center items-center p-1" style={{ backgroundColor: "#F7F9FC" }}>
                            <span className="text-07 font-medium" style={{ color: "rgba(0, 0, 0, 0.25)" }}>{secondMain}</span>
                            <span className="text-07 font-light" style={{ color: "rgba(0, 0, 0, 0.25)" }}>{secondSub}</span>
                        </div>
                    </div>

                    <div className="w-1/3 relative">
                        <div className="flex flex-col gap-1 justify-center items-center p-1" style={{ backgroundColor: "#F7F9FC" }}>
                            <span className="text-07 font-medium" style={{ color: "rgba(0, 0, 0, 0.25)" }}>{thirdMain}</span>
                            <span className="text-07 font-light" style={{ color: "rgba(0, 0, 0, 0.25)" }}>{thirdSub}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const get4ColoredCells = (title: string, redMain: string, redSub: string, orangeMain: string, orangeSub: string, greenMain: string, greenSub: string, blueMain: string, blueSub: string, reportAnswer: ReportAnswer) => {
        return (
            <div className="mt-3">
                <div className="text-07 font-medium mb-1 uppercase">{title}</div>
                <div className="flex flex-row" style={{ gap: "1px" }}>
                    <div className="w-1/4 relative">
                        <div className="background-light-blue flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{blueMain}</span>
                            <span className="text-07 font-light">{blueSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.A &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                {t('reports.wellness.you')}
                            </div>
                        }
                    </div>

                    <div className="w-1/4 relative">
                        <div className="background-green flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{greenMain}</span>
                            <span className="text-07 font-light">{greenSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.B &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                {t('reports.wellness.you')}
                            </div>
                        }
                    </div>


                    <div className="w-1/4 relative">
                        <div className="background-orange flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{orangeMain}</span>
                            <span className="text-07 font-light">{orangeSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.C &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                {t('reports.wellness.you')}
                            </div>
                        }
                    </div>

                    <div className="w-1/4 relative">
                        <div className="background-red flex flex-col gap-1 justify-center items-center p-1">
                            <span className="text-07 font-medium">{redMain}</span>
                            <span className="text-07 font-light">{redSub}</span>
                        </div>
                        {reportAnswer === ReportAnswer.D &&
                            <div className="w-full text-06 bg-black-gray text-white flex flex-col justify-center items-center absolute" style={{ padding: "2px" }}>
                                {t('reports.wellness.you')}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };

    const getInjuryHistoryCells = (injuryHistory: InjuryHistoryResponse) => {
        let formattedPainAreas = "";

        if (injuryHistory.injuryHistoryPainAreas) {
            const keysArray = injuryHistory.injuryHistoryPainAreas.split(',');
            const translatedKeys = keysArray.map(key => t(key));
            formattedPainAreas = new Intl.ListFormat(i18n.language, { style: 'long', type: 'conjunction' }).format(translatedKeys);
        }

        return (
            <div className="mt-3">
                <div className="flex flex-row" style={{ gap: "1px" }}>
                    <div className="w-1/4 relative">
                        <div className="bg-black-gray bg-opacity-5 flex flex-col gap-1 justify-center items-center p-1 h-6"><span className="text-07 font-medium">{t('reports.wellness.previousInjury')}</span></div>
                        <div className={`${injuryHistory.injuryHistoryInjury ? "background-red" : "background-green"} flex flex-col gap-1 justify-center items-center p-1 h-6`}>
                            <span className="text-07 font-medium">{injuryHistory.injuryHistoryInjury ? t('general.yes') : t('general.no')}</span>
                        </div>
                    </div>

                    <div className="w-1/4 relative">
                        <div className="bg-black-gray bg-opacity-5 flex flex-col gap-1 justify-center items-center p-1 h-6"><span className="text-07 font-medium">{t('reports.wellness.currentPain')}</span></div>
                        <div className={`${injuryHistory.injuryHistoryPain ? "background-red" : "background-green"} flex flex-col gap-1 justify-center items-center p-1 h-6`}>
                            <span className="text-07 font-medium">{injuryHistory.injuryHistoryPain ? t('general.yes') : t('general.no')}</span>
                        </div>
                    </div>

                    <div className="w-1/4 relative">
                        <div className="bg-black-gray bg-opacity-5 flex flex-col gap-1 justify-center items-center p-1 h-6"><span className="text-07 font-medium">{t('reports.wellness.seenPhysician')}</span></div>
                        <div className={`${injuryHistory.injuryHistoryPainPhysician ? "background-green" : "background-red"} flex flex-col gap-1 justify-center items-center p-1 h-6`}>
                            <span className="text-07 font-medium">{injuryHistory.injuryHistoryPainPhysician ? t('general.yes') : t('general.no')}</span>
                        </div>
                    </div>

                    <div className="w-1/4 relative">
                        <div className="bg-black-gray bg-opacity-5 flex flex-col gap-1 justify-center items-center p-1 h-6"><span className="text-07 font-medium">{t('reports.wellness.recoveryScore')}</span></div>
                        <div className={`${injuryHistory.injuryHistorySaneScore >= 88 ? "background-green" : "background-red"} flex flex-col gap-1 justify-center items-center p-1 h-6`}>
                            <span className="text-07 font-medium">{injuryHistory.injuryHistorySaneScore}%</span>
                        </div>
                    </div>
                </div>

                {formattedPainAreas &&
                    <div className="text-07 pt-4">
                        <div className="font-medium uppercase">
                            {t('reports.wellness.injuryLocation')}
                        </div>
                        <div className="font-light">
                            {formattedPainAreas}
                        </div>
                    </div>
                }
            </div>
        );
    };

    // const getReportGraphs = () => {
    //     <div>
    //         <div className="flex flex-row justify-between">
    //             <h1>Your Progress</h1>
    //             <div className="text-xs font-light" style={{ color: "rgba(173, 223, 253, 1)" }}>November 2023 - April 2024</div>
    //         </div>
    //         <div className="flex flex-col mt-3">
    //             <div className="flex flex-row">
    //                 <div>
    //                     <div className="absolute top-1 right-1">
    //                         {getChartBadge(data.wellnessGraphData?.data[4] ? data.wellnessGraphData?.data[4] : data.wellnessGraphData?.data[5], data.wellnessGraphData?.data[5])}
    //                     </div>
    //                     <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels} data={data.wellnessGraphData?.data} title={data.wellnessGraphData?.title} />
    //                 </div>
    //             </div>
    //             <div className="flex flex-row">
    //                 <div>
    //                     <div className="absolute top-1 right-1">
    //                         {getChartBadge(data.movementHealthGraphData?.data[4] ? data.movementHealthGraphData?.data[4] : data.movementHealthGraphData?.data[5], data.movementHealthGraphData?.data[5])}
    //                     </div>
    //                     <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels} data={data.movementHealthGraphData?.data} title={data.movementHealthGraphData?.title} />
    //                 </div>
    //                 <div>
    //                     <div className="absolute top-1 right-1">
    //                         {getChartBadge(data.physicalActivityGraphData?.data[4] ? data.physicalActivityGraphData?.data[4] : data.physicalActivityGraphData?.data[5], data.physicalActivityGraphData?.data[5])}
    //                     </div>
    //                     <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels} data={data.physicalActivityGraphData?.data} title={data.physicalActivityGraphData?.title} />
    //                 </div>
    //             </div>
    //             <div className="flex flex-row">
    //                 <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels} data={data.bodyCompositionGraphData?.data} title={data.bodyCompositionGraphData?.title} />
    //                 <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels} data={data.injuryHistoryGraphData?.data} title={data.injuryHistoryGraphData?.title} />
    //             </div>
    //             <div className="flex flex-row">
    //                 <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels} data={data.nutritionAwarenessGraphData?.data} title={data.nutritionAwarenessGraphData?.title} />
    //                 <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels} data={data.behavioralHealthGraphData?.data} title={data.behavioralHealthGraphData?.title} />
    //             </div>
    //             <div className="flex flex-row">
    //                 <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels} data={data.sleepWellnessGraphData?.data} title={data.sleepWellnessGraphData?.title} />
    //                 <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels} data={data.breathingQualityGraphData?.data} title={data.breathingQualityGraphData?.title} />
    //             </div>
    //         </div>
    //     </div>
    // }

    // const titleCase = (str: string) => {
    //     var splitStr = str.toLowerCase().split(' ');
    //     for (var i = 0; i < splitStr.length; i++) {
    //         // You do not need to check if i is larger than splitStr length, as your for does that for you
    //         // Assign it back to the array
    //         splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    //     }
    //     // Directly return the joined string
    //     return splitStr.join(' ');
    // }

    return (
        <div className={`${COMPONENT_CLASS} -wellness`}>
            <ReportWellnessHeader name={data.userFullName} isFemale={data.isFemale} age={data.age} mskScoreCreatedDate={moment(data.mskScoreSecondsCreated ? data.mskScoreSecondsCreated * 1000 : NaN).format("MMM DD, YYYY")} />
            <div className="flex gap-6 background-gray-blue pt-6 pb-4 px-10">
                <div className="w-4/5">
                    <h1>{t('reports.wellness.yourSummary')}</h1>
                    <p>
                        {t('reports.wellness.description1')}
                        <br />
                        <br />
                        <span dangerouslySetInnerHTML={{ __html: summary }}></span>
                        <br />
                        <br />
                        {t('reports.wellness.description2')}
                    </p>
                </div>
                <div className="wellness-score-donut-chart-container">
                    <DonutChart
                        percentage={data.wellnessScore}
                        title={t('reports.wellness.donutTitle')}
                        badgeText={wellnessScoreLabel}
                    />
                    <div className="text-06 font-normal text-gray">{t('reports.wellness.goal')}</div>
                </div>
            </div>

            <div className="flex justify-between gap-10 pt-5 pl-10">
                {data.focusAreas && data.focusAreas.length > 0 &&
                    <div className="w-3/4 pb-5">
                        <h2 className="mb-3">{t('reports.wellness.yourFocuses')}</h2>
                        <p>
                            {t('reports.wellness.basedOn')} <strong>{formatter.format(data.focusAreas.map(fa => fa = t(GroupUtil.getNameTranslationReference(fa))))}</strong>
                        </p>

                        {data.focusAreas.map((focusArea, index) => {
                            return getFocusAreaSection(focusArea, index === 0, focusArea === FocusArea.MOVEMENT_HEALTH && data.movementPain); //show bottom border only on the 1st element
                        })}
                    </div>
                }

                {data.behavioralHealth && data.movementHealth && data.injuryHistory && data.sleepWellness && data.nutritionalAwareness && data.bodyComposition && data.breathingQuality && data.physicalActivity &&
                    <div className="flex flex-col gap-2 background-gray-blue p-4 rounded-xl">
                        <h2 className="text-center">{t('reports.wellness.yourScores')}</h2>
                        {assessmentScores.map((a) => {
                            return getAssessmentScoreCard(a.title, a.percentage, a.avg, a.groupId)
                        })}
                        {/* {getAssessmentScoreCard("Behavioral Health", data.behavioralHealth.percentage, data.behavioralHealth.average)}
                        {getAssessmentScoreCard("Movement Health", data.movementHealth.percentage, data.movementHealth.average)}
                        {getAssessmentScoreCard("Injury History", data.injuryHistory.percentage, data.injuryHistory.average)}
                        {getAssessmentScoreCard("Sleep Wellness", data.sleepWellness.percentage, data.sleepWellness.average)}
                        {getAssessmentScoreCard("Nutritional Awareness", data.nutritionalAwareness.percentage, data.nutritionalAwareness.average)}
                        {getAssessmentScoreCard("Body Composition", data.bodyComposition.percentage, data.bodyComposition.average)}
                        {getAssessmentScoreCard("Breathing Quality", data.breathingQuality.percentage, data.breathingQuality.average)}
                        {getAssessmentScoreCard("Physical Activity", data.physicalActivity.percentage, data.physicalActivity.average)} */}
                    </div>
                }
            </div>
            <div className="break-after-page"></div>

            <div className="px-10 pt-6">
                <h1>{t('reports.wellness.yourNextSteps')}</h1>
                <div className="flex flex-col gap-3">
                    {data.highRisk && data.highRiskType &&
                        getProfessionalConsulationCard(data.highRiskType)
                    }
                    {data.symmioAccess
                        ?
                        <>
                            <div className="flex flex-row gap-2 p-4 background-gray-blue rounded-xl">
                                {getCardNumber(data.highRisk ? 2 : 1, "blue")}
                                <div>
                                    <h2>{t('reports.wellness.personalizedProgram')}</h2>
                                    <p className="py-1" dangerouslySetInnerHTML={{ __html: t('reports.wellness.download') }}>
                                    </p>
                                    <div className="flex flex-row gap-3 items-center bg-white p-5 mt-4 rounded-xl">
                                        <div className="qr-code-wrapper">
                                            <img src={qrCodeImg} alt="QR Code" />
                                        </div>
                                        <div>
                                            <p>
                                                <b>
                                                    {t('reports.wellness.scan')}
                                                </b>
                                            </p>
                                            <p className="pt-2">
                                                <b>{t('reports.wellness.username')}</b> {data.userEmail}
                                            </p>
                                            <div className="mobile-stores pt-2">
                                                <a href="https://apps.apple.com/us/app/symmio/id1605214846" target="__blank"><img src={appStoreImg} alt="App Store" className="app-store-button" /></a>
                                                <a href="https://play.google.com/store/apps/details?id=com.functionalmovementsystems.symmio&hl=en_CA&gl=US" target="__blank"><img src={googlePlayImg} alt="Google Play" className="google-play-button" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {getExercises()}
                        </>
                        :
                        <div className="flex flex-col gap-3 p-4 background-gray-blue rounded-xl">
                            <div className="flex flex-row gap-2">
                                {getCardNumber(data.highRisk ? 2 : 1, "blue")}
                                <div className="pr-6">
                                    <h2>{t('reports.wellness.move')}</h2>
                                    <p className="pt-2">
                                        <b>
                                            {t('reports.wellness.guide')}
                                        </b>
                                        <br />
                                        <br />
                                        {t('reports.wellness.optimize')}
                                    </p>
                                </div>
                                <div className="qr-code-container">
                                    <div className="text-05 font-medium">{t('reports.wellness.learn')}</div>
                                    <div className="qr-code-wrapper">
                                        <img src={qrWebMySymmio} alt="QR Code" />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-2 p-4 justify-center">
                                <div>
                                    <div className="icon icon__24px">
                                        <ImprovementPlanIcon />
                                    </div>
                                    <span className="text-07">{t('reports.wellness.improvementTitle')}</span>
                                    <p>
                                        {t('reports.wellness.improvementText')}
                                    </p>
                                </div>

                                <div>
                                    <div className="icon icon__24px">
                                        <ProgressTrackingIcon />
                                    </div>
                                    <span className="text-07">{t('reports.wellness.trackTitle')}</span>
                                    <p>
                                        {t('reports.wellness.trackText')}
                                    </p>
                                </div>

                                <div>
                                    <div className="icon icon__24px">
                                        <DailyTipsIcon />
                                    </div>
                                    <span className="text-07">{t('reports.wellness.tipsTitle')}</span>
                                    <p>
                                        {t('reports.wellness.tipsText')}
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col justify-center items-center">
                                <div className="flex flex-row justify-center gap-5 bg-white rounded-xl p-4 w-fit">
                                    <div className="w-56">
                                        <img src={devicesImg} alt="Symmio Devices" />
                                    </div>
                                    <div className="flex flex-col justify-center items-center text-center gap-2">
                                        <div className="w-14">
                                            <img src={logoStackedImg} alt="Symmio Logo" />
                                        </div>
                                        <div className="text-08 font-medium text-blue-dark" dangerouslySetInnerHTML={{ __html: t('reports.wellness.askAdmin') }}>
                                        </div>
                                        <div className="text-08 font-light">
                                            {data.accountHolderEmail}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="break-after-page"></div>

            <div className="px-10 pt-6">
                {data.movementHealth &&
                    <div>
                        {getDomainOverviewSection(
                            <MovementIcon style={{ backgroundColor: "#ADDFFD" }} />,
                            t('focus.movementHealth'),
                            t('reports.wellness.domainOverview.movement.description'),
                            t('reports.wellness.domainOverview.movement.why'),
                            qrCodeMovementHealth,
                            data.movementHealth.percentage,
                            data.movementHealth.average,
                            GroupIds.MOVEMENT_HEALTH,
                        )}

                        <div>
                            <h2 className="mt-4 mb-4">{t('reports.wellness.breakdown')}</h2>
                            <table className="text-07 assessment-breakdown">
                                <thead>
                                    <tr>
                                        <th className="text-start">{t('reports.wellness.assessmentCapital')}</th>
                                        <th className="text-start">{t('reports.wellness.goalCapital')}</th>
                                        <th className="text-start">{t('reports.wellness.yourScoreCapital')}</th>
                                        <th className="text-start">{t('reports.wellness.painCapital')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{t('movementFocus.toeTouch')}</td>
                                        <td>{t('reports.wellness.score75')}</td>
                                        <td className="text-05">
                                            <div className={`flex flex-row justify-start gap-4 items-center py-2 px-5 w-4/5 ${getAssessmentBreakdownColor(data.movementHealth.toeTouchScore, 75)}`}>
                                                <span className="text-xs font-bold">{data.movementHealth.toeTouchScore}</span>
                                                <div className="flex flex-col" style={{ gap: "2px" }}>
                                                    <span className="font-medium">{t('reports.wellness.leftSide')}</span>
                                                    {getAssessmentBreakdownCell(t('reports.wellness.frontToe'), data.movementHealth.toeTouchReachLeft.itemOne)}
                                                    {getAssessmentBreakdownCell(t('reports.wellness.backToe'), data.movementHealth.toeTouchReachLeft.itemTwo)}

                                                    <span className="font-medium">{t('reports.wellness.rightSide')}</span>
                                                    {getAssessmentBreakdownCell(t('reports.wellness.frontToe'), data.movementHealth.toeTouchReachRight.itemOne)}
                                                    {getAssessmentBreakdownCell(t('reports.wellness.backToe'), data.movementHealth.toeTouchReachRight.itemTwo)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>{getPainBadge(data.movementHealth.toeTouchQPain)}</td>
                                    </tr>

                                    <tr>
                                        <td>{t('movementFocus.shoulderMobility')}</td>
                                        <td>{t('reports.wellness.score75')}</td>
                                        <td className="text-05">
                                            <div className={`flex flex-row justify-start gap-4 items-center py-2 px-5 w-4/5 ${getAssessmentBreakdownColor(data.movementHealth.shoulderMobilityScore, 75)}`}>
                                                <span className="text-xs font-bold">{data.movementHealth.shoulderMobilityScore}</span>
                                                <div className="flex flex-col" style={{ gap: "2px" }}>
                                                    <span className="font-medium">{t('reports.wellness.leftSide')}</span>
                                                    {getAssessmentBreakdownCell(t('reports.wellness.paperPass'), data.movementHealth.shoulderMobilityReachLeft.itemOne)}
                                                    {getAssessmentBreakdownCell(t('reports.wellness.fingertipsTouch'), data.movementHealth.shoulderMobilityReachLeft.itemTwo)}

                                                    <span className="font-medium">{t('reports.wellness.rightSide')}</span>
                                                    {getAssessmentBreakdownCell(t('reports.wellness.paperPass'), data.movementHealth.shoulderMobilityReachRight.itemOne)}
                                                    {getAssessmentBreakdownCell(t('reports.wellness.fingertipsTouch'), data.movementHealth.shoulderMobilityReachRight.itemTwo)}
                                                </div>
                                            </div>
                                        </td>
                                        {(data.movementHealth.shoulderMobilityQPain && data.movementHealth.shoulderMobilityQPain !== ReportAnswer.A) ?
                                            <td>{getPainBadge(data.movementHealth.shoulderMobilityQPain)}</td> : <td>{getClearingPainBadge(data.movementHealth.shoulderClearingQPain, 1)}</td>}
                                    </tr>

                                    <tr>
                                        <td>{t('movementFocus.rotation')}</td>
                                        <td>{t('reports.wellness.score75')}</td>
                                        <td className="text-05">
                                            <div className={`flex flex-row justify-start gap-4 items-center py-2 px-5 w-4/5 ${getAssessmentBreakdownColor(data.movementHealth.rotationScore, 75)}`}>
                                                <span className="text-xs font-bold">{data.movementHealth.rotationScore}</span>
                                                <div className="flex flex-col" style={{ gap: "2px" }}>
                                                    <span className="font-medium">{t('reports.wellness.leftSide')}</span>
                                                    {getAssessmentBreakdownCell(t('reports.wellness.feet'), data.movementHealth.rotationReachLeft.itemOne)}
                                                    {getAssessmentBreakdownCell(t('reports.wellness.rightFoot'), data.movementHealth.rotationReachLeft.itemTwo)}

                                                    <span className="font-medium">{t('reports.wellness.rightSide')}</span>
                                                    {getAssessmentBreakdownCell(t('reports.wellness.feet'), data.movementHealth.rotationReachRight.itemOne)}
                                                    {getAssessmentBreakdownCell(t('reports.wellness.leftFoot'), data.movementHealth.rotationReachRight.itemTwo)}
                                                </div>
                                            </div>
                                        </td>
                                        {(data.movementHealth.rotationQPain && data.movementHealth.rotationQPain !== ReportAnswer.A) ? <td>{getPainBadge(data.movementHealth.rotationQPain)}</td> : <td>{getClearingPainBadge(data.movementHealth.spineClearingQPain, 2)}</td>}

                                    </tr>

                                    <tr>
                                        <td>{t('movementFocus.squat')}</td>
                                        <td>{t('reports.wellness.score50')}</td>
                                        <td className="text-05">
                                            <div className={`flex flex-row justify-start gap-4 items-center py-2 px-5 w-4/5 ${getAssessmentBreakdownColor(data.movementHealth.squatScore, 50)}`}>
                                                <span className="text-xs font-bold">{data.movementHealth.squatScore}</span>
                                                <div className="flex flex-col" style={{ gap: "2px" }}>
                                                    {getAssessmentBreakdownCell(t('reports.wellness.squat'), data.movementHealth.squatLow.itemOne)}
                                                    {getAssessmentBreakdownCell(t('reports.wellness.fingertips'), data.movementHealth.squatLow.itemTwo)}
                                                    {getAssessmentBreakdownCell(t('reports.wellness.fists'), data.movementHealth.squatLow.itemThree ?? true)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>{getPainBadge(data.movementHealth.squatQPain)}</td>
                                    </tr>

                                    <tr>
                                        <td>{t('movementFocus.balanceAndReach')}</td>
                                        <td>{t('reports.wellness.score75')}</td>
                                        <td className="text-05">
                                            <div className={`flex flex-row justify-start gap-4 items-center py-2 px-5 w-4/5 ${getAssessmentBreakdownColor(data.movementHealth.balanceScore, 75)}`}>
                                                <span className="text-xs font-bold">{data.movementHealth.balanceScore}</span>
                                                <div className="flex flex-col" style={{ gap: "2px" }}>
                                                    <span className="font-medium">{t('reports.wellness.oneFoot')}</span>
                                                    {getAssessmentBreakdownCell(t('reports.wellness.right'), data.movementHealth.firstBalanceReach.itemOne)}
                                                    {getAssessmentBreakdownCell(t('reports.wellness.left'), data.movementHealth.firstBalanceReach.itemTwo)}

                                                    <span className="font-medium">{t('reports.wellness.twoFeet')}</span>
                                                    {getAssessmentBreakdownCell(t('reports.wellness.rightAttempts', { attempts: data.movementHealth.secondBalanceReachLeft.attempts }), data.movementHealth.secondBalanceReachLeft.success)}
                                                    {getAssessmentBreakdownCell(t('reports.wellness.leftAttempts', { attempts: data.movementHealth.secondBalanceReachRight.attempts }), data.movementHealth.secondBalanceReachRight.success)}

                                                    <span className="font-medium">{t('reports.wellness.twoHalfFeet')}</span>
                                                    {getAssessmentBreakdownCell(t('reports.wellness.rightAttempts', { attempts: data.movementHealth.thirdBalanceReachLeft.attempts }), data.movementHealth.thirdBalanceReachLeft.success)}
                                                    {getAssessmentBreakdownCell(t('reports.wellness.leftAttempts', { attempts: data.movementHealth.thirdBalanceReachRight.attempts }), data.movementHealth.thirdBalanceReachRight.success)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>{getPainBadge(data.movementHealth.balanceQPain)}</td>
                                    </tr>

                                    <tr>
                                        <td>{t('movementFocus.shoulderClearing')}</td>
                                        <td>{t('reports.wellness.passNoPain')}</td>
                                        <td className="text-05">
                                            <div className={`flex flex-row justify-center gap-4 items-center py-2 px-5 w-4/5 ${data.movementHealth.shoulderClearingScore ? "background-green" : "background-red"}`}>
                                                <span>
                                                    {data.movementHealth.shoulderClearingScore ? t('general.pass') : t('general.fail')}
                                                </span>
                                            </div>
                                        </td>
                                        <td>{getPainBadge(data.movementHealth.shoulderClearingQPain)}</td>
                                    </tr>

                                    <tr>
                                        <td>{t('movementFocus.spineClearing')}</td>
                                        <td>{t('reports.wellness.passNoPain')}</td>
                                        <td className="text-05">
                                            <div className={`flex flex-row justify-center gap-4 items-center py-2 px-5 w-4/5 ${data.movementHealth.spineClearingScore ? "background-green" : "background-red"}`}>
                                                <span>
                                                    {data.movementHealth.spineClearingScore ? t('general.pass') : t('general.fail')}
                                                </span>
                                            </div>
                                        </td>
                                        <td>{getPainBadge(data.movementHealth.spineClearingQPain)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                <div className="break-after-page"></div>

                {data.physicalActivity &&
                    <div className="pt-6">
                        {getDomainOverviewSection(
                            <PhysicalActivityIcon style={{ backgroundColor: "#ADDFFD" }} />,
                            t('focus.physicalActivity'),
                            t('reports.wellness.domainOverview.activity.description'),
                            t('reports.wellness.domainOverview.activity.why'),
                            qrCodePhysicalActivity,
                            data.physicalActivity.percentage,
                            data.physicalActivity.average,
                        )}

                        {get3ColoredCells(
                            t('reports.wellness.coloredCells.moderateActivity.title'),
                            t('reports.wellness.coloredCells.moderateActivity.redMain'),
                            t('reports.wellness.coloredCells.moderateActivity.redSub'),
                            t('reports.wellness.coloredCells.moderateActivity.orangeMain'),
                            t('reports.wellness.coloredCells.moderateActivity.orangeSub'),
                            t('reports.wellness.coloredCells.moderateActivity.greenMain'),
                            t('reports.wellness.coloredCells.moderateActivity.greenSub'),
                            data.physicalActivity.physicalActivityModerate,
                        )}

                        <br />

                        {get3ColoredCells(
                            t('reports.wellness.coloredCells.vigorousActivity.title'),
                            t('reports.wellness.coloredCells.vigorousActivity.redMain'),
                            t('reports.wellness.coloredCells.vigorousActivity.redSub'),
                            t('reports.wellness.coloredCells.vigorousActivity.orangeMain'),
                            t('reports.wellness.coloredCells.vigorousActivity.orangeSub'),
                            t('reports.wellness.coloredCells.vigorousActivity.greenMain'),
                            t('reports.wellness.coloredCells.vigorousActivity.greenSub'),
                            data.physicalActivity.physicalActivityVigorous,
                        )}

                        <br />

                        {get3ColoredCells(
                            t('reports.wellness.coloredCells.strengthTraining.title'),
                            t('reports.wellness.coloredCells.strengthTraining.redMain'),
                            t('reports.wellness.coloredCells.strengthTraining.redSub'),
                            t('reports.wellness.coloredCells.strengthTraining.orangeMain'),
                            t('reports.wellness.coloredCells.strengthTraining.orangeSub'),
                            t('reports.wellness.coloredCells.strengthTraining.greenMain'),
                            t('reports.wellness.coloredCells.strengthTraining.greenSub'),
                            data.physicalActivity.physicaActivityStrengthNum,
                        )}
                    </div>
                }

                <hr />

                {data.bodyComposition &&
                    <div>
                        {getDomainOverviewSection(
                            <BodyCompIcon style={{ backgroundColor: "#ADDFFD" }} />,
                            t('focus.bodyComposition'),
                            t('reports.wellness.domainOverview.bodyComp.description'),
                            t('reports.wellness.domainOverview.bodyComp.why'),
                            qrCodeBodyComposition,
                            data.bodyComposition.percentage,
                            data.bodyComposition.average,
                        )}

                        {get4ColoredCells(
                            t('reports.wellness.coloredCells.bmi.title'),
                            t('reports.wellness.coloredCells.bmi.redMain'),
                            t('reports.wellness.coloredCells.bmi.redSub'),
                            t('reports.wellness.coloredCells.bmi.orangeMain'),
                            t('reports.wellness.coloredCells.bmi.orangeSub'),
                            t('reports.wellness.coloredCells.bmi.greenMain'),
                            t('reports.wellness.coloredCells.bmi.greenSub'),
                            t('reports.wellness.coloredCells.bmi.blueMain'),
                            t('reports.wellness.coloredCells.bmi.blueSub'),
                            data.bodyComposition.bmi,
                        )}

                        <br />

                        {data.bodyComposition.hipToWaistRatio &&
                            data.bodyComposition.hipToWaistRatio === ReportAnswer.E
                            ? get3GreyedCells(
                                t('reports.wellness.coloredCells.hipToWaist.title'),
                                t('reports.wellness.coloredCells.hipToWaist.greenMain'),
                                data.isFemale ? t('reports.wellness.coloredCells.hipToWaist.greenSubFemale') : t('reports.wellness.coloredCells.hipToWaist.greenSubMale'),
                                t('reports.wellness.coloredCells.hipToWaist.orangeMain'),
                                data.isFemale ? t('reports.wellness.coloredCells.hipToWaist.orangeSubFemale') : t('reports.wellness.coloredCells.hipToWaist.orangeSubMale'),
                                t('reports.wellness.coloredCells.hipToWaist.redMain'),
                                data.isFemale ? t('reports.wellness.coloredCells.hipToWaist.redSubFemale') : t('reports.wellness.coloredCells.hipToWaist.redSubMale'),
                                data.bodyComposition.hipToWaistRatio,
                            )
                            : get3ColoredCellsReversed(
                                t('reports.wellness.coloredCells.hipToWaist.title'),
                                t('reports.wellness.coloredCells.hipToWaist.greenMain'),
                                data.isFemale ? t('reports.wellness.coloredCells.hipToWaist.greenSubFemale') : t('reports.wellness.coloredCells.hipToWaist.greenSubMale'),
                                t('reports.wellness.coloredCells.hipToWaist.orangeMain'),
                                data.isFemale ? t('reports.wellness.coloredCells.hipToWaist.orangeSubFemale') : t('reports.wellness.coloredCells.hipToWaist.orangeSubMale'),
                                t('reports.wellness.coloredCells.hipToWaist.redMain'),
                                data.isFemale ? t('reports.wellness.coloredCells.hipToWaist.redSubFemale') : t('reports.wellness.coloredCells.hipToWaist.redSubMale'),
                                data.bodyComposition.hipToWaistRatio as ReportAnswer,
                            )
                        }

                    </div>
                }
                <div className="break-after-page"></div>

                {data.injuryHistory &&
                    <div className="pt-6">
                        {getDomainOverviewSection(
                            <InjuryHistoryIcon style={{ backgroundColor: "#ADDFFD" }} />,
                            t('focus.injuryHistory'),
                            t('reports.wellness.domainOverview.injury.description'),
                            t('reports.wellness.domainOverview.injury.why'),
                            qrCodeInjuryHistory,
                            data.injuryHistory.percentage,
                            data.injuryHistory.average,
                        )}

                        {getInjuryHistoryCells(data.injuryHistory)}
                    </div>
                }

                <hr />

                {data.nutritionalAwareness &&
                    <div>
                        {getDomainOverviewSection(
                            <NutritionIcon style={{ backgroundColor: "#ADDFFD" }} />,
                            t('focus.nutritionalAwareness'),
                            t('reports.wellness.domainOverview.nutrition.description'),
                            t('reports.wellness.domainOverview.nutrition.why'),
                            qrCodeNutritionalAwareness,
                            data.nutritionalAwareness.percentage,
                            data.nutritionalAwareness.average,
                        )}
                    </div>
                }

                <hr />

                {data.behavioralHealth &&
                    <div>
                        {getDomainOverviewSection(
                            <BehavioralHealthIcon style={{ backgroundColor: "#ADDFFD" }} />,
                            t('focus.behavioralHealth'),
                            t('reports.wellness.domainOverview.behavioral.description'),
                            t('reports.wellness.domainOverview.behavioral.why'),
                            qrCodeBehavioralHealth,
                            data.behavioralHealth.percentage,
                            data.behavioralHealth.average,
                        )}

                    </div>
                }
                <div className="break-after-page"></div>

                {data.sleepWellness &&
                    <div className="pt-6">
                        {getDomainOverviewSection(
                            <SleepIcon style={{ backgroundColor: "#ADDFFD" }} />,
                            t('focus.sleepWellness'),
                            t('reports.wellness.domainOverview.sleep.description'),
                            t('reports.wellness.domainOverview.sleep.why'),
                            qrCodeSleepQuality,
                            data.sleepWellness.percentage,
                            data.sleepWellness.average,
                        )}
                        {data.sleepWellness.sleepPSQI === ReportAnswer.E
                            ? get3GreyedCells(
                                t('reports.wellness.coloredCells.sleep.title'),
                                t('reports.wellness.coloredCells.sleep.greenMain'),
                                t('reports.wellness.coloredCells.sleep.greenSub'),
                                t('reports.wellness.coloredCells.sleep.orangeMain'),
                                t('reports.wellness.coloredCells.sleep.orangeSub'),
                                t('reports.wellness.coloredCells.sleep.redMain'),
                                t('reports.wellness.coloredCells.sleep.redSub'),
                                data.sleepWellness.sleepPSQI
                            )
                            : get3ColoredCellsReversed(
                                t('reports.wellness.coloredCells.sleep.title'),
                                t('reports.wellness.coloredCells.sleep.greenMain'),
                                t('reports.wellness.coloredCells.sleep.greenSub'),
                                t('reports.wellness.coloredCells.sleep.orangeMain'),
                                t('reports.wellness.coloredCells.sleep.orangeSub'),
                                t('reports.wellness.coloredCells.sleep.redMain'),
                                t('reports.wellness.coloredCells.sleep.redSub'),
                                data.sleepWellness.sleepPSQI,
                            )
                        }
                    </div>
                }

                <hr />

                {data.breathingQuality &&
                    <div>
                        {getDomainOverviewSection(
                            <BreathingIcon style={{ backgroundColor: "#ADDFFD" }} />,
                            t('focus.breathingQuality'),
                            t('reports.wellness.domainOverview.breathing.description'),
                            t('reports.wellness.domainOverview.breathing.why'),
                            qrCodeBreathingQuality,
                            data.breathingQuality.percentage,
                            data.breathingQuality.average,
                        )}

                        {get3ColoredCells(
                            t('reports.wellness.coloredCells.breathHold.title'),
                            t('reports.wellness.coloredCells.breathHold.redMain'),
                            t('reports.wellness.coloredCells.breathHold.redSub'),
                            t('reports.wellness.coloredCells.breathHold.orangeMain'),
                            t('reports.wellness.coloredCells.breathHold.orangeSub'),
                            t('reports.wellness.coloredCells.breathHold.greenMain'),
                            t('reports.wellness.coloredCells.breathHold.greenSub'),
                            data.breathingQuality.breathBreath,
                        )}

                        <br />

                        {get3ColoredCells(
                            t('reports.wellness.coloredCells.breathingQuestionnaire.title'),
                            t('reports.wellness.coloredCells.breathingQuestionnaire.redMain'),
                            t('reports.wellness.coloredCells.breathingQuestionnaire.redSub'),
                            t('reports.wellness.coloredCells.breathingQuestionnaire.orangeMain'),
                            t('reports.wellness.coloredCells.breathingQuestionnaire.orangeSub'),
                            t('reports.wellness.coloredCells.breathingQuestionnaire.greenMain'),
                            t('reports.wellness.coloredCells.breathingQuestionnaire.greenSub'),
                            data.breathingQuality.breathingQuestionnaire,
                        )}

                        <br />
                        {get2ColoredCells(
                            t('reports.wellness.coloredCells.tobacco.title'),
                            t('general.yes'),
                            t('general.no'),
                            data.breathingQuality.breathingSmokeNow,
                        )}
                    </div>
                }
            </div>
            <div className="break-after-page"></div>

            <div className="px-10 pt-6">
                <div className="relative">
                    <div>
                        <div className="flex flex-row justify-between">
                            <h1>{t('reports.wellness.yourProgress')}</h1>
                            <div className="text-xs font-light">{getReportDateRange()}</div>
                        </div>
                        <div className="flex flex-col mt-3 gap-4">
                            <div>
                                <div style={{ position: "absolute", top: "52px", right: "0px" }}>
                                    {getChartBadge(data.wellnessGraphData?.data[4] ? data.wellnessGraphData?.data[4] : data.wellnessGraphData?.data[5], data.wellnessGraphData?.data[5])}
                                </div>
                                <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels}
                                    data={data.wellnessGraphData?.data}
                                    title={data.wellnessGraphData?.title}
                                    height="200px"
                                    width="650px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC"
                                    devicePixelRatio={4} />

                            </div>
                            <div className="flex flex-row gap-4">
                                <div style={{ position: "absolute", top: "268px", right: "0px" }}>
                                    {getChartBadge(data.physicalActivityGraphData?.data[4] ? data.physicalActivityGraphData?.data[4] : data.physicalActivityGraphData?.data[5], data.physicalActivityGraphData?.data[5])}
                                </div>
                                <div style={{ position: "absolute", top: "268px", left: "324px" }}>
                                    {getChartBadge(data.movementHealthGraphData?.data[4] ? data.movementHealthGraphData?.data[4] : data.movementHealthGraphData?.data[5], data.movementHealthGraphData?.data[5])}
                                </div>
                                <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels} data={data.movementHealthGraphData?.data} title={data.movementHealthGraphData?.title}
                                    height="150px"
                                    width="317px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC"
                                    devicePixelRatio={4} />
                                <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels} data={data.physicalActivityGraphData?.data} title={data.physicalActivityGraphData?.title}
                                    height="150px"
                                    width="317px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC"
                                    devicePixelRatio={4} />
                            </div>
                            <div className="flex flex-row gap-4">
                                <div style={{ position: "absolute", top: "434px", right: "0px" }}>
                                    {getChartBadge(data.injuryHistoryGraphData?.data[4] ? data.injuryHistoryGraphData?.data[4] : data.injuryHistoryGraphData?.data[5], data.injuryHistoryGraphData?.data[5])}
                                </div>
                                <div style={{ position: "absolute", top: "434px", left: "324px" }}>
                                    {getChartBadge(data.bodyCompositionGraphData?.data[4] ? data.bodyCompositionGraphData?.data[4] : data.bodyCompositionGraphData?.data[5], data.bodyCompositionGraphData?.data[5])}
                                </div>
                                <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels} data={data.bodyCompositionGraphData?.data} title={data.bodyCompositionGraphData?.title}
                                    height="150px"
                                    width="317px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC"
                                    devicePixelRatio={4} />
                                <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels} data={data.injuryHistoryGraphData?.data} title={data.injuryHistoryGraphData?.title}
                                    height="150px"
                                    width="317px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC"
                                    devicePixelRatio={4} />
                            </div>
                            <div className="flex flex-row gap-4">
                                <div style={{ position: "absolute", top: "600px", right: "0px" }}>
                                    {getChartBadge(data.behavioralHealthGraphData?.data[4] ? data.behavioralHealthGraphData?.data[4] : data.behavioralHealthGraphData?.data[5], data.behavioralHealthGraphData?.data[5])}
                                </div>
                                <div style={{ position: "absolute", top: "600px", left: "324px" }}>
                                    {getChartBadge(data.nutritionAwarenessGraphData?.data[4] ? data.nutritionAwarenessGraphData?.data[4] : data.nutritionAwarenessGraphData?.data[5], data.nutritionAwarenessGraphData?.data[5])}
                                </div>
                                <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels} data={data.nutritionAwarenessGraphData?.data} title={data.nutritionAwarenessGraphData?.title}
                                    height="150px"
                                    width="317px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC"
                                    devicePixelRatio={4} />
                                <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels} data={data.behavioralHealthGraphData?.data} title={data.behavioralHealthGraphData?.title}
                                    height="150px"
                                    width="317px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC"
                                    devicePixelRatio={4} />
                            </div>
                            <div className="flex flex-row gap-4">
                                <div style={{ position: "absolute", top: "766px", right: "0px" }}>
                                    {getChartBadge(data.breathingQualityGraphData?.data[4] ? data.breathingQualityGraphData?.data[4] : data.breathingQualityGraphData?.data[5], data.breathingQualityGraphData?.data[5])}
                                </div>
                                <div style={{ position: "absolute", top: "766px", left: "324px" }}>
                                    {getChartBadge(data.sleepWellnessGraphData?.data[4] ? data.sleepWellnessGraphData?.data[4] : data.sleepWellnessGraphData?.data[5], data.sleepWellnessGraphData?.data[5])}
                                </div>
                                <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels} data={data.sleepWellnessGraphData?.data} title={data.sleepWellnessGraphData?.title}
                                    height="150px"
                                    width="317px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC"
                                    devicePixelRatio={4} />
                                <LineChartJS lineHeight={0} paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} pointRadius={4} pointBorderWidth={2} borderWidth={3} horizontalScrolling={false} pointLabelOffset={-6} pointFontSize={8} xLabelFontSize={6} labels={data.graphLabels}
                                    data={data.breathingQualityGraphData?.data}
                                    title={data.breathingQualityGraphData?.title}
                                    height="150px"
                                    width="317px"
                                    responsive={true}
                                    maintainAspectRatio={false}
                                    borderRadius="10px"
                                    backgroundColor="#F7F9FC"
                                    devicePixelRatio={4} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReportWellness;
