import { Button } from '../button/button';
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { ReactComponent as PainLocationsIcon } from "../../assets/icons/solid/broken-heart-icon.svg";
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/solid/question-mark.svg";
import DonutChart from "../charts/donut-chart";
import RiskChartVertical from "../charts/risk-chart-vertical";
import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import FocusAreaCard from "../focus-areas-card/focus-areas-card";
import GrayFooterCard from "../gray-footer-card/gray-footer-card";
import MskScoreUtil from "../../utilities/msk-score-util";
import { MskScore } from '../../models/interfaces/msk-score';
import AssessmentResponse from '../../models/interfaces/assessment-response.entity';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AppContentUtil from '../../utilities/app-content-util';
import WellnessProgramService from '../../utilities/services/wellness-program-service';
import { WellnessProgram } from '../../models/interfaces/wellness-program';
import UserUtil from '../../utilities/user-util';
import { Modal } from '../modal/modal';
import ReportDataUtil from '../../utilities/report-data-util';
import DataAssessmentsUtil from '../../utilities/data-assesments-util';

const COMPONENT_CLASS = "c-assessment-results";
const QUESTION_MARK_VIDEO_URL = "";

interface AssessmentResultsHeaderProps {
    mskScore?: MskScore;
    latestAssessmentResponses?: AssessmentResponse[];
    riskChartPercentage: number;
    lastAssessment: string;
    lastAssessmentDateInDays: string;
}

const AssessmentResultsHeader: React.FC<AssessmentResultsHeaderProps> = (props: AssessmentResultsHeaderProps) => {
    const {
        mskScore,
        riskChartPercentage,
        latestAssessmentResponses,
        lastAssessment,
        lastAssessmentDateInDays,
    } = props;

    const { state } = useAuthState();
    const { t } = useTranslation();
    const history = useHistory();

    const [openRetestModal, setOpenRetestModal] = useState(false);
    const [wellnessProgram, setWellnessProgram] = useState<WellnessProgram>();

    const [showQuestionMarkModal, setShowQuestionMarkModal] = useState(false);
    const questionMarkVideoEmbed = AppContentUtil.getVideoEmbedCode(QUESTION_MARK_VIDEO_URL);

    const downloadReportButton = (
        <Button
            type="default"
            buttonText={t('buttons.btn_downloadYourReport')}
            onClick={() => ReportDataUtil.downloadWellness(state.user, mskScore)} />
    );

    useEffect(() => {
        const getWellnessProgram = async () => {
            if (state.user && state.user.wellnessProgramId) {
                let fetchedWellnessProgram = await WellnessProgramService.get(state.user.wellnessProgramId);

                if (fetchedWellnessProgram) {
                    setWellnessProgram(fetchedWellnessProgram);
                }
            }
        }

        if (!wellnessProgram) {
            getWellnessProgram();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user])

    return (
        <div>
            <div className="py-6 flex flex-wrap justify-center gap-5">
                <div className={`${COMPONENT_CLASS}__score-section-card`}>
                    <h3 className="mb-4">
                        {t('dashboard.overallScore')}
                    </h3>
                    <div className={`${COMPONENT_CLASS}__donut-chart`}>
                        <DonutChart
                            percentage={mskScore?.percentage}
                            title={t('userProfile.wellnessScore')}
                        />
                    </div>
                    {mskScore &&
                        <div className="text-xs text-center font-light my-4">
                            {DataAssessmentsUtil.getMskAverage(mskScore)}
                        </div>
                    }
                    {(state.user?.isSuperAdmin || !state.user?.wellnessProgramId || !wellnessProgram || !wellnessProgram.isCurrent || state.user.symmioAccess !== SymmioAccessType.AppLicense || state.user?.administeredRetake) &&
                        <div>
                            <Button
                                buttonStyle="secondary"
                                buttonText={t('assessmentResults.retest')}
                                onClick={() => {
                                    if (!state.user?.wellnessProgramId || !wellnessProgram || !wellnessProgram.isCurrent) {
                                        history.push("/movement?retake=true");
                                    }
                                    else {
                                        setOpenRetestModal(true);
                                    }
                                }} />
                        </div>
                    }
                </div>

                <div className="flex flex-col gap-5">
                    <div className={`${COMPONENT_CLASS}__score-section-card`}>
                        <h3 className="flex gap-3 items-center justify-center mb-4">
                            {t('assessmentResults.mskHealth')}
                            {questionMarkVideoEmbed &&
                                <div className='cursor-pointer' onClick={() => setShowQuestionMarkModal(true)}>
                                    <QuestionMarkIcon />
                                </div>
                            }
                        </h3>
                        <div className="w-full h-64">
                            <RiskChartVertical
                                heightPercentage={100 - riskChartPercentage}
                                score={mskScore?.riskFactor?.percentage}
                                breakpoints={[
                                    { min: 0, max: 0, label: t('riskFactor.optimal'), bgColor: "#8BE3CE" },
                                    { min: 1, max: 27, label: t('riskFactor.acceptable'), bgColor: "#ADDFFD" },
                                    { min: 28, max: 72, label: t('riskFactor.low'), bgColor: "#FFC79C" },
                                    { min: 73, max: 100, label: t('riskFactor.veryLow'), bgColor: "#FFA998" },
                                ]}
                                decimals={false}
                            />
                        </div>
                    </div>

                    <div className={`${COMPONENT_CLASS}__score-section-card`}>
                        <div className="w-fit flex gap-1 items-center justify-center">
                            <div>
                                <PainLocationsIcon />
                            </div>
                            <div className="font-bold text-2xl">
                                {mskScore && latestAssessmentResponses &&
                                    MskScoreUtil.getNumberOfPainLocations(mskScore, latestAssessmentResponses)
                                }
                            </div>
                            <div className="uppercase font-bold text-10" dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.painIdentified') }}>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-5">
                    {mskScore && mskScore.focusAreas && mskScore.focusAreas.length > 0 &&
                        <FocusAreaCard focusAreas={mskScore.focusAreas} title={t('assessmentResults.areasOfFocus')} />
                    }

                    <GrayFooterCard mainText={lastAssessment} subText={t('assessmentResults.lastAssessment')} footerText={lastAssessmentDateInDays} />
                </div>

                <Modal
                    isOpen={openRetestModal}
                    isLoading={false}
                    onClose={setOpenRetestModal}
                    defaultModalActions={true}
                    onSubmit={() => {
                        if (wellnessProgram && state.user && state.user.id && mskScore) {
                            UserUtil.handleRetestClick(state.user, wellnessProgram, mskScore).then(() => {
                                setWellnessProgram(undefined);
                                history.push("/movement?retake=true");
                            })
                        }
                    }}
                    onCancel={() => setOpenRetestModal(false)}
                    title={t('assessmentResults.retest_modal.title')}
                    submitButtonText={t('general.yes')}
                    cancelButtonText={t('buttons.btn_cancel')}
                >
                    <div className="mb-6">
                        <p className="text-sm font-light mb-4" dangerouslySetInnerHTML={{ __html: t('assessmentResults.retest_modal.text') }}></p>
                    </div>
                </Modal>

                {questionMarkVideoEmbed &&
                    <Modal
                        isOpen={showQuestionMarkModal}
                        isLoading={false}
                        onClose={setShowQuestionMarkModal}>
                        <div className={`${COMPONENT_CLASS}__video-container-modal`}>
                            <div dangerouslySetInnerHTML={{ __html: questionMarkVideoEmbed }}></div>
                        </div>
                    </Modal>
                }
            </div>
            <div className={`${COMPONENT_CLASS}__report-section`}>
                <p>{t('assessmentResults.downloadFullReport')}</p>
                <p className="italic text-center" style={{ fontSize: "12px" }}>{t('assessmentResults.disablePopUp')}</p>
                <div className="flex items-center justify-center">
                    {downloadReportButton}
                </div>
            </div>
        </div>
    );
}

export default AssessmentResultsHeader;
