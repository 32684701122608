import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import moment from "moment";
import { MovementHealthResponse } from "../../models/interfaces/reports/movement-health-response";
import NonLinearLineChart from "../charts/non-linear-line-chart";
import { Utils } from "../../utilities/utils";
import { useTranslation } from "react-i18next";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileRotationProps {
    mskScore?: MskScore;
    validMskScore: boolean;
    user: User;
    allMskScores: MskScore[];
    setAllMskScores: Function;
    movementResponses?: MovementHealthResponse;

}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileRotation: React.FC<UserProfileRotationProps> = (props: UserProfileRotationProps) => {
    const { mskScore, allMskScores, movementResponses, validMskScore } = props;
    const [rotationHistory, setRotationHistory] = useState<{ x: string, y: number }[]>();
    const { t } = useTranslation();

    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempRotationHistory = []

                for (const mskScore of allMskScores) {
                    tempRotationHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.movementScore?.rotationScore.percentage ?? 0
                    })
                }
                setRotationHistory(tempRotationHistory);
            }
        }
        getHistory();
    }, [allMskScores])

    return (
        <div className="flex gap-5">
            <div className={`${COMPONENT_CLASS}__score-section-card gap-4 items-center`}>
                <div className="text-base font-normal">{t('movementFocus.rotation')}</div>

                <div className={`${COMPONENT_CLASS}__movement-group-score-card ${Utils.getGreenOrRedColor(mskScore?.movementScore?.rotationScore.percentage, 75)}`}>
                    <div className={`${COMPONENT_CLASS}__movement-group-score-card__main-text`}>{mskScore?.movementScore?.rotationScore.percentage}</div>
                    <div className={`${COMPONENT_CLASS}__movement-group-score-card__sub-text`}>{t('userProfile.assessments.goal75')}</div>
                </div>
                {validMskScore ?
                    <>
                        <div className="flex gap-4">
                            <div className="flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                <span className="text-10 font-bold uppercase">{t('reports.wellness.leftSide')}</span>
                                {Utils.getAssessmentBreakdownCell(t('reports.wellness.feet'), mskScore?.movementScore?.rotationScore.feetTogetherLeft)}
                                {Utils.getAssessmentBreakdownCell(t('reports.wellness.rightFoot'), mskScore?.movementScore?.rotationScore.rightFootBackLeft)}
                            </div>
                            <div className="flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                <span className="text-10 font-bold uppercase">{t('reports.wellness.rightSide')}</span>
                                {Utils.getAssessmentBreakdownCell(t('reports.wellness.feet'), mskScore?.movementScore?.rotationScore.feetTogetherRight)}
                                {Utils.getAssessmentBreakdownCell(t('reports.wellness.leftFoot'), mskScore?.movementScore?.rotationScore.rightFootBackRight)}
                            </div>
                        </div>
                        <div>
                            {Utils.getPainBadge(mskScore?.movementScore?.rotationScore.painSeverity!)}
                        </div>
                    </>
                    : movementResponses ?
                        <>
                            <div className="flex gap-4">
                                <div className="flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                    <span className="text-10 font-bold">{t('reports.wellness.leftSide')}</span>
                                    {Utils.getAssessmentBreakdownCell(t('reports.wellness.feet'), movementResponses.rotationReachLeft.itemOne)}
                                    {Utils.getAssessmentBreakdownCell(t('reports.wellness.rightFoot'), movementResponses.rotationReachLeft.itemTwo)}
                                </div>
                                <div className="flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                    <span className="text-10 font-bold">{t('reports.wellness.rightSide')}</span>
                                    {Utils.getAssessmentBreakdownCell(t('reports.wellness.feet'), movementResponses.rotationReachRight.itemOne)}
                                    {Utils.getAssessmentBreakdownCell(t('reports.wellness.leftFoot'), movementResponses.rotationReachRight.itemTwo)}
                                </div>
                            </div>
                            <div>
                                {Utils.getPainBadge(mskScore?.movementScore?.rotationScore.painSeverity ?? movementResponses.rotationQPain)}
                            </div>
                        </>
                        :
                        <div></div>
                }

            </div>

            {rotationHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                    <NonLinearLineChart
                        dataPoints={rotationHistory.map(history => history.y)}
                        labels={rotationHistory.map(history => history.x)}
                        title={t('userProfile.assessments.rotationHistory')}
                        sectionDetails={{
                            sections: 2,
                            rangeOne: { start: 0, end: 74 },
                            rangeTwo: { start: 75, end: 100 },
                            backgroundColorOrder: { first: "#FFA998", second: "#8BE3CE" },
                            dataPointColorOrder: { first: "#E7514F", second: "#00DFBC" },
                            yAxisLabels: { first: t('userProfile.assessments.below'), second: t('userProfile.assessments.above') },
                            decimals: false
                        }}
                        toolTipLabel={true} />
                </div>
            )}
        </div>
    );
}

export default UserProfileRotation;
