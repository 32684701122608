import React from 'react';
import logoAccount from "../../assets/images/symmio-logos/logo_account.png";
import BaseCardLayoutActions from '../base-card-layout/base-card-layout-actions';
import { useTranslation } from 'react-i18next';


const COMPONENT_CLASS = "c-subscriptions";

interface AccountCreatedProps {
  onSubmitClick: () => void;
  welcomeText: string,
}
const AccountCreated: React.FC<AccountCreatedProps> = ({ onSubmitClick, welcomeText }) => {
  const { t } = useTranslation();

  return (
    <div className={`${COMPONENT_CLASS}__account-created-wrapper`}>
      <div className={`${COMPONENT_CLASS}__account-created`}>
        <div className={`${COMPONENT_CLASS}__account-created-content`}>
          <div>
            <img src={logoAccount} alt="Symmio Logo" />
          </div>
        </div>
        <div className={`${COMPONENT_CLASS}__account-created-content`}>
          <div className={`${COMPONENT_CLASS}__account-created-content-text`}>
            <p style={{ fontSize: '38px' }}>{t('pricing.accountCreatedPage.title')}</p>
            <p className='whitespace-pre-line'>{welcomeText}</p>
          </div>
        </div>
      </div>
      <BaseCardLayoutActions
        submitButton={true}
        submitButtonText={t('buttons.btn_accountCreated')}
        onSubmit={onSubmitClick}
      />
    </div>

  );
}

export default AccountCreated;