import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import moment from "moment";
import NonLinearLineChart from "../charts/non-linear-line-chart";
import { Utils } from "../../utilities/utils";
import { MovementHealthResponse } from "../../models/interfaces/reports/movement-health-response";
import { useTranslation } from "react-i18next";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileShoulderClearingProps {
    mskScore?: MskScore;
    validMskScore: boolean;
    user: User;
    allMskScores: MskScore[];
    setAllMskScores: Function;
    movementResponses?: MovementHealthResponse;

}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileShoulderClearing: React.FC<UserProfileShoulderClearingProps> = (props: UserProfileShoulderClearingProps) => {
    const { mskScore, allMskScores, movementResponses, validMskScore } = props;
    const [shoulderClearingHistory, setShoulderClearingHistory] = useState<{ x: string, y: number }[]>();
    const { t } = useTranslation();

    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempShoulderClearingHistory = []

                for (const mskScore of allMskScores) {
                    tempShoulderClearingHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.movementScore?.hasShoulderClearingPain ? 25 : 75
                    })
                }
                setShoulderClearingHistory(tempShoulderClearingHistory);
            }
        }
        getHistory();
    }, [allMskScores])

    return (
        <div className="flex gap-5">
            <div className={`${COMPONENT_CLASS}__score-section-card gap-4 items-center`}>
                <div className="text-base font-normal">{t('movementFocus.shoulderClearing')}</div>
                {mskScore && mskScore?.movementScore && (
                    <div className={`${COMPONENT_CLASS}__movement-group-score-card ${mskScore.movementScore.hasShoulderClearingPain ? "bg-red-medium" : "bg-green-medium"}`}>
                        <div className={`${COMPONENT_CLASS}__movement-group-score-card__main-text`}>{mskScore.movementScore.hasShoulderClearingPain ? t('general.fail') : t('general.pass')}</div>
                        <div className={`${COMPONENT_CLASS}__movement-group-score-card__sub-text`}>{t('userProfile.assessments.goalPass')}</div>
                    </div>
                )}
                {validMskScore ?
                    <div>
                        {Utils.getPainBadge(mskScore?.movementScore?.shoulderClearingPainSeverity!)}
                    </div>
                    : movementResponses ?
                        <div>
                            {Utils.getPainBadge(movementResponses.shoulderClearingQPain)}
                        </div>
                        :
                        <div></div>
                }

            </div>

            {shoulderClearingHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                    <NonLinearLineChart
                        dataPoints={shoulderClearingHistory.map(history => history.y)}
                        labels={shoulderClearingHistory.map(history => history.x)}
                        title={t('userProfile.assessments.shoulderClearingHistory')}
                        sectionDetails={{
                            sections: 2,
                            rangeOne: { start: 0, end: 49 },
                            rangeTwo: { start: 50, end: 100 },
                            backgroundColorOrder: { first: "#FFA998", second: "#8BE3CE" },
                            dataPointColorOrder: { first: "#E7514F", second: "#00DFBC" },
                            yAxisLabels: { first: t('general.pain').toUpperCase(), second: t('general.noPain').toUpperCase() },
                            decimals: false
                        }}
                        binaryGraph={{ isTrue: 75, isFalse: 25, trueLabel: t('general.noPain'), falseLabel: t('general.pain') }}
                        toolTipLabel={false} />
                </div>
            )}
        </div>
    );
}

export default UserProfileShoulderClearing;
