import React from "react";
import { useTranslation } from 'react-i18next';

export interface RequestModalTitleProps {
    newRequest: boolean,
}

/**
 * Represents the title that is displayed in the request modal.
 *
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const RequestModalTitle: React.FC<RequestModalTitleProps> = (props) => {
    const { newRequest } = props;
    const { t } = useTranslation();

    const title = newRequest
        ? t('login.modal.request.title.newRequest')
        : t('login.modal.request.title.oldRequest')

    return (
        <h1 className="block" style={{ padding: '105px 0px 48px 0px', textAlign: 'center' }}>{title}</h1>
    );
};

export default RequestModalTitle;