import { useEffect, useState } from "react";
import Select from 'react-select'
import ReactQuill from "react-quill";
import { useQuillStore } from "./rte-stores";
import { AppContentType } from "../../models/enumerations/app-content-type";
import AppContentTypeToggle from "../app-content-type-toggle/app-content-type-toggle"
import { VideoEmbedSource } from "../../models/enumerations/video-embed-source";
import AppContentUtil from "../../utilities/app-content-util";
import { Button } from "../button/button";
import { TextTypeInput } from "../forms";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const COMPONENT_CLASS = "c-rich-text-editor";

interface RichTextEditorProps {
    includeTextAlignment?: boolean;
    showEdit: boolean,
    showPreview: boolean,
    isEditingContact: boolean;
    setIsEditingContact: React.Dispatch<React.SetStateAction<boolean>>;

    appContentType: AppContentType;
    setAppContentType: React.Dispatch<React.SetStateAction<AppContentType>>;

    appContentPainContent: string;
    appContentPainVideoUrl: string;
    appContentPainHeaderText: string;
    appContentPainVideoSource: string;
    appContentPainButtonText: string;
    appContentPainButtonLink: string;

    appContentBehavioralContent: string;
    appContentBehavioralVideoUrl: string;
    appContentBehavioralHeaderText: string;
    appContentBehavioralVideoSource: string;
    appContentBehavioralButtonText: string;
    appContentBehavioralButtonLink: string;

    setAppContentPainContent: React.Dispatch<React.SetStateAction<string>>;
    setAppContentPainVideoUrl: React.Dispatch<React.SetStateAction<string>>;
    setAppContentPainHeaderText: React.Dispatch<React.SetStateAction<string>>;
    setAppContentPainVideoSource: React.Dispatch<React.SetStateAction<string>>;
    setAppContentPainButtonText: React.Dispatch<React.SetStateAction<string>>;
    setAppContentPainButtonLink: React.Dispatch<React.SetStateAction<string>>;

    setAppContentBehavioralContent: React.Dispatch<React.SetStateAction<string>>;
    setAppContentBehavioralVideoUrl: React.Dispatch<React.SetStateAction<string>>;
    setAppContentBehavioralHeaderText: React.Dispatch<React.SetStateAction<string>>;
    setAppContentBehavioralVideoSource: React.Dispatch<React.SetStateAction<string>>;
    setAppContentBehavioralButtonText: React.Dispatch<React.SetStateAction<string>>;
    setAppContentBehavioralButtonLink: React.Dispatch<React.SetStateAction<string>>;
}

const RichTextEditor: React.FC<RichTextEditorProps> = (props: RichTextEditorProps) => {
    const {
        includeTextAlignment,
        showEdit,
        showPreview,
        isEditingContact,
        setIsEditingContact,

        appContentType,
        setAppContentType,

        appContentPainContent,
        appContentPainVideoUrl,
        appContentPainHeaderText,
        appContentPainVideoSource,
        appContentPainButtonText,
        appContentPainButtonLink,

        appContentBehavioralContent,
        appContentBehavioralVideoUrl,
        appContentBehavioralHeaderText,
        appContentBehavioralVideoSource,
        appContentBehavioralButtonText,
        appContentBehavioralButtonLink,

        setAppContentPainContent,
        setAppContentPainVideoUrl,
        setAppContentPainHeaderText,
        setAppContentPainVideoSource,
        setAppContentPainButtonText,
        setAppContentPainButtonLink,

        setAppContentBehavioralContent,
        setAppContentBehavioralVideoUrl,
        setAppContentBehavioralHeaderText,
        setAppContentBehavioralVideoSource,
        setAppContentBehavioralButtonText,
        setAppContentBehavioralButtonLink,

    } = props;

    const { t } = useTranslation();
    const quillStore = useQuillStore();
    const [shouldRender, setShouldRender] = useState(false);

    const [appContentPainToDisplay, setAppContentPainToDisplay] = useState<string>("");
    const [appContentBehavioralToDisplay, setAppContentBehavioralToDisplay] = useState<string>("");

    const mainBodyDescriptionElementPain = (
        <div className={`${COMPONENT_CLASS}__main-body-description`}>{t('richTextEditor.painDescription')}</div>
    );

    const mainBodyDescriptionElementBehavioral = (
        <div className={`${COMPONENT_CLASS}__main-body-description`}>{t('richTextEditor.behavioralDescription')}</div>
    );

    const selectOptions = [];

    const methods = useForm<any>();


    const {
        register,
    } = methods;

    for (const [, value] of Object.entries(VideoEmbedSource)) {
        selectOptions.push({
            value: value,
            label: value,
        })
    }

    useEffect(() => {
        const init = async () => {
            if (includeTextAlignment) {
                quillStore.addTextAlignmentControls();
            }

            // make sure link and clean controls are placed
            // at the end of the toolbar
            quillStore.addLinkAndCleanControls()
                .then(() => {
                    setShouldRender(true);
                });
        }

        init();

        return () => {
            quillStore.resetStoreState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [includeTextAlignment]);

    useEffect(() => {
        async function composeEmbedCode() {
            const painEmbedHtml = AppContentUtil.getEmbedCode(appContentPainVideoUrl, appContentPainHeaderText, appContentPainContent, appContentPainButtonText, appContentPainButtonLink);
            const behavioralEmbedHtml = AppContentUtil.getEmbedCode(appContentBehavioralVideoUrl, appContentBehavioralHeaderText, appContentBehavioralContent, appContentBehavioralButtonText, appContentBehavioralButtonLink);

            setAppContentPainToDisplay(painEmbedHtml);
            setAppContentBehavioralToDisplay(behavioralEmbedHtml);
        }

        composeEmbedCode();
    }, [appContentPainHeaderText, appContentBehavioralHeaderText, appContentPainContent, appContentBehavioralContent, appContentPainVideoUrl, appContentBehavioralVideoUrl, appContentPainButtonText, appContentPainButtonLink, appContentBehavioralButtonText, appContentBehavioralButtonLink]);

    return (
        <div className={COMPONENT_CLASS}>
            <AppContentTypeToggle
                isOption1={appContentType === AppContentType.PAIN}
                isOption2={appContentType === AppContentType.BEHAVIORAL_HEALTH}
                onClickOption1={() => setAppContentType(AppContentType.PAIN)}
                onClickOption2={() => setAppContentType(AppContentType.BEHAVIORAL_HEALTH)}
                textOption1={t('appContentTypeToggle.healthcare')}
                textOption2={t('appContentTypeToggle.behavioralHealth')}
            />
            {isEditingContact ?
                <>
                    {shouldRender &&
                        <div
                            className={`${COMPONENT_CLASS}__fields ${appContentType === AppContentType.PAIN ? "flex" : "hidden"}`}>

                            <div className={`${COMPONENT_CLASS}__video-fields-container`}>

                                <div className={`${COMPONENT_CLASS}__select-video-source`}>
                                    <label htmlFor="video-source-pain">{t('richTextEditor.urlSource')}</label>
                                    <Select
                                        aria-labelledby="video-source-pain"
                                        options={selectOptions}
                                        name="video-source-pain"
                                        id="video-source-pain"
                                        value={selectOptions.find(so => so.value === appContentPainVideoSource)}
                                        onChange={select => setAppContentPainVideoSource(select?.value || "")}
                                    />
                                </div>

                                <div className={`${COMPONENT_CLASS}__video-url`}>
                                    <label htmlFor="video-url-pain">{t('richTextEditor.videoUrl')}</label>
                                    <TextTypeInput
                                        id="video-url-pain"
                                        type="text"
                                        registerHook={register}
                                        registerOptions={{
                                            value: appContentPainVideoUrl,
                                            onChange: (e) => setAppContentPainVideoUrl(e.target.value)
                                        }} />
                                </div>
                            </div>

                            <div className={`${COMPONENT_CLASS}__header-text`}>
                                <label htmlFor="header-text-pain">{t('richTextEditor.headerText')}</label>
                                <TextTypeInput id="header-text-pain" type="text" registerHook={register} registerOptions={{
                                    value: appContentPainHeaderText,
                                    onChange: (e) => setAppContentPainHeaderText(e.target.value),
                                }} />
                            </div>

                            <div className={`${COMPONENT_CLASS}__main-text-container`}>
                                <label>{t('richTextEditor.mainBody')}</label>
                                {mainBodyDescriptionElementPain}
                                <ReactQuill theme="snow"
                                    className={COMPONENT_CLASS}
                                    modules={quillStore.options.modules}
                                    formats={quillStore.options.formats}
                                    value={appContentPainContent}
                                    onChange={(content) => {
                                        setAppContentPainContent(content);
                                    }} />
                            </div>

                            <div className={`${COMPONENT_CLASS}__button-inputs-container`}>
                                <div className={`${COMPONENT_CLASS}__button-text`}>
                                    <label htmlFor="button-text-pain">{t('richTextEditor.buttonText')}</label>
                                    <TextTypeInput id="button-text-pain" type="text" registerHook={register} registerOptions={{
                                        value: appContentPainButtonText,
                                        onChange: (e) => setAppContentPainButtonText(e.target.value),
                                    }} />
                                </div>

                                <div className={`${COMPONENT_CLASS}__button-link`}>
                                    <label htmlFor="button-link-pain">{t('richTextEditor.buttonUrl')}</label>
                                    <TextTypeInput id="button-link-pain" type="text" registerHook={register} registerOptions={{
                                        value: appContentPainButtonLink,
                                        onChange: (e) => setAppContentPainButtonLink(e.target.value),
                                    }} />
                                </div>
                            </div>
                        </div>

                    }
                    {shouldRender &&
                        <div
                            className={`${COMPONENT_CLASS}__fields ${appContentType === AppContentType.BEHAVIORAL_HEALTH ? "flex" : "hidden"}`}>

                            <div className={`${COMPONENT_CLASS}__video-fields-container`}>
                                <div className={`${COMPONENT_CLASS}__select-video-source`}>
                                    <label htmlFor="video-source-behavioral">{t('richTextEditor.urlSource')}</label>
                                    <Select
                                        aria-labelledby="video-source-behavioral"
                                        options={selectOptions}
                                        name="video-source-behavioral"
                                        id="video-source-behavioral"
                                        value={selectOptions.find(so => so.value === appContentBehavioralVideoSource)}
                                        onChange={select => setAppContentBehavioralVideoSource(select?.value || "")}
                                    />
                                </div>

                                <div className={`${COMPONENT_CLASS}__video-url`}>
                                    <label htmlFor="video-url-behavioral">{t('richTextEditor.videoUrl')}</label>
                                    <TextTypeInput id="video-url-behavioral" type="text" registerHook={register} registerOptions={{
                                        value: appContentBehavioralVideoUrl,
                                        onChange: (e) => setAppContentBehavioralVideoUrl(e.target.value),
                                    }} />
                                </div>
                            </div>

                            <div className={`${COMPONENT_CLASS}__header-text`}>
                                <label htmlFor="header-text-behavioral">{t('richTextEditor.headerText')}</label>
                                <TextTypeInput id="header-text-behavioral" type="text" registerHook={register} registerOptions={{
                                    value: appContentBehavioralHeaderText,
                                    onChange: (e) => setAppContentBehavioralHeaderText(e.target.value),
                                }} />
                            </div>

                            <div className={`${COMPONENT_CLASS}__main-text-container`}>
                                <label>Main Body</label>
                                {mainBodyDescriptionElementBehavioral}
                                <ReactQuill theme="snow"
                                    className={COMPONENT_CLASS}
                                    modules={quillStore.options.modules}
                                    formats={quillStore.options.formats}
                                    value={appContentBehavioralContent}
                                    onChange={(content) => {
                                        setAppContentBehavioralContent(content);
                                    }} />
                            </div>

                            <div className={`${COMPONENT_CLASS}__button-inputs-container`}>
                                <div className={`${COMPONENT_CLASS}__button-text`}>
                                    <label htmlFor="button-text-behavioral">{t('richTextEditor.buttonText')}</label>
                                    <TextTypeInput id="button-text-behavioral" type="text" registerHook={register} registerOptions={{
                                        value: appContentBehavioralButtonText,
                                        onChange: (e) => setAppContentBehavioralButtonText(e.target.value),
                                    }} />
                                </div>

                                <div className={`${COMPONENT_CLASS}__button-link`}>
                                    <label htmlFor="button-link-behavioral">{t('richTextEditor.buttonUrl')}</label>
                                    <TextTypeInput id="button-link-behavioral" type="text" registerHook={register} registerOptions={{
                                        value: appContentBehavioralButtonLink,
                                        onChange: (e) => setAppContentBehavioralButtonLink(e.target.value),
                                    }} />
                                </div>
                            </div>
                        </div>
                    }
                    {showPreview &&
                        <div className={`${COMPONENT_CLASS}__actions`}>
                            <Button
                                buttonText={t('buttons.btn_previewChanges')}
                                type="default"
                                onClick={
                                    () => setIsEditingContact(false)
                                } />
                        </div>
                    }
                </>
                :
                <>
                    <div className={`${COMPONENT_CLASS}__display-contact-container`}>
                        {appContentType === AppContentType.PAIN
                            ?
                            <>
                                {mainBodyDescriptionElementPain}
                                <div className={`${COMPONENT_CLASS}__display-contact`} dangerouslySetInnerHTML={{ __html: appContentPainToDisplay }}></div>
                            </>
                            :
                            <>
                                {mainBodyDescriptionElementBehavioral}
                                <div className={`${COMPONENT_CLASS}__display-contact`} dangerouslySetInnerHTML={{ __html: appContentBehavioralToDisplay }}></div>
                            </>
                        }
                    </div>

                    {showEdit &&
                        <div className={`${COMPONENT_CLASS}__actions`}>
                            <Button
                                buttonText={t('buttons.btn_edit')}
                                type="default"
                                onClick={
                                    () => setIsEditingContact(true)
                                } />
                        </div>
                    }
                </>
            }
        </div>
    )
};

export default RichTextEditor;