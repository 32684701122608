import { MskScore } from "../../models/interfaces/msk-score";
import PillChart from "../charts/pill-chart/pill-chart";
import { UserProfileAssessment } from "../../models/interfaces/user-profile-asssessment";
import { Utils } from "../../utilities/utils";
import { ReactComponent as ScoreUpIcon } from "../../assets/icons/solid/stat-icon-up.svg";
import { ReactComponent as ScoreDownIcon } from "../../assets/icons/solid/stat-icon-down.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/nav/icon_checkmark.svg";
import { ReactComponent as DashIcon } from "../../assets/icons/solid/dash.svg";
import { ReactComponent as ExclamationIcon } from "../../assets/icons/solid/exclamation.svg";
import { UserProfileAssessmentPriority } from "../../models/enumerations/user-profile-assessment-priority";
import { ReactComponent as CrossRedIcon } from "../../assets/icons/solid/icon_white-cross-red-background.svg";
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useTranslation } from "react-i18next";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileAssessmentHeaderProps {
    assessment?: UserProfileAssessment;
    mskScore?: MskScore;
    children?: React.ReactNode;
    setSpecificAssessment?: Function;
    extraHeaderElement?: JSX.Element;
    mainTextHeader: string,
    mainText: string,
    whyImportantText: string,
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileAssessmentHeader: React.FC<UserProfileAssessmentHeaderProps> = (props: UserProfileAssessmentHeaderProps) => {
    const {
        assessment,
        mskScore,
        children,
        setSpecificAssessment,
        extraHeaderElement,
        mainTextHeader,
        mainText,
        whyImportantText
    } = props;

    const { t } = useTranslation();
    const percentageAssessmentScore = assessment?.current ? assessment.current : 0;
    const averageAssessmentScore = mskScore?.averageScores?.groupAverages?.find((x) => x.groupId === assessment?.groupId)?.average;
    const isPercentageHigherThanAvg = averageAssessmentScore ? (percentageAssessmentScore > (averageAssessmentScore) || percentageAssessmentScore === 100) : undefined;

    return (
        <div className={`${COMPONENT_CLASS}__header`}>
            <div className="flex justify-between items-center mt-8">
                <div className="flex gap-2">
                    {setSpecificAssessment &&
                        <>
                            <div
                                onClick={() => setSpecificAssessment(undefined)}
                                className="underline cursor-pointer text-sm font-medium flex items-center justify-center"
                            >
                                <div>{t('userProfile.assessments.breadcrumbs')}</div>
                            </div>
                            <div className="w-fit flex items-center justify-center"><ChevronRightIcon style={{ height: "24px" }} /></div>
                        </>
                    }
                    <div className="flex gap-2 items-center">
                        <div className={`${COMPONENT_CLASS}__icon-container`}>
                            {assessment?.groupId ? Utils.getFocusAreaData(assessment.groupId).icon : <></>}
                        </div>
                        <div className="text-sm font-medium ">{assessment?.assessmentName}</div>
                        <div>
                            {extraHeaderElement &&
                                <div>
                                    {extraHeaderElement}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${COMPONENT_CLASS}__header__main-row`}>
                <div className="flex flex-col min-w-36 h-full max-h-72 justify-center items-center bg-black-gray-5 rounded-xl p-6 width-half">
                    <div className="flex flex-row items-center justify-center gap-2">
                        {isPercentageHigherThanAvg
                            ?
                            <>
                                <span className="icon icon__20px">
                                    <CheckmarkIcon className="checkmark-icon" />
                                </span>
                                <span className="green-text font-bold text-24">{percentageAssessmentScore}%</span>
                            </>
                            : isPercentageHigherThanAvg === false
                                ?
                                <>
                                    <span className="icon icon__20px">
                                        <CrossRedIcon />
                                    </span>
                                    <span className="text-red-dark font-bold text-24">{percentageAssessmentScore}%</span>
                                </>
                                :
                                <span className="text-black-gray font-bold text-24">{percentageAssessmentScore}%</span>
                        }
                    </div>
                    <div className="max-h-full" style={{ minWidth: "1.25rem", maxWidth: "1.25rem" }}>
                        <svg width="100%" height="100%" viewBox="0 0 34 280" className=" overflow-visible">
                            <PillChart
                                key={assessment?.groupId}
                                uniqueId={assessment?.groupId}
                                index={0}
                                percentage={percentageAssessmentScore}
                                linePercentage={averageAssessmentScore}
                                lineText={t('userProfile.assessments.avg', { average: averageAssessmentScore })}
                                heightPixels={270}
                            />
                        </svg>
                    </div>
                </div>

                {assessment &&
                    <div className="flex flex-col gap-5 w-max width-half">
                        <div className={`${COMPONENT_CLASS}__score-section-card gap-2 items-center justify-center w-full`}>
                            <div className="flex items-center gap-2">
                                <div className={`${COMPONENT_CLASS}__icon-container-20`}>
                                    {assessment.priority === UserProfileAssessmentPriority.Low ? <CheckmarkIcon className="checkmark-icon" /> :
                                        assessment.priority === UserProfileAssessmentPriority.Moderate ? <DashIcon /> :
                                            assessment.priority === UserProfileAssessmentPriority.High ? <ExclamationIcon /> : <></>}
                                </div>
                                <div className="text-2xl font-bold">{assessment.priority}</div>
                            </div>
                            <div className="text-10 font-bold uppercase">{t('userProfile.assessments.priority')}</div>
                        </div>

                        <div className={`${COMPONENT_CLASS}__score-section-card gap-2 items-center justify-center w-full`}>
                            <div className="flex items-center gap-2">
                                <div className="text-2xl font-bold flex gap-1">
                                    <span>{assessment.progress > 0 ? "+" : ""}</span>
                                    <span>{assessment.progress}</span>
                                    <span>{t('userProfile.assessments.points')}</span>
                                </div>
                                <div>{assessment.progress > 0 ? <ScoreUpIcon /> :
                                    assessment.progress < 0 ? < ScoreDownIcon /> : <></>}</div>
                            </div>
                            <div className="text-10 font-bold uppercase">{t('userProfile.assessments.progress')}</div>
                        </div>
                    </div>
                }

                <div className={`${COMPONENT_CLASS}__score-section-card`}>
                    <div>
                        <div className="text-xs font-medium mb-2">{mainTextHeader}</div>

                        <p className={`${COMPONENT_CLASS}__header__main-text`} dangerouslySetInnerHTML={{ __html: mainText }}></p>

                        <div className="text-xs font-medium mb-2">{t('userProfile.assessments.why')}</div>

                        <p className="text-xs font-light">{whyImportantText}</p>

                        {children &&
                            <div className="mt-7">
                                {children}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserProfileAssessmentHeader;
