import React from "react";
import { LoginError } from "../../../models/enumerations/login-error";
import { useTranslation } from 'react-i18next';

export interface LoginModalExtraMessageProps {
    error: LoginError,
}

const COMPONENT_CLASS = 'c-login-modal';

/**
 * Represents buttons on the login modal.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const LoginModalExtraMessage: React.FC<LoginModalExtraMessageProps> = (props): JSX.Element => {
    const {
        error,
    } = props;
    const { t } = useTranslation();

    if (
        error === LoginError.TrialExpired
        || error === LoginError.AdminDeactivated
        || error === LoginError.AdminDeactivatedBilling
    ) {
        return (
            <div className={`${COMPONENT_CLASS}__extra-message`}>
                <p>{t('login.modal.message.extra')}&nbsp;</p>
                <a href="mailto:support@symmio.com">{t('login.modal.message.extraLink')}</a>
            </div>
        )
    }

    return <></>;
};

export default LoginModalExtraMessage;