import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import BaseCardLayoutActions from "../base-card-layout/base-card-layout-actions";
import OrganizationService from '../../utilities/services/organization-service';
import { Organization } from '../../models/interfaces/organization';
import { OrganizationStatus } from '../../models/enumerations/organization-status';
import { AppContent } from '../../models/interfaces/app-content';
import { AppContentType } from '../../models/enumerations/app-content-type';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import { useParams } from 'react-router-dom';
import { TextTypeInput } from '../forms';
import { useTranslation } from 'react-i18next';
import LeadLinksService from '../../utilities/services/lead-links-service';
import { IndustryEnglishLabel, IndustryListOptions, IndustryValue } from '../../models/industry';

interface CreateOrganizationProps {
  onBackClick: () => void;
  onSubmitClick: () => void;
  onOrganizationCreate: (data: any, product: any) => void;
}
interface LinkPaymentParams {
  id: string;
  userId: string;
  type?: string;
}
const CreateOrganization: React.FC<CreateOrganizationProps> = ({ onSubmitClick, onBackClick, onOrganizationCreate }) => {
  const COMPONENT_CLASS = "c-subscriptions";
  const FORM_COMPONENT_CLASS = "p-link-create-account";
  const [organizationError, setOrganizationError] = useState("");
  const { id } = useParams<LinkPaymentParams>();
  const { t } = useTranslation();
  const { state } = useAuthState();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onBlur', reValidateMode: 'onSubmit', defaultValues: {
      organizationName: state.user?.npeTemp!.orgName ?? '', industry: state.user?.industry || IndustryValue.Athletic, otherIndustry: ''
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const onSubmit = async (data: any) => {
    const { organizationName, industry, otherIndustry } = data;

    let leadLink = id ? await LeadLinksService.get(id) : null;

    if (organizationName && industry) {
      const existingOrganization = await OrganizationService.getBy([{ field: "name", operator: "==", value: organizationName }])
      if (existingOrganization.length > 0) {
        setOrganizationError(t('pricing.organizationPage.err_alreadyExists'));
        return;
      }
      setOrganizationError('');
      let org: Organization;
      org = {
        appContent: {
          [AppContentType.PAIN]: {} as AppContent,
          [AppContentType.BEHAVIORAL_HEALTH]: {} as AppContent,
        },
        status: OrganizationStatus.Active,
        name: organizationName,
        leadLinkId: id ? id : '',
        orgDefaultContentConfig: leadLink ? leadLink.orgDefaultContentConfig : undefined,
        industry: state.user?.industry ? state.user?.industry :
          industry === IndustryValue.Other ? otherIndustry : IndustryEnglishLabel[industry]
      };
      onOrganizationCreate(org, state.temp.product);
    } else {
      setOrganizationError(t('forms.orgName_val'));
    }
  }
  const selectedOption = watch("industry");

  const getIndustryValue = (industry: any) => {
    const industryExists = IndustryListOptions.find((x) => x.value === industry);
    return industryExists ? industry : IndustryValue.Other;
  }

  return (
    <>
      <div className={`${COMPONENT_CLASS}__header`}>
        {t('pricing.organizationPage.title')}
      </div>
      <div style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '1rem', textAlign: "center" }}>
        {t('pricing.organizationPage.subTitle')}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`${FORM_COMPONENT_CLASS}__sign-up-content`} style={{ height: '345px' }}>
          <div className={`${FORM_COMPONENT_CLASS}__field`}>
            {errors.organizationName && (
              <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                {t('forms.orgName_val')}
              </div>
            )}
            {organizationError && (
              <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                {organizationError}
              </div>
            )}
            <TextTypeInput
              id='organizationName'
              label={t('forms.orgName')}
              hideLabel={true}
              inputClassName={`c-input__input ${errors.organizationName && `${FORM_COMPONENT_CLASS} -showerror`}`}
              type='text'
              registerHook={register}
              registerOptions={{
                value: state.temp.organization ? state.temp.organization.name : "",
                required: true
              }}
              errorState={errors.organizationName}
            />
          </div>
          {!state.user?.industry &&
            <>
              <div className={`${FORM_COMPONENT_CLASS}__field`}>
                {errors.industry && (
                  <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                    {t('pricing.organizationPage.selectIndustry')}
                  </div>
                )}
                <select id="industry"
                  className={`c-input__input ${errors.industry && `${FORM_COMPONENT_CLASS} -showerror`}`}
                  {...register("industry", {
                    required: true,
                    value: state.temp.organization ? getIndustryValue(state.temp.organization.industry) : IndustryValue.Athletic
                  })}
                  style={{ display: 'flex' }}
                >
                  {IndustryListOptions.map((option) =>
                    <option value={option.value}>{option.label}</option>
                  )}
                </select>
              </div>
              {selectedOption === IndustryValue.Other && (
                <div className={`${FORM_COMPONENT_CLASS}__field`}>
                  {errors.otherIndustry && (
                    <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                      {t('pricing.organizationPage.enterIndustry')}
                    </div>
                  )}
                  <TextTypeInput
                    id='otherIndustry'
                    label={t('pricing.organizationPage.industry')}
                    hideLabel={true}
                    inputClassName={`c-input__input ${errors.otherIndustry && `${FORM_COMPONENT_CLASS} -showerror`}`}
                    registerHook={register}
                    registerOptions={{
                      required: true,
                      value: state.temp.organization ? state.temp.organization.industry : ''
                    }}
                    errorState={errors.otherIndustry} />
                </div>
              )}
            </>
          }
        </div>
      </form>
      <BaseCardLayoutActions submitButton={true} backButton={true} onBack={onBackClick} onSubmit={handleSubmit(onSubmit)} submitButtonText={state.temp.product === 'STARTER' ? t('pricing.organizationPage.btn_createAccount') : t('buttons.btn_continue')}></BaseCardLayoutActions>
    </>
  );
}

export default CreateOrganization;