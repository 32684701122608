import { DateTime } from "luxon";
import { MskHealthRiskOptions, MskHealthRiskOptionsTranslationReference } from "../models/msk-health-risk";
import UserRoles from "../models/user-roles";
import { OrganizationSubscription } from "../models/interfaces/organization-subscription";
import { ReactComponent as BehavioralHealthIcon } from "../assets/icons/solid/behavioral-health.svg";
import { ReactComponent as MovementIcon } from "../assets/icons/outline/movement.svg";
import { ReactComponent as MovementBreathingIcon } from "../assets/icons/solid/movement-and-breathing.svg";
import { ReactComponent as PhysicalActivityIcon } from "../assets/icons/solid/activity-level.svg";
import { ReactComponent as BodyCompIcon } from "../assets/icons/solid/body-comp.svg";
import { ReactComponent as BreathingIcon } from "../assets/icons/solid/breathing-qual.svg";
import { ReactComponent as InjuryHistoryIcon } from "../assets/icons/solid/injury-history.svg";
import { ReactComponent as NutritionIcon } from "../assets/icons/solid/nutrition-aware.svg";
import { ReactComponent as SleepIcon } from "../assets/icons/solid/sleep-well.svg";
import { ReactComponent as RoadToWellnessIcon } from "../assets/icons/solid/road-to-wellness.svg";
import { ReactComponent as CrossIcon } from "../assets/icons/general-icons/close.svg";
import { ReactComponent as CheckIcon } from "../assets/icons/solid/check.svg";
import { FocusArea } from "../models/enumerations/focus-area";
import moment from "moment";
import Badge from "../components/badge/badge";
import { ReportAnswer } from "../models/enumerations/report-answer";
import { PainSeverity } from "../models/enumerations/pain-severity";
import i18n from "../i18n";
import { LanguageDateFormat } from "../models/language-date-format";
// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

function capitalizeFirstLetter(input: string): string {
    if (input == null || input.length === 0) {
        return input;
    }
    return input.charAt(0).toUpperCase() + input.slice(1);
}

function getAge(dob: string | undefined): number | undefined {
    if (dob == null || dob.length === 0) {
        return undefined;
    }

    const years = DateTime.fromISO(dob).diffNow("years").years;
    return Math.floor(Math.abs(years)); // convert -68.814 to 68
}

function getRoleNameFromValue(input: string): string {
    if (input == null || input.length === 0) {
        return "";
    }
    return UserRoles.ALL_ROLES.find((r) => r.value === input)?.label || "";
}

function getHealthRiskName(riskCategory: string | undefined): string | undefined {
    return MskHealthRiskOptions.find((x) => x.value === riskCategory)?.label;
}

function getHealthRiskValue(riskCategory: string | undefined): string | undefined {
    return MskHealthRiskOptions.find((x) => x.value === riskCategory)?.value;
}

function getHealthRiskTranslationReference(riskCategory: string | undefined): string | undefined {
    return MskHealthRiskOptionsTranslationReference.find((x) => x.value === riskCategory)?.label;
}

function getHealthRiskNames(riskCategories: string[]): string[] {
    if (riskCategories == null || riskCategories.length === 0) {
        return [];
    }
    return riskCategories.map((x) => {
        return getHealthRiskName(x)!;
    });
}

function parseQueryString(querystring: string) {
    // parse query string
    const params = new URLSearchParams(querystring);

    const obj: any = {};

    // iterate over all keys
    for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {
            obj[key] = params.getAll(key);
        } else {
            obj[key] = params.get(key);
        }
    }

    return obj;
}

function validateEmailAddress(address: string): boolean {
    const regex = new RegExp(
        "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        "i"
    );
    return regex.test(address);
}

// Used only for dates where the timezone doesn't matter (e.g. get only the yyyy-mm-dd part of a birth date)
// Since it doesn't account for the time zone offset DO NOT use in situations where the time matters (e.g. subscription start date/time)
function convertToISO(dateString: string) {
    const [month, day, year] = dateString.split('/');
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    return date.toISOString().split('T')[0];
}

function getDiscountBadgeText(orgSub: OrganizationSubscription | undefined | null) {
    let badgeText = "";

    if (orgSub && orgSub.mySymmioMonthlyPrice !== undefined && orgSub.mySymmioYearlyPrice !== undefined) {
        const percentageDiff = (orgSub.mySymmioMonthlyPrice * 12 - orgSub.mySymmioYearlyPrice) / (orgSub.mySymmioMonthlyPrice * 12) * 100;

        if (percentageDiff >= 1) {
            const percentageDiffStr = percentageDiff.toFixed(0);
            badgeText = i18n.t('buttons.btn_savePercent', { percentage: percentageDiffStr });
        }
    }

    return badgeText;
}

function getPainBadge(painAnswer: string | boolean) {
    if (painAnswer && painAnswer !== ReportAnswer.A && painAnswer !== "NONE") {
        let text = "";
        switch (painAnswer) {
            case true:
                text = i18n.t('utils.getPainBadge.pain');
                break;
            case ReportAnswer.B:
            case PainSeverity.MILD:
                text = i18n.t('utils.getPainBadge.mild_pain');
                break;
            case ReportAnswer.C:
            case PainSeverity.MODERATE:
                text = i18n.t('utils.getPainBadge.moderate_pain');
                break;
            case ReportAnswer.D:
            case PainSeverity.SEVERE:
                text = i18n.t('utils.getPainBadge.severe_pain');
                break;
        }
        return (
            <Badge text={text} bgColor="#FFE9E5" textColor="#E7514F" />
        );
    }
}

function hasPain(painAnswer: string | boolean | undefined | null) {
    let hasPain = false;

    if (painAnswer && painAnswer !== ReportAnswer.A && painAnswer !== "NONE") {
        switch (painAnswer) {
            case true:
                hasPain = true;
                break;
            case ReportAnswer.B:
            case PainSeverity.MILD:
                hasPain = true;
                break;
            case ReportAnswer.C:
            case PainSeverity.MODERATE:
                hasPain = true;
                break;
            case ReportAnswer.D:
            case PainSeverity.SEVERE:
                hasPain = true;
                break;
        }
    }

    return hasPain;
}

function highestPainSeverity(painLevels: string[]) {
    const PainLevel: { [key: string]: number } = {
        [ReportAnswer.B]: 0,
        [PainSeverity.MILD]: 0,

        [ReportAnswer.C]: 1,
        [PainSeverity.MODERATE]: 1,

        [ReportAnswer.D]: 2,
        [PainSeverity.SEVERE]: 2
    };

    let highestSeverity = -1;

    for (const painLevel of painLevels) {
        if (PainLevel[painLevel] > highestSeverity) {
            highestSeverity = PainLevel[painLevel];
        }
    }

    switch (highestSeverity) {
        case 0:
            return PainSeverity.MILD;
        case 1:
            return PainSeverity.MODERATE;
        case 2:
            return PainSeverity.SEVERE;
        default:
            return PainSeverity.NONE;
    }
}

function getFocusAreaData(focusArea: string, movementBreathingFullName: boolean = true) {
    let focusAreaData = {
        icon: <></>,
        title: "",
    }

    switch (focusArea) {
        case FocusArea.MOVEMENT_HEALTH:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#ADDFFD" }
                }>
                    <span>
                        <MovementIcon style={{ backgroundColor: "#ADDFFD" }} />
                    </span>
                </div>
            );
            focusAreaData.title = i18n.t('focus.movementHealth');
            break;
        case FocusArea.BEHAVIORAL_HEALTH:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#8BE3CE" }
                }>
                    <span>
                        <BehavioralHealthIcon style={{ backgroundColor: "#8BE3CE" }} />
                    </span>
                </div>);
            focusAreaData.title = i18n.t('focus.behavioralHealth');
            break;
        case FocusArea.INJURY_HISTORY:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#FFA998" }}>
                    <span>
                        <InjuryHistoryIcon style={{ backgroundColor: "#FFA998" }} />
                    </span>
                </div>
            );
            focusAreaData.title = i18n.t('focus.injuryHistory');
            break;
        case FocusArea.BREATHING:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#ADDFFD" }}>
                    <span>
                        <BreathingIcon style={{ backgroundColor: "#ADDFFD" }} />
                    </span>
                </div>
            );
            focusAreaData.title = i18n.t('focus.breathingQuality');
            break;
        case FocusArea.SLEEP:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#CEBAF8" }}>
                    <span>
                        <SleepIcon style={{ backgroundColor: "#CEBAF8" }} />
                    </span>
                </div>
            );
            focusAreaData.title = i18n.t('focus.sleepWellness');
            break;
        case FocusArea.PHYSICAL_ACTIVITY:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#FFA998" }}>
                    <span>
                        <PhysicalActivityIcon style={{ backgroundColor: "#FFA998" }} />
                    </span>
                </div>
            );
            focusAreaData.title = i18n.t('focus.physicalActivity');
            break;
        case FocusArea.BODY_COMPOSITION:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#FFC79C" }}>
                    <span>
                        <BodyCompIcon style={{ backgroundColor: "#FFC79C" }} />
                    </span>
                </div>
            );
            focusAreaData.title = i18n.t('focus.bodyComposition');
            break;
        case FocusArea.NUTRITION:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#8BE3CE" }}>
                    <span>
                        <NutritionIcon style={{ backgroundColor: "#8BE3CE" }} />
                    </span>
                </div>
            );
            focusAreaData.title = i18n.t('focus.nutritionalAwareness');
            break;
        case FocusArea.MOVEMENT_HEALTH_BREATHING:
            // focusAreaData.icon = (
            //     <span className="flex flex-row" >
            //         <div className="" style={{ backgroundColor: "#ADDFFD", padding: "0.3rem", display: "flex" }}>
            //             <MovementIcon style={{ backgroundColor: "#ADDFFD" }} />
            //         </div>
            //         < div className="" style={{ backgroundColor: "#ADDFFD", padding: "0.3rem", display: "flex", marginLeft: "-0.3rem" }}>
            //             <BreathingIcon style={{ backgroundColor: "#ADDFFD" }} />
            //         </div>
            //     </span>
            // );
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#ADDFFD" }}>
                    <span>
                        <MovementBreathingIcon style={{ backgroundColor: "#ADDFFD" }} />
                    </span>
                </div>
            );
            focusAreaData.title = movementBreathingFullName ? i18n.t('focus.movementHealthAndBreathing') : i18n.t('focus.movementAndBreathing');
            break;
        case FocusArea.ROAD_TO_WELLNESS:
            focusAreaData.icon = (
                <div style={{ backgroundColor: "#ADDFFD" }}>
                    <span>
                        <RoadToWellnessIcon style={{ backgroundColor: "#ADDFFD" }} />
                    </span>
                </div>
            );
            focusAreaData.title = i18n.t('focus.roadToWellness');
            break;
    }

    return focusAreaData;
}

function getAssessmentBreakdownCell(text: string, showCheckMark?: boolean, width: string = "10px", height: string = "10px", fontSize: string = "12px", fontWeight: string = "300") {
    if (showCheckMark === undefined) {
        return;
    }
    const iconStyle = { minWidth: width, maxWidth: width, minHeight: height, maxHeight: height }

    return (
        <span className="flex flex-row items-center gap-1">
            <span>
                {showCheckMark
                    ? <CheckIcon style={iconStyle} />
                    : <CrossIcon style={iconStyle} />}
            </span>
            <span style={{ fontSize: fontSize, fontWeight: fontWeight }} dangerouslySetInnerHTML={{ __html: text }}></span>
        </span>
    );
}

function getGreenOrRedColor(score: number | undefined, goalScore: number) {
    if (score !== undefined && score >= goalScore) {
        return "bg-green-medium";
    }
    else {
        return "bg-red-medium";
    }
}

function getHowManyDaysAgo(date: Date) {
    const todayDate = new Date();

    const dateInMs = date.getTime();
    const todayDateInMs = todayDate.getTime();

    const differenceBtwDates = todayDateInMs - dateInMs;

    const aDayInMs = 24 * 60 * 60 * 1000;

    const daysDiff = Math.round(differenceBtwDates / aDayInMs);

    let daysDiffString = "";

    if (daysDiff === 0) {
        daysDiffString = i18n.t('utils.getHowManyDaysAgo.today');
    }
    else if (daysDiff === 1) {
        daysDiffString = i18n.t('utils.getHowManyDaysAgo.1dayAgo');
    }
    else {
        daysDiffString = i18n.t('utils.getHowManyDaysAgo.numOfDaysAgo', { numberOfDays: daysDiff });
    }

    return daysDiffString;
}

function getHowManyDaysAgoUsingMoment(dateStr: string | undefined) {
    if (!dateStr) {
        return "";
    }

    const now = moment();
    const lastAssessmentDate = moment(dateStr)
    const daysDiff = now.diff(lastAssessmentDate, "days");

    let daysDiffString = "";

    if (daysDiff === 0) {
        daysDiffString = i18n.t('utils.getHowManyDaysAgo.today');
    }
    else if (daysDiff === 1) {
        daysDiffString = i18n.t('utils.getHowManyDaysAgo.1dayAgo');
    }
    else {
        daysDiffString = i18n.t('utils.getHowManyDaysAgo.numOfDaysAgo', { numberOfDays: daysDiff });
    }

    return daysDiffString;
}

function formatDate(date: Date | null | undefined): string {
    if (!date || date.getFullYear() <= 1900) {
        return i18n.t('misc.notAvailable');
    }

    return moment(date).local().format(LanguageDateFormat[i18n.language]);
}

// previously, the FMS API would use a method capable of passing parameters
// like "&w=800", but now it points directly to the image. So having url
// params is breaking the image urls and causing them not to display in app.
// This method removes "&w=800" from the image url so that it displays
// properly.
function cleanImageUrl(url: string | undefined) {
    if (typeof (url) === 'string' && url.endsWith("&w=800")) {
        return url.replace("&w=800", "");
    }
    return url;
};

function getFocusAreaTranslatedNames(area: any) {
    switch (area) {
        case "Breathing Quality": return i18n.t('focus.breathingQuality');
        case "Movement Health": return i18n.t('focus.movementHealth');
        case "Injury History": return i18n.t('focus.injuryHistory');
        case "Physical Activity": return i18n.t('focus.physicalActivity');
        case "Behavioral Health": return i18n.t('focus.behavioralHealth');
        case "Sleep Wellness": return i18n.t('focus.sleepWellness');
        case "Body Composition": return i18n.t('focus.bodyComposition');
        case "Nutrition Awareness": return i18n.t('focus.nutritionalAwareness');

        case "Toe Touch": return i18n.t('movementFocus.toeTouch');
        case "Balance & Reach": return i18n.t('movementFocus.balanceAndReach');
        case "Shoulder Mobility": return i18n.t('movementFocus.shoulderMobility');
        case "Shoulder Clearing": return i18n.t('movementFocus.shoulderClearing');
        case "Rotation": return i18n.t('movementFocus.rotation');
        case "Spine Clearing": return i18n.t('movementFocus.spineClearing');
        case "Squat": return i18n.t('movementFocus.squat');
        case "Pain": return i18n.t('movementFocus.pain');
        default: return "..."
    }
}

// 0 Low Risk
// 1-27 Slight Risk
// 28-72  Moderate Risk
// 73-100 High isk
const getMskRiskLevel = (percentage: number | undefined | null) => {
    if (percentage === undefined || percentage === null) {
        return undefined;
    }

    let riskLevel = "";

    if (percentage === 0) {
        riskLevel = i18n.t('general.mskRisk.low')
    }
    else if (percentage >= 1 && percentage <= 27) {
        riskLevel = i18n.t('general.mskRisk.slight')
    }
    else if (percentage >= 28 && percentage <= 72) {
        riskLevel = i18n.t('general.mskRisk.moderate')
    }
    else if (percentage >= 73 && percentage <= 100) {
        riskLevel = i18n.t('general.mskRisk.high')
    }

    return riskLevel;
}

const isHighRisk = (percentage: number | null | undefined) => {
    if (percentage === null || percentage === undefined) {
        return false;
    }

    if (percentage >= 73) {
        return true;
    }

    return false;
}

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export const Utils = {
    capitalizeFirstLetter,
    getAge,
    getHealthRiskName,
    getHealthRiskValue,
    getHealthRiskTranslationReference,
    getHealthRiskNames,
    getRoleNameFromValue,
    parseQueryString,
    validateEmailAddress,
    convertToISO,
    getDiscountBadgeText,
    highestPainSeverity,
    getPainBadge,
    hasPain,
    getFocusAreaData,
    getAssessmentBreakdownCell,
    getGreenOrRedColor,
    getHowManyDaysAgo,
    getHowManyDaysAgoUsingMoment,
    formatDate,
    cleanImageUrl,
    getFocusAreaTranslatedNames,
    getMskRiskLevel,
    isHighRisk
};

// #endregion Exports
