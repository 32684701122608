import { GroupIds } from "../../models/enumerations/group-ids";
import { ReportDetailData } from "../../models/interfaces/reports/report-detail-data";
import ReportGenericHeader from "./report-generic-header";
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "c-report";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportScoreProps {
    data: ReportDetailData;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportScore: React.FC<ReportScoreProps> = (props: ReportScoreProps) => {
    const { data } = props;
    const { t } = useTranslation();
    data.users.sort(function compareFn(a, b): number {
        if (a?.name == null) {
            return -1;
        }
        if (b?.name == null) {
            return 1;
        }
        const nameA = a.name.toLocaleLowerCase();
        const nameB = b.name.toLocaleLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    const formatNumber = (percent?: number): string => {
        if (percent == null) {
            return t('general.notApplicable');
        }
        return percent.toString();
    }

    const isLow = (groupId: string, percent?: number): boolean => {
        if (percent == null) {
            return false;
        }

        switch (groupId) {
            // From https://docs.google.com/spreadsheets/d/14DSKCtdkPA4eeBhkTjZy-4aDrfP_ozjPU48oyIR9IU0/edit#gid=449420902
            case GroupIds.BEHAVIORAL_HEALTH:
                return percent < 75;
            case GroupIds.BREATHING:
                return percent <= 65;
            case GroupIds.NUTRITION:
                return percent <= 69;
            case GroupIds.BODY_COMPOSITION:
            case GroupIds.INJURY_HISTORY:
            case GroupIds.PHYSICAL_ACTIVITY:
            case GroupIds.SLEEP:
                return percent <= 70;
            case GroupIds.MOVEMENT_HEALTH:
                return percent < 50;

            // Custom
            case GroupIds.MSK:
                return percent < 70;
            default:
                return false;
        }
    }

    return (
        <div className={`${COMPONENT_CLASS} -score`}>
            <ReportGenericHeader companyName={data.organizationName} reportName={t('reports.score.reportName')} />
            <div className="flex gap-6 pt-6 pb-6 px-10 sub-header" style={{ backgroundColor: "#F7F9FC" }}>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>{t('reports.usersInReport')}</p>
                    <p>{data.totalUsers}</p>
                </div>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>{t('reports.groups')}</p>
                    {data.groupNames && data.groupNames.length > 0 ?
                        <p>{data.groupNames.join(", ")}</p> :
                        <p>{data.organizationName}</p>
                    }
                </div>
                <div className="w-1/3">
                    <p style={{ fontWeight: "500" }}>{t('reports.tags')}</p>
                    {data.tagNames && data.tagNames.length > 0 &&
                        <p>{data.tagNames.join(", ")}</p>
                    }
                </div>
                <div className="w-1/4">
                    <p style={{ fontWeight: "500" }}>{t('reports.filters')}</p>
                    {data.filterNames && data.filterNames.length > 0 &&
                        <p>{data.filterNames.join(", ")}</p>
                    }
                </div>
            </div>
            <div className={`${COMPONENT_CLASS}__section`}>
                <table className={`${COMPONENT_CLASS}__table`}>
                    <thead>
                        <tr>
                            <td>{t('reports.score.name')}</td>
                            <td>{t('reports.score.movement')}</td>
                            <td>{t('reports.score.breathing')}</td>
                            <td>{t('reports.score.injury')}</td>
                            <td>{t('reports.score.behavioral')}</td>
                            <td>{t('reports.score.activity')}</td>
                            <td>{t('reports.score.bodyComp')}</td>
                            <td>{t('reports.score.sleep')}</td>
                            <td>{t('reports.score.nutrition')}</td>
                            <td style={{ lineHeight: "10px" }}>{t('reports.score.wellness')}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.users.map((user, index) => (
                            <tr
                                className={`${COMPONENT_CLASS}__table__row`}
                                key={index}>
                                <td>{user.name}</td>
                                <td className={isLow(GroupIds.MOVEMENT_HEALTH, user.movementScore) || user.inPain ? "-warning" : ""}>
                                    {formatNumber(user.movementScore)}
                                </td>
                                <td className={isLow(GroupIds.BREATHING, user.breathingScore) ? "-warning" : ""}>
                                    {formatNumber(user.breathingScore)}
                                </td>
                                <td className={isLow(GroupIds.INJURY_HISTORY, user.injuryHistoryScore) ? "-warning" : ""}>
                                    {formatNumber(user.injuryHistoryScore)}
                                </td>
                                <td className={isLow(GroupIds.BEHAVIORAL_HEALTH, user.behavioralScore) ? "-warning" : ""}>
                                    {formatNumber(user.behavioralScore)}
                                </td>
                                <td className={isLow(GroupIds.PHYSICAL_ACTIVITY, user.activityScore) ? "-warning" : ""}>
                                    {formatNumber(user.activityScore)}
                                </td>
                                <td className={isLow(GroupIds.BODY_COMPOSITION, user.bodyCompScore) ? "-warning" : ""}>
                                    {formatNumber(user.bodyCompScore)}
                                </td>
                                <td className={isLow(GroupIds.SLEEP, user.sleepScore) ? "-warning" : ""}>
                                    {formatNumber(user.sleepScore)}
                                </td>
                                <td className={isLow(GroupIds.NUTRITION, user.nutritionScore) ? "-warning" : ""}>
                                    {formatNumber(user.nutritionScore)}
                                </td>
                                <td className={isLow(GroupIds.MSK, user.mskScore) ? "-warning" : ""}>
                                    {formatNumber(user.mskScore)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ReportScore;