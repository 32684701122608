import { useTranslation } from "react-i18next";
import logoHeader from "../../assets/images/symmio-logos/symmio-logo-text-white-side-large.png"

const COMPONENT_CLASS = "c-report";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportWellnessHeaderProps {
    name?: string;
    isFemale?: boolean;
    age?: number;
    mskScoreCreatedDate?: string;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportWellnessHeader: React.FC<ReportWellnessHeaderProps> = (props: ReportWellnessHeaderProps) => {
    const { name, isFemale, age, mskScoreCreatedDate } = props;

    const { t } = useTranslation();

    return (
        <div className={`${COMPONENT_CLASS}__header__wellness`}>
            <div className={`${COMPONENT_CLASS}__logo`}>
                <img src={logoHeader} alt="Symmio Logo" />
            </div>
            <div>
                <div className="text-sm font-light text-right">{t('reports.wellness.headerTitle')}</div>
                <div className="text-xs font-light text-right" style={{ color: "rgba(173, 223, 253, 1)" }}>{name || t('reports.wellness.noName')} • {isFemale ? t('general.female') : t('general.male')} • {age} {t('reports.wellness.years')} • {mskScoreCreatedDate}</div>
            </div>
        </div>
    );
}

export default ReportWellnessHeader;