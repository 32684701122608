/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import AssessmentResponse from "../../models/interfaces/assessment-response.entity";
import MskScoreService from "../../utilities/services/msk-score-service";
import moment from "moment";
import { GroupIds } from "../../models/enumerations/group-ids";
import { QuestionIds } from "../../models/enumerations/question-ids";
import UserProfileAssessmentHeader from "./user-profile-assessment-header";
import NonLinearLineChart from "../charts/non-linear-line-chart";
import LineChartJS from "../charts/line-chart-js";
import RiskChartVertical from "../charts/risk-chart-vertical";
import { useTranslation } from "react-i18next";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileBodyCompositionProps {
    mskScore?: MskScore;
    user: User;
    assessment?: any;
    assessmentResponses: AssessmentResponse[];
    allMskScores: MskScore[];
    setAllMskScores: Function;
    setSpecificAssessment: Function;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileBodyComposition: React.FC<UserProfileBodyCompositionProps> = (props: UserProfileBodyCompositionProps) => {
    const { setSpecificAssessment, mskScore, assessment, allMskScores, setAllMskScores, user, assessmentResponses } = props;
    const [bodyCompositionHistory, setBodyCompositionHistory] = useState<{ x: string, y: number }[]>();
    const [bmiHistory, setBmiHistory] = useState<{ x: string, y: number }[]>();
    const [hipToWaistRatioHistory, setHipToWaistRatioHistory] = useState<{ x: string, y: number }[]>();
    const isFemale = mskScore?.averageScores?.isFemale;
    const { t } = useTranslation();

    let breakpointsHipToWaist = [];

    if (isFemale) {
        breakpointsHipToWaist = [
            { min: 0.86, max: 0.91, label: t('reports.wellness.coloredCells.hipToWaist.redMainNextLine'), bgColor: "#FFA998", subLabel: t('reports.wellness.coloredCells.hipToWaist.redSubFemale') },
            { min: 0.81, max: 0.85, label: t('reports.wellness.coloredCells.hipToWaist.orangeMainNextLine'), bgColor: "#FFC79C", subLabel: t('reports.wellness.coloredCells.hipToWaist.orangeSubFemale') },
            { min: 0, max: 0.80, label: t('reports.wellness.coloredCells.hipToWaist.greenMainNextLine'), bgColor: "#8BE3CE", subLabel: t('reports.wellness.coloredCells.hipToWaist.greenSubFemale') },
        ];
    }
    else {
        breakpointsHipToWaist = [
            { min: 1.0, max: 1.5, label: t('reports.wellness.coloredCells.hipToWaist.redMainNextLine'), bgColor: "#FFA998", subLabel: t('reports.wellness.coloredCells.hipToWaist.redSubMale') },
            { min: 0.91, max: 0.99, label: t('reports.wellness.coloredCells.hipToWaist.orangeMainNextLine'), bgColor: "#FFC79C", subLabel: t('reports.wellness.coloredCells.hipToWaist.orangeSubMale') },
            { min: 0, max: 0.90, label: t('reports.wellness.coloredCells.hipToWaist.greenMainNextLine'), bgColor: "#8BE3CE", subLabel: t('reports.wellness.coloredCells.hipToWaist.greenSubMale') },
        ];
    }

    useEffect(() => {
        const getAllMskScores = async () => {
            if (!allMskScores) {
                const mskScores = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }])

                setAllMskScores(mskScores);
            }
        }
        getAllMskScores();
    }, [])
    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempBodyCompositionHistory = []
                let tempBmiHistory = []
                let tempHipToWaistRatioHistory = [];

                for (const mskScore of allMskScores) {
                    tempBodyCompositionHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.lifestyleScore?.bodyCompositionScore?.percentage ?? 0
                    })
                    tempBmiHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.lifestyleScore?.bodyCompositionScore?.bmi ?? 0
                    })

                    if (mskScore.lifestyleScore?.bodyCompositionScore?.hipToWaistRatio) {
                        tempHipToWaistRatioHistory.push({
                            x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                            y: mskScore.lifestyleScore?.bodyCompositionScore?.hipToWaistRatio
                        })
                    }
                }
                if (tempHipToWaistRatioHistory.length === 0) {
                    const hipToWaist = getbodyCompositionResponses()
                    if (hipToWaist) {
                        tempHipToWaistRatioHistory.push(hipToWaist);
                    }
                }
                setBodyCompositionHistory(tempBodyCompositionHistory);
                setBmiHistory(tempBmiHistory);
                setHipToWaistRatioHistory(tempHipToWaistRatioHistory);
            }
        }
        getHistory();
    }, [allMskScores])

    const getbodyCompositionResponses = () => {
        if (!assessmentResponses) {
            return;
        }
        const bodyCompResponse: any = assessmentResponses.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.BODY_COMPOSITION)
        if (bodyCompResponse.length > 0) {
            // find waist/hip question
            const hips = bodyCompResponse && bodyCompResponse.length > 0 ? bodyCompResponse[0].responses.find((response: any) =>
                response.questionId === QuestionIds.BODY_COMPOSITION_HIPS) : undefined
            const waist = bodyCompResponse && bodyCompResponse.length > 0 ? bodyCompResponse[0].responses.find((response: any) =>
                response.questionId === QuestionIds.BODY_COMPOSITION_WAIST) : undefined
            if (hips?.answerResponse && waist?.answerResponse) {
                const hipToWaistRatioCalc = parseFloat(waist.answerResponse) / parseFloat(hips.answerResponse)
                return {
                    x: moment(bodyCompResponse[0] && bodyCompResponse[0].created?._seconds ? bodyCompResponse[0].created._seconds * 1000 : NaN).format("MMM DD, YY"),
                    y: hipToWaistRatioCalc
                }
            }
        }
    }
    return (
        <>
            <div>
                <UserProfileAssessmentHeader
                    assessment={assessment}
                    mskScore={mskScore}
                    setSpecificAssessment={setSpecificAssessment}
                    mainTextHeader={t('userProfile.assessments.bodyCompHeader')}
                    mainText={t('userProfile.assessments.bodyCompText')}
                    whyImportantText={t('userProfile.assessments.bodyCompWhy')}
                />
            </div>

            {bodyCompositionHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper my-5`}>
                    <LineChartJS
                        data={bodyCompositionHistory.map(history => history.y)}
                        title={t('userProfile.assessments.bodyCompHistory')}
                        labels={bodyCompositionHistory.map(history => history.x)}
                        height={"200px"}
                        horizontalScrolling={true}
                        pointRadius={7}
                        pointBorderWidth={3}
                        borderWidth={5}
                        pointFontSize={16}
                        fontFamily={'pragmatica-extended'}
                        pointLabelOffset={0}
                        xLabelFontSize={14}
                        paddingTop={30}
                        paddingBottom={20}
                        maintainAspectRatio={false}
                    />
                </div>
            )}

            {bmiHistory && bmiHistory.length > 0 && (
                <div className="flex gap-5 mb-5">
                    <div className={`${COMPONENT_CLASS}__score-section-card min-w-72 max-w-72`}>
                        <h3 className="flex items-center justify-center mb-4">
                            {t('reports.wellness.coloredCells.bmi.title')}
                        </h3>
                        <div className="w-full h-64">
                            <RiskChartVertical
                                score={bmiHistory[bmiHistory.length - 1].y}
                                breakpoints={[
                                    { min: 30, max: 39, label: t('reports.wellness.coloredCells.bmi.redMain'), bgColor: "#FFA998", subLabel: "30.0 and above" },
                                    { min: 25, max: 29.9, label: t('reports.wellness.coloredCells.bmi.orangeMain'), bgColor: "#FFC79C", subLabel: "25.0 - 29.9" },
                                    { min: 18.5, max: 24.9, label: t('reports.wellness.coloredCells.bmi.greenMain'), bgColor: "#8BE3CE", subLabel: "18.5 - 24.9" },
                                    { min: 0, max: 18.4, label: t('reports.wellness.coloredCells.bmi.blueMain'), bgColor: "#ADDFFD", subLabel: "Below 18.5" },
                                ]}
                                decimals={true}
                            />
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                        <NonLinearLineChart
                            dataPoints={bmiHistory.map(history => history.y)}
                            labels={bmiHistory.map(history => history.x)}
                            title={t('userProfile.assessments.bmiHistory')}
                            sectionDetails={{
                                sections: 4,
                                rangeOne: { start: 0, end: 18.4 },
                                rangeTwo: { start: 18.5, end: 24.9 },
                                rangeThree: { start: 25, end: 29.9 },
                                rangeFour: { start: 30, end: 60 },
                                backgroundColorOrder: { first: "#ADDFFD", second: "#8BE3CE", third: "#FFE9CC", fourth: "#FFA998" },
                                dataPointColorOrder: { first: "#43B5F8", second: "#00DFBC", third: "#FFC79C", fourth: "#E7514F", },
                                yAxisLabels: { first: t('reports.wellness.coloredCells.bmi.blueMain').toUpperCase(), second: t('userProfile.assessments.healthy'), third: t('reports.wellness.coloredCells.bmi.orangeMain').toUpperCase(), fourth: t('reports.wellness.coloredCells.bmi.redMain').toUpperCase() },
                                decimals: true
                            }}
                            toolTipLabel={true}
                        />
                    </div>
                </div>

            )}

            {hipToWaistRatioHistory && hipToWaistRatioHistory.length > 0 && mskScore && (
                <div className="flex gap-5">
                    <div className={`${COMPONENT_CLASS}__score-section-card min-w-72 max-w-72`}>
                        <h3 className="flex items-center justify-center mb-4">
                            {t('reports.wellness.coloredCells.hipToWaist.title')}
                        </h3>
                        <div className="w-full h-64">
                            <RiskChartVertical
                                score={hipToWaistRatioHistory[hipToWaistRatioHistory.length - 1].y}
                                breakpoints={breakpointsHipToWaist}
                                decimals={true}
                            />
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                        <NonLinearLineChart
                            dataPoints={hipToWaistRatioHistory.map(history => history.y)}
                            labels={hipToWaistRatioHistory.map(history => history.x)}
                            title={t('userProfile.assessments.hipToWaistHistory')}
                            sectionDetails={{
                                sections: 3,
                                rangeOne: { start: breakpointsHipToWaist[2].min, end: breakpointsHipToWaist[2].max },
                                rangeTwo: { start: breakpointsHipToWaist[1].min, end: breakpointsHipToWaist[1].max },
                                rangeThree: { start: breakpointsHipToWaist[0].min, end: breakpointsHipToWaist[0].max },
                                backgroundColorOrder: { first: "#8BE3CE", second: "#FFE9CC", third: "#FFA998" },
                                dataPointColorOrder: { first: "#00DFBC", second: "#FFC79C", third: "#E7514F" },
                                yAxisLabels: { first: t('userProfile.assessments.low').toUpperCase(), second: t('userProfile.assessments.moderate').toUpperCase(), third: t('userProfile.assessments.high').toUpperCase() },
                                decimals: true,
                            }}
                            toolTipLabel={true}
                        />
                    </div>
                </div>

            )}
        </>
    );
}

export default UserProfileBodyComposition;
