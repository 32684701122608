import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import { Button } from '../button/button';
import { Loader } from '../loader/loader';
import { UserMenu } from '../user-menu/user-menu';
import UserUtil from '../../utilities/user-util';
import { SwitchOrganizationModal } from '../switch-organization-modal/switch-organization-modal'
import { NotificationMenu } from '../notifications-menu/notifications-menu';
import { LanguageSelectorUserMenu } from '../language-selector-user-menu/language-selector-user-menu';
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "c-application-header";

const ApplicationHeader: React.FC = () => {
    const [isSwitching, setIsSwitching] = useState(false);
    const [open, setOpen] = useState(false);
    const { state } = useAuthState();
    const history = useHistory();
    const useBackButton = history.location.pathname.split('/').length > 2;
    const isSuperAdmin = state?.claims?.superAdmin === true;
    const isAccountHolder = UserUtil.isAccountHolder(state.user);
    const useOrganizationHeader = isSuperAdmin || (isAccountHolder && state.organization.subOrganizationIds && state.organization.subOrganizationIds?.length > 0);
    const { t } = useTranslation();

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <Loader
                isVisible={isSwitching} />
            <div className={`${COMPONENT_CLASS}__container`}>
                {useBackButton &&
                    <div className={`${COMPONENT_CLASS}__back hide-on-mobile hide-on-tablet`}>
                        <Button
                            type="back"
                            buttonText={history.location.pathname.split('/')[1] === 'users' ? t('buttons.btn_backToAllUsers') : t('buttons.btn_back')}
                            buttonStyle="secondary"
                            onClick={() => history.goBack()} />
                    </div>
                }
                <div className={`${COMPONENT_CLASS}__organization hide-on-mobile`}>
                    {useOrganizationHeader &&
                        <div className={`${COMPONENT_CLASS}__organization__name`}>
                            <div>
                                <span className={`label-small`}>{t('applicationHeader.viewing')}</span>
                                <h3 className="break-all"> {state.organization?.name || ''}</h3>
                            </div>

                            <div className='hide-on-mobile'>
                                <Button onClick={() => setOpen(true)} buttonText={t('buttons.btn_switchOrg')} />
                            </div>
                        </div>
                    }
                </div>
                {(UserUtil.isAccountHolder(state.user) || UserUtil.isAdmin(state.user) || UserUtil.isManager(state.user)) &&
                    <NotificationMenu />
                }
                <UserMenu />
                <LanguageSelectorUserMenu userPortal={true} />
            </div>
            <SwitchOrganizationModal
                open={open}
                setOpen={setOpen}
                setIsSwitching={setIsSwitching} />
        </div>
    );
}

export { ApplicationHeader };
