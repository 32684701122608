import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../components/button/button";
import EmailList from "../../components/email-list/email-list";
import { Tag } from "../../components/tag/tag";
import { Group } from "../../models/interfaces/group";
import { User } from "../../models/interfaces/user";
import StringUtil from "../../utilities/string-util";
import { Utils } from "../../utilities/utils";
import { TextTypeInput } from "../../components/forms";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "p-group-edit";

export interface GroupTabProps {
    group: Group;
    setGroup: React.Dispatch<React.SetStateAction<Group>>;
    handleSubmit: (event: React.FormEvent) => Promise<void>;
    isNewGroup: boolean;
    users: User[];
    nameErrorMessages: string[];
    pendingGroupEmails: string[];
    setPendingGroupEmails: React.Dispatch<React.SetStateAction<string[]>>;
}

const GroupTab: React.FunctionComponent<GroupTabProps> = (props) => {
    const {
        group,
        setGroup,
        handleSubmit,
        isNewGroup,
        nameErrorMessages,
        users,
        pendingGroupEmails,
        setPendingGroupEmails,
    } = props;

    const { t } = useTranslation();
    const history = useHistory();
    const [emailErrorMessages, setEmailErrorMessages] = useState([] as string[]);

    const {
        register,
        setValue,
        formState: { errors },
    } = useForm({
        mode: 'onBlur', reValidateMode: 'onSubmit', defaultValues: {
            name: "",
        }
    });

    useEffect(() => {
        setValue("name", group?.name || "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group?.name]);

    return (
        <form className={COMPONENT_CLASS} onSubmit={handleSubmit}>
            <div className={nameErrorMessages.length > 0 ? `${COMPONENT_CLASS}__fieldgroup-error` : `${COMPONENT_CLASS}__fieldgroup`}>
                <TextTypeInput
                    id='name'
                    type="text"
                    label={t('groups.edit.groupDetails_tab.name')}
                    registerHook={register}
                    registerOptions={{
                        value: group?.name || "",
                        onChange: (e) => setGroup(Object.assign({}, group, { name: e.target.value })),
                        required: true,
                    }}
                    errorState={errors.name}
                />
            </div>
            { // if
                nameErrorMessages.length > 0 &&
                <div className={`${COMPONENT_CLASS}__email-errors`}>
                    {nameErrorMessages.map((error, i) => <div className={`${COMPONENT_CLASS}__email-errors__item`} key={`error-${i}`}>{error}</div>)}
                </div>
            }


            <div className={emailErrorMessages.length > 0 ? `${COMPONENT_CLASS}__fieldgroup-error` : `${COMPONENT_CLASS}__fieldgroup`}>
                <label className="text-black">{t('groups.edit.groupDetails_tab.addUsersToGroup')}</label>
                <EmailList
                    existingUsers={users && users.length > 0 ? users.filter(user => user.id != null).map(user => user.id!) : undefined}
                    onEmailsAdded={(emails) => {
                        emails = StringUtil.getUniqueValues(emails, true);

                        let pendingEmails = [...new Set(pendingGroupEmails.concat(emails))];
                        if (users && users.length > 0) {
                            const groupEmails = users.map(user => user.email).filter(email => email && email.trim().length > 0);
                            pendingEmails = pendingEmails.filter(email => !StringUtil.existsInArray(email, groupEmails, true));
                        }

                        setPendingGroupEmails(pendingEmails);
                    }}
                    onEmailsError={(errors) => { emailErrorMessages.length = 0; emailErrorMessages.push(...new Set(emailErrorMessages.concat(errors))); setEmailErrorMessages(emailErrorMessages); }}
                    enableSuggestions
                />
            </div>
            { // if
                emailErrorMessages.length > 0 &&
                <div className={`${COMPONENT_CLASS}__email-errors`}>
                    {emailErrorMessages.map((error, i) => <div className={`${COMPONENT_CLASS}__email-errors__item`} key={`error-${i}`}>{error}</div>)}
                </div>
            }
            <div className="mb-1">
                {pendingGroupEmails != null && pendingGroupEmails.length > 0 &&
                    <div className={`${COMPONENT_CLASS}__email-invites`}>
                        <div>
                            {pendingGroupEmails.map((email, i) => (<Tag
                                id={i.toString()}
                                onCloseClick={(index) => {
                                    const emails = [...pendingGroupEmails];
                                    emails.splice(parseInt(index), 1);
                                    setPendingGroupEmails(emails);
                                }}
                                key={`email-${i}`}
                            >{email}</Tag>))}
                        </div>
                    </div>
                }
            </div>

            {(group?.userCount || 0) > 0 &&
                <div className={`${COMPONENT_CLASS}__table-container`}>
                    <label>{t('groups.edit.groupDetails_tab.usersInGroup')}</label>
                    <table
                        className="light"
                        cellSpacing={0}
                        cellPadding={0}>
                        <thead>
                            <tr>
                                <th>{t('groups.edit.groupDetails_tab.name')}</th>
                                <th>{t('groups.edit.groupDetails_tab.roles')}</th>
                                <th>{t('groups.edit.groupDetails_tab.email')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length > 0 &&
                                users.map((user, index) => {
                                    return (
                                        <tr key={index} onClick={() => history.push(`/users/${user.id}`)}>
                                            <td>{`${user.firstName} ${user.lastName}`}</td>
                                            <td>
                                                {(user.roles) &&
                                                    user.roles.map((role, index) => (
                                                        <Tag key={index} id={index.toString()}>{
                                                            Utils.getRoleNameFromValue(role)}
                                                        </Tag>
                                                    ))
                                                }
                                            </td>
                                            <td>{user.email}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }
            <div className={`${COMPONENT_CLASS}__actions`}>
                <Button
                    type="default"
                    buttonStyle="white"
                    buttonText={t('buttons.btn_cancel')}
                    onClick={(event) => {
                        event.preventDefault();
                        history.push("/groups");
                    }} />
                <Button
                    type="default"
                    buttonText={`${isNewGroup ? t('groups.edit.addGroup') : t('buttons.btn_saveChanges')}`}
                    onClick={(event) => handleSubmit(event)} />
            </div>
        </form>
    );
}

export default GroupTab;
