import { SymmioAccessType } from "./enumerations/symmio-access-type";
import i18n from "../i18n";

export interface SymmioAccessTypeOptionsInterface {
    value: SymmioAccessType;
    label: string;
}

export const SymmioAccessTypeOptions: SymmioAccessTypeOptionsInterface[] = [
    {
        value: SymmioAccessType.WebAccess,
        label: i18n.t('general.no'),
    }, {
        value: SymmioAccessType.AppLicense,
        label: i18n.t('general.yes'),
    }
]

export const SymmioAccessLinkTypeOptions: SymmioAccessTypeOptionsInterface[] = [
    {
        value: SymmioAccessType.WebAccess,
        label: i18n.t('deeplinks.edit.linkType_modal.assessmentOnly'),
    }, {
        value: SymmioAccessType.AppLicense,
        label: i18n.t('deeplinks.edit.linkType_modal.assessmentWellness'),
    }
]

export const SymmioAccessLinkTypeLabels = {
    [SymmioAccessType.WebAccess]: i18n.t('deeplinks.edit.linkType_modal.webAssessmentOnly'),
    [SymmioAccessType.AppLicense]: i18n.t('general.wellnessProgram'),
}