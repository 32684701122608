/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Organization } from "../../../models/interfaces/organization";
import { useAuthState } from "../../../utilities/contexts/auth-state-context";
import OrganizationService from "../../../utilities/services/organization-service";
import { useHistory } from "react-router";
import { FormikTextField } from "../../formik-fields/formik-text-field";
import { SaveButton } from "../../button/save-button";
import { AppContentType } from "../../../models/enumerations/app-content-type";
import { AppContent } from "../../../models/interfaces/app-content";
import { enqueueSnackbar } from "notistack";
import { Button } from "../../button/button";
import { useTranslation } from 'react-i18next';

type EditSubOrganizationFormProps = {
    organizationId?: string,
    redirect?: string,
    onSave?: Function,
    onCancel?: Function,
};

interface formValues {
    organizationName: string,
}

const COMPONENT_CLASS = "c-organization-modal";

export const EditSubOrganizationForm: React.FC<EditSubOrganizationFormProps> = (props: EditSubOrganizationFormProps) => {
    const { organizationId, redirect, onSave, onCancel } = props;
    const { state } = useAuthState();
    const history = useHistory();
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [organization, setOrganization] = useState<Organization>();
    const [initialForm, setInitialForm] = useState<formValues>({ organizationName: '' });
    const [parentOrgName, setParentOrgName] = useState<string>();
    const [parentNameError, setParentNameError] = useState<string>();

    useEffect(() => {
        const loadData = async () => {
            if (!organizationId) {
                setLoading(false);
                return
            };

            const o = await OrganizationService.get(organizationId);

            if (!o) {
                setLoading(false);
                return
            };

            const parentOrgId = o.parentOrganizationId;

            if (parentOrgId) {
                const parentOrg = await OrganizationService.get(parentOrgId);
                if (parentOrg) {
                    if (parentOrg.name) {
                        setParentOrgName(parentOrg.name);
                    } else {
                        setParentNameError(t('organization.edit.err_parentNoName'));
                    }
                } else {
                    setParentNameError(t('organization.edit.err_cantRetrieveParent'));
                }
            }

            setOrganization(o);

            setInitialForm({ organizationName: o?.name ?? '' })

            setLoading(false);
        };

        loadData();

    }, [organizationId])

    const saveOrganization = async (values: formValues) => {
        setSubmitting(true);
        if (!organization) {
            const parentOrgId = state.user?.organizationId;

            if (!parentOrgId) return;

            const parentOrg = await OrganizationService.get(parentOrgId);

            if (!parentOrg) return;

            const o: Organization = {
                name: values.organizationName.trim(),
                status: parentOrg.status,
                parentOrganizationId: parentOrg.id,
                isSubOrganization: true,
                appContent: {
                    [AppContentType.PAIN]: {} as AppContent,
                    [AppContentType.BEHAVIORAL_HEALTH]: {} as AppContent,
                },
            }

            if (parentOrg.testingOrg === true) {
                o.testingOrg = true;
            }

            const savedOrg = await OrganizationService.save(o, state.user);

            if (!savedOrg || !savedOrg.id) return;

            const newSubIds = parentOrg.subOrganizationIds ?? [];

            newSubIds.push(savedOrg.id);

            parentOrg.subOrganizationIds = newSubIds;

            await OrganizationService.save(parentOrg, state.user);
        } else {
            const o: Organization = { ...organization };

            o.name = values.organizationName.trim();

            await OrganizationService.save(o, state.user);
        }

        setSubmitting(false);

        redirect && history.push(redirect);

        onSave && onSave();

        enqueueSnackbar(organizationId ? t('organization.edit.orgEdited') : t('organization.edit.orgCreated'), { variant: "toast", width: "450px" });
    }

    return (
        <Formik
            initialValues={initialForm}
            enableReinitialize
            validationSchema={
                loading
                    ? null
                    : Yup.object().shape({
                        organizationName: Yup
                            .string()
                            .required(t('organization.edit.validation.orgName')),
                    })
            }
            onSubmit={(values: formValues) => {
                saveOrganization(values);
            }}
        >
            {({
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <h1 style={{ marginTop: '56px', marginBottom: '8px' }}>{t('organization.edit.subOrgTitle')}</h1>
                    <div style={{ display: 'inline-block', width: '450px' }}>
                        <p style={{ fontSize: '12px', fontWeight: 700, marginBottom: '2px', opacity: '60%' }}>{t('organization.edit.fields.parentOrganization')}</p>
                        <p style={{ fontSize: '14px', marginBottom: '32px', opacity: '60%' }}>{parentOrgName ?? parentNameError}</p>
                        <div className={`${COMPONENT_CLASS}__field`}>
                            <FormikTextField
                                name="organizationName"
                                label={t('organization.edit.fields.orgName')}
                                disabled={loading}
                                required
                            />
                        </div>
                        <div style={{ margin: '40px 0', display: 'flex', justifyContent: organization ? 'right' : 'left' }}>
                            {
                                organization &&
                                <Button
                                    type='link'
                                    onClick={() => onCancel && onCancel()}
                                    buttonText={t('buttons.btn_cancel')}
                                    inputStyle={{
                                        padding: '5px 15px',
                                        margin: '5px 25px',
                                    }} />
                            }
                            <SaveButton
                                text={organization ? t('buttons.btn_save') : t('organization.edit.addOrganization')}
                                disabled={submitting || loading}
                            />
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
}