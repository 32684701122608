/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import UniqueUserInviteLinksService from '../../utilities/services/unique-user-invite-link-service';
import { Timestamp } from 'firebase/firestore';
import UserService from '../../utilities/services/user-service';
import { User } from '../../models/interfaces/user';
import { createUserWithEmailAndPassword } from "firebase/auth";
import getFirebase from '../../utilities/firebase';
import { useHistory } from 'react-router-dom';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import { Modal } from '../../components/modal/modal';
import BaseCardLayout from '../../components/base-card-layout/base-card-layout';
import BaseCardLayoutActions from '../../components/base-card-layout/base-card-layout-actions';
import { TextTypeInput } from '../../components/forms';
import { EmailTypeInput } from '../../components/forms/email-type-input';
import { CheckboxTypeInput } from '../../components/forms/checkbox-type-input';
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "p-create-account";

const CreateAccountPage: React.FC = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<any>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { state, setState } = useAuthState();
    const { auth } = getFirebase();
    const [passwordError, setPasswordError] = useState("");
    const [EmailError, setEmailError] = useState("");
    const queryParams = new URLSearchParams(window.location.search);
    const inviteId = queryParams.get("inviteId");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [errorTitle, setErrorTitle] = useState("");
    const [modalVisible, setModalVisble] = useState(false);
    const expirationInMillis = Timestamp.now().toMillis() - 2592000000;
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const [emailInvited, setEmailInvited] = useState("");
    const [isCheckedTerms, setIsCheckedTerms] = useState(false);
    const { t } = useTranslation();

    let history = useHistory();
    let authUser;

    useEffect(() => {
        if (state.authenticated) {
            history.replace("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const getEmail = async () => {
            const invite = await UniqueUserInviteLinksService.get(inviteId ?? "");

            if (invite) {
                const user = await UserService.get(invite.userId);
                setEmailInvited(user?.email ?? "");
                if (!user) {
                    // this needs to be "invalid invite link"
                    history.push("/account-creation-expired");
                }
            }
        }
        getEmail();
    }, []);


    const onSubmit = async (data: any) => {
        setIsLoading(true);
        const { email, password, passwordTwo } = data;
        let { firstName, lastName } = data;

        // Capitalize first letter
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
        lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1)

        if (password !== passwordTwo) {
            setPasswordError(t('baseCard.err_password_npMatch'));
            setIsLoading(false);
            return;
        }

        let user: User;

        try {
            let userByEmail = await UserService.getByEmail(emailInvited, inviteId ?? "");
            user = userByEmail;
        } catch (exception: any) {
            switch (exception.name) {
                case "EntityNotFoundException":
                    setErrorTitle(t('app.signup.emailError'));
                    setError(
                        t('app.login.error.entityNotFoundException')
                    );
                    break;
                case "DuplicateEntityFoundException":
                    setErrorTitle(t('app.signup.sorry'));
                    setError(
                        t('app.signup.sorryText')
                    );
                    break;
                default:
                    setErrorTitle(t('app.signup.oh'));
                    setError(
                        t('forms.authErrors.custom')
                    );
                    break;
            }

            setModalVisble(true);
            setIsLoading(false);
            return;
        }

        try {
            authUser = await createUserWithEmailAndPassword(
                auth,
                emailInvited.toLowerCase(),
                password
            );
        } catch (error: any) {
            // This doesn't seem to be used anywhere
            // setGlobalError("Signup Creating Account", error);

            switch (error.code) {
                case "auth/user-not-found":
                    setEmailError(t('forms.err_emailDoesNotExist'));
                    break;
                case "auth/email-already-in-use":
                    setEmailError(t('forms.err_emailInUse'));
                    break;
                case "auth/invalid-email":
                    setEmailError(t('forms.err_emailInvalid'));
                    break;
                case "auth/weak-password":
                    setPasswordError(
                        t('baseCard.err_password_minChars')
                    );
                    break;
                default:
                    setErrorTitle(t('app.signup.oh'));
                    setError(
                        t('forms.authErrors.custom')
                    );
                    setModalVisble(true);
                    break;
            }
            setIsLoading(false);
            return;
        }
        if (authUser?.user == null) {
            setPasswordError(t('forms.err_userNotFound', { email: email.toLowerCase() }));
            setIsLoading(false);
            return;
        }

        user.isAuthenticated = true;
        user.firstName = firstName;
        user.lastName = lastName;

        //don't think we need this data for the admin portal (these are part of global state)
        //setHasMskScore(false);
        //setIdentity(user);
        UserService.update(user);

        // For some reason after creating user it's supposed to log them in
        // Instead its not logging them in but creating an auth session that's 
        // in a weird state. They can't access any pages and are stuck in login screen
        // but can't login because there is already an auth session active.
        // auth.signOut();
        // setState((state) => ({ ...state, ...{ authenticated: false, user: null } }));

        // This signs them in again similar to the createUserWithEmailAndPassword, they aren't quite logged in,
        // but auth thinks they are. They then can't login until refreshing the page.
        // signInWithEmailAndPassword(auth, data.email, data.password)
        //     .catch((error: any) => {
        //         handleError(error);
        //         setModalVisble(true);
        //     });

        history.push("/account-created");
    }

    useEffect(() => {
        const checkIfExpired = async () => {
            const invite = await UniqueUserInviteLinksService.get(inviteId ?? "");
            if (invite && invite.updated) {
                const inviteInMillis = invite?.updated?.toMillis();
                if (inviteInMillis && inviteInMillis < expirationInMillis) {
                    setIsExpired(true);
                }
            }
            else {
                const inviteInMillis = invite?.created?.toMillis();
                if (inviteInMillis && inviteInMillis < expirationInMillis) {
                    setIsExpired(true);
                }
            }

        }
        checkIfExpired();
    }, []);


    if (isExpired) {
        history.replace("/account-creation-expired");
    }

    const onModalDismiss = () => {
        setModalVisble(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleError = (error: any) => {
        if (error?.code == null) {
            setError(t('forms.authErrors.custom'));
        }

        switch (error.code) {
            case "auth/user-not-found":
                setError(t('forms.authErrors.userNotFound'));
                break;
            case "auth/wrong-password":
                setError(t('forms.authErrors.wrongPassword'));
                break;
            case "auth/too-many-requests":
                setError(t('forms.authErrors.tooManyRequests'));
                break;
            case "auth/internal-error":
                setError(t('forms.authErrors.internal'));
                break;
            default:
                setError(t('forms.authErrors.custom'));
                break;
        }
    }

    return (
        <BaseCardLayout>
            <div className="flex justify-center" style={{ marginTop: "2.3%" }}>
                <div className={`${COMPONENT_CLASS}`}>
                    <Modal
                        onClose={onModalDismiss}
                        isOpen={modalVisible}
                        isLoading={isLoading}
                        title={errorTitle}
                        submitButtonText={t('app.signup.tryAgain')}
                        onSubmit={() => onModalDismiss()}
                        defaultCenterModal={true}
                    // className={`${COMPONENT_CLASS}__modal`}
                    >
                        <div>
                            <p>{error}</p>
                        </div>
                    </Modal>
                    <h4 className="-light">{t('app.signup.title')}</h4>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={`${COMPONENT_CLASS}__content`}>
                            <div className={`${COMPONENT_CLASS}__field`}>
                                {/* {errors.email && (
                                    <div
                                        role="alert"
                                        className={`${COMPONENT_CLASS} -error`}>
                                        Email is required
                                    </div>
                                )} */}
                                {EmailError && (
                                    <div
                                        role="alert"
                                        className={`${COMPONENT_CLASS} -error`}>
                                        {EmailError}
                                    </div>
                                )}
                                {/* <EmailTypeInput
                                    id='email'
                                    style={{ backgroundColor: "#f3f3f4", border: "1px solid #eaeaec", marginBottom: "0px" }}
                                    registerHook={register}
                                    registerOptions={{
                                        value: emailInvited,
                                    }}
                                    inputClassName={`c-input__input ${errors.email &&
                                        `${COMPONENT_CLASS} -showerror`
                                        }`}
                                /> */}

                                {/*TODO: using email input wasn't taking value, not sure why. Assigning for a later date to fix due to work load */}
                                <EmailTypeInput
                                    id='email'
                                    style={{ backgroundColor: "#f3f3f4", border: "1px solid #eaeaec", marginBottom: "0px" }}
                                    readOnly={true}
                                    inputClassName={`c-input__input ${errors.email &&
                                        `${COMPONENT_CLASS} -showerror`
                                        }`}
                                    registerHook={register}
                                    registerOptions={{
                                        value: emailInvited,
                                    }}
                                    errorState={errors.email} />

                                <p style={{ marginTop: "0.75rem", marginBottom: "1.25rem", fontSize: "12px", fontWeight: "300" }}>{t('pages.createAccount.emailForLogin')}</p>
                            </div>
                            <div className={`${COMPONENT_CLASS}__field`}>
                                {errors.firstName && (
                                    <div
                                        role="alert"
                                        className={`${COMPONENT_CLASS} -error`}>
                                        {t('manageAccount.firstName_req')}
                                    </div>
                                )}
                                <TextTypeInput
                                    id='firstName'
                                    type='text'
                                    label={t('manageAccount.firstName')}
                                    hideLabel={true}
                                    inputClassName={`c-input__input -displaytext ${errors.firstName &&
                                        `${COMPONENT_CLASS} -showerror`
                                        }`}
                                    registerHook={register}
                                    registerOptions={{
                                        required: true,
                                    }}
                                    errorState={errors.firstName} />

                            </div>
                            <div className={`${COMPONENT_CLASS}__field`}>
                                {errors.lastName && (
                                    <div
                                        role="alert"
                                        className={`${COMPONENT_CLASS} -error`}>
                                        {t('manageAccount.lastName_req')}
                                    </div>
                                )}
                                <TextTypeInput
                                    id='lastName'
                                    type='text'
                                    label={t('manageAccount.lastName')}
                                    hideLabel={true}
                                    inputClassName={`c-input__input -displaytext ${errors.lastName &&
                                        `${COMPONENT_CLASS} -showerror`
                                        }`}
                                    registerHook={register}
                                    registerOptions={{
                                        required: true,
                                    }}
                                    errorState={errors.lastName} />
                            </div>
                            <div className={`${COMPONENT_CLASS}__field`}>
                                {errors.password && (
                                    <div
                                        role="alert"
                                        className={`${COMPONENT_CLASS} -error`}>
                                        {t('baseCard.err_password_required')}
                                    </div>
                                )}
                                {passwordError && (
                                    <div
                                        role="alert"
                                        className={`${COMPONENT_CLASS} -error`}>
                                        {passwordError}
                                    </div>
                                )}
                                <TextTypeInput
                                    id='password'
                                    type='password'
                                    label={t('forms.password')}
                                    hideLabel={true}
                                    inputClassName={`c-input__input ${errors.password &&
                                        `${COMPONENT_CLASS} -showerror`
                                        }`}
                                    registerHook={register}
                                    registerOptions={{
                                        required: true,
                                    }}
                                    errorState={errors.password} />
                            </div>
                            <div className={`${COMPONENT_CLASS}__field`}>
                                {errors.passwordTwo && (
                                    <div
                                        role="alert"
                                        className={`${COMPONENT_CLASS} -error`}>
                                        {t('baseCard.err_password_required')}
                                    </div>
                                )}
                                {passwordError && (
                                    <div
                                        role="alert"
                                        className={`${COMPONENT_CLASS} -error`}>
                                        {passwordError}
                                    </div>
                                )}
                                <TextTypeInput
                                    id='passwordTwo'
                                    type='password'
                                    label={t('forms.confirmPassword')}
                                    hideLabel={true}
                                    inputClassName={`c-input__input ${errors.passwordTwo &&
                                        `${COMPONENT_CLASS} -showerror`
                                        }`}
                                    registerHook={register}
                                    registerOptions={{
                                        required: true,
                                    }}
                                    errorState={errors.passwordTwo} />
                            </div>
                            <div className={`${COMPONENT_CLASS}__field`}>
                                <div className={`${COMPONENT_CLASS}__terms`} style={{ rowGap: "0px" }}>
                                    {errors.termsAndConditions && (
                                        <div
                                            role="alert"
                                            className={`${COMPONENT_CLASS} -error`}
                                            style={{ gridRow: "1 / 2", gridColumn: "1 / 3" }}>
                                            {t('forms.tc_agree_val')}
                                        </div>
                                    )}
                                    <CheckboxTypeInput
                                        id='termsAndConditions'
                                        registerHook={register}
                                        registerOptions={{
                                            required: true,
                                            onChange: (e) => {
                                                setIsCheckedTerms(e.target.checked);
                                            }
                                        }}
                                        // label='I agree to terms and conditions'
                                        checked={isCheckedTerms}
                                        errorState={errors.termsAndConditions} />
                                    <div dangerouslySetInnerHTML={{ __html: t('pages.createAccount.termsAndConditions') }}>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div style={{ marginTop: "5%", width: "100%" }}>
                <BaseCardLayoutActions backButton={false} submitButton={true} onSubmit={handleSubmit(onSubmit)} submitButtonText={t('pricing.accountPage.next_button')}></BaseCardLayoutActions>
            </div>
        </BaseCardLayout>
    );
}
export default CreateAccountPage;