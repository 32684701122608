import { useEffect, useState } from 'react';
import { Loader } from '../loader/loader';
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import UserService from '../../utilities/services/user-service';
import { enqueueSnackbar } from 'notistack';
import { ToggleButton } from '../forms';
import { Button } from '../button/button';
import { useTranslation } from 'react-i18next';

interface AccountProps {
}
const COMPONENT_CLASS = "c-notifications";

const Notifications: React.FC<AccountProps> = (props: AccountProps) => {
  const { t } = useTranslation();
  const { state, setState } = useAuthState();
  const [localNotificationSettings, setLocalNotificationSettings] = useState({
    highRiskUser: false,
    assessments: false,
    streaksToDos: false,
    newAccounts: false,
  })
  const [notificationSettings, setNotificationSettings] = useState({
    inactiveUser: false,
    highRiskUser: false,
    dayStreakMilestone: false,
    newAccount: false,
    completedTasks: false,
    baselineAssessment: false,
  })

  useEffect(() => {
    if (state.user && state.user.notificationSettings) {
      setNotificationSettings({
        inactiveUser: state.user.notificationSettings.inactiveUser ?? false,
        highRiskUser: state.user.notificationSettings.highRiskUser ?? false,
        dayStreakMilestone: state.user.notificationSettings.dayStreakMilestone ?? false,
        newAccount: state.user.notificationSettings.newAccount ?? false,
        completedTasks: state.user.notificationSettings.completedTasks ?? false,
        baselineAssessment: state.user.notificationSettings.baselineAssessment ?? false,
      })

      setLocalNotificationSettings({
        highRiskUser: state.user.notificationSettings.highRiskUser ?? false,
        assessments: state.user.notificationSettings.baselineAssessment ?? false,
        streaksToDos: (state.user.notificationSettings.dayStreakMilestone && state.user.notificationSettings.completedTasks) ?? false,
        newAccounts: (state.user.notificationSettings.newAccount && state.user.notificationSettings.inactiveUser) ?? false,
      })
    }
  }, [state.user]);

  return (
    <div className={COMPONENT_CLASS}>
      <Loader isVisible={false} />
      <div className={`${COMPONENT_CLASS}__container`}>
        <h2>{t('settings.notifications.title')}</h2>
        <div className={`${COMPONENT_CLASS}__info`}>
          <div className={`${COMPONENT_CLASS}__notificationText`}>
            <p className='body-bold' style={{ marginBottom: '0.5rem' }}>{t('settings.notifications.highRisk')}</p>
            <div className='body'>{t('settings.notifications.highRiskDescription')}</div>
          </div>
          <div>
            <ToggleButton
              id='highRiskUserSetting'
              isChecked={localNotificationSettings.highRiskUser}
              onClick={(event) => {
                setLocalNotificationSettings({
                  ...localNotificationSettings, ...{ highRiskUser: !localNotificationSettings.highRiskUser }
                })
                setNotificationSettings({
                  ...notificationSettings, ...{ highRiskUser: !notificationSettings.highRiskUser }
                })
              }} />
          </div>
        </div>
        <div className={`${COMPONENT_CLASS}__info`}>
          <div className={`${COMPONENT_CLASS}__notificationText`}>
            <p className='body-bold' style={{ marginBottom: '0.5rem' }}>{t('settings.notifications.assessments')}</p>
            <div className='body'>{t('settings.notifications.assessmentsDescription')}</div>
          </div>
          <div>
            <ToggleButton
              id='assessmentsSetting'
              isChecked={localNotificationSettings.assessments}
              onClick={(event) => {
                setLocalNotificationSettings({
                  ...localNotificationSettings, ...{ assessments: !localNotificationSettings.assessments }
                })
                setNotificationSettings({
                  ...notificationSettings, ...{ baselineAssessment: !notificationSettings.baselineAssessment }
                })
              }} />
          </div>
        </div>
        <div className={`${COMPONENT_CLASS}__info`}>
          <div className={`${COMPONENT_CLASS}__notificationText`}>
            <p className='body-bold' style={{ marginBottom: '0.5rem' }}>{t('settings.notifications.streaks')}</p>
            <div className='body'>{t('settings.notifications.streaksDescription')}</div>
          </div>
          <div>
            <ToggleButton
              id='streaksToDosSettings'
              isChecked={localNotificationSettings.streaksToDos}
              onClick={(event) => {
                setLocalNotificationSettings({
                  ...localNotificationSettings, ...{ streaksToDos: !localNotificationSettings.streaksToDos }
                })
                setNotificationSettings({
                  ...notificationSettings, ...{ dayStreakMilestone: !notificationSettings.dayStreakMilestone, completedTasks: !notificationSettings.completedTasks }
                })
              }} />
          </div>
        </div>
        <div className={`${COMPONENT_CLASS}__info`}>
          <div className={`${COMPONENT_CLASS}__notificationText`}>
            <p className='body-bold' style={{ marginBottom: '0.5rem' }}>{t('settings.notifications.newAccounts')}</p>
            <div className='body'>{t('settings.notifications.newAccountsDescription')}</div>
          </div>
          <div>
            <ToggleButton
              id='newAccountsSettings'
              isChecked={localNotificationSettings.newAccounts}
              onClick={(event) => {
                setLocalNotificationSettings({
                  ...localNotificationSettings, ...{ newAccounts: !localNotificationSettings.newAccounts }
                })
                setNotificationSettings({
                  ...notificationSettings, ...{ newAccount: !notificationSettings.newAccount, inactiveUser: !notificationSettings.inactiveUser }
                })
              }} />
          </div>
        </div>
        <div className={'c-login-modal__button-area button-gap'}>
          <Button
            type='link'
            buttonText={t('buttons.btn_cancel')}
            onClick={() => {
              setNotificationSettings({
                inactiveUser: state.user?.notificationSettings?.inactiveUser ?? false,
                highRiskUser: state.user?.notificationSettings?.highRiskUser ?? false,
                dayStreakMilestone: state.user?.notificationSettings?.dayStreakMilestone ?? false,
                newAccount: state.user?.notificationSettings?.newAccount ?? false,
                completedTasks: state.user?.notificationSettings?.completedTasks ?? false,
                baselineAssessment: state.user?.notificationSettings?.baselineAssessment ?? false,
              }); setLocalNotificationSettings({
                highRiskUser: state.user?.notificationSettings?.highRiskUser ?? false,
                assessments: state.user?.notificationSettings?.baselineAssessment ?? false,
                streaksToDos: (state.user?.notificationSettings?.dayStreakMilestone && state.user?.notificationSettings?.completedTasks) ?? false,
                newAccounts: (state.user?.notificationSettings?.newAccount && state.user?.notificationSettings?.inactiveUser) ?? false,
              })
            }} />
          <Button
            buttonStyle='primary'
            buttonText={t('buttons.btn_saveChanges')}
            onClick={async () => {
              if (state.user && state.user.id) {
                const user = await UserService.get(state.user.id);
                if (user && state.user) {
                  user.notificationSettings = notificationSettings;
                  await UserService.update(user, state.user).then((result: any) => {
                    const stateOrg = state.user;
                    stateOrg!.notificationSettings = notificationSettings;
                    setState((state) => ({
                      ...state, ...{ user: stateOrg }
                    }));
                    enqueueSnackbar(t('settings.infoUpdated'), { variant: "toast", width: "450px", autoHideDuration: 5000 });
                  });
                }

              }
            }} />
        </div>
      </div>
    </div >
  );
}
export default Notifications;
