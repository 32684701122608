/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import MskScoreService from "../../utilities/services/msk-score-service";
import moment from "moment";
import { UserProfileAssessment } from "../../models/interfaces/user-profile-asssessment";
import UserProfileAssessmentHeader from "./user-profile-assessment-header";
import LineChartJS from "../charts/line-chart-js";
import { useTranslation } from "react-i18next";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileBehavioralProps {
    mskScore?: MskScore;
    user: User;
    assessment?: UserProfileAssessment;
    // assessmentResponses: AssessmentResponse[];
    allMskScores: MskScore[];
    setAllMskScores: Function;
    setSpecificAssessment: Function;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileBehavioral: React.FC<UserProfileBehavioralProps> = (props: UserProfileBehavioralProps) => {
    const { setSpecificAssessment, mskScore, assessment, allMskScores, setAllMskScores, user } = props;
    const [behavioralHistory, setBehavioralHistory] = useState<{ x: string, y: number }[]>();
    const { t } = useTranslation();

    useEffect(() => {
        const getAllMskScores = async () => {
            if (!allMskScores) {
                const mskScores = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }])

                setAllMskScores(mskScores);
            }
        }
        getAllMskScores();
    }, [])
    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempBehavioralHistory = []

                for (const mskScore of allMskScores) {
                    tempBehavioralHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.lifestyleScore?.behavioralScore?.percentage ?? 0
                    })
                }
                setBehavioralHistory(tempBehavioralHistory);
            }
        }
        getHistory();
    }, [allMskScores])
    return (
        <>
            <div>
                <UserProfileAssessmentHeader
                    assessment={assessment}
                    mskScore={mskScore}
                    setSpecificAssessment={setSpecificAssessment}
                    mainTextHeader={t('userProfile.assessments.behavioralHeader')}
                    mainText={t('userProfile.assessments.behavioralText')}
                    whyImportantText={t('userProfile.assessments.behavioralWhy')}
                />
            </div>

            {behavioralHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper mt-5`}>
                    <LineChartJS
                        data={behavioralHistory.map(history => history.y)}
                        title={t('userProfile.assessments.behavioralHistory')}
                        labels={behavioralHistory.map(history => history.x)}
                        height={"200px"}
                        horizontalScrolling={true}
                        pointRadius={7}
                        pointBorderWidth={3}
                        borderWidth={5}
                        pointFontSize={16}
                        fontFamily={'pragmatica-extended'}
                        pointLabelOffset={0}
                        xLabelFontSize={14}
                        paddingTop={30}
                        paddingBottom={20}
                        maintainAspectRatio={false}
                    />
                </div>
            )}
        </>
    );
}

export default UserProfileBehavioral;
