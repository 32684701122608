const COMPONENT_CLASS = "c-assessment-title";

export interface AssessmentTitleProps {
    title?: string;
    subtitle?: string;
}

const AssessmentTitle: React.FC<AssessmentTitleProps> = (
    props: AssessmentTitleProps
) => {

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <div className={`${COMPONENT_CLASS}__title`}>{props.title}</div>
            {
                // if
                props.subtitle != null && (
                    <div className={`${COMPONENT_CLASS}__sub`}>
                        {props.subtitle}
                    </div>
                )
            }
        </div>
    );
};

export default AssessmentTitle;
