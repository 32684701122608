import { ReactComponent as CheckIcon } from "../../assets/icons/solid/check-circle.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/solid/cross-circle.svg";
import { UserLimit } from "../../models/enumerations/user-limit";
import { LicenseLimit } from "../../models/enumerations/license-limit";
import { useTranslation } from 'react-i18next';
interface PlansTableContentProps {
    carouselItemIndex: number;
    addOnPrices?: { user?: string, license?: string, organization?: string };
    isMobile?: boolean;
}

const PlansTableContent: React.FC<PlansTableContentProps> = ({ carouselItemIndex, isMobile, addOnPrices }) => {
    const COMPONENT_CLASS = "c-subscriptions";
    const { t } = useTranslation();
    enum SubscriptionCarouselIndex {
        Starter = 0,
        Business = 1,
        Enterprise = 2,
    }

    const basicsInfo = [
        { name: t('general.users'), tooltip: t('pricing.plansPage.benefits.basics.users_desc'), starter: UserLimit.Starter, business: UserLimit.Business, enterprise: t('pricing.general.custom') },

        { name: t('pricing.plansPage.benefits.basics.wellnessPrograms'), tooltip: t('pricing.plansPage.benefits.basics.wellnessPrograms_desc'), starter: LicenseLimit.Starter, business: LicenseLimit.Business, enterprise: t('pricing.general.custom') },

        { name: t('pricing.plansPage.benefits.basics.marketing'), tooltip: t('pricing.plansPage.benefits.basics.marketing_desc'), starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: t('pricing.plansPage.benefits.basics.subOrgAcc'), tooltip: t('pricing.plansPage.benefits.basics.subOrgAcc_desc'), starter: <CrossIcon />, business: t('pricing.general.comingSoon'), enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: t('pricing.plansPage.benefits.basics.prioritySupport'), tooltip: t('pricing.plansPage.benefits.basics.prioritySupport_desc'), starter: <CrossIcon />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: t('pricing.plansPage.benefits.basics.dedicatedCSM'), tooltip: t('pricing.plansPage.benefits.basics.dedicatedCSM_desc'), starter: <CrossIcon />, business: <CrossIcon />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },
    ];

    const adminFeaturesInfo = [
        { name: t('pricing.plansPage.benefits.adminFeatures.wn_management'), tooltip: t('pricing.plansPage.benefits.adminFeatures.wn_management_desc'), starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: t('pricing.general.wn_funcAssessment'), tooltip: t('pricing.plansPage.benefits.adminFeatures.admin_funcAssessment_desc'), starter: t('pricing.general.unlimited'), business: t('pricing.general.unlimited'), enterprise: t('pricing.general.unlimited') },

        { name: t('pricing.plansPage.benefits.adminFeatures.prebuiltDash'), tooltip: t('pricing.plansPage.benefits.adminFeatures.prebuiltDash_desc'), starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: t('pricing.plansPage.benefits.adminFeatures.structureManagement'), tooltip: t('pricing.plansPage.benefits.adminFeatures.structureManagement_desc'), starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: t('pricing.plansPage.benefits.adminFeatures.userProfilesManagement'), tooltip: t('pricing.plansPage.benefits.adminFeatures.userProfilesManagement_desc'), starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: t('pricing.plansPage.benefits.adminFeatures.groupsTags'), tooltip: t('pricing.plansPage.benefits.adminFeatures.groupsTags_desc'), starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: t('pricing.plansPage.benefits.adminFeatures.reporting'), tooltip: t('pricing.plansPage.benefits.adminFeatures.reporting_desc'), starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: t('pricing.plansPage.benefits.adminFeatures.csvExports'), tooltip: t('pricing.plansPage.benefits.adminFeatures.csvExports_desc'), starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: t('pricing.plansPage.benefits.adminFeatures.inviteLinks'), tooltip: t('pricing.plansPage.benefits.adminFeatures.inviteLinks_desc'), starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },
    ];

    const userFeaturesInfo = [
        { name: t('pricing.general.wn_funcAssessment'), tooltip: t('pricing.plansPage.benefits.userFeatures.user_funcAssessment_desc'), starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: t('pricing.plansPage.benefits.userFeatures.wellnessScore'), tooltip: t('pricing.plansPage.benefits.userFeatures.wellnessScore_desc'), starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: t('pricing.plansPage.benefits.userFeatures.healthReports'), tooltip: t('pricing.plansPage.benefits.userFeatures.healthReports_desc'), starter: <CheckIcon style={{ fill: "#FFE9CC" }} />, business: <CheckIcon style={{ fill: "#8BE3CE" }} />, enterprise: <CheckIcon style={{ fill: "#ADDFFD" }} /> },

        { name: t('pricing.plansPage.benefits.userFeatures.symmioApp'), tooltip: t('pricing.plansPage.benefits.userFeatures.symmioApp_desc'), starter: t('pricing.general.includedWellness'), business: t('pricing.general.includedWellness'), enterprise: t('pricing.general.includedWellness') },

        { name: t('pricing.plansPage.benefits.userFeatures.personalPlans'), tooltip: t('pricing.plansPage.benefits.userFeatures.personalPlans_desc'), starter: t('pricing.general.includedWellness'), business: t('pricing.general.includedWellness'), enterprise: t('pricing.general.includedWellness') },

        { name: t('pricing.plansPage.benefits.userFeatures.movementCorrExercise'), tooltip: t('pricing.plansPage.benefits.userFeatures.movementCorrExercise_desc'), starter: t('pricing.general.includedWellness'), business: t('pricing.general.includedWellness'), enterprise: t('pricing.general.includedWellness') },

        { name: t('pricing.plansPage.benefits.userFeatures.articlesVideos'), tooltip: t('pricing.plansPage.benefits.userFeatures.articlesVideos_desc'), starter: t('pricing.general.includedWellness'), business: t('pricing.general.includedWellness'), enterprise: t('pricing.general.includedWellness') },
    ];

    const addOnsInfo = [
        { name: t('pricing.plansPage.benefits.addOns.addUsers'), tooltip: t('pricing.plansPage.benefits.addOns.addUsers_desc'), starter: <CrossIcon />, business: `${t('pricing.general.startingAt', { price: addOnPrices?.user || "..." })}`, enterprise: t('pricing.general.customPricing') },

        { name: t('pricing.plansPage.benefits.addOns.addPrograms'), tooltip: t('pricing.plansPage.benefits.addOns.addPrograms_desc'), starter: <CrossIcon />, business: `${t('pricing.general.startingAt', { price: addOnPrices?.license || "..." })}`, enterprise: t('pricing.general.customPricing') },

        { name: t('pricing.plansPage.benefits.addOns.addSubOrgs'), tooltip: t('pricing.plansPage.benefits.addOns.addSubOrgs_desc'), starter: <CrossIcon />, business: t('pricing.general.comingSoon'), enterprise: t('pricing.general.customPricing') },
    ];

    return (
        <>
            <table className={`${COMPONENT_CLASS}__plans-table`}>
                <thead>
                    <tr>
                        <th className="py-2" style={{ textAlign: 'left', paddingLeft: '2rem' }}>
                            <span>{t('pricing.plansPage.benefits.basics.title')}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {basicsInfo.map((h: any, i: number) => (
                        <tr
                            className={`${COMPONENT_CLASS}__item `}
                            key={`user-list-${i}`}
                        >
                            <td style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '1rem', paddingBottom: '1rem' }}>
                                <div style={{ width: '200px', paddingLeft: '1rem' }}>
                                    <div className="tooltip">
                                        <span className="tooltiptext">{h.tooltip}</span>
                                        {h.name}
                                    </div>
                                </div>

                                {isMobile
                                    ?
                                    <div className={`${COMPONENT_CLASS}__row `}>
                                        {carouselItemIndex === SubscriptionCarouselIndex.Starter &&
                                            h.starter
                                        }
                                        {carouselItemIndex === SubscriptionCarouselIndex.Business && (
                                            h.business
                                        )}
                                        {carouselItemIndex === SubscriptionCarouselIndex.Enterprise &&
                                            h.enterprise
                                        }
                                    </div>
                                    :
                                    <>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.starter}
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.business}
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.enterprise}
                                        </div>
                                    </>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <table className={`${COMPONENT_CLASS}__plans-table`}>
                <thead>
                    <tr>
                        <th className="py-2" style={{ textAlign: 'left', paddingLeft: '2rem' }}>
                            <span>{t('pricing.plansPage.benefits.addOns.title')}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {addOnsInfo.map((h: any, i: number) => (
                        <tr
                            className={`${COMPONENT_CLASS}__item `}
                            key={`user-list-${i}`}
                        >
                            <td style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '1rem', paddingBottom: '1rem' }}>
                                <div style={{ width: '200px', paddingLeft: '1rem' }}>
                                    <div className="tooltip">
                                        <span className="tooltiptext">{h.tooltip}</span>
                                        {h.name}
                                    </div>
                                </div>

                                {isMobile
                                    ?
                                    <div className={`${COMPONENT_CLASS}__row `}>
                                        {carouselItemIndex === SubscriptionCarouselIndex.Starter &&
                                            h.starter
                                        }
                                        {carouselItemIndex === SubscriptionCarouselIndex.Business && (
                                            h.business
                                        )}
                                        {carouselItemIndex === SubscriptionCarouselIndex.Enterprise &&
                                            h.enterprise
                                        }
                                    </div>
                                    :
                                    <>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.starter}
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.business}
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.enterprise}
                                        </div>
                                    </>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <table className={`${COMPONENT_CLASS}__plans-table`}>
                <thead>
                    <tr>
                        <th className="py-2" style={{ textAlign: 'left', paddingLeft: '2rem' }}>
                            <span>{t('pricing.plansPage.benefits.adminFeatures.title')}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {adminFeaturesInfo.map((h: any, i: number) => (
                        <tr
                            className={`${COMPONENT_CLASS}__item `}
                            key={`user-list-${i}`}
                        >
                            <td style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '1rem', paddingBottom: '1rem' }}>
                                <div style={{ width: '200px', paddingLeft: '1rem' }}>
                                    <div className="tooltip">
                                        <span className="tooltiptext">{h.tooltip}</span>
                                        {h.name}
                                    </div>
                                </div>

                                {isMobile
                                    ?
                                    <div className={`${COMPONENT_CLASS}__row `}>
                                        {carouselItemIndex === SubscriptionCarouselIndex.Starter &&
                                            h.starter
                                        }
                                        {carouselItemIndex === SubscriptionCarouselIndex.Business && (
                                            h.business
                                        )}
                                        {carouselItemIndex === SubscriptionCarouselIndex.Enterprise &&
                                            h.enterprise
                                        }
                                    </div>
                                    :
                                    <>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.starter}
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.business}
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.enterprise}
                                        </div>
                                    </>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <table className={`${COMPONENT_CLASS}__plans-table`}>
                <thead>
                    <tr>
                        <th className="py-2" style={{ textAlign: 'left', paddingLeft: '2rem' }}>
                            <span>{t('pricing.plansPage.benefits.userFeatures.title')}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {userFeaturesInfo.map((h: any, i: number) => (
                        <tr
                            className={`${COMPONENT_CLASS}__item `}
                            key={`user-list-${i}`}
                        >
                            <td style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '1rem', paddingBottom: '1rem' }}>
                                <div style={{ width: '200px', paddingLeft: '1rem' }}>
                                    <div className="tooltip">
                                        <span className="tooltiptext">{h.tooltip}</span>
                                        {h.name}
                                    </div>
                                </div>

                                {isMobile
                                    ?
                                    <div className={`${COMPONENT_CLASS}__row `}>
                                        {carouselItemIndex === SubscriptionCarouselIndex.Starter &&
                                            h.starter
                                        }
                                        {carouselItemIndex === SubscriptionCarouselIndex.Business && (
                                            h.business
                                        )}
                                        {carouselItemIndex === SubscriptionCarouselIndex.Enterprise &&
                                            h.enterprise
                                        }
                                    </div>
                                    :
                                    <>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.starter}
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.business}
                                        </div>
                                        <div className={`${COMPONENT_CLASS}__row `}>
                                            {h.enterprise}
                                        </div>
                                    </>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

export default PlansTableContent;