import React from 'react';
import logo from "../../assets/images/symmio-logos/symmio-logo-horizontal-dark.png";
import BaseCardLayout from '../../components/base-card-layout/base-card-layout';
import BaseCardLayoutActions from '../../components/base-card-layout/base-card-layout-actions';
import { useTranslation } from 'react-i18next';


const COMPONENT_CLASS = "p-create-account-expiration";

const CreateAccountExpirationPage: React.FC = () => {

    const onClick = () => {
        // TODO -> Where does this take you?
    }
    const { t } = useTranslation();

    return (
        <BaseCardLayout>
            <div className="flex justify-center">
                <div className={`${COMPONENT_CLASS}`}>
                    <img src={logo} alt="Logo" />
                    <h4 className={`${COMPONENT_CLASS}__header`}>{t('pages.createAccount.invitationExpired')}</h4>
                    <div className={`${COMPONENT_CLASS}__informative`}>
                        <p dangerouslySetInnerHTML={{ __html: t('pages.createAccount.invitationExpired_desc') }}></p>
                    </div>
                </div>
            </div>
            <BaseCardLayoutActions backButton={false} submitButton={true} onSubmit={onClick} submitButtonText={t('buttons.btn_symmioHome')}></BaseCardLayoutActions>
        </BaseCardLayout>
    );
}
export default CreateAccountExpirationPage;