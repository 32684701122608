import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import moment from "moment";
import { MovementHealthResponse } from "../../models/interfaces/reports/movement-health-response";
import NonLinearLineChart from "../charts/non-linear-line-chart";
import { Utils } from "../../utilities/utils";
import { useTranslation } from "react-i18next";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileToeTouchProps {
    mskScore?: MskScore;
    validMskScore: boolean;
    user: User;
    allMskScores: MskScore[];
    setAllMskScores: Function;
    movementResponses?: MovementHealthResponse;

}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileToeTouch: React.FC<UserProfileToeTouchProps> = (props: UserProfileToeTouchProps) => {
    const { mskScore, allMskScores, movementResponses, validMskScore } = props;
    const [toeTouchHistory, setToeTouchHistory] = useState<{ x: string, y: number }[]>();
    const { t } = useTranslation();

    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempToeTouchHistory = []

                for (const mskScore of allMskScores) {
                    tempToeTouchHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.movementScore?.toeTouchScore.percentage ?? 0
                    })
                }

                setToeTouchHistory(tempToeTouchHistory);
            }
        }
        getHistory();
    }, [allMskScores])

    return (
        <div className="flex gap-5">
            <div className={`${COMPONENT_CLASS}__score-section-card gap-4 items-center`}>
                <div className="text-base font-normal">{t('movementFocus.toeTouch')}</div>

                <div className={`${COMPONENT_CLASS}__movement-group-score-card ${Utils.getGreenOrRedColor(mskScore?.movementScore?.toeTouchScore.percentage, 75)}`}>
                    <div className={`${COMPONENT_CLASS}__movement-group-score-card__main-text`}>{mskScore?.movementScore?.toeTouchScore.percentage}</div>
                    <div className={`${COMPONENT_CLASS}__movement-group-score-card__sub-text`}>{t('userProfile.assessments.goal75')}</div>
                </div>
                {
                    validMskScore ?
                        <>
                            <div className="flex gap-4">
                                <div className="flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                    <span className="text-10 font-bold">{t('reports.wellness.leftSide')}</span>
                                    {Utils.getAssessmentBreakdownCell(t('reports.wellness.frontToe'), mskScore?.movementScore?.toeTouchScore.frontToeLeft)}
                                    {Utils.getAssessmentBreakdownCell(t('reports.wellness.backToe'), mskScore?.movementScore?.toeTouchScore.backToeLeft)}
                                </div>
                                <div className="flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                    <span className="text-10 font-bold">{t('reports.wellness.rightSide')}</span>
                                    {Utils.getAssessmentBreakdownCell(t('reports.wellness.frontToe'), mskScore?.movementScore?.toeTouchScore.frontToeRight)}
                                    {Utils.getAssessmentBreakdownCell(t('reports.wellness.backToe'), mskScore?.movementScore?.toeTouchScore.backToeRight)}
                                </div>
                            </div>
                            <div>
                                {Utils.getPainBadge(mskScore?.movementScore?.toeTouchScore.painSeverity!)}
                            </div>
                        </>
                        : movementResponses ?
                            <>
                                <div className="flex gap-4">
                                    <div className="flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                        <span className="text-10 font-bold">{t('reports.wellness.leftSide')}</span>
                                        {Utils.getAssessmentBreakdownCell(t('reports.wellness.frontToe'), movementResponses.toeTouchReachLeft.itemOne)}
                                        {Utils.getAssessmentBreakdownCell(t('reports.wellness.backToe'), movementResponses.toeTouchReachLeft.itemTwo)}
                                    </div>
                                    <div className="flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                        <span className="text-10 font-bold">{t('reports.wellness.rightSide')}</span>
                                        {Utils.getAssessmentBreakdownCell(t('reports.wellness.frontToe'), movementResponses.toeTouchReachRight.itemOne)}
                                        {Utils.getAssessmentBreakdownCell(t('reports.wellness.backToe'), movementResponses.toeTouchReachRight.itemTwo)}
                                    </div>
                                </div>
                                <div>
                                    {Utils.getPainBadge(movementResponses.toeTouchQPain)}
                                </div>
                            </>
                            :
                            <div></div>
                }

            </div>
            {toeTouchHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                    <NonLinearLineChart
                        dataPoints={toeTouchHistory.map(history => history.y)}
                        labels={toeTouchHistory.map(history => history.x)}
                        title={t('userProfile.assessments.toeTouchHistory')}
                        sectionDetails={{
                            sections: 2,
                            rangeOne: { start: 0, end: 74 },
                            rangeTwo: { start: 75, end: 100 },
                            backgroundColorOrder: { first: "#FFA998", second: "#8BE3CE" },
                            dataPointColorOrder: { first: "#E7514F", second: "#00DFBC" },
                            yAxisLabels: { first: t('userProfile.assessments.below'), second: t('userProfile.assessments.above') },
                            decimals: false
                        }}
                        toolTipLabel={true} />
                </div>
            )}
        </div>
    );
}

export default UserProfileToeTouch;
