import React, { useEffect, useState } from "react";
import { Button } from "../../components/button/button";
import RichTextEditor from "../../components/rich-text-editor/rich-text-editor";
import { Group } from "../../models/interfaces/group";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import GroupService from "../../utilities/services/group-service";
import { enqueueSnackbar } from "notistack";
import { AppContentType } from "../../models/enumerations/app-content-type"
import { AppContent } from "../../models/interfaces/app-content";
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "p-group-edit";

export interface CustomizationTabProps {
    group: Group;
    setGroup: React.Dispatch<React.SetStateAction<Group>>;
    initialGroup: Group;
    isEditingContact: boolean;
    setIsEditingContact: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomizationTab: React.FunctionComponent<CustomizationTabProps> = (props) => {
    const {
        group,
        setGroup,
        initialGroup,
        isEditingContact,
        setIsEditingContact
    } = props;

    const { t } = useTranslation();

    const { state } = useAuthState();

    const [appContentType, setAppContentType] = useState<AppContentType>(AppContentType.PAIN);

    const [appContentPainContent, setAppContentPainContent] = useState<string>("");
    const [appContentPainVideoUrl, setAppContentPainVideoUrl] = useState<string>("");
    const [appContentPainHeaderText, setAppContentPainHeaderText] = useState<string>("");
    const [appContentPainVideoSource, setAppContentPainVideoSource] = useState<string>("");
    const [appContentPainButtonText, setAppContentPainButtonText] = useState<string>("");
    const [appContentPainButtonLink, setAppContentPainButtonLink] = useState<string>("");

    const [appContentBehavioralContent, setAppContentBehavioralContent] = useState<string>("");
    const [appContentBehavioralVideoUrl, setAppContentBehavioralVideoUrl] = useState<string>("");
    const [appContentBehavioralHeaderText, setAppContentBehavioralHeaderText] = useState<string>("");
    const [appContentBehavioralVideoSource, setAppContentBehavioralVideoSource] = useState<string>("");
    const [appContentBehavioralButtonText, setAppContentBehavioralButtonText] = useState<string>("");
    const [appContentBehavioralButtonLink, setAppContentBehavioralButtonLink] = useState<string>("");

    const setDefaultAppContent = () => {
        const appContentPain = group?.appContent ? group?.appContent[AppContentType.PAIN] : undefined;
        const appContentBehavioral = group?.appContent ? group?.appContent[AppContentType.BEHAVIORAL_HEALTH] : undefined;

        setAppContentPainContent(appContentPain ? appContentPain.content : "");
        setAppContentPainVideoUrl(appContentPain ? appContentPain.videoUrl : "");
        setAppContentPainHeaderText(appContentPain ? appContentPain.headerText : "");
        setAppContentPainVideoSource(appContentPain ? appContentPain.videoSource : "");
        setAppContentPainButtonText(appContentPain ? appContentPain.buttonText : "");
        setAppContentPainButtonLink(appContentPain ? appContentPain.buttonLink : "");

        setAppContentBehavioralContent(appContentBehavioral ? appContentBehavioral.content : "");
        setAppContentBehavioralVideoUrl(appContentBehavioral ? appContentBehavioral.videoUrl : "");
        setAppContentBehavioralHeaderText(appContentBehavioral ? appContentBehavioral.headerText : "");
        setAppContentBehavioralVideoSource(appContentBehavioral ? appContentBehavioral.videoSource : "");
        setAppContentBehavioralButtonText(appContentBehavioral ? appContentBehavioral.buttonText : "");
        setAppContentBehavioralButtonLink(appContentBehavioral ? appContentBehavioral.buttonLink : "");
    };

    useEffect(() => {
        setDefaultAppContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group]);

    const handleSubmitRecommendations = async (event: React.FormEvent) => {
        event.preventDefault();

        const updatedGroup = await GroupService.save({
            ...initialGroup,
            appContent: {
                [AppContentType.PAIN]: {
                    content: appContentPainContent,
                    videoUrl: appContentPainVideoUrl,
                    videoSource: appContentPainVideoSource,
                    headerText: appContentPainHeaderText,
                    buttonText: appContentPainButtonText,
                    buttonLink: appContentPainButtonLink,
                } as AppContent,
                [AppContentType.BEHAVIORAL_HEALTH]: {
                    content: appContentBehavioralContent,
                    videoUrl: appContentBehavioralVideoUrl,
                    videoSource: appContentBehavioralVideoSource,
                    headerText: appContentBehavioralHeaderText,
                    buttonText: appContentBehavioralButtonText,
                    buttonLink: appContentBehavioralButtonLink,
                } as AppContent,
            }
        }, state.user);

        setGroup({
            ...group,
            appContent: updatedGroup.appContent,
        })
        enqueueSnackbar(t('groups.edit.highRiskUpdated'), { variant: "toast", width: "450px" });
        setIsEditingContact(false);
    };

    return (
        <>
            <h6>{t('groups.edit.customization_tab.title')}</h6>
            <div className={`${COMPONENT_CLASS}__secondary`}>
                <div className={`${COMPONENT_CLASS}__disclaimer`}>
                    <p>
                        {t('groups.edit.customization_tab.desc')}
                    </p>
                </div>
            </div>
            <div>
                <RichTextEditor
                    appContentType={appContentType}
                    setAppContentType={setAppContentType}

                    appContentPainContent={appContentPainContent}
                    appContentPainVideoUrl={appContentPainVideoUrl}
                    appContentPainHeaderText={appContentPainHeaderText}
                    appContentPainVideoSource={appContentPainVideoSource}
                    appContentPainButtonText={appContentPainButtonText}
                    appContentPainButtonLink={appContentPainButtonLink}

                    appContentBehavioralContent={appContentBehavioralContent}
                    appContentBehavioralVideoUrl={appContentBehavioralVideoUrl}
                    appContentBehavioralHeaderText={appContentBehavioralHeaderText}
                    appContentBehavioralVideoSource={appContentBehavioralVideoSource}
                    appContentBehavioralButtonText={appContentBehavioralButtonText}
                    appContentBehavioralButtonLink={appContentBehavioralButtonLink}

                    setAppContentPainContent={setAppContentPainContent}
                    setAppContentPainVideoUrl={setAppContentPainVideoUrl}
                    setAppContentPainHeaderText={setAppContentPainHeaderText}
                    setAppContentPainVideoSource={setAppContentPainVideoSource}
                    setAppContentPainButtonText={setAppContentPainButtonText}
                    setAppContentPainButtonLink={setAppContentPainButtonLink}

                    setAppContentBehavioralContent={setAppContentBehavioralContent}
                    setAppContentBehavioralVideoUrl={setAppContentBehavioralVideoUrl}
                    setAppContentBehavioralHeaderText={setAppContentBehavioralHeaderText}
                    setAppContentBehavioralVideoSource={setAppContentBehavioralVideoSource}
                    setAppContentBehavioralButtonText={setAppContentBehavioralButtonText}
                    setAppContentBehavioralButtonLink={setAppContentBehavioralButtonLink}

                    includeTextAlignment={true}
                    showEdit={true}
                    showPreview={false}
                    isEditingContact={isEditingContact}
                    setIsEditingContact={setIsEditingContact}
                />
            </div>
            {
                isEditingContact &&
                <div className={`${COMPONENT_CLASS}__actions`}>
                    <Button
                        type="default"
                        buttonStyle="white"
                        buttonText={t('buttons.btn_cancel')}
                        onClick={(event) => {
                            event.preventDefault();
                            setIsEditingContact(false);
                            setDefaultAppContent();
                        }} />
                    <Button
                        type="default"
                        buttonText={t('buttons.btn_saveRecs')}
                        onClick={(event) => handleSubmitRecommendations(event)} />
                </div>
            }
        </>
    );
}

export default CustomizationTab;
