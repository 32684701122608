import { useEffect, useState } from "react";
import { Deeplink } from "../../models/interfaces/deeplink";
import DeeplinksSearchHits from './deeplinks-search-hits';
import { Modal } from "../modal/modal";
import { useDeeplinks } from "../../utilities/hooks/use-deeplinks";
import { useHistory } from "react-router";
import "./deeplinks-list.styles.scss";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from 'react-i18next';

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const DeeplinksList: React.FC = () => {
    const [openDelete, setOpenDelete] = useState(false);
    const {
        fetchDeeplinks,
        deeplinks,
        deeplinksIsLoaded,
        deleteDeeplink } = useDeeplinks();
    const [linkToDelete, setLinkToDelete] = useState<Deeplink>();
    const history = useHistory();
    const { t } = useTranslation();

    const handleOpenDelete = (deeplink: Deeplink) => {
        setLinkToDelete(deeplink);
        setOpenDelete(true);
    }

    const handleDelete = async () => {
        try {
            if (!linkToDelete) {
                throw new Error('linkToDelete is not set');
            }
            await deleteDeeplink(linkToDelete);
            setOpenDelete(false);
            enqueueSnackbar(t('deeplinks.link_del_confirm'), { variant: "toast", width: "450px" });
        } catch (err) {
            setOpenDelete(false);
            console.error(err);
            // TODO : Global exception handling
            // https://app.clickup.com/t/2219993/FMS-1236
        }
    }

    useEffect(() => {
        const loadLinks = async () => {
            await fetchDeeplinks();
        }

        loadLinks();
    }, [fetchDeeplinks, deeplinks]);

    return (
        <>
            <div className={`list__table-container`}>
                <table
                    role={"table"}
                    className={"dark"}
                    data-testid="deeplinks-list">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('deeplinks.list.linkName')}</th>
                            <th>{t('deeplinks.list.linkType')}</th>
                            <th>{t('deeplinks.list.group')}</th>
                            <th>{t('deeplinks.list.tags')}</th>
                            <th>{t('deeplinks.list.usedLimit')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        { // if
                            deeplinksIsLoaded &&
                            <DeeplinksSearchHits
                                deeplinks={deeplinks}
                                editClickHandler={(deeplink: Deeplink) => {
                                    history.push(`invitelink/${deeplink.id}`)
                                }}
                                deleteClickHandler={(deeplink) => {
                                    handleOpenDelete(deeplink);
                                }} />
                        }
                    </tbody>
                </table>
            </div>
            { // if
                !deeplinksIsLoaded &&

                <div className={`${'loading-spinner'} ${''} ${true ? '-active' : ''}`}>
                    <div className={`${'loading-spinner'}__content`}>
                    </div>
                    <div className={'loading-title'}>{t('deeplinks.loading')}</div>
                </div>

            }
            <Modal
                isOpen={openDelete}
                isLoading={false}
                onClose={setOpenDelete}
                title={t('deeplinks.delete_modal.title')}
                defaultModalActions={true}
                onSubmit={handleDelete}
                onCancel={() => setOpenDelete(false)}
                submitButtonText={t('buttons.btn_deleteLink')}
            // submitDisabled={linkToDelete?.used !== undefined && linkToDelete.used > 0}
            >
                {/* {linkToDelete?.used !== undefined && linkToDelete.used > 0 &&
                    <div>
                        <p>{t('deeplinks.delete_modal.err_usedLink')}</p>
                    </div>
                } */}
                {/* {(linkToDelete?.used === undefined || linkToDelete.used === 0) && */}
                <div>
                    <div>
                        <p>
                            {t('deeplinks.delete_modal.del_confirm')}
                        </p>
                    </div>
                </div>
                {/* } */}
            </Modal>
        </>
    );
}

export default DeeplinksList;