import React from "react";
import { Dialog } from "@headlessui/react";
import { QuestionInformation } from "../../models/interfaces/questions/question-information";
import FirebaseStorage from "../../utilities/firebase-storage";
import { Button } from "../button/button";
import { useTranslation } from "react-i18next";

export interface AssessmentHelpModalProps {
    onClose: Function,
    open: boolean,
    questionInfo?: QuestionInformation,

}

const COMPONENT_CLASS = 'c-assessment-help-modal';

/**
 * Represents the modal that is shown when a Inactivity error occurs.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const AssessmentHelpModal: React.FC<AssessmentHelpModalProps> = (props) => {
    const {
        onClose,
        open,
    } = props;
    const { t } = useTranslation();

    const storageUtil = new FirebaseStorage();

    const handleClose = () => {
        onClose(false);
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={COMPONENT_CLASS}
        >
            <div className={`fixed inset-0 flex items-center justify-center p-4 ${COMPONENT_CLASS}__container`}>
                <div className={`${COMPONENT_CLASS}__backdrop`} />
                <div className={`${COMPONENT_CLASS}__overlay`} >
                    <Dialog.Panel className="rounded bg-white" style={{
                        width: '978px', borderRadius: '30px', height: '450px',
                        marginBottom: '160px'
                    }}>
                        <div className={`flex-block justify-center ${COMPONENT_CLASS}__modal`} >
                            {
                                //if
                                props.questionInfo?.imageStoragePath != null && (
                                    <img
                                        className={`${COMPONENT_CLASS}__image`}
                                        src={`${storageUtil.getMediaUrl(
                                            props.questionInfo.imageStoragePath
                                        )}`}
                                        alt={props.questionInfo?.title}
                                    />
                                )
                            }
                            <div className={`${COMPONENT_CLASS}__content`}>
                                <h4>
                                    {t(props.questionInfo?.subtitle)}
                                </h4>
                                <p>{t(props.questionInfo?.content)}</p>
                                <Button
                                    buttonText={t('buttons.btn_gotIt')}
                                    onClick={handleClose}
                                    buttonStyle="white"
                                    inputClassName={`${COMPONENT_CLASS}__button`}
                                />
                            </div>
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
};

export default AssessmentHelpModal;