import React from "react";
import { LoginError } from "../../../models/enumerations/login-error";
import { Dialog } from "@headlessui/react";
import logo from "../../../assets/images/symmio-logos/symmio-icon-logo-light.png";
import RequestModalMessage from "./request-modal-message";
import RequestModalTitle from "./request-modal-title";
import { Button } from "../../button/button";
import { useTranslation } from 'react-i18next';

export interface RequestModalProps {
    error: LoginError,
    onClose: Function,
    open: boolean,
    newRequest: boolean,
}

const COMPONENT_CLASS = 'c-login-modal';

/**
 * Represents the modal that is shown when a request for account reactivation /
 * trial upgrade occurs.
 *
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const RequestModal: React.FC<RequestModalProps> = (props) => {
    const {
        onClose,
        open,
        newRequest,
    } = props;
    const { t } = useTranslation();

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={COMPONENT_CLASS}
        >
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <div className={`${COMPONENT_CLASS}__backdrop`} />
                <div className={`${COMPONENT_CLASS}__overlay`} >
                    <Dialog.Panel className="rounded bg-white" style={{ width: '978px', borderRadius: '30px' }}>
                        <div className={`${COMPONENT_CLASS}__header`} >
                            <img src={logo} alt="Logo" className={`${COMPONENT_CLASS}__header-image`} />
                        </div>
                        <div className="flex-block justify-center" >
                            <RequestModalTitle newRequest={!newRequest} />
                            <RequestModalMessage newRequest={!newRequest} />
                            <div className={`${COMPONENT_CLASS}__button-area -no-lower-message`}>
                                <Button
                                    type="link"
                                    onClick={handleClose}
                                    buttonText={t('buttons.btn_close')}
                                />

                            </div>
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
};

export default RequestModal;