/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import LineChartPoint from "../../models/interfaces/charts/line-chart-point";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import MskScoreService from "../../utilities/services/msk-score-service";
import UserService from "../../utilities/services/user-service";
import { Loader } from "../loader/loader";
import UserProfileHeader from "../user-profile-header/user-profile-header";
import UserProfileSummary from "../user-profile-summary/user-profile-summary";
import { enqueueSnackbar } from "notistack";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { AssessmentUtils } from "../../utilities/assessments/assessment-utils";
import { PageTabs } from "../page-tabs/page-tabs";
import UserProfileAssessments from "../user-profile-assessments/user-profile-assessments";
import UserProfileWellnessProgram from "../user-profile-wellness-program/user-profile-wellness-program";
import UserProfileHistory from "../user-profile-history/user-profile-history";
import { Modal } from "../modal/modal";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import PortalProgressBar from "../portal-progress-bar/portal-progress-bar";
import UserUtil from "../../utilities/user-util";
import MskScoreUtil from "../../utilities/msk-score-util";
import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import OrganizationService from "../../utilities/services/organization-service";
import { Organization } from "../../models/interfaces/organization";
import FunctionService from "../../utilities/services/function-service";
import { useTranslation } from "react-i18next";
import { Tabs } from "../../models/enumerations/tabs";

const COMPONENT_CLASS = "c-user-profile";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileProps {
    userId: string;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfile: React.FC<UserProfileProps> = (props: UserProfileProps) => {
    const { t } = useTranslation();
    const db = getFirestore();
    const params = new URLSearchParams(window.location.search);
    const active = params.get("active");
    const { state } = useAuthState();
    const [isLoadingMskData, setIsLoadingMskData] = useState(true);
    const [isLoadingAssessmentResponses, setIsLoadingAssessmentResponses] = useState(true);
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const [, setPercentPoints] = useState<LineChartPoint[]>();
    const [products, setProducts] = useState<any>();
    const [latestMskData, setLatestMskData] = useState<MskScore>();
    const [user, setUser] = useState<User | null>(null);
    const [latestAssessmentResponses, setLatestAssessmentResponses] = useState<any>();
    const [initialTab] = useState(active ? Number(active) : Tabs.Summary);
    const [allMskScores, setAllMskScores] = useState<MskScore[]>()
    const [openGiftModal, setOpenGiftModal] = useState(false);
    const [openRemoveLicenseModal, setOpenRemoveLicenseModal] = useState(false);
    const [userOrganization, setUserOrganization] = useState<Organization>();
    const [hasAvailableLicenses, setHasAvailableLicenses] = useState(false);

    const openLicenseModal = () => setOpenGiftModal(true);
    const openRemoveLicense = () => setOpenRemoveLicenseModal(true);
    const isAccountHolder = UserUtil.isAccountHolder(state.user);
    const canAssignLicense = UserUtil.isAdmin(state.user) || UserUtil.isManager(state.user) || UserUtil.isAccountHolder(state.user) || UserUtil.isSuperAdmin(state.claims);

    const [validMskScore, setValidMskScore] = useState<boolean>(false)
    useEffect(() => {
        if (!props.userId) {
            return;
        }

        UserService.getSnapshot(props.userId, (u: User) => {
            setUser(u);
            setIsLoadingUser(false);
        });

        MskScoreService.getSnapshotBy([{
            field: "userId",
            operator: "==",
            value: props.userId,
        }], [{
            field: "created",
            direction: "desc",
        }], 12, (mskScores: MskScore[]) => {
            if (mskScores.length > 0) {
                setLatestMskData(mskScores[0]);
                setValidMskScore(MskScoreUtil.checkMskScore(mskScores[0]))
            }

            setPercentPoints(mskScores
                .filter(d => d.created)
                .map(d => ({ created: d.created!.toDate(), percentage: d.percentage }))
            );

            setIsLoadingMskData(false);
        });

    }, [props.userId]);

    useEffect(() => {
        const getAssessmentResponses = async () => {
            const assessmentResponses = await AssessmentUtils.fetchAssessmentResponsesById(props.userId ?? "")
            assessmentResponses && assessmentResponses.length > 0 ? setLatestAssessmentResponses(assessmentResponses) : setLatestAssessmentResponses(undefined)
            setIsLoadingAssessmentResponses(false);
        }

        getAssessmentResponses();

    }, [props.userId])
    // Get products/prices
    useEffect(() => {
        const getProductsAndPrices = async () => {
            const products: any[] = [];
            const q = query(
                collection(db, 'products'),
                where('active', '==', true),
            );

            const querySnapshot = await getDocs(q);

            // for each product, get the product price info
            querySnapshot.docs.map(async (productDoc) => {

                let prices: any[] = [];

                // fetch prices subcollection per product
                const pricesCollection = collection(productDoc.ref, 'prices');
                const priceQuerySnapshot = await getDocs(pricesCollection);

                // loop through difference business prices
                priceQuerySnapshot.docs.forEach((item) => {
                    prices.push({ data: item.data(), id: item.id });
                })
                products.push({
                    product: { data: productDoc.data(), id: productDoc.id },
                    prices: prices
                })
            });
            setProducts(products);
        }
        getProductsAndPrices();
    }, [])

    useEffect(() => {
        const getOrg = async () => {
            if (user && user.organizationId) {
                const org = await OrganizationService.get(user.organizationId);
                if (org) {
                    const hasLicenses = org.mySymmioLicense !== undefined && org.mySymmioLicenseCount !== undefined && org.mySymmioLicense > org.mySymmioLicenseCount;

                    setUserOrganization(org);
                    setHasAvailableLicenses(hasLicenses);
                }
            };
        }
        getOrg();
    }, [user]);

    const handleGreenToast = () => {
        enqueueSnackbar(t('userProfile.profileUpdated'), { variant: "toast", width: "450px" });
    }
    //     if (!props.userId) return;
    //     const userInFirebase = await UserService.get(props!.userId);
    //     if (!userInFirebase || !userInFirebase.organizationId) return;
    //     const latestOrgId = userInFirebase.organizationId;
    //     const userId = userInFirebase.id;

    //     setIsLoading(true);
    //     if (!userId) {
    //         return;
    //     }
    //     const date = DateTime.utc().toISO();
    //     const report = await ReportService.getBy(
    //         [
    //             {
    //                 field: "userId",
    //                 operator: "==",
    //                 value: userId,
    //             },
    //             {
    //                 field: "organizationId",
    //                 operator: "==",
    //                 value: latestOrgId,
    //             },
    //             {
    //                 field: "status",
    //                 operator: "==",
    //                 value: "complete",
    //             },
    //             {
    //                 field: "type",
    //                 operator: "==",
    //                 value: 4,
    //             },
    //             {
    //                 field: "expiration",
    //                 operator: ">",
    //                 value: date,
    //             },
    //         ], [
    //         {
    //             field: "expiration",
    //             direction: "desc",
    //         },
    //         {
    //             field: "updated",
    //             direction: "desc",
    //         }]);
    //     if (report.length <= 0) {
    //         setIsLoading(false);
    //         setDownloadingReportSnackBar(enqueueSnackbar("Downloading your Symmio Report. Please wait while we generate it.", { variant: "warning" }));
    //         handleGenerateReport(userId, latestOrgId);
    //         return;
    //     }

    //     if (!report[0].id) {
    //         setIsLoading(false);
    //         return;
    //     }

    //     const reportUnsubscribe = ReportService.getSnapshot(
    //         report[0].id,
    //         (r: Report) => {
    //             if (r.status === "complete" && r.url) {
    //                 setIsLoading(false);
    //                 reportUnsubscribe();

    //                 //Loads in the System browser
    //                 window.open(r.url);
    //             }
    //             if (r.status === "error") {
    //                 setReportError(r.errorMessage);
    //                 setIsLoading(false);
    //                 reportUnsubscribe();
    //             }
    //         }
    //     );
    // };



    // const handleGenerateReport = async (userId: string, organizationId: string) => {
    //     setIsLoading(true);
    //     const date = DateTime.now().toISODate();
    //     const report: Report = {
    //         dateEnd: date,
    //         dateStart: date,
    //         emailResults: false,
    //         organizationId: organizationId,
    //         status: "scheduled",
    //         type: ReportType.Assessment,
    //         userId: userId,
    //     };

    //     const user = await UserService.get(userId);

    //     const scheduledReport = await ReportService.save(
    //         report,
    //         user
    //     );


    //     if (!scheduledReport.id) {
    //         setIsLoading(false);
    //         setReportError(
    //             "Report was not saved successfully. Please try again."
    //         );
    //         return;
    //     }

    //     const reportUnsubscribe = ReportService.getSnapshot(
    //         scheduledReport.id,
    //         (r: Report) => {
    //             if (r.status === "complete" && r.url) {
    //                 setIsLoading(false);
    //                 reportUnsubscribe();

    //                 //Loads in the System browser
    //                 window.open(r.url);
    //             }
    //             if (r.status === "error") {
    //                 setReportError(r.errorMessage);
    //                 setIsLoading(false);
    //                 reportUnsubscribe();
    //             }
    //         }
    //     );
    // };

    // const handleGenerateWellnessReport = async (userId: string, organizationId: string, type: number) => {
    //     setIsLoading(true);
    //     const date = DateTime.now().toISODate();

    //     const report: Report = {
    //         dateEnd: date,
    //         dateStart: date,
    //         emailResults: false,
    //         organizationId: organizationId,
    //         status: "scheduled",
    //         type: type,
    //         userId: userId,
    //     };

    //     const user = await UserService.get(userId);

    //     const scheduledReport = await ReportService.save(
    //         report,
    //         user
    //     );

    //     if (!scheduledReport.id) {
    //         setIsLoading(false);
    //         setReportError(
    //             "Report was not saved successfully. Please try again."
    //         );
    //         return;
    //     }

    //     const reportUnsubscribe = ReportService.getSnapshot(
    //         scheduledReport.id,
    //         (r: Report) => {
    //             if (r.status === "complete" && r.url) {
    //                 setIsLoading(false);
    //                 reportUnsubscribe();

    //                 //Loads in the System browser
    //                 window.open(r.url);
    //             }
    //             if (r.status === "error") {
    //                 setReportError(r.errorMessage);
    //                 setIsLoading(false);
    //                 reportUnsubscribe();
    //             }
    //         }
    //     );
    // };

    const handleAssignLicense = async () => {
        if (user && hasAvailableLicenses && canAssignLicense) {
            user.symmioAccess = SymmioAccessType.AppLicense;
            await UserService.save(user);

            const info = { orgName: userOrganization?.name, accountOwnerEmail: state.user?.email };
            FunctionService.sendMySymmioAccessAssigned(user, info);

            enqueueSnackbar(t('userProfile.enrolled'), { variant: "toast", width: "450px" });
            setOpenGiftModal(false);
        }
    };

    const handleRemoveLicense = async () => {
        if (user && canAssignLicense) {
            user.symmioAccess = SymmioAccessType.WebAccess;
            await UserService.save(user);

            const info = { orgName: userOrganization?.name, accountOwnerEmail: state.user?.email };
            FunctionService.sendMySymmioAccessRemoved(user, info);

            setOpenRemoveLicenseModal(false);
        }
    };

    return (
        <div className={COMPONENT_CLASS}>
            <Loader
                isVisible={isLoadingMskData && isLoadingUser && isLoadingAssessmentResponses} />

            <Modal
                isOpen={openGiftModal}
                isLoading={false}
                onClose={setOpenGiftModal}
                defaultModalActions={true}
                submitDisabled={!hasAvailableLicenses || !canAssignLicense}
                onSubmit={handleAssignLicense}
                onCancel={() => setOpenGiftModal(false)}
                title={t('userProfile.enroll')}
                submitButtonText={t('buttons.btn_enrollUser')}
                cancelButtonText={t('buttons.btn_cancel')}
            >
                <div className="mb-6">
                    <p className="text-sm font-light mb-4">
                        {t('userProfile.enrollDescription1')}
                        <br />
                        <br />
                        {t('userProfile.enrollDescription2')}
                    </p>
                    {userOrganization &&
                        <div>
                            <PortalProgressBar total={userOrganization.mySymmioLicense!} currentProgress={userOrganization.mySymmioLicenseCount!}
                                headerText={t('symmioLicenseCheckbox.progressBarText', {
                                    usedLicenses: userOrganization.mySymmioLicenseCount!,
                                    totalLicenses: userOrganization.mySymmioLicense!
                                })}
                                link={isAccountHolder ? "/settings" : undefined} linkText={isAccountHolder ? t('symmioLicenseCheckbox.manageAddOns') : undefined} />
                        </div>
                    }
                </div>
            </Modal>

            <Modal
                isOpen={openRemoveLicenseModal}
                isLoading={false}
                onClose={setOpenRemoveLicenseModal}
                defaultModalActions={true}
                submitDisabled={!canAssignLicense}
                onSubmit={handleRemoveLicense}
                onCancel={() => setOpenRemoveLicenseModal(false)}
                title={t('userProfile.removeWp')}
                submitButtonText={t('buttons.btn_removeWp')}
                cancelButtonText={t('buttons.btn_cancel')}
            >
                <div className="mb-6">
                    <p className="text-sm font-light mb-4">
                        {t('userProfile.removeWpDescription')}
                    </p>
                    {userOrganization &&
                        <div>
                            <PortalProgressBar total={userOrganization.mySymmioLicense!} currentProgress={userOrganization.mySymmioLicenseCount!}
                                headerText={t('symmioLicenseCheckbox.progressBarText', {
                                    usedLicenses: userOrganization.mySymmioLicenseCount!,
                                    totalLicenses: userOrganization.mySymmioLicense!
                                })}
                                link={isAccountHolder ? "/settings" : undefined} linkText={isAccountHolder ? t('symmioLicenseCheckbox.manageAddOns') : undefined} />
                        </div>
                    }
                </div>
            </Modal>

            {user &&
                <>
                    <UserProfileHeader latestMskData={latestMskData} user={user} greenToast={handleGreenToast} products={products} />
                    <div className={`${COMPONENT_CLASS}__tab-content`}>
                        <PageTabs components={[
                            {
                                tab: Tabs.Summary, tabLabel: t('userProfile.tabs.summary'), component: UserProfileSummary, props: {
                                    user: user,
                                    mskScore: latestMskData,
                                    assessmentResponses: latestAssessmentResponses,
                                    allMskScores: allMskScores,
                                    setAllMskScores: setAllMskScores,
                                    openLicenseModal: openLicenseModal,
                                    openRemoveLicense: openRemoveLicense,
                                    hasAvailableLicenses: hasAvailableLicenses,
                                    canAssignLicense: canAssignLicense,
                                }
                            },
                            {
                                tab: Tabs.Assessments, tabLabel: t('userProfile.tabs.assessments'), component: UserProfileAssessments, props:
                                {
                                    user: user,
                                    mskScore: latestMskData,
                                    validMskScore: validMskScore,
                                    assessmentResponses: latestAssessmentResponses,
                                    allMskScores: allMskScores,
                                    setAllMskScores: setAllMskScores,
                                }
                            },
                            {
                                tab: Tabs.WellnessProgram, tabLabel: t('general.wellnessProgram'),
                                component: UserProfileWellnessProgram,
                                props: {
                                    user: user,
                                    mskScore: latestMskData,
                                    validMskScore: validMskScore,
                                    assessmentResponses: latestAssessmentResponses,
                                    allMskScores: allMskScores,
                                    setAllMskScores: setAllMskScores,
                                    openLicenseModal: openLicenseModal,
                                    hasAvailableLicenses: hasAvailableLicenses,
                                    canAssignLicense: canAssignLicense,
                                }
                            },
                            {
                                tab: Tabs.History, tabLabel: t('userProfile.tabs.history'),
                                component: UserProfileHistory,
                                props: {
                                    user: user,
                                    allMskScores: allMskScores,
                                    setAllMskScores: setAllMskScores,
                                }
                            }
                        ]} initialTab={initialTab} highlightLineId="userProfileTabsHighlightLine" />
                    </div>
                </>
            }
        </div>
    );
}

export default UserProfile;
