/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import BaseCardLayout from "../base-card-layout/base-card-layout";
import { enqueueSnackbar } from "notistack";
import { Redirect, } from "react-router";
import { useParams } from "react-router";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { Button } from "../button/button";
import { AssessmentsUserMenu } from "../assessments-user-menu/assessments-user-menu";
import BaseCardLayoutActions from "../base-card-layout/base-card-layout-actions";
import Stripe from "stripe";
import UserService from "../../utilities/services/user-service";
import { User } from "../../models/interfaces/user";
import OrganizationSubscriptionService from "../../utilities/services/organization-subscription-service";
import { OrganizationSubscription } from "../../models/interfaces/organization-subscription";
import { useHistory } from 'react-router-dom';
import SubscriptionService from "../../utilities/services/subscription-service";
import { WarningItem } from "../warning-item/warning-item";
import { Utils } from "../../utilities/utils";
import { useTranslation } from 'react-i18next';
import moment from "moment";

const COMPONENT_CLASS = "c-subscription-management";

interface ManageSubscriptionParams {
  id: string;
}

const ManageSubscription: React.FC = () => {
  const { id } = useParams<ManageSubscriptionParams>();
  const { state, } = useAuthState();
  const [interval, setInterval] = useState({ title: '', price: '' });
  const [price, setPrice] = useState('');
  const [startDate, setStartDate] = useState('');
  const [nextPaymentDate, setNextPaymentDate] = useState('');
  const [coupon, setCoupon] = useState('');
  const [isSubscriptionCancelled, setIsSubscriptionCancelled] = useState<boolean>(false);
  const [organizationSubscriptionPrice, setOrganizationSubscriptionPrice] = useState<string>();
  const history = useHistory();
  const [organizationSubscription, setOrganizationSubscription] = useState<OrganizationSubscription>();
  const { t } = useTranslation();

  const stripe: Stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
  // Format the price above to USD using the locale, style, and currency.
  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });


  useEffect(() => {
    if (state.user && state.user.id) {
      UserService.getSnapshot(state.user.id, async (u: User) => {
        // await stripe.customers.list({
        //   limit: 1,
        //   email: `${u?.email}`,
        //   expand: ['data.subscriptions']
        // }).then(async (customer: any) => {

        // })
        if (u && u.subscription && u.subscription.subscriptionId) {
          await stripe.subscriptions.retrieve(
            u?.subscription?.subscriptionId
          ).then(async (subscription) => {
            setIsSubscriptionCancelled(subscription.cancel_at_period_end);
            const orgSub = await OrganizationSubscriptionService.getBy([{
              field: "organizationId",
              operator: "==",
              value: u.organizationId
            }]);
            if (subscription.discount && subscription.discount.coupon && !subscription.cancel_at_period_end) {
              setCoupon(subscription.discount.coupon.id);
            }
            setOrganizationSubscription(orgSub[0]);
            if (subscription.items.data[0].plan.interval === "year") {
              setInterval({ title: t('manageSubscription.yearInterval'), price: t('manageSubscription.yearPrice') });
              if (orgSub.length > 0 && orgSub[0].mySymmioMonthlyPrice)
                setOrganizationSubscriptionPrice(USDollar.format(orgSub[0].mySymmioMonthlyPrice));

            }
            else {
              setInterval({ title: t('manageSubscription.yearInterval'), price: t('manageSubscription.monthPrice') });
              if (orgSub.length > 0 && orgSub[0].mySymmioYearlyPrice)
                setOrganizationSubscriptionPrice(USDollar.format(orgSub[0].mySymmioYearlyPrice));

            }
            const subPrice = subscription.items.data[0].plan.amount ? subscription.items.data[0].plan.amount / 100 : 0;
            setPrice(USDollar.format(subPrice));
            const start = new Date(subscription.current_period_start * 1000);
            const end = new Date(subscription.current_period_end * 1000);
            setStartDate(moment(start).format("MMMM DD, yyyy"));
            setNextPaymentDate(moment(end).format("MMMM DD, yyyy"));
          });
        }

      });

    }
  }, [id, isSubscriptionCancelled]);

  if (state.user && !state.user.isAccountSetup) {
    return <Redirect to="/setup" />;
  }
  if (!state.authenticated) {
    return <Redirect to="/login" />;
  }
  if (state.user && !state.user.subscriptionLinkId) {
    return <Redirect to="/" />
  }
  if (state.user && (!state.user.subscription || !state.user.subscription.subscriptionId || state.user.subscription.didPaymentFail) && state.user.subscriptionLinkId) {
    return <Redirect to="/wellness-results" />
  }

  return (
    <>
      <div className={`user-menu`} style={{}}>
        <AssessmentsUserMenu profileImage={false} />
      </div>
      <BaseCardLayout>
        <div className={`${COMPONENT_CLASS}`}>
          <div className={`${COMPONENT_CLASS}__wrapper`}>
            <div style={{ marginTop: '2rem', marginBottom: '1rem' }} className={`c-subscriptions__header`}>
              {t('manageSubscription.title')}
            </div>
            <div className={`${COMPONENT_CLASS}__content`}>
              <div style={{ fontSize: '20px', fontWeight: '500', marginBottom: '1rem' }}>{t('manageSubscription.currentSubscription')}</div>
              {isSubscriptionCancelled && (
                <WarningItem
                  textColor="#000000"
                  iconColor="#FFC79C"
                  backgroundColor="#FFF4E4"
                  title={t('manageSubscription.cancelTitle')}
                  text={t('manageSubscription.subscriptionCancelled', { nextPaymentDate: nextPaymentDate })}
                  buttonText={t('buttons.btn_resubscribe')}
                  onButtonClick={async () => {
                    try {
                      await SubscriptionService.handleUserSubscriptionReactivation(state.user?.id);
                      enqueueSnackbar(t('manageSubscription.reactivateTitle'), { variant: "toast", width: "450px" });
                      setIsSubscriptionCancelled(false);
                    } catch (err: any) {
                      enqueueSnackbar(err);
                    }

                  }}
                //isButtonDisabled={!UserUtil.isAccountHolder(state.user)}
                />
              )}
              <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F3F3F4', marginBottom: isSubscriptionCancelled ? '3.5rem' : '', height: 'fit-content', width: '100%', borderRadius: '10px', padding: '2rem' }}>
                <div className="flex flex-wrap justify-between items-start">
                  <div className="md:w-auto w-full">
                    <div style={{ fontSize: '16px', fontWeight: '400', marginBottom: '1rem' }}>{interval.title}</div>
                    <h1 style={{ fontSize: '13px', color: '#82848B', marginBottom: '0.5rem' }}>{t('manageSubscription.reactivateTitle', { startDate: startDate })}</h1>
                    <h1 style={{ fontSize: '13px', color: '#82848B', marginBottom: '1rem' }}>{isSubscriptionCancelled ? t('manageSubscription.nextPayment_cancel') : t('manageSubscription.nextPayment', { nextPayment: nextPaymentDate })}</h1>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto' }}>
                      <h1 style={{ fontSize: '32px', fontWeight: 'bold', marginRight: '0.5rem' }}>{price}</h1>
                      <h1 style={{ fontSize: '13px', color: '#82848B' }}>/ {interval.price}</h1>
                    </div>
                    {coupon && (
                      <h1 style={{ fontSize: '13px' }}><strong>{t('manageSubscription.offerApplied')}</strong> {coupon === 'DdJdKkSV' ? t('manageSubscription.50off') : t('manageSubscription.30off')}</h1>
                    )}
                  </div>
                </div>

                {!isSubscriptionCancelled && (
                  <div className={`${COMPONENT_CLASS}__actions`}>
                    <button
                      type="button"
                      className={`${COMPONENT_CLASS}__actions__cancel`}
                      style={{ backgroundColor: '#F3F3F4', paddingLeft: '0px' }}
                      onClick={() => history.push("/cancel-plan")}>
                      {t('buttons.btn_cancelSubscription')}
                    </button>
                    <Button
                      buttonText={t('buttons.btn_updateBilling')}
                      onClick={async () => await SubscriptionService.getCustomerPortalSession(state.user, organizationSubscription)} />
                  </div>
                )}

              </div>
              {!isSubscriptionCancelled && (
                <><div style={{ fontSize: '20px', fontWeight: '500', marginTop: '1rem', marginBottom: '1rem' }}>{interval.title === t('manageSubscription.yearInterval') ? t('manageSubscription.changeSubscription') : t('manageSubscription.upgradeSave')}</div><div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F3F3F4', height: 'fit-content', width: '100%', borderRadius: '10px', padding: '2rem' }}>
                  <div className="flex flex-wrap justify-between items-center">
                    <div className="sm:w-auto w-full">
                      <div className="flex gap-4 items-center mb-4">
                        <div style={{ fontSize: '16px', fontWeight: '400' }}>{interval.title === t('manageSubscription.yearInterval') ? t('manageSubscription.monthInterval') : t('manageSubscription.yearInterval')}</div>
                        {interval.title !== t('manageSubscription.yearInterval') && Utils.getDiscountBadgeText(organizationSubscription) &&
                          <div className={`${COMPONENT_CLASS}__badge`}>{Utils.getDiscountBadgeText(organizationSubscription)}</div>
                        }
                      </div>

                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto' }}>
                        <h1 style={{ fontSize: '32px', fontWeight: 'bold', marginRight: '0.5rem' }}>{organizationSubscriptionPrice}</h1>
                        <h1 style={{ fontSize: '13px', color: '#82848B' }}>{interval.title === t('manageSubscription.yearInterval') ? t('manageCancellation.slashMonth') : t('manageCancellation.slashYear')}</h1>
                      </div>
                      <h1 style={{ fontSize: '13px', color: '#82848B', marginBottom: '1rem' }}>{interval.title === t('manageSubscription.yearInterval') ? t('manageSubscription.billedMonthly') : t('manageSubscription.billedAnnually')}</h1>
                    </div>
                    <div>
                      <Button
                        buttonText={interval.title === t('manageSubscription.yearInterval') ? t('manageSubscription.changeSubscription') :
                          (Utils.getDiscountBadgeText(organizationSubscription) ? t('buttons.upgradeAnd', { saveText: Utils.getDiscountBadgeText(organizationSubscription) }) : t('buttons.btn_upgrade'))}
                        onClick={async () => await SubscriptionService.getCustomerPortalSession(state.user, organizationSubscription)} />
                    </div>
                  </div>
                </div></>
              )}
            </div>
            <BaseCardLayoutActions
              backButton={true}
              onBack={() => history.push("/wellness-results")}
            />
          </div>
        </div>
      </BaseCardLayout>
    </>
  );
}

export default ManageSubscription;