import { MenuAlt2Icon, XIcon } from '@heroicons/react/outline';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { siteMap } from '../../sitemap';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import logo from "../../assets/images/symmio-logos/symmio-icon-logo-light.png";
import UserUtil from '../../utilities/user-util';
// import { ReactComponent as AcademyIcon } from "../../assets/icons/nav/icon_academy.svg";
import { OrganizationSubMenu } from './organization-sub-menu';
import { ReactComponent as DataExportIcon } from "../../assets/icons/nav/icon_data-export.svg";
import { ReactComponent as LeadCaptureIcon } from "../../assets/icons/nav/icon_lead-capture.svg";
import { ReactComponent as SubscriptionLinkIcon } from "../../assets/icons/nav/icon_recurring-subscription.svg";
import { useTranslation } from 'react-i18next';
import { PlanType } from '../../models/enumerations/plan-type';

const COMPONENT_CLASS = "c-sidebar";
// const ACADEMY_ICON_NAME = "sitemap.academy";
const DATA_EXPORT_ICON_NAME = "sitemap.exportUsers";
const LEAD_CAPTURE_ICON_NAME = "sitemap.leadCapture";
const SUBSCRIPTION_LINK_ICON_NAME = "sitemap.subLinks";

const Sidebar: React.FC = () => {
    let authRoutes = siteMap.filter(x => x.includeInNav === true).sort((a, b) => (a.navOrder || 0) - (b.navOrder || 0));
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { state } = useAuthState();
    const sidebarRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    // const ACADEMY_LINK = state.user ? `https://functionalmovement.com/education/OnlineCourseSymmio/81?userId=${state.user.id}` : 'https://functionalmovement.com';

    if (UserUtil.isSuperAdmin(state.claims)) {
        authRoutes = authRoutes.filter(x => x.name !== "Org Mgmt");
    }
    else if (UserUtil.isAccountHolder(state.user)) {
        authRoutes = authRoutes.filter(x => !x.onlySuperadmin);
    }

    if (!state.claims) {
        authRoutes = authRoutes.filter(x => x.onlySuperadmin == null || !x.onlySuperadmin);
        authRoutes = authRoutes.filter(x => x.requiredRoles == null || x.requiredRoles.length === 0);
    } else {
        if (!state.claims.superAdmin && !UserUtil.isAccountHolder(state.user)) {
            authRoutes = authRoutes.filter(x => x.onlySuperadmin == null || !x.onlySuperadmin);

            if (state.organization?.id === state.user?.organizationId) {
                authRoutes = authRoutes.filter(x => x.requiredRoles == null || x.requiredRoles.filter(r => state.user && state.user.roles && state.user.roles.includes(r)).length > 0);
            } else {
                authRoutes = authRoutes.filter(x => x.requiredRoles == null || x.requiredRoles.length === 0);
            }
        }
    }

    useEffect(() => {
        document.addEventListener('touchstart', handleClickOutside);

        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, []);

    function handleClickOutside(event: Event) {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target as HTMLBaseElement)) {
            // Clicked outside the side navigation bar, close it
            setSidebarOpen(false);
        }
    }

    const getIconAndLabel = (item: any) => {
        return (
            <div>
                {<item.icon />}
                <span>{item.translationName}</span>
            </div>
        )
    }

    return (
        <>
            <div className={`${COMPONENT_CLASS}__overlay ${sidebarOpen ? 'show-on-mobile' : ""}`}></div>
            <div className={`${COMPONENT_CLASS}`} ref={sidebarRef}>
                {
                    !sidebarOpen ?
                        <button
                            type="button"
                            className={`${COMPONENT_CLASS}__mobile-button-open show-on-mobile`}
                            onClick={() => setSidebarOpen(true)}>
                            <span className="sr-only show-on-mobile">Open sidebar</span>
                            <MenuAlt2Icon aria-hidden="true" />
                        </button>
                        :
                        <button
                            type="button"
                            className={`${COMPONENT_CLASS}__mobile-button-close show-on-mobile`}
                            onClick={() => setSidebarOpen(false)}>
                            <span className="sr-only show-on-mobile">Close sidebar</span>
                            <XIcon aria-hidden="true" />
                        </button>
                }
                <div className={`${COMPONENT_CLASS}__nav-container show-on-desktop ${sidebarOpen ? 'show-on-mobile' : ""}`}>
                    <div className={`${COMPONENT_CLASS}__inner`}>
                        <div className={`${COMPONENT_CLASS}__col`}>
                            <div className={`${COMPONENT_CLASS}__logo`}>
                                <a href="/">
                                    <img
                                        src={logo}
                                        alt="Logo" />
                                </a>
                            </div>
                            <div className={`${COMPONENT_CLASS}__nav-list`}>
                                <nav className={`${COMPONENT_CLASS}__nav-list-item`}>
                                    {authRoutes.map((item) => (
                                        item.name !== "Super Admin"
                                            ?
                                            <Link
                                                key={item.name}
                                                className={window.location.pathname === item.path ? '-active' : ''}
                                                to={item.path}>
                                                {item.icon ? getIconAndLabel(item) : item.translationName}
                                            </Link>
                                            :
                                            <>
                                                <Link
                                                    key={item.name}
                                                    className={`${(window.location.pathname === item.path ? '-active' : '')} superadmin`}
                                                    to={item.path}>
                                                    {item.icon ? getIconAndLabel(item) : item.translationName}
                                                </Link>
                                                <OrganizationSubMenu />
                                            </>

                                    ))}

                                    {/* Display Super Admin pages from the on-hover submenu on the mobile sidebar */}
                                    {state.user?.isSuperAdmin &&
                                        <>
                                            <Link
                                                key="Lead Capture"
                                                className={`${(window.location.pathname === '/lead-capture' ? '-active' : '')} show-on-mobile`}
                                                to="/lead-capture">
                                                <div>
                                                    {<LeadCaptureIcon style={{ width: "45%", height: "45%" }} />}
                                                    <span>{t(LEAD_CAPTURE_ICON_NAME)}</span>
                                                </div>
                                            </Link>


                                            {state.organization.plan?.value === PlanType.ThirdParty &&
                                                <Link
                                                    key="Subscription Links"
                                                    className={`${(window.location.pathname === '/subscription-links' ? '-active' : '')} show-on-mobile`}
                                                    to="/subscription-links">
                                                    <div>
                                                        {<SubscriptionLinkIcon style={{ width: "45%", height: "45%" }} />}
                                                        <span>{t(SUBSCRIPTION_LINK_ICON_NAME)}</span>
                                                    </div>
                                                </Link>
                                            }

                                            <Link
                                                key="Data Export"
                                                className={`${(window.location.pathname === '/data-export' ? '-active' : '')} show-on-mobile`}
                                                to="/data-export">
                                                <div>
                                                    {<DataExportIcon />}
                                                    <span>{t(DATA_EXPORT_ICON_NAME)}</span>
                                                </div>
                                            </Link>
                                        </>
                                    }

                                    {/* This link is an <a> tag and not a Link component,
                                because Link components are connected to the BrowserRouter
                                which attaches a basename to the url (e.g. localhost:8100/academy-link) */}
                                    {/* <a
                                        className={window.location.pathname === ACADEMY_LINK ? '-active' : ''}
                                        href={ACADEMY_LINK}
                                        target="_blank" rel="noreferrer">
                                        <div>
                                            {<AcademyIcon />}
                                            <span>{t(ACADEMY_ICON_NAME)}</span>
                                        </div>
                                    </a> */}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { Sidebar };

