import { ListOptions } from "./interfaces/list-options";
import i18n from "../i18n";

export enum MskHealthRiskValues {
    Health = "Health",
    Wellness = "Wellness",
    Fitness = "Fitness",
    Performance = "Performance",
}

export const MskHealthRiskOptions: ListOptions[] = [
    {
        value: MskHealthRiskValues.Health,
        label: i18n.t('general.mskRisk.high'),
    }, {
        value: MskHealthRiskValues.Wellness,
        label: i18n.t('general.mskRisk.moderate'),
    }, {
        value: MskHealthRiskValues.Fitness,
        label: i18n.t('general.mskRisk.slight'),
    }, {
        value: MskHealthRiskValues.Performance,
        label: i18n.t('general.mskRisk.low'),
    }
];

export const MskHealthRiskOptionsTranslationReference: ListOptions[] = [
    {
        value: "Health",
        label: 'general.mskRisk.high',
    }, {
        value: "Wellness",
        label: 'general.mskRisk.moderate',
    }, {
        value: "Fitness",
        label: 'general.mskRisk.slight',
    }, {
        value: "Performance",
        label: 'general.mskRisk.low',
    }
];
