import { ListOptions } from "./interfaces/list-options";
import i18n from "../i18n";

export const FocusGroupOptions: ListOptions[] = [
    // {
    //     value: "ACTIVITY_LEVEL",
    //     label: "Activity Level",
    // },
    {
        value: "BEHAVIORAL_HEALTH",
        label: i18n.t('focus.behavioralHealth'),
    }, {
        value: "BODY_COMPOSITION",
        label: i18n.t('focus.bodyComposition'),
    }, {
        value: "BREATHING",
        label: i18n.t('focus.breathingQuality'),
    }, {
        value: "INJURY_HISTORY",
        label: i18n.t('focus.injuryHistory'),
    }, {
        value: "MOVEMENT_HEALTH",
        label: i18n.t('focus.movementHealth'),
    }, {
        value: "NUTRITION",
        label: i18n.t('focus.nutritionAwareness'),
    },
    {
        value: "PHYSICAL_ACTIVITY",
        label: i18n.t('focus.physicalActivity'),
    }, {
        value: "SLEEP",
        label: i18n.t('focus.sleepWellness'),
    }
];