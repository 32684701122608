import React from "react";
import { useTranslation } from 'react-i18next';

export interface InactivityModalTitleProps {
}

/**
 * Represents the title that is displayed in the Inactivity modal when a Inactivity error
 * occurs.
 *
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const InactivityModalTitle: React.FC<InactivityModalTitleProps> = (props) => {
    const { t } = useTranslation();

    const title = t('inactivity.title');

    return (
        <h1 className="block" style={{ padding: '105px 0px 48px 0px', textAlign: 'center' }}>{title}</h1>
    );
};

export default InactivityModalTitle;