/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BaseCardLayout from '../../components/base-card-layout/base-card-layout';
import BaseCardLayoutActions from '../../components/base-card-layout/base-card-layout-actions';
import { useForm } from 'react-hook-form';
import DateInputOption from '../../components/account-information-date-input-option/account-information-date-input-option';
import UserService from '../../utilities/services/user-service';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import dayjs, { Dayjs } from 'dayjs';
import { User } from '../../models/interfaces/user';
import { enqueueSnackbar } from 'notistack';
import { TextTypeInput } from '../../components/forms';
import { useTranslation } from 'react-i18next';
import { BiologicalSexValue } from '../../models/biological-sex';


const COMPONENT_CLASS = "p-assessments-account-information";


const AssessmentsAccountInformation: React.FC = () => {
    let history = useHistory();
    const {
        register,
        handleSubmit,
        formState: { },
    } = useForm();
    const { state, setState } = useAuthState();
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [birthdate, setBirthdate] = React.useState<Dayjs | null>()
    const [user, setUser] = useState<User>();
    const [init, setInit] = useState<boolean>(false);
    const { t } = useTranslation();

    useEffect(() => {
        const findUserByEmail = async () => {
            const userByEmail = await UserService.findFirstBy([{
                field: "email",
                operator: "==",
                value: state.user?.email,
            }]);
            setUser(userByEmail);


        }

        findUserByEmail();

    }, [])

    useEffect(() => {
        if (!init && user) {
            let element = document.getElementById("biologicalSex") as HTMLSelectElement;
            element.value = user?.biologicalSex ? user.biologicalSex : BiologicalSexValue.Male;
            setBirthdate(dayjs(`${user?.birthdate}`))
            setInit(true);
        }


    }, [user]);

    const validate = () => {
        let errors = false;
        if (user?.firstName?.trim().length === 0) {
            setFirstNameError(true);
            errors = true;
        }
        if (user?.lastName?.trim().length === 0) {
            setLastNameError(true);
            errors = true;
        }

        return errors;
    }

    const onSubmit = async (data: any) => {
        const { biologicalSex } = data;

        let test = validate();
        if (test) {
            return;
        }

        try {
            if (user == null || firstNameError || lastNameError) {
                return;
            }

            await UserService.update({
                ...user,
                firstName: user.firstName,
                lastName: user.lastName,
                biologicalSex: biologicalSex,
                birthdate: birthdate?.format('YYYY-MM-DD'),
            }, state.user).then(() => {
                enqueueSnackbar(t('pages.assessmentsAccountInfo.accountInfoUpdated'), { variant: "toast", width: "500px" });
                history.push("/wellness-assessment");

                setState((state) => ({
                    ...state, ...{
                        user: {
                            ...user,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            biologicalSex: biologicalSex,
                            birthdate: birthdate?.format('L')
                        }
                    }
                }));
            }

            );
        } catch (exception: any) {
            // switch (exception.name) {
            //     case "EntityNotFoundException":
            //         setErrorTitle("Email Address Error");
            //         setError(
            //             "Please input the email address that you received your invitation through."
            //         );
            //         break;
            //     case "DuplicateEntityFoundException":
            //         setErrorTitle("Sorry, we have a problem");
            //         setError(
            //             "It looks like you have more than one account linked to the same email. Please reach out to an admin to resolve."
            //         );
            //         break;
            //     default:
            //         setErrorTitle("Oh no!");
            //         setError(
            //             "Something went wrong. We are working on fixing the problem. Please try again later."
            //         );
            //         break;
            // }

            // setModalVisble(true);
            // setIsLoading(false);
            return;
        }

    }

    return (
        <BaseCardLayout>
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <div className={`${COMPONENT_CLASS} flex flex-row`} style={{}}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={`${COMPONENT_CLASS}__content`}>
                            <h4>Edit Account</h4>
                            <div className={`${COMPONENT_CLASS}__field`}>
                                <label htmlFor="firstName">{t('manageAccount.firstName')}</label>
                                {firstNameError && (
                                    <div
                                        role="alert"
                                        className={`${COMPONENT_CLASS} -error`}>
                                        {t('manageAccount.firstName_req')}
                                    </div>
                                )}
                                <TextTypeInput
                                    id='firstName'
                                    type='text'
                                    label={t('manageAccount.firstName')}
                                    hideLabel={true}
                                    inputClassName={`c-input__input -displaytext ${firstNameError &&
                                        `${COMPONENT_CLASS} -showerror`
                                        }`}
                                    registerHook={register}
                                    registerOptions={{
                                        value: user?.firstName,
                                        maxLength: 20,
                                        onChange: (e) => setUser(Object.assign({}, user, { firstName: e.target.value })),
                                    }} />
                            </div>
                            <div className={`${COMPONENT_CLASS}__field`}>
                                <label htmlFor="lastName">{t('manageAccount.lastName')}</label>
                                {lastNameError && (
                                    <div
                                        role="alert"
                                        className={`${COMPONENT_CLASS} -error`}>
                                        {t('manageAccount.lastName_req')}
                                    </div>
                                )}
                                <TextTypeInput
                                    id='lastName'
                                    label={t('manageAccount.lastName')}
                                    hideLabel={true}
                                    inputClassName={`c-input__input -displaytext ${lastNameError &&
                                        `${COMPONENT_CLASS} -showerror`
                                        }`}
                                    registerHook={register}
                                    registerOptions={{
                                        onChange: (e) => setUser(Object.assign({}, user, { lastName: e.target.value })),
                                        value: user?.lastName,
                                        maxLength: 20,
                                    }} />
                            </div>
                            <div className={`${COMPONENT_CLASS}__field`}>
                                <label htmlFor="biologicalSex">{t('manageAccount.biological')}</label>
                                <select id="biologicalSex" {...register("biologicalSex", {
                                    required: true,
                                })}>
                                    <option value="Male">{t('general.male')}</option>
                                    <option value="Female">{t('general.female')}</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="birthdate">{t('manageAccount.birthdate')}</label>
                                <DateInputOption defaultBirthdate={user?.birthdate} answer={birthdate} onChange={(a) => { setBirthdate(dayjs(a)) }}  ></DateInputOption>
                            </div>


                        </div>
                    </form>
                </div>
                <div className={`${COMPONENT_CLASS}__footer`}>
                    <BaseCardLayoutActions backButton={true} onBack={() => { history.goBack() }} submitButton={true} onSubmit={handleSubmit(onSubmit)} submitButtonText={t('buttons.btn_continue')}></BaseCardLayoutActions>
                </div>
            </div>

        </BaseCardLayout>
    );
}
export default AssessmentsAccountInformation;