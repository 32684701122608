/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Button } from "../button/button";
import { useTranslation } from 'react-i18next';

export interface InactivityModalButtonsProps {
    handleLogout: Function,
    resetActivity: Function,
    setIsModalOpen: Function,
}

const COMPONENT_CLASS = 'c-login-modal';

/**
 * Represents buttons on the Inactivity modal.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const InactivityModalButtons: React.FC<InactivityModalButtonsProps> = (props) => {
    const { t } = useTranslation();

    const {
        handleLogout,
        resetActivity,
        setIsModalOpen
    } = props;
    const handleLogoutButtonClick = () => {
        handleLogout();
    };

    const handleContinueButtonClick = () => {
        setIsModalOpen(false);
        resetActivity()
    }
    let areaClass = `${COMPONENT_CLASS}__button-area -no-lower-message`;

    return (
        <div className={areaClass}>
            <a onClick={handleLogoutButtonClick} style={{
                marginRight: "39px",
                lineHeight: "47px",
                textDecorationLine: "underline",
                fontWeight: "700",
                cursor: "pointer"
            }}>{t('userMenu.item_logOut')}</a>
            <Button
                buttonStyle="primary"
                onClick={handleContinueButtonClick}
                buttonText={t('buttons.btn_continueSession')} />
        </div >
    );
};

export default InactivityModalButtons;
