import { Deeplink } from "../models/interfaces/deeplink";
import { StateCreator } from "zustand";
import { UnifiedStore } from "./useStore";
import FirestoreService from "../utilities/services/firestore-service";
import EntityNotFoundException from "../models/exceptions/entity-not-found";
import FirestoreCondition from "../models/interfaces/firestore-condition";
import UserUtil from "../utilities/user-util";

const COLLECTION_NAME = "deeplinks";

export interface DeeplinkSliceState {
    deeplinksIsLoaded: boolean;
    deeplinks: Deeplink[];
}

const initialState = {
    deeplinksIsLoaded: false,
    deeplinks: [],
};

export interface DeeplinkSlice extends DeeplinkSliceState {
    fetchDeeplinks: () => Promise<Deeplink[]>;
    addDeeplink: (entity: Deeplink) => Promise<Deeplink>;
    updateDeeplink: (entity: Deeplink) => Promise<Deeplink>;
    getDeeplinkById: (id: string) => Promise<Deeplink>;
    deleteDeeplink: (deeplink: Deeplink) => Promise<void>;
    markDeeplinksStale: () => Promise<void>;
}

export const createDeeplinkSlice: StateCreator<
    UnifiedStore,
    [],
    [],
    DeeplinkSlice
> = (set, get) => ({
    ...initialState,

    fetchDeeplinks: async (): Promise<Deeplink[]> => {
        const { deeplinks, deeplinksIsLoaded, organization, user } = get();

        if (deeplinksIsLoaded) {
            return deeplinks;
        }

        if (!organization?.id) {
            throw Error(
                "A organization is necessary to use the fetchDeeplinks call"
            );
        }

        const conditions: FirestoreCondition[] = [];

        conditions.push({
            field: "organizationId",
            operator: "==",
            value: organization.id,
        });

        let entities = await FirestoreService.getBy<Deeplink>(
            COLLECTION_NAME,
            conditions,
            [
                {
                    field: "created",
                    direction: "desc",
                },
            ]
        );

        if (user && UserUtil.isManager(user)) {
            const groupIds = await UserUtil.getHierarchicalGroupIds(user.managedGroupIds);

            entities = entities.filter(deeplink => deeplink.groupId && groupIds.includes(deeplink.groupId));
        }

        set({ deeplinks: entities, deeplinksIsLoaded: true });

        return entities;
    },

    addDeeplink: async (entity: Deeplink): Promise<Deeplink> => {
        const { markDeeplinksStale } = get();

        markDeeplinksStale();
        return FirestoreService.add<Deeplink>(COLLECTION_NAME, entity);
    },

    updateDeeplink: async (entity: Deeplink): Promise<Deeplink> => {
        const { markDeeplinksStale } = get();

        markDeeplinksStale();
        return FirestoreService.update(COLLECTION_NAME, entity);
    },

    getDeeplinkById: async (id): Promise<Deeplink> => {
        const { deeplinksIsLoaded, fetchDeeplinks } = get();
        let { deeplinks } = get();

        if (!deeplinksIsLoaded) {
            deeplinks = await fetchDeeplinks();
        }

        const entity = deeplinks.find((x) => x.id === id);

        if (!entity) {
            throw EntityNotFoundException;
        }

        return entity;
    },

    deleteDeeplink: async (deeplink) => {
        const { markDeeplinksStale } = get();

        try {
            if (!deeplink.id) {
                throw new Error();
            }
            await FirestoreService.deleteById(COLLECTION_NAME, deeplink.id);
            await markDeeplinksStale();
        } catch (err) {
            console.error("deeplink.id is not set");
            // TODO : Global exception handling
            // https://app.clickup.com/t/2219993/FMS-1236
        }
    },

    markDeeplinksStale: async (): Promise<void> =>
        set({
            ...initialState,
        }),
});
