/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { FormikTextField } from '../../formik-fields/formik-text-field';
import { RadioGroupItem } from '../../radio-group/radio-group-item';
import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { FormikDateField } from '../../formik-fields/formik-date-field';
import { TrialLengthItems } from '../trial-length-items';
import { FormikSelectField } from '../../formik-fields/formik-select-field';
import { NumericListOptions } from '../../../models/interfaces/numeric-list-options';
import moment from 'moment';
import UserService from '../../../utilities/services/user-service';
import UserRoles from '../../../models/user-roles';
import { ListOptions } from '../../../models/interfaces/list-options';
import { OrganizationType } from '../../../models/enumerations/organization-type';
import UserUtil from '../../../utilities/user-util';
import { PlanType } from '../../../models/enumerations/plan-type';
import { useTranslation } from 'react-i18next';

type DefaultFieldsProps = {
    organizationId?: string;
    accountType: RadioGroupItem,
    accountHolderName?: string,
    editing: boolean,
    initCustomTrialLength: boolean,
    loading: boolean,
};


export const DefaultFields: React.FC<DefaultFieldsProps> = (props: DefaultFieldsProps) => {
    const {
        accountType,
        accountHolderName,
        editing,
        initCustomTrialLength,
        loading,
        organizationId,
    } = props;
    const [customTrialLength, setCustomTrialLength] = useState<boolean>(initCustomTrialLength);
    const [holderOptions, setHolderOptions] = useState<ListOptions[]>([]);
    const [accountManagerSelectItems, setAccountManagerSelectItems] = useState<ListOptions[]>([]);
    const { t } = useTranslation();
    const formik = useFormikContext<any>();
    const { values, setFieldValue, validateField } = formik;

    useEffect(() => {
        const setHolders = async () => {
            const users = await UserService.getBy([
                {
                    field: 'organizationId',
                    operator: '==',
                    value: organizationId,
                },
                {
                    field: 'roles',
                    operator: '==',
                    value: [UserRoles.ADMIN_ID]
                }
            ]);


            const options: ListOptions[] = users.map((u) => {
                let name = u.email ?? `id: ${u.id}`;

                if (u.firstName && u.lastName) {
                    name = `${u.firstName} ${u.lastName}`;
                }

                if (u.firstName && !u.lastName) {
                    name = u.firstName;
                }

                if (!u.firstName && u.lastName) {
                    name = u.lastName;
                }

                const o: ListOptions = {
                    value: u.id!,
                    label: name,
                }

                return o;
            });


            // const finalOptions: ListOptions[] = [{ value: 'na', label: 'Select an account holder...' }];

            // options.forEach((opt) => finalOptions.push(opt));


            setHolderOptions(options);
        };
        if (accountHolderName === 'n/a' && editing) {
            setHolders();
        }
    }, [accountHolderName])

    // this is a hidden field value used only to determine whether or not to
    // validate account holder email when creating a new org.
    if (organizationId && !values['organizationId']) {
        setFieldValue('organizationId', organizationId)
    }

    useEffect(() => {
        setCustomTrialLength(initCustomTrialLength);
    }, [initCustomTrialLength]);

    useEffect(() => {
        if (accountHolderName) {
            setFieldValue('accountHolderName', accountHolderName);
        }
    }, [accountHolderName]);

    useEffect(() => {
        const fetchSuperAdmins = async () => {
            const superAdminsListOptions: ListOptions[] = [];
            const superAdmins = await UserService.getAllSuperAdmins();

            superAdmins.forEach((user) => {
                if (user.id && !UserUtil.isDeveloperUser(user)) {
                    const name = user ? `${(user.firstName || "")} ${(user.lastName || "")}` : "";

                    const selectItem: ListOptions = {
                        value: user.id,
                        label: name,
                    }

                    superAdminsListOptions.push(selectItem);
                }
            });

            setAccountManagerSelectItems(superAdminsListOptions);
        }

        fetchSuperAdmins();
    }, []);

    const handleTrialLengthChange = async (option: NumericListOptions) => {
        setCustomTrialLength(option.value === 0);
        if (option.value !== 0) {
            const exp = moment().add(option.value, 'days');
            await setFieldValue('renewDate', exp);
            validateField('renewDate');
        } else {
            setFieldValue('renewDate', null);
        }
    }

    const handleCustomTrialLengthChange = (days: any) => {
        if (days.match(/^([0-9])+$/)) {
            const intDays = parseInt(days, 10);
            const exp = moment().add(intDays, 'days');
            setFieldValue('renewDate', exp);
        }
    }
    return (
        <>
            <Grid container rowSpacing={4} columnSpacing={3}>
                <Grid item xs={12}>
                    <FormikTextField
                        name="organizationName"
                        label={t('organization.edit.fields.orgName')}
                        disabled={loading}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    {
                        (accountHolderName === 'n/a' && editing) ? (
                            <FormikSelectField
                                label={'Account Holder'}
                                name={t('organization.edit.fields.accountHolder')}
                                disabled={loading}
                                items={holderOptions}
                                includeNone
                            />
                        ) : (

                            <FormikTextField
                                name={editing ? 'accountHolderName' : 'accountHolderEmail'}
                                label={editing ? t('organization.edit.fields.accountHolder') : t('organization.edit.fields.accountHolderEmail')}
                                disabled={loading}
                                readOnly={editing}
                                required={!editing}
                            />
                        )
                    }
                </Grid>
                {
                    (!editing && accountType.value !== OrganizationType.Trial && (values.plan && values.plan.value !== PlanType.ThirdParty)) &&
                    <Grid item sm={12} md={6}>
                        <FormikDateField
                            name="startDate"
                            label={t('deeplinks.edit.startDate')}
                            disabled={loading}
                            required
                        />
                    </Grid>
                }
                {
                    (accountType.value !== OrganizationType.Trial && (values.plan && values.plan.value !== PlanType.Starter && values.plan.value !== PlanType.ThirdParty)) &&
                    <Grid item sm={12} md={6}>
                        <FormikDateField
                            name="renewDate"
                            label={t('organization.edit.fields.renewDate')}
                            minDate={values['startDate']}
                            disabled={loading}
                            readOnly={accountType.value === OrganizationType.Trial}
                            required={accountType.value !== OrganizationType.Trial}
                        />
                    </Grid>
                }
                {
                    accountType.value === OrganizationType.Trial &&
                    <Grid item sm={12} md={6}>
                        <FormikSelectField
                            name='trialLength'
                            label={t('organization.edit.fields.trialLength')}
                            disabled={loading}
                            items={TrialLengthItems}
                            onChange={handleTrialLengthChange}
                            includeNone
                            required
                        />
                    </Grid>
                }
                {
                    (accountType.value === OrganizationType.Trial && customTrialLength) &&
                    <Grid item sm={12} md={6}>
                        <FormikTextField
                            name='customTrialLength'
                            label={t('organization.edit.fields.customTrialLength')}
                            onChange={handleCustomTrialLengthChange}
                            disabled={loading}
                            required
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <FormikSelectField
                        label={t('organization.edit.fields.accountManager')}
                        name="accountManager"
                        items={accountManagerSelectItems}
                        includeNone
                        disabled={loading}
                        onChange={(option: NumericListOptions) => setFieldValue('accountManager', option)}
                    />
                </Grid>
            </Grid>
        </>
    );
}