import { NumericListOptions } from "../../models/interfaces/numeric-list-options";
import i18n from "../../i18n";

export const TrialLengthItems: NumericListOptions[] = [
    {
        value: 7,
        label: i18n.t('organization.edit.trialDays.7days'),
    },
    {
        value: 14,
        label: i18n.t('organization.edit.trialDays.14days'),
    },
    {
        value: 30,
        label: i18n.t('organization.edit.trialDays.30days'),
    },
    {
        value: 60,
        label: i18n.t('organization.edit.trialDays.60days'),
    },
    {
        value: 0,
        label: i18n.t('organization.edit.trialDays.custom'),
    }
];