/* eslint-disable jsx-a11y/img-redundant-alt */
import FirebaseStorage from "../../utilities/firebase-storage";
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "c-assessment-intro";

export interface LifestyleIntroProps { }

const LifestyleIntro: React.FC<LifestyleIntroProps> = (props) => {
    const { t } = useTranslation();

    return (
        <div className={`${COMPONENT_CLASS} scroll-container`}>
            <div className={`${COMPONENT_CLASS}__image`}>
                <img
                    alt="Movement Image"
                    src={new FirebaseStorage().getMediaUrl(
                        "public/lifestyle/lifestyle-assessment@2x.png"
                    )}
                />
            </div>
            <div className={`${COMPONENT_CLASS}__content-container`}>
                <h1>{t('assessmentIntro.titleLifestyle')}</h1>
                <div className={`${COMPONENT_CLASS}__content`}>
                    <p className={`${COMPONENT_CLASS}__description`}>
                        {t('assessmentIntro.messageLifestyle')}
                    </p>
                    <div className={`${COMPONENT_CLASS}__content__section`}>
                        <p className={`${COMPONENT_CLASS}__content__header`}>
                            {t('assessmentIntro.duration')}
                        </p>
                        <p>{t('assessmentIntro.duration_desc')}</p>
                    </div>
                    <div className={`${COMPONENT_CLASS}__content__section`}>
                        <p className={`${COMPONENT_CLASS}__content__header`}>
                            {t('assessmentIntro.equipment')}
                        </p>
                        <p>{t('assessmentIntro.equipmentLifestyle_desc')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LifestyleIntro;
