import { IntercomProvider } from "react-use-intercom";
import Settings from "../../components/settings/settings";

function SettingsPage() {
    const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID ?? "";

    return (
        <IntercomProvider appId={INTERCOM_APP_ID}>
            <Settings />
        </IntercomProvider>
    );
}

export default SettingsPage;
