import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { Report } from "../../models/interfaces/report";
import { User } from "../../models/interfaces/user";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "reports";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new report to the reports collection
 * @param {Report} report - The report that is being added to the collection
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Report>} A promise to the newly added report
 */
const add = async (report: Report, currentUser: User | null = null) => {
    report.language = currentUser && currentUser.preferredLanguage ? currentUser.preferredLanguage : "en";

    return FirestoreService.add<Report>(COLLECTION_NAME, report, currentUser);
};

/**
 * Delete a report collection by the Id
 * @param {string} id - The Id of the report being deleted
 */
const deleteById = async (id: string) => {
    FirestoreService.deleteById(COLLECTION_NAME, id);
};

/**
 * Find the specific report by the id
 * @param {string} id - The Id of the report that we are retrieving
 * @returns {Promise<Report>} A promise for the Report we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<Report>(COLLECTION_NAME, id);
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<User>} A promise for the User we are retrieving
 */
const getBy = async (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], limit?: number) => {
    return FirestoreService.getBy<Report>(COLLECTION_NAME, conditions, order, limit);
};

/**
 * Get all of the reports stored in the database
 * @returns {Promise<Report[]>} A promise for the collection of Reports
 */
const getAll = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getAll<Report>(COLLECTION_NAME, order);
};

/**
 * Get a snapshot of the specific report to see when it changes
 * @param {string} id - Id of the report document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshot = (id: string, listener: Function) => {
    return FirestoreService.getSnapshot<Report>(COLLECTION_NAME, id, listener);
};

/**
 * Get a snapshot of the reports to see when it changes
 * @param {string} id - Id of the report document
 * @param {Function} listener - Function to listen to the changes to the document
 */
const getSnapshotBy = (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], listener: Function) => {
    return FirestoreService.getSnapshotBy<Report>(COLLECTION_NAME, conditions, order, -1, listener);
};

/**
 * Save the specified report in the database
 * @param {Report} report - The report that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Report>} A promise for the report that is being updated
 */
const save = async (report: Report, currentUser: User | null = null) => {
    return FirestoreService.save<Report>(COLLECTION_NAME, report, currentUser);
};

/**
 * Update the specified report stored in the database
 * @param {Report} report - The report that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Report>} A promise for the report that is being updated
 */
const update = async (report: Report, currentUser: User | null = null) => {
    return FirestoreService.update<Report>(COLLECTION_NAME, report, currentUser);
};

// #endregion Service Methods


// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const ReportService = {
    add,
    deleteById,
    get,
    getBy,
    getAll,
    getSnapshot,
    getSnapshotBy,
    save,
    update,
};

export default ReportService;

// #endregion Exports