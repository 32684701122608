import { useHistory } from "react-router-dom";
import BaseCardLayout from "../base-card-layout/base-card-layout";
import { Button } from '../button/button';
import logoImage from "../../assets/images/symmio-logos/symmio-logo-text-white-side.png"
import { useEffect, useState } from "react";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { AssessmentsUserMenu } from "../assessments-user-menu/assessments-user-menu";
import UserService2 from '../../utilities/services/user-service';
import wellnessProgramImg from "../../assets/images/wellness-program.png"
import { ReactComponent as ExerciseIcon } from "../../assets/icons/outline/icon_toe-touch.svg";
import { ReactComponent as LightbulbIcon } from "../../assets/icons/outline/icon_lightbulb.svg";
import { ReactComponent as WellnessScoreIcon } from "../../assets/icons/outline/icon_wellness-score.svg";
import { ReactComponent as FocusAreaIcon } from "../../assets/icons/outline/icon_magnifying-glass.svg";
import AppContentUtil from "../../utilities/app-content-util";
import nflImg from "../../assets/images/fms-clients/nfl.png"
import premierLeagueImg from "../../assets/images/fms-clients/premier-league.png"
import equinoxImg from "../../assets/images/fms-clients/equinox.png"
import cardinalGlassImg from "../../assets/images/fms-clients/cardinal-glass.png"
import mlbImg from "../../assets/images/fms-clients/major-baseball-league.png"
import usArmyImg from "../../assets/images/fms-clients/us-army.png"
import todayImg from "../../assets/images/today-page.png"
import assessmentImg from "../../assets/images/assessments-mobile-app.png"
import { ReactComponent as BehavioralHealthIcon } from "../../assets/icons/solid/behavioral-health.svg";
import { ReactComponent as MovementIcon } from "../../assets/icons/outline/movement.svg";
import { ReactComponent as PhysicalActivityIcon } from "../../assets/icons/solid/activity-level.svg";
import { ReactComponent as BodyCompIcon } from "../../assets/icons/solid/body-comp.svg";
import { ReactComponent as BreathingIcon } from "../../assets/icons/solid/breathing-qual.svg";
import { ReactComponent as InjuryHistoryIcon } from "../../assets/icons/solid/injury-history.svg";
import { ReactComponent as NutritionIcon } from "../../assets/icons/solid/nutrition-aware.svg";
import { ReactComponent as SleepIcon } from "../../assets/icons/solid/sleep-well.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/solid/check.svg";
import { ReactComponent as CheckmarkWhiteIcon } from "../../assets/icons/solid/check-circle.svg";
import StarIcon from '@mui/icons-material/Star';
import { Modal } from "../modal/modal";
import { MskScore } from "../../models/interfaces/msk-score";
import FaqAccordion from "../faq-accordion/faq-accordion";
import { WarningItem } from "../warning-item/warning-item";
import SubscriptionService from "../../utilities/services/subscription-service";
import OrganizationSubscriptionService from "../../utilities/services/organization-subscription-service";
import { OrganizationSubscription } from "../../models/interfaces/organization-subscription";
import GroupService from "../../utilities/services/group-service";
import { GroupIds } from "../../models/enumerations/group-ids";
import MskScoreService from "../../utilities/services/msk-score-service";
import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import qrCodeImg from "../../assets/images/qr-code-mobile-stores.png";
import googlePlayImg from "../../assets/images/google-play-button.png";
import appStoreImg from "../../assets/images/app-store-button.png";
import Card from "../card/card";
import { CardTypes } from "../../models/enumerations/card-types";
import { Loader } from "../loader/loader";
import videoReplacementImg from "../../assets/images/results-page-video-replacement.png"
import QrCodeCard from "../subscription-links/qr-code-card";
import { Utils } from "../../utilities/utils";
import RiskFactorUtil from "../../utilities/risk-factor-util";
import AssessmentResponse from "../../models/interfaces/assessment-response.entity";
import { AssessmentUtils } from "../../utilities/assessments/assessment-utils";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { LanguageSelectorUserMenu } from "../language-selector-user-menu/language-selector-user-menu";
import AssessmentResultsHeader from "./assessment-results-header";

export interface AssessmentResultsProps {
    secondScreenVideoUrl?: string;
    secondScreenButtonText?: string;
    showYourWellnessProgramSectionWithoutLicense?: boolean;
    yourWellnessProgramText?: string;
}

const COMPONENT_CLASS = "c-assessment-results";
const SECOND_SCREEN_DEFAULT_VIDEO_URL = "";

const AssessmentResults: React.FunctionComponent<AssessmentResultsProps> = (props) => {
    const {
        secondScreenVideoUrl,
        secondScreenButtonText,
        showYourWellnessProgramSectionWithoutLicense,
        yourWellnessProgramText,
    } = props;

    const { state, setState } = useAuthState();
    const { t } = useTranslation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);

    const secondScreenVideoEmbed = AppContentUtil.getVideoEmbedCode(secondScreenVideoUrl ? secondScreenVideoUrl : SECOND_SCREEN_DEFAULT_VIDEO_URL);

    const [showMainScreen, setShowMainScreen] = useState(true);

    const [showAppContentModal, setShowAppContentModal] = useState(false);
    const [appContent, setAppContent] = useState("");
    const [appContentVideo, setAppContentVideo] = useState("");

    const [mskScore, setMskScore] = useState<MskScore>();
    const [riskChartPercentage, setRiskChartPercentage] = useState(0);
    const [latestAssessmentResponses, setLatestAssessmentResponses] = useState<AssessmentResponse[]>();
    const [lastAssessment, setLastAssessment] = useState<string>(t('misc.notAvailable'));
    const [lastAssessmentDateInDays, setLastAssessmentDateInDays] = useState<string>("");
    const [orgSub, setOrgSub] = useState<OrganizationSubscription>();
    const showChargeFailedBanner = state.user?.subscription?.hasChargeError;
    const [showQrCodeModal, setShowQrCodeModal] = useState(false);
    let joinNowButtonAction = () => history.push(`/signup/ath-payment`);
    let joinNowButtonText = t('buttons.btn_joinNow');

    if (state.user?.symmioAccess === SymmioAccessType.AppLicense) {
        joinNowButtonAction = () => setShowQrCodeModal(true);
        joinNowButtonText = t('buttons.btn_getStarted')
    }

    const [faqs, setFaqs] = useState([
        {
            question: t('assessmentResults.faq.question_0'),
            answer: t('assessmentResults.faq.answer_0'),
            isOpen: false
        },
        {
            question: t('assessmentResults.faq.question_1'),
            answer: t('assessmentResults.faq.answer_1'),
            isOpen: false
        },
        {
            question: t('assessmentResults.faq.question_2'),
            answer: t('assessmentResults.faq.answer_2'),
            isOpen: false
        },
        {
            question: t('assessmentResults.faq.question_3'),
            answer: t('assessmentResults.faq.answer_3'),
            isOpen: false
        },
        {
            question: t('assessmentResults.faq.question_4'),
            answer: t('assessmentResults.faq.answer_4'),
            isOpen: false
        }
    ]);

    const [showMentalHealthCard, setShowMentalHealthCard] = useState(false);
    const [showPainCard, setShowPainCard] = useState(false);

    let highRiskText = "";

    if (showPainCard && showMentalHealthCard) {
        highRiskText = t('assessmentResults.highRisk.painMental');
    }
    else if (showPainCard) {
        highRiskText = t('assessmentResults.highRisk.pain');
    }
    else if (showMentalHealthCard) {
        highRiskText = t('assessmentResults.highRisk.mental');
    }

    useEffect(() => {
        const getMskScore = async () => {
            try {
                if (state.user && state.user.id) {
                    const fetchedScore = await MskScoreService.getBy(
                        [
                            {
                                field: "userId",
                                operator: "==",
                                value: state.user.id,
                            },
                        ],
                        [
                            {
                                field: "created",
                                direction: "desc",
                            },
                        ],
                    )

                    if (fetchedScore && fetchedScore.length > 0) {
                        setMskScore(fetchedScore[0]);
                        setRiskChartPercentage(RiskFactorUtil.convertRiskFactorToDisplayPercent(fetchedScore[0].riskFactor?.percentage) || 0);

                        if (fetchedScore[0]?.created) {
                            setLastAssessment(Utils.formatDate(fetchedScore[0].created.toDate()));
                            setLastAssessmentDateInDays(Utils.getHowManyDaysAgo(fetchedScore[0].created.toDate()));
                        }
                    }
                }
            } catch (err) {
                console.error("Cannot fetch the Wellness Score");
            } finally {
                setIsLoading(false);
            }
        }

        if (!mskScore) {
            getMskScore();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user, state.user?.id]);

    useEffect(() => {
        const getAssessmentResponses = async () => {
            if (state.user?.id) {
                const assessmentResponses = await AssessmentUtils.fetchAssessmentResponsesById(state.user?.id);
                assessmentResponses && assessmentResponses.length > 0 ? setLatestAssessmentResponses(assessmentResponses) : setLatestAssessmentResponses(undefined);
            }
        }

        getAssessmentResponses();
    }, [state.user?.id])

    useEffect(() => {
        const getOrgSub = async () => {
            try {
                const fetchedOrgSubArray = await OrganizationSubscriptionService.getByOrganizationId(state.organization.id!);

                if (fetchedOrgSubArray && fetchedOrgSubArray.length > 0) {
                    setOrgSub(fetchedOrgSubArray[0]);
                }
            } catch (err) {
                console.error("Cannot fetch the organization subscription", err);
            }
        }

        getOrgSub();

    }, [state.organization.id])

    useEffect(() => {
        const checkIfAppContentHasVideo = async () => {
            const group = state.user?.groupId ? await GroupService.get(state.user.groupId) : null;
            let appContentVideoTemp = "";

            if (showMentalHealthCard && state.organization.appContent.BEHAVIORAL_HEALTH.videoUrl) {
                appContentVideoTemp = state.organization.appContent.BEHAVIORAL_HEALTH.videoUrl;
            }
            else if (showPainCard && state.organization.appContent.PAIN.videoUrl) {
                appContentVideoTemp = state.organization.appContent.PAIN.videoUrl;
            }
            else if (showMentalHealthCard && group?.appContent?.BEHAVIORAL_HEALTH.videoUrl) {
                appContentVideoTemp = group?.appContent?.BEHAVIORAL_HEALTH.videoUrl;
            }
            else if (showPainCard && group?.appContent?.PAIN.videoUrl) {
                appContentVideoTemp = group?.appContent?.PAIN.videoUrl;
            }

            setAppContentVideo(AppContentUtil.getVideoEmbedCode(appContentVideoTemp));
        }

        checkIfAppContentHasVideo();

    }, [showMentalHealthCard, showPainCard, state.organization.appContent.BEHAVIORAL_HEALTH.videoUrl, state.organization.appContent.PAIN.videoUrl, state.user?.groupId])

    useEffect(() => {
        const getAppContentEmbed = async () => {
            try {
                const embedCode = await AppContentUtil.composeAppContentForDisplay(state.user);

                if (embedCode) {
                    setAppContent(embedCode);
                }
            } catch (err) {
                console.error("Cannot fetch the High-Risk Recommendations");
            }
        }

        getAppContentEmbed();
    }, [state.user])

    useEffect(() => {
        const fetchUser = async () => {
            if (state.user && state.user.id) {
                const fetchedUser = await UserService2.get(state.user?.id);

                if (fetchedUser) {
                    setState((state) => ({
                        ...state, ...{ user: fetchedUser }
                    }));

                    const showMentalHealth = fetchedUser.importantMessages?.some(msg => msg.groupId === GroupIds.BEHAVIORAL_HEALTH) || false;
                    const showPain = fetchedUser.importantMessages?.some(msg => msg.groupId === GroupIds.PAIN) || false;

                    setShowMentalHealthCard(showMentalHealth);
                    setShowPainCard(showPain);
                }
            }
        }

        fetchUser();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getConnectedButton =
        <Button
            type="default"
            buttonText={t('buttons.btn_getConnected')}
            onClick={() => setShowAppContentModal(true)} />

    const openSecondScreenButton =
        <Button
            type="default"
            buttonText={secondScreenButtonText ? secondScreenButtonText : t('buttons.btn_startYourProgram')}
            onClick={() => {
                setShowMainScreen(false);
                window.scrollTo(0, 0);
            }} />

    const joinNowButton = (isBlue: boolean = false) => (
        <Button
            type="default"
            buttonText={joinNowButtonText}
            buttonStyle={isBlue ? "blue" : undefined}
            onClick={() => joinNowButtonAction()} />
    );
    return (
        isLoading
            ?
            <Loader isVisible={isLoading} />
            :
            <div className={`${COMPONENT_CLASS}`}>
                {mskScore && mskScore?.movementScore?.percentage != null && mskScore?.lifestyleScore?.percentage != null ?
                    <>
                        {appContent &&
                            <Modal
                                isOpen={showAppContentModal}
                                isLoading={false}
                                onClose={setShowAppContentModal}>
                                <div className={`${COMPONENT_CLASS}__video-container-modal`}>
                                    <div dangerouslySetInnerHTML={{ __html: appContent }}></div>
                                </div>
                            </Modal>
                        }

                        <Modal
                            isOpen={showQrCodeModal}
                            isLoading={false}
                            onClose={setShowQrCodeModal}
                        >
                            <div className="flex flex-col justify-center items-center text-center">
                                <div>
                                    <h1 className="text-center">{t('assessmentResults.qrCode_modal.title')}</h1>
                                </div>
                                <div className="w-4/5 flex flex-col gap-8 justify-center">
                                    <div className="text-sm font-light leading-6">
                                        {t('assessmentResults.qrCode_modal.text')}
                                    </div>
                                    <QrCodeCard email={state.user?.email} />
                                </div>
                            </div>
                        </Modal>
                        <div className={`${COMPONENT_CLASS}__user-menu-container`}>
                            {!showMainScreen &&
                                <div className={`${COMPONENT_CLASS}__back`}>
                                    <Button
                                        type="back"
                                        buttonText={t('buttons.btn_back')}
                                        buttonStyle="secondary"
                                        onClick={() => setShowMainScreen(true)} />
                                </div>
                            }

                            <div className="user-menu flex-grow">
                                <AssessmentsUserMenu profileImage={false} />

                                <LanguageSelectorUserMenu userPortal={true} />
                            </div>
                        </div>
                        {
                            showMainScreen
                                ?
                                <BaseCardLayout>
                                    <div className="w-full">
                                        <header className="pt-20 px-4 md:px-16">
                                            {showChargeFailedBanner && (
                                                <WarningItem
                                                    iconColor="#E7514F"
                                                    backgroundColor="#FFE9E5"
                                                    title={t('checkoutPage.paymentDeclined.banner.title')}
                                                    text={t('checkoutPage.paymentDeclined.banner.subText_subscription')}
                                                    buttonText={t('buttons.btn_updatePaymentDetails')}
                                                    onButtonClick={async () => await SubscriptionService.getCustomerPortalSession(state.user, orgSub, false)}
                                                />
                                            )}
                                            <h1 className="text-center">{t('misc.hi')} {state.user?.firstName ?? "..."}</h1>

                                            {state.user?.administeredRetake
                                                ?
                                                <>
                                                    <p className="text-center">
                                                        {t('assessmentResults.retest_modal.administeredRetake')}
                                                    </p>
                                                    <div className="flex justify-center mx-auto w-max max-w-full mt-6" style={{ minWidth: "66.667%" }}>
                                                        <Card
                                                            imageUrl="public/today/lifestyle-movement.png"
                                                            type={CardTypes.Retake}
                                                            title={t('assessmentResults.retest_modal.retake_title')}
                                                            duration={15}
                                                            grow={true}
                                                        />
                                                    </div>

                                                    <hr className="border-black-gray-5 h-px" style={{ margin: "26px 0" }} />

                                                    {mskScore && mskScore.created &&
                                                        <div className="text-center">
                                                            <h2>{t('assessmentResults.yourLastAssessment')}</h2>
                                                            <p>{moment(mskScore.created.seconds * 1000).format("MMM DD, YYYY")}</p>
                                                        </div>
                                                    }

                                                </>
                                                :
                                                <p className="text-center">
                                                    {t('assessmentResults.assessmentCompleted')}
                                                </p>
                                            }

                                            <AssessmentResultsHeader
                                                mskScore={mskScore}
                                                latestAssessmentResponses={latestAssessmentResponses}
                                                riskChartPercentage={riskChartPercentage}
                                                lastAssessment={lastAssessment}
                                                lastAssessmentDateInDays={lastAssessmentDateInDays}
                                            />
                                        </header>
                                        <main className="px-4 md:px-16">
                                            {(showMentalHealthCard || showPainCard) &&
                                                <div>
                                                    <div>
                                                        <h2 className="mt-6 mb-4">{t('assessmentResults.yourNextSteps')}</h2>
                                                        <div className={`${COMPONENT_CLASS}__video-section`}>
                                                            {appContentVideo &&
                                                                <div className="cursor-pointer" style={{ width: "300px", maxWidth: "100%" }} onClick={() => setShowAppContentModal(true)}>
                                                                    <div className={`${COMPONENT_CLASS}__video-container`}>
                                                                        <div dangerouslySetInnerHTML={{ __html: appContentVideo }}></div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className={`${COMPONENT_CLASS}__prof-consultation-text ${appContentVideo ? "" : "items-center text-center"}`}>
                                                                <h3 className="m-0">{t('assessmentResults.profConsultation')}</h3>
                                                                <p>
                                                                    {highRiskText}
                                                                </p>
                                                                {appContent &&
                                                                    <div className="mx-auto md:m-0">
                                                                        {getConnectedButton}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </main>
                                        <footer className="pb-20 px-4 md:px-16">
                                            {
                                                state.user && state.user.symmioAccess === SymmioAccessType.AppLicense
                                                    ?
                                                    <>
                                                        <h2 className="mt-6 mb-4">{t('assessmentResults.yourWellnessProgram')}</h2>
                                                        <div className={`${COMPONENT_CLASS}__wellness-section`}>
                                                            <div className="max-w-full w-80 flex flex-col justify-center items-center gap-5">
                                                                <img src={wellnessProgramImg} alt="Your Wellness Program" />
                                                                <div>
                                                                    {openSecondScreenButton}
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-col flex-1 justify-center">
                                                                <h3 className="m-0">{t('assessmentResults.startProfJourney')}</h3>
                                                                <p className="mt-2 mb-6">
                                                                    {yourWellnessProgramText
                                                                        ?
                                                                        yourWellnessProgramText
                                                                        :
                                                                        <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.accessGranted', { organizationName: state.organization.name }) }}>
                                                                        </span>
                                                                    }
                                                                </p>
                                                                <div className="bg-white rounded-xl py-4 px-6">
                                                                    <div className="flex flex-row gap-x-6 gap-y-6 items-center justify-center flex-wrap sm:flex-nowrap">
                                                                        <div style={{ minWidth: "76px", maxWidth: "76px", minHeight: "76px", maxHeight: "76px" }}>
                                                                            <img src={qrCodeImg} alt="QR Code" />
                                                                        </div>
                                                                        <div className="flex flex-col gap-3 justify-center items-center md:items-baseline">
                                                                            <div className="text-center text-xs font-light w-4/5">
                                                                                {t('assessmentResults.scanQRCode')}
                                                                            </div>

                                                                            <div className="flex flex-row gap-6">
                                                                                <a href="https://apps.apple.com/us/app/symmio/id1605214846" target="__blank"><img src={appStoreImg} alt="App Store" style={{ minWidth: "94px", maxWidth: "94px" }} /></a>
                                                                                <a href="https://play.google.com/store/apps/details?id=com.functionalmovementsystems.symmio&hl=en_CA&gl=US" target="__blank"><img src={googlePlayImg} alt="Google Play" style={{ minWidth: "103px", maxWidth: "103px" }} /></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="bg-white rounded-xl py-4 px-6 mt-6">
                                                                    <div className="text-sm text-center">
                                                                        {t('assessmentResults.loginWith')}
                                                                        <br />
                                                                        <span className="font-bold">{state.user.email}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {showYourWellnessProgramSectionWithoutLicense &&
                                                            <>
                                                                <h2 className="mt-6 mb-4">{t('assessmentResults.yourWellnessProgram')}</h2>
                                                                <div className={`${COMPONENT_CLASS}__wellness-section`}>
                                                                    <div className={`${COMPONENT_CLASS}__wellness-image-wrapper`}>
                                                                        <img src={wellnessProgramImg} alt="Your Wellness Program" />
                                                                    </div>
                                                                    <div className="flex flex-col flex-1 justify-center">
                                                                        <h3 className="m-0">{t('assessmentResults.startProfJourney')}</h3>
                                                                        <p className="mt-2 mb-6">
                                                                            {t('assessmentResults.tailoredProgram')}
                                                                        </p>
                                                                        <div className="bg-white rounded-xl py-4 px-6">
                                                                            <p><b>{t('assessmentResults.personalProgram')}</b></p>
                                                                            <div className="flex gap-2 my-3">
                                                                                <span className="flex items-center justify-center" style={{ width: "24px" }}><ExerciseIcon /></span><span className="text-sm"><strong>14</strong> {t('assessmentResults.correctiveExercises')}</span>
                                                                            </div>
                                                                            <div className="flex gap-2">
                                                                                <span className="flex items-center justify-center" style={{ width: "24px" }}><LightbulbIcon /></span><span className="text-sm"><strong>12</strong> {t('assessmentResults.educationalArticles')}</span>
                                                                            </div>
                                                                            <div className="mt-4">
                                                                                {openSecondScreenButton}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                            }

                                        </footer>
                                    </div>
                                </BaseCardLayout >
                                :
                                <>
                                    <BaseCardLayout>
                                        <div className="w-full">
                                            <div className="text-white py-16 px-4 md:px-16" style={{ backgroundColor: "#10151B", borderRadius: "40px 40px 0 0" }}>
                                                <div>
                                                    <img src={logoImage} alt="Symmio Logo" />
                                                </div>
                                                <div className="flex flex-wrap justify-between mt-4 items-center gap-8">
                                                    <div className={`${COMPONENT_CLASS}__header-companion`}>
                                                        <h1 className="text-white">{t('assessmentResults.wellnessCompanion')}</h1>
                                                        <p className="mb-4">
                                                            {t('assessmentResults.wellnessCompanion_desc')}
                                                        </p>
                                                        <div className="flex justify-center md:justify-start">
                                                            {joinNowButton(true)}
                                                        </div>
                                                    </div>
                                                    <div className={`${COMPONENT_CLASS}__video-companion`}>
                                                        {secondScreenVideoEmbed
                                                            ?
                                                            <div className={`${COMPONENT_CLASS}__video-wrapper`} dangerouslySetInnerHTML={{ __html: secondScreenVideoEmbed }}></div>
                                                            :
                                                            <img src={videoReplacementImg} alt="Symmio Mobile App" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-4 px-4 md:px-16 bg-black-gray-5 flex flex-col items-center text-center">
                                                <div className="font-medium">{t('assessmentResults.trustedBy')}</div>
                                                <div className={`${COMPONENT_CLASS}__fms-clients`}>
                                                    <div>
                                                        <img className="max-h-12" src={nflImg} alt="NFL" />
                                                    </div>
                                                    <div>
                                                        <img className="max-h-8" src={premierLeagueImg} alt="Premier League" />
                                                    </div>
                                                    <div>
                                                        <img className="max-h-5" src={equinoxImg} alt="Equinox" />
                                                    </div>
                                                    <div>
                                                        <img className="max-h-4" src={cardinalGlassImg} alt="Cardinal Glass Industries" />
                                                    </div>
                                                    <div>
                                                        <img className="max-h-8" src={mlbImg} alt="Major League Baseball" />
                                                    </div>
                                                    <div>
                                                        <img className="max-h-12" src={usArmyImg} alt="U.S. Army" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-16 px-4 md:px-16">
                                                <h2>{t('assessmentResults.startYourJourney')}</h2>
                                                <p className="text-center py-6">
                                                    {t('assessmentResults.startYourJourney_desc')}
                                                </p>
                                                <div className="flex flex-wrap justify-center md:justify-between gap-6">
                                                    <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                                        <div style={{ backgroundColor: "#ADDFFD" }}>
                                                            <span>
                                                                <MovementIcon style={{ backgroundColor: "#ADDFFD" }} />
                                                            </span>
                                                        </div>
                                                        <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.movementHealth') }}></span>
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                                        <div style={{ backgroundColor: "#8BE3CE" }}>
                                                            <span>
                                                                <BehavioralHealthIcon style={{ backgroundColor: "#8BE3CE" }} />
                                                            </span>
                                                        </div>
                                                        <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.behavioralHealth') }}></span>
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                                        <div style={{ backgroundColor: "#FFA998" }}>
                                                            <span>
                                                                <InjuryHistoryIcon style={{ backgroundColor: "#FFA998" }} />
                                                            </span>
                                                        </div>
                                                        <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.injuryHistory') }}></span>
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                                        <div style={{ backgroundColor: "#ADDFFD" }}>
                                                            <span>
                                                                <BreathingIcon style={{ backgroundColor: "#ADDFFD" }} />
                                                            </span>
                                                        </div>
                                                        <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.breathingQuality') }}></span>
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__focus-area-vertical`} >
                                                        <div style={{ backgroundColor: "#FFA998" }}>
                                                            <span>
                                                                <PhysicalActivityIcon style={{ backgroundColor: "#FFA998" }} />
                                                            </span>
                                                        </div>
                                                        <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.physicalActivity') }}></span>
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                                        <div style={{ backgroundColor: "#FFC79C" }}>
                                                            <span>
                                                                <BodyCompIcon style={{ backgroundColor: "#FFC79C" }} />
                                                            </span>
                                                        </div>
                                                        <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.bodyComposition') }}></span>
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                                        <div style={{ backgroundColor: "#CEBAF8" }}>
                                                            <span>
                                                                <SleepIcon style={{ backgroundColor: "#CEBAF8" }} />
                                                            </span>
                                                        </div>
                                                        <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.sleepWellness') }}></span>
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__focus-area-vertical`} >
                                                        <div style={{ backgroundColor: "#8BE3CE" }}>
                                                            <span>
                                                                <NutritionIcon style={{ backgroundColor: "#8BE3CE" }} />
                                                            </span>
                                                        </div>
                                                        <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.nutritionalAwareness') }}></span>
                                                    </div>
                                                </div>
                                                <div className="py-16">
                                                    <h2>{t('assessmentResults.howItWorks')}</h2>
                                                    <div className="flex flex-wrap gap-6 mt-6 justify-center">
                                                        <div className={`${COMPONENT_CLASS}__step`}>
                                                            <div className="flex justify-between items-center">
                                                                <div className={`${COMPONENT_CLASS}__card-number`}>1</div>
                                                                <div className={`${COMPONENT_CLASS}__badge`}>
                                                                    <CheckmarkIcon className="w-3 h-auto" />
                                                                    {t('assessmentResults.done')}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h3 className="mt-4 mb-2">{t('assessmentResults.completeAssessment')}</h3>
                                                                <p>
                                                                    {t('assessmentResults.completeAssessment_desc')}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className={`${COMPONENT_CLASS}__step`}>
                                                            <div className="flex justify-between items-center">
                                                                <div className={`${COMPONENT_CLASS}__card-number`}>2</div>
                                                            </div>
                                                            <div>
                                                                <h3 className="mt-4 mb-2">{t('assessmentResults.personalizedPlan')}</h3>
                                                                <p>
                                                                    {t('assessmentResults.personalizedPlan_desc')}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className={`${COMPONENT_CLASS}__step`}>
                                                            <div className="flex justify-between items-center">
                                                                <div className={`${COMPONENT_CLASS}__card-number`}>3</div>
                                                            </div>
                                                            <div>
                                                                <h3 className="mt-4 mb-2">{t('assessmentResults.trackProgress')}</h3>
                                                                <p>
                                                                    {t('assessmentResults.trackProgress_desc')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex justify-center items-center">
                                                    {joinNowButton(false)}
                                                </div>
                                                <div className="pt-16">
                                                    <h2>{t('assessmentResults.tailoredPlans')}</h2>
                                                    <p className="mt-2 mb-6 text-center">
                                                        {t('assessmentResults.tailoredPlans_desc')}
                                                    </p>

                                                    <div>
                                                        <div className="flex flex-wrap items-center gap-6 mb-16">
                                                            <div className={`${COMPONENT_CLASS}__card-image-wrapper`}>
                                                                <img className={`${COMPONENT_CLASS}__card-image`} src={todayImg} alt="Mobile App" />
                                                            </div>
                                                            <div className="flex flex-col gap-6 flex-1">
                                                                <div className="flex gap-5">
                                                                    <div style={{ minWidth: "2rem", maxWidth: "2rem" }}>
                                                                        <WellnessScoreIcon />
                                                                    </div>
                                                                    <div className="flex flex-col">
                                                                        <h3 className="mb-1">{t('assessmentResults.wellnessScore')}</h3>
                                                                        <p>
                                                                            {t('assessmentResults.wellnessScore_desc')}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="flex gap-5">
                                                                    <div style={{ minWidth: "2rem", maxWidth: "2rem" }}>
                                                                        <FocusAreaIcon />
                                                                    </div>
                                                                    <div className="flex flex-col">
                                                                        <h3 className="mb-1">{t('assessmentResults.areasOfFocus')}</h3>
                                                                        <p>
                                                                            {t('assessmentResults.areasOfFocus_desc')}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="flex gap-5">
                                                                    <div style={{ minWidth: "2rem", maxWidth: "2rem" }}>
                                                                        <ExerciseIcon />
                                                                    </div>
                                                                    <div className="flex flex-col">
                                                                        <h3 className="mb-1">{t('assessmentResults.correctiveExercises')}</h3>
                                                                        <p>
                                                                            {t('assessmentResults.correctiveExercises_desc')}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="flex gap-5">
                                                                    <div style={{ minWidth: "2rem", maxWidth: "2rem" }}>
                                                                        <LightbulbIcon />
                                                                    </div>
                                                                    <div className="flex flex-col">
                                                                        <h3 className="mb-1">{t('assessmentResults.educationTips')}</h3>
                                                                        <p>
                                                                            {t('assessmentResults.educationTips_desc')}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-wrap items-center gap-6">
                                                            <div className={`${COMPONENT_CLASS}__card-image-text`}>
                                                                <h2>{t('assessmentResults.research')}</h2>
                                                                <p className="mt-2 mb-6">
                                                                    {t('assessmentResults.research_desc')}
                                                                </p>
                                                                <div className="flex flex-col gap-4">
                                                                    <div className="flex gap-5">
                                                                        <div>
                                                                            <CheckmarkWhiteIcon className="rounded-full" style={{ backgroundColor: "#00DFBC", color: "#FFF" }} />
                                                                        </div>
                                                                        <p>
                                                                            {t('assessmentResults.correction')}
                                                                        </p>
                                                                    </div>

                                                                    <div className="flex gap-5">
                                                                        <div>
                                                                            <CheckmarkWhiteIcon className="rounded-full" style={{ backgroundColor: "#00DFBC", color: "#FFF" }} />
                                                                        </div>
                                                                        <p>
                                                                            {t('assessmentResults.healthBenefits')}
                                                                        </p>
                                                                    </div>

                                                                    <div className="flex gap-5">
                                                                        <div>
                                                                            <CheckmarkWhiteIcon className="rounded-full" style={{ backgroundColor: "#00DFBC", color: "#FFF" }} />
                                                                        </div>
                                                                        <p>
                                                                            {t('assessmentResults.reduceRisk')}
                                                                        </p>
                                                                    </div>

                                                                    <div className="flex gap-5">
                                                                        <div>
                                                                            <CheckmarkWhiteIcon className="rounded-full" style={{ backgroundColor: "#00DFBC", color: "#FFF" }} />
                                                                        </div>
                                                                        <p>
                                                                            {t('assessmentResults.balanceWellbeing')}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`${COMPONENT_CLASS}__card-image-wrapper`}>
                                                                <img className={`${COMPONENT_CLASS}__card-image`} src={assessmentImg} alt="Mobile App Assessments" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-10 px-4 md:px-16 flex flex-col gap-4 items-center justify-center text-center" style={{ backgroundColor: "#ADDFFD" }}>
                                                <h2>{t('assessmentResults.liveWithoutPain')}</h2>
                                                <p>{t('assessmentResults.liveWithoutPain_desc')}</p>
                                                <div>{joinNowButton(false)}</div>
                                            </div>
                                            <div className="py-16 px-4 md:px-16">
                                                <h2>{t('assessmentResults.reviews.title')}</h2>
                                                <p className="mt-2 mb-6 text-center">{t('assessmentResults.reviews.text')}</p>

                                                <div className="flex flex-wrap gap-6 justify-center">
                                                    <div className={`${COMPONENT_CLASS}__review`}>
                                                        <div>
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                        </div>
                                                        <p>
                                                            “{t('assessmentResults.reviews.review0_details')}”
                                                        </p>
                                                        <p style={{ fontSize: "14px" }}>
                                                            <b>{t('assessmentResults.reviews.review0_author')}</b>
                                                            <br />
                                                            {t('assessmentResults.reviews.symmioUser')}
                                                        </p>
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__review`}>
                                                        <div>
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                        </div>
                                                        <p>
                                                            “{t('assessmentResults.reviews.review1_details')}”
                                                        </p>
                                                        <p style={{ fontSize: "14px" }}>
                                                            <b>{t('assessmentResults.reviews.review1_author')}</b>
                                                            <br />
                                                            {t('assessmentResults.reviews.symmioUser')}
                                                        </p>
                                                    </div>

                                                    <div className={`${COMPONENT_CLASS}__review`}>
                                                        <div>
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                            <StarIcon sx={{ color: "#43B5F8" }} />
                                                        </div>
                                                        <p>
                                                            “{t('assessmentResults.reviews.review2_details')}”
                                                        </p>
                                                        <p style={{ fontSize: "14px" }}>
                                                            <b>{t('assessmentResults.reviews.review2_author')}</b>
                                                            <br />
                                                            {t('assessmentResults.reviews.symmioUser')}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-16 px-4 md:px-16">
                                                <h2 className="mb-6">{t('assessmentResults.faq.title')}</h2>
                                                <FaqAccordion faqs={faqs} setFaqs={setFaqs} />
                                            </div>
                                            <div style={{ borderRadius: "0 0 40px 40px" }} className="py-16 px-4 md:px-16 bg-blue-light flex flex-col gap-10 justify-center items-center">
                                                <h2>{t('assessmentResults.joinSymmio')}</h2>
                                                <div>
                                                    {joinNowButton(false)}
                                                </div>
                                            </div>
                                        </div>
                                    </BaseCardLayout >
                                </>
                        }
                    </>
                    :
                    <>
                        <div className={`${COMPONENT_CLASS}__user-menu-container`}>
                            <div className="user-menu flex-grow">
                                <AssessmentsUserMenu profileImage={false} />
                            </div>
                        </div>

                        <BaseCardLayout>
                            <div className="w-full">
                                <header className="pt-20 px-4 md:px-16">
                                    <h1 className="text-center">{t('misc.hi')} {state.user?.firstName ?? "..."}</h1>
                                    <p className="text-center">
                                        {t('assessmentResults.completeAssessments')}
                                    </p>
                                </header>

                                <main className="flex flex-wrap justify-center pt-20 pb-32 px-4 md:px-16">
                                    <Card
                                        completedMs={mskScore?.movementScore?.percentage != null ? 1 : undefined}
                                        imageUrl="public/today/movement-assessment.png"
                                        type={CardTypes.Movement}
                                        title={t('assessmentResults.completeMovement')}
                                        duration={15}
                                    />
                                    <Card
                                        completedMs={mskScore?.lifestyleScore?.percentage != null ? 1 : undefined}
                                        imageUrl="public/today/lifestyle-assessment.png"
                                        type={CardTypes.Lifestyle}
                                        title={t('assessmentResults.completeLifestyle')}
                                        duration={15}
                                    />
                                </main>

                                <footer className="border-t-black-gray-20 pt-12 pb-14 px-12 md:px-24" style={{ borderTopWidth: "1px" }}>
                                    <p className="mb-3">{t('assessmentResults.aboutMSK')}</p>
                                    <p>
                                        {t('assessmentResults.aboutMSK_desc')}
                                    </p>
                                </footer>
                            </div>
                        </BaseCardLayout >
                    </>
                }
            </div >
    );
};

export default AssessmentResults;