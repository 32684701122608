import symmioAcademyImg from "../../assets/images/resources/welcome-academy.jpg"
import marketingMaterialsImg from "../../assets/images/resources/marketing-materials.jpg"
import helpCenterImg from "../../assets/images/resources/help-center.jpg"
import wellnessCourseImg from "../../assets/images/resources/wellness-course.png"
import wellnessCourseLockedImg from "../../assets/images/resources/wellness-course-locked.png"
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { PlanType } from "../../models/enumerations/plan-type"
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router"

const COMPONENT_CLASS = "p-resources";

const ResourcesPage: React.FC = () => {
    const { state } = useAuthState();
    const { t } = useTranslation();
    const history = useHistory();

    const academyLink = state.user ? `https://functionalmovement.com/education/OnlineCourseSymmio/81?userId=${state.user.id}` : 'https://functionalmovement.com';

    const wellnessCourseLink = state.user ? `https://functionalmovement.com/education/onlinecoursesymmio?id=1090&userId=${state.user.id}` : 'https://functionalmovement.com';

    const getWellnessCourseCard = (image: string) => {
        return (
            <div className={`${COMPONENT_CLASS}__card`}>
                <div className={`${COMPONENT_CLASS}__image-wrapper`}>
                    <img src={image} alt="Functional Wellness Course" />
                </div>
                <div className="p-8">
                    <div className="font-bold text-xs uppercase mb-2">
                        {t('pages.resources.course')}
                    </div>
                    <div className="font-light text-sm leading-6">
                        {t('pages.resources.course_desc')}
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <div className={`${COMPONENT_CLASS}__header`}>
                <h1>{t('pages.resources.title')}</h1>
            </div>

            <div className="flex flex-wrap gap-5">
                <a className="max-w-full" href={academyLink} target="_blank" rel="noreferrer">
                    <div className={`${COMPONENT_CLASS}__card`}>
                        <div className={`${COMPONENT_CLASS}__image-wrapper`}>
                            <img src={symmioAcademyImg} alt="Symmio Academy" />
                        </div>
                        <div className="p-8">
                            <div className="font-bold text-xs uppercase mb-2">
                                {t('pages.resources.symmioAcademy')}
                            </div>
                            <div className="font-light text-sm leading-6">
                                {t('pages.resources.symmioAcademy_desc')}
                            </div>
                        </div>
                    </div>
                </a>

                <a className="max-w-full" href="https://www.symmio.com/marketing-resources" target="_blank" rel="noreferrer">
                    <div className={`${COMPONENT_CLASS}__card`}>
                        <div className={`${COMPONENT_CLASS}__image-wrapper`}>
                            <img src={marketingMaterialsImg} alt="Marketing Materials" />
                        </div>
                        <div className="p-8">
                            <div className="font-bold text-xs uppercase mb-2">
                                {t('pages.resources.marketing')}
                            </div>
                            <div className="font-light text-sm leading-6">
                                {t('pages.resources.marketing_desc')}
                            </div>
                        </div>
                    </div>
                </a>

                <a className="max-w-full" href="https://help.symmio.com/en/" target="_blank" rel="noreferrer">
                    <div className={`${COMPONENT_CLASS}__card`}>
                        <div className={`${COMPONENT_CLASS}__image-wrapper`}>
                            <img src={helpCenterImg} alt={t('pages.resources.helpCenter')} />
                        </div>
                        <div className="p-8">
                            <div className="font-bold text-xs uppercase mb-2">
                                {t('pages.resources.helpCenter')}
                            </div>
                            <div className="font-light text-sm leading-6">
                                {t('pages.resources.helpCenter_desc')}
                            </div>
                        </div>
                    </div>
                </a>

                {state.organization.plan?.value === PlanType.Starter
                    ?
                    <div onClick={() => history.push("settings/upgrade?open=true")} className="cursor-pointer">
                        {getWellnessCourseCard(wellnessCourseLockedImg)}
                    </div>
                    :
                    <a className="max-w-full" href={wellnessCourseLink} target="_blank" rel="noreferrer">
                        {getWellnessCourseCard(wellnessCourseImg)}
                    </a>
                }
            </div>

        </div>
    );
}

export default ResourcesPage;
