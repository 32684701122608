import { KeyboardEventHandler, FocusEventHandler, useState } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { ActionMeta } from "react-select/dist/declarations/src";
import { ListOptions } from "../../models/interfaces/list-options";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import searchClient, { SearchIndexes } from "../../utilities/search-client";
import { Utils } from "../../utilities/utils";
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "c-email-list";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface EmailListProps {
    enableSuggestions: boolean;
    existingUsers?: string[];
    onEmailsAdded: (emails: string[]) => void;
    onEmailsError: (errors: string[]) => void;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const EmailList: React.FC<EmailListProps> = (props: EmailListProps) => {
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState();
    const { state } = useAuthState();
    const { t } = useTranslation();

    const handleInputChange = (value: string) => {
        setInputValue(value);
    };

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (inputValue == null) {
            return;
        }
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                let invalidEmails: string[] = [];
                const val = inputValue
                    .split(/[,;\s]/)
                    .map((d: string) => d.replace(/[<>]/g, ''))
                    .filter((d: string) => d.trim().length > 0)
                    .filter((d: string) => {
                        if (!Utils.validateEmailAddress(d)) {
                            invalidEmails.push(" " + d);
                        }
                        return Utils.validateEmailAddress(d)
                    });
                props.onEmailsAdded(val);
                setInputValue('');
                setValue({} as any);

                if (invalidEmails.length > 0) {
                    props.onEmailsError([t('emailList.err_invalidEmails', { invalidEmails: invalidEmails.toString() })]);
                }

                event.preventDefault();
        }
    };

    const handleBlur: FocusEventHandler<HTMLDivElement> = (event) => {
        if (inputValue == null) {
            return;
        }

        let invalidEmails: string[] = [];
        const val = inputValue
            .split(/[,;\s]/)
            .map((d: string) => d.replace(/[<>]/g, ''))
            .filter((d: string) => d.trim().length > 0)
            .filter((d: string) => {
                if (!Utils.validateEmailAddress(d)) {
                    invalidEmails.push(" " + d);
                }
                return Utils.validateEmailAddress(d)
            });
        props.onEmailsAdded(val);
        setInputValue('');
        setValue({} as any);

        if (invalidEmails.length > 0) {
            props.onEmailsError([t('emailList.err_invalidEmails', { invalidEmails: invalidEmails.toString() })]);
        }

        event.preventDefault();
    };

    const searchIndex = searchClient.initIndex(SearchIndexes.usersByNameAsc);

    const onLoadOptions = (inputValue: string) =>
        new Promise<ListOptions[]>((resolve) => {
            const searchOptions = {
                filters: `organizationId:${state.organization?.id}`,
            } as any;
            if (props.existingUsers && props.existingUsers.length > 0) {
                searchOptions.filters += ` AND ${props.existingUsers.map(user => `NOT objectID:${user}`).join(' AND ')}`
            }
            searchIndex.search(inputValue, searchOptions).then((response) => {
                resolve(response.hits.map((h: any) => ({ value: h.objectID, label: `${h.authenticationId == null ? t('emailList.err_unregistered') : `${h.firstName || ""} ${h.lastName || ""}`} <${h.email}>` })));
            });
        });

    const handleChange = (
        newValue: any,
        actionMeta: ActionMeta<ListOptions>
    ) => {
        if (newValue && newValue.label) {
            const regex = /<(\S+@\S+\.\S+)>/;
            const match = newValue.label.match(regex);
            if (match && match.length > 0) {
                const email = match[1];
                if (Utils.validateEmailAddress(email)) {
                    props.onEmailsAdded([email]);
                    setInputValue('');
                    setValue({} as any);
                }
            }
        }
    };

    return (
        <div onPaste={(e: any) => {
            let invalidEmails: string[] = [];
            const emails = e.clipboardData
                .getData('text')
                .split(/[,;\s]/)
                .map((d: string) => d.replace(/[<>]/g, ''))
                .filter((d: string) => d.trim().length > 0)
                .filter((d: string) => {
                    if (!Utils.validateEmailAddress(d)) {
                        invalidEmails.push(" " + d);
                    }
                    return Utils.validateEmailAddress(d)
                });

            props.onEmailsAdded(emails);

            if (invalidEmails.length > 0) {
                props.onEmailsError([t('emailList.err_invalidEmails', { invalidEmails: invalidEmails.toString() })]);
            }

            e.preventDefault();
        }}>
            <AsyncCreatableSelect
                name="emails"
                classNamePrefix={COMPONENT_CLASS}
                components={{ DropdownIndicator: () => null }}
                placeholder={t('emailList.placeholder')}
                menuIsOpen={!props.enableSuggestions ? false : undefined}
                onInputChange={handleInputChange}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                inputValue={inputValue}
                loadOptions={onLoadOptions}
                onChange={handleChange}
                value={value}
                defaultOptions
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: "1px solid rgba(18, 22, 34, 0.20)",
                        borderRadius: "1.5rem",
                    }),
                }}
            />
        </div>

    );
}

export default EmailList;