import FirestoreCondition from "../../../models/interfaces/firestore-condition";
import { LifestyleAssessment } from "../../../models/interfaces/scores/lifestyle-assessment";
import { IUser } from "../../../models/interfaces/assessment-user";
import { FirebaseUtils } from "../../firebase-utils";
import FirestoreServiceAssessments from "../firestore-service-assessments";

const COLLECTION_NAME = "lifestyleAssessments";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Update a recent record, or add a new score to the collection
 * @param {LifestyleAssessment} score - The score that is being added to the collection
 * @param {interfaces.User} currentUser - The user that is logged in
 * @returns {Promise<IUser>} A promise to the newly added user
 */
const addOrUpdateRecent = async (
    score: LifestyleAssessment,
    currentUser: IUser
) => {
    // find recent record
    const groupIdCondition = FirebaseUtils.createFirebaseConditions(
        "groupId",
        "==",
        score.groupId
    );
    const recents = await FirestoreServiceAssessments.getRecent<LifestyleAssessment>(
        COLLECTION_NAME,
        currentUser,
        groupIdCondition
    );

    // overwrite existing record
    if (recents != null && recents.length >= 1) {
        const recent = recents[0];
        score.id = recent.id;
        score.created = recent.created;
        if (recent.createdBy != null) {
            score.createdBy = recent.createdBy;
        }
        return FirestoreServiceAssessments.update<LifestyleAssessment>(
            COLLECTION_NAME,
            score,
            currentUser
        );
    }

    // create record
    score.userId = currentUser.id!;
    return FirestoreServiceAssessments.add<LifestyleAssessment>(
        COLLECTION_NAME,
        score,
        currentUser
    );
};

/**
 * Update a recent record, or add a new score to the collection
 * @param {LifestyleAssessment} score - The score that is being added to the collection
 * @param {interfaces.User} currentUser - The user that is logged in
 * @returns {Promise<IUser>} A promise to the newly added user
 */
const addOrUpdateRecentByUserId = async (
    score: LifestyleAssessment,
    currentUserId: string
) => {
    // find recent record
    const groupIdCondition = FirebaseUtils.createFirebaseConditions(
        "groupId",
        "==",
        score.groupId
    );
    const recents = await FirestoreServiceAssessments.getRecentById<LifestyleAssessment>(
        COLLECTION_NAME,
        currentUserId,
        groupIdCondition
    );
    // overwrite existing record
    if (recents != null && recents.length >= 1) {
        const recent = recents[0];
        score.id = recent.id;
        score.created = recent.created;
        if (recent.createdBy != null) {
            score.createdBy = recent.createdBy;
        }
        return FirestoreServiceAssessments.updateById<LifestyleAssessment>(
            COLLECTION_NAME,
            score,
            currentUserId
        );
    }

    // create record
    score.userId = currentUserId
    return FirestoreServiceAssessments.addById<LifestyleAssessment>(
        COLLECTION_NAME,
        score,
        currentUserId
    );
};

/**
 * Delete a score record by the Id
 * @param {string} id - The Id of the user being deleted
 */
const deleteById = async (id: string) => {
    FirestoreServiceAssessments.deleteById(COLLECTION_NAME, id);
};

/**
 * Delete a score by the user
 * @param {string} id - The Id of the user being deleted
 */
const deleteByUser = async (userId: string) => {
    const records = await FirestoreServiceAssessments.getBy<LifestyleAssessment>(
        COLLECTION_NAME,
        FirebaseUtils.createFirebaseConditions("userId", "==", userId)
    );

    records.forEach((record) => {
        FirestoreServiceAssessments.deleteById(COLLECTION_NAME, record.id!);
    });
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<LifestyleAssessment>} A promise for the user we are retrieving
 */
const get = async (id: string) => {
    return FirestoreServiceAssessments.get<LifestyleAssessment>(COLLECTION_NAME, id);
};

/**
 * Find records matching the user
 * @param {string} userId - The Id of the user that we are retrieving
 * @returns {Promise<LifestyleAssessment[]>} A promise for the records we are retrieving
 */
const getByUserId = async (userId: string) => {
    return FirestoreServiceAssessments.getBy<LifestyleAssessment>(
        COLLECTION_NAME,
        FirebaseUtils.createFirebaseConditions("userId", "==", userId)
    );
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<IUser>} A promise for the user we are retrieving
 */
const getMostRecentByUserIdAndType = async (userId: string, type: string) => {
    const conditions: FirestoreCondition[] = [
        {
            field: "userId",
            operator: "==",
            value: userId,
        },
        {
            field: "groupId",
            operator: "==",
            value: type,
        },
    ];

    return FirestoreServiceAssessments.getBy<LifestyleAssessment>(
        COLLECTION_NAME,
        conditions,
        [
            {
                field: "created",
                direction: "desc",
            },
        ],
        2
    );
};

/**
 * Get all of the users stored in the database
 * @returns {Promise<IUser[]>} A promise for the collection of users
 */
const getAll = async () => {
    return FirestoreServiceAssessments.getAll<LifestyleAssessment>(COLLECTION_NAME);
};

// #endregion Service Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const LifestyleAssessmentService = {
    addOrUpdateRecent,
    addOrUpdateRecentByUserId,
    deleteById,
    deleteByUser,
    get,
    getByUserId,
    getMostRecentByUserIdAndType,
    getAll,
};

export default LifestyleAssessmentService;

// #endregion Exports
