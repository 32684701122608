import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { useTranslation } from 'react-i18next';

interface LoaderAssessmentsProps {
    isLoading: boolean;
    loaderText?: string;
}

const LoaderAssessments: React.FC<LoaderAssessmentsProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.isLoading}
        >
            <h4 style={{ marginRight: "5px" }}>{`${props.loaderText ? props.loaderText : t('misc.pleaseWait')}`}</h4>
            <CircularProgress color="inherit" />
        </Backdrop>
        // <IonLoading
        //     cssClass="my-custom-class"
        //     isOpen={props.isLoading}
        //     message={"Please wait..."}
        // />
    );
};

export default LoaderAssessments;
