import { useTranslation } from "react-i18next";
import GroupUtil from "../../utilities/group-util";

const COMPONENT_CLASS = "c-lifestyle-tips";

export interface LifestyleIntroProps {
    questionGroupNames?: string[];
}

const ScreenTests: React.FC<LifestyleIntroProps> = (props) => {
    const { t } = useTranslation();

    return (
        <div className={`${COMPONENT_CLASS} scroll-container`}>
            <div className={`${COMPONENT_CLASS}__header`}>
                <h3>{t('screenSlide.assessmentAreas')}</h3>
            </div>
            {props.questionGroupNames &&
                props.questionGroupNames.map((group, index) => (
                    <div className={`${COMPONENT_CLASS}__item`} key={index}>
                        {GroupUtil.getName(group)}
                    </div>
                ))}
        </div>
    );
};

export default ScreenTests;
