import { useState } from 'react';
import { useLocation, useHistory } from "react-router-dom"
import { useForm } from "react-hook-form";
import { TextTypeInput } from "../../components/forms";
import { getAuth, confirmPasswordReset } from "@firebase/auth";
import BaseCardLayout from "../../components/base-card-layout/base-card-layout";
import BaseCardLayoutActions from "../../components/base-card-layout/base-card-layout-actions";
import ErrorIcon from '@mui/icons-material/Error';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "p-reset-password";

export interface ResetPasswordPageProps {
}

const ResetPasswordPage: React.FunctionComponent<ResetPasswordPageProps> = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        reset,
    } = useForm();

    const useQuery = () => {
        const location = useLocation();
        return new URLSearchParams(location.search);
    }

    const query = useQuery();
    const { t } = useTranslation();

    const [, setSubmitting] = useState<boolean>(false);
    const [passwordMatch, setPasswordMatch] = useState<boolean>(true);
    const [isPasswordInputType, setIsPasswordInputType] = useState<boolean>(true);
    const history = useHistory();
    const email = query.get("email") || "";

    const visibilityIconStyles = {
        visibility: isPasswordInputType ? "visible" : "hidden",
        position: "absolute",
        marginTop: "11px",
        right: "18px",
        cursor: "pointer",
    }

    const visibilityOffIconStyles = {
        visibility: isPasswordInputType ? "hidden" : "visible",
        position: "absolute",
        marginTop: "11px",
        right: "18px",
        cursor: "pointer",
    }

    const passwordsMatch = (newPassword: string, confirmPassword: string) => {
        return newPassword.localeCompare(confirmPassword) === 0
    }

    const resetPassword = (newPassword: string) => {
        const auth = getAuth();
        const oobCode = query.get("oobCode") || "";
        return confirmPasswordReset(auth, oobCode, newPassword);
    }

    const handleError = (error: any) => {
        if (error?.code == null) {
            setError("formErrors", {
                type: "custom",
                message: t('forms.authErrors.custom')
            });
        }

        switch (error.code) {
            case "auth/weak-password":
                setError("formErrors", {
                    type: "custom",
                    message: t('baseCard.err_password_minChars')
                });
                break;
            case "auth/internal-error":
                setError("formErrors", {
                    type: "custom",
                    message: t('forms.authErrors.internal')
                });
                break;
            default:
                setError("formErrors", {
                    type: "custom",
                    message: t('forms.authErrors.custom')
                });
                break;
        }
    }

    const onSubmit = async (data: any) => {
        if (passwordsMatch(data.newPassword, data.confirmPassword)) {
            setPasswordMatch(true);
            setSubmitting(true);
            resetPassword(data.newPassword)
                .then((r: any) => {
                    reset();
                    setSubmitting(false);
                    enqueueSnackbar(t('manageAccount.passwordReset'), { variant: "toast", width: "450px", autoHideDuration: 5000 });
                    history.push("/login");
                })
                .catch((error: any) => {
                    setSubmitting(false);
                    handleError(error);
                });
        }
        else {
            setSubmitting(false);
            setPasswordMatch(false);
        }
    }

    return (
        <BaseCardLayout>
            <div className="flex justify-center" style={{ height: "550px" }}>
                <div className={`${COMPONENT_CLASS}`}>
                    {
                        !passwordMatch && (
                            <div className={`${COMPONENT_CLASS}__password-error`}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <ErrorIcon color="error"></ErrorIcon>
                                    <h4 style={{
                                        paddingTop: "3px",
                                        marginLeft: "5px"
                                    }}>{t('manageAccount.err_passwordMustMatch')}</h4>
                                </div>
                            </div>
                        )
                    }
                    <div className={`${COMPONENT_CLASS}__container`}>
                        <form className={"login-form"}
                            onSubmit={handleSubmit(onSubmit)}>
                            <h4 className={`${COMPONENT_CLASS}__title`} style={{ fontWeight: "700" }}>{t('pages.resetPassword.title')}</h4>
                            <p className={`${COMPONENT_CLASS}__title`} style={{ marginBottom: "50px", fontWeight: "300" }}>{t('pages.resetPassword.enterPassword', { email: email })}</p>
                            <div className={`${COMPONENT_CLASS}__field-container`} style={{ position: "relative" }}>
                                <VisibilityIcon sx={visibilityIconStyles} onClick={() => setIsPasswordInputType(false)} />
                                <VisibilityOffIcon sx={visibilityOffIconStyles} onClick={() => setIsPasswordInputType(true)} />

                                <TextTypeInput
                                    hideLabel
                                    id="newPassword"
                                    label={t('manageAccount.newPassword')}
                                    type={isPasswordInputType ? "password" : "text"}
                                    registerHook={register}
                                    errorState={errors.password}
                                    registerOptions={{ required: t('manageAccount.enterNewPassword') }}
                                    style={{ border: "2px solid #D0D0D3" }}
                                />

                                <TextTypeInput
                                    hideLabel
                                    id="confirmPassword"
                                    label={t('manageAccount.confirmNewPassword')}
                                    type={isPasswordInputType ? "password" : "text"}
                                    registerHook={register}
                                    errorState={errors.password}
                                    registerOptions={{ required: t('manageAccount.err_confirmNewPassword') }}
                                    style={{ border: "2px solid #D0D0D3" }}
                                />
                            </div>
                            <div {...register("formErrors")}>
                                {errors.formErrors &&
                                    <span className="error-style" role="alert">
                                        {`${errors.formErrors.message}`}
                                    </span>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: "1.8%", width: "100%" }}>
                <BaseCardLayoutActions backButton={false} submitButton={true} onSubmit={handleSubmit(onSubmit)} submitButtonText={t('buttons.btn_reset')}></BaseCardLayoutActions>
            </div>
        </BaseCardLayout>
    );
};

export default ResetPasswordPage;
