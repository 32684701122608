import React, { useState } from 'react';
import {
    BrowserRouter, Route, Switch
} from "react-router-dom";
import './App.css';
import AuthenticatedLayout from './layouts/authenticated-layout/authenticated-layout';
import DebugPage from './pages/debug-page';
import GenerateReportPage from './pages/generate-report-page/generate-report-page';
import LoginPage from './pages/login-page/login-page';
import ResetPasswordPage from './pages/reset-password-page/reset-password-page';
import ReportAssessmentTestPage from './pages/reports-page/report-assessment-test-page';
import ReportComplianceTestPage from './pages/reports-page/report-compliance-test-page';
import ReportDetailTestPage from './pages/reports-page/report-detail-test-page';
import ReportScoreTestPage from './pages/reports-page/report-score-test-page';
import ReportSummaryTestPage from './pages/reports-page/report-summary-test-page';
import { siteMap } from './sitemap';
import { AuthStateProvider } from './utilities/contexts/auth-state-context';
import Inactivity from './components/inactivity/inactivity';
import CreateAccountPage from './pages/create-account-page/create-account-page';
import CreateAccountConfirmationPage from './pages/create-account-page/created-account-confirmation';
import CreateAccountExpirationPage from './pages/create-account-page/create-account-expiration-page';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import Toast from './components/toast/toast';
// import AssessmentsHomeScreenPage from './pages/assessments-home-screen-page/assessments-home-screen-page';
import Movement from './pages/movement/movement';
import AccountSetup from './pages/account-setup/account-setup';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { enUS } from '@mui/x-date-pickers/locales';
import Lifestyle from './pages/lifestyle/lifestyle';
import AssessmentsAccountInformation from './pages/assessments-account-information/assessments-account-information';
import LandingPage from './pages/landing-page/landing-page';
import LeadCaptureResultsPage from './pages/lead-capture/lead-capture-results-page';
import LinkAccountCreation from './pages/link-account-creation/link-account-creation';
import Subscription from './components/subscription/subscription';
import { OktaLogin } from './components/okta-login/okta-login';
import DeactivatedAccountCheck from './components/deactivated-account-page/deactivated-account-check';
import DeactivatedAccountPage from './pages/deactivated-account-page/deactivated-account-page';
import { IntercomProvider } from 'react-use-intercom';
import IntercomAuthCheck from './components/intercom/intercom-auth-check';
import SubscriptionLinkResultsPage from './pages/subscription-link/subscription-link-results-page';
import SubscriptionLinkCheckoutPage from './pages/subscription-link/subscription-link-checkout-page';
import ManageAccount from './components/manage-account/manage-account';
import ManageSubscription from './components/manage-account/manage-subscription';
import ManageUserCancellation from './components/manage-account/manage-cancellation';
import AssessmentHomePage from './pages/assessments-home-screen-page/assessment-home-page';
import { ReactComponent as LoadingIcon } from "./assets/icons/solid/loading-icon.svg";
import { useTranslation } from 'react-i18next';


declare module 'notistack' {
    interface VariantOverrides {
        // adds `reportComplete` variant and specifies the
        // "extra" props it takes in options of `enqueueSnackbar`
        toast: {
            width: string
        }
    }
}




function App() {
    const { t } = useTranslation();
    const [inactivityPrompt, setInactivityPrompt] = useState(false);
    const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID ?? "";

    const hideInactivityPrompt = () => {
        if (inactivityPrompt) {
            setInactivityPrompt(false);
        }
    }

    const theme = createTheme(
        {
            palette: {
                primary: { main: '#121622' },
            },
        },
        enUS, // x-date-pickers translations
    );

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                Components={{ toast: Toast }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                autoHideDuration={10000}
                action={(snackbarId) => (
                    <button onClick={() => closeSnackbar(snackbarId)}>
                        {t('buttons.btn_dismiss')}
                    </button>
                )}
                iconVariant={{
                    info: <div className='mr-2'><LoadingIcon /></div>,
                }}
            >
                <BrowserRouter>
                    <AuthStateProvider>
                        <IntercomProvider appId={INTERCOM_APP_ID}>
                            <IntercomAuthCheck />
                            <Inactivity />
                            <DeactivatedAccountCheck />
                            <Switch>
                                <Route path="/okta-login">
                                    <OktaLogin />
                                </Route>
                                <Route path="/link-account-creation/:id/:type?">
                                    <LinkAccountCreation />
                                </Route>
                                {/* <Route path="/pricing">
                                <Subscription />
                            </Route> */}
                                <Route path="/pricing/:npeType?">
                                    <Subscription />
                                </Route>
                                <Route path="/manage-profile">
                                    <ManageAccount />
                                </Route>
                                <Route path="/plans-and-payment">
                                    <ManageSubscription />
                                </Route>
                                <Route path="/cancel-plan">
                                    <ManageUserCancellation />
                                </Route>
                                <Route path="/lc-pricing/:id?/:type?/:userId?/:npeType?">
                                    <Subscription />
                                </Route>
                                <Route path="/reactivate-subscription">
                                    <DeactivatedAccountPage />
                                </Route>
                                <Route path="/login">
                                    <LoginPage hideInactivityPrompt={hideInactivityPrompt} showInactivityPrompt={inactivityPrompt} />
                                </Route>
                                <Route path="/reset-password">
                                    <ResetPasswordPage />
                                </Route>
                                <Route path="/create-account">
                                    <CreateAccountPage />
                                </Route>
                                <Route path="/account-created">
                                    <CreateAccountConfirmationPage />
                                </Route>
                                <Route path="/account-information">
                                    <AssessmentsAccountInformation />
                                </Route>
                                <Route path="/account-creation-expired">
                                    <CreateAccountExpirationPage />
                                </Route>
                                <Route path="/wellness-assessment">
                                    <AssessmentHomePage />
                                </Route>
                                <Route path="/movement">
                                    <Movement />
                                </Route>
                                <Route path="/lifestyle">
                                    <Lifestyle />
                                </Route>
                                <Route path="/sign-up/:id">
                                    <LandingPage />
                                </Route>
                                <Route path="/lc-assessment-results">
                                    <LeadCaptureResultsPage />
                                </Route>
                                <Route path="/wellness-results">
                                    <SubscriptionLinkResultsPage />
                                </Route>
                                <Route path="/signup/ath-payment">
                                    <SubscriptionLinkCheckoutPage />
                                </Route>
                                <Route path="/setup">
                                    <AccountSetup />
                                </Route>
                                <Route path="/generate/report">
                                    <GenerateReportPage />
                                </Route>
                                <Route path="/report/assessment-test">
                                    <ReportAssessmentTestPage />
                                </Route>
                                <Route path="/report/compliance-test">
                                    <ReportComplianceTestPage />
                                </Route>
                                <Route path="/report/detail-test">
                                    <ReportDetailTestPage />
                                </Route>
                                <Route path="/report/score-test">
                                    <ReportScoreTestPage />
                                </Route>
                                <Route path="/report/summary-test">
                                    <ReportSummaryTestPage />
                                </Route>
                                <Route path="/debug">
                                    <DebugPage />
                                </Route>
                                {siteMap.map((route, index) => {
                                    let exact = true;

                                    // is our path a child page
                                    // ex: /settings/faq
                                    if (route.path.match(/(\/{1}.*){2}/g)) {
                                        exact = false;
                                    }

                                    return (
                                        <Route key={index} path={route.path} exact={exact}>
                                            <AuthenticatedLayout children={route.component} />
                                        </Route>
                                    );
                                })}
                            </Switch>
                        </IntercomProvider>
                    </AuthStateProvider>
                </BrowserRouter>
            </SnackbarProvider>
        </ThemeProvider >
    );
}

export default App;
