import { useTranslation } from "react-i18next";
import { QuestionGroupDescription } from "../../models/interfaces/questions/question-group-description";
import FirebaseStorage from "../../utilities/firebase-storage";

const COMPONENT_CLASS = "c-screen-intro";

export interface ScreenIntroProps {
    categoryId?: string;
    description?: QuestionGroupDescription;
    groupId?: string;
    title?: string;
    progressBarTotal?: number;
    progressBarCurrent?: number;
}

const ScreenIntro: React.FC<ScreenIntroProps> = (props) => {
    const storageUtil = new FirebaseStorage();
    const { t } = useTranslation();
    return (
        <div className={`${COMPONENT_CLASS} scroll-container`}>
            <div className={`${COMPONENT_CLASS}__image`}>
                <img
                    alt={props.title}
                    src={storageUtil.getMediaUrl(
                        `public/intro/intro_${props.groupId}.jpg`
                    )}
                />
            </div>

            <div className={`${COMPONENT_CLASS}__footer scroll-container`}>
                {
                    // if
                    props.progressBarTotal != null &&
                    props.progressBarTotal >= 2 && (
                        <h2>
                            {props.progressBarCurrent} /{" "}
                            {props.progressBarTotal}
                        </h2>
                    )
                }
                <h4>{props.title}</h4>
                {
                    // if
                    props.description?.content != null && (
                        <>
                            <h5>{t(props.description?.header)}:</h5>
                            <p>{t(props.description?.content)}</p>
                            {
                                // if
                                props.description?.note != null && (
                                    <div className={`${COMPONENT_CLASS}__note`}>
                                        {t(props.description.note)}
                                    </div>
                                )
                            }
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default ScreenIntro;
