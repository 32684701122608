/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import UserService from "../../utilities/services/user-service";
import BaseCardLayout from "../base-card-layout/base-card-layout";
import { User } from "../../models/interfaces/user";
import { enqueueSnackbar } from "notistack";
import { Redirect, useHistory } from "react-router";
import DateInputOption from "../account-information-date-input-option/account-information-date-input-option";
import dayjs from 'dayjs';
import { BiologicalSexOptions } from "../../models/biological-sex";
import Select from "react-select";
import { useParams } from "react-router";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { Button } from "../button/button";
import { AssessmentsUserMenu } from "../assessments-user-menu/assessments-user-menu";
import { useForm } from "react-hook-form";
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "@firebase/auth";
import ErrorIcon from '@mui/icons-material/Error';
import { ReactComponent as CircleIcon } from "../../assets/icons/outline/check-circle-icon.svg";
import { ReactComponent as CheckedIcon } from "../../assets/icons/outline/filled-check-circle-icon.svg";
import BaseCardLayoutActions from "../base-card-layout/base-card-layout-actions";
import { updateEmail } from "firebase/auth";
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "c-account-management";

interface ManageAccountParams {
  id: string;
}

const ManageAccount: React.FC = () => {
  const { id } = useParams<ManageAccountParams>();
  const [editUser, setEditUser] = useState<User>();
  const [isBirthdateValid, setIsBirthdateValid] = useState<boolean>(true);
  const [firstNameError, setFirstNameError] = useState(false);
  const [emailValidation, setEmailValidation] = useState('');
  const [lastNameError, setLastNameError] = useState(false);
  const { state, setState } = useAuthState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();
  const auth = getAuth();
  const [passwordMatch, setPasswordMatch] = useState<boolean>(true);
  const [, setSubmitting] = useState<boolean>(false);
  const [passwordValidation, setPasswordValidation] = useState({ length: false, uppercase: false, lowercase: false, number: false, special: false });
  const history = useHistory();
  const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
  const { t } = useTranslation();

  useEffect(() => {
    if (state.user && state.user.id) {
      UserService.getSnapshot(state.user.id, (u: User) => {
        setEditUser(u);
        setIsBirthdateValid(dayjs(u.birthdate).isValid());
      });
    }
  }, [id]);

  const saveUser = async () => {
    if (!editUser) {
      return;
    }

    let test = validate();
    if (test) {
      return;
    }



    try {
      if (editUser == null || firstNameError || lastNameError || emailValidation !== '') {
        return;
      }

      if (editUser.email !== state.user?.email) {
        const emailExists = await UserService.getBy([{
          field: 'email',
          operator: '==',
          value: editUser.email,
        }]);

        if (emailExists.length > 0) {
          setEmailValidation("Email already exists.");
          return;
        } else {
          if (!auth.currentUser || !auth.currentUser.email || !editUser.email) return;

          await updateEmail(auth.currentUser, editUser.email).then(() => {

          }).catch((error: any) => {
            setEmailValidation("Something went wrong.");
            return;
          });
          const customer = await stripe.customers.list({
            limit: 1,
            email: `${state.user?.email}`,
          })
          if (customer.data.length > 0) {
            await stripe.customers.update(customer.data[0].id,
              {
                email: `${editUser.email}`
              }
            );
          }
        }

      }

      await UserService.update(editUser, state.user).then((result) => {
        if (result) {
          setState((state) => ({
            ...state, ...{ user: result }
          }));
        }
      });

    } catch (err: any) {
      // TODO : Global exception handling
      // https://app.clickup.com/t/2219993/FMS-1236
      console.error(err);
      enqueueSnackbar(t('manageAccount.err_savingUser'));
    }

    enqueueSnackbar(t('manageAccount.profileUpdated'), { variant: "toast", width: "450px" });
  };

  const validate = () => {
    let errors = false;
    setFirstNameError(editUser?.firstName?.trim().length === 0);
    setLastNameError(editUser?.lastName?.trim().length === 0);
    setEmailValidation(editUser?.email?.trim().length === 0 ? 'Email is required.' : '');

    if (editUser?.firstName?.trim().length === 0 ||
      editUser?.lastName?.trim().length === 0 || editUser?.email?.trim().length === 0) {
      errors = true;
    }

    return errors;
  }

  const iconStyles = {
    width: '22px', height: '22px', marginRight: '1em'
  };

  if (state.user && !state.user.isAccountSetup) {
    return <Redirect to="/setup" />;
  }
  if (!state.authenticated) {
    return <Redirect to="/login" />;
  }
  const handleBirthdateChange = (date: string) => {
    setEditUser(Object.assign({}, editUser, { birthdate: dayjs(date).format('YYYY-MM-DD') }));
    setIsBirthdateValid(true);
  }

  const passwordsMatch = (newPassword: string, confirmPassword: string) => {
    return newPassword.localeCompare(confirmPassword) === 0
  }
  function handlePasswordValidation(event: any): void {
    const value = event.target.value;
    setPasswordValidation({
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /\d/.test(value),
      // eslint-disable-next-line no-useless-escape
      special: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(value)
    });
  }

  function handleEmailValidation(e: any): void {
    const value = e.target.value;
    // eslint-disable-next-line no-useless-escape
    const check = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    setEmailValidation(check ? '' : 'Invalid email.');
  }

  const handlePasswordError = (error: any) => {
    if (error?.code == null) {
      setError("formErrors", {
        type: "custom",
        message: "Something went wrong. We are working on fixing the problem. Please try again later."
      });
    }

    switch (error.code) {
      case "auth/internal-error":
        setError("formErrors", {
          type: "custom",
          message: "An error occurred. Please try again later."
        });
        break;
      case "auth/wrong-password":
        setError("formErrors", {
          type: "custom",
          message: "Invalid current password."
        });
        break;
      default:
        setError("formErrors", {
          type: "custom",
          message: "Something went wrong. We are working on fixing the problem. Please try again later."
        });
        break;
    }
  }

  const handleSignoutClick = () => {
    auth.signOut();
    setState((state) => ({ ...state, ...{ authenticated: false, user: null } }));
  }

  const onSubmit = async (data: any) => {
    if (passwordsMatch(data.newPassword, data.confirmPassword)) {
      setPasswordMatch(true);
      setSubmitting(true);
      if (!auth.currentUser || !auth.currentUser.email) return;
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        data.currentPassword
      );
      let authenticated = true;
      await reauthenticateWithCredential(auth.currentUser, credential).catch((error: any) => {
        setSubmitting(false);
        console.log(error);
        authenticated = false;
        handlePasswordError(error);
        return;
      });
      if (authenticated) {
        await updatePassword(auth.currentUser, data.newPassword)
          .then((r: any) => {
            reset();
            setSubmitting(false);
            enqueueSnackbar(t('manageAccount.passwordReset'), { variant: "toast", width: "450px", autoHideDuration: 5000 });
            handleSignoutClick();
          })
          .catch((error: any) => {
            setSubmitting(false);
            handlePasswordError(error);
          });
      }
    }
    else {
      setSubmitting(false);
      setPasswordMatch(false);
    }
  }

  return (
    <>
      <div className={`user-menu`} style={{}}>
        <AssessmentsUserMenu profileImage={false} />
      </div>
      <BaseCardLayout>

        <div className={`${COMPONENT_CLASS}`}>

          <div className={`${COMPONENT_CLASS}__wrapper`}>
            <div style={{ marginTop: '2rem' }} className={`c-subscriptions__header`}>
              {t('manageAccount.account')}
            </div>
            <div className={`${COMPONENT_CLASS}__content`}>
              <div className={`${COMPONENT_CLASS}__container`} style={{ padding: '32px' }}>
                <div className={`${COMPONENT_CLASS}__title`}>{t('manageAccount.personalDetails')}</div>
                <div className={`${COMPONENT_CLASS}__form__edit`}>
                  <div>
                    <label htmlFor="email">Email</label>
                    {emailValidation &&
                      <div className={`${COMPONENT_CLASS}__error-message`}>
                        {emailValidation}
                      </div>
                    }
                    <input
                      id="email"
                      type="text"
                      placeholder={t('manageAccount.email')}
                      value={editUser?.email}
                      className={emailValidation ? `${COMPONENT_CLASS}__error_input` : ''}
                      onChange={(e) => { handleEmailValidation(e); setEditUser(Object.assign({}, editUser, { email: e.target.value })) }} />
                  </div>
                </div>
                <div className={`${COMPONENT_CLASS}__form__edit`}>
                  <div>
                    <label htmlFor="firstName">{t('manageAccount.firstName')}</label>
                    {firstNameError &&
                      <div className={`${COMPONENT_CLASS}__error-message`}>
                        {t('manageAccount.firstName_req')}
                      </div>
                    }
                    <input
                      id="firstName"
                      type="text"
                      placeholder={t('manageAccount.firstName')}
                      className={firstNameError ? `${COMPONENT_CLASS}__error_input` : ''}
                      value={editUser?.firstName}
                      onChange={(e) => setEditUser(Object.assign({}, editUser, { firstName: e.target.value }))} />
                  </div>
                </div>
                <div className={`${COMPONENT_CLASS}__form__edit`}>
                  <div>
                    <label htmlFor="lastName">{t('manageAccount.lastName')}</label>
                    {lastNameError &&
                      <div className={`${COMPONENT_CLASS}__error-message`}>
                        {t('manageAccount.lastName_req')}
                      </div>
                    }
                    <input
                      id="lastName"
                      type="text"
                      placeholder={t('manageAccount.lastName')}
                      value={editUser?.lastName}
                      className={lastNameError ? `${COMPONENT_CLASS}__error_input` : ''}
                      onChange={(e) => setEditUser(Object.assign({}, editUser, { lastName: e.target.value }))} />
                  </div>
                </div>
                <div className={`${COMPONENT_CLASS}__form__edit`}>
                  <div>
                    <label htmlFor="biologicalSex">{t('manageAccount.biological')}</label>
                    <Select
                      id="biologicalSex"
                      name="biologicalSex"
                      classNamePrefix={`${COMPONENT_CLASS}`}
                      placeholder={t('manageAccount.biological')}
                      options={BiologicalSexOptions}
                      onChange={(newValue: any, actionMeta: any) => setEditUser(Object.assign({}, editUser, { biologicalSex: newValue.value }))}
                      value={BiologicalSexOptions.find(o => o.value === editUser?.biologicalSex)}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className={`${COMPONENT_CLASS}__form__edit`}>
                  <div>
                    <label htmlFor="birthdate">{t('manageAccount.birthdate')}</label>
                    {!isBirthdateValid &&
                      <div className={`${COMPONENT_CLASS}__error-message`}>
                        {t('manageAccount.err_birtdateNotValid')}
                      </div>
                    }
                    <DateInputOption
                      style={{
                        fontSize: '13px',
                        paddingTop: '0rem',
                        borderRadius: "10px",
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '1.5rem',
                          width: '100%',
                          backgroundColor: 'white',
                          height: '41.6px',
                          fontFamily: 'pragmatica-extended, sans-serif',
                          '& fieldset': {
                            border: '1px solid rgba(18, 22, 34, 0.20)',
                          },
                        },
                      }}
                      answer={dayjs(editUser?.birthdate)} onChange={handleBirthdateChange} onInvalidDate={() => setIsBirthdateValid(false)} ></DateInputOption>
                  </div>
                </div>
                <div>
                  <Button
                    disabled={!isBirthdateValid}
                    buttonText="Save Changes"
                    type="default"
                    style={{
                      fontSize: '14px',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      height: '2.25rem',
                    }}
                    onClick={() => saveUser()}
                  />
                </div>
              </div>
              <div className={`${COMPONENT_CLASS}__container`}>
                {
                  !passwordMatch && (
                    <div className={`${COMPONENT_CLASS}__password-error`}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <ErrorIcon color="error"></ErrorIcon>
                        <h4 style={{
                          paddingTop: "3px",
                          marginLeft: "5px"
                        }}>{t('manageAccount.err_passwordMustMatch')}</h4>
                      </div>
                    </div>
                  )
                }
                <form className={`${COMPONENT_CLASS}__password-form`}
                  onSubmit={handleSubmit(onSubmit)}>
                  <div style={{ paddingTop: '32px', paddingLeft: '32px', paddingRight: '32px' }}>
                    <div className={`${COMPONENT_CLASS}__title`}>{t('manageAccount.password')}</div>
                    <div className={`${COMPONENT_CLASS}__form__edit`}>
                      {errors.currentPassword && (
                        <div role="alert" className={`${COMPONENT_CLASS} -error`}>
                          {t('manageAccount.confirmPassword')}
                        </div>
                      )}
                      <div>
                        <label className={`${COMPONENT_CLASS}__password-form_label`} htmlFor="currentPassword">{t('manageAccount.currentPassword')}</label>
                        <input placeholder={t('manageAccount.currentPassword')}
                          id="currentPassword"
                          className={`${COMPONENT_CLASS}__password-form_input`}
                          type="password"
                          {...register("currentPassword", {
                            required: true,
                          })}
                        />
                      </div>

                    </div>
                    <div className={`${COMPONENT_CLASS}__form__edit`}>
                      {errors.newPassword && (
                        <div role="alert" className={`${COMPONENT_CLASS} -error`}>
                          {t('manageAccount.enterNewPassword')}
                        </div>
                      )}
                      <div>
                        <label className={`${COMPONENT_CLASS}__password-form_label`} htmlFor="newPassword">{t('manageAccount.newPassword')}</label>
                        <input placeholder={t('manageAccount.newPassword')} className={`${COMPONENT_CLASS}__password-form_input`}
                          type="password"
                          {...register("newPassword", {
                            required: true,
                            onChange: (e) => handlePasswordValidation(e),
                            // eslint-disable-next-line no-useless-escape
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\-]{8,}$/
                          })}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="px-4">
                    {t('forms.passwordTitle')}
                    <div className={`${COMPONENT_CLASS}__password_container`}>
                      <div style={{ width: '50%' }}>
                        <div className={`${COMPONENT_CLASS}_item`}>
                          <span>
                            {passwordValidation.length ? <CheckedIcon style={iconStyles} /> : <CircleIcon style={iconStyles} />}
                          </span>
                          {t('forms.8chars')}
                        </div>
                        <div className={`${COMPONENT_CLASS}_item`}>
                          <span>
                            {passwordValidation.lowercase ? <CheckedIcon style={iconStyles} /> : <CircleIcon style={iconStyles} />}
                          </span>
                          {t('forms.1lower')} </div>
                        <div className={`${COMPONENT_CLASS}_item`}>
                          <span>
                            {passwordValidation.uppercase ? <CheckedIcon style={iconStyles} /> : <CircleIcon style={iconStyles} />}
                          </span>
                          {t('forms.1upper')} </div>
                      </div>
                      <div style={{ width: '50%' }}>
                        <div className={`${COMPONENT_CLASS}_item`}>
                          <span>
                            {passwordValidation.number ? <CheckedIcon style={iconStyles} /> : <CircleIcon style={iconStyles} />}
                          </span>
                          {t('forms.1number')} </div>
                        <div className={`${COMPONENT_CLASS}_item`}>
                          <span>
                            {passwordValidation.special ? <CheckedIcon style={iconStyles} /> : <CircleIcon style={iconStyles} />}
                          </span>
                          {t('forms.1special')} </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-8" style={{ paddingTop: '16px' }}>
                    <div className={`${COMPONENT_CLASS}__form__edit`}>
                      {errors.confirmPassword && (
                        <div role="alert" className={`${COMPONENT_CLASS} -error`}>
                          {t('manageAccount.err_confirmNewPassword')}
                        </div>
                      )}
                      <div>
                        <label className={`${COMPONENT_CLASS}__password-form_label`} htmlFor="confirmPassword">{t('manageAccount.confirmNewPassword')}</label>
                        <input placeholder={t('manageAccount.confirmNewPassword')} className={`${COMPONENT_CLASS}__password-form_input`}
                          type="password"
                          {...register("confirmPassword", {
                            required: true,
                            // onChange: (e) => handlePasswordValidation(e),
                            // eslint-disable-next-line no-useless-escape
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\-]{8,}$/
                          })}
                        />
                      </div>

                    </div>
                    <div {...register("formErrors")}>
                      {errors.formErrors &&
                        <span className="error-style" role="alert">
                          {`${errors.formErrors.message}`}
                        </span>
                      }
                    </div>
                  </div>

                </form>
                <div className={`${COMPONENT_CLASS}__actions`}>
                  <Button
                    buttonText={t('buttons.btn_changePassword')}
                    type="default"
                    style={{
                      fontSize: '14px',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      height: '2.25rem',
                    }}
                    onClick={handleSubmit(onSubmit)}
                  />
                </div>
              </div>
            </div>
            <BaseCardLayoutActions
              backButton={true}
              onBack={() => history.push("/wellness-results")}
            />
          </div>

        </div >
      </BaseCardLayout >
    </>
  );
}

export default ManageAccount;