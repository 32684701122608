import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle"
import { useTranslation } from 'react-i18next';

SwiperCore.use([Pagination, Navigation]);

const COMPONENT_CLASS = "c-movement-tips";

const MovementTips: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <div className={`${COMPONENT_CLASS}__header`}>{t('movementTips.title')}</div>
            <Swiper
                spaceBetween={-100}
                centeredSlides={true}
                pagination={{ clickable: true }}
                navigation
                slidesPerView={1}>
                <SwiperSlide className={`${COMPONENT_CLASS}__slide`}>
                    <div className={`${COMPONENT_CLASS}__slide-text`}>
                        {t('movementTips.tip1')}
                    </div>
                </SwiperSlide>
                <SwiperSlide className={`${COMPONENT_CLASS}__slide`}>
                    <div className={`${COMPONENT_CLASS}__slide-text`}>
                        {t('movementTips.tip2')}
                    </div>
                </SwiperSlide>
                <SwiperSlide className={`${COMPONENT_CLASS}__slide`}>
                    <div className={`${COMPONENT_CLASS}__slide-text`}>
                        {t('movementTips.tip3')}
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default MovementTips;
