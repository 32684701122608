import { MskScore } from "../models/interfaces/scores/msk-score";
import DataAssessments from "../models/interfaces/data-assessments";
import { GroupIds } from "../models/enumerations/group-ids";
import { UserCorrectiveExercise } from "../models/interfaces/corrective-exercises/user-corrective-exercise";
import { UserMskFocusArticles } from "../models/interfaces/articles/user-msk-focus-articles";
import { LifestyleInjuryHistoryScore } from "../models/interfaces/scores/lifestyle-injury-history-score";
import DataAssessment from "../models/interfaces/data-assessment";
import { ResultLevel } from "../models/enumerations/result-level";
import GroupUtil from "./group-util";
import { MovementScore } from "../models/interfaces/scores/movement-score";
import { NumberUtils } from "./number-utils";
import { User } from "../models/interfaces/user";
import i18n from "../i18n";

export default class DataAssessmentsUtil {
    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public static hasMovementPain(movementScore?: MovementScore): boolean {
        if (movementScore == null) {
            return false;
        }
        return (
            movementScore.balanceScore?.score === 0 ||
            movementScore.rotationScore?.score === 0 ||
            movementScore.shoulderMobilityScore?.score === 0 ||
            movementScore.squatScore?.score === 0 ||
            movementScore.toeTouchScore?.score === 0
        );
    }

    public static create(
        mskScore?: MskScore,
        userCorrective?: UserCorrectiveExercise | null,
        userArticles?: UserMskFocusArticles | null,
        user?: User
    ): DataAssessments {
        const hasPartialLifestyle =
            mskScore?.lifestyleScore?.percentage == null &&
            (mskScore?.lifestyleScore?.behavioralScore != null ||
                mskScore?.lifestyleScore?.bodyCompositionScore != null ||
                mskScore?.lifestyleScore?.breathingScore != null ||
                mskScore?.lifestyleScore?.injuryHistoryScore != null ||
                mskScore?.lifestyleScore?.nutritionScore != null ||
                mskScore?.lifestyleScore?.physicalActivityScore != null ||
                mskScore?.lifestyleScore?.sleepScore != null);
        const result: DataAssessments = {
            finishMovement: mskScore?.movementScore?.percentage == null,
            finishLifestyle:
                !hasPartialLifestyle &&
                mskScore?.lifestyleScore?.percentage == null,
            hasPartialLifestyle: hasPartialLifestyle,
            focusAssessments: [],
            otherAssessments: [],
        };
        if (mskScore == null) {
            return {
                finishMovement: result.finishMovement,
                finishLifestyle: result.finishLifestyle,
                hasPartialLifestyle: result.hasPartialLifestyle,
                focusAssessments: [],
                otherAssessments: [
                    DataAssessmentsUtil.getNoDataAssessment(
                        GroupIds.MOVEMENT_HEALTH
                    ),
                    DataAssessmentsUtil.getNoDataAssessment(
                        GroupIds.BODY_COMPOSITION
                    ),
                    DataAssessmentsUtil.getNoDataAssessment(
                        GroupIds.PHYSICAL_ACTIVITY
                    ),
                    DataAssessmentsUtil.getNoDataAssessment(
                        GroupIds.INJURY_HISTORY
                    ),
                    DataAssessmentsUtil.getNoDataAssessment(
                        GroupIds.BEHAVIORAL_HEALTH
                    ),
                    DataAssessmentsUtil.getNoDataAssessment(GroupIds.SLEEP),
                    DataAssessmentsUtil.getNoDataAssessment(GroupIds.NUTRITION),
                    DataAssessmentsUtil.getNoDataAssessment(GroupIds.BREATHING),
                ],
            };
        }

        // movement
        if (mskScore.movementScore?.percentage == null) {
            result.otherAssessments.push(
                DataAssessmentsUtil.getNoDataAssessment(
                    GroupIds.MOVEMENT_HEALTH
                )
            );
        } else {
            const groupId = GroupIds.MOVEMENT_HEALTH;
            const movement = mskScore.movementScore;
            const hasPain = this.hasMovementPain(movement);
            const articles = userArticles?.focusStatuses?.find(
                (x) => x.mskFocusAreaGroupId === groupId
            );
            const articleRetestDays =
                articles == null
                    ? 0
                    : articles.numArticles -
                    (articles.completedArticles || []).length;
            const correctiveRetestDays =
                userCorrective == null
                    ? 0
                    : userCorrective.routineDays -
                    (userCorrective.completedDateMs || []).length;
            const completedNumerator =
                (articles?.completedArticles == null
                    ? 0
                    : articles.completedArticles.length) +
                (userCorrective?.completedDateMs == null
                    ? 0
                    : userCorrective.completedDateMs.length);
            const completedDenominator =
                (articles?.numArticles == null ? 0 : articles.numArticles) +
                (userCorrective?.routineDays == null
                    ? 0
                    : userCorrective.routineDays);

            const assessment: DataAssessment = {
                averageDetails: DataAssessmentsUtil.getAverageDetails(
                    groupId,
                    mskScore,
                    user
                ),
                completedPercent:
                    completedDenominator === 0
                        ? 0
                        : Math.round(
                            (100 * completedNumerator) / completedDenominator
                        ),
                groupId: groupId,
                hasPain: hasPain,
                isLow: mskScore.movementScore?.resultLevel === ResultLevel.Low,
                movementType: mskScore.movementScore?.focusArea?.groupName,
                name: "Movement Health",
                percentAvg: DataAssessmentsUtil.getAveragePercent(
                    groupId,
                    mskScore
                ),
                percentChange: 0,
                percentCurrent: mskScore.movementScore?.percentage,
                percentPrevious: undefined,
                resultDescription: mskScore.movementScore?.resultDescription,
                retestDays: NumberUtils.highestNumber([
                    articleRetestDays,
                    correctiveRetestDays,
                ]),
            };

            const isMskFocus =
                mskScore.focusAreas != null &&
                mskScore.focusAreas.find((x) => x.groupId === GroupIds.MOVEMENT_HEALTH) != null;
            if (isMskFocus) {
                result.focusAssessments.push(assessment);
            } else {
                result.otherAssessments.push(assessment);
            }
        }

        // lifestyle
        const lifestyle = mskScore?.lifestyleScore;
        const lifestyleScores: any[] = [
            {
                groupId: GroupIds.BODY_COMPOSITION,
                score: lifestyle?.bodyCompositionScore,
            },
            {
                groupId: GroupIds.PHYSICAL_ACTIVITY,
                score: lifestyle?.physicalActivityScore,
            },
            {
                groupId: GroupIds.INJURY_HISTORY,
                score: lifestyle?.injuryHistoryScore,
            },
            {
                groupId: GroupIds.BEHAVIORAL_HEALTH,
                score: lifestyle?.behavioralScore,
            },
            { groupId: GroupIds.SLEEP, score: lifestyle?.sleepScore },
            { groupId: GroupIds.NUTRITION, score: lifestyle?.nutritionScore },
            { groupId: GroupIds.BREATHING, score: lifestyle?.breathingScore },
        ];
        for (let i = 0; i < lifestyleScores.length; i++) {
            const score = lifestyleScores[i].score;
            if (score == null) {
                const groupId = lifestyleScores[i].groupId;
                result.otherAssessments.push(
                    DataAssessmentsUtil.getNoDataAssessment(groupId)
                );
                continue;
            }

            const groupId = score!.groupId;
            const hasPain =
                groupId === GroupIds.INJURY_HISTORY &&
                (score as LifestyleInjuryHistoryScore)?.riskFactors?.hadPain ===
                true;
            const articles = userArticles?.focusStatuses?.find(
                (x) => x.mskFocusAreaGroupId === groupId
            );
            const assessment: DataAssessment = {
                averageDetails: DataAssessmentsUtil.getAverageDetails(
                    groupId,
                    mskScore,
                    user
                ),
                completedPercent:
                    articles?.completedArticles == null
                        ? 0
                        : Math.round(
                            (100 * articles.completedArticles.length) / articles.numArticles
                        ),
                hasPain: hasPain,
                groupId: groupId,
                isLow: score.resultLevel === ResultLevel.Low,
                name: score.groupName,
                percentAvg: DataAssessmentsUtil.getAveragePercent(
                    groupId,
                    mskScore
                ),
                percentChange: 0,
                percentCurrent: score.percentage,
                percentPrevious: undefined,
                resultDescription: score.resultDescription,
                retestDays:
                    articles == null
                        ? 0
                        : articles.numArticles - (articles.completedArticles || []).length,
            };

            const isMskFocus =
                mskScore.focusAreas != null &&
                mskScore.focusAreas.find((x) => x.groupId === groupId) != null;
            if (isMskFocus) {
                result.focusAssessments.push(assessment);
            } else {
                result.otherAssessments.push(assessment);
            }
        }

        // sort focuses
        if (mskScore.focusAreas != null) {
            result.focusAssessments = (result.focusAssessments ?? []).sort((a, b) => {
                const indexA = mskScore.focusAreas?.indexOf(mskScore.focusAreas.find(x => x.groupId === a.groupId)!) ?? 99;
                const indexB = mskScore.focusAreas?.indexOf(mskScore.focusAreas.find(x => x.groupId === b.groupId)!) ?? 99;
                return indexA < indexB ? -1 : indexA > indexB ? 1 : 0;
            });
        }

        // sort incomplete to the top of other assessments
        if (result.hasPartialLifestyle) {
            result.otherAssessments = result.otherAssessments.sort((a, b) =>
                a.percentCurrent >= 0 ? 1 : -1
            );
        }

        return result;
    }

    public static getMskAverage(
        mskScore: MskScore,
    ): string {
        return i18n.t('assessments.averageScore', { ageRange: mskScore?.averageScores?.ageRange, biologicalSex: mskScore?.averageScores?.isFemale ? i18n.t('assessments.femaleForAverageScore') : i18n.t('assessments.maleForAverageScore'), averageScore: mskScore?.averageScores?.mskAverage });
    }

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    private static getNoDataAssessment(groupId: string): DataAssessment {
        return {
            completedPercent: 0,
            groupId: groupId,
            name: GroupUtil.getName(groupId),
            hasPain: false,
            isLow: false,
            percentChange: 0,
            percentCurrent: -1,
            retestDays: 0,
        };
    }

    private static getAveragePercent(
        groupId: string,
        mskScore: MskScore
    ): number | undefined {
        const groupAverages = mskScore?.averageScores?.groupAverages;
        if (groupAverages == null) {
            return undefined;
        }

        const average = groupAverages.find((x) => x.groupId === groupId);
        return average?.average;
    }

    private static getAverageDetails(
        groupId: string,
        mskScore: MskScore,
        user?: User
    ): string | undefined {

        const groupAverage = mskScore?.averageScores?.groupAverages?.find(
            (x) => x.groupId === groupId
        );
        if (groupAverage?.average == null) {
            return undefined;
        }

        return i18n.t('assessments.averageScore', { ageRange: mskScore.averageScores.ageRange, biologicalSex: mskScore.averageScores.isFemale ? i18n.t('assessments.femaleForAverageScore') : i18n.t('assessments.maleForAverageScore'), averageScore: groupAverage.average })
    }

    // #endregion Private Methods
}
