import { useAuthState } from "../../../utilities/contexts/auth-state-context";
import { useTranslation } from 'react-i18next';

interface ZapierProps {
}

const Zapier: React.FC<ZapierProps> = (props: ZapierProps) => {
  const { t } = useTranslation();
  const { state } = useAuthState();

  return (
    <div>
      <h2>{t('settings.integrations.zapier.title')}</h2>
      <p className='font-light'>{t('settings.integrations.zapier.description')}</p>
      <br />
      {state && state.user && state.user.email &&
        <div className="pt-10 border-t border-black-gray-5">
          <zapier-workflow
            sign-up-email={state.user.email}
            sign-up-first-name={state.user.firstName ?? ""}
            sign-up-last-name={state.user.lastName ?? ""}
            client-id="Ysmkq8f3XNM6mV286b7dDp5YRGpeMyYYJ6duR7nn"
            theme="light"
            intro-copy-display="hide"
            guess-zap-display="show"
            template-limit={10}
          />
        </div>
      }
    </div >
  );
}
export default Zapier;
