import React, { useState } from "react";
import { Organization } from "../../models/interfaces/organization";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { useHistory } from "react-router";
import CancellationDialogBox from "../cancellation-dialog-box/cancellation-dialog-box";
import { OrganizationStatus } from "../../models/enumerations/organization-status";
import OrganizationSubscriptionService from "../../utilities/services/organization-subscription-service";
import OrganizationService from "../../utilities/services/organization-service";
import { ReactComponent as GreenCheckMark } from "../../assets/icons/outline/gree-check-mark.svg";
import { ReactComponent as RedCross } from "../../assets/icons/outline/red-cross.svg";
import UserDeactivationModal from "../manage-users-modals/user-deactivation-modal";
import LicenseRemovalModal from "../manage-users-modals/license-removal-modal";
import { UserLimit } from "../../models/enumerations/user-limit";
import { LicenseLimit } from "../../models/enumerations/license-limit";
import { Elements } from "@stripe/react-stripe-js";
import ReactivateSubscriptionForm from "./reactivate-subscription-form";
import { loadStripe } from "@stripe/stripe-js";
import UserService from "../../utilities/services/user-service";
import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import { useTranslation } from 'react-i18next';

export interface ReactivateAddOnContentProps {
    COMPONENT_CLASS: string;
    organization: Organization;
    products: any[];
}

/**
 * Content for the modal when the user's add-on subscription was cancelled due to a failed payment.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const ReactivateAddOnContent: React.FC<ReactivateAddOnContentProps> = (props) => {
    const {
        COMPONENT_CLASS,
        organization,
        products,
    } = props;

    const { state, setState } = useAuthState();
    const [org, setOrg] = useState<Organization>(organization);
    const [openCancellationQuestionnaire, setOpenCancellationQuestionnaire] = useState(false);
    const [openPaymentForm, setOpenPaymentForm] = useState(false);
    const [openUserDeactivation, setOpenUserDeactivation] = useState(false);
    const [openLicenseRemoval, setOpenLicenseRemoval] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updatedOrgLicenseCount, setUpdatedOrgLicenseCount] = useState<number | undefined>(undefined);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);
    const history = useHistory();
    const { t } = useTranslation();

    const handleCancelButtonClick = async () => {
        setIsLoading(true);

        if (org.id) {
            org.userLimit = UserLimit.Starter;
            org.mySymmioLicense = LicenseLimit.Starter;

            setOrg(org);

            if (org.userCount && org.userLimit && org.userCount > org.userLimit) {
                setOpenUserDeactivation(true);
            }
            else {
                setOpenUserDeactivation(false);
                checkUpdatedOrg();
            }
        }

        setIsLoading(false);
    }

    const updateOrganization = async () => {
        if (org.id) {
            const orgSubArr = await OrganizationSubscriptionService.getByOrganizationId(org.id);

            if (orgSubArr && orgSubArr[0]) {
                const orgSub = orgSubArr[0];

                org.showDeactivatedAccountAddOnModal = false;
                org.status = OrganizationStatus.Active;

                org.userLimit = UserLimit.Business;
                org.mySymmioLicense = LicenseLimit.Business;

                orgSub.userLimit = UserLimit.Business;
                orgSub.mySymmioLicense = LicenseLimit.Business;
                orgSub.stripeAddOnSubscription = "";

                // TODO: Reset Sub Org limit

                const updatedOrg = await OrganizationService.save(org);
                await OrganizationSubscriptionService.save(orgSub);

                setState((state) => ({ ...state, ...{ organization: updatedOrg } }));
            }
        }
    }

    // Set openLicenseRemoval only after the User Deactivation pop-up:
    // Deactivated users lose the license, so we should check the updated mySymmioLicenseCount on the Org
    const checkUpdatedOrg = async () => {
        setIsLoading(true);

        if (state.user?.organizationId) {
            // const updatedOrg = await OrganizationService.get(state.user?.organizationId);

            // We use that instead of organization.mySymmioLicenseCount because
            // deactivated users lose the license and then licenseCount gets updated in the Firebase function,
            // so it might take a while until licenseCount gets updated in the Organization document
            const orgLicenseCount = await UserService.getCountBy(
                [{
                    field: 'symmioAccess',
                    operator: '==',
                    value: SymmioAccessType.AppLicense,
                }, {
                    field: "organizationId",
                    operator: "==",
                    value: state.user?.organizationId,
                }],
            );
            setUpdatedOrgLicenseCount(orgLicenseCount);

            if (org && orgLicenseCount && org.mySymmioLicense && orgLicenseCount > org.mySymmioLicense) {
                setOpenLicenseRemoval(true);
            }
            else {
                setOpenLicenseRemoval(false);
                updateOrganization();
                history.replace("/dashboard?subscriptionStatusModal=showCancelledAddOn");
            }
        }

        setIsLoading(false);
    }

    const runAfterUserDeactivation = async () => {
        setIsLoading(true);
        await checkUpdatedOrg();
        setIsLoading(false);
        setOpenUserDeactivation(false);
    }

    const runAfterLicenseRemoval = async () => {
        setIsLoading(false);
        setOpenLicenseRemoval(false);
        updateOrganization();
        history.replace("/dashboard?subscriptionStatusModal=showCancelledAddOn");
    }

    return (
        <>
            {openPaymentForm
                ?
                <Elements stripe={stripePromise} options={{ mode: 'setup', currency: 'usd' }}>
                    <ReactivateSubscriptionForm
                        returnUrl="/dashboard?subscriptionStatusModal=showReactivatedAddOn"
                        organization={organization}
                        products={products}
                        isReactivatingPlan={false}
                        isReactivatingAddOn={true}
                        onBackButtonClick={() => setOpenPaymentForm(false)}
                    />
                </Elements >
                :
                <>
                    <div className={`${COMPONENT_CLASS}__content-container`}>
                        <h1>{t('reactivateAddOn.updateTitle')}</h1>
                        <p>{t('reactivateAddOn.failedPayment_desc')}</p>
                        <div className={`${COMPONENT_CLASS}__actions`}>
                            <div className={`${COMPONENT_CLASS}__clickable`} onClick={() => setOpenPaymentForm(true)}>
                                <div className={`${COMPONENT_CLASS}__image-container`}>
                                    <GreenCheckMark />
                                </div>
                                <div className={`${COMPONENT_CLASS}__text-container`}>
                                    <h4>{t('reactivateAddOn.continueTitle')}</h4>
                                    <p>{t('reactivateAddOn.continue_desc')}</p>
                                </div>
                            </div>

                            <div className={`${COMPONENT_CLASS}__clickable`} onClick={() => setOpenCancellationQuestionnaire(true)}>
                                <div className={`${COMPONENT_CLASS}__image-container`}>
                                    <RedCross />
                                </div>
                                <div className={`${COMPONENT_CLASS}__text-container`}>
                                    <h4>{t('reactivateAddOn.cancelTitle')}</h4>
                                    <p>{t('reactivateAddOn.cancel_desc')}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {openCancellationQuestionnaire &&
                        <CancellationDialogBox
                            isOpen={openCancellationQuestionnaire}
                            onClose={setOpenCancellationQuestionnaire}
                            title={t('billing.cancelTitle')}
                            cancelButtonAction={handleCancelButtonClick}
                        />
                    }

                    {openUserDeactivation &&
                        <UserDeactivationModal
                            openUserDeactivation={openUserDeactivation}
                            setOpenUserDeactivation={setOpenUserDeactivation}
                            isLoading={isLoading}
                            organization={org}
                            hideClose={true}
                            removeAtFutureDate={false}
                            onSubmit={runAfterUserDeactivation}
                        />
                    }

                    {openLicenseRemoval &&
                        <LicenseRemovalModal
                            openLicenseRemoval={openLicenseRemoval}
                            setOpenLicenseRemoval={setOpenLicenseRemoval}
                            isLoading={isLoading}
                            organization={org}
                            deactivationAmount={org.mySymmioLicense && updatedOrgLicenseCount ? updatedOrgLicenseCount - org.mySymmioLicense : undefined}
                            hideClose={true}
                            removeAtFutureDate={false}
                            onSubmit={runAfterLicenseRemoval}
                        />
                    }
                </>
            }
        </>
    );
};

export default ReactivateAddOnContent;