import React, { useEffect, useState } from 'react';
import { Button } from '../button/button';
import { Modal } from '../modal/modal';
import SubscriptionService from '../../utilities/services/subscription-service';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import HubspotSubCancellationDataService from '../../utilities/services/hubspot-sub-cancellation-data-service';
import { HubspotSubCancellationData } from '../../models/interfaces/hubspot-sub-cancellation-data';
import { enqueueSnackbar } from 'notistack';
import { ReactComponent as CrossIcon } from "../../assets/icons/solid/red-cross.svg";
import BaseCardLayoutActions from '../base-card-layout/base-card-layout-actions';
import Stripe from "stripe";
import { useHistory } from 'react-router-dom';
import UserService from '../../utilities/services/user-service';
import { User } from '../../models/interfaces/user';
import OrganizationSubscriptionService from '../../utilities/services/organization-subscription-service';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface ManageUserCancellationFormProps {
    clearFields?: boolean;
    hideCoupon?: boolean;
    onCancel?: Function;
    hideBackButton?: boolean;
    skipConfirmation?: boolean;
}

const COMPONENT_CLASS = "c-cancellation-management";
const MODAL_COMPONENT_CLASS = "c-modal";

const ManageUserCancellationForm: React.FC<ManageUserCancellationFormProps> = (props) => {
    const {
        clearFields,
        hideCoupon,
        onCancel,
        hideBackButton,
        skipConfirmation,
    } = props;

    const { state } = useAuthState();
    const [openCouponOffer, setOpenCouponOffer] = useState(false);
    const [selectedReason, setSelectedReason] = useState<number | null>(null);
    const [details, setDetails] = useState('');
    const [intervalStripe, setIntervalStripe] = useState(''); // Can't name it setInterval since it's a name taken by a global built-in function
    const [nextPaymentDate, setNextPaymentDate] = useState('');
    const [originalPrice, setOriginalPrice] = useState('');
    const [discountedPrice, setDiscountedPrice] = useState('');
    const { t } = useTranslation();
    const [openBenefitsPage, setOpenBenefitsPage] = useState<boolean>(false);
    const [openConfirmationPage, setOpenConfirmationPage] = useState<boolean>(false);
    const [couponExists, setCouponExists] = useState<boolean>(false);
    const stripe: Stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
    const commonStyles = {
        fontSize: '10px',
        fontWeight: 'bold',
        marginBottom: '1rem',
        color: 'black',
        width: 'fit-content',
        height: 'fit-content',
        padding: '6px 8px',
        borderRadius: '4px'
    };

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });


    useEffect(() => {
        if (state.user && state.user.id) {
            UserService.getSnapshot(state.user.id, async (u: User) => {
                // await stripe.customers.list({
                //   limit: 1,
                //   email: `${u?.email}`,
                //   expand: ['data.subscriptions']
                // }).then(async (customer: any) => {

                // })
                if (u && u.subscription && u.subscription.subscriptionId) {
                    await stripe.subscriptions.retrieve(
                        u?.subscription?.subscriptionId
                    ).then(async (subscription) => {
                        if (subscription.discount && subscription.discount.coupon && !subscription.cancel_at_period_end) {
                            setCouponExists(true);
                        }
                        if (subscription.items.data[0].plan.interval === "year") {
                            setIntervalStripe('year');
                        }
                        else {
                            setIntervalStripe('month');
                        }
                        const end = new Date(subscription.current_period_end * 1000);
                        setNextPaymentDate(moment(end).format("MMMM DD, yyyy"));
                    });
                }
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hideCoupon]);

    const reasons = [
        { value: t('manageCancellation.reasons.differentTool') },
        { value: t('manageCancellation.reasons.features') },
        { value: t('manageCancellation.reasons.costIssues') },
        { value: t('manageCancellation.reasons.other') }];

    const benefits = [
        { value: t('manageCancellation.benefits.unlimitedAssessments') },
        { value: t('manageCancellation.benefits.reports') },
        { value: t('manageCancellation.benefits.symmioApp') },
        { value: t('manageCancellation.benefits.plansAndExercises') },
        { value: t('manageCancellation.benefits.progressTracking') },
        { value: t('manageCancellation.benefits.dailyTips') }
    ];

    const handleRadioChange = (reason: number) => {
        setSelectedReason((prev) => prev === reason ? null : reason);
    };

    const handleCancellation = async () => {
        if (state.user) {
            if (onCancel) {
                onCancel();
            }
            else {
                await SubscriptionService.handleCancelUserSubscription(state.user?.id, state.user);
            }

            const hubspotSubCanellationData: HubspotSubCancellationData = {
                organizationName: state.organization.name,
                accountHolderEmail: state.user?.email,
                accountHolderName: `${(state.user?.firstName || "")} ${(state.user?.lastName || "")}`,
                response: selectedReason !== null ? reasons[selectedReason].value : undefined,
                details: details || undefined,
                isUserSubscription: true,
            }

            await HubspotSubCancellationDataService.add(hubspotSubCanellationData, state.user);
        }
    };

    const getCouponPrices = async () => {
        if (state.user) {
            const orgSub = await OrganizationSubscriptionService.getBy([{
                field: "organizationId",
                operator: "==",
                value: state.user.organizationId
            }]);

            if (intervalStripe === 'year') {
                if (orgSub.length > 0 && orgSub[0].mySymmioYearlyPrice) {
                    setOriginalPrice(USDollar.format(orgSub[0].mySymmioYearlyPrice));
                    setDiscountedPrice(USDollar.format(orgSub[0].mySymmioYearlyPrice * 0.7));
                }
            }
            else {
                if (orgSub.length > 0 && orgSub[0].mySymmioMonthlyPrice) {
                    setOriginalPrice(USDollar.format(orgSub[0].mySymmioMonthlyPrice));
                    setDiscountedPrice(USDollar.format(orgSub[0].mySymmioMonthlyPrice * 0.5));
                }
            }
        }

    }

    // Modal closes - reset everything;
    useEffect(() => {
        if (clearFields) {
            setSelectedReason(null);
            setDetails('');
            setOpenBenefitsPage(false);
            setOpenConfirmationPage(false);
        }
    }, [clearFields]);

    const history = useHistory();

    const couponOffer = (
        <Modal
            isOpen={openCouponOffer}
            isLoading={false}
            onClose={(closeModal) => {
                setOpenCouponOffer(closeModal);
            }}>
            <div className={`${COMPONENT_CLASS}__coupon-box`}>
                <span
                    style={{
                        background: 'rgba(0, 223, 188, 1)',
                        ...commonStyles,
                    }}
                >
                    {t('manageCancellation.limitedOffer')}
                </span>
                {intervalStripe === 'year' ?
                    <><h3>{t('manageCancellation.yearDiscount')}</h3><div className={`${COMPONENT_CLASS}__coupon-box_offer`}>
                        <h2 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '1rem' }}>{t('manageCancellation.30OffYear')}</h2>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto' }}>
                            <h1 style={{ fontSize: '32px', fontWeight: 'bold', marginRight: '0.5rem' }}>{discountedPrice}</h1>
                            <h1 style={{ fontSize: '13px', color: '#82848B' }}>{t('manageCancellation.slashYear')}</h1>
                        </div>
                        <p style={{ fontWeight: '100', textDecoration: 'line-through', fontSize: '14px', color: 'var(--ion-color-secondary)' }}>
                            {originalPrice} {t('manageCancellation.slashYear')}
                        </p>
                        <Button
                            //type="large"
                            style={{ marginTop: '1rem' }}
                            buttonText={t('buttons.btn_claimOffer')}
                            onClick={async () => {
                                if (state.user && state.user.subscription?.subscriptionId) {
                                    await stripe.subscriptions.update(
                                        `${state.user.subscription?.subscriptionId}`,
                                        {
                                            coupon: 'CpM7B9OB',
                                        }
                                    );
                                    enqueueSnackbar(t('manageCancellation.subscriptionUpdated'), { variant: "toast", width: "450px", autoHideDuration: 5000 });
                                    history.push("/plans-and-payment");
                                }
                            }} />
                        <p style={{ fontWeight: '300', fontSize: '12px', marginTop: '1.5rem' }}>
                            {t('manageCancellation.offerEffective', { nextPaymentDate: nextPaymentDate })}
                        </p>
                    </div><div className={`${MODAL_COMPONENT_CLASS}__actions`}>
                            <button
                                type="button"
                                style={{ fontSize: '14px' }}
                                className={`${MODAL_COMPONENT_CLASS}__actions__cancel`}
                                onClick={async () => {
                                    // if (state.user) {
                                    //   await SubscriptionService.handleCancelUserSubscription(state.user?.id, state.user);
                                    //    history.push("/plans-and-payment");
                                    // }
                                    setOpenBenefitsPage(true);
                                    setOpenCouponOffer(false);
                                }}>
                                {t('buttons.btn_declineOffer')}
                            </button>

                        </div></>
                    :
                    <><h3>{t('manageCancellation.monthDiscount')}</h3><div className={`${COMPONENT_CLASS}__coupon-box_offer`}>
                        <h2 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '1rem' }}>{t('manageCancellation.50Off6Months')}</h2>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto' }}>
                            <h1 style={{ fontSize: '32px', fontWeight: 'bold', marginRight: '0.5rem' }}>{discountedPrice}</h1>
                            <h1 style={{ fontSize: '13px', color: '#82848B' }}>{t('manageCancellation.slashMonth')}</h1>
                        </div>
                        <p style={{ fontWeight: '100', textDecoration: 'line-through', fontSize: '14px', color: 'var(--ion-color-secondary)' }}>
                            {originalPrice} {t('manageCancellation.slashMonth')}
                        </p>
                        <Button
                            //type="large"
                            style={{ marginTop: '1rem' }}
                            buttonText={t('buttons.btn_claimOffer')}
                            onClick={async () => {
                                if (state.user && state.user.subscription?.subscriptionId) {
                                    await stripe.subscriptions.update(
                                        `${state.user.subscription?.subscriptionId}`,
                                        {
                                            coupon: 'DdJdKkSV',
                                        }
                                    );
                                    enqueueSnackbar(t('manageCancellation.subscriptionUpdated'), { variant: "toast", width: "450px", autoHideDuration: 5000 });
                                    history.push("/plans-and-payment");
                                }
                            }} />
                        <p style={{ fontWeight: '300', fontSize: '12px', marginTop: '1.5rem' }}>
                            {t('manageCancellation.offerEffective', { nextPaymentDate: nextPaymentDate })}
                        </p>
                    </div><div className={`${MODAL_COMPONENT_CLASS}__actions`}>
                            <button
                                type="button"
                                style={{ fontSize: '14px' }}
                                className={`${MODAL_COMPONENT_CLASS}__actions__cancel`}
                                onClick={async () => {
                                    // if (state.user) {
                                    //   await SubscriptionService.handleCancelUserSubscription(state.user?.id, state.user);
                                    //    history.push("/plans-and-payment");
                                    // }
                                    setOpenBenefitsPage(true);
                                    setOpenCouponOffer(false);
                                }}>
                                {t('buttons.btn_declineOffer')}
                            </button>

                        </div></>
                }
            </div>
        </Modal>
    );

    const reasonsPage = (
        <div className={COMPONENT_CLASS}>
            <div style={{ fontSize: '14px', fontWeight: '300' }} className='my-8'>{t('manageCancellation.reasons.title')}</div>
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F3F3F4', height: 'fit-content', width: '100%', borderRadius: '10px', padding: '2rem' }}>

                <div className={`${COMPONENT_CLASS}__reasons`}>
                    <strong>{t('manageCancellation.reasons.primaryReason')}</strong>
                    {reasons.map((reason, index) => (
                        <div key={index}>
                            <div className={`reason ${selectedReason === index ? 'visible' : ''}`} onClick={() => handleRadioChange(index)} >
                                <div>
                                    <input type='radio' name="cancellationReason" value={reason.value} checked={selectedReason === index} onChange={e => { }} />
                                </div>
                                <label>{reason.value}</label>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem', backgroundColor: '#F3F3F4', height: 'fit-content', width: '100%', borderRadius: '10px', padding: '2rem' }}>
                <div className={`${COMPONENT_CLASS}__cancellation-details`}>
                    <strong>{t('manageCancellation.reasons.letUsKnow')}</strong>
                    <textarea onChange={event => setDetails(event.target.value)} value={details} placeholder="Enter Comments" />
                </div>
            </div>
        </div>
    );

    const benefitsPage = (
        <div className={`${COMPONENT_CLASS} my-8 mx-20 gap-8 flex flex-col`}>
            <div style={{ fontSize: '14px', fontWeight: '300' }} dangerouslySetInnerHTML={{ __html: t('manageCancellation.benefits.caution', { nextPaymentDate: nextPaymentDate }) }}></div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', fontSize: '14px', fontWeight: '300' }}>
                {benefits.map((benefit, index) => (
                    <div style={{ display: 'flex' }}>
                        <div>
                            <CrossIcon />
                        </div>
                        <div style={{ marginLeft: '1rem' }}>{benefit.value}</div>
                    </div>
                ))}
            </div>
        </div>
    );

    const confirmationPage = (
        <div className={COMPONENT_CLASS}>
            <div style={{ fontSize: '14px', fontWeight: '300' }} className='my-8 mx-20' dangerouslySetInnerHTML={{ __html: t('manageCancellation.benefits.confirmation', { nextPaymentDate: nextPaymentDate }) }}>
            </div>
        </div>
    );

    return (
        <>
            {openCouponOffer && couponOffer}
            <div className={`${COMPONENT_CLASS}`}>
                <div className={`${COMPONENT_CLASS}__wrapper`}>
                    <div style={{ marginTop: '2rem' }} className={`c-subscriptions__header`}>
                        {t('buttons.btn_cancelSubscription')}
                    </div>
                    <div className="px-4">
                        {openBenefitsPage ? benefitsPage :
                            (openConfirmationPage ? confirmationPage : reasonsPage)}
                    </div>
                </div>
            </div>
            <div className='w-full content-end'>
                <BaseCardLayoutActions
                    backButton={!openConfirmationPage || hideBackButton}
                    submitButton={true}
                    onSubmit={async () => {
                        if (openConfirmationPage) {
                            history.push("/plans-and-payment");
                        }
                        else if (!openBenefitsPage && !couponExists && !hideCoupon) {
                            getCouponPrices();
                            setOpenCouponOffer(true)
                        } else if (!openBenefitsPage && (couponExists || hideCoupon)) {
                            setOpenBenefitsPage(true);
                        } else if (openBenefitsPage) {
                            setOpenBenefitsPage(false);
                            setOpenConfirmationPage(true);
                            await handleCancellation();

                            if (skipConfirmation) {
                                history.push("/plans-and-payment");
                            }
                        } else {
                            history.push("/plans-and-payment");
                        }
                    }}
                    submitButtonText={openBenefitsPage ? t('buttons.btn_confirmCancel') :
                        (openConfirmationPage ? t('buttons.btn_continue') : t('buttons.btn_next'))}
                    onBack={() => history.push("/plans-and-payment")}
                    submitDisabled={selectedReason === null}
                />
            </div>
        </>
    );

};

export default ManageUserCancellationForm;