import React, { useEffect, useState } from 'react';
import { DialogBox } from '../../dialog-box/dialog-box';
import { Organization } from '../../../models/interfaces/organization';
import { OrganizationSubscription } from '../../../models/interfaces/organization-subscription';
import OrganizationService from '../../../utilities/services/organization-service';
import OrganizationSubscriptionService from '../../../utilities/services/organization-subscription-service';
import { OrganizationStatus } from '../../../models/enumerations/organization-status';
import { Grid } from '@mui/material'
import { ViewOrganizationField } from './view-organization-field';
import { PlanType } from '../../../models/enumerations/plan-type';
import moment from 'moment';
import { PaymentType } from '../../../models/enumerations/payment-type';
import { User } from '../../../models/interfaces/user';
import UserService from '../../../utilities/services/user-service';
import OrganizationUtil from '../../../utilities/organization-util';
import { OrganizationStatusBadge } from '../../organization-status-badge/organization-status-badge';
import { OrganizationType, OrganizationTypeLabel } from '../../../models/enumerations/organization-type';
import { Button } from '../../button/button';
import { useTranslation } from 'react-i18next';
import { PlanTypeLabels } from '../../../models/plan-type';
import { LanguageDateFormat } from '../../../models/language-date-format';
import { PaymentCycle } from '../../../models/enumerations/payment-cycle';
import { PaymentCycleTranslated } from '../../../models/payment-cycle';

type ViewOrganizationModalProps = {
    organizationId: string | undefined,
    open: boolean,
    onClose: Function,
    onEdit: Function,
};

export const ViewOrganizationModal: React.FC<ViewOrganizationModalProps> = (props: ViewOrganizationModalProps) => {
    const {
        organizationId,
        open,
        onClose,
        onEdit,
    } = props;

    const [organization, setOrganization] = useState<Organization>()
    const [subscription, setSubscription] = useState<OrganizationSubscription>();
    const [trial, setTrial] = useState<boolean>(false);
    const [accountHolder, setAccountHolder] = useState<User>();
    const [userQuantity, setUserQuantity] = useState<number>();
    const [expired, setExpired] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(true);
    const [accountManagerName, setAccountManagerName] = useState("");
    const { t, i18n } = useTranslation();
    const reset = () => {
        setOrganization(undefined);
        setSubscription(undefined);
        setTrial(false);
        setAccountHolder(undefined);
        setUserQuantity(undefined);
        setExpired(undefined);
    }

    useEffect(() => {
        const loadData = async () => {
            if (!organizationId) return;
            const o = await OrganizationService.get(organizationId);
            if (!o) return;

            setOrganization(o);

            const temp = await OrganizationSubscriptionService.getByOrganizationId(organizationId);
            const orgSub = temp[0];

            if (orgSub.accountType.value === OrganizationType.Trial) {
                setTrial(true);
            }
            if (o.accountHolderId) {
                const holder = await UserService.get(o.accountHolderId);
                if (holder) setAccountHolder(holder);
            }

            const uQuantity = await OrganizationUtil.getOrganizationUserCount(o);
            setUserQuantity(uQuantity);

            const subResponse = await OrganizationSubscriptionService.getByOrganizationId(organizationId);
            if (subResponse && subResponse.length > 0) {
                setSubscription(subResponse[0]);
                if (subResponse[0].renewDate) {
                    const renewDate = moment(subResponse[0].renewDate);
                    const exp = renewDate.isSameOrBefore(moment());
                    setExpired(exp);
                }
            }

            setAccountManagerName(o.accountManager?.label || "");

            setLoading(false);
        }
        reset();
        loadData();
    }, [organizationId, open]);

    const getAccountHolderName = () => {
        if (!accountHolder) return 'none';

        if (accountHolder.firstName && accountHolder.lastName) {
            return `${accountHolder.firstName} ${accountHolder.lastName}`;
        }

        if (accountHolder.email) {
            return accountHolder.email;
        }

        return `id: ${accountHolder.id}`
    }

    // const paidOrTrial = (status: OrganizationStatus) => {
    //     if (status === OrganizationStatus.Trial) {
    //         return 'Trial';
    //     }
    //     return 'Paid';
    // };

    const getOrganizationStatus = () => {
        if (!organization) {
            return OrganizationStatus.Active;
        }

        const status = organization.status;

        if (typeof status === 'string') {
            if (status === OrganizationStatus.Deactivated.toString() || organization.active === false) {
                return OrganizationStatus.Deactivated;
            }
            if (status === OrganizationStatus.Active.toString()) {
                return OrganizationStatus.Active;
            }
            if (status === OrganizationStatus.Expired.toString()) {
                return OrganizationStatus.Expired;
            }
        } else {
            if (status === OrganizationStatus.Deactivated || organization.active === false) {
                return OrganizationStatus.Deactivated
            }
            if (status === OrganizationStatus.Active) {
                return OrganizationStatus.Active;
            }
            if (status === OrganizationStatus.Expired) {
                return OrganizationStatus.Expired;
            }
        }

        return OrganizationStatus.Active;
    }

    const getOrganizationLimit = () => {
        if (organization?.userLimit) {
            return organization.userLimit;
        }

        if (subscription?.userLimit) {
            return subscription.userLimit;
        }

        return 'no limit';
    }

    const handleEdit = () => {
        onEdit(organizationId);
    }

    return (
        <>
            <DialogBox
                maxWidth="lg"
                fullWidth
                open={!!organizationId && open}
                onClose={onClose}
                sx={{ maxWidth: '1000px', height: '1200px', margin: 'auto', maxHeight: '99ch' }}
            >
                <div style={{ marginTop: '30px' }} />
                <OrganizationStatusBadge status={getOrganizationStatus()} expired={expired} />
                <h1 style={{ margin: '16px 0px 32px 0px' }}>{organization ? organization.name : ''}</h1>
                <Grid container spacing={0}>

                    {/* ===== FIRST ROW ===== */}

                    <ViewOrganizationField
                        firstRow
                        label={t('organization.edit.fields.accountType')}
                        value={subscription && subscription.accountType ? OrganizationTypeLabel[subscription.accountType.value as OrganizationType] : ''}
                        loading={loading}
                    />
                    <ViewOrganizationField
                        firstRow
                        label={t('organization.edit.fields.plan')}
                        value={subscription?.plan?.value !== undefined ? PlanTypeLabels[subscription.plan.value as PlanType] : undefined}
                        loading={loading}
                    />
                    <ViewOrganizationField
                        firstRow
                        label={t('organization.edit.fields.pricingModel')}
                        value={subscription?.paymentType?.label ?? 'n/a'}
                        hidden={subscription?.plan?.value !== PlanType.Enterprise}
                        loading={loading}
                    />
                    <ViewOrganizationField
                        firstRow
                        rowEnd
                        label={t('deeplinks.edit.startDate')}
                        value={subscription?.startDate ? moment(subscription.startDate).format(LanguageDateFormat[i18n.language]) : 'n/a'}
                        loading={loading}
                    />

                    {/* ===== SECOND ROW ===== */}

                    {
                        trial ? (<ViewOrganizationField />)
                            : (
                                <ViewOrganizationField
                                    label={t('organization.edit.fields.paymentCycle')}
                                    value={subscription?.paymentCycle ? PaymentCycleTranslated[subscription?.paymentCycle?.value as PaymentCycle] : ''}
                                    loading={loading}
                                    hidden={trial}
                                />
                            )
                    }
                    {
                        (
                            subscription
                            && subscription.plan?.value === PlanType.Enterprise
                            && subscription.paymentType?.value === PaymentType.PayPerUser
                        )
                            ? (
                                <ViewOrganizationField
                                    label={t('organization.edit.fields.pricePerUser')}
                                    value={subscription?.pricePerUser ?? 'none'}
                                    startAdornment={subscription?.pricePerUser ? '$' : ''}
                                    loading={loading}
                                />
                            )
                            : (
                                <ViewOrganizationField
                                    label={t('organization.edit.fields.price')}
                                    value={subscription?.price ?? 'none'}
                                    startAdornment={subscription?.price ? '$' : ''}
                                    loading={loading}
                                    hidden={trial}
                                />
                            )
                    }
                    <ViewOrganizationField />

                    <ViewOrganizationField
                        rowEnd
                        label={trial ? t('organization.edit.fields.expirationDate') : t('organization.edit.fields.renewDate')}
                        value={subscription?.startDate && subscription.renewDate ? moment(subscription.renewDate).format(LanguageDateFormat[i18n.language]) : 'n/a'}
                        loading={loading}
                        expired={expired}
                    />

                    {/* ===== THIRD ROW ===== */}
                    <ViewOrganizationField
                        label={t('organization.edit.fields.userLimit')}
                        value={subscription?.userLimit ?? ''}
                        loading={loading}
                    />
                    <ViewOrganizationField
                        label={t('organization.edit.fields.programs')}
                        value={subscription?.mySymmioLicense ?? ''}
                        loading={loading}
                    />
                    <ViewOrganizationField />
                    <ViewOrganizationField
                        rowEnd
                        label={t('organization.edit.fields.userQuantity')}
                        userQuantity={userQuantity}
                        value={` / ${getOrganizationLimit()}`}
                        loading={loading}
                    />

                    {/* ===== FOURTH ROW ===== */}
                    {(accountManagerName) ?
                        (<ViewOrganizationField
                            label={t('organization.edit.fields.accountManager')}
                            value={accountManagerName}
                            loading={loading}
                        />) :
                        (
                            <ViewOrganizationField />
                        )
                    }
                    <ViewOrganizationField
                        label={t('organization.edit.fields.accountHolder')}
                        value={getAccountHolderName()}
                        loading={loading}
                        columns={2}
                    />
                    <ViewOrganizationField
                        rowEnd
                        label={t('organization.edit.fields.programsQuantity')}
                        userQuantity={organization?.mySymmioLicenseCount}
                        value={` / ${organization?.mySymmioLicense}`}
                        loading={loading}
                    />
                </Grid>
                <div style={{ marginTop: '65px', display: 'flex', justifyContent: 'right' }}>
                    <Button
                        buttonStyle='primary'
                        onClick={handleEdit}
                        buttonText={t('buttons.btn_edit')} />
                </div>
                <div style={{ marginBottom: '30px' }} />
            </DialogBox >
        </>
    );
}
