/* eslint-disable jsx-a11y/img-redundant-alt */
import FirebaseStorage from "../../utilities/firebase-storage";
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "c-assessment-intro";

export interface MovementIntroProps { }

const MovementIntro: React.FC<MovementIntroProps> = (props) => {
    const { t } = useTranslation();

    return (
        <div className={`${COMPONENT_CLASS} -movement scroll-container`}>
            <div className={`${COMPONENT_CLASS}__image`}>
                <img
                    alt="Movement Image"
                    src={new FirebaseStorage().getMediaUrl(
                        "public/movement/movement-assessment@2x.png"
                    )}
                />
            </div>
            <div className={`${COMPONENT_CLASS}__content-container`}>
                <h1>{t('assessmentIntro.titleMovement')}</h1>
                <div className={`${COMPONENT_CLASS}__content`}>
                    <p className={`${COMPONENT_CLASS}__description`}>
                        {t('assessmentIntro.messageMovement')}
                    </p>
                    <div className={`${COMPONENT_CLASS}__content__section`}>
                        <p className={`${COMPONENT_CLASS}__content__header`}>
                            {t('assessmentIntro.duration')}
                        </p>
                        <p>{t('assessmentIntro.duration_desc')}</p>
                    </div>
                    <div className={`${COMPONENT_CLASS}__content__section`}>
                        <p className={`${COMPONENT_CLASS}__content__header`}>
                            {t('assessmentIntro.equipment')}
                        </p>
                        <p> {t('assessmentIntro.equipmentMovement_desc')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MovementIntro;
