import { ListOptions } from "./interfaces/list-options";
import i18n from "../i18n";

export const MovementFocusOptions: ListOptions[] = [
    {
        value: "TOE_TOUCH",
        label: i18n.t('movementFocus.toeTouch'),
        shortLabel: i18n.t('movementFocus.shortLabel.toeTouch'),
    }, {
        value: "SQUAT",
        label: i18n.t('movementFocus.squat'),
        shortLabel: i18n.t('movementFocus.shortLabel.squat'),
    }, {
        value: "SPINE_CLEARING",
        label: i18n.t('movementFocus.spineClearing'),
        shortLabel: i18n.t('movementFocus.shortLabel.spineClearing')
    }, {
        value: "SHOULDER_MOBILITY",
        label: i18n.t('movementFocus.shoulderMobility'),
        shortLabel: i18n.t('movementFocus.shortLabel.shoulderMobility')
    }, {
        value: "SHOULDER_CLEARING",
        label: i18n.t('movementFocus.shoulderClearing'),
        shortLabel: i18n.t('movementFocus.shortLabel.shoulderClearing')
    }, {
        value: "ROTATION",
        label: i18n.t('movementFocus.rotation'),
        shortLabel: i18n.t('movementFocus.shortLabel.rotation')
    }, {
        value: "BALANCE",
        label: i18n.t('movementFocus.balanceAndReach'),
        shortLabel: i18n.t('movementFocus.shortLabel.balanceAndReach')
    }
];

export const MovementFocusOptionsWithBreathing: ListOptions[] = [
    {
        value: "TOE_TOUCH",
        label: i18n.t('movementFocus.toeTouch'),
        shortLabel: i18n.t('movementFocus.shortLabel.toeTouch'),
    }, {
        value: "SQUAT",
        label: i18n.t('movementFocus.squat'),
        shortLabel: i18n.t('movementFocus.shortLabel.squat'),
    }, {
        value: "SPINE_CLEARING",
        label: i18n.t('movementFocus.spineClearing'),
        shortLabel: i18n.t('movementFocus.shortLabel.spineClearing')
    }, {
        value: "SHOULDER_MOBILITY",
        label: i18n.t('movementFocus.shoulderMobility'),
        shortLabel: i18n.t('movementFocus.shortLabel.shoulderMobility')
    }, {
        value: "SHOULDER_CLEARING",
        label: i18n.t('movementFocus.shoulderClearing'),
        shortLabel: i18n.t('movementFocus.shortLabel.shoulderClearing')
    }, {
        value: "ROTATION",
        label: i18n.t('movementFocus.rotation'),
        shortLabel: i18n.t('movementFocus.shortLabel.rotation')
    }, {
        value: "BALANCE",
        label: i18n.t('movementFocus.balanceAndReach'),
        shortLabel: i18n.t('movementFocus.shortLabel.balanceAndReach')
    },
    {
        value: "TOE_TOUCH_BREATHING",
        label: i18n.t('movementFocus.toeTouchBreathing'),
        shortLabel: i18n.t('movementFocus.shortLabel.toeTouchBreathing')
    }, {
        value: "SQUAT_BREATHING",
        label: i18n.t('movementFocus.squatBreathing'),
        shortLabel: i18n.t('movementFocus.shortLabel.squatBreathing')
    }, {
        value: "SPINE_CLEARING_BREATHING",
        label: i18n.t('movementFocus.spineClearingBreathing'),
        shortLabel: i18n.t('movementFocus.shortLabel.spineClearingBreathing')
    }, {
        value: "SHOULDER_MOBILITY_BREATHING",
        label: i18n.t('movementFocus.shoulderMobilityBreathing'),
        shortLabel: i18n.t('movementFocus.shortLabel.shoulderMobilityBreathing')
    }, {
        value: "SHOULDER_CLEARING_BREATHING",
        label: i18n.t('movementFocus.shoulderClearingBreathing'),
        shortLabel: i18n.t('movementFocus.shortLabel.shoulderClearingBreathing')
    }, {
        value: "ROTATION_BREATHING",
        label: i18n.t('movementFocus.rotationBreathing'),
        shortLabel: i18n.t('movementFocus.shortLabel.rotationBreathing')
    }, {
        value: "BALANCE_BREATHING",
        label: i18n.t('movementFocus.balanceAndReachBreathing'),
        shortLabel: i18n.t('movementFocus.shortLabel.balanceAndReachBreathing')
    }
];