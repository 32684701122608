import BaseCardLayoutActions from "../base-card-layout/base-card-layout-actions";
import { Button } from "../button/button";
import { PaymentCycle } from "../../models/enumerations/payment-cycle";
import { useEffect, useState } from "react";
import { query, collection, where, getDocs, getFirestore, DocumentData } from 'firebase/firestore';
import { Modal } from "../modal/modal";
import EnterpriseContactUs from "./enterprise-contact-us";
import { Carousel } from "react-responsive-carousel"
import { PlanId } from "../../models/enumerations/plan-id";
import { AddOnId } from "../../models/enumerations/add-on-id";
import PlansTableContent from "./plans-table-content";
import { useTranslation } from "react-i18next";

interface PlansProps {
  onProductSelect: (data: any) => void;
  onBackButton: () => void;
}

const Plans: React.FC<PlansProps> = ({ onProductSelect, onBackButton }) => {
  const COMPONENT_CLASS = "c-subscriptions";

  const [paymentCycle, setPaymentCycle] = useState<PaymentCycle>(PaymentCycle.Monthly);
  const [contactUsEnterpriseOpen, setContactUsEnterpriseOpen] = useState(false);
  const [businessSub, setBusinessSub] = useState<DocumentData>();
  const [addOnPrices, setAddOnPrices] = useState<{ user?: string, license?: string, organization?: string }>();
  const { t } = useTranslation();

  enum SubscriptionCarouselIndex {
    Starter = 0,
    Business = 1,
    Enterprise = 2,
  }
  const [carouselItemIndex, setCarouselItemIndex] = useState(SubscriptionCarouselIndex.Starter);

  const commonStyles = {
    fontSize: '12px',
    fontWeight: 'bold',
    marginLeft: '0.5rem',
    color: 'black',
    width: 'fit-content',
    padding: '6px 8px',
    borderRadius: '4px'
  };
  useEffect(() => {
    stuff();
  }, [])

  const stuff = async () => {
    const db = getFirestore();
    // create a query object
    const q = query(
      collection(db, 'products'),
      where('active', '==', true),
    );

    const querySnapshot = await getDocs(q);
    const productsPromises = querySnapshot.docs.map(async (productDoc, index) => {
      if (productDoc.id === PlanId.Business) {
        let productInfo = productDoc.data();
        const pricesCollection = collection(productDoc.ref, 'prices');
        const priceQuerySnapshot = await getDocs(pricesCollection);

        const priceInfo: DocumentData[] = [];
        // eslint-disable-next-line array-callback-return
        priceQuerySnapshot.docs.map((priceDoc, index) => {
          priceInfo[index] = priceDoc.data();
          productInfo['priceId'] = priceDoc.id;
        });
        productInfo['priceInfo'] = priceInfo;
        setBusinessSub(productInfo);
        return productInfo;
      }
      else if (productDoc.id === AddOnId.User.toString() || productDoc.id === AddOnId.License.toString() || productDoc.id === AddOnId.Organization.toString()) {
        let productInfo = productDoc.data();
        const pricesCollection = collection(productDoc.ref, 'prices');
        const priceQuerySnapshot = await getDocs(pricesCollection);

        const priceInfo: DocumentData[] = [];
        // eslint-disable-next-line array-callback-return
        priceQuerySnapshot.docs.map((priceDoc, index) => {
          priceInfo[index] = priceDoc.data();
          productInfo['priceId'] = priceDoc.id;
        });
        productInfo['priceInfo'] = priceInfo;

        const addOnPrice = (productInfo?.priceInfo.find((item: any) => item.interval === "month" && item.active).unit_amount / 100).toFixed(2);

        if (productDoc.id === AddOnId.User.toString()) {
          setAddOnPrices((prices) => ({ ...prices, ...{ user: addOnPrice } }));
        }
        else if (productDoc.id === AddOnId.License.toString()) {
          setAddOnPrices((prices) => ({ ...prices, ...{ license: addOnPrice } }));
        }
        else if (productDoc.id === AddOnId.Organization.toString()) {
          setAddOnPrices((prices) => ({ ...prices, ...{ organization: addOnPrice } }));
        }
        return productInfo;
      }
    });
    await Promise.all(productsPromises);
  }

  const starterCard = (
    <div className={`${COMPONENT_CLASS}__container_card`} style={{ background: '#FFF4E4' }}>
      <h4 className={`${COMPONENT_CLASS}__title`}>{t('pricing.general.pl_starter')}</h4>
      <h5 style={{ textAlign: "center", height: '8rem' }}>{t('pricing.plansPage.pl_starter_desc')}</h5>
      <div className={`${COMPONENT_CLASS}__header card`}>
        <div style={{ display: 'flex', alignItems: 'center', height: '6rem' }}>
          {t('pricing.general.free')}
        </div>
        <Button
          buttonText={t('buttons.btn_getStarted')}
          onClick={() => { onProductSelect('STARTER') }} />
      </div>
    </div>
  );

  const businessCard = (
    <div className={`${COMPONENT_CLASS}__container_card`} style={{ background: '#DFF5F1' }}>
      <h4 className={`${COMPONENT_CLASS}__title`}>{t('pricing.general.pl_business')}</h4>
      <h5 style={{ textAlign: "center", height: '8rem' }}>{t('pricing.plansPage.pl_business_desc')}</h5>
      <div className={`${COMPONENT_CLASS}__header card`}>
        {paymentCycle === PaymentCycle.Monthly ? (
          <div style={{ display: 'flex', alignItems: 'center', height: '6rem' }}>
            ${businessSub ? businessSub?.priceInfo.find((item: any) => item.interval === "month").unit_amount / 100 : '...'}
            <p style={{ fontWeight: '100', fontSize: '15px', color: 'var(--ion-color-secondary)', marginLeft: '0.25rem' }}> {t('pricing.general.month')}</p>
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', height: '6rem' }}>
            <div style={{ display: 'flex' }}>
              ${businessSub ? (businessSub?.priceInfo.find((item: any) => item.interval !== "month").unit_amount / 100) : '...'}
              <p style={{ fontWeight: '100', fontSize: '15px', color: 'var(--ion-color-secondary)', marginLeft: '0.25rem' }}> {t('pricing.general.year')}</p>
            </div>
            <p style={{ fontWeight: '100', textDecoration: 'line-through', fontSize: '15px', color: 'var(--ion-color-secondary)', marginLeft: '0.25rem' }}>
              ${businessSub ? (businessSub?.priceInfo.find((item: any) => item.interval === "month").unit_amount / 100 * 12) : '...'} {t('pricing.general.year')}
            </p>
          </div>
        )}
        <Button
          buttonText={t('buttons.btn_getStarted')}
          onClick={() => {
            let product = { ...businessSub };
            if (product && paymentCycle === PaymentCycle.Monthly)
              product.priceInfo = businessSub?.priceInfo.find((item: any) => item.interval === "month");
            else if (product && paymentCycle === PaymentCycle.Yearly)
              product.priceInfo = businessSub?.priceInfo.find((item: any) => item.interval !== "month");
            onProductSelect({ ...product, interval: paymentCycle });
          }} />
      </div>
    </div>
  );

  const enterpriseCard = (
    <div className={`${COMPONENT_CLASS}__container_card`} style={{ background: '#DEF3FF' }}>
      <h4 className={`${COMPONENT_CLASS}__title`}>{t('pricing.general.pl_enterprise')}</h4>
      <h5 style={{ textAlign: "center", height: '8rem' }}>{t('pricing.plansPage.pl_enterprise_desc')}</h5>
      <div className={`${COMPONENT_CLASS}__header card`}>
        <div style={{ display: 'flex', alignItems: 'center', height: '6rem' }}>
          {t('pricing.plansPage.enterprise_letsTalk')}
        </div>
        <Button
          buttonText={t('buttons.btn_contactUs')}
          onClick={() => { setContactUsEnterpriseOpen(true); }} />
      </div>
    </div>
  );

  const onCarouselChange = (index: number, item: React.ReactNode) => {
    setCarouselItemIndex(index);
  };

  return (
    <>
      <div className={`${COMPONENT_CLASS}__header`}>
        {t('pricing.plansPage.title')}
      </div>
      <div className="w-full flex justify-center">
        <div className="c-payment-cycle-toggle">
          <button type="button" onClick={() => setPaymentCycle(PaymentCycle.Monthly)} className={`c-payment-cycle-toggle__payment-cycle-monthly ${paymentCycle === PaymentCycle.Monthly ? "active" : ""}`}>
            {t('pricing.plansPage.sl_montly')}
          </button>
          <button type="button" onClick={() => setPaymentCycle(PaymentCycle.Yearly)} className={`c-payment-cycle-toggle__payment-cycle-yearly ${paymentCycle === PaymentCycle.Yearly ? "active" : ""}`}>
            {t('pricing.plansPage.sl_yearly')}
            <span
              style={{
                background: 'rgba(0, 223, 188, 1)',
                ...commonStyles,
              }}
            >
              15% {t('pricing.plansPage.discount')}
            </span>
          </button>
        </div>
      </div>
      <div className={`${COMPONENT_CLASS}__plans-info-desktop`}>
        <div className={`${COMPONENT_CLASS}__container`}>
          {starterCard}
          {businessCard}
          {enterpriseCard}
        </div>

        <PlansTableContent
          carouselItemIndex={carouselItemIndex}
          addOnPrices={addOnPrices}
          isMobile={false}
        />
      </div>
      <div className={`${COMPONENT_CLASS}__plans-info-mobile`}>
        <Carousel showArrows={true} showStatus={false} onChange={onCarouselChange}>
          <div className={`${COMPONENT_CLASS}__card-wrapper`}>
            {starterCard}
          </div>
          <div className={`${COMPONENT_CLASS}__card-wrapper`}>
            {businessCard}
          </div>
          <div className={`${COMPONENT_CLASS}__card-wrapper`}>
            {enterpriseCard}
          </div>
        </Carousel>

        <PlansTableContent
          carouselItemIndex={carouselItemIndex}
          addOnPrices={addOnPrices}
          isMobile={true}
        />
      </div >
      <BaseCardLayoutActions
        backButton={true}
        onBack={onBackButton}
      />
      <Modal
        isOpen={contactUsEnterpriseOpen}
        isLoading={false}
        onClose={() => {
          setContactUsEnterpriseOpen(false);
        }}
      >
        <EnterpriseContactUs title={t('pricing.plansPage.enterprise_salesTeam')} />
      </Modal>
    </>
  );
}

export default Plans;

