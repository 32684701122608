import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DropdownSelect } from "../../components/forms/dropdown-select/dropdown-select";
import { MultiSelect, TextTypeInput, ToggleButton } from "../../components/forms";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { Deeplink } from "../../models/interfaces/deeplink";
import EntityNotFoundException from "../../models/exceptions/entity-not-found";
import { useDeeplinks } from "../../utilities/hooks/use-deeplinks";
import "./link-add-edit.styles.scss";
import { DropdownSelectOption } from "../../models/interfaces/dropdown-select-option";
import { DatePicker } from "../../components/forms/date-picker";
import FunctionService from "../../utilities/services/function-service";
import { Loader } from "../../components/loader/loader";
import { DateTime } from "luxon";
import { useStore } from "../../store/useStore";
import { enqueueSnackbar } from "notistack";
import { SymmioAccessLinkTypeOptions, SymmioAccessTypeOptionsInterface } from "../../models/symmio-access-types";
import { Modal } from "../../components/modal/modal";
import PortalProgressBar from "../../components/portal-progress-bar/portal-progress-bar";
import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import { NumberTypeInput } from "../../components/forms/number-type-input/number-type-input";
import { Button } from "../../components/button/button";
import { useTranslation } from 'react-i18next';

interface LinksAddEditPageParams {
    id: string;
}

interface AddEditFormData {
    name: string,
    group?: DropdownSelectOption,
    active: boolean,
    tags?: DropdownSelectOption[],
    startDate?: string | null,
    endDate?: string | null
    url?: string,
    limit?: number | undefined,
    used?: number | undefined,
    linkType?: SymmioAccessTypeOptionsInterface,
}

const LinkAddEditPage: React.FC<LinksAddEditPageParams> =
    (props: LinksAddEditPageParams) => {
        const { id } = useParams<LinksAddEditPageParams>();
        const { state } = useAuthState();
        const history = useHistory();
        const [isLoading, setIsLoading] = useState<boolean>(true);
        const organization = useStore((state) => state.organization);
        const [openLinkTypeModal, setOpenLinkTypeModal] = useState(false);
        const { t } = useTranslation();

        if (!state.user) {
            throw new Error(t('deeplinks.edit.err_noUser'));
        }

        const user = state.user;

        if (!organization || organization.id === undefined) {
            throw new Error(t('deeplinks.edit.err_noOrg'));
        }

        const {
            getGroupsDropDownOptions,
            groupsDropDownOptions,
            groupGetById,
            getTagsDropDownSelectOptions,
            tagsDropDownOptions,
            addDeeplink,
            getDeeplinkById,
            updateDeeplink,
            getTagById,
        } = useDeeplinks();

        const methods = useForm<AddEditFormData>();

        const {
            register,
            handleSubmit,
            formState: { errors },
            setValue,
            reset,
            control,
            setError
        } = methods;

        useEffect(() => {
            const loadDependencies = async () => {
                await getGroupsDropDownOptions(user);
                await getTagsDropDownSelectOptions(organization.id!);
            };

            const loadDeeplink = async () => {
                const deeplink = await getDeeplinkById(id);

                let formData: AddEditFormData = {
                    name: deeplink.name,
                    active: deeplink.status === "active",
                    url: deeplink.url ?? undefined
                };

                if (deeplink.groupId) {
                    formData = {
                        ...formData, group: {
                            label: deeplink.groupName ?? "",
                            value: deeplink.groupId ?? "",
                        },
                    }
                }

                if (deeplink.tags) {
                    formData.tags = await Promise.all(deeplink.tags
                        .map(async (tag) => {
                            try {
                                return {
                                    label: tag.name,
                                    value: tag.id
                                } as DropdownSelectOption;
                            } catch (error: any) {
                                throw error;
                            }
                        }));
                }

                if (deeplink.linkType) {
                    formData.linkType = SymmioAccessLinkTypeOptions.find((option) => option.value === deeplink.linkType);
                }

                reset({
                    name: formData.name,
                    group: formData.group,
                    active: formData.active,
                    tags: formData.tags,
                    startDate: deeplink.startDate,
                    endDate: deeplink.endDate,
                    url: formData.url,
                    limit: deeplink.limit ?? undefined,
                    used: deeplink.used ?? undefined,
                    linkType: formData.linkType,
                });

                setIsLoading(false);
            };

            loadDependencies();

            if (id) {
                loadDeeplink();
            } else {
                reset({
                    active: true
                });
                setIsLoading(false);
            }
        }, [getGroupsDropDownOptions, user, getTagsDropDownSelectOptions, id, getDeeplinkById, reset, getTagById, setValue, organization.id]);

        const isFormValid = (formData: AddEditFormData) => {
            if (!formData.startDate && !formData.endDate) {
                return true;
            }

            const start = DateTime.fromISO(formData.startDate!);
            const end = DateTime.fromISO(formData.endDate!);

            if (end < start) {
                setError("endDate",
                    {
                        type: "custom",
                        message: t('deeplinks.edit.err_endBeforeStart')
                    });
                return false;
            }

            return true;
        }

        const onSubmit = async (data: any) => {
            setIsLoading(true);

            // typecasting for use
            const formData = data as AddEditFormData;

            if (!isFormValid(formData)) {
                setIsLoading(false);
                return;
            }

            var entity = {
                name: formData.name,
                tags: [],
                status: formData.active ? "active" : "inactive",
                organizationId: state.organization.id
            } as Deeplink;

            if (formData.tags) {
                formData.tags.forEach((tag) => {
                    entity?.tags?.push({
                        id: tag.value,
                        name: tag.label
                    })
                });
            }

            if (formData.group) {
                try {
                    const group = await groupGetById(user, formData.group.value);

                    entity.groupId = group.id;
                    entity.groupName = group.name;
                } catch (error: any) {
                    switch (error.name) {
                        // swallowing because were handling it in
                        // finally block
                        case EntityNotFoundException.name:
                            break;
                        default:
                            // TODO : Global exception handling
                            // https://app.clickup.com/t/2219993/FMS-1236
                            break;
                    }
                } finally {
                    entity.groupId = formData.group.value;
                }
            } else {
                entity.groupId = null;
                entity.groupName = null;
            }

            entity.startDate = formData.startDate ?? null;
            entity.endDate = formData.endDate ?? null;

            entity.limit = formData.limit ?? null;
            entity.linkType = formData.linkType ? formData.linkType.value : SymmioAccessType.WebAccess;

            if (id) {
                entity.id = id;

                try {
                    await updateDeeplink(entity);
                } catch (err) {
                    console.error(t('deeplinks.edit.err_updateFail'));
                }
            } else {
                try {
                    entity.id = (await addDeeplink(entity)).id;
                    await FunctionService.generateDeeplinkUrl(entity.id!);

                } catch (err) {
                    console.error(t('deeplinks.edit.err_createFail'));
                    // TODO : Global exception handling
                    // https://app.clickup.com/t/2219993/FMS-1236
                }
            }
            id ? enqueueSnackbar(t('deeplinks.edit.linkUpdated'), { variant: "toast", width: "450px" }) : enqueueSnackbar(t('deeplinks.edit.linkCreated'), { variant: "toast", width: "450px" });
            history.goBack();
        };

        return (
            <div className="grid grid-cols-12 gap-8">
                <div className="col-span-12 max-w-screen-md">
                    <h2>{(!id) ? t('deeplinks.edit.addLink') : t('deeplinks.edit.editLink')}</h2>
                    <Loader isVisible={isLoading} />
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                                <TextTypeInput id="name"
                                    className="col-span-1"
                                    label={t('deeplinks.list.linkName')}
                                    registerHook={register}
                                    registerOptions={{ required: t('deeplinks.edit.val_linkName') }}
                                    errorState={errors.name} />
                                {/* <TextTypeInput id="url"
                                    className="col-span-1"
                                    label="Url"
                                    readonly={true}
                                    registerHook={register} /> */}
                            </div>
                            <DropdownSelect id="linkType"
                                label={t('deeplinks.list.linkType')}
                                optionsArray={SymmioAccessLinkTypeOptions}
                                control={control}
                                errorState={errors.linkType}
                                includeQuestionMarkIcon={true}
                                onQuestionMarkClick={() => setOpenLinkTypeModal(true)}
                            />
                            <DropdownSelect id="group"
                                label={t('deeplinks.list.group')}
                                optionsArray={groupsDropDownOptions}
                                control={control}
                                errorState={errors.group} />
                            <MultiSelect id="tags"
                                label={t('deeplinks.list.tags')}
                                control={control}
                                optionsArray={tagsDropDownOptions}
                                setValue={setValue}
                                errorState={errors.tags} />
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                <NumberTypeInput
                                    id="limit"
                                    defaultValue={100}
                                    label={t('deeplinks.edit.limit')}
                                    registerHook={register}
                                ></NumberTypeInput>
                                <NumberTypeInput
                                    id="used"
                                    label={t('deeplinks.edit.used')}
                                    readonly={true}
                                    registerHook={register}
                                ></NumberTypeInput>
                                <DatePicker id="startDate"
                                    className="col-span-1"
                                    label={t('deeplinks.edit.startDate')}
                                    control={control} />
                                <DatePicker id="endDate"
                                    className="col-span-1"
                                    label={t('deeplinks.edit.endDate')}
                                    control={control} />
                            </div>
                            <ToggleButton id="active"
                                register={register}
                                mainLabelText={t('deeplinks.edit.active')}
                                subLabelText="Testing this is test" />
                            <div className={'actions-container'}>
                                <Button
                                    buttonText={t('buttons.btn_cancel')}
                                    type="link"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        history.goBack();
                                    }}
                                    inputClassName="cancel" />
                                <Button
                                    buttonType="submit"
                                    buttonText={id ? t('buttons.btn_saveChanges') : t('deeplinks.edit.addLink')} />
                            </div>
                        </form>
                    </FormProvider>
                </div>

                <Modal
                    isOpen={openLinkTypeModal}
                    isLoading={false}
                    onClose={setOpenLinkTypeModal}
                    title={t('deeplinks.list.linkType')}
                >
                    <div>
                        <br />
                        <span>
                            {t('deeplinks.edit.linkType_modal.line1')}
                            <br />
                            <br />
                            <h4><strong>{t('deeplinks.edit.linkType_modal.assessmentOnly')}</strong></h4>
                            {t('deeplinks.edit.linkType_modal.assessmentOnly_desc')}
                            <br />
                            <br />
                            <h4><strong>{t('deeplinks.edit.linkType_modal.assessmentWellness')}</strong></h4>
                            {t('deeplinks.edit.linkType_modal.assessmentWellness_desc')}
                            <br />
                            <br />
                            {t('deeplinks.edit.linkType_modal.line2')}
                        </span>

                        {organization.mySymmioLicense !== undefined && organization.mySymmioLicenseCount !== undefined &&
                            <div className="my-6">
                                <PortalProgressBar total={organization.mySymmioLicense} currentProgress={organization.mySymmioLicenseCount} headerText={t('deeplinks.edit.linkType_modal.usedLicenseCount', { mySymmioLicenseCount: organization.mySymmioLicenseCount, mySymmioLicense: organization.mySymmioLicense })} />
                            </div>
                        }
                    </div>
                </Modal>
            </div>
        );
    };

export default LinkAddEditPage;
