import { DateTime } from "luxon";
import { ReportType } from "../../models/enumerations/report-type";
import { ReportAssessmentData } from "../../models/interfaces/reports/report-assessment-data";
import DonutChart from "../charts/donut-chart";
import ReportHeader from "./report-header";
import { ReactComponent as MovementIcon } from "../../assets/icons/outline/movement.svg";
import { ReactComponent as ActivityIcon } from "../../assets/icons/solid/activity-level.svg";
import { ReactComponent as BehavioralHealthIcon } from "../../assets/icons/solid/behavioral-health.svg";
import { ReactComponent as BodyCompIcon } from "../../assets/icons/solid/body-comp.svg";
import { ReactComponent as BreathingIcon } from "../../assets/icons/solid/breathing-qual.svg";
import { ReactComponent as InjuryHistoryIcon } from "../../assets/icons/solid/injury-history.svg";
import { ReactComponent as NutritionIcon } from "../../assets/icons/solid/nutrition-aware.svg";
import { ReactComponent as SleepIcon } from "../../assets/icons/solid/sleep-well.svg";
import { GroupIds } from "../../models/enumerations/group-ids";
import GroupUtil from "../../utilities/group-util";
import { useTranslation } from "react-i18next";

const COMPONENT_CLASS = "c-report";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportAssessmentProps {
    data: ReportAssessmentData;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Enumeration
// -----------------------------------------------------------------------------------------

enum FocusAreaGroupName {
    ACTIVITY_LEVEL = "Physical Activity",
    BEHAVIORAL_HEALTH = "Behavioral Health",
    BODY_COMPOSITION = "Body Composition",
    BREATHING = "Breathing Quality",
    INJURY_HISTORY = "Injury History",
    MOVEMENT_HEALTH = "Movement Health",
    NUTRITION_AWARENESS = "Nutrition Awareness",
    SLEEP_WELLNESS = "Sleep Wellness"
}

// #endregion Enumeration

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportAssessment: React.FC<ReportAssessmentProps> = (props: ReportAssessmentProps) => {
    const { data } = props;
    const { t } = useTranslation();

    let history = data.history;
    if (!history || history.length < 9) {
        history = history.concat(Array.from(Array(9 - (history.length || 0)).map((a) => ({} as any))));
    }

    let iconModifier = '';

    let scoreRange = "";
    let scoreRangeDescription = "";
    if (data.mskScore != null && data.mskScore >= 0) {
        switch (true) {
            case (data.mskScore >= 90):
                scoreRange = t('reports.assessment.scoreRange.great');
                scoreRangeDescription = t('reports.assessment.scoreRangeDescription.great');
                break;
            case (data.mskScore >= 80):
                scoreRange = t('reports.assessment.scoreRange.good');
                scoreRangeDescription = t('reports.assessment.scoreRangeDescription.good');
                break;
            case (data.mskScore >= 70):
                scoreRange = t('reports.assessment.scoreRange.okay');
                scoreRangeDescription = t('reports.assessment.scoreRangeDescription.okay');
                break;
            default:
                scoreRange = t('reports.assessment.scoreRange.poor');
                scoreRangeDescription = t('reports.assessment.scoreRangeDescription.poor');
                break;
        }
    }

    const formatScore = (percentage?: number): string => {
        if (percentage == null) {
            return t('general.notApplicable');
        }
        return `${percentage}%`;
    }

    const formatScoreAndPain = (percentage?: number, hasPain?: boolean) => {
        if (hasPain) {
            return <>
                {formatScore(percentage)}
                <span className={`${COMPONENT_CLASS}__current__item__group__pain`}>{t('reports.assessment.pain')}</span>
            </>;
        }

        return `${formatScore(percentage)}`;
    }

    const getIndicatorWidth = (percent?: number, isLabel: boolean = false): string => {
        let width: number = percent ?? 0;
        if (isLabel) {
            width += 5;
        }
        return `${width}%`;
    }

    const getFocusIcon = (focusGroupName: string): any => {
        switch (focusGroupName) {
            case FocusAreaGroupName.ACTIVITY_LEVEL:
                return (<ActivityIcon style={{ backgroundColor: "#FFA998" }} />);
            case FocusAreaGroupName.BEHAVIORAL_HEALTH:
                return (<BehavioralHealthIcon style={{ backgroundColor: "#8BE3CE" }} />);
            case FocusAreaGroupName.BODY_COMPOSITION:
                return (<BodyCompIcon style={{ backgroundColor: "#FFC79C" }} />);
            case FocusAreaGroupName.BREATHING:
                return (<BreathingIcon style={{ backgroundColor: "#ADDFFD" }} />);
            case FocusAreaGroupName.INJURY_HISTORY:
                return (<InjuryHistoryIcon style={{ backgroundColor: "#FFA998" }} />);
            case FocusAreaGroupName.MOVEMENT_HEALTH:
                return (<MovementIcon style={{ backgroundColor: "#ADDFFD" }} />);
            case FocusAreaGroupName.NUTRITION_AWARENESS:
                return (<NutritionIcon style={{ backgroundColor: "#8BE3CE" }} />);
            case FocusAreaGroupName.SLEEP_WELLNESS:
                return (<SleepIcon style={{ backgroundColor: "#CEBAF8" }} />);
            default:
                return (<div></div>);
        }
    }

    const getFocusDescription = (focusGroupName: string): string => {
        switch (focusGroupName) {
            case FocusAreaGroupName.ACTIVITY_LEVEL:
                return data.activityDescription ?? "";
            case FocusAreaGroupName.BEHAVIORAL_HEALTH:
                return data.behavioralDescription ?? "";
            case FocusAreaGroupName.BODY_COMPOSITION:
                return data.bodyCompDescription ?? "";
            case FocusAreaGroupName.BREATHING:
                return data.breathingDescription ?? "";
            case FocusAreaGroupName.INJURY_HISTORY:
                return data.injuryHistoryDescription ?? "";
            case FocusAreaGroupName.MOVEMENT_HEALTH:
                return data.movementDescription ?? "";
            case FocusAreaGroupName.NUTRITION_AWARENESS:
                return data.nutritionDescription ?? "";
            case FocusAreaGroupName.SLEEP_WELLNESS:
                return data.sleepDescription ?? "";
            default:
                return "";
        }
    }

    const hasAnyMovementPain = data.toeTouchHasPain
        || data.balanceHasPain
        || data.shoulderMobilityHasPain
        || data.squatHasPain
        || data.rotationHasPain
        || data.didPassShoulderClearing === false
        || data.didPassSpineClearing === false;
    const assessmentResults: any[] = [
        { groupId: GroupIds.MOVEMENT_HEALTH, score: data.movementScore, average: data.movementAverage, hasPain: hasAnyMovementPain },
        { groupId: GroupIds.NUTRITION, score: data.nutritionScore, average: data.nutritionAverage },
        { groupId: GroupIds.BREATHING, score: data.breathingScore, average: data.breathingAverage },
        { groupId: GroupIds.BEHAVIORAL_HEALTH, score: data.behavioralScore, average: data.behavioralAverage },
        { groupId: GroupIds.INJURY_HISTORY, score: data.injuryHistoryScore, average: data.injuryHistoryAverage, hasPain: data.injuryHistoryHadPain },
        { groupId: GroupIds.PHYSICAL_ACTIVITY, score: data.activityScore, average: data.activityAverage },
        { groupId: GroupIds.SLEEP, score: data.sleepScore, average: data.sleepAverage },
        { groupId: GroupIds.BODY_COMPOSITION, score: data.bodyCompScore, average: data.bodyCompAverage },
    ];

    return (
        <div className={`${COMPONENT_CLASS} -assessment`}>
            <ReportHeader
                data={data}
                name={data.name}
                dob={data.dob ? DateTime.fromISO(data.dob).toFormat("M/d/yyyy") : t('general.notApplicable')}
                reportDateOverride={(data.history && data.history.length > 0) ? DateTime.fromISO(data.history[0].date).toFormat("M/d/yyyy") : t('general.notApplicable')}
                reportType={ReportType.Assessment} />
            <div className={`${COMPONENT_CLASS}__current ${COMPONENT_CLASS}__section`}>
                <h2>{t('reports.assessment.yourCurrentScores')}</h2>
                <div className={`${COMPONENT_CLASS}__current__wrapper`}>
                    <DonutChart
                        percentage={Math.round(data.mskScore || 0)}
                        showTotal={false}
                        title={t('reports.assessment.yourWellnessScore')}
                    />
                    <div className={`${COMPONENT_CLASS}__current__inner`}>
                        {assessmentResults.map((assessmentResult, index) => {
                            return (
                                <div className={`${COMPONENT_CLASS}__current__item`} key={`assessment-summary-${index}`}>
                                    <div className={`${COMPONENT_CLASS}__current__item__group`}>{t(GroupUtil.getNameTranslationReference(assessmentResult.groupId))} <span>{formatScore(assessmentResult.score)}</span></div>
                                    <div className={`${COMPONENT_CLASS}__current__item__scale`}>
                                        <div style={{ "width": `${assessmentResult.score || 0}%` }}></div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className={`${COMPONENT_CLASS}__review ${COMPONENT_CLASS}__section`}>
                <div className={`${COMPONENT_CLASS}__review__icon`}>
                    <div className={`${COMPONENT_CLASS}__review__icon text`}>{scoreRange}</div>
                </div>
                <div className={`${COMPONENT_CLASS}__review__description`}>
                    {scoreRangeDescription}
                </div>
            </div>
            <div className={`${COMPONENT_CLASS}__history ${COMPONENT_CLASS}__section`}>
                <h2>{t('reports.assessment.yourScoreHistory')}</h2>
                <div className={`${COMPONENT_CLASS}__history__wrapper`}>
                    {history.map((item, index) => {
                        if (!item) {
                            return (
                                <div
                                    className={`${COMPONENT_CLASS}__history__item not-available`}
                                    key={`history-${index}`}>
                                    <div className={`${COMPONENT_CLASS}__history__item__score`}>{t('general.notApplicable')}</div>
                                    <div className={`${COMPONENT_CLASS}__history__item__date`}></div>
                                </div>
                            );
                        }

                        const date = DateTime.fromISO(item.date);
                        return (
                            <div
                                className={`${COMPONENT_CLASS}__history__item ${item.percent <= 0 ? "not-available" : ""} ${index === 0 ? "current" : ""}`}
                                key={`history-${index}`}>
                                <div className={`${COMPONENT_CLASS}__history__item__score`}>{formatScore(item.percent)}</div>
                                <div className={`${COMPONENT_CLASS}__history__item__date`}>{index === 0 ? t('reports.assessment.currently') : date.toFormat(`M/d${date.year !== DateTime.local().year ? "/yyyy" : ""}`)}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
            {data.focusAreas &&
                <div className={`${COMPONENT_CLASS}__focus ${COMPONENT_CLASS}__section`}>
                    <h2>{t('reports.assessment.yourFocuses')}</h2>
                    {data.focusAreas &&
                        <div className={`${COMPONENT_CLASS}__focus__wrapper`}>
                            {data.focusAreas.map((focusGroupName, index) => {
                                iconModifier = focusGroupName.replace(/\s+/g, '-').toLowerCase();
                                return (
                                    <div
                                        className={`${COMPONENT_CLASS}__focus__item`}
                                        key={`focus-${index}`}>
                                        <div className={`${COMPONENT_CLASS}__focus__item__wrapper`}>
                                            <div className={`${COMPONENT_CLASS}__focus__item__icon-container -${iconModifier}`}>
                                                {getFocusIcon(focusGroupName)}
                                            </div>
                                            <div>
                                                <div className={`${COMPONENT_CLASS}__focus__item__name`}>{focusGroupName}</div>
                                                <div className={`${COMPONENT_CLASS}__focus__item__description`} dangerouslySetInnerHTML={{ __html: getFocusDescription(focusGroupName) }}></div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    }
                </div>
            }
            <div className={`${COMPONENT_CLASS}__section ${COMPONENT_CLASS}__current`}>
                <h2>{t('reports.assessment.movementAssessmentDetails.header')}</h2>
                <p>{t('reports.assessment.movementAssessmentDetails.description')}</p>
                <div className={`${COMPONENT_CLASS}__current__wrapper`}>
                    <div className={`${COMPONENT_CLASS}__current__item`}>
                        <div className={`${COMPONENT_CLASS}__current__item__group`}>{t('movementFocus.toeTouch')} <span>{formatScoreAndPain(data.toeTouchScore, data.toeTouchHasPain)}</span></div>
                        <div className={`${COMPONENT_CLASS}__current__item__scale`}>
                            <div style={{ "width": `${data.toeTouchScore || 0}%` }}></div>
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__current__item`}>
                        <div className={`${COMPONENT_CLASS}__current__item__group`}>{t('movementFocus.balanceAndReach')} <span>{formatScoreAndPain(data.balanceScore, data.balanceHasPain)}</span></div>
                        <div className={`${COMPONENT_CLASS}__current__item__scale`}>
                            <div style={{ "width": `${data.balanceScore || 0}%` }}></div>
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__current__item`}>
                        <div className={`${COMPONENT_CLASS}__current__item__group ${data.didPassShoulderClearing === false ? "--pain" : ""}`}>{t('movementFocus.shoulderClearing')} <span>{data.didPassShoulderClearing == null ? t('general.notApplicable') : data.didPassShoulderClearing ? "PASS" : "PAIN"}</span></div>
                        <div className={`${COMPONENT_CLASS}__current__item__group ${data.didPassSpineClearing === false ? "--pain" : ""}`}>{t('movementFocus.spineClearing')} <span>{data.didPassSpineClearing == null ? t('general.notApplicable') : data.didPassSpineClearing ? "PASS" : "PAIN"}</span></div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__current__item`}>
                        <div className={`${COMPONENT_CLASS}__current__item__group`}>{t('movementFocus.shoulderMobility')} <span>{formatScoreAndPain(data.shoulderMobilityScore, data.shoulderMobilityHasPain)}</span></div>
                        <div className={`${COMPONENT_CLASS}__current__item__scale`}>
                            <div style={{ "width": `${data.shoulderMobilityScore || 0}%` }}></div>
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__current__item`}>
                        <div className={`${COMPONENT_CLASS}__current__item__group`}>{t('movementFocus.squat')} <span>{formatScoreAndPain(data.squatScore, data.squatHasPain || data.didPassSpineClearing === false)}</span></div>
                        <div className={`${COMPONENT_CLASS}__current__item__scale`}>
                            <div style={{ "width": `${data.squatScore || 0}%` }}></div>
                        </div>
                    </div>
                    <div></div>
                    <div className={`${COMPONENT_CLASS}__current__item`}>
                        <div className={`${COMPONENT_CLASS}__current__item__group`}>{t('movementFocus.rotation')} <span>{formatScoreAndPain(data.rotationScore, data.rotationHasPain || data.didPassShoulderClearing === false)}</span></div>
                        <div className={`${COMPONENT_CLASS}__current__item__scale`}>
                            <div style={{ "width": `${data.rotationScore || 0}%` }}></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${COMPONENT_CLASS}__section ${COMPONENT_CLASS}__current`}>
                <h2>{t('reports.assessment.functionalWellnessBreakdown.header')}</h2>
                <p>{t('reports.assessment.functionalWellnessBreakdown.description')}</p>
                <div className={`${COMPONENT_CLASS}__current__wrapper start`}>
                    {assessmentResults.map((assessmentResult, index) => {
                        return (
                            <div className={`${COMPONENT_CLASS}__current__item`} key={`assessment-detail-${index}`}>
                                <div className={`${COMPONENT_CLASS}__current__item__group`}>{t(GroupUtil.getNameTranslationReference(assessmentResult.groupId))} <span>{formatScoreAndPain(assessmentResult.score, assessmentResult.hasPain)}</span></div>
                                <div className={`${COMPONENT_CLASS}__current__item__scale`}>
                                    <div style={{ "width": `${assessmentResult.score ?? 0}%` }}></div>
                                </div>
                                <div>
                                    <div className={`${COMPONENT_CLASS}__current__item indicator`} style={{ "width": getIndicatorWidth(assessmentResult.average) }}></div>
                                    <div className={`${COMPONENT_CLASS}__current__item indicator-text`} style={{ "width": getIndicatorWidth(assessmentResult.average, true) }}>{t('reports.assessment.functionalWellnessBreakdown.avg')} {assessmentResult.average}</div>
                                </div>
                                <p>
                                    {GroupUtil.getDescription(assessmentResult.groupId)}
                                </p>
                            </div>
                        );
                    })}
                    { // if
                        data.averageAgeRange != null &&
                        <div className={`${COMPONENT_CLASS}__current__item average`}>
                            <p>
                                {t('reports.assessment.functionalWellnessBreakdown.averageRange', { averageAgeRange: data.averageAgeRange, biologicalSex: data.isFemale ? t('reports.assessment.functionalWellnessBreakdown.female') : t('reports.assessment.functionalWellnessBreakdown.male') })}
                            </p>
                            <p>
                                {t('reports.assessment.functionalWellnessBreakdown.yourResultsAreShown')}
                                {t('reports.assessment.functionalWellnessBreakdown.seeHowYouMeasureUp')}
                            </p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ReportAssessment;
