const COMPONENT_CLASS = "c-app-content-type-toggle";

interface AppContentTypeToggleProps {
    isOption1: boolean;
    isOption2: boolean;
    onClickOption1: () => void;
    onClickOption2: () => void;
    textOption1: string;
    textOption2: string;
}

const AppContentTypeToggle: React.FC<AppContentTypeToggleProps> = (props: AppContentTypeToggleProps) => {
    const {
        isOption1,
        isOption2,
        onClickOption1,
        onClickOption2,
        textOption1,
        textOption2,
    } = props;

    return (
        <div className={COMPONENT_CLASS}>
            <button type="button" onClick={onClickOption1} className={`${COMPONENT_CLASS}__app-content-pain ${isOption1 ? "active" : ""}`}>
                {textOption1}
            </button>
            <button type="button" onClick={onClickOption2} className={`${COMPONENT_CLASS}__app-content-behavioral ${isOption2 ? "active" : ""}`}>
                {textOption2}
            </button>
        </div>
    )
};

export default AppContentTypeToggle;