import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AddIcon } from "../../assets/icons/general-icons/icon_add.svg";
import { ReactComponent as LeadCaptureIcon } from "../../assets/icons/nav/icon_lead-capture.svg";
import { ReactComponent as SubscriptionLinkIcon } from "../../assets/icons/nav/icon_recurring-subscription.svg";
import { ReactComponent as ExportIcon } from "../../assets/icons/nav/icon_export.svg";
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import { useTranslation } from 'react-i18next';
import { PlanType } from '../../models/enumerations/plan-type';

const COMPONENT_CLASS = "organization-sub-menu";

type OrganizationSubMenuProps = {
};

export const OrganizationSubMenu: React.FC<OrganizationSubMenuProps> = (props: OrganizationSubMenuProps) => {
    const { state } = useAuthState();
    const { t } = useTranslation();

    return (
        <div className={COMPONENT_CLASS}>
            <div className='hover-buffer'></div>
            <div className="item-container">
                <Link
                    className='item'
                    to="/organization">
                    <AddIcon /> {t('sitemap.addOrg')}
                </Link>

                <Link
                    className='item'
                    to="/lead-capture">
                    <LeadCaptureIcon style={{ color: "#121622" }} /> {t('sitemap.leadCapture')}
                </Link>

                {state.organization.plan?.value === PlanType.ThirdParty &&
                    <Link
                        className='item'
                        to="/subscription-links">
                        <SubscriptionLinkIcon style={{ maxWidth: "17px", minWidth: "17px", height: "auto", color: "#121622" }} /> {t('sitemap.subLinks')}
                    </Link>
                }

                <Link
                    className='item'
                    to="/data-export">
                    <ExportIcon /> {t('sitemap.exportUsers')}
                </Link>
            </div>
        </div>

    );
}