import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import moment from "moment";
import { MovementHealthResponse } from "../../models/interfaces/reports/movement-health-response";
import NonLinearLineChart from "../charts/non-linear-line-chart";
import { Utils } from "../../utilities/utils";
import { useTranslation } from "react-i18next";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileSquatProps {
    mskScore?: MskScore;
    validMskScore: boolean;
    user: User;
    allMskScores: MskScore[];
    setAllMskScores: Function;
    movementResponses?: MovementHealthResponse;

}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileSquat: React.FC<UserProfileSquatProps> = (props: UserProfileSquatProps) => {
    const { mskScore, allMskScores, movementResponses, validMskScore } = props;
    const [squatHistory, setSquatHistory] = useState<{ x: string, y: number }[]>();
    const { t } = useTranslation();

    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempSquatHistory = []

                for (const mskScore of allMskScores) {
                    tempSquatHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.movementScore?.squatScore.percentage ?? 0
                    })
                }
                setSquatHistory(tempSquatHistory);
            }
        }
        getHistory();
    }, [allMskScores])

    return (
        <div className="flex gap-5">
            <div className={`${COMPONENT_CLASS}__score-section-card gap-4 items-center`}>
                <div className="text-base font-normal">{t('movementFocus.squat')}</div>

                <div className={`${COMPONENT_CLASS}__movement-group-score-card ${Utils.getGreenOrRedColor(mskScore?.movementScore?.squatScore.percentage, 75)}`}>
                    <div className={`${COMPONENT_CLASS}__movement-group-score-card__main-text`}>{mskScore?.movementScore?.squatScore.percentage}</div>
                    <div className={`${COMPONENT_CLASS}__movement-group-score-card__sub-text`}>{t('userProfile.assessments.goal75')}</div>
                </div>
                {validMskScore ?
                    <>
                        <div className="w-full flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                            {Utils.getAssessmentBreakdownCell(t('reports.wellness.squat'), mskScore?.movementScore?.squatScore.squat)}
                            {Utils.getAssessmentBreakdownCell(t('reports.wellness.fingertips'), mskScore?.movementScore?.squatScore.fingerTips)}
                            {Utils.getAssessmentBreakdownCell(t('reports.wellness.fists'), mskScore?.movementScore?.squatScore.fists)}
                        </div>
                        <div>
                            {Utils.getPainBadge(mskScore?.movementScore?.squatScore.painSeverity!)}
                        </div>
                    </>
                    : movementResponses ?
                        <>
                            <div className="w-full flex flex-col p-4 gap-1 bg-black-gray-5" style={{ borderRadius: "10px" }}>
                                {Utils.getAssessmentBreakdownCell(t('reports.wellness.squat'), movementResponses.squatLow.itemOne)}
                                {Utils.getAssessmentBreakdownCell(t('reports.wellness.fingertips'), movementResponses.squatLow.itemTwo)}
                                {Utils.getAssessmentBreakdownCell(t('reports.wellness.fists'), movementResponses.squatLow.itemThree)}
                            </div>
                            <div>
                                {Utils.getPainBadge(movementResponses.squatQPain)}
                            </div>
                        </>
                        :
                        <div></div>
                }

            </div>

            {squatHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                    <NonLinearLineChart
                        dataPoints={squatHistory.map(history => history.y)}
                        labels={squatHistory.map(history => history.x)}
                        title={t('userProfile.assessments.squatHistory')}
                        sectionDetails={{
                            sections: 2,
                            rangeOne: { start: 0, end: 49 },
                            rangeTwo: { start: 50, end: 100 },
                            backgroundColorOrder: { first: "#FFA998", second: "#8BE3CE" },
                            dataPointColorOrder: { first: "#E7514F", second: "#00DFBC" },
                            yAxisLabels: { first: t('userProfile.assessments.below'), second: t('userProfile.assessments.above') },
                            decimals: false
                        }}
                        toolTipLabel={true} />
                </div>
            )}
        </div>
    );
}

export default UserProfileSquat;
