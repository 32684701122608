/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Select from "react-select";
import LineChartPoint from "../../models/interfaces/charts/line-chart-point";
import { Group } from "../../models/interfaces/group";
import { DashboardData } from "../../models/interfaces/reports/dashboard-data";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import FunctionService from "../../utilities/services/function-service";
import GroupService from "../../utilities/services/group-service";
import UserUtil from "../../utilities/user-util";
import { Utils } from "../../utilities/utils";
import DonutChart from "../charts/donut-chart";
import LineChart from "../charts/line-chart";
import { Loader } from "../loader/loader";
import PillChartArray from "../charts/pill-chart/pill-chart-array";
import UserService from "../../utilities/services/user-service";
import { Modal } from "../modal/modal";
import welcomeImage from "../../assets/images/welcome.png";
import { ToggleButton } from "../forms";
import { useTranslation } from 'react-i18next';
import { MskHealthRiskValues } from "../../models/msk-health-risk";
import GroupUtil from "../../utilities/group-util";

const COMPONENT_CLASS = "c-dashboard";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface DashboardProps {
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Dashboard: React.FC<DashboardProps> = (props: DashboardProps) => {
    const [lineChartPoints, setLineChartPoints] = useState<LineChartPoint[]>();
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingGroups, setIsLoadingGroups] = useState(false);
    const [dashboardData, setDashboardData] = useState<DashboardData | null>();
    const [groups, setGroups] = useState<Group[]>([]);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const { state, setState } = useAuthState();
    const [includeInactiveUsers, setIncludeInactiveUsers] = useState(state.dashboard ? state.dashboard.choices.includeInactive : true);
    const [selectedGroupId, setSelectedGroupId] = useState<string | null>(state.dashboard ? state.dashboard.choices.groupId : '');
    const isAccountHolder = UserUtil.isAccountHolder(state.user);
    const isAdmin = UserUtil.isAdmin(state.user);
    const isSuperAdmin = UserUtil.isSuperAdmin(state.claims);
    const { t } = useTranslation();

    const statusModalContent = {
        showReactivatedPlan: { title: t('dashboard.showReactivatedPlan.title'), boldText: t('dashboard.showReactivatedPlan.boldText'), mainText: t('dashboard.showReactivatedPlan.mainText') },

        showReactivatedAddOn: { title: t('dashboard.showReactivatedAddOn.title'), boldText: t('dashboard.showReactivatedAddOn.boldText'), mainText: t('dashboard.showReactivatedAddOn.mainText') },

        showCancelledPlan: { title: t('dashboard.showCancelledPlan.title'), boldText: t('dashboard.showCancelledPlan.boldText'), mainText: t('dashboard.showCancelledPlan.mainText') },

        showCancelledAddOn: { title: t('dashboard.showCancelledAddOn.title'), boldText: t('dashboard.showCancelledAddOn.boldText'), mainText: t('dashboard.showCancelledAddOn.mainText') },
    };

    const params = new URLSearchParams(window.location.search);
    type SubscriptionStatus = keyof typeof statusModalContent;
    const subscriptionStatusModalParam = params.get("subscriptionStatusModal") as SubscriptionStatus;
    const [showSubscriptionStatusModal, setShowSubscriptionStatusModal] = useState(subscriptionStatusModalParam ? true : false);

    const getRiskNames = (category: any) => {
        const util = Utils.getHealthRiskValue(category);
        switch (util) {
            case MskHealthRiskValues.Health: return t('dashboard.risk_high');
            case MskHealthRiskValues.Wellness: return t('dashboard.risk_mod');
            case MskHealthRiskValues.Fitness: return t('dashboard.risk_slight');
            case MskHealthRiskValues.Performance: return t('dashboard.risk_low');
            default: return "..."
        }
    }

    useEffect(() => {
        if (state.organization?.id == null) {
            return;
        }

        let allowAllGroups = false;
        let groupId: string | null = null;

        if (isSuperAdmin || isAdmin || isAccountHolder) {
            allowAllGroups = true;
        } else if (UserUtil.isManagerOrTeamMember(state.user)) {
            groupId = UserUtil.getDefaultManageGroupId(state.user);
        }

        setSelectedGroupId(groupId);

        // do not load additional data
        if (groupId == null && allowAllGroups === false) {
            setGroups([]);
            return;
        }

        setIsLoadingGroups(true);
        GroupService.getSnapshotBy([{
            field: "organizationId",
            operator: "==",
            value: state.organization?.id,
        }], [{
            field: "name",
            direction: "asc"
        }], (groups: Group[]) => {
            if (allowAllGroups) {
                groups.unshift({
                    id: "",
                    name: t('general.all'),
                    organizationId: state.organization?.id!,
                });
            }
            setGroups(groups);
            setIsLoadingGroups(false);
        });
    }, [state.user, state.user?.managedGroupIds, state.organization?.id, isAccountHolder, isAdmin, isSuperAdmin]);

    useEffect(() => {
        const fetchUser = async () => {
            if (state.user && state.user?.id) {
                const user = await UserService.get(state.user?.id);

                if (user) {
                    setState((state) => ({
                        ...state, ...{ user: user }
                    }));
                }
            }
        }

        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setShowWelcomeModal(state.user?.showWelcomeScreen || false);
    }, [state.user]);

    useEffect(() => {
        if (state.dashboard) {
            if (state.dashboard.data) {
                setDashboardData(state.dashboard.data);
            }
            if (state.dashboard.lineChart) {
                setLineChartPoints(state.dashboard.lineChart);
            }
        } else {
            if (state.dashboard === null && groups) {
                setIncludeInactiveUsers(true);
                fetchDashboardData(true, '');
            }
        }
    }, [groups]);

    const fetchDashboardData = (includeInactive: boolean = true, groupId: string = '') => {
        if (groupId === undefined || groups == null || groups.length === 0) {
            return;
        }

        setIsLoadingData(true);
        const groupIds = groupId != null && groupId !== "" ? [groupId] :
            isAdmin
                ? null
                : state.user?.managedGroupIds ?? null;

        FunctionService.getDashboardData(groupIds, state.organization?.id!, includeInactive).then(data => {
            setState((state) => ({
                ...state, ...{
                    dashboard: {
                        data: data, lineChart: data?.history.map((d) => {
                            const date = d.date.split("-");
                            return {
                                created: new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2])),
                                percentage: d.percent
                            };
                        }), choices: {
                            includeInactive: includeInactive,
                            groupId: groupId
                        }
                    }
                }
            }));
            setDashboardData(data);
            setLineChartPoints(data?.history.map((d) => {
                const date = d.date.split("-");
                return {
                    created: new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2])),
                    percentage: d.percent
                };
            }));
            setIsLoadingData(false);
        });
    }

    const getGroupOptions = () => {
        if (isAdmin || isAccountHolder) {
            return groups.map((g) => ({ label: g.name, value: g.id }));
        }

        return groups.filter(group => UserUtil.canManageGroup(state.user, group))
            .map((g) => ({ label: g.name, value: g.id }));
    }

    // const handleStartTour = async () => {
    //     const editUser = state.user;

    //     if (editUser) {
    //         editUser.showWelcomeScreen = false;
    //         await UserService.update(editUser);
    //         setShowWelcomeModal(false);
    //         setState((state) => ({
    //             ...state, ...{ user: editUser }
    //         }));
    //     }
    // }

    if (!isLoadingData && !isLoadingGroups && groups.length === 0) {
        return (
            <div className={COMPONENT_CLASS}>
                <div className={`${COMPONENT_CLASS}__header`}>
                    <h1>{t('dashboard.header')}</h1>
                </div>
                <h2>{t('dashboard.noGroups')}</h2>
            </div>
        );
    }

    return (
        <div className={COMPONENT_CLASS}>
            <Loader
                isVisible={isLoadingData} />
            <div className={`${COMPONENT_CLASS}__header`}>
                <h1>{t('dashboard.header')}</h1>
                <div className={`${COMPONENT_CLASS}__status__group`}>
                    <Select
                        name="group"
                        classNamePrefix={COMPONENT_CLASS}
                        placeholder={t('deeplinks.list.group')}
                        options={getGroupOptions()}
                        onChange={(newValue: any, actionMeta: any) => { setSelectedGroupId(newValue.value); fetchDashboardData(includeInactiveUsers, newValue.value); }}
                        value={{ label: groups.find(g => g.id === (state.dashboard ? state.dashboard.choices.groupId : selectedGroupId))?.name || t('general.all'), value: state.dashboard ? state.dashboard.choices.groupId : selectedGroupId || 0 }}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderRadius: "1.5rem",
                                lineHeight: "1.5rem",
                            }),
                        }} />
                </div>
            </div>
            <div className={`${COMPONENT_CLASS}__status`}>
                { // if
                    dashboardData != null &&
                    <div className={`${COMPONENT_CLASS}__status__container`}>
                        <div className={`${COMPONENT_CLASS}__status__item__wrapper`}>
                            <div className={`${COMPONENT_CLASS}__status__item`}>
                                {dashboardData?.activeUsers}
                            </div>
                            <h6>{t('dashboard.acc_active')}</h6>
                        </div>
                        <div className={`${COMPONENT_CLASS}__status__item__wrapper`}>
                            <div className={`${COMPONENT_CLASS}__status__item`}>
                                {dashboardData?.inactiveUsers}
                            </div>
                            <h6>{t('dashboard.acc_inactive')}</h6>
                        </div>
                        <div className={`${COMPONENT_CLASS}__status__item__wrapper`}>
                            <div className={`${COMPONENT_CLASS}__status__item`}>
                                {dashboardData?.pendingUsers}
                            </div>
                            <h6>{t('dashboard.acc_pending')}</h6>
                        </div>
                    </div>
                }
            </div>
            <div className={`${COMPONENT_CLASS}__statistics`}>

                <div className="grid grid-cols-3 gap-2 clear-both">
                    { // if
                        dashboardData != null &&
                        dashboardData?.percent !== -1 &&
                        <div className={`${COMPONENT_CLASS}__score-summary`}>
                            <h3>{t('dashboard.overallScore')}</h3>
                            <DonutChart
                                percentage={dashboardData?.percent || 0}
                                title={t('dashboard.avgWellness')}
                            />
                        </div>
                    }
                    { // if
                        lineChartPoints != null &&
                        <div className={`${COMPONENT_CLASS}__score-line`}>
                            <div className={`${COMPONENT_CLASS}__statistics__container`}>
                                <h3>{t('dashboard.scoreHistory')}</h3>
                                <ToggleButton
                                    id="inactiveUsersToggle"
                                    isChecked={includeInactiveUsers}
                                    onClick={() => {
                                        setIncludeInactiveUsers(!includeInactiveUsers);
                                        fetchDashboardData(!includeInactiveUsers, selectedGroupId ?? '');
                                    }}
                                    mainLabelText={t('dashboard.include_acc_inactive')}
                                    isRightSideLabels={true}
                                    containerStyle={{ marginTop: "0", alignItems: "center" }} />
                            </div>
                            <LineChart
                                axisFormat="%b"
                                data={lineChartPoints}
                                title={t('dashboard.scoreHistory')}
                            />
                        </div>
                    }

                </div>
                {/* {
                    lineChartPoints && <MonthlyScoreChart
                        data={lineChartPoints}
                        title="Score History"
                    />
                } */}
            </div>
            {
                dashboardData?.scoreBreakdown != null &&
                dashboardData.scoreBreakdown.length >= 1 &&
                <div className={`${COMPONENT_CLASS}__score`}>
                    <PillChartArray
                        data={dashboardData?.scoreBreakdown.map((score) => ({ range: `${score.name}`, value: score.count }))}
                    />
                </div>
            }
            { // if
                dashboardData?.riskFactorCodeCounts != null &&
                dashboardData.riskFactorCodeCounts.length >= 1 &&
                <div className={`${COMPONENT_CLASS}__injury`}>
                    <h3 style={{ marginBottom: "30px" }}>{t('general.msk')}</h3>
                    <p className="opacity-60 mb-8 font-light text-sm">{t('dashboard.msk_desc')}</p>
                    <div className={`${COMPONENT_CLASS}__injury__container`}>
                        {dashboardData?.riskFactorCodeCounts.map((category, index) => {
                            return (
                                <div
                                    className={`${COMPONENT_CLASS}__injury__item__wrapper`}
                                    key={`category-${index}`}>
                                    <div className={`${COMPONENT_CLASS}__injury__item`}>
                                        {category.count}
                                    </div>
                                    <h6>{getRiskNames(category.name)}</h6>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
            { // if
                dashboardData?.focusAreas != null &&
                dashboardData.focusAreas.length >= 1 &&
                <>
                    <hr className="line" />
                    <div className={`${COMPONENT_CLASS}__focuses`}>
                        <h3 style={{ marginBottom: "30px" }}>{t('dashboard.focusBreak')}</h3>
                        <p className="opacity-60 mb-8 font-light text-sm">{t('dashboard.focusBreak_desc')}</p>
                        <div className={`${COMPONENT_CLASS}__focuses__wrapper`}>
                            {dashboardData.focusAreas.map((area, index) => {
                                return (
                                    <div
                                        className={`${COMPONENT_CLASS}__focuses__item`}
                                        key={index}>
                                        <span>{area.count}</span> {GroupUtil.getName(area.groupId)}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </>
            }

            <Modal
                isOpen={showWelcomeModal}
                isLoading={false}
                defaultCenterModal={true}
                onCancel={() => setShowWelcomeModal(false)}
                onSubmit={() => setShowWelcomeModal(false)}
                cancelButtonText={t('dashboard.welcomeModal.btn_skipTour')}
                submitButtonText={t('buttons.btn_getStarted')}

            >
                <div className={`${COMPONENT_CLASS}__welcome-modal-content`}>
                    <div className={`${COMPONENT_CLASS}__welcome-image-container`}>
                        <img src={welcomeImage} alt="Welcome" />
                    </div>
                    <h3>{t('dashboard.welcomeModal.title')}</h3>
                    <p>
                        {t('dashboard.welcomeModal.mainText')}
                    </p>
                </div>
            </Modal >

            {subscriptionStatusModalParam && statusModalContent[subscriptionStatusModalParam] &&
                <Modal
                    isOpen={showSubscriptionStatusModal}
                    isLoading={false}
                    onClose={setShowSubscriptionStatusModal}
                    defaultCenterModal={true}
                    submitButtonText={t('buttons.btn_continue')}
                    onSubmit={() => setShowSubscriptionStatusModal(false)}
                    title={statusModalContent[subscriptionStatusModalParam].title}
                >
                    <div>
                        <p className="whitespace-pre-line">
                            <strong>{statusModalContent[subscriptionStatusModalParam].boldText}</strong>
                            <br />
                            {statusModalContent[subscriptionStatusModalParam].mainText}
                        </p>
                    </div>
                </Modal >
            }
        </div >
    );
}

export default Dashboard;