/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

import { Button } from "../button/button";
import { ReactComponent as IconOrg } from "../../assets/icons/solid/icon_org-structure-colored.svg";
import { ReactComponent as IconSymmio } from "../../assets/icons/symmio-icons/icon_my-symmio-license.svg";
import { ReactComponent as IconUsers } from "../../assets/icons/solid/icon_users-colored.svg";
import { Modal } from '../modal/modal';
import AddOnDialogBox from "../add-on-dialog-box/add-on-dialog-box";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import OrganizationSubscriptionService from "../../utilities/services/organization-subscription-service";
import moment from "moment";
import OrganizationService from "../../utilities/services/organization-service";
import { Organization } from "../../models/interfaces/organization";
import { Dialog } from "@headlessui/react";
import { AddOnsToBeRemoved } from "../../models/interfaces/add-ons-to-be-removed";
import { AddOnId } from "../../models/enumerations/add-on-id";
import UserUtil from "../../utilities/user-util";
import { NumberTypeInput } from "../forms/number-type-input/number-type-input";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
// import UserService from "../../utilities/services/user-service";
// import { collection, doc, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore";
// import FunctionService from "../../utilities/services/function-service";

interface AddOnProps {
  products: any[];
}

interface Recommendations {
  licenses: number,
  users: number,
  //organizations: number,
}
const COMPONENT_CLASS = "c-add-ons";

const AddOns: React.FC<AddOnProps> = (props: AddOnProps) => {
  const {
    products
  } = props;
  const {
    register,
  } = useForm<any>();
  const [, setUserCount] = useState(0);
  const [, setUserLimit] = useState(0);
  const [, setOrgSub] = useState<any>();
  const { t } = useTranslation();
  const [organization, setOrganization] = useState<Organization>();
  const [dynamicUserPrice, setDynamicUserPrice] = useState<number>(products ? products.find((item) => item.product.id === AddOnId.User).prices.find((item: any) => item.data.active === true).data.unit_amount / 100 : NaN);
  const [dynamicLicensePrice, setDynamicLicensePrice] = useState<number>(products ? products.find((item) => item.product.id === AddOnId.License).prices.find((item: any) => item.data.active === true).data.unit_amount / 100 : NaN);
  const [addOnRemovedModalOpen, setAddOnRemovedModalOpen] = useState<boolean>(false);
  const [recommendations, setRecommendations] = useState<Recommendations>({
    licenses: 0,
    users: 0,
  });
  const [formValues, setFormValues] = useState([
    {
      key: "licenses",
      value: 1
    },
    {
      key: "users",
      value: 1
    },
  ]);
  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const [openAddOn, setOpenAddOn] = useState(false);
  const [removeAddOn, setRemoveAddOn] = useState(false);
  const [selectedAddOn, setSelectedAddOn] = useState(0);
  const [mySelectedAddOn, setMySelectedAddOn] = useState(0);
  const [isSaving] = useState(false);
  const { state } = useAuthState();
  const isAccountHolder = UserUtil.isAccountHolder(state.user);
  const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
  const [myAddOns, setMyAddOns] = useState<any[]>()
  const [removedAddOnInfo, setRemovedAddOnInfo] = useState<AddOnsToBeRemoved>({
    name: "",
    amountToBeRemoved: 0,
    amountManaged: 0,
    billingCycleEnd: "",
  })
  const [addOnPurchaseComplete, setAddOnPurchaseComplete] = useState<boolean>(false);
  const [addOnRemoveComplete, setAddOnRemoveComplete] = useState<boolean>(false);
  // Get Current Org
  useEffect(() => {
    getOrganization();
  }, []);

  useEffect(() => {
    setUserCount(60);
    setUserLimit(100);

    //const organization = state.organization;
    if (organization) {
      setRecommendations({
        // Change first value in users for te demo. Actual line is commented out
        users: (Number(organization!.userCount) / Number(organization!.userLimit)) * 100,
        licenses: (Number(organization!.mySymmioLicenseCount) / Number(organization!.mySymmioLicense)) * 100,
      })
    }
    if (addOns) {
      addOns.sort((a, b) => b.percentage - a.percentage);
    }

  }, [organization]);
  const calculatePercentage = (category: string) => {
    switch (category) {
      case 'users':
        return recommendations.users;
      case 'licenses':
        return recommendations.licenses;
      default:
        return 0;
    }

  };

  const getPricingByPriceId = (quantityToBeAdded: any, priceId: string) => {
    const price = products.find((item) => item.prices.find((price: any) => price.id === priceId)).prices.find((price: any) => price.id === priceId).data.unit_amount
    return parseInt(quantityToBeAdded) * price;
  }
  const getPricingByAddOnId = (quantityToBeAdded: any, addOnId: AddOnId) => {
    const price = products.find((item) => item.product.id === addOnId).prices.find((item: any) => item.data.active === true).data.unit_amount
    return parseInt(quantityToBeAdded) * price;

  }

  let addOns = [
    {
      icon: "super-admin",
      name: products.find((item) => item.product.id === AddOnId.License).product.data.name,
      subName: t('addOns.licenses.title'),
      id: "licenses",
      price: dynamicLicensePrice,
      description: t('addOns.licenses.description'),
      descriptionBullets: [t('addOns.licenses.descBullet1'),
      t('addOns.licenses.descBullet2'),
      t('addOns.licenses.descBullet3'),
      t('addOns.licenses.descBullet4')],
      summaryDescription: [`Subscribing to this add-on will increase your Wellness Program limit to`,
        `available programs. You will be charged `,
        ` / month for this add-on, and your additional Wellness Programs will be available immediately.*`],
      recommended: calculatePercentage('licenses') >= 75,
      percentage: calculatePercentage('licenses'),
      priceId: products.find((item) => item.product.id === AddOnId.License).prices.find((item: any) => item.data.active === true).id,
    },
    {
      icon: "users",
      name: products.find((item) => item.product.id === AddOnId.User).product.data.name,
      subName: t('addOns.users.title'),
      id: "users",
      price: dynamicUserPrice,
      description: t('addOns.users.description'),
      descriptionBullets: [t('addOns.users.descBullet1'),
      t('addOns.users.descBullet2'),
      t('addOns.users.descBullet3'),
      t('addOns.users.descBullet4')],
      summaryDescription: [`Subscribing to this add-on will increase your user limit to`,
        `users total. You will be charged`,
        `/ month for this add-on, and your additional users will be available immediately.*`],
      recommended: calculatePercentage('users') >= 75,
      percentage: calculatePercentage('users'),
      priceId: products.find((item) => item.product.id === AddOnId.User).prices.find((item: any) => item.data.active === true).id
    }
  ];
  addOns.sort((a: any, b: any) => { return b.percentage - a.percentage; });

  useEffect(() => {
    const sortFormValues = () => {
      setFormValues(formValues.sort((a, b) => {
        return addOns.findIndex(p => p.id === a.key) - addOns.findIndex(p => p.id === b.key);
      }));
    }
    sortFormValues();
  }, [addOns])

  // Get current add-ons
  useEffect(() => {
    const getCurrentAddOns = async () => {
      await OrganizationSubscriptionService.getByOrganizationId(state.organization.id!).then(async (orgSub) => {
        setOrgSub(orgSub[0]);
        if (orgSub[0].stripeAddOnSubscription) {
          await stripe.subscriptions.retrieve(orgSub[0].stripeAddOnSubscription).then(async (sub: any) => {
            let d = new Date(sub.current_period_end * 1000);
            let userAddOns: any[] = [];
            if (sub.schedule) {
              // Retrieve the schedule
              const subscriptionSchedule = await stripe.subscriptionSchedules.retrieve(sub.schedule);
              sub.items.data.forEach((item: any) => {
                if (item.quantity !== 0) {
                  const futurePhaseExists = !((subscriptionSchedule.phases.at(-1).start_date === subscriptionSchedule.current_phase.start_date) &&
                    (subscriptionSchedule.phases.at(-1).end_date === subscriptionSchedule.current_phase.end_date));

                  // Checking if a future phase already exists
                  if (futurePhaseExists) {

                    // Find item index within last phase
                    const index = subscriptionSchedule.phases.at(-1).items.findIndex((subScheduleItem: any) => subScheduleItem.price === item.price.id);
                    userAddOns.push({
                      icon: `${addOns.find((addOn: any) => addOn.priceId === item.price.id)?.icon}`,
                      subName: `${addOns.find((addOn: any) => addOn.priceId === item.price.id)?.subName}`,
                      name: `${products.find((outerItem) => outerItem.product.id === products.find((innerItem) => innerItem.prices.find((item: any) => item.data.active === true).id === item.price.id).product.id).product.data.name}`,
                      stripeName: `${products.find((outerItem) => outerItem.product.id === products.find((innerItem) => innerItem.prices.find((item: any) => item.data.active === true).id === item.price.id).product.id).product.data.name}`,
                      price: (getPricingByPriceId(item.quantity, item.price.id) / 100).toFixed(2), /*item.quantity * item.plan.amount / 100, */
                      pricePer: item.plan.amount,
                      quantity: item.quantity,
                      recommended: false,
                      nextPayment: moment(d).format('MMMM D, Y'),
                      priceId: item.price.id,
                      removedQuantity: subscriptionSchedule.phases.at(-1).items[index].quantity === item.quantity ? 0 : item.quantity - subscriptionSchedule.phases.at(-1).items[index].quantity,
                      description: `${addOns.find((addOn: any) => addOn.priceId === item.price.id)?.description}`,
                      descriptionBullets: `${addOns.find((addOn: any) => addOn.priceId === item.price.id)?.descriptionBullets}`,
                      summaryDescription: `${addOns.find((addOn: any) => addOn.priceId === item.price.id)?.summaryDescription}`
                    })
                  }
                  else {
                    userAddOns.push({
                      icon: `${addOns.find((addOn: any) => addOn.priceId === item.price.id)?.icon}`,
                      subName: `${addOns.find((addOn: any) => addOn.priceId === item.price.id)?.subName}`,
                      name: `${products.find((outerItem) => outerItem.product.id === products.find((innerItem) => innerItem.prices.find((item: any) => item.data.active === true).id === item.price.id).product.id).product.data.name}`,
                      stripeName: `${products.find((outerItem) => outerItem.product.id === products.find((innerItem) => innerItem.prices.find((item: any) => item.data.active === true).id === item.price.id).product.id).product.data.name}`,
                      price: (getPricingByPriceId(item.quantity, item.price.id) / 100).toFixed(2), /*item.quantity * item.plan.amount / 100, */
                      pricePer: item.plan.amount,
                      quantity: item.quantity,
                      recommended: false,
                      nextPayment: moment(d).format('MMMM D, Y'),
                      priceId: item.price.id,
                      removedQuantity: 0,
                      description: `${addOns.find((addOn: any) => addOn.priceId === item.price.id)?.description}`,
                      descriptionBullets: `${addOns.find((addOn: any) => addOn.priceId === item.price.id)?.descriptionBullets}`,
                      summaryDescription: `${addOns.find((addOn: any) => addOn.priceId === item.price.id)?.summaryDescription}`
                    })
                  }
                }
              })
            }
            else {
              sub.items.data.forEach((item: any) => {
                if (item.quantity !== 0) {
                  userAddOns.push({
                    icon: `${addOns.find((addOn: any) => addOn.priceId === item.price.id)?.icon}`,
                    subName: `${addOns.find((addOn: any) => addOn.priceId === item.price.id)?.subName}`,
                    name: `${products.find((outerItem) => outerItem.product.id === products.find((innerItem) => innerItem.prices.find((item: any) => item.data.active === true).id === item.price.id).product.id).product.data.name}`,
                    stripeName: `${products.find((outerItem) => outerItem.product.id === products.find((innerItem) => innerItem.prices.find((item: any) => item.data.active === true).id === item.price.id).product.id).product.data.name}`,
                    price: (getPricingByPriceId(item.quantity, item.price.id) / 100).toFixed(2), /*item.quantity * item.plan.amount / 100, */
                    pricePer: item.plan.amount,
                    quantity: item.quantity,
                    recommended: false,
                    nextPayment: moment(d).format('MMMM D, Y'),
                    priceId: item.price.id,
                    removedQuantity: 0,
                    description: `${addOns.find((addOn: any) => addOn.priceId === item.price.id)?.description}`,
                    descriptionBullets: `${addOns.find((addOn: any) => addOn.priceId === item.price.id)?.descriptionBullets}`,
                    summaryDescription: `${addOns.find((addOn: any) => addOn.priceId === item.price.id)?.summaryDescription}`
                  })
                }
              })
            }
            setMyAddOns(userAddOns);
          })
        }
      })
      await getOrganization();
    }
    getCurrentAddOns();
    setAddOnPurchaseComplete(false);
    setAddOnRemoveComplete(false);
  }, [addOnPurchaseComplete, addOnRemoveComplete])

  const getOrganization = async () => {
    if (state.organization.id) {
      await OrganizationService.get(state.organization.id).then(async (org) => {
        if (org) {
          await setOrganization(org);
        }
      })
    }
  }

  const getAddOnStyle = (category: string) => {
    var percentage = calculatePercentage(category);
    if (percentage >= 75 && percentage < 90) {
      return { border: '3px solid #FFC79C', borderRadius: '0 0 10px 10px' }
    } else if (percentage >= 90) {
      return { border: '3px solid #E7514F', borderRadius: '0 0 10px 10px' }
    } else {
      return {};
    }
  };

  const getLabelStyle = (category: string) => {
    var percentage = calculatePercentage(category);
    if (percentage >= 75 && percentage <= 89) {
      return { color: 'black', backgroundColor: '#FFC79C' }
    } else if (percentage > 89) {
      return { color: 'white', backgroundColor: '#E7514F' }
    } else {
      return { display: 'none' };
    }
  };

  const getIcon = (icon: string | undefined) => {
    switch (icon) {
      case 'users':
        return <IconUsers />;
      case 'super-admin':
        return <IconSymmio />;
      case 'org-structure':
        return <IconOrg />;
    }
  }

  const updateAddOnPrice = (index: number, value: number) => {
    if (addOns[index].id === "licenses") {
      if (isNaN(value)) {
        setDynamicLicensePrice(0);
        return;
      }
      else {
        setDynamicLicensePrice(parseFloat((getPricingByAddOnId(value, AddOnId.License) / 100).toFixed(2)))
      }
    }
    else if (addOns[index].id === "users") {
      if (isNaN(value)) {
        setDynamicUserPrice(0);
        return;
      }
      else {
        setDynamicUserPrice(parseFloat((getPricingByAddOnId(value, AddOnId.User) / 100).toFixed(2)))
      }
    }
  }

  return (
    <div className={COMPONENT_CLASS}>
      {
        myAddOns && myAddOns.length > 0 && (
          <>
            <div className={`${COMPONENT_CLASS}__heading`}>
              <h3>{t('addOns.heading')}</h3>
            </div>

            <div className={`${COMPONENT_CLASS}__add-on-list`}>
              {myAddOns.map((addOn, index) => (
                <>
                  <div
                    className={`${COMPONENT_CLASS}__add-on-list-row`}
                    key={`addons_${index}`}
                    onClick={() => {
                      // setSelectedAddOn(index);
                      // setOpenAddOn(true);
                    }}
                  >
                    <div className={`${COMPONENT_CLASS}__icon-column`}>
                      <div>
                        {getIcon(addOn.icon)}
                      </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__information-container`}>
                      <div className={`${COMPONENT_CLASS}__description-column`}>
                        <span className={`${COMPONENT_CLASS}__product-name`}>{addOn.quantity} {addOn.stripeName}</span>
                        {
                          addOn.removedQuantity !== 0 && (
                            <span className={`${COMPONENT_CLASS}__product-description`}>{t('addOns.addOnRemoved', { addOnName: addOn.name, addOnQuantity: addOn.quantity - addOn.removedQuantity, addOnNextPayment: addOn.nextPayment })}</span>
                          )
                        }
                      </div>

                      <div className={`${COMPONENT_CLASS}__price-and-button-container`}>
                        <div className={`${COMPONENT_CLASS}__price-column`}>
                          <div dangerouslySetInnerHTML={{ __html: t('addOns.monthlyPrice', { price: addOn.price, className: COMPONENT_CLASS }) }}>
                          </div>
                          <div className={`${COMPONENT_CLASS}__payment-cycle-text`}>{t('addOns.nextPayment', { addOnNextPayment: addOn.nextPayment })}</div>
                        </div>

                        <div className={`${COMPONENT_CLASS}__action-column`}>
                          <div className={`${COMPONENT_CLASS}__column`}>
                            {
                              myAddOns && (
                                <Button
                                  disabled={!isAccountHolder || (addOn.quantity - addOn.removedQuantity < 1)}
                                  buttonText={t('addOns.buttons.removeAddOn')}
                                  onClick={async () => {
                                    // Old logic when formValue from inputs on add-on page was being used
                                    // if (formValues[index].value <= (myAddOns.find((item: any) => item.priceId === addOns[index].priceId).quantity -
                                    //   myAddOns.find((item: any) => item.priceId === addOns[index].priceId).removedQuantity)) {
                                    //   setSelectedAddOn(index);
                                    //   setRemoveAddOn(true);
                                    // }
                                    // else {
                                    //   // TODO: what do we want to display when they try to remove too many of an add-on (modal?)
                                    // }
                                    setMySelectedAddOn(index);
                                    setRemoveAddOn(true);
                                  }}
                                />
                              )
                            }
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </>
        )
      }

      <div className={`${COMPONENT_CLASS}__heading`}>
        <h2>{t('addOns.recommendedAddOns')}</h2>
      </div>
      <div className={`${COMPONENT_CLASS}__add-on-list`}>
        {addOns.map((addOn, index) => (
          <>
            {addOn.recommended &&
              <div className={`${COMPONENT_CLASS}__warning_label`} style={getLabelStyle(addOn.id)}>
                <p>{t('addOns.recommended')}</p>
              </div>
            }
            <div
              className={`${COMPONENT_CLASS}__add-on-list-row`}
              style={addOn.recommended ? getAddOnStyle(addOn.id) : { marginTop: '0rem' }}
              key={`addons_${index}`}
            // onClick={() => {
            //   setSelectedAddOn(index);
            //   setOpenAddOn(true);
            // }}
            >
              <div className={`${COMPONENT_CLASS}__icon-column`}>
                <div>
                  {getIcon(addOn.icon)}
                </div>
              </div>
              <div className={`${COMPONENT_CLASS}__information-container`}>
                <div className={`${COMPONENT_CLASS}__description-column`}>
                  <span className={`${COMPONENT_CLASS}__product-name`}>{addOn.name}</span>
                  <span className={`${COMPONENT_CLASS}__product-description`}>{addOn.description}</span>
                </div>

                <div className={`${COMPONENT_CLASS}__price-and-button-container`}>
                  <div className={`${COMPONENT_CLASS}__price-column`}>
                    {/* <div>{addOn.subName}</div> */}
                    <div>
                      <NumberTypeInput
                        id={addOn.subName}
                        registerHook={register}
                        registerOptions={{
                          value: formValues.find(({ key }) => key === addOn.id)?.value,
                          onChange: (event) => {
                            let value;
                            if (parseInt(event.target.value) > 1000) {
                              value = "1000";
                            }
                            else {
                              value = event.target.value;
                            }
                            const temp = [...formValues];
                            temp[index] = { ...temp[index], value: parseInt(value) }
                            setFormValues(temp);
                            // Change displayed price of add-on
                            updateAddOnPrice(index, parseInt(value));
                          }
                        }}
                        customKey={index}
                        inputClassName={`${COMPONENT_CLASS}__add-input`} />
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: t('addOns.plusMonthlyPrice', { price: USDollar.format(addOn.price), className: COMPONENT_CLASS }) }}>
                    </div>
                  </div>

                  <div className={`${COMPONENT_CLASS}__action-column`}>
                    <div className={`${COMPONENT_CLASS}__column`}>
                      <Button
                        disabled={!isAccountHolder || isNaN(formValues[index].value)}
                        buttonText={t('addOns.buttons.addToPlan')}
                        onClick={() => {
                          setSelectedAddOn(index);
                          setOpenAddOn(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
        <Modal
          isOpen={openAddOn}
          isLoading={isSaving}
          onClose={() => {
            setOpenAddOn(false);
          }}
        >
          {
            organization && (
              <AddOnDialogBox
                onClose={() => {
                  setOpenAddOn(false);
                  setFormValues([
                    {
                      key: "licenses",
                      value: 1
                    },
                    {
                      key: "users",
                      value: 1
                    },
                    // {
                    //   key: "organizations",
                    //   value: 1
                    // }
                  ])
                  setDynamicLicensePrice(parseFloat((getPricingByAddOnId(1, AddOnId.License) / 100).toFixed(2)));
                  setDynamicUserPrice(parseFloat((getPricingByAddOnId(1, AddOnId.User) / 100).toFixed(2)));
                }}
                serviceName={addOns[selectedAddOn].name}
                addOnName={addOns[selectedAddOn].subName}
                price={addOns[selectedAddOn].price}
                description={addOns[selectedAddOn].description}
                descriptionBullets={addOns[selectedAddOn].descriptionBullets}
                summaryDescription={addOns[selectedAddOn].summaryDescription}
                priceId={addOns[selectedAddOn].priceId}
                quantity={formValues[selectedAddOn].value}
                setAddOnPurchaseComplete={setAddOnPurchaseComplete}
                setRemovedAddOnInfo={setRemovedAddOnInfo}
                removeAddOn={false}
                organization={organization}
                products={products}
                existingAddOnAmount={0} // We only care about this value whe removeAddOn is true. Left required to avoid if statement in component
              ></AddOnDialogBox>
            )
          }

        </Modal>
        <Modal
          isOpen={removeAddOn}
          isLoading={isSaving}
          onClose={() => {
            setRemoveAddOn(false);
          }}
        >
          {
            myAddOns && myAddOns.length > 0 && organization && (
              <AddOnDialogBox
                onClose={() => { setRemoveAddOn(false) }}
                serviceName={myAddOns[mySelectedAddOn].name}
                addOnName={myAddOns[mySelectedAddOn].subName}
                price={myAddOns[mySelectedAddOn].price}
                nextPayment={myAddOns[mySelectedAddOn].nextPayment}
                description={myAddOns[mySelectedAddOn].description}
                descriptionBullets={myAddOns[mySelectedAddOn].descriptionBullets}
                summaryDescription={myAddOns[mySelectedAddOn].summaryDescription}
                priceId={myAddOns[mySelectedAddOn].priceId}
                quantity={myAddOns[mySelectedAddOn].quantity}
                setAddOnRemoveComplete={setAddOnRemoveComplete}
                removeAddOn={true}
                setAddOnRemovedModalOpen={setAddOnRemovedModalOpen}
                setRemovedAddOnInfo={setRemovedAddOnInfo}
                organization={organization}
                products={products}
                existingAddOnAmount={myAddOns[mySelectedAddOn].quantity}
              ></AddOnDialogBox>
            )
          }
        </Modal>
        <Modal
          isOpen={addOnRemovedModalOpen}
          isLoading={isSaving}
          onClose={() => {
            setAddOnRemovedModalOpen(false);
          }}
        >
          <div className={`${COMPONENT_CLASS}__removal-container`}>
            <div>
              <Dialog.Title
                as="h2" className={`${COMPONENT_CLASS}__modal-title`}>
                {t('addOns.removed')}
              </Dialog.Title>
            </div>
            {
              removedAddOnInfo.amountManaged && removedAddOnInfo.amountManaged > 0 && (
                <div className={`${COMPONENT_CLASS}__removal-info-container`}>
                  <p className={`${COMPONENT_CLASS}__removal-info-bold`} >{t('addOns.removedAddOn1', { amountToBeRemoved: removedAddOnInfo.amountToBeRemoved, name: removedAddOnInfo.name, amountManaged: removedAddOnInfo.amountManaged, nameLowerCase: removedAddOnInfo.name?.toLowerCase(), billingCycleEnd: removedAddOnInfo.billingCycleEnd })}</p>
                  <p className={`${COMPONENT_CLASS}__removal-info-regular`}>{t('billing.emailConfirmation')}</p>
                </div>
              )
            }
            {
              !removedAddOnInfo.amountManaged && (
                <div className={`${COMPONENT_CLASS}__removal-info-container`}>
                  <p className={`${COMPONENT_CLASS}__removal-info-bold`}>{t('addOns.removedAddOn2', { amountToBeRemoved: removedAddOnInfo.amountToBeRemoved, name: removedAddOnInfo.name, billingCycleEnd: removedAddOnInfo.billingCycleEnd })}</p>
                  <p className={`${COMPONENT_CLASS}__removal-info-regular`}>{t('billing.emailConfirmation')}</p>
                </div>
              )
            }
            <div className="flex justify-center">
              <Button
                buttonText={t('buttons.btn_continue')}
                onClick={async () => {
                  setAddOnRemovedModalOpen(false);
                }}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div >
  );
}

export default AddOns;
