import React from 'react';
import logo from "../../assets/images/symmio-logos/symmio-logo-horizontal-dark.png";
import { useHistory } from 'react-router-dom';
import BaseCardLayout from '../../components/base-card-layout/base-card-layout';
import BaseCardLayoutActions from '../../components/base-card-layout/base-card-layout-actions';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import UserRoles from '../../models/user-roles';
import { useTranslation } from 'react-i18next';


const COMPONENT_CLASS = "p-create-account-confirmation";

const CreateAccountConfirmationPage: React.FC = () => {
    let history = useHistory();
    const { state } = useAuthState();
    const { t } = useTranslation();

    const onSubmit = () => {
        // history.push("/setup")

        if (state.user && state.user.roles && state.user.roles.find((role) => role === UserRoles.CLIENT_ID)) {
            history.push("/setup")
        }
        else {
            history.push("/login")
        }

    }

    return (
        <BaseCardLayout>
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <div className={`${COMPONENT_CLASS} flex flex-row flex-wrap`} style={{ marginTop: "9.8%" }}>
                    <div><img src={logo} alt="Logo" style={{ width: "250px", height: "auto" }} /></div>
                    <div style={{ margin: "auto 0" }}>
                        <h4 className={`${COMPONENT_CLASS}__header`}>{t('pricing.accountCreatedPage.title')}</h4>
                        <div className={`${COMPONENT_CLASS}__informative`}>
                            <p>{t('pricing.accountCreatedPage.subStarter')}<br></br> {t('pages.createAccount.loginToSymmio')}</p>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "18%", width: "100%" }}>
                    <BaseCardLayoutActions backButton={false} submitButton={true} onSubmit={onSubmit} submitButtonText={t('buttons.btn_continue')}></BaseCardLayoutActions>
                </div>
            </div>

        </BaseCardLayout>
    );
}
export default CreateAccountConfirmationPage;