import { ReportType } from "./enumerations/report-type";
import { ListOptions } from "./interfaces/list-options";
import i18n from "../i18n";

export default class ReportTypes {
    public static ALL_TYPES: ListOptions[] = [
        {
            value: ReportType.Compliance.toString(),
            label: i18n.t('reportType.compliance'),
        },
        {
            value: ReportType.Detail.toString(),
            label: i18n.t('reportType.detail'),
        },
        {
            value: ReportType.Score.toString(),
            label: i18n.t('reportType.score'),
        },
        {
            value: ReportType.Summary.toString(),
            label: i18n.t('reportType.summary'),
        },
        {
            value: ReportType.Movement.toString(),
            label: i18n.t('reportType.movementHealth'),
        },
    ];

    public static TEAM_MEMBER_TYPES: ListOptions[] = [
        {
            value: ReportType.Compliance.toString(),
            label: i18n.t('reportType.compliance'),
        },
        {
            value: ReportType.Summary.toString(),
            label: i18n.t('reportType.summary'),
        },
    ];

    public static ALL_TYPES_WITH_REPORT_WORD: ListOptions[] = [
        {
            value: ReportType.Compliance.toString(),
            label: i18n.t('reportType.compliance_rep'),
        },
        {
            value: ReportType.Detail.toString(),
            label: i18n.t('reportType.detail_rep'),
        },
        {
            value: ReportType.Score.toString(),
            label: i18n.t('reportType.score_rep'),
        },
        {
            value: ReportType.Summary.toString(),
            label: i18n.t('reportType.summary_rep'),
        },
        {
            value: ReportType.Wellness.toString(),
            label: i18n.t('reportType.wellness_rep'),
        },
        {
            value: ReportType.Movement.toString(),
            label: i18n.t('reportType.movement_health_rep'),
        },
    ];
}
