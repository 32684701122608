import React from "react";
import {
    Breakpoint,
    DialogContent,
    styled,
} from '@mui/material';
import MuiDialog from '@mui/material/Dialog';
import { DialogBoxTitle } from "./dialog-box-title";
import { useTranslation } from 'react-i18next';

const StyledDialog = styled(MuiDialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: '30px',
        [theme.breakpoints.up("xs")]: {
            padding: '5px 20px 5px 20px',
            minWidth: '375px',
        },
        [theme.breakpoints.up("sm")]: {
            padding: '15px 40px 15px 40px',
            minWidth: '550px',
        },
        [theme.breakpoints.up("md")]: {
            padding: '30px 55px 30px 55px',
            minWidth: '800px',
        },
        [theme.breakpoints.up("lg")]: {
            padding: '40px 71px 40px 71px',
        },
    },
    '& .MuiDialogContent-root': {
        padding: 0,
        overflowY: 'unset'
    }
}));

interface DialogBoxProps {
    open: boolean,
    onClose: Function,
    children: any,
    maxWidth?: Breakpoint | false | undefined,
    fullWidth?: boolean | undefined,
    title?: string,
    sx?: any,
    width?: string,
}

/**
 * A select form control that provides a drop down of
 * label value pairs of which one is selectable.
 */
export const DialogBox: React.FC<DialogBoxProps> = (props) => {
    const {
        open,
        onClose,
        children,
        maxWidth,
        fullWidth,
        title,
        sx,
        width,
    } = props;

    const handleClose = () => {
        onClose();
    }
    const { t } = useTranslation();

    return (
        <StyledDialog
            onClose={(event: object, reason: string) => {
                onClose();
            }}
            scroll={'paper'}
            PaperProps={{ sx: { width, maxWidth: width } }}
            open={open}
            aria-labelledby="dialogTitle"
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            sx={{ ...sx, borderRadius: '30px', padding: '100px' }}
        >
            {
                title &&
                <DialogBoxTitle
                    text={t('misc.addAcc')}
                    onClose={handleClose}
                />
            }
            <DialogContent>
                {children}
            </DialogContent>
        </StyledDialog>
    );
};
