import { useEffect, useState } from "react";
import { Button } from "../button/button";
import SubscriptionLinkSearchHits from './subscription-links-search-hits';
import { Modal } from "../modal/modal";
import { useHistory } from "react-router";
import { enqueueSnackbar } from "notistack";
import SubscriptionLinkService from "../../utilities/services/subscription-link-service";
import { SubscriptionLink } from "../../models/interfaces/subscription-link";
import { useStore } from "../../store/useStore";
import { useTranslation } from 'react-i18next';

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SubscriptionLinkList: React.FC = () => {
    const [openDelete, setOpenDelete] = useState(false);
    const [subscriptionLinks, setSubscriptionLinks] = useState<any>();
    const [linkToDelete, setLinkToDelete] = useState<SubscriptionLink>();
    const history = useHistory();
    const organization = useStore((state) => state.organization);
    const { t } = useTranslation();

    if (!organization || !organization.id) {
        throw new Error(t('subscriptionLinks.errorNoOrg'));
    }

    const handleOpenDelete = (subscriptionLink: SubscriptionLink) => {
        setLinkToDelete(subscriptionLink);
        setOpenDelete(true);
    }

    const handleDelete = async () => {
        try {
            if (!linkToDelete || !linkToDelete.id) {
                throw new Error(t('subscriptionLinks.errorLinkToDelete'));
            }
            await SubscriptionLinkService.deleteById(linkToDelete.id);
            setOpenDelete(false);
            await SubscriptionLinkService.getAll().then((result) => {
                setSubscriptionLinks(result);
            });
            enqueueSnackbar(t('deeplinks.link_del_confirm'), { variant: "toast", width: "450px" });
        } catch (err) {
            setOpenDelete(false);
            console.error(err);
            // TODO : Global exception handling
            // https://app.clickup.com/t/2219993/FMS-1236
        }
    }

    useEffect(() => {
        const loadLinks = async () => {
            await SubscriptionLinkService.getBy([{
                field: 'organizationId',
                operator: '==',
                value: organization.id,
            }]).then((result) => {
                setSubscriptionLinks(result);
            });
        }

        loadLinks();
    }, [organization.id]);

    return (
        <>
            <div>
                <table
                    role={"table"}
                    className={"dark list__table-container"}
                    data-testid="deeplinks-list">
                    <thead className="hide-on-mobile">
                        <tr>
                            <th></th>
                            <th>{t('deeplinks.list.linkName')}</th>
                            <th>{t('deeplinks.list.group')}</th>
                            <th>{t('deeplinks.list.tags')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        { // if
                            subscriptionLinks &&
                            <SubscriptionLinkSearchHits
                                subscriptionLinks={subscriptionLinks}
                                editClickHandler={(subscriptionLink: SubscriptionLink) => {
                                    history.push(`/subscription-link/${subscriptionLink.id}`)
                                }}
                                deleteClickHandler={(subscriptionLink) => {
                                    handleOpenDelete(subscriptionLink);
                                }} />
                        }
                    </tbody>
                </table>
            </div>
            { // if
                !subscriptionLinks &&

                <div className={`${'loading-spinner'} ${''} ${true ? '-active' : ''}`}>
                    <div className={`${'loading-spinner'}__content`}>
                    </div>
                    <div className={'loading-title'}>{t('subscriptionLinks.loading')}</div>
                </div>

            }
            <Modal
                isOpen={openDelete}
                isLoading={false}
                onClose={setOpenDelete}
                title={t('deeplinks.delete_modal.title')}>
                {/* {linkToDelete?.used
                    ?
                    <div>
                        <p>{t('deeplinks.delete_modal.err_usedLink')}</p>
                        <button
                            type="button"
                            className={'c-modal__actions__cancel'}
                            onClick={() => setOpenDelete(false)}>
                            {t('buttons.btn_close')}
                        </button>
                    </div>
                    : */}
                <div>
                    <div>
                        <p>
                            {t('subscriptionLinks.deleteMessage')}
                        </p>
                    </div>
                    <div className={'c-modal__actions'}>
                        <button
                            type="button"
                            className={'c-modal__actions__cancel'}
                            onClick={() => setOpenDelete(false)}>
                            {t('buttons.btn_cancel')}
                        </button>
                        <Button
                            onClick={handleDelete}
                            buttonText={t('buttons.btn_deleteLink')}
                            type="default" />
                    </div>
                </div>
                {/* } */}
            </Modal>
        </>
    );
}

export default SubscriptionLinkList;