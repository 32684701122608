import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { ReportType } from "../../models/enumerations/report-type";
import { FocusGroupOptions } from "../../models/focus-group";
import { MovementFocusOptions } from "../../models/movement-focus-group";
import { MskHealthRiskOptions } from "../../models/msk-health-risk";
import { Group } from "../../models/interfaces/group";
import { ListOptions } from "../../models/interfaces/list-options";
import { Report } from "../../models/interfaces/report";
import { Tag } from "../../models/interfaces/tag";
import ReportTypes from "../../models/report-types";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import GroupService from "../../utilities/services/group-service";
import ReportService from "../../utilities/services/report-service";
import TagService from "../../utilities/services/tag-service";
import UserUtil from "../../utilities/user-util";
import { Button } from "../button/button";
import { Loader } from "../loader/loader";
import { CheckboxTypeInput } from "../forms/checkbox-type-input";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { AssociatedMovementAndBreathing } from "../../models/interfaces/associated-movement-and-breathing";
import { GroupIds } from "../../models/enumerations/group-ids";

const COMPONENT_CLASS = "c-report-schedule";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportScheduleProps {
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportSchedule: React.FC<ReportScheduleProps> = (props: ReportScheduleProps) => {
    const [isInPain, setIsInPain] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSavingReport, setIsSavingReport] = useState(false);
    const [groups, setGroups] = useState<Group[]>();
    const [selectedFocusAreas, setSelectedFocusAreas] = useState<ListOptions[]>([]);
    const [selectedMovementFocusAreas, setSelectedMovementFocusAreas] = useState<ListOptions[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<ListOptions[]>([]);
    const [selectedHealthCategories, setSelectedHealthCategories] = useState<ListOptions[]>([]);
    const [selectedTags, setSelectedTags] = useState<ListOptions[]>([]);
    const [tags, setTags] = useState<Tag[]>();
    const [reportConfig, setReportConfig] = useState<Report>();
    const history = useHistory();
    const { state } = useAuthState();
    const { t, i18n } = useTranslation();

    const now = DateTime.now();
    const fullAccess = UserUtil.isSuperAdmin(state.claims) || UserUtil.isAdmin(state.claims) || UserUtil.isAccountHolder(state.user);
    const hasManagedGroups = UserUtil.isManagerOrTeamMember(state.claims) &&
        state.user?.managedGroupIds != null &&
        state.user.managedGroupIds.length >= 1;

    const {
        register,
    } = useForm<any>();

    useEffect(() => {
        if (state.organization?.id == null || state.user?.id == null) {
            return;
        }

        setReportConfig({
            dateEnd: now.startOf("month").toISODate(),
            dateStart: now.minus({ week: 1 }).toISODate(),
            groupIds: [],
            organizationId: state.organization?.id,
            status: "scheduled",
            tagIds: [],
            type: ReportType.Compliance,
            userId: state.user.id,
        });

        const loadData = async () => {
            if (fullAccess) {
                setGroups(await GroupService.getBy([{
                    field: "organizationId",
                    operator: "==",
                    value: state.organization?.id,
                }], [{
                    field: "name",
                    direction: "asc"
                }]));
            } else {
                const groupIds = await UserUtil.getHierarchicalGroupIds(state.user?.managedGroupIds);
                if (groupIds.length === 0) {
                    setGroups([]);
                } else {
                    setGroups(await GroupService.getBy([{
                        field: "id",
                        operator: "in",
                        value: groupIds,
                    }]));
                }
            }

            setTags(await TagService.getBy([{
                field: "organizationId",
                operator: "==",
                value: state.organization?.id,
            }], [{
                field: "name",
                direction: "asc"
            }]));

            setIsLoading(false);
        }

        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.organization?.id]);

    const handleScheduleReportClick = async () => {
        setIsSavingReport(true);

        if (reportConfig == null) {
            setIsSavingReport(false);
            return;
        }
        reportConfig.groupIds = selectedGroups.map((g) => g.value);
        reportConfig.healthCategories = selectedHealthCategories.map((c) => c.value);
        reportConfig.isInPain = isInPain;
        reportConfig.tagIds = selectedTags.map((t) => t.value);
        reportConfig.type = parseInt(reportConfig.type.toString());
        if (reportConfig.type === ReportType.Movement) {
            reportConfig.focusAreas = selectedMovementFocusAreas.map((f) => f.value);

            // We don't have a separate dropdown option of MovementFocus + Breathing, so we have to add it here
            // For example, User has selected Balance, so we need result.focusAreas to be [Balance, Balance_Breathing]
            if (reportConfig.focusAreas && reportConfig.focusAreas.length > 0) {
                const tempFocusAreas = [];
                for (const focusArea of reportConfig.focusAreas) {
                    tempFocusAreas.push(AssociatedMovementAndBreathing[focusArea]);
                }
                reportConfig.focusAreas = [...reportConfig.focusAreas, ...tempFocusAreas];
            }
        }
        else {
            reportConfig.focusAreas = selectedFocusAreas.map((f) => f.value);

            if (reportConfig.focusAreas.includes(GroupIds.MOVEMENT_HEALTH) || reportConfig.focusAreas.includes(GroupIds.BREATHING)) {
                reportConfig.focusAreas.push(GroupIds.MOVEMENT_HEALTH_BREATHING);
            }
        }


        if (reportConfig.type === ReportType.Compliance) {
            reportConfig.dateEnd = now.toISODate();
        } else {
            if (reportConfig.type === ReportType.Summary) {
                reportConfig.monthsAgo = 9;
            }
            reportConfig.dateStart = reportConfig.dateEnd;
        }

        // set default group for managers
        if (!fullAccess && reportConfig.groupIds.length === 0) {
            reportConfig.groupIds.push(UserUtil.getDefaultManageGroupId(state.user) ?? "NO_MANAGED_GROUPS");
        }

        const scheduledReport = await ReportService.add(reportConfig, state.user);

        if (!scheduledReport.id) {
            setIsSavingReport(false);
            return;
        }

        history.push(`/reports/${scheduledReport?.id}`);
    }

    const reportMonthOptions: ListOptions[] = [{
        value: now.startOf("month").toISODate(),
        label: t('reports.currentMonth')
    }];
    let monthDate = now.minus({ month: 1 });
    while (reportMonthOptions.length < 13) {
        reportMonthOptions.push({
            value: monthDate.startOf("month").toISODate(),
            label: monthDate.setLocale(i18n.language).toFormat("MMMM yyyy"),
        });
        monthDate = monthDate.minus({ month: 1 });
    }

    const reportRangeOptions: ListOptions[] = [{
        value: now.minus({ week: 1 }).toISODate(),
        label: t('reports.7days')
    }, {
        value: now.minus({ month: 1 }).toISODate(),
        label: t('reports.30days')
    }, {
        value: now.minus({ months: 3 }).toISODate(),
        label: t('reports.3months')
    }, {
        value: now.minus({ months: 6 }).toISODate(),
        label: t('reports.6months')
    }];

    const hasFocusAndCategoryBreakdowns = reportConfig?.type === ReportType.Detail || reportConfig?.type === ReportType.Score;
    const reportTypeOptions = UserUtil.isTeamMember(state.claims) ? ReportTypes.TEAM_MEMBER_TYPES : ReportTypes.ALL_TYPES;
    const movementFocusAndCategoryBreakdowns = reportConfig?.type === ReportType.Movement;
    if (!fullAccess && !hasManagedGroups) {
        return (
            <div className={`${COMPONENT_CLASS}`}>
                <div className={`${COMPONENT_CLASS}__header`}>
                    <h1>{t('reports.title')}</h1>
                </div>
                <h2>{t('reports.err_noGroups')}</h2>
            </div>
        );
    }

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <Loader
                isVisible={isLoading || isSavingReport} />
            <div className={`${COMPONENT_CLASS}__header`}>
                <h1>{t('reports.title')}</h1>
            </div>
            <div>
                <div className={`${COMPONENT_CLASS}__settings`}>
                    <div>
                        <label htmlFor="type">{t('reports.reportType')}</label>
                        <Select
                            name="type"
                            classNamePrefix={COMPONENT_CLASS}
                            placeholder={t('reports.reportType')}
                            options={reportTypeOptions}
                            onChange={(newValue: any, actionMeta: any) => setReportConfig(Object.assign({}, reportConfig, { type: parseInt(newValue.value) }))}
                            value={reportTypeOptions.find(o => o.value === reportConfig?.type.toString())} />
                    </div>
                </div>
                <hr style={{ marginBottom: "30px" }} />
                <div className={`${COMPONENT_CLASS}__settings`}>
                    <div>
                        <label htmlFor="groups">{t('reports.fieldGroups')}</label>
                        {groups &&
                            <Select
                                name="groups"
                                classNamePrefix={COMPONENT_CLASS}
                                placeholder={t('reports.fieldGroups')}
                                isMulti={true}
                                options={groups.map((g) => ({ label: g.name, value: g.id }))}
                                onChange={(newValue: any, actionMeta: any) => setSelectedGroups(newValue)}
                                value={selectedGroups} />
                        }
                    </div>
                    <div>
                        <label htmlFor="tags">{t('reports.fieldTags')}</label>
                        {tags &&
                            <Select
                                name="tags"
                                classNamePrefix={COMPONENT_CLASS}
                                placeholder={t('reports.fieldTags')}
                                isMulti={true}
                                options={tags.map((g) => ({ label: g.name, value: g.id }))}
                                onChange={(newValue: any, actionMeta: any) => setSelectedTags(newValue)}
                                value={selectedTags} />
                        }
                    </div>
                    {hasFocusAndCategoryBreakdowns &&
                        <div>
                            <label htmlFor="category">{t('general.msk')}</label>
                            <Select
                                name="category"
                                classNamePrefix={COMPONENT_CLASS}
                                placeholder={t('general.msk')}
                                isMulti={true}
                                options={MskHealthRiskOptions}
                                onChange={(newValue: any, actionMeta: any) => setSelectedHealthCategories(newValue)}
                                value={selectedHealthCategories} />
                        </div>
                    }
                    {movementFocusAndCategoryBreakdowns &&
                        <div>
                            <label
                                htmlFor="focus">
                                {t('reports.movementFocus')}
                            </label>
                            <Select
                                name="focus"
                                classNamePrefix={COMPONENT_CLASS}
                                placeholder={t('focus.focusAreas')}
                                isMulti={true}
                                options={MovementFocusOptions}
                                onChange={(newValue: any, actionMeta: any) => setSelectedMovementFocusAreas(newValue)}
                                value={selectedMovementFocusAreas} />
                        </div>
                    }
                    {(hasFocusAndCategoryBreakdowns || movementFocusAndCategoryBreakdowns) &&
                        <div className="flex">
                            <label htmlFor="inPain">{t('reports.hasMovementPain')}</label>
                            <CheckboxTypeInput
                                id="inPain"
                                registerHook={register}
                                registerOptions={{
                                    onChange: (e) => setIsInPain(!isInPain),
                                }}
                                checked={isInPain} />
                        </div>
                    }
                    {hasFocusAndCategoryBreakdowns &&
                        <div>
                            <label
                                htmlFor="focus">
                                {t('focus.focusAreas')}
                            </label>
                            <Select
                                name="focus"
                                classNamePrefix={COMPONENT_CLASS}
                                placeholder={t('focus.focusAreas')}
                                isMulti={true}
                                options={FocusGroupOptions}
                                onChange={(newValue: any, actionMeta: any) => setSelectedFocusAreas(newValue)}
                                value={selectedFocusAreas} />
                        </div>
                    }
                    {movementFocusAndCategoryBreakdowns &&
                        <div>
                            <label htmlFor="category">{t('general.msk')}</label>
                            <Select
                                name="category"
                                classNamePrefix={COMPONENT_CLASS}
                                placeholder={t('general.msk')}
                                isMulti={true}
                                options={MskHealthRiskOptions}
                                onChange={(newValue: any, actionMeta: any) => setSelectedHealthCategories(newValue)}
                                value={selectedHealthCategories} />
                        </div>
                    }
                    {reportConfig?.type !== ReportType.Compliance &&
                        <div>
                            <label
                                htmlFor="dateEnd">
                                {t('reports.reportDate')}
                            </label>
                            <Select
                                name="dateEnd"
                                classNamePrefix={COMPONENT_CLASS}
                                placeholder={t('reports.reportDate')}
                                options={reportMonthOptions}
                                onChange={(newValue: any, actionMeta: any) => setReportConfig(Object.assign({}, reportConfig, { dateEnd: newValue.value }))}
                                value={reportMonthOptions.find(o => o.value === reportConfig?.dateEnd.toString())} />
                        </div>
                    }
                    {reportConfig?.type === ReportType.Compliance &&
                        <div>
                            <label
                                htmlFor="dateStart">
                                {t('reports.reportDate')}
                            </label>
                            <Select
                                name="dateStart"
                                classNamePrefix={COMPONENT_CLASS}
                                placeholder={t('reports.reportDate')}
                                options={reportRangeOptions}
                                onChange={(newValue: any, actionMeta: any) => setReportConfig(Object.assign({}, reportConfig, { dateStart: newValue.value }))}
                                value={reportRangeOptions.find(o => o.value === reportConfig?.dateStart.toString())} />
                        </div>
                    }
                </div>
                <div className={`${COMPONENT_CLASS}__actions`}>
                    <Button
                        buttonText={t('buttons.btn_runReport')}
                        type="default"
                        onClick={() => handleScheduleReportClick()} />
                </div>
            </div>
        </div>
    );
}

export default ReportSchedule;