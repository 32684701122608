import React from "react";
import { Organization } from "../models/interfaces/organization";
import { PlanType } from "../models/enumerations/plan-type";
import { useAuthState } from "../utilities/contexts/auth-state-context";
import PortalProgressBar from "./portal-progress-bar/portal-progress-bar";
import { WarningItem } from "./warning-item/warning-item";
import { useHistory } from "react-router-dom";
import { CheckboxTypeInput } from "./forms/checkbox-type-input";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Tabs } from "../models/enumerations/tabs";

const COMPONENT_CLASS = "c-symmio-license-checkbox";

interface SymmioLicenseCheckboxProps {
    organization: Organization;
    onClick: () => void;
    isChecked: boolean;
    numberOfAddedLicenses: number;
    numberOfRemovedLicenses: number;
    isDisabled?: boolean;
}

export const SymmioLicenseCheckbox: React.FC<SymmioLicenseCheckboxProps> = (props) => {
    const { t } = useTranslation();

    const {
        organization,
        onClick,
        isChecked,
        numberOfAddedLicenses,
        numberOfRemovedLicenses,
        isDisabled
    } = props;

    const { state } = useAuthState();
    const history = useHistory();

    const {
        register,
    } = useForm<any>();

    return (
        <div className={`${COMPONENT_CLASS}__checkbox-wrapper ${isChecked ? `${COMPONENT_CLASS}__is-symmio-access` : ``}`} onClick={isDisabled ? () => { } : onClick}>
            <div>
                <CheckboxTypeInput
                    id="symmioAccess"
                    inputClassName={`${COMPONENT_CLASS}__symmio-access-checkbox`}
                    registerHook={register}
                    registerOptions={{
                        onChange: onClick,
                        disabled: isDisabled,
                    }}

                    checked={isChecked} />

            </div>
            <div className={`${COMPONENT_CLASS}__checkbox-text`}>
                <strong className="mb-2 text-md">{t('users.enroll_in_wellness')}</strong>
                <p className="text-sm font-light">{t('symmioLicenseCheckbox.description')}</p>
                <div className={`${COMPONENT_CLASS}__checkbox-info`}>
                    {
                        organization.plan?.value === PlanType.Enterprise &&
                        isChecked && organization.mySymmioLicenseCount! + numberOfAddedLicenses > organization.mySymmioLicense! && (
                            <a href={`mailto:support@symmio.com?subject=URGENT: Wellness Program Increase Request from ${organization.name}&body=We have received an urgent Wellness Program increase request from one of our enterprise accounts. Please see the details below and follow up promptly.%0D%0AOrganization Name: ${organization.name}%0D%0ARequest Submitted By: ${state.user?.firstName} ${state.user?.lastName}%0D%0AAccount Owner Email: ${state.user?.email}%0D%0ACurrent Wellness Program Count: ${organization.mySymmioLicenseCount}%0D%0ACurrent Wellness Program Limit: ${organization.mySymmioLicense}`}
                                onClick={(e) => { e.stopPropagation() }}>{t('symmioLicenseCheckbox.contactUs')}</a>
                        )
                    }
                    {
                        (organization.plan?.value === PlanType.Business || organization.plan?.value === PlanType.Starter) &&
                        isChecked && organization.mySymmioLicenseCount! + numberOfAddedLicenses > organization.mySymmioLicense! && (
                            <p style={{
                                color: "#121622",
                                fontSize: "14px",
                                fontWeight: "300",
                                textDecorationLine: "underline",
                                cursor: "pointer"
                            }} onClick={(event) => {
                                event.stopPropagation();
                                if (organization.plan?.value === PlanType.Business) {
                                    history.push(`/settings?active=${Tabs.AddOns}`)
                                }
                                else {
                                    history.push(`/settings/upgrade`);
                                }
                            }}>{t('symmioLicenseCheckbox.manageAddOns')}</p>
                        )
                    }

                </div>
                <PortalProgressBar total={organization.mySymmioLicense!} currentProgress={isChecked ? organization.mySymmioLicenseCount! + numberOfAddedLicenses : organization.mySymmioLicenseCount! - numberOfRemovedLicenses}
                    headerText={t('symmioLicenseCheckbox.progressBarText', {
                        usedLicenses: isChecked ? organization.mySymmioLicenseCount! + numberOfAddedLicenses : organization.mySymmioLicenseCount! - numberOfRemovedLicenses,
                        totalLicenses: organization.mySymmioLicense!
                    })}
                    link="/settings" linkText={t('symmioLicenseCheckbox.manageAddOns')} />
                {
                    // if isChecked and license count > limit
                    isChecked && organization.mySymmioLicenseCount! + numberOfAddedLicenses > organization.mySymmioLicense! && (
                        <WarningItem
                            iconColor="#E7514F"
                            backgroundColor="#FFE9E5"
                            title={t('symmioLicenseCheckbox.warningTitle')}
                            text={t('symmioLicenseCheckbox.warningText')}
                            marginBottom="0rem"
                        />
                    )
                }
            </div>
        </div>
    )
};
