import React from 'react';
import { ButtonBase, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledButton = styled(ButtonBase)(({ theme }) => ({
    height: '50px',
    borderRadius: '30px',
    background: 'rgba(18, 22, 34, 1)',
    paddingLeft: '36.5px',
    paddingRight: '36.5px',
    color: 'white',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'pragmatica-extended',
    fontWeight: 700,
    '& .MuiButtonBase-root': {
    }
}));

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SaveButtonProps {
    text?: String,
    disabled?: boolean,
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SaveButton: React.FC<SaveButtonProps> = (props: SaveButtonProps) => {
    const { text, disabled } = props;
    const { t } = useTranslation();

    return (
        <StyledButton
            sx={{ width: '210px' }}
            type="submit"
            disabled={disabled}
        >
            {text ?? t('buttons.btn_save')}
        </StyledButton>
    );
}

export { SaveButton };
