import { PaymentCycle } from "./enumerations/payment-cycle";
import { NumericListOptions } from "./interfaces/numeric-list-options";
import i18n from "../i18n";

export const PaymentCycleOptions: NumericListOptions[] = [
    {
        value: PaymentCycle.Monthly,
        label: i18n.t('general.paymentCycle.monthly'),
    },
    {
        value: PaymentCycle.Yearly,
        label: i18n.t('general.paymentCycle.yearly'),
    },
]

export const PaymentCycleEnterpriseOptions: NumericListOptions[] = [
    {
        value: PaymentCycle.Monthly,
        label: i18n.t('general.paymentCycle.monthly'),
    },
    {
        value: PaymentCycle.Quarterly,
        label: i18n.t('general.paymentCycle.quarterly'),
    },
    {
        value: PaymentCycle.Yearly,
        label: i18n.t('general.paymentCycle.yearly'),
    },
]

export const PaymentCycleTranslated = {
    [PaymentCycle.Monthly]: i18n.t('general.paymentCycle.monthly'),
    [PaymentCycle.Quarterly]: i18n.t('general.paymentCycle.quarterly'),
    [PaymentCycle.Yearly]: i18n.t('general.paymentCycle.yearly'),
}