export enum Tabs {
  Account,
  HighRiskRecommendation,
  Billing,
  Notifications,
  AddOns,
  Summary,
  Assessments,
  WellnessProgram,
  History,
  ToeTouch,
  ShoulderMobility,
  Rotation,
  Squat,
  BalanceAndReach,
  ShoulderClearing,
  SpineClearing,
  GroupDetails,
  Customization,
  Integrations,
}