import React, { useState } from "react";
import UserService from "../../utilities/services/user-service";
import { ReactComponent as GreenCheckMark } from "../../assets/icons/outline/gree-check-mark.svg";
import { ReactComponent as RedCross } from "../../assets/icons/outline/red-cross.svg";
import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import { User } from "../../models/interfaces/user";
import CancellationUserSubscriptionModal from "../cancellation-user-subscription-modal/cancellation-user-subscription-modal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionLinkCheckoutForm from "../subscription-links/subscription-link-checkout-form";
import { Modal } from "../modal/modal";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import FunctionService from "../../utilities/services/function-service";
import { useTranslation } from 'react-i18next';

export interface ReactivateUserSubscriptionProps {
    COMPONENT_CLASS: string;
    user: User;
}

/**
 * Content for the modal when the user's plan subscription was cancelled due to a failed payment.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const ReactivateUserSubscription: React.FC<ReactivateUserSubscriptionProps> = (props) => {
    const {
        COMPONENT_CLASS,
        user,
    } = props;

    const { setState } = useAuthState();
    const stripePromise = process.env.REACT_APP_STRIPE_PUBLIC_KEY ? loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY) : undefined;
    const [openCancellationQuestionnaire, setOpenCancellationQuestionnaire] = useState(false);
    const [openCheckoutForm, setOpenCheckoutForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const handleCancelButtonClick = async () => {
        user.subscription = {};

        user.symmioAccess = SymmioAccessType.WebAccess;

        const updatedUser = await UserService.save(user);

        await FunctionService.sendUserSubscriptionCancellationEmail(user);

        setState((state) => ({
            ...state, ...{
                user: updatedUser
            }
        }));
    }

    return (
        <>
            <div className={`${COMPONENT_CLASS}__content-container`}>
                <h1>{t('reactivateSubscription.updatePaymentTitle')}</h1>
                <p>{t('reactivateSubscription.updatePayment_desc')}</p>
                <div className={`${COMPONENT_CLASS}__actions`}>
                    <div className={`${COMPONENT_CLASS}__clickable`} onClick={() => setOpenCheckoutForm(true)}>
                        <div className={`${COMPONENT_CLASS}__image-container`}>
                            <GreenCheckMark />
                        </div>
                        <div className={`${COMPONENT_CLASS}__text-container`}>
                            <h4>{t('reactivateSubscription.continueTitle')}</h4>
                            <p>{t('reactivateSubscription.continue_desc')}</p>
                        </div>
                    </div>

                    <div className={`${COMPONENT_CLASS}__clickable`} onClick={() => setOpenCancellationQuestionnaire(true)}>
                        <div className={`${COMPONENT_CLASS}__image-container`}>
                            <RedCross />
                        </div>
                        <div className={`${COMPONENT_CLASS}__text-container`}>
                            <h4>{t('reactivateSubscription.cancelTitle')}</h4>
                            <p>{t('reactivateSubscription.cancelUser_desc')}</p>
                        </div>
                    </div>
                </div>
            </div>

            {stripePromise &&
                <Modal
                    isOpen={openCheckoutForm}
                    isLoading={isLoading}
                    onClose={setOpenCheckoutForm}
                    title=""
                >
                    <Elements stripe={stripePromise} options={{ mode: 'setup', currency: 'usd' }}>
                        <SubscriptionLinkCheckoutForm isLoading={isLoading} setIsLoading={setIsLoading} showBackButton={false} redirectAfterPayment={true} />
                    </Elements >
                </Modal>
            }

            {
                openCancellationQuestionnaire &&
                <CancellationUserSubscriptionModal
                    isOpen={openCancellationQuestionnaire}
                    onClose={setOpenCancellationQuestionnaire}
                    title=""
                    cancelButtonAction={handleCancelButtonClick}
                />
            }
        </>
    );
};

export default ReactivateUserSubscription;