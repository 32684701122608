/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import AssessmentResponse from "../../models/interfaces/assessment-response.entity";
import MskScoreService from "../../utilities/services/msk-score-service";
import moment from "moment";
import { GroupIds } from "../../models/enumerations/group-ids";
import UserProfileToeTouch from "./user-profile-toe-touch";
import { MovementHealthResponse } from "../../models/interfaces/reports/movement-health-response";
import AssessmentResponseService from "../../utilities/services/assessment-response-service/assessment-response-service";
import UserProfileBalanceReach from "./user-profile-balance-reach";
import UserProfileShoulderMobility from "./user-profile-shoulder-mobility";
import UserProfileShoulderClearing from "./user-profile-shoulder-clearing";
import UserProfileRotation from "./user-profile-rotation";
import UserProfileSpineClearing from "./user-profile-spine-clearing";
import UserProfileSquat from "./user-profile-squat";
import UserProfileAssessmentHeader from "./user-profile-assessment-header";
import LineChartJS from "../charts/line-chart-js";
import { ReactComponent as PersonIcon } from "../../assets/icons/solid/icon_person.svg";
import { PageTabs } from "../page-tabs/page-tabs";
import MskScoreUtil from "../../utilities/msk-score-util";
import { Utils } from "../../utilities/utils";
import { useTranslation } from "react-i18next";
import { Tabs } from "../../models/enumerations/tabs";
import { MovementFocusOptionsWithBreathing } from "../../models/movement-focus-group";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileMovementProps {
    mskScore?: MskScore;
    validMskScore: boolean;
    user: User;
    assessment?: any;
    assessmentResponses?: AssessmentResponse[];
    allMskScores: MskScore[];
    setAllMskScores: Function;
    setSpecificAssessment: Function;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileMovement: React.FC<UserProfileMovementProps> = (props: UserProfileMovementProps) => {
    const { validMskScore, setSpecificAssessment, mskScore, assessment, allMskScores, setAllMskScores, user, assessmentResponses } = props;
    const [movementResponses, setMovementResponses] = useState<MovementHealthResponse>();
    const [movementHealthHistory, setMovementHealthHistory] = useState<{ x: string, y: number }[]>();
    const { t } = useTranslation();

    const params = new URLSearchParams(window.location.search);
    const movementTab = params.get("movementTab");
    const initialTab = movementTab ? Number(movementTab) : Tabs.ToeTouch;

    const hasToeTouchPain = Utils.hasPain(mskScore?.movementScore?.toeTouchScore.painSeverity) || Utils.hasPain(movementResponses?.toeTouchQPain);

    const hasShoulderMobilityPain = Utils.hasPain(mskScore?.movementScore?.shoulderMobilityScore.painSeverity) || Utils.hasPain(movementResponses?.shoulderMobilityQPain);

    const hasRotationPain = Utils.hasPain(mskScore?.movementScore?.rotationScore.painSeverity) || Utils.hasPain(mskScore?.movementScore?.rotationScore.painSeverity ?? movementResponses?.rotationQPain);

    const hasSquatPain = Utils.hasPain(mskScore?.movementScore?.squatScore.painSeverity) || Utils.hasPain(movementResponses?.squatQPain);

    const hasBalancePain = Utils.hasPain(mskScore?.movementScore?.balanceScore.painSeverity) || Utils.hasPain(movementResponses?.balanceQPain);

    const hasShoulderClearancePain = Utils.hasPain(mskScore?.movementScore?.shoulderClearingPainSeverity) || Utils.hasPain(movementResponses?.shoulderClearingQPain);

    const hasSpineClearingPain = Utils.hasPain(mskScore?.movementScore?.spineClearingPainSeverity) || Utils.hasPain(movementResponses?.spineClearingQPain);

    useEffect(() => {
        const getAllMskScores = async () => {
            if (!allMskScores) {
                const mskScores = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }])

                setAllMskScores(mskScores);
            }
        }
        getAllMskScores();
    }, [])

    useEffect(() => {
        const getMovementResponses = () => {
            const movementResponse: any = assessmentResponses!.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.MOVEMENT_HEALTH)
            const response = AssessmentResponseService.getMovementAssessmentResponses(movementResponse);
            setMovementResponses(response);
        }
        if (assessmentResponses) {
            getMovementResponses();
        }
    }, [])

    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempMovementHealthHistory = []

                for (const mskScore of allMskScores) {
                    tempMovementHealthHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.movementScore?.percentage ?? 0
                    })
                }
                setMovementHealthHistory(tempMovementHealthHistory);
            }
        }
        getHistory();
    }, [allMskScores])

    return (
        <>
            <UserProfileAssessmentHeader
                assessment={assessment}
                mskScore={mskScore}
                setSpecificAssessment={setSpecificAssessment}
                extraHeaderElement={
                    mskScore
                        ?
                        Utils.getPainBadge(MskScoreUtil.getNumberOfPainLocationsMovementAssessmentFromMsk(mskScore) > 0)
                        :
                        assessmentResponses
                            ?
                            Utils.getPainBadge(MskScoreUtil.getNumberOfPainLocationsMovementAssessment(assessmentResponses) > 0)
                            :
                            undefined}
                mainTextHeader={t('userProfile.assessments.movementHeader')}
                mainText={t('userProfile.assessments.movementText')}
                whyImportantText={t('userProfile.assessments.movementWhy')}
            >
                {mskScore?.movementScore?.focusArea.groupId && (
                    <div className="flex gap-2 items-center">
                        <div className="w-6">
                            <PersonIcon className="w-full max-w-full min-w-full" />
                        </div>
                        <div className="text-xs font-light flex gap-2">
                            <div className="font-medium">{t('userProfile.assessments.areaOfFocus')}</div>
                            {MovementFocusOptionsWithBreathing.find(focus => focus.value === mskScore.movementScore?.focusArea.groupId)?.label}
                        </div>
                    </div>
                )}
            </UserProfileAssessmentHeader>

            {movementHealthHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper my-5`}>
                    <LineChartJS
                        data={movementHealthHistory.map(history => history.y)}
                        title={t('userProfile.assessments.movementHistory')}
                        labels={movementHealthHistory.map(history => history.x)}
                        height={"200px"}
                        horizontalScrolling={true}
                        pointRadius={7}
                        pointBorderWidth={3}
                        borderWidth={5}
                        pointFontSize={16}
                        fontFamily={'pragmatica-extended'}
                        pointLabelOffset={0}
                        xLabelFontSize={14}
                        paddingTop={30}
                        paddingBottom={20}
                        maintainAspectRatio={false}
                    />
                </div>
            )}

            <PageTabs components={[
                {
                    tab: Tabs.ToeTouch, tabLabel: t('movementFocus.toeTouch'), showExclamation: hasToeTouchPain, component: UserProfileToeTouch, props: {
                        allMskScores: allMskScores,
                        setAllMskScores: setAllMskScores,
                        mskScore: mskScore,
                        validMskScore: validMskScore,
                        user: user,
                        movementResponses: movementResponses,
                    }
                },
                {
                    tab: Tabs.ShoulderMobility, tabLabel: t('movementFocus.shoulderMobility'), showExclamation: hasShoulderMobilityPain, component: UserProfileShoulderMobility, props: {
                        allMskScores: allMskScores,
                        setAllMskScores: setAllMskScores,
                        mskScore: mskScore,
                        validMskScore: validMskScore,
                        user: user,
                        movementResponses: movementResponses,
                    }
                },
                {
                    tab: Tabs.Rotation, tabLabel: t('movementFocus.rotation'), showExclamation: hasRotationPain, component: UserProfileRotation, props: {
                        allMskScores: allMskScores,
                        setAllMskScores: setAllMskScores,
                        mskScore: mskScore,
                        validMskScore: validMskScore,
                        user: user,
                        movementResponses: movementResponses,
                    }
                },
                {
                    tab: Tabs.Squat, tabLabel: t('movementFocus.squat'), showExclamation: hasSquatPain, component: UserProfileSquat, props: {
                        allMskScores: allMskScores,
                        setAllMskScores: setAllMskScores,
                        mskScore: mskScore,
                        validMskScore: validMskScore,
                        user: user,
                        movementResponses: movementResponses,
                    }
                },
                {
                    tab: Tabs.BalanceAndReach, tabLabel: t('movementFocus.balanceAndReach'), showExclamation: hasBalancePain, component: UserProfileBalanceReach, props: {
                        allMskScores: allMskScores,
                        setAllMskScores: setAllMskScores,
                        mskScore: mskScore,
                        validMskScore: validMskScore,
                        user: user,
                        movementResponses: movementResponses,
                    }
                },
                {
                    tab: Tabs.ShoulderClearing, tabLabel: t('movementFocus.shoulderClearing'), showExclamation: hasShoulderClearancePain, component: UserProfileShoulderClearing, props: {
                        allMskScores: allMskScores,
                        setAllMskScores: setAllMskScores,
                        mskScore: mskScore,
                        validMskScore: validMskScore,
                        user: user,
                        movementResponses: movementResponses,
                    }
                },
                {
                    tab: Tabs.SpineClearing, tabLabel: t('movementFocus.spineClearing'), showExclamation: hasSpineClearingPain, component: UserProfileSpineClearing, props: {
                        allMskScores: allMskScores,
                        setAllMskScores: setAllMskScores,
                        mskScore: mskScore,
                        validMskScore: validMskScore,
                        user: user,
                        movementResponses: movementResponses,
                    }
                },
            ]} initialTab={initialTab} highlightLineId="movementTabsHighlightLine" showRoundTabs={true} />


        </>
    );
}

export default UserProfileMovement;
