import { useState } from 'react';
import { Modal } from '../../modal/modal';
import { Loader } from '../../loader/loader';
import { Button } from '../../button/button';
import PasswordUpdate from './password-update';
import { useAuthState } from "../../../utilities/contexts/auth-state-context";
import EditOrganization from './edit-organization';
import EditAccount from './edit-account';
import UserUtil from '../../../utilities/user-util';
import { useTranslation } from 'react-i18next';

interface AccountProps {
}
const COMPONENT_CLASS = "c-account";

const Account: React.FC<AccountProps> = (props: AccountProps) => {
  const [openPasswordChange, setOpenPasswordChange] = useState(false);
  const [openOrganizationChange, setOpenOrganizationChange] = useState(false);
  const [openUserChange, setOpenUserChange] = useState(false);
  const { t } = useTranslation();

  const { state } = useAuthState();
  const isAccountHolder = UserUtil.isAccountHolder(state.user);
  return (
    <div className={COMPONENT_CLASS}>
      <Loader isVisible={false} />
      <div className={`${COMPONENT_CLASS}__container`}>
        <h2>{t('settings.account.title')}</h2>
        <div className={`${COMPONENT_CLASS}__info`}>
          <div>
            <p className='body-bold'>{t('forms.orgName')}</p>
            <div className='body'>{state.organization.name}</div>
          </div>
          <div>
            <Button
              disabled={!isAccountHolder}
              onClick={() => { setOpenOrganizationChange(true) }} buttonText={t('settings.account.editOrg')} />
          </div>
        </div>
      </div >
      <div className={`${COMPONENT_CLASS}__container`}>
        <div className={`${COMPONENT_CLASS}__info`}>
          <div>
            <p className="body-bold">{t('settings.account.name')}</p>
            <div className='body'>{state.user?.firstName} {state.user?.lastName}</div>
          </div>
          <div>
            <p className="body-bold">{t('settings.account.email')}</p>
            <div className='body'>{state.user?.email}</div>
          </div>
          <div>
            <Button onClick={() => { setOpenUserChange(true) }} buttonText={t('settings.account.editPersonalInfo')} />
          </div>
        </div>
      </div>
      <div className={`${COMPONENT_CLASS}__container`}>
        <div className={`${COMPONENT_CLASS}__info`}>
          <div>
            <p className="body-bold">{t('settings.account.currentPassword')}</p>
            <div className='body'>*************</div>
          </div>
          <div>
            <Button onClick={() => { setOpenPasswordChange(true) }} buttonText={t('settings.account.updatePassword')} />
          </div>
        </div>
      </div>
      <Modal
        isOpen={openPasswordChange}
        isLoading={false}
        onClose={() => { setOpenPasswordChange(false) }}
        title={t('settings.account.updatePassword')}>
        <PasswordUpdate onClose={() => setOpenPasswordChange(false)} />
      </Modal>
      <Modal
        isOpen={openOrganizationChange}
        isLoading={false}
        onClose={() => { setOpenOrganizationChange(false) }}
        title={t('settings.account.editOrg')}>
        <EditOrganization onClose={() => setOpenOrganizationChange(false)} />
      </Modal>
      <Modal
        isOpen={openUserChange}
        isLoading={false}
        onClose={() => { setOpenUserChange(false) }}
        title={t('settings.account.editPersonalInfo')}>
        <EditAccount onClose={() => setOpenUserChange(false)} />
      </Modal>
    </div >
  );
}
export default Account;
