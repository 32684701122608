import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";
import { Configure, connectHits, InstantSearch, Pagination, SearchBox, SortBy } from 'react-instantsearch-dom';
import Select from "react-select";
import { UserStatus } from "../../models/enumerations/user-status";
import FirestoreCondition from "../../models/interfaces/firestore-condition";
import { User } from "../../models/interfaces/user";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import searchClient, { SearchIndexes } from "../../utilities/search-client";
import UserService from "../../utilities/services/user-service";
import { Utils } from "../../utilities/utils";
import { Button } from "../button/button";
import EmailList from "../email-list/email-list";
import { Loader } from "../loader/loader";
import { Modal } from "../modal/modal";
import { Tag as TagComponent } from "../tag/tag";
import { Tag } from "../../models/interfaces/tag";
import UserUtil from "../../utilities/user-util";
import StringUtil from "../../utilities/string-util";
import TagService from "../../utilities/services/tag-service";
import { ListOptions } from "../../models/interfaces/list-options";
import { Group } from "../../models/interfaces/group";
import GroupService from "../../utilities/services/group-service";
import { UserTag } from "../../models/interfaces/user-tag";
import { SearchHits } from "./search-hits";
import OrganizationService from "../../utilities/services/organization-service";
import OrganizationUtil from "../../utilities/organization-util";
import { Organization } from "../../models/interfaces/organization";
import UserRoles from "../../models/user-roles";
import { UniqueUserInviteLink } from "../../models/interfaces/unique-user-invite-link";
import UniqueUserInviteLinksService from "../../utilities/services/unique-user-invite-link-service";
import { enqueueSnackbar } from "notistack";
import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import OrganizationSubscriptionService from "../../utilities/services/organization-subscription-service";
import { AddOns } from "../../models/enumerations/add-ons";
import { AddOnId } from "../../models/enumerations/add-on-id";
import { SymmioLicenseCheckbox } from "../symmio-license-checkbox";
import { ToggleButton } from "../forms";
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "c-user-list";

interface ClientsProps {
    organization: Organization;
    products: any[];
    setOrganization: Function;
}

const Clients: React.FC<ClientsProps> = (props: ClientsProps) => {
    const {
        organization,
        products,
        setOrganization,
    } = props;
    const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
    const [isSaving, setIsSaving] = useState(false);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [userToDelete, setUserToDelete] = useState<any>({});
    const [newEmails, setNewEmails] = useState([] as string[]);
    const [errorMessages, setErrorMessages] = useState([] as string[]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [selectedTags, setSelectedTags] = useState<UserTag[]>([]);
    const [groups, setGroups] = useState<Group[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<any>();
    const [selectedManagedGroups, setSelectedManagedGroups] = useState<string[]>();
    const [, setSelectedRole] = useState<any>();
    const [algoliaFilters, setAlgoliaFilters] = useState<string>(`organizationId:"NO_MATCH"`);
    const [searchAllOrgs, setSearchAllOrgs] = useState<boolean>(false);
    const [isRunning, setIsRunning] = useState<boolean>(false);
    const [isSymmioAccess, setIsSymmioAccess] = useState<boolean>(false);
    const [overUserLimit, setOverUserLimit] = useState<number | null>();
    const [orgUsers, setOrgUsers] = useState<number>();
    const [orgUserLimit, setOrgUserLimit] = useState<number>();
    const [parentOrg, setParentOrg] = useState<Organization | null>();
    const [activeUsers, setActiveUsers] = useState<number>();
    const [inactiveUsers, setInactiveUsers] = useState<number>();
    const [pendingUsers, setPendingUsers] = useState<number>();
    let invalidEmails: string[] = [];
    const [searchState, setSearchState] = useState({
        sortBy: SearchIndexes.usersByNameAsc
    });
    const { state } = useAuthState();
    const isSuperAdmin = UserUtil.isSuperAdmin(state.claims);
    const isAdmin = UserUtil.isAdmin(state.user);
    const isManager = UserUtil.isManager(state.user);
    const isAccountHolder = UserUtil.isAccountHolder(state.user);
    const CustomHits = connectHits(SearchHits);
    const { t } = useTranslation();

    useEffect(() => {
        const getCount = async () => {
            if (organization) {
                const allUsers = await OrganizationUtil.getOrganizationUserCount(organization);
                setOrgUsers(allUsers);

                if (organization.organizationLimit && !organization.isSubOrganization) {
                    setParentOrg(organization);
                    setOrgUserLimit(organization.userLimit);
                } else if (organization.parentOrganizationId) {
                    const pOrg = await OrganizationService.get(organization.parentOrganizationId);
                    if (pOrg && pOrg.userLimit) {
                        setParentOrg(pOrg);;
                        setOrgUserLimit(pOrg.userLimit);
                    }
                }
            }
            // if (isAccountHolder) {
            //     setOrgUserLimit(state.user?.usersLimit);
            // } else if (state.organization.accountHolderId) {
            //     const holder = await UserService.get(state.organization.accountHolderId);
            //     if (holder && holder.usersLimit) {
            //         setOrgUserLimit(holder.usersLimit);
            //     }
            // }
        }
        if (open) {
            getCount();
        }
    }, [state.user, organization, open]);

    useEffect(() => {
        const getUserStatus = async () => {
            let users: User[] = [];

            if (isManager) {
                const managedGroupIds = await UserUtil.getHierarchicalGroupIds(state.user?.managedGroupIds);

                if (managedGroupIds.length !== 0) {
                    users = await UserService.getBy([
                        {
                            field: "organizationId",
                            operator: "==",
                            value: state.organization.id,
                        },
                        {
                            field: "groupId",
                            operator: "in",
                            value: managedGroupIds,
                        },
                        {
                            field: "roles",
                            operator: "array-contains",
                            value: "client"
                        }
                    ]);
                }
            }
            else {
                users = await UserService.getBy([
                    {
                        field: "organizationId",
                        operator: "==",
                        value: state.organization.id,
                    },
                    {
                        field: "roles",
                        operator: "array-contains",
                        value: "client"
                    }
                ]);
            }

            setActiveUsers(users?.filter((u) => u.status === UserStatus.Active && u.authenticationId != null).length || 0);
            setInactiveUsers(users?.filter((u) => u.status === UserStatus.Inactive && u.authenticationId != null).length || 0);
            setPendingUsers(users?.filter((u) => u.status !== UserStatus.Disabled && u.authenticationId == null).length || 0);
        }


        getUserStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (state?.user == null || state?.organization?.id == null) {
            return;
        }

        const loadData = async () => {
            TagService.getBy([{
                field: "organizationId",
                operator: "==",
                value: state.organization.id
            }], [{
                field: "name",
                direction: "asc",
            }]).then((tags: Tag[]) => {
                setTags(tags);
            });

            GroupService.getBy([{
                field: "organizationId",
                operator: "==",
                value: state.organization.id
            }], [{
                field: "name",
                direction: "asc",
            }]).then((groups: Group[]) => {
                setGroups(groups);
            });

            setAlgoliaFilters(await UserUtil.getAlgoliaUserOrganizationAndGroupFilterByClient(
                state.user,
                searchAllOrgs ? undefined : state.organization.id,
            ));

        }

        loadData();
    }, [state.user, state.organization, searchAllOrgs]);

    const handleAddUserClick = () => {
        setErrorMessages([]);
        setOpen(true);
    }

    const handleSymmioAccessCheckbox = () => {
        setIsSymmioAccess(!isSymmioAccess);
    }

    const handleDeleteUserClick = async () => {
        if (userToDelete?.objectID == null) {
            return;
        }

        setIsSaving(true);

        await UserService.deleteById(userToDelete.objectID);

        setTimeout(() => {
            setOpenDelete(false);
            setUserToDelete(undefined);
            setIsSaving(false);
            searchClient.clearCache();
            enqueueSnackbar(t('users.user_del_confirm'), { variant: "toast", width: "450px" });
        }, 5000);

    }

    const isEmailUnique = async (email: string): Promise<boolean> => {

        let isUnique = true;
        const conditions = [{
            field: "email",
            operator: "==",
            value: email,
        }] as FirestoreCondition[];

        await UserService.getBy(conditions).then((result) => { isUnique = (result.length === 0) })
        return isUnique;
    }

    const handleSaveUserClick = async () => {
        const emails = newEmails.filter((email) => email.trim().length > 0 && Utils.validateEmailAddress(email.trim()));

        if (emails.length === 0) {
            errorMessages.push(t('forms.err_noEmails'))
            return;
        }

        setIsSaving(true);
        checkAndUpdateHiddenLimit();
        for (const email of emails) {
            const conditions = [{
                field: "email",
                operator: "==",
                value: email,
            }] as FirestoreCondition[];

            const usersByEmail = await UserService.getBy(conditions);
            if (!usersByEmail || usersByEmail.length > 0) {
                continue;
            }

            const user = {
                email: email.toLowerCase(),
                groupId: selectedGroup?.value ?? null,
                groupName: selectedGroup?.label ?? null,
                organizationId: state.organization?.id,
                status: UserStatus.Inactive,
                tags: selectedTags,
                roles: [UserRoles.CLIENT_ID],
                symmioAccess: isSymmioAccess ? SymmioAccessType.AppLicense : SymmioAccessType.WebAccess,
                managedGroupIds: selectedManagedGroups,
            } as User;

            const savedUser = await UserService.save(user, state.user);

            const uniqueInviteLink = {
                userId: savedUser.id,
                senderEmail: state.user?.email,
                organizationId: state.organization.id,
            } as UniqueUserInviteLink

            await UniqueUserInviteLinksService.save(uniqueInviteLink, state.user);
        }
        enqueueSnackbar(t('users.user_add_confirm')
            , { variant: "toast", width: "550px", style: { whiteSpace: 'pre-line' } });
        setTimeout(() => {
            setOpen(false);
            setNewEmails([]);
            setIsSaving(false);
            searchClient.clearCache();
            setOrganization()
        }, 5000);
    }

    useEffect(() => {
        if (!open) {
            setNewEmails([]);
        }
    }, [open]);

    useEffect(() => {
        if (!openDelete) {
            setUserToDelete(undefined);
        }
    }, [openDelete]);

    const getOverLimitMessage = () => {
        if (orgUserLimit && orgUsers) {
            if ((isAccountHolder || isAdmin || isSuperAdmin) && orgUserLimit && orgUsers) {
                return t('users.user_limit_exceeded', { orgUserLimit: orgUserLimit, overUserLimit: overUserLimit })
            }
        }
        return t('users.user_limit_reached');
    };

    const checkAccountHolderLimit = async (numberOfEmails: number) => {
        if (parentOrg && parentOrg.userLimit) {
            const limit = parentOrg?.userLimit;
            const current = await OrganizationUtil.getOrganizationUserCount(parentOrg, true);
            const remaining = limit - current;
            const overLimit = numberOfEmails > remaining;
            if (overLimit) {
                setOverUserLimit((limit - (current + numberOfEmails)) * -1);
            } else {
                setOverUserLimit(null);
            }
        }
        return false;
    };

    const checkAndUpdateHiddenLimit = async () => {
        await OrganizationSubscriptionService.getByOrganizationId(state.organization.id!).then(async (orgSubs) => {

            // Check if they're at the hidden limit
            if (orgSubs[0] && orgSubs[0].addOnsToBeRemoved && orgSubs[0].addOnsToBeRemoved.find((item: any) => item.name === AddOns.User)) {
                // We need to grab the absolute latest organization object.
                // If a client tries to add users twice in a row, we can't reliably update the organization state object as
                // The organization count is updated based on a function
                // It's possible that on the 2nd "add user" flow, when we tried to update the organization state right after the 1st "add user" flow
                // the function hadn't updated the organization object yet. Therefore, we're comparing the wrong userCount
                await OrganizationService.get(orgSubs[0].organizationId).then(async (org) => {
                    const sub = await stripe.subscriptions.retrieve(orgSubs[0].stripeAddOnSubscription);
                    const addOnsToBeRemoved = orgSubs[0].addOnsToBeRemoved!.find((item: any) => item.name === AddOns.User)
                    const hiddenLimit = org && org.userLimit && addOnsToBeRemoved && addOnsToBeRemoved.amountToBeRemoved && (addOnsToBeRemoved.amountManaged || addOnsToBeRemoved.amountManaged === 0) ? org.userLimit - (addOnsToBeRemoved.amountToBeRemoved - addOnsToBeRemoved.amountManaged) : null
                    if (org && org.userCount && hiddenLimit && addOnsToBeRemoved?.amountToBeRemoved && hiddenLimit < org.userCount + newEmails.length) {
                        // Figure out how much over the limit they are
                        const amountOver = (org.userCount + newEmails.length) - (hiddenLimit)
                        // Update OrgSub
                        addOnsToBeRemoved.amountToBeRemoved = addOnsToBeRemoved.amountToBeRemoved - amountOver;
                        await OrganizationSubscriptionService.save(orgSubs[0]);

                        // Update subscription schedule phase
                        // Retrieve existing subscription schedule
                        const subscriptionSchedule = await stripe.subscriptionSchedules.retrieve(sub.schedule);

                        // Check if subscription schedule has already been updated for the future month
                        // To do so, take last index of phase array and check if phase.start/end === subSched.current_phase
                        // If === then no future phase isn't created
                        // If !== then future phase is created and we want to update
                        const futurePhaseExists = !((subscriptionSchedule.phases.at(-1).start_date === subscriptionSchedule.current_phase.start_date) &&
                            (subscriptionSchedule.phases.at(-1).end_date === subscriptionSchedule.current_phase.end_date));
                        // We should always get in this as the only way we would have "AddOnsToBeRemoved" is if they've modified add-ons and created a subscription schedule
                        if (futurePhaseExists) {
                            // Update existing phase quantity
                            // TODO: Should prob use an enum for priceIds of prod products
                            const index = subscriptionSchedule.phases.at(-1).items.findIndex((item: any) => item.price === products.find((item) => item.product.id === AddOnId.User).prices.find((item: any) => item.data.active === true).id);
                            subscriptionSchedule.phases.at(-1).items[index].quantity = subscriptionSchedule.phases.at(-1).items[index].quantity + amountOver

                            // Create new phases object
                            let phases: any[] = [];
                            subscriptionSchedule.phases.forEach(function (phase: any, index: any, array: any) {
                                let phaseItems: any[] = [];
                                phase.items.forEach(function (item: any) {
                                    phaseItems.push({
                                        price: item.price,
                                        quantity: item.quantity,
                                    })
                                })

                                // Check if we're on last iteration
                                if (index === array.length - 1) {
                                    phases.push({
                                        items: phaseItems,
                                        iterations: 1,
                                    })
                                }
                                else {

                                    phases.push({
                                        items: phaseItems,
                                        start_date: phase.start_date,
                                        end_date: phase.end_date,
                                    })
                                }
                            })

                            // Update existing phase            
                            await stripe.subscriptionSchedules.update(

                                sub.schedule,
                                {
                                    phases: phases
                                },
                            )
                        }
                    }
                })
            }
        })
    }

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <Loader
                isVisible={isSaving} />

            { // if
                (isSuperAdmin) &&
                <ToggleButton
                    id="searchAllOrgsCheckbox"
                    onClick={(event) => { setSearchAllOrgs((event?.target as any)?.checked === true) }}
                    mainLabelText={t('buttons.btn_searchAllOrgs')} />

            }
            <InstantSearch
                indexName={SearchIndexes.usersByNameAsc}
                searchClient={searchClient}
                searchState={searchState}
                onSearchStateChange={setSearchState}>
                <Configure
                    filters={algoliaFilters}
                    hitsPerPage={50}
                />
                <SortBy
                    items={[
                        { label: t('users.sorting.nameAsc'), value: SearchIndexes.usersByNameAsc },
                        { label: t('users.sorting.nameDesc'), value: SearchIndexes.usersByNameDesc },
                        { label: t('users.sorting.groupAsc'), value: SearchIndexes.usersByGroupAsc },
                        { label: t('users.sorting.groupDesc'), value: SearchIndexes.usersByGroupDesc },
                        { label: t('users.sorting.statusAsc'), value: SearchIndexes.usersByStatusAsc },
                        { label: t('users.sorting.statusDesc'), value: SearchIndexes.usersByStatusDesc },
                        { label: t('users.sorting.accessAsc'), value: SearchIndexes.usersBySymmioAccessAsc },
                        { label: t('users.sorting.accessDesc'), value: SearchIndexes.usersBySymmioAccessDesc },
                    ]}
                    defaultRefinement="users"
                />
                <div style={{ display: "flex", flexFlow: "wrap-reverse", justifyContent: "space-between", alignItems: "center", gap: "1rem", marginTop: "1rem" }}>
                    <SearchBox
                        autoFocus
                        className={`${COMPONENT_CLASS}__search`}
                        searchAsYouType={true}
                        showLoadingIndicator={true}
                        loadingIndicator={<Loader isVisible={true} />}
                        translations={{
                            placeholder: t('general.search')
                        }}
                    />
                    <div style={{ display: "flex", flexFlow: "wrap", justifyContent: "center", gap: "8px" }}>
                        <div className={`${COMPONENT_CLASS}__info-card`}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <div style={{ fontSize: "24px", fontStyle: "normal", fontWeight: "700" }}>{activeUsers ? activeUsers : "0"}</div>
                                <div className="uppercase" style={{ margin: "auto 0", fontSize: "12px", fontStyle: "normal", fontWeight: "400", marginLeft: "8px" }}>{t('users.active')}</div>
                            </div>
                        </div>
                        <div className={`${COMPONENT_CLASS}__info-card`}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <div style={{ fontSize: "24px", fontStyle: "normal", fontWeight: "700" }}>{inactiveUsers ? inactiveUsers : "0"}</div>
                                <div className="uppercase" style={{ margin: "auto 0", fontSize: "12px", fontStyle: "normal", fontWeight: "400", marginLeft: "8px" }}>{t('users.inactive')}</div>
                            </div>
                        </div>
                        <div className={`${COMPONENT_CLASS}__info-card`}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <div style={{ fontSize: "24px", fontStyle: "normal", fontWeight: "700" }}>{pendingUsers ? pendingUsers : "0"}</div>
                                <div className="uppercase" style={{ margin: "auto 0", fontSize: "12px", fontStyle: "normal", fontWeight: "400", marginLeft: "8px" }}>{t('users.userPending')}</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__actions`}>
                        <Button
                            type="default"
                            buttonText={t('buttons.btn_addUsers')}
                            onClick={() => handleAddUserClick()} />
                    </div>
                </div>
                <CustomHits
                    setSearchState={setSearchState}
                    searchState={searchState}
                    SearchIndexes={SearchIndexes}
                    setUserToDelete={setUserToDelete}
                    isSuperAdmin={isSuperAdmin}
                    isAdmin={isAdmin}
                    isManager={isManager}
                    isAccountHolder={isAccountHolder}
                    setOpenDelete={setOpenDelete}
                    COMPONENT_CLASS={COMPONENT_CLASS}
                    isTeam={false}
                />
                <Pagination
                    className={`${COMPONENT_CLASS}__pagination`} />
            </InstantSearch >
            <Modal
                isOpen={open}
                isLoading={isSaving}
                onClose={() => {
                    setIsSymmioAccess(false);
                    setOpen(false);
                    setSelectedRole("")
                    setSelectedManagedGroups([]);
                }}
                title={t('users.addUsers')}
                defaultModalActions={true}
                submitDisabled={newEmails == null || newEmails.length === 0 || (!!overUserLimit) || (isSymmioAccess && organization.mySymmioLicenseCount! + newEmails.length > organization.mySymmioLicense!)}
                onCancel={() => {
                    setOpen(false)
                    setSelectedRole("")
                    setSelectedManagedGroups([]);
                }}
                onSubmit={() => handleSaveUserClick()}
                submitButtonText={t('buttons.btn_sendInvites')}>
                <div className={errorMessages.length > 0 ? `${COMPONENT_CLASS}__fieldgroup-error` : `${COMPONENT_CLASS}__fieldgroup`}>
                    <label>{t('users.emails')}</label>
                    <EmailList
                        onEmailsAdded={(emails) => {
                            errorMessages.length = 0;

                            if (!isRunning) {
                                setIsRunning(true);

                                const result = [...newEmails];
                                emails = StringUtil.getUniqueValues(emails, true);

                                Promise.all(emails.map(async (email) => {
                                    if (await isEmailUnique(email) === false) {
                                        invalidEmails.push(" " + email);
                                    }
                                    else if (!StringUtil.existsInArray(email, result, true)) {
                                        result.push(email);
                                    }
                                })).then(async () => {

                                    if (invalidEmails.length !== 0) {
                                        let message = t('users.err_users_exist', { invalidEmails: invalidEmails.toString() });
                                        if (errorMessages.indexOf(message) === -1) {
                                            errorMessages.push(message);
                                        }
                                    }

                                    setNewEmails(result);
                                    await checkAccountHolderLimit(result.length);

                                    setIsRunning(false);
                                });
                            }

                        }}
                        onEmailsError={(errors) => { errorMessages.push(...new Set(errorMessages.concat(errors))); setErrorMessages(errorMessages); }}
                        enableSuggestions={false}
                    />
                    { // if
                        (errorMessages.length > 0 || overUserLimit) &&
                        <div className={`${COMPONENT_CLASS}__email-errors`}>
                            {errorMessages.map((error, i) => <div className={`${COMPONENT_CLASS}__email-errors__item`} key={`error-${i}`}>{error}</div>)}
                            {
                                (overUserLimit) &&
                                <div className={`${COMPONENT_CLASS}__email-errors__item`}>
                                    {getOverLimitMessage()}
                                </div>
                            }
                        </div>
                    }
                </div>
                <div className={`${COMPONENT_CLASS}__fieldgroup-row`}>
                    <div className={`${COMPONENT_CLASS}__fieldgroup ${COMPONENT_CLASS}__fieldgroup-row-group`}>
                        <label htmlFor="group">{t('users.group')}</label>
                        <Select
                            classNamePrefix={"c-user-profile-header"}
                            isClearable={true}
                            name="group"
                            onChange={(newValue: any, actionMeta: any) => setSelectedGroup(newValue)}
                            options={groups.map((g) => ({ label: g.name, value: g.id }))}
                            placeholder={t('users.group')}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: "100%",
                                    borderRadius: "1.5rem",
                                    lineHeight: "1.5rem",
                                    padding: "0.3rem 1.25rem"
                                }),
                            }} />
                    </div>
                    <div className={`${COMPONENT_CLASS}__fieldgroup ${COMPONENT_CLASS}__fieldgroup-row-tag`}>
                        <label htmlFor="tags">{t('users.tags')}</label>
                        <Select
                            classNamePrefix={"c-user-profile-header"}
                            isMulti
                            isClearable={false}
                            name="tags"
                            onChange={(newValue: any, actionMeta: any) => setSelectedTags(newValue.map((v: ListOptions) => ({ id: v.value, name: v.label })))}
                            options={tags.map((t) => ({ label: t.name, value: t.id }))}
                            placeholder={t('users.tags')}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: "100%",
                                    borderRadius: "1.5rem",
                                    lineHeight: "1.5rem",
                                    padding: "0.3rem 1.25rem"
                                }),
                            }} />
                    </div>
                </div>
                { // if
                    (isAdmin || isSuperAdmin || isManager || isAccountHolder) &&
                    <div className={`${COMPONENT_CLASS}__fieldgroup`}>
                        <label htmlFor="symmioAccess">{t('users.enroll_in_wellness')}</label>
                        <SymmioLicenseCheckbox
                            organization={organization}
                            onClick={handleSymmioAccessCheckbox}
                            isChecked={isSymmioAccess}
                            numberOfAddedLicenses={newEmails.length}
                            numberOfRemovedLicenses={0}
                        />
                    </div>
                }
                { // if
                    newEmails != null && newEmails.length > 0 &&
                    <div className={`${COMPONENT_CLASS}__email-invites`}>
                        <Dialog.Title
                            as="h4">
                            {t('users.invitesToBeSent')}
                        </Dialog.Title>
                        <div>
                            {newEmails.map((email, i) => (<TagComponent
                                id={i.toString()}
                                onCloseClick={(index) => {
                                    const emails = [...newEmails];
                                    emails.splice(parseInt(index), 1);
                                    setNewEmails(emails);
                                    checkAccountHolderLimit(emails.length);
                                }}
                                key={`email-${i}`}
                            >{email}</TagComponent>))}
                        </div>
                    </div>
                }

            </Modal>
            <Modal
                isOpen={openDelete}
                isLoading={isSaving}
                onClose={setOpenDelete}
                title={t('buttons.btn_deleteUser')}
                defaultModalActions={true}
                submitButtonText={t('buttons.btn_deleteUserConfirm')}
                onSubmit={() => handleDeleteUserClick()}
                onCancel={() => setOpenDelete(false)}>
                <div>
                    <p>
                        {t('users.user_confirmDelete', { email: userToDelete?.email })}
                    </p>
                </div>
            </Modal>

        </div >
    );
}

export { Clients };