import React from "react";
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "c-input-option";

export interface MinuteInputOptionProps {
    onChange: (newAnswer: string) => void;
    answer?: string;
    maxValue?: number;
}

const MinuteInputOption: React.FC<MinuteInputOptionProps> = (props) => {
    const integerOnlyValidation = (event: any) => {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    };
    const { t } = useTranslation();

    return (
        <div className={`${COMPONENT_CLASS} -weight`}>
            <div className={`${COMPONENT_CLASS}__container`}>
                <div className={`${COMPONENT_CLASS}__col`}>
                    <div className={`${COMPONENT_CLASS}__minute-input`}>
                        <input className={`${COMPONENT_CLASS}__input`}
                            autoFocus
                            value={props.answer}
                            onChange={(e: any) => {
                                if (e.target.value == null) {
                                    props.onChange("");
                                    return;
                                }
                                let answerStr = e.target.value.toString();
                                if (props.maxValue != null) {
                                    const answer = parseFloat(answerStr);
                                    if (answer > props.maxValue) {
                                        answerStr = answerStr.substring(
                                            0,
                                            answerStr.length - 1
                                        );
                                        props.onChange(answerStr);
                                        return;
                                    }
                                }
                                props.onChange(answerStr);
                            }}
                            type="number"
                            maxLength={3}
                            onKeyPress={(e: any) => integerOnlyValidation(e)}>

                        </input>
                    </div>
                    {/* <IonItem lines="none" data-testid="time-options">
                        <IonInput
                            autofocus
                            value={props.answer}
                            maxlength={3}
                            onKeyPress={(e: any) => integerOnlyValidation(e)}
                            onIonChange={(e: any) => {
                                if (e.detail.value == null) {
                                    props.onChange("");
                                    return;
                                }
                                let answerStr = e.detail.value.toString();
                                if (props.maxValue != null) {
                                    const answer = parseFloat(answerStr);
                                    if (answer > props.maxValue) {
                                        answerStr = answerStr.substring(
                                            0,
                                            answerStr.length - 1
                                        );
                                        props.onChange(answerStr);
                                        return;
                                    }
                                }
                                props.onChange(answerStr);
                            }}
                            type="number"
                        />
                    </IonItem> */}
                    <span className={`${COMPONENT_CLASS}__minute-span`}>{t('general.time.min')}</span>
                </div>
            </div>
        </div>
    );
};

export default MinuteInputOption;
