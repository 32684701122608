/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import AssessmentResponse from "../../models/interfaces/assessment-response.entity";
import MskScoreService from "../../utilities/services/msk-score-service";
import moment from "moment";
import { GroupIds } from "../../models/enumerations/group-ids";
import { ReportAnswer } from "../../models/enumerations/report-answer";
import { QuestionIds } from "../../models/enumerations/question-ids";
import { QuestionResponse } from "../../models/interfaces/questions/question-response";
import { LifestyleSleepPsqi } from "../../utilities/lifestyle-sleep-psqi";
import LifestyleQuestionGroupScore from "../../utilities/lifestyle-question-group-score.entity";
import UserProfileAssessmentHeader from "./user-profile-assessment-header";
import NonLinearLineChart from "../charts/non-linear-line-chart";
import LineChartJS from "../charts/line-chart-js";
import RiskChartVertical from "../charts/risk-chart-vertical";
import { useTranslation } from "react-i18next";

const COMPONENT_CLASS = "c-user-profile-assessments";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileSleepProps {
    mskScore?: MskScore;
    user: User;
    assessment?: any;
    assessmentResponses: AssessmentResponse[];
    allMskScores: MskScore[];
    setAllMskScores: Function;
    setSpecificAssessment: Function;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileSleep: React.FC<UserProfileSleepProps> = (props: UserProfileSleepProps) => {
    const { setSpecificAssessment, mskScore, assessment, allMskScores, setAllMskScores, user, assessmentResponses } = props;
    const [sleepActivityHistory, setSleepActivityHistory] = useState<{ x: string, y: number }[]>();
    const [sleepPsqiHistory, setSleepPSQIHistory] = useState<{ x: string, y: number }[]>();
    const { t } = useTranslation();

    useEffect(() => {
        const getAllMskScores = async () => {
            if (!allMskScores) {
                const mskScores = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }])

                setAllMskScores(mskScores);
            }
        }
        getAllMskScores();
    }, [])
    useEffect(() => {
        const getHistory = () => {
            if (allMskScores) {
                let tempSleepActivityHistory = []
                let tempSleepPsqiHistory = [];

                for (const mskScore of allMskScores) {
                    tempSleepActivityHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.lifestyleScore?.sleepScore?.percentage ?? 0
                    })
                    if (mskScore.lifestyleScore?.sleepScore?.psqiScore || mskScore.lifestyleScore?.sleepScore?.psqiScore === 0) {
                        tempSleepPsqiHistory.push({
                            x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                            y: mskScore.lifestyleScore?.sleepScore?.psqiScore ?? 0
                        })
                    }
                }
                // if 0, use assessmentResponses
                if (tempSleepPsqiHistory.length === 0) {
                    const psqi = getSleepResponses();
                    if (psqi) {
                        tempSleepPsqiHistory.push(psqi);
                    }
                }
                setSleepActivityHistory(tempSleepActivityHistory);
                setSleepPSQIHistory(tempSleepPsqiHistory);
            }
        }

        getHistory();
    }, [allMskScores])

    const getSleepResponses = () => {
        if (!assessmentResponses) {
            return;
        }
        // Check if sleep responses produce PSQI questions

        const sleepResponses: any = assessmentResponses.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.SLEEP)
        let numFailed = 0
        if (sleepResponses && sleepResponses.length > 0) {
            const q1 = sleepResponses[0].responses.find((response: any) => response.questionId === QuestionIds.SLEEP_REST)
            if (q1 && (q1.answerId === ReportAnswer.A || q1.answerId === ReportAnswer.B)) {
                numFailed++;
            }
            const q2 = sleepResponses[0].responses.find((response: any) => response.questionId === QuestionIds.SLEEP_ENERGY)
            if (q2 && (q2.answerId === ReportAnswer.A || q2.answerId === ReportAnswer.B)) {
                numFailed++;
            }
            const q3 = sleepResponses[0].responses.find((response: any) => response.questionId === QuestionIds.SLEEP_DROWSY)
            if (q3 && (q3.answerId === ReportAnswer.C)) {
                numFailed++;
            }
        }
        if (numFailed >= 2) {
            const sleepPSQIResponses: any = assessmentResponses.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.SLEEP_PSQI)
            const sleepPSQIQuestionResponses: QuestionResponse[] = []
            if (sleepPSQIResponses && sleepPSQIResponses.length > 0) {
                for (const response of sleepPSQIResponses[0].responses) {
                    sleepPSQIQuestionResponses.push({
                        questionId: response.questionId,
                        answerId: response.answerId,
                        answerResponse: response.answerResponse
                    })
                }
            }

            if (sleepPSQIQuestionResponses.length === 17) {
                const calculator = new LifestyleSleepPsqi();
                calculator.load(sleepPSQIQuestionResponses);
                calculator.validate();
                const sleepPSQIScore = calculator.getScore() as LifestyleQuestionGroupScore;
                return {
                    x: moment(sleepResponses[0] && sleepResponses[0].created?._seconds ? sleepResponses[0].created._seconds * 1000 : NaN).format("MMM DD, YY"),
                    y: sleepPSQIScore.score ?? 0
                }
            }
        }
        else {
            return {
                x: moment(sleepResponses[0] && sleepResponses[0].created?._seconds ? sleepResponses[0].created._seconds * 1000 : NaN).format("MMM DD, YY"),
                y: 0
            }
        }
    }


    return (
        <>
            <div>
                <UserProfileAssessmentHeader
                    assessment={assessment}
                    mskScore={mskScore}
                    setSpecificAssessment={setSpecificAssessment}
                    mainTextHeader={t('userProfile.assessments.sleepHeader')}
                    mainText={t('userProfile.assessments.sleepText')}
                    whyImportantText={t('userProfile.assessments.sleepWhy')}
                />
            </div>

            {sleepActivityHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper my-5`}>
                    <LineChartJS
                        data={sleepActivityHistory.map(history => history.y)}
                        title={t('userProfile.assessments.sleepHistory')}
                        labels={sleepActivityHistory.map(history => history.x)}
                        height={"200px"}
                        horizontalScrolling={true}
                        pointRadius={7}
                        pointBorderWidth={3}
                        borderWidth={5}
                        pointFontSize={16}
                        fontFamily={'pragmatica-extended'}
                        pointLabelOffset={0}
                        xLabelFontSize={14}
                        paddingTop={30}
                        paddingBottom={20}
                        maintainAspectRatio={false}
                    />
                </div>
            )}


            {sleepPsqiHistory && sleepPsqiHistory.length > 0 && (
                <div className="flex gap-5">
                    <div className={`${COMPONENT_CLASS}__score-section-card min-w-72 max-w-72`}>
                        <h3 className="flex items-center justify-center mb-4">
                            {t('reports.wellness.coloredCells.sleep.title')}
                        </h3>
                        <div className="w-full h-64">
                            <RiskChartVertical
                                score={sleepPsqiHistory[sleepPsqiHistory.length - 1].y}
                                breakpoints={[
                                    { min: 10, max: 21, label: t('reports.wellness.coloredCells.sleep.redMain').toUpperCase(), bgColor: "#FFA998", subLabel: t('reports.wellness.coloredCells.sleep.redSub') },
                                    { min: 5, max: 9, label: t('reports.wellness.coloredCells.sleep.orangeMain').toUpperCase(), bgColor: "#FFC79C", subLabel: t('reports.wellness.coloredCells.sleep.orangeSub') },
                                    { min: 0, max: 4, label: t('reports.wellness.coloredCells.sleep.greenMain').toUpperCase(), bgColor: "#8BE3CE", subLabel: t('reports.wellness.coloredCells.sleep.greenSub') },
                                ]}
                                decimals={false}
                            />
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__score-section-card chart-wrapper grow`}>
                        <NonLinearLineChart
                            dataPoints={sleepPsqiHistory.map(history => history.y)}
                            labels={sleepPsqiHistory.map(history => history.x)}
                            title={t('userProfile.assessments.sleepPsqiHistory')}
                            sectionDetails={{
                                sections: 3,
                                rangeOne: { start: 0, end: 4 },
                                rangeTwo: { start: 5, end: 9 },
                                rangeThree: { start: 10, end: 21 },
                                backgroundColorOrder: { first: "#8BE3CE", second: "#FFE9CC", third: "#FFA998" },
                                dataPointColorOrder: { first: "#00DFBC", second: "#FFC79C", third: "#E7514F" },
                                yAxisLabels: { first: t('reports.wellness.coloredCells.sleep.greenMain').toUpperCase(), second: t('reports.wellness.coloredCells.sleep.orangeMain').toUpperCase(), third: t('reports.wellness.coloredCells.sleep.redMain').toUpperCase() },
                                decimals: false
                            }}
                            toolTipLabel={true}
                        />
                    </div>
                </div>


            )}
        </>
    );
}

export default UserProfileSleep;
