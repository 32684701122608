import i18n from "../i18n";
import { GroupIds } from "../models/enumerations/group-ids";

export default class GroupUtil {

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public static getName(groupId?: string): string {
        groupId = GroupUtil.getGroupIdToUse(groupId);
        const group = GroupUtil.groupData.find(x => x.groupId === groupId);
        if (group == null) {
            return i18n.t('utils.groupUtilities.noName', { groupID: groupId });
        }
        return group.name;
    }

    public static getNameTranslationReference(groupId?: string): string {
        groupId = GroupUtil.getGroupIdToUse(groupId);
        const group = GroupUtil.groupDataTranslationReference.find((x) => x.groupId === groupId);
        if (group == null) {
            return i18n.t('utils.groupUtilities.noName', { groupID: groupId });
        }
        return group.name;
    }

    public static getDescription(groupId?: string): string | undefined {
        groupId = GroupUtil.getGroupIdToUse(groupId);
        const group = GroupUtil.groupData.find(x => x.groupId === groupId);
        if (group == null) {
            return i18n.t('utils.groupUtilities.noDescription', { groupID: groupId });
        }
        return group.description;
    }

    // #endregion Public Methods


    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    private static groupData = [
        // Road to Wellness
        { groupId: GroupIds.ROAD_TO_WELLNESS, name: i18n.t('focus.roadToWellness'), description: undefined },

        // Pain
        { groupId: GroupIds.PAIN, name: i18n.t('movementFocus.pain'), description: undefined },

        // Movements
        { groupId: GroupIds.BALANCE, name: i18n.t('movementFocus.balanceAndReach'), description: undefined },
        { groupId: GroupIds.ROTATION, name: i18n.t('movementFocus.rotation'), description: undefined },
        { groupId: GroupIds.SHOULDER_CLEARING, name: i18n.t('movementFocus.shoulderClearing'), description: undefined },
        { groupId: GroupIds.SHOULDER_MOBILITY, name: i18n.t('movementFocus.shoulderMobility'), description: undefined },
        { groupId: GroupIds.SPINE_CLEARING, name: i18n.t('movementFocus.spineClearing'), description: undefined },
        { groupId: GroupIds.SQUAT, name: i18n.t('movementFocus.squat'), description: undefined },
        { groupId: GroupIds.TOE_TOUCH, name: i18n.t('movementFocus.toeTouch'), description: undefined },

        // Movement + Breathing
        { groupId: GroupIds.BALANCE_BREATHING, name: i18n.t('movementFocus.balanceAndReachBreathing'), description: undefined },
        { groupId: GroupIds.ROTATION_BREATHING, name: i18n.t('movementFocus.rotationBreathing'), description: undefined },
        { groupId: GroupIds.SHOULDER_CLEARING_BREATHING, name: i18n.t('movementFocus.shoulderClearingBreathing'), description: undefined },
        { groupId: GroupIds.SHOULDER_MOBILITY_BREATHING, name: i18n.t('movementFocus.shoulderMobilityBreathing'), description: undefined },
        { groupId: GroupIds.SPINE_CLEARING_BREATHING, name: i18n.t('movementFocus.spineClearingBreathing'), description: undefined },
        { groupId: GroupIds.SQUAT_BREATHING, name: i18n.t('movementFocus.squatBreathing'), description: undefined },
        { groupId: GroupIds.TOE_TOUCH_BREATHING, name: i18n.t('movementFocus.toeTouchBreathing'), description: undefined },

        // Lifestyle
        { groupId: GroupIds.BEHAVIORAL_HEALTH, name: i18n.t('focus.behavioralHealth'), description: i18n.t('utils.groupUtilities.focusDescription.behavioral') },
        { groupId: GroupIds.BODY_COMPOSITION, name: i18n.t('focus.bodyComposition'), description: i18n.t('utils.groupUtilities.focusDescription.body_composition') },
        { groupId: GroupIds.BREATHING, name: i18n.t('focus.breathingQuality'), description: i18n.t('utils.groupUtilities.focusDescription.breathing') },
        { groupId: GroupIds.INJURY_HISTORY, name: i18n.t('focus.injuryHistory'), description: i18n.t('utils.groupUtilities.focusDescription.injury_history') },
        { groupId: GroupIds.NUTRITION, name: i18n.t('focus.nutritionAwareness'), description: i18n.t('utils.groupUtilities.focusDescription.nutrition') },
        { groupId: GroupIds.PHYSICAL_ACTIVITY, name: i18n.t('focus.physicalActivity'), description: i18n.t('utils.groupUtilities.focusDescription.physical_activity') },
        { groupId: GroupIds.SLEEP, name: i18n.t('focus.sleepWellness'), description: i18n.t('utils.groupUtilities.focusDescription.sleep') },

        // General
        { groupId: GroupIds.GENERAL_HEALTH, name: i18n.t('focus.generalHealth'), description: undefined },
        { groupId: GroupIds.MOVEMENT_HEALTH, name: i18n.t('focus.movementHealth'), description: i18n.t('utils.groupUtilities.focusDescription.movement') },
        { groupId: GroupIds.MOVEMENT_HEALTH_BREATHING, name: i18n.t('focus.movementHealthAndBreathing'), description: i18n.t('utils.groupUtilities.focusDescription.movementBreathing') },
    ];

    private static groupDataTranslationReference = [
        // Road to Wellness
        { groupId: GroupIds.ROAD_TO_WELLNESS, name: 'focus.roadToWellness', description: undefined },

        // Pain
        { groupId: GroupIds.PAIN, name: 'movementFocus.pain', description: undefined },

        // Movements
        { groupId: GroupIds.BALANCE, name: 'movementFocus.balanceAndReach', description: undefined },
        { groupId: GroupIds.ROTATION, name: 'movementFocus.rotation', description: undefined },
        { groupId: GroupIds.SHOULDER_CLEARING, name: 'movementFocus.shoulderClearing', description: undefined },
        { groupId: GroupIds.SHOULDER_MOBILITY, name: 'movementFocus.shoulderMobility', description: undefined },
        { groupId: GroupIds.SPINE_CLEARING, name: 'movementFocus.spineClearing', description: undefined },
        { groupId: GroupIds.SQUAT, name: 'movementFocus.squat', description: undefined },
        { groupId: GroupIds.TOE_TOUCH, name: 'movementFocus.toeTouch', description: undefined },

        // Movement + Breathing
        { groupId: GroupIds.BALANCE_BREATHING, name: 'movementFocus.balanceAndReachBreathing', description: undefined },
        { groupId: GroupIds.ROTATION_BREATHING, name: 'movementFocus.rotationBreathing', description: undefined },
        { groupId: GroupIds.SHOULDER_CLEARING_BREATHING, name: 'movementFocus.shoulderClearingBreathing', description: undefined },
        { groupId: GroupIds.SHOULDER_MOBILITY_BREATHING, name: 'movementFocus.shoulderMobilityBreathing', description: undefined },
        { groupId: GroupIds.SPINE_CLEARING_BREATHING, name: 'movementFocus.spineClearingBreathing', description: undefined },
        { groupId: GroupIds.SQUAT_BREATHING, name: 'movementFocus.squatBreathing', description: undefined },
        { groupId: GroupIds.TOE_TOUCH_BREATHING, name: 'movementFocus.toeTouchBreathing', description: undefined },

        // Lifestyle
        {
            groupId: GroupIds.BEHAVIORAL_HEALTH,
            name: 'focus.behavioralHealth',
            description: 'utils.groupUtilities.focusDescription.behavioral'
        }, {
            groupId: GroupIds.BODY_COMPOSITION,
            name: 'focus.bodyComposition',
            description: 'utils.groupUtilities.focusDescription.body_composition'
        }, {
            groupId: GroupIds.BREATHING,
            name: 'focus.breathingQuality',
            description: 'utils.groupUtilities.focusDescription.breathing'
        }, {
            groupId: GroupIds.INJURY_HISTORY,
            name: 'focus.injuryHistory',
            description: 'utils.groupUtilities.focusDescription.injury_history'
        }, {
            groupId: GroupIds.NUTRITION,
            name: 'focus.nutritionAwareness',
            description: 'utils.groupUtilities.focusDescription.nutrition'
        }, {
            groupId: GroupIds.PHYSICAL_ACTIVITY,
            name: 'focus.physicalActivity',
            description: 'utils.groupUtilities.focusDescription.physical_activity'
        }, {
            groupId: GroupIds.SLEEP,
            name: 'focus.sleepWellness',
            description: 'utils.groupUtilities.focusDescription.sleep'
        },

        // General
        { groupId: GroupIds.GENERAL_HEALTH, name: 'focus.generalHealth', description: undefined },
        {
            groupId: GroupIds.MOVEMENT_HEALTH,
            name: 'focus.movementHealth',
            description: 'utils.groupUtilities.focusDescription.movement'
        },
        { groupId: GroupIds.MOVEMENT_HEALTH_BREATHING, name: 'focus.movementHealthAndBreathing', description: 'utils.groupUtilities.focusDescription.movementBreathing' },
    ];

    private static getGroupIdToUse(groupId: string | undefined): string | undefined {
        switch (groupId) {
            case GroupIds.BEHAVIORAL_QUESTIONNAIRE:
                return GroupIds.BEHAVIORAL_HEALTH;
            case GroupIds.NUTRITION_QUESTIONNAIRE:
                return GroupIds.NUTRITION;
            case GroupIds.SLEEP_PSQI:
                return GroupIds.SLEEP;
            default:
                return groupId;
        }
    }

    // #endregion Private Methods

}
