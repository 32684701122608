/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import AppContentTypeToggle from "../app-content-type-toggle/app-content-type-toggle";

const COMPONENT_CLASS = "c-input-option";
const CONVERSION = 2.54;

export interface HeightInputOptionProps {
    onChange: (newAnswer: string) => void;
    answer?: string;
}

const HeightInputOption: React.FC<HeightInputOptionProps> = (props) => {
    const { t, i18n } = useTranslation();
    const initialInches = props.answer
        ? (parseInt(props.answer) % 12).toFixed(0)
        : "";
    const initialFeet = props.answer
        ? Math.floor(parseInt(props.answer) / 12).toString()
        : "";
    const [inches, setInches] = useState(initialInches);
    const [feet, setFeet] = useState(initialFeet);
    const [isImperial, setIsImperial] = useState(i18n.language === "en");

    const inchesToCm = (inch: number) => {
        return inch * CONVERSION;
    };

    const cmToInches = (centimeters: number) => {
        return centimeters / CONVERSION;
    };

    const [cm, setCm] = useState(props.answer
        ? inchesToCm(parseInt(props.answer)).toFixed(0)
        : "");

    const inchesRef = useRef(null) as any;

    useEffect(() => {
        let newAnswer = 0;

        if (isImperial) {
            newAnswer =
                parseInt(inches.length > 0 ? inches : "0") +
                parseInt(feet.length > 0 ? feet : "0") * 12;
        }
        else {
            newAnswer = Math.round(cmToInches(parseInt(cm.length > 0 ? cm : "0")));
        }

        if (newAnswer <= 0) {
            props.onChange("");
            return;
        }

        props.onChange(newAnswer.toString());
    }, [feet, inches, cm, isImperial]);

    const integerOnlyValidation = (event: any) => {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    };

    return (
        <div>
            <AppContentTypeToggle
                isOption1={isImperial}
                isOption2={!isImperial}
                onClickOption1={() => setIsImperial(true)}
                onClickOption2={() => setIsImperial(false)}
                textOption1={t('general.height.imperial')}
                textOption2={t('general.height.metric')}
            />

            {isImperial
                ?
                <div className={`${COMPONENT_CLASS}__height-input-container`} >
                    <div className={`${COMPONENT_CLASS}__height-input`} style={{ marginLeft: "1rem" }}>
                        <input className={`${COMPONENT_CLASS}__input`}
                            autoFocus
                            onChange={(e: any) => {
                                let feetStr = e.target.value;
                                let feetValue = parseInt(feetStr);
                                if (feetValue >= 12 || feetStr.length > 2) {
                                    feetStr = feetStr.substring(
                                        0,
                                        feetStr.length - 1
                                    );
                                }
                                setFeet(feetStr);
                                if (
                                    !isNaN(feetValue) &&
                                    feetValue > 0 &&
                                    inchesRef?.current
                                ) {
                                    inchesRef.current.setFocus();
                                }
                            }}
                            type="number"
                            value={feet}
                            maxLength={2}
                            min="0"
                            onKeyPress={(e: any) => integerOnlyValidation(e)}>

                        </input>
                    </div>
                    <span className={`${COMPONENT_CLASS}__height-span`}>{t('general.height.ft')}</span>
                    <div className={`${COMPONENT_CLASS}__height-input`} style={{ marginLeft: "1rem" }}>
                        <input className={`${COMPONENT_CLASS}__input`}
                            onChange={(e: any) => {
                                let inchesStr = e.target.value;
                                let inchesValue = parseInt(inchesStr);
                                if (inchesValue >= 12 || inchesStr.length > 2) {
                                    inchesStr = inchesStr.substring(
                                        0,
                                        inchesStr.length - 1
                                    );
                                }
                                setInches(inchesStr);
                            }}
                            type="number"
                            value={inches}
                            maxLength={2}
                            min="0"
                            onKeyPress={(e: any) => integerOnlyValidation(e)}>

                        </input>
                    </div>
                    <span className={`${COMPONENT_CLASS}__height-span`}>{t('general.height.in')}</span>
                </div>
                :
                <div className={`${COMPONENT_CLASS}__height-input-container`} >
                    <div className={`${COMPONENT_CLASS}__height-input`} style={{ marginLeft: "1rem" }}>
                        <input className={`${COMPONENT_CLASS}__input`}
                            autoFocus
                            onChange={(e: any) => {
                                let cmStr = e.target.value;
                                let cmValue = parseInt(cmStr);

                                if (cmValue >= 300 || cmStr.length > 3) {
                                    cmStr = cmStr.substring(
                                        0,
                                        cmStr.length - 1
                                    );
                                }

                                setCm(cmStr);
                            }}
                            type="number"
                            value={cm}
                            maxLength={3}
                            min="0"
                            onKeyPress={(e: any) => integerOnlyValidation(e)}>
                        </input>
                    </div>
                    <span className={`${COMPONENT_CLASS}__height-span`}>{t('general.height.cm')}</span>
                </div>
            }
        </div>
    );
};

export default HeightInputOption;
