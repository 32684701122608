import React from "react";
import { UserStatus } from "../../models/enumerations/user-status";
import { useTranslation } from "react-i18next";

const COMPONENT_CLASS = "c-user-status-badge";

interface UsersBadgeProps {
    status?: UserStatus,
}

/**
 * A small badge representing the status of an organization.
 */
export const UserStatusBadge: React.FC<UsersBadgeProps> = (props) => {
    const {
        status,
    } = props;

    const { t } = useTranslation();

    const activeBadge = (
        <span
            className={`${COMPONENT_CLASS}__status-badge active`}
        >
            {t('users.active')}
        </span>
    );

    const inactiveBadge = (
        <span
            className={`${COMPONENT_CLASS}__status-badge inactive`}
        >
            {t('users.inactive')}
        </span>
    );

    const disabledBadge = (
        <span
            className={`${COMPONENT_CLASS}__status-badge deactivated`}
        >
            {t('users.deactivated')}
        </span>
    );

    if (status === UserStatus.Active) {
        return activeBadge;
    }

    if (status === UserStatus.Inactive) {
        return inactiveBadge;
    }

    if (status === UserStatus.Disabled) {
        return disabledBadge;
    }

    return null;
};
