import { Button } from "../button/button";
import { useState } from "react";
import {
    CheckCircleIcon,
    ClipboardIcon,
} from '@heroicons/react/outline';
import { Modal } from "../modal/modal";
import '../deeplinks-list/deeplink-search-hits.styles.scss';
import { LeadLink } from "../../models/interfaces/lead-link";
import LinkInfo from "./lead-link-view";
import { ReactComponent as StatiscticIconUp } from "../../assets/icons/solid/stat-icon-up.svg";
import { ReactComponent as StatiscticIconDown } from "../../assets/icons/solid/stat-icon-down.svg";
import { useTranslation } from 'react-i18next';


interface LeadLinksSearchHitsProps {
    leadLinks: LeadLink[];
    editClickHandler: (leadLink: LeadLink) => void;
    deleteClickHandler: (leadLink: LeadLink) => void;
}

const LeadLinksSearchHits: React.FC<LeadLinksSearchHitsProps> = (props) => {

    const { leadLinks, editClickHandler, deleteClickHandler } = props;
    const [openQRModal, setOpenQRModal] = useState(false);
    const [copiedLinkId, setCopiedLinkId] = useState('');
    const [modalLeadLink, setModalLeadLink] = useState<LeadLink>();
    const COMPONENT_CLASS = "c-lead-link-view";
    const { t } = useTranslation();

    const handleQRClick = (leadLink: LeadLink) => {
        setModalLeadLink(leadLink);
        setOpenQRModal(true);
    }

    const handleLinkCopy = (leadLink: LeadLink | undefined) => {

        if (!leadLink?.url || !leadLink?.id) {
            console.error('Copy link failed. No id or URL available');
            return;
        }
        // TODO : Global exception handling
        // https://app.clickup.com/t/2219993/FMS-1236

        navigator.clipboard.writeText(leadLink.url);
        setCopiedLinkId(leadLink.id);
        setTimeout(() => {
            setCopiedLinkId('');
        }, 2000);
    }


    const stat = (mainNumber: any, mainText: any, bottomText: any, lastMonthData: any, currentMonthData: any, isPlan: boolean) => {
        let icon = <></>;
        if (lastMonthData > 0 && currentMonthData > lastMonthData)
            icon = <StatiscticIconUp />;
        else if (lastMonthData > 0 && currentMonthData < lastMonthData)
            icon = <StatiscticIconDown />;
        else
            icon = <></>;

        return (
            <div className={`${COMPONENT_CLASS}__stat_row`}>
                <div className={`${COMPONENT_CLASS}__stat`}>
                    <div style={{ display: 'flex', alignItems: 'center' }}><p className={`${COMPONENT_CLASS}__main_stat`}>{mainNumber} </p><div style={{ marginLeft: '0.5rem', marginTop: '1rem' }}>{isPlan && icon}</div></div>

                    <p style={{ fontSize: '13px', fontWeight: '700' }}>{mainText}</p>
                    <div className={`${COMPONENT_CLASS}__percentage`}>
                        <p style={{ marginRight: '0.5rem' }}>{bottomText}</p>
                        {!isPlan && icon}
                    </div>
                </div>
                <></>
                <></>
            </div>
        )
    };

    const totalPlanUsers = (modalLeadLink?.leadsTotalCount ?? 0) + (modalLeadLink?.starterTotalCount ?? 0) + (modalLeadLink?.businessTotalCount ?? 0) + (modalLeadLink?.enterpriseTotalCount ?? 0);

    const getCurrentPerformance = () => {
        const currentYear = (new Date()).getUTCFullYear();
        const currentMonthIndex = (new Date()).getUTCMonth();
        if (modalLeadLink && modalLeadLink.performance && modalLeadLink.performance[currentYear] && modalLeadLink.performance[currentYear][currentMonthIndex]) {
            return modalLeadLink.performance[currentYear][currentMonthIndex];
        }
        return { starterCount: 0, leadsCount: 0, enterpriseCount: 0, businessCount: 0, clicksCount: 0 }
    }


    const getLastPerformance = () => {
        let year = (new Date()).getUTCFullYear();
        let monthIndex = (new Date()).getUTCMonth() - 1;

        // If January return
        // currentYear - 1 = prevYear
        // currentMonthIndex = 11 (December)
        if ((new Date()).getUTCMonth() === 0) {
            year -= 1;
            monthIndex = 11;
        }

        if (modalLeadLink && modalLeadLink.performance && modalLeadLink.performance[year] && modalLeadLink.performance[year][monthIndex]) {
            return modalLeadLink.performance[year][monthIndex];
        }
        return { starterCount: 0, leadsCount: 0, enterpriseCount: 0, businessCount: 0, clicksCount: 0 }
    }

    const compareLinkConversion = () => {
        const currentTotal = getCurrentPerformance().starterCount + getCurrentPerformance().businessCount + getCurrentPerformance().enterpriseCount;
        const lastMonthTotal = getLastPerformance().starterCount + getLastPerformance().businessCount + getLastPerformance().enterpriseCount;
        return { last: lastMonthTotal, current: currentTotal }
    }

    return leadLinks.length ? (
        <>
            {leadLinks.map((leadLink, index) => {
                const className = (leadLink.status === 'active') ? '' : 'disabled';
                return (
                    <tr className={`${className} table-row-container`} onClick={() => { handleQRClick(leadLink) }} key={`deeplink_${index}`}>
                        <td>
                            <div className={'icon-container'} onClick={(event) => {
                                event.stopPropagation();
                                handleLinkCopy(leadLink);
                            }}>
                                {(copiedLinkId === leadLink.id) ?
                                    <CheckCircleIcon className="h-7 w-7 my-1.5 mx-1.5" />
                                    : <ClipboardIcon className="h-7 w-7 my-1.5 mx-1.5" />
                                }
                            </div>
                        </td>
                        <td><strong>{leadLink.name}</strong></td>
                        <td><strong className="show-on-mobile mr-1">{t('leadlinks.searchHits.leads')}:</strong>{leadLink.leadsTotalCount}</td>
                        <td><strong className="show-on-mobile mr-1">{t('leadlinks.searchHits.starter')}:</strong>{leadLink.starterTotalCount}</td>
                        <td><strong className="show-on-mobile mr-1">{t('leadlinks.searchHits.business')}:</strong>{leadLink.businessTotalCount}</td>
                        <td><strong className="show-on-mobile mr-1">{t('leadlinks.searchHits.enterprise')}:</strong>{leadLink.enterpriseTotalCount}</td>
                        <td>
                            <div className='buttons-container'>
                                <Button
                                    type="table-action"
                                    buttonText={t('buttons.btn_edit')}
                                    buttonStyle="white"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        editClickHandler(leadLink);
                                    }}
                                />
                                <Button
                                    type="table-action"
                                    buttonText={t('buttons.btn_delete')}
                                    buttonStyle="white" onClick={(event) => {
                                        event.stopPropagation();
                                        deleteClickHandler(leadLink)
                                    }} />
                            </div>
                        </td>
                    </tr>
                )
            })}
            <Modal
                isOpen={openQRModal}
                isLoading={false}
                onClose={() => { setOpenQRModal(false) }}
                title={t('subscriptionLinks.linkInformation')}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ borderBottom: '1px solid #F3F3F4' }}>
                        <LinkInfo linkData={modalLeadLink}></LinkInfo>
                    </div>
                    <div className={`${COMPONENT_CLASS}__stat_container`}>
                        <div className={`${COMPONENT_CLASS}__stat_row`}>
                            {stat(modalLeadLink?.clicksTotalCount, t('leadlinks.searchHits.clicks'), getLastPerformance().clicksCount > 0 ? `${(((getCurrentPerformance().clicksCount * 100) / getLastPerformance().clicksCount) - 100).toFixed(0)}%` : '', getLastPerformance().clicksCount, getCurrentPerformance().clicksCount, false)}
                            {stat(modalLeadLink?.leadsTotalCount, t('leadlinks.searchHits.leads'), getLastPerformance().leadsCount > 0 ? `${(((getCurrentPerformance().leadsCount * 100) / getLastPerformance().leadsCount) - 100).toFixed(0)}%` : '', getLastPerformance().leadsCount, getCurrentPerformance().leadsCount, false)}
                            {stat(totalPlanUsers > 0 ? `${((((modalLeadLink?.starterTotalCount ?? 0) + (modalLeadLink?.businessTotalCount ?? 0) + (modalLeadLink?.enterpriseTotalCount ?? 0)) * 100) / totalPlanUsers).toFixed(0)}%` : 0, t('leadlinks.searchHits.leadConversion'), compareLinkConversion().last > 0 ? `${(((compareLinkConversion().current * 100) / compareLinkConversion().last) - 100).toFixed(0)}%` : '', compareLinkConversion().last, compareLinkConversion().current, false)}
                        </div>
                        <div className={`${COMPONENT_CLASS}__stat_row`}>
                            {stat(totalPlanUsers > 0 ? `${(((modalLeadLink?.starterTotalCount ?? 0) * 100) / totalPlanUsers).toFixed(0)}%` : 0, t('leadlinks.searchHits.starterPlan'), `${(modalLeadLink?.starterTotalCount ?? 0)} ${t('leadlinks.searchHits.customers')}`, getLastPerformance().starterCount, getCurrentPerformance().starterCount, true)}
                            {stat(totalPlanUsers > 0 ? `${(((modalLeadLink?.businessTotalCount ?? 0) * 100) / totalPlanUsers).toFixed(0)}%` : 0, t('leadlinks.searchHits.businessPlan'), `${(modalLeadLink?.businessTotalCount ?? 0)} ${t('leadlinks.searchHits.customers')}`, getLastPerformance().businessCount, getCurrentPerformance().businessCount, true)}
                            {stat(totalPlanUsers > 0 ? `${(((modalLeadLink?.enterpriseTotalCount ?? 0) * 100) / totalPlanUsers).toFixed(0)}%` : 0, t('leadlinks.searchHits.enterprisePlan'), `${(modalLeadLink?.enterpriseTotalCount ?? 0)} ${t('leadlinks.searchHits.customers')}`, getLastPerformance().enterpriseCount, getCurrentPerformance().enterpriseCount, true)}
                        </div>
                    </div>
                </div>

            </Modal>
        </>
    ) : (
        <td className={`pt-6`} colSpan={8}>
            <div className="flex justify-center items-center h-full">
                <span className="text-center">
                    {t('leadlinks.noLeadCaptures')}
                </span>
            </div>
        </td>
    )
}

export default LeadLinksSearchHits;
