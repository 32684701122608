import * as d3 from "d3";
import BarChartPoint from "../models/interfaces/charts/bar-chart-point";
import LineChartPoint from "../models/interfaces/charts/line-chart-point";

interface LineChartDataPoint {
    x: Date;
    y: number;
    percentage: number;
}

function drawDonutChart(percentage: number, thickness: number = 20, fillColor: string = "rgba(18, 22, 34, 0.85)") {
    const cssClass = "c-donut-chart";
    const data: any = [{
        isPercentageValue: true,
        value: percentage,
    }, {
        isPercentageValue: false,
        value: 100 - percentage,
    }]
    const boxSize = 500;

    const node = document.createElement("div");

    // Create new svg
    const svg = d3
        .select(node)
        .append("svg")
        .attr("preserveAspectRatio", "xMidYMid meet")
        .attr("height", "100%")
        .attr("width", "100%")
        .attr("viewBox", `0 0 ${boxSize} ${boxSize}`)
        .append("g")
        .attr("transform", `translate(${boxSize / 2}, ${boxSize / 2})`);

    const defs = svg
        .append("defs");

    defs
        .append("filter")
        .attr("id", "inner-shadow")
        .attr("color-interpolation-filters", "sRGB")
        .append("feDropShadow")
        .attr("dx", 0)
        .attr("dy", 4)
        .attr("stdDeviation", 10)
        .attr("flood-opacity", "0.15")
        .attr("flood-color", "black");

    const outerShadow = defs
        .append("filter")
        .attr("id", "outer-shadow")
        .attr("x", "-150%")
        .attr("y", "-150%")
        .attr("width", "300%")
        .attr("height", "300%");

    outerShadow
        .append("feComponentTransfer")
        .attr("in", "SourceAlpha")
        .append("feFuncA")
        .attr("type", "table")
        .attr("tableValues", "1 0");

    outerShadow
        .append("feGaussianBlur")
        .attr("stdDeviation", "10");

    outerShadow
        .append("feOffset")
        .attr("dx", 4)
        .attr("dy", 4)
        .attr("result", "offsetBlur");

    outerShadow
        .append("feFlood")
        .attr("flood-opacity", "0.15")
        .attr("flood-color", "black")
        .attr("result", "color");

    outerShadow
        .append("feComposite")
        .attr("in2", "offsetBlur")
        .attr("operator", "in");

    outerShadow
        .append("feComposite")
        .attr("in2", "SourceAlpha")
        .attr("operator", "in");

    const blurMerge = outerShadow
        .append("feMerge")

    blurMerge
        .append("feMergeNode")
        .attr("in", "SourceGraphic");

    blurMerge
        .append("feMergeNode");

    const outline = svg
        .append("g");

    outline
        .append("circle")
        .attr("class", `${cssClass}__outline__outer`)
        .attr("cx", 0)
        .attr("cy", 0)
        .attr("r", 240);

    outline
        .append("circle")
        .attr("class", `${cssClass}__outline__inner`)
        .attr("cx", 0)
        .attr("cy", 0)
        .attr("r", 240 - 2 * thickness);

    const arcGenerator = d3
        .arc()
        .innerRadius(240 - 2 * thickness)
        .outerRadius(240);

    const pieGenerator = d3
        .pie()
        .sort(null)
        .value((d: any) => d.value);

    svg
        .append("g")
        .selectAll("path")
        .data(pieGenerator(data))
        .enter()
        .append("path")
        .attr("d", arcGenerator as any)
        .attr("fill", (d: any, i: any) => i === 0 ? fillColor : "none")
        .attr("class", (d: any, i: any) => i === 0 ? `${cssClass}__percent` : `${cssClass}__overall`);

    return node;
};

function drawLineChart(data: LineChartPoint[], axisFormat: string = "%-m/%-d") {
    const cssClass = "c-line-chart";
    const boxSize = [500, 250];
    const margin = { top: 25, right: 20, bottom: 25, left: 20 };
    const width = boxSize[0] - margin.left - margin.right;
    const height = boxSize[1] - margin.top - margin.bottom;

    data = data.map(d => {
        const date = new Date(d.created!);
        date.setHours(0, 0, 0, 0);
        d.created = date;
        return d;
    });

    const formattedData: LineChartDataPoint[] = data
        .filter(d => d.created && d.percentage > -1)
        .map(d => ({
            x: d.created,
            y: d.percentage,
            percentage: d.percentage,
        }));
    const xAxisValues = formattedData.map((data) => data.x);

    const x = d3.scaleTime()
        .domain([
            d3.min(data.map(d => d.created))!,
            d3.max(data.map(d => d.created))!,
        ])
        .range([10, width - 10]);

    const y = d3.scaleLinear()
        .domain([
            0,
            100
        ])
        .range([height, 0]);

    const node = document.createElement("div");

    // Create new svg
    const svg = d3
        .select(node)
        .append("svg")
        .attr("preserveAspectRatio", "xMidYMid meet")
        .attr("height", "100%")
        .attr("width", "100%")
        .attr("viewBox", `0 0 ${boxSize[0]} ${boxSize[1]}`)
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const blurFilter = svg
        .append("defs")
        .append("filter")
        .attr("id", "selected-blur")
        .attr("x", "-150%")
        .attr("y", "-150%")
        .attr("width", "300%")
        .attr("height", "300%");

    blurFilter
        .append("feGaussianBlur")
        .attr("in", "SourceGraphic")
        .attr("stdDeviation", "3");

    // Add Y Axis gridlines
    svg
        .append("g")
        .attr("class", `${cssClass}__grid`)
        .call(d3.axisLeft(y)
            .ticks(10)
            .tickSize(-width)
            .tickFormat("" as any)
        );

    // Add value line between data points
    svg
        .append("path")
        .data([formattedData])
        .attr("class", `${cssClass}__line`)
        .attr("d", d3
            .line()
            .x((d: any) => x(d.x))
            .y((d: any) => y(d.y))
            .curve(d3.curveCatmullRom) as any
        );

    // Add line from point to X axis
    svg
        .append("g")
        .selectAll("line")
        .data(formattedData)
        .enter()
        .append("line")
        .attr("id", (d, index) => `point-line-${index}`)
        .attr("class", (d, index) => `${cssClass}__point-line ${index}`)
        .attr("x1", (d) => x(d.x))
        .attr("x2", (d) => x(d.x))
        .attr("y1", (d) => y(0))
        .attr("y2", (d) => y(d.y));

    // Add glow circle when selected
    svg
        .append("g")
        .selectAll("circle")
        .data(formattedData)
        .enter()
        .append("circle")
        .attr("id", (d, index) => `data-point-${index}`)
        .attr("cx", (d) => x(d.x))
        .attr("cy", (d) => y(d.y))
        .attr("r", (d, index) => index === 0 ? 10 : 7)
        .attr("class", (d, index) => `${cssClass}__data-point-filter ${index === 0 ? "-highlight" : ""}`);

    // Add circle representing an assessment data point
    svg
        .append("g")
        .selectAll("circle")
        .data(formattedData)
        .enter()
        .append("circle")
        .attr("cx", (d) => x(d.x))
        .attr("cy", (d) => y(d.y))
        .attr("r", (d, index) => index === 0 ? 8 : 6)
        .attr("class", (d, index) => `${cssClass}__data-point ${index === 0 ? "-highlight" : ""}`);

    // Add larger invisible circle for mobile hitspace
    svg
        .append("g")
        .selectAll("circle")
        .data(formattedData)
        .enter()
        .append("circle")
        .attr("id", (d, index) => index)
        .attr("cx", (d) => x(d.x))
        .attr("cy", (d) => y(d.y) - 8)
        .attr("r", 25)
        .attr("class", `${cssClass}__data-point-hitarea`)
        .on("click", null)
        .on("click", (event, d) => {
            d3.selectAll(".-selected")
                .classed("-selected", false);

            d3.selectAll(`#data-point-${event.target.id}, #point-line-${event.target.id}, #point-tick-${event.target.id}`)
                .classed("-selected", true);
        });

    // Add text label to circle
    svg
        .append("g")
        .selectAll("text")
        .data(formattedData)
        .enter()
        .append("text")
        .attr("x", (d) => x(d.x) - 10)
        .attr("y", (d) => y(d.y) - 14)
        .attr("class", `${cssClass}__data-point__label`)
        .text((d) => d.percentage);

    // Add X Axis
    svg
        .append("g")
        .attr("class", `${cssClass}__axis`)
        .attr("transform", `translate(0,${height + 8})`)
        .call(d3.axisBottom(x)
            .tickValues(xAxisValues)
            .tickFormat(d3.timeFormat("%b") as any)
            .tickSize(0)
        );

    svg.selectAll(`.${cssClass}__axis .tick text`)
        .attr("id", (d, index) => {
            return `point-tick-${index}`;
        });

    return node;
}

function drawRiskChart(yourRisk: number, averageRisk: number) {
    // const colors = ["#05BBD2", "#2070C4", "#EB80F1", "#F5C842", "#37D400"];
    const boxSize = [500, 50];

    const node = document.createElement("div");

    /*
    High Risk:      78%+
    Moderate Risk:  29-77%
    Slight Risk:    1-28%
    Low Risk:       0%
    */

    // Create new svg
    const svg = d3
        .select(node)
        .append("svg")
        .attr("preserveAspectRatio", "xMidYMid meet")
        .attr("height", "100%")
        .attr("width", "100%")
        .attr("viewBox", `0 0 ${boxSize[0]} ${boxSize[1]}`)
        .append("g");

    const scale = d3.scaleLinear()
        .domain([0, 100])
        .range([0, boxSize[0]]);

    svg.selectAll(null)
        .data([yourRisk])
        .enter()
        .append("rect")
        .attr("x", 10)
        .attr("y", function (d, i) {
            return 10 + i * 20
        })
        .attr("height", 10)
        .attr("width", function (d) {
            return scale(scale.domain()[1])
        })
        .attr("rx", 5)
        .attr("ry", 5)
        .style("fill", "#ddd");


    return node;
}

function drawScoreBreakdown(data: BarChartPoint[], totalUsers: number) {
    const cssClass = "c-bar-chart";
    // set the dimensions and margins of the graph
    const boxSize = [800, 200];
    const margin = { top: 10, right: 0, bottom: 30, left: 0 },
        width = boxSize[0] - margin.left - margin.right,
        height = boxSize[1] - margin.top - margin.bottom;

    const node = document.createElement("div");

    // append the svg object to the body of the page
    const svg = d3
        .select(node)
        .append("svg")
        .attr("width", "100%")
        .attr("height", "100%")
        .attr("viewBox", `0 0 ${boxSize[0]} ${boxSize[1]}`)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

    // List of groups = species here = value of the first column called group -> I show them on the X axis
    const groups = d3.map(data, (d) => d.range)

    // Build axis
    const x = d3.scaleBand()
        .domain(groups)
        .range([0, width])
        .paddingOuter(0)
        .paddingInner(0.6)
    const y = d3.scaleLinear()
        .domain([
            0,
            d3.max(data.map(d => d.value))!,
        ])
        .range([height, 0]);

    // Add X axis
    svg.append("g")
        .attr("transform", `translate(0,${height + 15})`)
        .attr("class", `${cssClass}__bar__axis`)
        .call(d3.axisBottom(x).tickSize(0))
        .call((g) => g.select(".domain").remove());

    // Add grey background
    svg.append("g")
        .selectAll("g")
        // Enter in the stack data = loop key per key = group per group
        .data(data)
        .enter()
        .append("rect")
        .attr("class", `${cssClass}__bar__background`)
        .attr("x", (d) => x(d.range) as any)
        .attr("y", 0)
        .attr("rx", 20)
        .attr("ry", 20)
        .attr("height", height)
        .attr("width", x.bandwidth())
        .attr("transform", `translate(0,10)`);

    // Add text label to bar
    svg
        .append("g")
        .selectAll("text")
        .data(data)
        .enter()
        .append("text")
        .attr("x", (d) => x(d.range) as any + 13)
        .attr("y", 0)
        .attr("class", `${cssClass}__bar__label`)
        .text((d) => d.value);

    // Show the bars
    svg.append("g")
        .selectAll("g")
        // Enter in the stack data = loop key per key = group per group
        .data(data.filter((d) => d.value > 0))
        .enter()
        .append("path")
        .attr("d", (d, i) => drawRect(d, i, x, y, height))
        .attr("class", `${cssClass}__bar__value`)
        .attr("transform", `translate(0,10)`);

    return node;
}

const drawRect = (d: any, i: any, x: any, y: any, height: number) => {
    const x0 = x(d.range);
    const y0 = y(d.value);
    const x1 = x(d.range) + x.bandwidth();
    const y1 = height;
    const radius = 20;

    if (y0 <= radius) {
        return `M${x0} ${y1 - y0} L${x0} ${y1 - y0} L${x0} ${y1 - radius} Q${x0} ${y1} ${x0 + radius} ${y1} L${x1 - radius} ${y1} Q${x1} ${y1} ${x1} ${y1 - radius} L${x1} ${y0 + radius} Q${x1} ${y0} ${x1 - radius} ${y0} L${x0 + radius} ${y0} Q${x0} ${y0} ${x0} ${y0 + radius} Z`;
    }

    return `M${x0} ${y0} L${x0} ${y0} L${x0} ${y1 - radius} Q${x0} ${y1} ${x0 + radius} ${y1} L${x1 - radius} ${y1} Q${x1} ${y1} ${x1} ${y1 - radius} L${x1} ${y0} Z`;

}

export const ChartUtils = {
    drawDonutChart,
    drawLineChart,
    drawRiskChart,
    drawScoreBreakdown,
};