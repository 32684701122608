import { Redirect } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import SubscriptionLinkService from "../../utilities/services/subscription-link-service";
import AssessmentResults from "../../components/assessment-results/assessment-results";
import { useTranslation } from 'react-i18next';

export interface SubscriptionLinkResultsPageProps {

}

const COMPONENT_CLASS = "p-subscription-link-results";

const SubscriptionLinkResultsPage: React.FunctionComponent<SubscriptionLinkResultsPageProps> = () => {
    const { state } = useAuthState();
    const { t } = useTranslation();

    const [linkVideoUrl, setLinkVideoUrl] = useState<string | undefined>();

    useEffect(() => {
        const getSubscriptionLink = async () => {
            try {
                const fetchedLink = await SubscriptionLinkService.get(state.user?.subscriptionLinkId!);
                setLinkVideoUrl(fetchedLink?.videoUrl);
            } catch (err) {
                console.error("Cannot fetch the subscription link ID");
            }
        }

        getSubscriptionLink();
    }, [state.user?.subscriptionLinkId]);

    if (!state.authenticated) {
        return <Redirect to="/login" />;
    }
    if (state.user && !state.user.isAccountSetup) {
        return <Redirect to="/setup" />;
    }
    if (state.user && state.user?.leadLinkId && state.user.organizationId === process.env.REACT_APP_LEAD_CAPTURE_ORG_ID) {
        return <Redirect to="/lc-assessment-results" />
    }
    if (state.user && !state.user.subscriptionLinkId) {
        return <Redirect to="/" />
    }

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <AssessmentResults secondScreenVideoUrl={linkVideoUrl} secondScreenButtonText={t('buttons.btn_startYourProgram')} showYourWellnessProgramSectionWithoutLicense={true} yourWellnessProgramText={t('baseCard.congratsMessage_desc')} />
        </div>
    );
};

export default SubscriptionLinkResultsPage;