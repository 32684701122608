import { ListOptions } from "./interfaces/list-options";
import i18n from "../i18n";

export enum BiologicalSexValue {
    Female = "Female",
    Male = "Male",
}

export const BiologicalSexLabel = {
    [BiologicalSexValue.Female]: i18n.t('general.female'),
    [BiologicalSexValue.Male]: i18n.t('general.male'),
}

export const BiologicalSexOptions: ListOptions[] = [
    {
        value: BiologicalSexValue.Female,
        label: BiologicalSexLabel[BiologicalSexValue.Female],
    },
    {
        value: BiologicalSexValue.Male,
        label: BiologicalSexLabel[BiologicalSexValue.Male],
    },
]