import { RadioGroupItem } from "../components/radio-group/radio-group-item";
import { PaymentType } from "./enumerations/payment-type";
import i18n from "../i18n";

export const PaymentTypeOptions: RadioGroupItem[] = [
    {
        value: PaymentType.PayPerUser,
        label: i18n.t('general.paymentType.perUser'),
    },
    {
        value: PaymentType.FlatFee,
        label: i18n.t('general.paymentType.flat'),
    },
]