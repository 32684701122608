import { DateTime } from "luxon";
import { ReportType } from "../../models/enumerations/report-type";
import { BaseReportData } from "../../models/interfaces/reports/base-report-data";
import { Utils } from "../../utilities/utils";
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "c-report";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportHeaderProps {
    data: BaseReportData;
    dateFormat?: string;
    focusAreas?: string[];
    healthCategories?: string[];
    isInPain?: boolean;
    name?: string;
    dob?: string;
    reportDateOverride?: string;
    reportType: ReportType;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportHeader: React.FC<ReportHeaderProps> = (props: ReportHeaderProps) => {
    const { data } = props;
    const { t } = useTranslation();

    const dateFormat = props.dateFormat || "MMMM yyyy"
    const reportDate = DateTime.fromISO(data.reportDate);
    let reportName = ""
    switch (props.reportType) {
        case ReportType.Compliance:
            reportName = t('reports.header.compliance');
            break;
        case ReportType.Detail:
            reportName = t('reports.header.detail');
            break;
        case ReportType.Score:
            reportName = t('reports.header.score');
            break;
        case ReportType.Summary:
            reportName = t('reports.header.summary');
            break;
        case ReportType.Assessment:
            reportName = t('reports.header.assessment');
            break;
    }

    return (
        <div>
            <div className={`${COMPONENT_CLASS}__header`}>
                <h1>{t('reports.header.symmioLowerCase')}</h1>
                <div className={`${COMPONENT_CLASS}__header__type`}>
                    {reportName}
                </div>
                <div className={`${COMPONENT_CLASS}__header__wrapper`}>
                    {props.reportType === ReportType.Assessment &&
                        <div>
                            <div className={`${COMPONENT_CLASS}__header__col`}>
                                <div className={`${COMPONENT_CLASS}__header__item`}>{t('reports.header.name')} {props.name}</div>
                                <div className={`${COMPONENT_CLASS}__header__item`}>{t('reports.header.dob')} {props.dob}</div>
                                <div className={`${COMPONENT_CLASS}__header__item`}>{t('reports.header.lastAssessment')} {props.reportDateOverride}</div>
                            </div>
                        </div>
                    }
                    {props.reportType !== ReportType.Assessment &&
                        <div className={`${COMPONENT_CLASS}__header__col`}>
                            <div className={`${COMPONENT_CLASS}__header__item`}>{t('reports.header.companyName')} {data.organizationName}</div>
                            {props.reportDateOverride &&
                                <div className={`${COMPONENT_CLASS}__header__item`}>{props.reportDateOverride}</div>
                            }
                            {!props.reportDateOverride &&
                                <div className={`${COMPONENT_CLASS}__header__item`}>{t('reports.header.monthlySummary')} {reportDate.toFormat(dateFormat)}</div>
                            }
                            <div className={`${COMPONENT_CLASS}__header__item`}>{t('reports.header.totalReportUsers')} {data.totalUsers}</div>
                        </div>
                    }
                    {props.reportType !== ReportType.Assessment &&
                        <div className={`${COMPONENT_CLASS}__header__col`}>
                            <div className={`${COMPONENT_CLASS}__header__item`}>{t('reports.header.reportDate')} {DateTime.now().toFormat("MMMM d, yyyy")}</div>
                            {data.groupNames && data.groupNames.length > 0 &&
                                <div className={`${COMPONENT_CLASS}__header__item`}>{t('reports.header.groups')} {data.groupNames.join(", ")}</div>
                            }
                            {data.tagNames && data.tagNames.length > 0 &&
                                <div className={`${COMPONENT_CLASS}__header__item`}>{t('reports.header.tags')} {data.tagNames.join(", ")}</div>
                            }
                        </div>
                    }
                    {(props.reportType === ReportType.Detail || props.reportType === ReportType.Score) &&
                        <div className={`${COMPONENT_CLASS}__header__col`}>
                            {props.healthCategories && props.healthCategories.length > 0 &&
                                <div className={`${COMPONENT_CLASS}__header__item`}>{t('reports.header.mskRisk')} {Utils.getHealthRiskNames(props.healthCategories).join(", ")}</div>
                            }
                            {props.isInPain &&
                                <div className={`${COMPONENT_CLASS}__header__item`}>{t('reports.header.movementPain')} {props.isInPain ? t('general.yes') : t('general.no')}</div>
                            }
                            {props.focusAreas && props.focusAreas.length > 0 &&
                                <div className={`${COMPONENT_CLASS}__header__item`}>{t('reports.header.focus')} {props.focusAreas.join(", ")}</div>
                            }
                        </div>
                    }
                </div>
            </div>
            <hr />
        </div>
    );
}

export default ReportHeader;