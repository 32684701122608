import { CorrectiveExercise } from '../../models/interfaces/corrective-exercises/corrective-exercise';
import { Modal } from '../modal/modal';
import { ReactComponent as ClockIcon } from "../../assets/icons/outline/icon_clock.svg";
import { CorrectiveExerciseUtils } from '../../utilities/corrective-exercise-utils';
import { Utils } from '../../utilities/utils';
import { GroupIds } from '../../models/enumerations/group-ids';
import PortalProgressBar from '../portal-progress-bar/portal-progress-bar';
import { ControlBar, Player } from 'video-react';
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "c-exercise-modal";

interface ExerciseModalProps {
    exercise: CorrectiveExercise;
    openExerciseModal: boolean;
    setOpenExerciseModal: React.Dispatch<React.SetStateAction<boolean>>;
    programProgressCurrent?: number;
    programProgressTotal?: number;
}

const ExerciseModal: React.FC<ExerciseModalProps> = (props) => {
    const {
        exercise,
        openExerciseModal,
        setOpenExerciseModal,
        programProgressCurrent,
        programProgressTotal,
    } = props;

    const { t } = useTranslation();
    const duration = CorrectiveExerciseUtils.getExerciseDuration(exercise);

    return (
        <Modal
            isOpen={openExerciseModal}
            isLoading={false}
            onClose={setOpenExerciseModal}
        >
            <div className={COMPONENT_CLASS}>
                <div className='flex flex-col grow gap-4 justify-center'>
                    <div className='flex justify-between items-center gap-4'>
                        <div>
                            <h1 style={{ margin: "0" }}>
                                {t(exercise.name)}
                            </h1>
                        </div>

                        <div className={`${COMPONENT_CLASS}__focus-icon`}>
                            {Utils.getFocusAreaData(GroupIds.MOVEMENT_HEALTH).icon}
                        </div>
                    </div>

                    <div className='flex justify-between items-center gap-4'>
                        {programProgressCurrent !== undefined &&
                            programProgressCurrent !== null &&
                            programProgressTotal !== undefined &&
                            programProgressTotal !== null &&
                            <div className='w-2/3'>
                                <div className='flex justify-between items-center gap-4'>
                                    <div className='text-10 font-bold uppercase'>
                                        {t('userProfile.programProgress')}
                                    </div>
                                    <div className='text-sm font-medium'>
                                        {programProgressCurrent}/{programProgressTotal}
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <PortalProgressBar total={programProgressTotal} currentProgress={programProgressCurrent} headerText="" fillColor="#43B5F8" />
                                </div>
                            </div>
                        }

                        <div className='flex items-center gap-1 text-xs font-light'>
                            <div><ClockIcon /></div>
                            {duration === 1 ? t('userProfile.exerciseDuration.singular', { numOfMinutes: duration }) : t('userProfile.exerciseDuration.plural', { numOfMinutes: duration })}
                        </div>
                    </div>
                </div>

                <hr />

                {exercise.description &&
                    <div className='overflow-auto text-xs font-light flex gap-6 items-center' style={{ maxHeight: "50vh" }}>
                        {exercise && exercise.videos && exercise.videos[0].videoUrl &&
                            <div className='max-w-full overflow-hidden' style={{ width: "49%", minWidth: "49%", borderRadius: "10px" }}>
                                <Player aspectRatio="16:9">
                                    <source src={exercise.videos[0].videoUrl} type="video/mp4" />
                                    <source src={exercise.videos[0].videoUrl} type="video/ogg" />
                                    <ControlBar />
                                </Player>
                            </div>
                        }

                        <div className='bg-black-gray-5 p-6 self-stretch flex items-center' style={{ borderRadius: "10px", lineHeight: "18px" }}>
                            <p>{t(exercise.description)}</p>
                        </div>
                    </div>
                }
            </div>
        </Modal>
    );
};

export default ExerciseModal;