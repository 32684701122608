import React, { useState } from "react";
import Modal from "../symmio-modal/modal";
import { User } from "../../models/interfaces/user";
import Select from "react-select";
import UserRoles from "../../models/user-roles";
import OrganizationService from "../../utilities/services/organization-service";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { Organization } from "../../models/interfaces/organization";
import UserService from "../../utilities/services/user-service";
import { Button } from "../button/button";
import { useTranslation } from "react-i18next";

export interface ModalProps {
    onClose: Function,
    open: boolean,
    currentAccountHolder: User | undefined,
    newAccountHolderId: string | undefined,
    organization: Organization | undefined,
}

/**
 * Represents the modal that is shown when a login error occurs.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const ChangeAccountHolderModal: React.FC<ModalProps> = (props) => {
    const {
        onClose,
        open,
        currentAccountHolder,
        newAccountHolderId,
        organization,
    } = props;

    const { t } = useTranslation();
    const [newRole, setNewRole] = useState<any>();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const { state } = useAuthState();

    if (!currentAccountHolder || !newAccountHolderId || !organization) {
        return null;
    }

    const handleAccountHolderChange = async () => {
        // get all orgs assigned to the current account holder
        const holderOrgs = await OrganizationService.getBy([{
            field: 'accountHolderId',
            operator: '==',
            value: currentAccountHolder!.id,
        }]);

        // reassign the orgs to the new account holder
        const promises: Promise<Organization | User>[] = [];

        holderOrgs.forEach((o) => {
            o.accountHolderId = newAccountHolderId;
            promises.push(OrganizationService.update(o, state.user));
        });

        // change the current (now prev) account holder's role
        currentAccountHolder.roles = [newRole.value];
        promises.push(UserService.update(currentAccountHolder, state.user));

        setIsSaving(true);

        await Promise.all(promises)
            .then(() => {
                handleClose(true);
                setIsSaving(false);
            });
    }

    /**
     * Handles closing of the modal.
     * 
     * @param {boolean} save True if the user decided to proceed with changing
     * the account holder, false if they cancelled.
     */
    const handleClose = (save: boolean) => {
        onClose(save);
    };

    const holderName = `${currentAccountHolder!.firstName} ${currentAccountHolder!.lastName}`;
    const holderEmail = currentAccountHolder!.email;

    const title = t('users.accountHolderChange')

    const message = t('users.accountHolderChangeDescription', { orgName: organization.name, holderName: holderName, holderEmail: holderEmail });

    const handleRoleChange = (e: any) => {
        setNewRole(e);
    };

    const children = (
        <>
            <Select
                isClearable={true}
                name="role"
                classNamePrefix={"c-user-profile-header"}
                placeholder={t('users.role')}
                options={UserRoles.ADMIN_ROLES}
                onChange={handleRoleChange}
                value={newRole} />
            <div className={'c-login-modal__button-area'}>
                <Button
                    type="link"
                    onClick={() => handleClose(false)}
                    buttonText={t('buttons.btn_cancel')} />
                <div style={{ width: '4em' }} />
                <Button
                    disabled={!newRole || isSaving}
                    onClick={handleAccountHolderChange}
                    buttonText={t('buttons.btn_saveChanges')} />
            </div>
        </>
    );

    return (
        <Modal
            onClose={handleClose}
            open={open}
            children={children}
            message={message}
            title={title}
            showHeader={false}
        />
    );
};

export default ChangeAccountHolderModal;