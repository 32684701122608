import { PlanType } from "./enumerations/plan-type";
import { NumericListOptions } from "./interfaces/numeric-list-options";
import i18n from "../i18n";

export const PlanTypeOptions: NumericListOptions[] = [
    {
        value: PlanType.Starter,
        label: i18n.t('general.plan.starter'),
    }, {
        value: PlanType.Business,
        label: i18n.t('general.plan.business'),
    }, {
        value: PlanType.Enterprise,
        label: i18n.t('general.plan.enterprise'),
    },
    {
        value: PlanType.ThirdParty,
        label: i18n.t('general.plan.thirdParty'),
    },
]

export const PlanTypeLabels = {
    [PlanType.Starter]: i18n.t('general.plan.starter'),
    [PlanType.Business]: i18n.t('general.plan.business'),
    [PlanType.Enterprise]: i18n.t('general.plan.enterprise'),
    [PlanType.Trial]: i18n.t('general.plan.trial'),
    [PlanType.ThirdParty]: i18n.t('general.plan.thirdParty'),
}