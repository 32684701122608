/* eslint-disable no-useless-escape */
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "@firebase/auth";
import { enqueueSnackbar } from "notistack";
import { useAuthState } from '../../../utilities/contexts/auth-state-context';
import ErrorIcon from '@mui/icons-material/Error';
import { ReactComponent as CircleIcon } from "../../../assets/icons/outline/check-circle-icon.svg";
import { ReactComponent as CheckedIcon } from "../../../assets/icons/outline/filled-check-circle-icon.svg";
import { TextTypeInput } from '../../forms';
import { Button } from '../../button/button';
import { useTranslation } from 'react-i18next';
interface PasswordUpdateProps {
  onClose: any,
}
const COMPONENT_CLASS = "c-subscriptions";
const FORM_COMPONENT_CLASS = "p-link-create-account";

const PasswordUpdate: React.FC<PasswordUpdateProps> = (props: PasswordUpdateProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();
  const auth = getAuth();
  const { setState } = useAuthState();
  const [passwordMatch, setPasswordMatch] = useState<boolean>(true);
  const [, setSubmitting] = useState<boolean>(false);
  const [passwordValidation, setPasswordValidation] = useState({ length: false, uppercase: false, lowercase: false, number: false, special: false });
  const { t } = useTranslation();


  const passwordsMatch = (newPassword: string, confirmPassword: string) => {
    return newPassword.localeCompare(confirmPassword) === 0
  }
  function handlePasswordValidation(event: any): void {
    const value = event.target.value;
    setPasswordValidation({
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /\d/.test(value),
      special: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(value)
    });
  }
  const handleError = (error: any) => {
    if (error?.code == null) {
      setError("formErrors", {
        type: "custom",
        message: t('forms.authErrors.custom')
      });
    }

    switch (error.code) {
      case "auth/internal-error":
        setError("formErrors", {
          type: "custom",
          message: t('forms.authErrors.internal')
        });
        break;
      case "auth/wrong-password":
        setError("formErrors", {
          type: "custom",
          message: t('forms.authErrors.wrongPassword')
        });
        break;
      default:
        setError("formErrors", {
          type: "custom",
          message: t('forms.authErrors.custom')
        });
        break;
    }
  }

  const handleSignoutClick = () => {
    auth.signOut();
    setState((state) => ({ ...state, ...{ authenticated: false, user: null } }));
  }

  const onSubmit = async (data: any) => {
    if (passwordsMatch(data.newPassword, data.confirmPassword)) {
      setPasswordMatch(true);
      setSubmitting(true);
      if (!auth.currentUser || !auth.currentUser.email) return;
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        data.currentPassword
      );
      let authenticated = true;
      await reauthenticateWithCredential(auth.currentUser, credential).catch((error: any) => {
        setSubmitting(false);
        authenticated = false;
        handleError(error);
        return;
      });
      if (authenticated) {
        await updatePassword(auth.currentUser, data.newPassword)
          .then((r: any) => {
            reset();
            setSubmitting(false);
            enqueueSnackbar(t('manageAccount.passwordReset'), { variant: "toast", width: "450px", autoHideDuration: 5000 });
            props.onClose();
            handleSignoutClick();
          })
          .catch((error: any) => {
            setSubmitting(false);
            handleError(error);
          });
      }
    }
    else {
      setSubmitting(false);
      setPasswordMatch(false);
    }
  }
  return (
    <div className={`${COMPONENT_CLASS}`}>
      <div className={`${COMPONENT_CLASS}__wrapper`}>
        <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: '2rem' }}>
          {
            !passwordMatch && (
              <div className={`${COMPONENT_CLASS}__password-error`}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <ErrorIcon color="error"></ErrorIcon>
                  <h4 style={{
                    paddingTop: "3px",
                    marginLeft: "5px"
                  }}>{t('manageAccount.err_passwordMustMatch')}</h4>
                </div>
              </div>
            )
          }
          <form
            onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className={`${FORM_COMPONENT_CLASS}__field`}>
                {errors.currentPassword && (
                  <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                    {t('manageAccount.confirmPassword')}
                  </div>
                )}
                <TextTypeInput
                  id="currentPassword"
                  type='password'
                  label={t('manageAccount.currentPassword')}
                  hideLabel={true}
                  inputClassName={`c-input__input ${errors.currentPassword && `${FORM_COMPONENT_CLASS} -showerror`}`}
                  registerHook={register}
                  registerOptions={{
                    required: true,
                  }}
                  errorState={errors.currentPassword} />
              </div>
              <div className={`${FORM_COMPONENT_CLASS}__field`}>
                {errors.newPassword && (
                  <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                    {t('manageAccount.enterNewPassword')}
                  </div>
                )}
                <TextTypeInput
                  id='newPassword'
                  label={t('manageAccount.newPassword')}
                  hideLabel={true}
                  inputClassName={`c-input__input ${errors.newPassword && `${FORM_COMPONENT_CLASS} -showerror`}`}
                  type='password'
                  registerHook={register}
                  registerOptions={{
                    required: true,
                    onChange: (e) => handlePasswordValidation(e),
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\-]{8,}$/
                  }}
                  errorState={errors.newPassword} />
                <div style={{ paddingLeft: '1.5em' }}>
                  {t('forms.passwordTitle')}
                  <div className={`${FORM_COMPONENT_CLASS}__password_container`}>
                    <div style={{ width: '50%' }}>
                      <div className={`${FORM_COMPONENT_CLASS}_item`}>
                        {passwordValidation.length ? <CheckedIcon style={{ marginRight: '1.5em' }} /> : <CircleIcon style={{ marginRight: '1.5em' }} />}
                        {t('forms.8chars')}
                      </div>
                      <div className={`${FORM_COMPONENT_CLASS}_item`}>
                        {passwordValidation.lowercase ? <CheckedIcon style={{ marginRight: '1.5em' }} /> : <CircleIcon style={{ marginRight: '1.5em' }} />}
                        {t('forms.1lower')}
                      </div>
                      <div className={`${FORM_COMPONENT_CLASS}_item`}>
                        {passwordValidation.uppercase ? <CheckedIcon style={{ marginRight: '1.5em' }} /> : <CircleIcon style={{ marginRight: '1.5em' }} />}
                        {t('forms.1upper')}
                      </div>
                    </div>
                    <div style={{ width: '50%' }}>
                      <div className={`${FORM_COMPONENT_CLASS}_item`}>
                        {passwordValidation.number ? <CheckedIcon style={{ marginRight: '1.5em' }} /> : <CircleIcon style={{ marginRight: '1.5em' }} />}
                        {t('forms.1number')}
                      </div>
                      <div className={`${FORM_COMPONENT_CLASS}_item`}>
                        {passwordValidation.special ? <CheckedIcon style={{ marginRight: '1.5em' }} /> : <CircleIcon style={{ marginRight: '1.5em' }} />}
                        {t('forms.1special')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${FORM_COMPONENT_CLASS}__field`}>
                {errors.confirmPassword && (
                  <div role="alert" className={`${FORM_COMPONENT_CLASS} -error`}>
                    {t('manageAccount.err_confirmNewPassword')}
                  </div>
                )}
                <TextTypeInput
                  id='confirmPassword'
                  type='password'
                  label={t('manageAccount.confirmNewPassword')}
                  hideLabel={true}
                  inputClassName={`c-input__input ${errors.confirmPassword && `${FORM_COMPONENT_CLASS} -showerror`}`}
                  registerHook={register}
                  registerOptions={{
                    required: true,
                    //onChange: (e) => handlePasswordValidation(e),
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\-]{8,}$/
                  }}
                  errorState={errors.confirmPassword} />
              </div>
            </div>
            <div {...register("formErrors")}>
              {errors.formErrors &&
                <span className="error-style" role="alert">
                  {`${errors.formErrors.message}`}
                </span>
              }
            </div>
          </form>
          <div className={'c-login-modal__button-area'} style={{ gap: "50px" }}>
            <Button
              onClick={props.onClose}
              type='link'
              buttonText={t('buttons.btn_cancel')} />
            <Button
              buttonText={t('buttons.btn_saveChanges')}
              onClick={handleSubmit(onSubmit)} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordUpdate;
