import { useEffect, useState } from "react";
import LeadLinksSearchHits from './lead-links-search-hits';
import { Modal } from "../modal/modal";
import { useHistory } from "react-router";
import "./leadlinks-list.styles.scss";
import { enqueueSnackbar } from "notistack";
import LeadLinksService from "../../utilities/services/lead-links-service";
import { LeadLink } from "../../models/interfaces/lead-link";
import { useTranslation } from 'react-i18next';

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const LeadLinksList: React.FC = () => {
    const [openDelete, setOpenDelete] = useState(false);
    const [leadLinks, setLeadLinks] = useState<any>();
    const [linkToDelete, setLinkToDelete] = useState<LeadLink>();
    const history = useHistory();
    const { t } = useTranslation();

    const handleOpenDelete = (leadLink: LeadLink) => {
        setLinkToDelete(leadLink);
        setOpenDelete(true);
    }

    const handleDelete = async () => {
        try {
            if (!linkToDelete || !linkToDelete.id) {
                throw new Error('linkToDelete is not set');
            }
            await LeadLinksService.deleteById(linkToDelete.id);
            setOpenDelete(false);
            await LeadLinksService.getAll().then((result) => {
                setLeadLinks(result);
            });
            enqueueSnackbar(t('deeplinks.link_del_confirm'), { variant: "toast", width: "450px" });
        } catch (err) {
            setOpenDelete(false);
            console.error(err);
            // TODO : Global exception handling
            // https://app.clickup.com/t/2219993/FMS-1236
        }
    }

    useEffect(() => {
        const loadLinks = async () => {
            await LeadLinksService.getAll().then((result) => {
                setLeadLinks(result);
            });
        }

        loadLinks();
    }, []);

    return (
        <>
            <div>
                <table
                    role={"table"}
                    className={"dark list__table-container"}
                    data-testid="deeplinks-list">
                    <thead className="hide-on-mobile">
                        <tr>
                            <th></th>
                            <th>{t('leadlinks.name')}</th>
                            <th>{t('leadlinks.leads')}</th>
                            <th>{t('leadlinks.starter')}</th>
                            <th>{t('leadlinks.business')}</th>
                            <th>{t('leadlinks.enterprise')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        { // if
                            leadLinks &&
                            <LeadLinksSearchHits
                                leadLinks={leadLinks}
                                editClickHandler={(leadLink: LeadLink) => {
                                    history.push(`/lead-capture-link/${leadLink.id}`)
                                }}
                                deleteClickHandler={(leadLink) => {
                                    handleOpenDelete(leadLink);
                                }} />
                        }
                    </tbody>
                </table>
            </div>
            { // if
                !leadLinks &&

                <div className={`${'loading-spinner'} ${''} ${true ? '-active' : ''}`}>
                    <div className={`${'loading-spinner'}__content`}>
                    </div>
                    <div className={'loading-title'}>{t('leadlinks.leadLinksLoading')}</div>
                </div>

            }
            <Modal
                isOpen={openDelete}
                isLoading={false}
                onClose={setOpenDelete}
                title={t('deeplinks.delete_modal.title')}
                defaultModalActions={true}
                onSubmit={handleDelete}
                onCancel={() => setOpenDelete(false)}
                submitButtonText={t('buttons.btn_deleteLink')}
            // submitDisabled={!!(linkToDelete?.leadsTotalCount ||
            //     linkToDelete?.starterTotalCount ||
            //     linkToDelete?.businessTotalCount ||
            //     linkToDelete?.enterpriseTotalCount)}
            >
                {/* {linkToDelete?.leadsTotalCount ||
                    linkToDelete?.starterTotalCount ||
                    linkToDelete?.businessTotalCount ||
                    linkToDelete?.enterpriseTotalCount
                    ?
                    <div>
                        <p>{t('deeplinks.delete_modal.err_usedLink')}</p>
                    </div>
                    : */}
                <div>
                    <div>
                        <p>
                            {t('leadlinks.link_del_confirm')}
                        </p>
                    </div>
                </div>
                {/* } */}
            </Modal>
        </>
    );
}

export default LeadLinksList;