/* eslint-disable no-useless-escape */
import * as Yup from 'yup';
import ValidationRules from '../../../utilities/yup/validation-rules';
import { PaymentType } from '../../../models/enumerations/payment-type';
import { PlanType } from '../../../models/enumerations/plan-type';
import { Moment } from 'moment';
import { UserLimit } from '../../../models/enumerations/user-limit';
import i18n from '../../../i18n';

const PaidOrganizationValidationRules = () => {
    return Yup.object().shape({
        organizationName: Yup
            .string()
            .required(i18n.t('organization.edit.validation.orgName')),

        accountHolderEmail: Yup
            .string()
            .test({
                test: (value, context) => {
                    const editing = !!context.parent.organizationId;
                    if (!editing) {
                        return !!value;
                    }
                    return true;
                },
                message: i18n.t('organization.edit.validation.accountHolderEmail')
            })
            .test({
                test: (value, context) => {
                    const editing = !!context.parent.accountHolderName;
                    if (!editing) {
                        return !!value?.match(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/g);
                    }
                    return true;
                },
                message: i18n.t('organization.edit.validation.validEmail')
            }),

        accountHolderId: Yup
            .object(),

        accountManager: Yup
            .object()
            .nullable(),

        mySymmioYearlyPrice: Yup
            .number()
            .typeError(i18n.t('organization.edit.validation.numeric'))
            .max(9999, i18n.t('organization.edit.validation.maxNumber9999'))
            .test({
                test: (value, context) => {
                    const shouldValidate = (
                        context.parent.plan?.value === PlanType.ThirdParty
                    );
                    if (shouldValidate) {
                        return !!value;
                    }
                    return true;
                },
                message: i18n.t('organization.edit.validation.amount'),
            }),

        mySymmioMonthlyPrice: Yup
            .number()
            .typeError(i18n.t('organization.edit.validation.numeric'))
            .max(9999, i18n.t('organization.edit.validation.maxNumber9999'))
            .test({
                test: (value, context) => {
                    const shouldValidate = (
                        context.parent.plan?.value === PlanType.ThirdParty
                    );
                    if (shouldValidate) {
                        return !!value;
                    }
                    return true;
                },
                message: i18n.t('organization.edit.validation.amount'),
            }),

        subscriptionName: Yup
            .string()
            .test({
                test: (value, context) => {
                    const shouldValidate = (
                        context.parent.plan?.value === PlanType.ThirdParty
                    );
                    if (shouldValidate) {
                        return !!value;
                    }
                    return true;
                },
                message: i18n.t('organization.edit.validation.subscriptionName'),
            }),

        plan: Yup
            .object()
            .required(i18n.t('organization.edit.validation.plan')),

        paymentCycle: Yup
            .object()
            .required(i18n.t('organization.edit.validation.paymentCycle')),

        price: Yup
            .number()
            .typeError(i18n.t('organization.edit.validation.numeric'))
            .test({
                test: (value, context) => {
                    // we only want to validate this field if the plan is not
                    // enterprise (will be the case if there is no paymentType) or
                    // if the plan is enterprise and the payment type is flat fee.
                    const shouldValidate = (
                        !context.parent.paymentType
                        || (
                            context.parent.paymentType
                            && context.parent.paymentType.value === PaymentType.FlatFee
                        )
                    );

                    if (shouldValidate) {
                        return value || value === 0 ? true : false;
                    }
                    return true;
                },
                message: i18n.t('organization.edit.validation.amount'),
            }),

        pricePerUser: Yup
            .number()
            .typeError(i18n.t('organization.edit.validation.numeric'))
            .max(100, i18n.t('organization.edit.validation.maxNumber100'))
            .test({
                test: (value, context) => {
                    const shouldValidate = (
                        context.parent.plan?.value === PlanType.Enterprise
                        && context.parent.paymentType
                        && context.parent.paymentType.value === PaymentType.PayPerUser
                    );
                    if (shouldValidate) {
                        return !!value;
                    }
                    return true;
                },
                message: i18n.t('organization.edit.validation.amount'),
            }),



        mySymmioLicense: Yup
            .number()
            .typeError(i18n.t('organization.edit.validation.numeric'))
            .required(i18n.t('organization.edit.validation.amount'))
            .test({
                test: (value, context) => {
                    if (context.parent.plan?.value === PlanType.Business && (+value < 10 || +value > UserLimit.Business)) {
                        return false;
                    }
                    return true;
                },
                message: i18n.t('organization.edit.validation.numberRange10NA', { limit: UserLimit.Business }),
            }),

        availableOrganizations: Yup
            .number()
            .typeError(i18n.t('organization.edit.validation.numeric'))
            .max(9999, i18n.t('organization.edit.validation.maxNumber9999'))
            .test({
                test: (value, context) => {
                    const shouldValidate = context.parent.plan && context.parent.plan.value === PlanType.Enterprise;
                    if (shouldValidate) {
                        return !!value;
                    }
                    return true;
                },
                message: i18n.t('organization.edit.validation.amount'),
            }),


        userLimit: Yup
            .number()
            .typeError(i18n.t('organization.edit.validation.numeric'))
            .required(i18n.t('organization.edit.validation.userLimit'))
            .max(1000000, i18n.t('organization.edit.validation.maxNumber1000000')),

        startDate: ValidationRules
            .requiredDate(i18n.t('organization.edit.validation.startDate')),

        renewDate: ValidationRules
            .requiredDate(i18n.t('organization.edit.validation.renewDate'))
            .test({
                test: (value, context) => {
                    const startDate = context.parent.startDate as Moment;
                    const renewDate = value as Moment;
                    if (!startDate) {
                        return true;
                    }
                    return renewDate.isAfter(startDate);
                },
                message: i18n.t('organization.edit.validation.renewBeforeStart')
            })
    });
}

const TrialOrganizationValidationRules = () => {
    return Yup.object().shape({
        organizationName: Yup
            .string()
            .required(i18n.t('organization.edit.validation.orgName')),

        accountHolderEmail: Yup
            .string()
            .test({
                test: (value, context) => {
                    const editing = !!context.parent.accountHolderName;
                    if (!editing) {
                        return !!value;
                    }
                    return true;
                },
                message: i18n.t('organization.edit.validation.accountHolderEmail')
            })
            .test({
                test: (value, context) => {
                    const editing = !!context.parent.accountHolderName;
                    if (!editing) {
                        return !!value?.match(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/g);
                    }
                    return true;
                },
                message: i18n.t('organization.edit.validation.validEmail')
            }),
        mySymmioLicense: Yup
            .number()
            .required(i18n.t('organization.edit.validation.amount'))
            .typeError(i18n.t('organization.edit.validation.numeric'))
            .max(1000000, i18n.t('organization.edit.validation.maxNumber1000000'))
            .test({
                test: (value, context) => {
                    if (+value > 1000000 || +value < 1) {
                        return false;
                    }
                    return true;
                },
                message: i18n.t('organization.edit.validation.numberRange1and1000000'),
            }),
        accountHolderId: Yup
            .object()
            .nullable(),

        accountManager: Yup
            .object()
            .nullable(),

        userLimit: Yup
            .number()
            .typeError(i18n.t('organization.edit.validation.numeric'))
            .required(i18n.t('organization.edit.validation.userLimit'))
            .max(1000000, i18n.t('organization.edit.validation.maxNumber1000000')),

        availableOrganizations: Yup
            .number()
            .typeError(i18n.t('organization.edit.validation.numeric'))
            .required(i18n.t('organization.edit.validation.amount'))
            .max(9999, i18n.t('organization.edit.validation.maxNumber9999')),

        trialLength: Yup
            .object()
            .required(i18n.t('organization.edit.validation.trialLength')),

        customTrialLength: Yup
            .number()
            .typeError(i18n.t('organization.edit.validation.numeric'))
            .max(365, i18n.t('organization.edit.validation.maxNumber365'))
            .test({
                test: (value, context) => {
                    const custom = context.parent.trialLength && context.parent.trialLength.value === 0;
                    if (custom) {
                        return !!value;
                    }
                    return true;
                },
                message: i18n.t('organization.edit.validation.customTrialLength'),
            }),
    });
}

const EditOrganizationValidationRules = {
    PaidOrganizationValidationRules,
    TrialOrganizationValidationRules,
}

export default EditOrganizationValidationRules;