import React from "react";
import { Button } from "../button/button";
import { useTranslation } from 'react-i18next';


export interface ModalActionsProps {
    submitDisabled?: boolean;
    onSubmit?: Function;
    onCancel?: Function;
    submitButtonText?: string;
    cancelButtonText?: string;
    center?: boolean;
    end?: boolean;
    start?: boolean;
}

/**
 * Represents the modal that is shown when a Inactivity error occurs.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const ModalActions: React.FC<ModalActionsProps> = (props) => {

    const { t } = useTranslation();

    return (
        <div className={`flex ${props.center ? "justify-center" : `${props.end ? "justify-end" : "justify-start"}`}`} style={{ width: "100%", gap: "40px" }} >

            {
                props.onCancel &&
                <Button
                    type="link"
                    buttonText={props.cancelButtonText ? props.cancelButtonText : t('buttons.btn_cancel')}
                    onClick={() => {
                        props.onCancel!()
                    }}
                />
            }

            {
                props.onSubmit &&
                <Button
                    onClick={() => {
                        props.onSubmit!()
                    }}
                    disabled={props.submitDisabled}
                    buttonType="submit"
                    inputClassName={'action-button'}
                    inputStyle={{ marginTop: "0px" }}
                    buttonText={props.submitButtonText ? props.submitButtonText : t('buttons.btn_continue')}
                />

            }
        </div >
    );
};

export default ModalActions;