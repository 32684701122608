/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FormikTextField } from "../../formik-fields/formik-text-field";
import { FormikRadioGroup } from "../../formik-fields/formik-radio-group";
import { PlanType } from "../../../models/enumerations/plan-type";
import { RadioGroupItem } from "../../radio-group/radio-group-item";
import { useFormikContext } from "formik";
import { PaymentCycle } from "../../../models/enumerations/payment-cycle";
import { PaymentCycleOptions, PaymentCycleEnterpriseOptions } from "../../../models/payment-cycle";
import { PaymentTypeOptions } from "../../../models/payment-type";
import { PlanTypeOptions } from '../../../models/plan-type';
import moment from "moment";
import { PaymentType } from "../../../models/enumerations/payment-type";
import { Grid } from "@mui/material";
import { FormikSelectField } from "../../formik-fields/formik-select-field";
import { NumericListOptions } from "../../../models/interfaces/numeric-list-options";
import { FormikDateField } from "../../formik-fields/formik-date-field";
import { OrganizationType } from "../../../models/enumerations/organization-type";
import { LicenseLimit } from "../../../models/enumerations/license-limit";
import { UserLimit } from "../../../models/enumerations/user-limit";
import { useTranslation } from 'react-i18next';

interface UnboundTextFieldProps {
    accountType: RadioGroupItem,
    loading: boolean,
    editing: boolean,
    startDateCallback: Function,
}

/**
 * The form fields that are used for starter and business plans.
 */
export const PlanFields: React.FC<UnboundTextFieldProps> = (props) => {
    const {
        accountType,
        loading,
        editing,
        startDateCallback,
    } = props;

    const formik = useFormikContext<any>();
    const { t } = useTranslation();
    const {
        setFieldValue,
        values,
        validateField,
    } = formik;

    const [planType, setPlanType] = useState<NumericListOptions>();
    const [paymentCycle, setPaymentCycle] = useState<PaymentCycle>();
    const [paymentType, setPaymentType] = useState<RadioGroupItem>(PaymentTypeOptions[0]);
    const [userLimitNonEnterprise, setUserLimitNonEnterprise] = useState<Number>(0);
    const [priceNonEnterprise, setPriceNonEnterprise] = useState<Number>(0);
    const [lockLicense, setLockLicense] = useState<boolean>(true);

    useEffect(() => {
        if (paymentType !== values['paymentType']) {
            setPaymentType(values['paymentType'])
        }
    }, [values['paymentType']])

    useEffect(() => {
        const setFields = async () => {
            setPlanType(values['plan']);
            if (!values['paymentCycle']) {
                setFieldValue('paymentCycle', PaymentCycleOptions[0]);
                setPaymentCycle(PaymentCycleOptions[0].value);
                await setFieldValue('renewDate', moment().add(1, 'month'));
                validateField('renewDate');
            }
        }
        setFields();
    }, [values['plan']]);

    useEffect(() => {
        const setFields = async () => {
            if (planType !== null) {
                if (planType?.value === PlanType.Starter) {
                    await setFieldValue('userLimit', UserLimit.Starter);
                    setUserLimitNonEnterprise(UserLimit.Starter);
                    validateField('userLimit');
                    setLockLicense(true);
                    await setFieldValue('mySymmioLicense', LicenseLimit.Starter);
                    if (paymentCycle === PaymentCycle.Monthly) {
                        setPriceNonEnterprise(0);
                    }
                    if (paymentCycle === PaymentCycle.Yearly) {
                        setPriceNonEnterprise(0);
                    }
                    if (paymentCycle === null) {
                        await setFieldValue('paymentCycle', PaymentCycleOptions[PaymentCycle.Monthly], false);
                        setPaymentCycle(PaymentCycleOptions[PaymentCycle.Monthly].value);
                        setPriceNonEnterprise(0);
                    }
                }
                if (planType?.value === PlanType.Enterprise) {
                    setLockLicense(false);
                    if (paymentType?.value === PaymentType.FlatFee && !values?.price) {
                        setFieldValue('price', 1500);
                        setPriceNonEnterprise(1500);
                    }
                }

                if (planType?.value === PlanType.Business) {
                    setLockLicense(true);
                    await setFieldValue('mySymmioLicense', LicenseLimit.Business);
                    await setFieldValue('userLimit', UserLimit.Business);
                    setUserLimitNonEnterprise(UserLimit.Business);
                    validateField('userLimit');
                    if (paymentCycle === PaymentCycle.Monthly) {
                        setFieldValue('price', 75);
                        setPriceNonEnterprise(75);
                    }
                    if (paymentCycle === PaymentCycle.Yearly) {
                        setFieldValue('price', 765);
                        setPriceNonEnterprise(765);
                    }
                    if (paymentCycle === null) {
                        await setFieldValue('paymentCycle', PaymentCycleOptions[PaymentCycle.Monthly], false);
                        setPaymentCycle(PaymentCycleOptions[PaymentCycle.Monthly].value);
                        setFieldValue('price', 75);
                        setPriceNonEnterprise(75);
                    }
                }

                if (planType?.value === PlanType.Enterprise && !values.userLimit) {
                    await setFieldValue('userLimit', 250);
                    validateField('userLimit');
                    setUserLimitNonEnterprise(250);
                }

                if (planType?.value === PlanType.ThirdParty) {
                    await setFieldValue('userLimit', UserLimit.ThirdParty);
                    setUserLimitNonEnterprise(UserLimit.ThirdParty);
                    validateField('userLimit');
                    setLockLicense(true);
                    await setFieldValue('mySymmioLicense', LicenseLimit.ThirdParty);
                }
            }
        }

        setFields();
    }, [planType]);

    useEffect(() => {
        const setFields = async () => {
            if (!loading && !!planType) {
                if (values['startDate'] === undefined) {
                    setFieldValue('startDate', moment());
                }

                let renewDate = moment();
                // if creating a new organization, the user can manually input the
                // start date. In this case, we want to use the start date to
                // determine the renew date. If they haven't input a start date yet,
                // use the current date.
                if (!values['organizationId']) {
                    renewDate = values['startDate'] !== undefined ? moment(values['startDate']) : moment()
                }

                if (paymentCycle === null || paymentCycle === PaymentCycle.Monthly) {
                    renewDate.add(1, 'month');
                }

                if (paymentCycle === PaymentCycle.Quarterly) {
                    renewDate.add(3, 'month');
                }

                if (paymentCycle === PaymentCycle.Yearly) {
                    renewDate.add(1, 'year');
                }

                if (renewDate !== null) {
                    await setFieldValue('renewDate', renewDate);
                    validateField('renewDate');
                }
            }
        }
        setFields();
    }, [values['startDate']])


    useEffect(() => {
        if (planType?.value === PlanType.Enterprise) {
            if (paymentType.value === PaymentTypeOptions[0].value && !values.pricePerUser) {
                setFieldValue('pricePerUser', 3);
            }
            if (paymentType.value === PaymentTypeOptions[1].value && !values.price) {
                setFieldValue('price', 1500);
                setPriceNonEnterprise(1500);
            }
        }
    }, [paymentType]);

    useEffect(() => {
        if (editing && values['startDate']) {
            startDateCallback(values['startDate']);
        }
    }, [values['startDate']]);

    const handlePlanTypeChange = (option: NumericListOptions) => {
        // if we're switching plan types and the payment cycle is quarterly, we
        // need to change it to monthly because quarterly is only available for
        // enterprise plans
        if (values['paymentCycle'] === PaymentCycleEnterpriseOptions[1]) {
            setFieldValue('paymentCycle', PaymentCycleOptions[0])
            setPaymentCycle(PaymentCycle.Monthly);
        }
        setPlanType(option);
    }

    const handlePaymentTypeChange = (type: RadioGroupItem) => {
        setPaymentType(type)
    }

    return (
        <div>
            <>
                {
                    accountType.value !== OrganizationType.Trial
                        ? (
                            <>
                                <Grid container rowSpacing={4} columnSpacing={3}>
                                    <Grid item sm={12} md={planType?.value !== PlanType.ThirdParty ? 6 : 12}>
                                        <FormikSelectField
                                            label={t('organization.edit.fields.plan')}
                                            name="plan"
                                            items={PlanTypeOptions}
                                            includeNone
                                            onChange={handlePlanTypeChange}
                                            disabled={loading}
                                            required
                                        />
                                    </Grid>
                                    {
                                        (planType?.value !== PlanType.Starter && planType?.value !== PlanType.ThirdParty) &&
                                        <Grid item sm={12} md={6}>
                                            <FormikSelectField
                                                label={t('organization.edit.fields.paymentCycle')}
                                                name="paymentCycle"
                                                items={planType?.value === PlanType.Enterprise ? PaymentCycleEnterpriseOptions : PaymentCycleOptions}
                                                includeNone
                                                disabled={loading}
                                                onChange={(o: NumericListOptions) => setPaymentCycle(o.value)}
                                                required
                                            />
                                        </Grid>
                                    }
                                    {
                                        planType?.value === PlanType.Starter &&
                                        <Grid item sm={12} md={6}>

                                        </Grid>
                                    }
                                    {
                                        (planType?.value === PlanType.Enterprise) &&
                                        <Grid item xs={12}>
                                            <FormikRadioGroup
                                                label={t('organization.edit.fields.paymentModel')}
                                                name="paymentType"
                                                items={PaymentTypeOptions}
                                                value={values['paymentType'] ?? paymentType ?? PaymentTypeOptions[0]}
                                                onChange={handlePaymentTypeChange}
                                            />
                                        </Grid>
                                    }
                                    {
                                        (planType?.value !== PlanType.ThirdParty) && (
                                            <Grid item sm={12} md={6}>
                                                <FormikTextField
                                                    name="userLimit"
                                                    label={t('organization.edit.fields.userLimit')}
                                                    disabled={loading}
                                                    readOnly={planType?.value !== PlanType.Enterprise}
                                                    hidden={planType?.value !== PlanType.Enterprise}
                                                    required
                                                />
                                                {
                                                    (planType?.value !== PlanType.Enterprise) &&
                                                    <div className="non-enterprise-field">
                                                        <label>{t('organization.edit.fields.userLimit')}</label>
                                                        {userLimitNonEnterprise.toString()}
                                                    </div>
                                                }
                                            </Grid>
                                        )

                                    }
                                    {(planType?.value !== PlanType.ThirdParty) && (
                                        <Grid item sm={12} md={6}>
                                            {
                                                (planType?.value === PlanType.Enterprise && paymentType?.value === PaymentType.PayPerUser)
                                                    ? (
                                                        <FormikTextField
                                                            name="pricePerUser"
                                                            label={t('organization.edit.fields.pricePerUser')}
                                                            disabled={loading}
                                                            startAdornment="$"
                                                            required
                                                        />
                                                    ) :
                                                    (
                                                        <>
                                                            <FormikTextField
                                                                name="price"
                                                                label={t('organization.edit.fields.price')}
                                                                startAdornment="$"
                                                                disabled={loading}
                                                                readOnly={planType?.value !== PlanType.Enterprise}
                                                                hidden={planType?.value !== PlanType.Enterprise}
                                                                required
                                                            />
                                                            {
                                                                (planType?.value !== PlanType.Enterprise) &&
                                                                <div className="non-enterprise-field">
                                                                    <label>{t('organization.edit.fields.price')}</label>
                                                                    {"$" + priceNonEnterprise.toString()}
                                                                </div>
                                                            }
                                                        </>
                                                    )
                                            }
                                        </Grid>
                                    )}
                                    {planType?.value === PlanType.ThirdParty && (
                                        <Grid item sm={12} md={12}>
                                            <FormikTextField
                                                name="subscriptionName"
                                                label={t('organization.edit.fields.subscriptionName')}
                                                required
                                                disabled={loading}
                                            />
                                        </Grid>
                                    )

                                    }
                                    {planType?.value === PlanType.ThirdParty && (
                                        <Grid item sm={12} md={6}>
                                            <FormikTextField
                                                name="mySymmioYearlyPrice"
                                                label={t('organization.edit.fields.yearlyPrice')}
                                                required
                                                disabled={loading}
                                            />
                                        </Grid>
                                    )}
                                    {planType?.value === PlanType.ThirdParty && (
                                        <Grid item sm={12} md={6}>
                                            <FormikTextField
                                                name="mySymmioMonthlyPrice"
                                                label={t('organization.edit.fields.monthlyPrice')}
                                                required
                                                disabled={loading}
                                            />
                                        </Grid>
                                    )}
                                    {planType?.value !== PlanType.ThirdParty && (
                                        <Grid item sm={12} md={6}>
                                            <FormikTextField
                                                name="mySymmioLicense"
                                                label={t('organization.edit.fields.symmioLicense')}
                                                required
                                                disabled={loading || lockLicense}
                                            />
                                        </Grid>
                                    )

                                    }
                                    {
                                        (planType?.value === PlanType.Enterprise) &&
                                        <>
                                            <Grid item sm={12} md={6}>
                                                <FormikTextField
                                                    name="availableOrganizations"
                                                    label={t('organization.edit.fields.organizationsAvailable')}
                                                    required
                                                    disabled={loading}
                                                />
                                            </Grid>
                                        </>
                                    }

                                </Grid>
                            </>

                        )
                        : (
                            <>
                                <Grid container rowSpacing={4} columnSpacing={3}>
                                    <Grid item sm={12} md={6}>
                                        <FormikTextField
                                            name="userLimit"
                                            label={t('organization.edit.fields.userLimit')}
                                            disabled={loading}
                                            required
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <FormikTextField
                                            name="availableOrganizations"
                                            label={t('organization.edit.fields.availableOrganizations')}
                                            disabled={loading}
                                            required
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <FormikDateField
                                            name="renewDate"
                                            label={t('organization.edit.fields.expiryDate')}
                                            minDate={values['startDate']}
                                            disabled={loading}
                                            readOnly={accountType.value === OrganizationType.Trial}
                                            required={accountType.value !== OrganizationType.Trial}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <FormikTextField
                                            name="mySymmioLicense"
                                            label={t('organization.edit.fields.programs')}
                                            required
                                            disabled={loading}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )
                }
            </>
        </div>
    );
};
