import { useHistory, Redirect } from "react-router-dom";
import BaseCardLayout from "../../components/base-card-layout/base-card-layout";
import { Button } from '../../components/button/button';
import logoImage from "../../assets/images/symmio-logos/symmio-logo-text-white-side.png"
import { useEffect, useState } from "react";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { AssessmentsUserMenu } from "../../components/assessments-user-menu/assessments-user-menu";
import LeadLinksService from "../../utilities/services/lead-links-service";
import { LeadLink } from "../../models/interfaces/lead-link";
import { useTranslation } from 'react-i18next';
import { MskScore } from "../../models/interfaces/msk-score";
import MskScoreService from "../../utilities/services/msk-score-service";
import { LanguageSelectorUserMenu } from "../../components/language-selector-user-menu/language-selector-user-menu";
import FaqAccordion from "../../components/faq-accordion/faq-accordion";
import StarIcon from '@mui/icons-material/Star';
import AssessmentResultsHeader from "../../components/assessment-results/assessment-results-header";
import { AssessmentUtils } from "../../utilities/assessments/assessment-utils";
import AssessmentResponse from "../../models/interfaces/assessment-response.entity";
import { Utils } from "../../utilities/utils";
import RiskFactorUtil from "../../utilities/risk-factor-util";
import nflImg from "../../assets/images/fms-clients/nfl.png"
import premierLeagueImg from "../../assets/images/fms-clients/premier-league.png"
import equinoxImg from "../../assets/images/fms-clients/equinox.png"
import cardinalGlassImg from "../../assets/images/fms-clients/cardinal-glass.png"
import mlbImg from "../../assets/images/fms-clients/major-baseball-league.png"
import usArmyImg from "../../assets/images/fms-clients/us-army.png"
import cardImg0 from "../../assets/images/lead-capture/img-0.png"
import cardImg1 from "../../assets/images/lead-capture/img-1.png"
import cardImg2 from "../../assets/images/lead-capture/img-2.png"
import cardImg3 from "../../assets/images/lead-capture/img-3.png"
import cardImg4 from "../../assets/images/lead-capture/img-4.png"
import cardImg5 from "../../assets/images/lead-capture/img-5.png"
import donU from "../../assets/images/lead-capture/don-u.png"
import davidD from "../../assets/images/lead-capture/david-d.png";
import lucasM from "../../assets/images/lead-capture/lucas-m.png";
import jimmyM from "../../assets/images/lead-capture/jimmy-m.png";
import mikeE from "../../assets/images/lead-capture/mike-e.png";
import jeffL from "../../assets/images/lead-capture/jeff-l.png";
import cynthiaF from "../../assets/images/lead-capture/cynthia-f.png";
import bradF from "../../assets/images/lead-capture/brad-f.png";
import samB from "../../assets/images/lead-capture/sam-b.png";
import { ReactComponent as BehavioralHealthIcon } from "../../assets/icons/solid/behavioral-health.svg";
import { ReactComponent as MovementIcon } from "../../assets/icons/outline/movement.svg";
import { ReactComponent as PhysicalActivityIcon } from "../../assets/icons/solid/activity-level.svg";
import { ReactComponent as BodyCompIcon } from "../../assets/icons/solid/body-comp.svg";
import { ReactComponent as BreathingIcon } from "../../assets/icons/solid/breathing-qual.svg";
import { ReactComponent as InjuryHistoryIcon } from "../../assets/icons/solid/injury-history.svg";
import { ReactComponent as NutritionIcon } from "../../assets/icons/solid/nutrition-aware.svg";
import { ReactComponent as SleepIcon } from "../../assets/icons/solid/sleep-well.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/solid/check.svg";
import videoReplacementImg from "../../assets/images/results-page-video-replacement.png"
import AppContentUtil from "../../utilities/app-content-util";

const LEAD_CAPTURE_LINK_TYPE = "leadCapture";

export interface LeadCaptureResultsPageProps {

}

const COMPONENT_CLASS = "c-assessment-results";

const LeadCaptureResultsPage: React.FunctionComponent<LeadCaptureResultsPageProps> = () => {
    const { state } = useAuthState();
    const history = useHistory();
    const { t } = useTranslation();

    const [leadLink, setLeadLink] = useState<LeadLink | null>();
    const [mskScore, setMskScore] = useState<MskScore>();
    const [riskChartPercentage, setRiskChartPercentage] = useState(0);
    const [latestAssessmentResponses, setLatestAssessmentResponses] = useState<AssessmentResponse[]>();
    const [lastAssessment, setLastAssessment] = useState<string>(t('misc.notAvailable'));
    const [lastAssessmentDateInDays, setLastAssessmentDateInDays] = useState<string>("");

    const cards = [
        {
            title: t('pages.leadCaptureResults.cards.title_0'),
            description: t('pages.leadCaptureResults.cards.desc_0'),
            img: cardImg0,
        },
        {
            title: t('pages.leadCaptureResults.cards.title_1'),
            description: t('pages.leadCaptureResults.cards.desc_1'),
            img: cardImg1,
        },
        {
            title: t('pages.leadCaptureResults.cards.title_2'),
            description: t('pages.leadCaptureResults.cards.desc_2'),
            img: cardImg2,
        },
        {
            title: t('pages.leadCaptureResults.cards.title_3'),
            description: t('pages.leadCaptureResults.cards.desc_3'),
            img: cardImg3,
        },
        {
            title: t('pages.leadCaptureResults.cards.title_4'),
            description: t('pages.leadCaptureResults.cards.desc_4'),
            img: cardImg4,
        },
        {
            title: t('pages.leadCaptureResults.cards.title_5'),
            description: t('pages.leadCaptureResults.cards.desc_5'),
            img: cardImg5,
        },
    ];

    const reviews = [
        {
            details: t('assessmentResults.reviews.review0_details'),
            author: t('assessmentResults.reviews.review0_author'),
            profession: t('pages.leadCaptureResults.reviews.clientUser'),
            img: donU,
        },
        {
            details: t('pages.leadCaptureResults.reviews.review_1_details'),
            author: t('pages.leadCaptureResults.reviews.review_1_author'),
            profession: t('pages.leadCaptureResults.reviews.personalTrainer'),
            img: samB
        },
        {
            details: t('pages.leadCaptureResults.reviews.review_6_details'),
            author: t('pages.leadCaptureResults.reviews.review_6_author'),
            profession: t('pages.leadCaptureResults.reviews.strengthCoach'),
            img: jimmyM
        },
        {
            details: t('pages.leadCaptureResults.reviews.review_3_details'),
            author: t('pages.leadCaptureResults.reviews.review_3_author'),
            profession: t('pages.leadCaptureResults.reviews.physicalTherapist'),
            img: bradF
        },
        {
            details: t('pages.leadCaptureResults.reviews.review_4_details'),
            author: t('pages.leadCaptureResults.reviews.review_4_author'),
            profession: t('pages.leadCaptureResults.reviews.personalTrainer'),
            img: cynthiaF
        },
        {
            details: t('pages.leadCaptureResults.reviews.review_8_details'),
            author: t('pages.leadCaptureResults.reviews.review_8_author'),
            profession: t('pages.leadCaptureResults.reviews.medGym'),
            img: davidD
        },
        {
            details: t('pages.leadCaptureResults.reviews.review_7_details'),
            author: t('pages.leadCaptureResults.reviews.review_7_author'),
            profession: t('pages.leadCaptureResults.reviews.conditioning'),
            img: lucasM
        },
        {
            details: t('pages.leadCaptureResults.reviews.review_2_details'),
            author: t('pages.leadCaptureResults.reviews.review_2_author'),
            profession: t('pages.leadCaptureResults.reviews.virginia'),
            img: jeffL
        },
        {
            details: t('pages.leadCaptureResults.reviews.review_5_details'),
            author: t('pages.leadCaptureResults.reviews.review_5_author'),
            profession: t('pages.leadCaptureResults.reviews.physicalTherapist'),
            img: mikeE
        },
    ];

    const [faqs, setFaqs] = useState([
        {
            question: t('pages.leadCaptureResults.faq.question_0'),
            answer: t('pages.leadCaptureResults.faq.answer_0'),
            isOpen: false
        },
        {
            question: t('pages.leadCaptureResults.faq.question_1'),
            answer: t('pages.leadCaptureResults.faq.answer_1'),
            isOpen: false
        },
        {
            question: t('pages.leadCaptureResults.faq.question_2'),
            answer: t('pages.leadCaptureResults.faq.answer_2'),
            isOpen: false
        },
        {
            question: t('pages.leadCaptureResults.faq.question_3'),
            answer: t('pages.leadCaptureResults.faq.answer_3'),
            isOpen: false
        },
    ]);

    useEffect(() => {
        const getLeadCaptureLink = async () => {
            let fetchedLink: LeadLink | null = null;

            try {
                fetchedLink = await LeadLinksService.get(state.user?.leadLinkId!);
                setLeadLink(fetchedLink);
            } catch (err) {
                console.error("Cannot fetch the lead link ID");
            }
        }

        getLeadCaptureLink();
    }, [state.user?.leadLinkId]);

    useEffect(() => {
        const getMskScore = async () => {
            try {
                if (state.user && state.user.id) {
                    const fetchedScore = await MskScoreService.getBy(
                        [
                            {
                                field: "userId",
                                operator: "==",
                                value: state.user.id,
                            },
                        ],
                        [
                            {
                                field: "created",
                                direction: "desc",
                            },
                        ],
                    )

                    if (fetchedScore && fetchedScore.length > 0) {
                        setMskScore(fetchedScore[0]);
                        setRiskChartPercentage(RiskFactorUtil.convertRiskFactorToDisplayPercent(fetchedScore[0].riskFactor?.percentage) || 0);

                        if (fetchedScore[0]?.created) {
                            setLastAssessment(Utils.formatDate(fetchedScore[0].created.toDate()));
                            setLastAssessmentDateInDays(Utils.getHowManyDaysAgo(fetchedScore[0].created.toDate()));
                        }
                    }
                }
            } catch (err) {
                console.error("Cannot fetch the Wellness Score");
            }
        }

        if (!mskScore) {
            getMskScore();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user, state.user?.id]);

    useEffect(() => {
        const getAssessmentResponses = async () => {
            if (state.user?.id) {
                const assessmentResponses = await AssessmentUtils.fetchAssessmentResponsesById(state.user?.id);
                assessmentResponses && assessmentResponses.length > 0 ? setLatestAssessmentResponses(assessmentResponses) : setLatestAssessmentResponses(undefined);
            }
        }

        getAssessmentResponses();
    }, [state.user?.id])

    const joinNowButton = (isBlue: boolean = false, buttonText: string) => (
        <Button
            type="default"
            buttonText={buttonText}
            buttonStyle={isBlue ? "blue" : undefined}
            onClick={() => {
                if (state.user?.npeTemp !== null)
                    history.push(`/lc-pricing/${state.user?.leadLinkId}/${LEAD_CAPTURE_LINK_TYPE}/${state.user?.id}/npeLead`);
                else
                    history.push(`/lc-pricing/${state.user?.leadLinkId}/${LEAD_CAPTURE_LINK_TYPE}/${state.user?.id}`)
            }
            } />
    );

    if (!state.authenticated) {
        return <Redirect to="/login" />;
    }

    if (!state.user || !state.user?.leadLinkId || state.user.organizationId !== process.env.REACT_APP_LEAD_CAPTURE_ORG_ID) {
        return <Redirect push to="/" />
    }

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <div className="user-menu">
                <AssessmentsUserMenu profileImage={false} />

                <LanguageSelectorUserMenu userPortal={true} />
            </div>
            <BaseCardLayout>
                <div className="w-full pt-20">
                    <h1 className="text-center">{t('misc.hi')} {state.user?.firstName ?? "..."}</h1>
                    <p className="text-center">
                        {t('pages.leadCaptureResults.intro')}
                    </p>

                    <div className="px-4 md:px-16">
                        <AssessmentResultsHeader
                            mskScore={mskScore}
                            latestAssessmentResponses={latestAssessmentResponses}
                            riskChartPercentage={riskChartPercentage}
                            lastAssessment={lastAssessment}
                            lastAssessmentDateInDays={lastAssessmentDateInDays}
                        />
                    </div>

                    <div className="bg-black-gray text-white py-16 px-4 mt-16 md:px-16">
                        <div>
                            <img src={logoImage} alt="Symmio Logo" />
                        </div>
                        <div className="flex flex-wrap justify-between mt-4 items-center gap-8">
                            <div className={`${COMPONENT_CLASS}__header-companion`}>
                                <h1 className="text-white">{t('pages.leadCaptureResults.uncover')}</h1>
                                <p className="mb-4">
                                    {t('pages.leadCaptureResults.uncover_desc')}
                                </p>
                                <div className="flex justify-center md:justify-start">
                                    {joinNowButton(true, t('buttons.btn_getStarted'))}
                                </div>
                            </div>
                            <div className={`${COMPONENT_CLASS}__video-companion`}>
                                {leadLink && leadLink?.videoUrl
                                    ?
                                    <div className={`${COMPONENT_CLASS}__video-wrapper`} dangerouslySetInnerHTML={{ __html: AppContentUtil.getVideoEmbedCode(leadLink?.videoUrl) }}></div>
                                    :
                                    <img src={videoReplacementImg} alt="Symmio Mobile App" />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="py-4 px-4 md:px-16 bg-black-gray-5 flex flex-col items-center text-center">
                        <div className="font-medium">{t('assessmentResults.trustedBy')}</div>
                        <div className={`${COMPONENT_CLASS}__fms-clients`}>
                            <div>
                                <img className="max-h-12" src={nflImg} alt="NFL" />
                            </div>
                            <div>
                                <img className="max-h-8" src={premierLeagueImg} alt="Premier League" />
                            </div>
                            <div>
                                <img className="max-h-5" src={equinoxImg} alt="Equinox" />
                            </div>
                            <div>
                                <img className="max-h-4" src={cardinalGlassImg} alt="Cardinal Glass Industries" />
                            </div>
                            <div>
                                <img className="max-h-8" src={mlbImg} alt="Major League Baseball" />
                            </div>
                            <div>
                                <img className="max-h-12" src={usArmyImg} alt="U.S. Army" />
                            </div>
                        </div>
                    </div>
                    <div className="py-16 px-4 md:px-16">
                        <h2>{t('assessmentResults.startYourJourney')}</h2>
                        <p className="text-center py-6">
                            {t('assessmentResults.startYourJourney_desc')}
                        </p>
                        <div className="flex flex-wrap justify-center md:justify-between gap-6">
                            <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                <div style={{ backgroundColor: "#ADDFFD" }}>
                                    <span>
                                        <MovementIcon style={{ backgroundColor: "#ADDFFD" }} />
                                    </span>
                                </div>
                                <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.movementHealth') }}></span>
                            </div>

                            <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                <div style={{ backgroundColor: "#8BE3CE" }}>
                                    <span>
                                        <BehavioralHealthIcon style={{ backgroundColor: "#8BE3CE" }} />
                                    </span>
                                </div>
                                <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.behavioralHealth') }}></span>
                            </div>

                            <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                <div style={{ backgroundColor: "#FFA998" }}>
                                    <span>
                                        <InjuryHistoryIcon style={{ backgroundColor: "#FFA998" }} />
                                    </span>
                                </div>
                                <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.injuryHistory') }}></span>
                            </div>

                            <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                <div style={{ backgroundColor: "#ADDFFD" }}>
                                    <span>
                                        <BreathingIcon style={{ backgroundColor: "#ADDFFD" }} />
                                    </span>
                                </div>
                                <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.breathingQuality') }}></span>
                            </div>

                            <div className={`${COMPONENT_CLASS}__focus-area-vertical`} >
                                <div style={{ backgroundColor: "#FFA998" }}>
                                    <span>
                                        <PhysicalActivityIcon style={{ backgroundColor: "#FFA998" }} />
                                    </span>
                                </div>
                                <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.physicalActivity') }}></span>
                            </div>

                            <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                <div style={{ backgroundColor: "#FFC79C" }}>
                                    <span>
                                        <BodyCompIcon style={{ backgroundColor: "#FFC79C" }} />
                                    </span>
                                </div>
                                <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.bodyComposition') }}></span>
                            </div>

                            <div className={`${COMPONENT_CLASS}__focus-area-vertical`}>
                                <div style={{ backgroundColor: "#CEBAF8" }}>
                                    <span>
                                        <SleepIcon style={{ backgroundColor: "#CEBAF8" }} />
                                    </span>
                                </div>
                                <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.sleepWellness') }}></span>
                            </div>

                            <div className={`${COMPONENT_CLASS}__focus-area-vertical`} >
                                <div style={{ backgroundColor: "#8BE3CE" }}>
                                    <span>
                                        <NutritionIcon style={{ backgroundColor: "#8BE3CE" }} />
                                    </span>
                                </div>
                                <span dangerouslySetInnerHTML={{ __html: t('assessmentResults.focus.nutritionalAwareness') }}></span>
                            </div>
                        </div>
                        <div className="py-16">
                            <h2>{t('assessmentResults.howItWorks')}</h2>
                            <div className="flex flex-wrap gap-6 mt-6 justify-center">
                                <div className={`${COMPONENT_CLASS}__step`}>
                                    <div className="flex justify-between items-center">
                                        <div className={`${COMPONENT_CLASS}__card-number`}>1</div>
                                        <div className={`${COMPONENT_CLASS}__badge`}>
                                            <CheckmarkIcon className="w-3 h-auto" />
                                            {t('assessmentResults.done')}
                                        </div>
                                    </div>
                                    <div>
                                        <h3 className="mt-4 mb-2">{t('pages.leadCaptureResults.assess')}</h3>
                                        <p>
                                            {t('pages.leadCaptureResults.assess_desc')}
                                        </p>
                                    </div>
                                </div>

                                <div className={`${COMPONENT_CLASS}__step`}>
                                    <div className="flex justify-between items-center">
                                        <div className={`${COMPONENT_CLASS}__card-number`}>2</div>
                                    </div>
                                    <div>
                                        <h3 className="mt-4 mb-2">{t('pages.leadCaptureResults.analyze')}</h3>
                                        <p>
                                            {t('pages.leadCaptureResults.analyze_desc')}
                                        </p>
                                    </div>
                                </div>

                                <div className={`${COMPONENT_CLASS}__step`}>
                                    <div className="flex justify-between items-center">
                                        <div className={`${COMPONENT_CLASS}__card-number`}>3</div>
                                    </div>
                                    <div>
                                        <h3 className="mt-4 mb-2">{t('pages.leadCaptureResults.trackProgress')}</h3>
                                        <p>
                                            {t('pages.leadCaptureResults.trackProgress_desc')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center items-center">
                            {joinNowButton(false, t('buttons.btn_joinNow'))}
                        </div>
                        <div className="pt-16">
                            <h2>{t('pages.leadCaptureResults.allInOne')}</h2>
                            <p className="mt-2 mb-6 text-center">
                                {t('pages.leadCaptureResults.allInOne_desc')}
                            </p>

                            <div>
                                {cards.map((card, index) => {
                                    let flexDirection = "flex-row-reverse";

                                    if (index % 2 === 0) {
                                        flexDirection = "flex-row";
                                    }

                                    return (
                                        <div className={`flex flex-wrap items-center gap-6 mb-16 ${flexDirection}`}>
                                            <div className={`${COMPONENT_CLASS}__card-image-wrapper`}>
                                                <img className={`${COMPONENT_CLASS}__card-image__380`} src={card.img} alt="" />
                                            </div>
                                            <div className="flex flex-col gap-6 flex-1">
                                                <div className="flex gap-5">
                                                    <div className="flex flex-col">
                                                        <h3 className="mb-1">{card.title}</h3>
                                                        <p>
                                                            {card.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="py-10 px-4 md:px-16 flex flex-col gap-4 items-center justify-center text-center" style={{ backgroundColor: "#ADDFFD" }}>
                        <h2>{t('pages.leadCaptureResults.beFirst')}</h2>
                        <p>{t('pages.leadCaptureResults.beFirst_desc')}</p>
                        <div>{joinNowButton(false, t('buttons.btn_getStarted'))}</div>
                    </div>
                    <div className="py-16 px-4 md:px-16">
                        <h2>{t('assessmentResults.reviews.title')}</h2>
                        <p className="mt-2 mb-6 text-center">{t('assessmentResults.reviews.text')}</p>

                        <div className="flex flex-wrap gap-6 justify-center">
                            {reviews.map((review) =>
                                <div className={`${COMPONENT_CLASS}__review`}>
                                    <div>
                                        <StarIcon sx={{ color: "#43B5F8" }} />
                                        <StarIcon sx={{ color: "#43B5F8" }} />
                                        <StarIcon sx={{ color: "#43B5F8" }} />
                                        <StarIcon sx={{ color: "#43B5F8" }} />
                                        <StarIcon sx={{ color: "#43B5F8" }} />
                                    </div>
                                    <p>
                                        "{review.details}"
                                    </p>
                                    <div className="flex items-center gap-2">
                                        <div>
                                            <img className="max-w-10" src={review.img} alt="" />
                                        </div>
                                        <p style={{ fontSize: "12px", lineHeight: "18px" }}>
                                            <b>{review.author}</b>
                                            <br />
                                            {review.profession}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="py-16 px-4 md:px-16">
                        <h2 className="mb-6">{t('assessmentResults.faq.title')}</h2>
                        <FaqAccordion faqs={faqs} setFaqs={setFaqs} />
                    </div>
                    <div className="flex flex-wrap py-16 px-4 md:px-16">
                        <div className="flex flex-col justify-center flex-1">
                            <h2 style={{ textAlign: "left" }} className="mb-4">{t('pages.leadCaptureResults.calendar_title')}</h2>
                            <p>{t('pages.leadCaptureResults.calendar_desc')}</p>
                        </div>
                        <div className="max-w-full" style={{ width: "580px" }}>
                            <iframe
                                title="hubspot"
                                style={{
                                    width: '100%',
                                    height: '630px'
                                }}
                                src="https://meetings.hubspot.com/kyle-barrow/symmio-sales-discovery?embed=true"
                            />
                        </div>
                    </div>
                    <div className="mb-20 py-16 px-4 md:px-16 bg-blue-light flex flex-col gap-10 justify-center items-center">
                        <h2>{t('assessmentResults.joinSymmio')}</h2>
                        <div>
                            {joinNowButton(false, t('buttons.btn_subscribeNow'))}
                        </div>
                    </div>
                </div >
            </BaseCardLayout >
        </div >
    );
};

export default LeadCaptureResultsPage;