/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import UserService from '../../utilities/services/user-service';
import { Modal } from '../modal/modal';
import { User } from "../../models/interfaces/user";
import { UserStatus } from '../../models/enumerations/user-status';
import { Organization } from '../../models/interfaces/organization';
import { ReactComponent as UserCircleIcon } from "../../assets/icons/solid/icon_default-profile.svg";
import PortalProgressBar from "../portal-progress-bar/portal-progress-bar";
import { AddOnRemoval } from '../../models/interfaces/add-on-removal';
import { AddOns } from '../../models/enumerations/add-ons';
import AddOnRemovalsService from '../../utilities/services/add-on-removal-service';
import { OrganizationSubscription } from '../../models/interfaces/organization-subscription';
import moment from 'moment';
import OrganizationSubscriptionService from '../../utilities/services/organization-subscription-service';
import { Loader } from '../loader/loader';
import { CheckboxTypeInput } from '../forms/checkbox-type-input';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "manage-users-modal";

interface UserDeactivationModalProps {
    openUserDeactivation: boolean;
    setOpenUserDeactivation: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
    organization: Organization;
    hideClose?: boolean;
    deactivationAmount?: number;
    removeAtFutureDate: boolean;
    organizationSubscription?: OrganizationSubscription;
    handleAddOnRemove?: Function;
    addOnAmountToBeRemoved?: number;
    setAddOnRemovedModalOpen?: Function;
    setRemovedAddOnInfo?: Function;
    onSubmit?: Function;
}

const UserDeactivationModal: React.FC<UserDeactivationModalProps> = (props) => {
    const { t } = useTranslation();

    const {
        openUserDeactivation,
        setOpenUserDeactivation,
        isLoading,
        organization,
        hideClose,
        deactivationAmount,
        removeAtFutureDate,
        organizationSubscription,
        handleAddOnRemove,
        addOnAmountToBeRemoved,
        setAddOnRemovedModalOpen,
        setRemovedAddOnInfo,
        onSubmit,
    } = props;

    const {
        register,
    } = useForm<any>();

    const { state } = useAuthState();
    const [loading, setLoading] = useState(false);
    const [searchedUsers, setSearchedUsers] = useState<User[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [userIdsToDeactivate, setUserIdsToDeactivate] = useState<string[]>([]);
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
    const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
    // Modal is closed - reset everything
    useEffect(() => {
        setUserIdsToDeactivate([]);
    }, [openUserDeactivation]);
    useEffect(() => {
        const fetchUsers = async () => {
            if (state.user?.organizationId) {
                const allUsers = await UserService.getAllByOrganizationId(state.user.organizationId);
                let filteredUsers = allUsers.filter((user) => user.id !== organization.accountHolderId && user.status !== UserStatus.Disabled);
                // Check if addOnRemovals exists & contains users
                if (organizationSubscription) {
                    await AddOnRemovalsService.getBy([{
                        field: "organizationSubscriptionId",
                        operator: "==",
                        value: organizationSubscription.id,
                    },
                    {
                        field: "removalCompleted",
                        operator: "==",
                        value: false,
                    },
                    {
                        field: "addOn",
                        operator: "==",
                        value: AddOns.User,
                    }]).then((item: any) => {
                        if (item[0] && item[0].userIds && item[0].userIds.length > 0) {
                            filteredUsers = filteredUsers.filter((val) => !item[0].userIds!.includes(val.id!))
                        }
                    })

                }

                setUsers(filteredUsers);
                setSearchedUsers(filteredUsers);
            }
        }

        fetchUsers();
    }, [state.user?.organizationId]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (!value) {
            setSearchedUsers(users);
        }
        else {
            const foundUsers = users.filter((user) =>
                user.email?.includes(value) ||
                user.firstName?.includes(value) ||
                user.lastName?.includes(value));

            setSearchedUsers(foundUsers);
        }
    }

    const handleRowClick = (userIdFromRow: string | undefined) => {
        if (userIdFromRow) {
            if (userIdsToDeactivate.includes(userIdFromRow)) {
                setUserIdsToDeactivate(userIdsToDeactivate.filter(userId => userId !== userIdFromRow));
            }
            else {
                setUserIdsToDeactivate([...userIdsToDeactivate, userIdFromRow]);
            }
        }
    };
    const handleRemoveSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        if (!checked && searchedUsers.length === userIdsToDeactivate.length) {
            setIsAllChecked(false);
        }
    }
    const selectAllUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        setIsAllChecked(checked);
        if (checked) {
            let userIds: string[] = [];

            searchedUsers.forEach((user) => {
                if (user.id && user.id !== organization.accountHolderId && user.status !== UserStatus.Disabled) {
                    userIds.push(user.id);
                }
            });

            setUserIdsToDeactivate(userIds);
        }
        else {
            setUserIdsToDeactivate([]);
        }
    }

    const handleFutureUserDeactivation = async () => {
        setLoading(true);
        if (!organizationSubscription || !handleAddOnRemove || !addOnAmountToBeRemoved) {
            return;
        }
        // TODO: I have OrgSub prop set to ? just to satisfy the error in reactivate-add-on-content. WE should make it mandatory @Egor
        const sub = await stripe.subscriptions.retrieve(organizationSubscription.stripeAddOnSubscription);

        // Check if AddOnRemoval exists
        // If removalCompleted false, then this is an active addOnRemoval
        const existingAddOnRemoval = await AddOnRemovalsService.getBy([{
            field: "organizationSubscriptionId",
            operator: "==",
            value: organizationSubscription.id,
        },
        {
            field: "removalCompleted",
            operator: "==",
            value: false,
        },
        {
            field: "addOn",
            operator: "==",
            value: AddOns.User,
        }])

        if (existingAddOnRemoval && existingAddOnRemoval.length > 0) {
            // update the existing addOnRemoval
            existingAddOnRemoval[0].userIds = [...existingAddOnRemoval[0].userIds!, ...userIdsToDeactivate]

            await AddOnRemovalsService.save(existingAddOnRemoval[0], state.user!);

        }
        else {

            // Create the future removal object
            const addOnRemoval = {
                addOn: AddOns.User,
                removalCompleted: false,
                userIds: userIdsToDeactivate,
                billingCycleEnd: moment(new Date(sub.current_period_end * 1000)).toISOString(),
                organizationSubscriptionId: organizationSubscription.id
            } as AddOnRemoval

            // Save the future removal object
            await AddOnRemovalsService.add(addOnRemoval, state.user!);
        }

        // Check if addOnsToBeRemoved exists and has "Users"
        if (organizationSubscription.addOnsToBeRemoved && organizationSubscription.addOnsToBeRemoved.find((item: any) => item.name === AddOns.User)) {
            const oldValue = organizationSubscription.addOnsToBeRemoved.find((item: any) => item.name === AddOns.User)
            const index = organizationSubscription.addOnsToBeRemoved.findIndex((item: any) => item.name === AddOns.User)
            const newValue = {
                name: oldValue?.name,
                amountToBeRemoved: Number(addOnAmountToBeRemoved) + Number(oldValue?.amountToBeRemoved!),
                amountManaged: userIdsToDeactivate.length + oldValue?.amountManaged!,
            }
            // setRemovedAddOnInfo prop must be set if you are handling future deactivation
            if (setRemovedAddOnInfo) {
                setRemovedAddOnInfo({
                    name: oldValue?.name,
                    amountToBeRemoved: Number(addOnAmountToBeRemoved),
                    amountManaged: userIdsToDeactivate.length,
                    billingCycleEnd: moment(new Date(sub.current_period_end * 1000)).format('MMMM D Y')
                })
            }
            organizationSubscription.addOnsToBeRemoved[index] = newValue;

            await OrganizationSubscriptionService.save(organizationSubscription);

        }
        else {
            // Create the add-ons-to-be-removed object 
            const addOnsToBeRemoved = [
                {
                    name: AddOns.User,
                    amountToBeRemoved: addOnAmountToBeRemoved,
                    amountManaged: userIdsToDeactivate.length,
                }
            ];
            // setRemovedAddOnInfo prop must be set if you are handling future deactivation
            if (setRemovedAddOnInfo) {
                setRemovedAddOnInfo({
                    name: AddOns.User,
                    amountToBeRemoved: Number(addOnAmountToBeRemoved),
                    amountManaged: userIdsToDeactivate.length,
                    billingCycleEnd: moment(new Date(sub.current_period_end * 1000)).format('MMMM D Y')
                })
            }
            organizationSubscription.addOnsToBeRemoved = addOnsToBeRemoved;
            await OrganizationSubscriptionService.save(organizationSubscription);
        }

        handleAddOnRemove().then(() => {
            setOpenUserDeactivation(false);
            // This must be passed as a prop if you are using future deactivation
            if (setAddOnRemovedModalOpen) {
                setAddOnRemovedModalOpen(true);
            }
            setLoading(false);
        });
    }

    const handleUserDeactivation = async () => {
        await UserService.deactivateUsersById(userIdsToDeactivate, state.user);
        onSubmit && onSubmit();
    }

    useEffect(() => {
        if (organization?.userCount &&
            organization?.userLimit &&
            userIdsToDeactivate.length >= (deactivationAmount ? deactivationAmount : (organization.userCount - organization.userLimit))) {
            setIsSubmitButtonDisabled(false);
        } else {
            setIsSubmitButtonDisabled(true);
        }
    }, [organization?.userCount, organization?.userLimit, userIdsToDeactivate]);

    return (
        <Modal
            isOpen={openUserDeactivation}
            isLoading={isLoading}
            onClose={hideClose ? undefined : setOpenUserDeactivation}
            title={t('manageAccount.title')}
            defaultModalActions={true}
            onSubmit={async () => {
                if (removeAtFutureDate) {
                    handleFutureUserDeactivation();
                }
                else {
                    handleUserDeactivation();
                }
            }}
            onCancel={!hideClose ? () => setOpenUserDeactivation(false) : undefined}
            submitButtonText={t('buttons.btn_deactivateUser')}
            submitDisabled={isSubmitButtonDisabled}>
            <Loader isVisible={loading || isLoading} />
            <div className={COMPONENT_CLASS}>
                {organization.userLimit && organization.userCount &&
                    <div className="my-6">
                        <PortalProgressBar total={organization.userLimit} currentProgress={deactivationAmount ? (deactivationAmount + organization.userLimit) : organization.userCount} headerText={t('licenseUserRemoval.usedUserProgress', { userCount: deactivationAmount ? (deactivationAmount + organization.userLimit) : organization.userCount, userLimit: organization.userLimit })} />
                    </div>
                }

                {organization.userCount && organization.userLimit &&
                    <h2>{t('licenseUserRemoval.usersToRemove', { count: (deactivationAmount ? deactivationAmount : (organization.userCount - organization.userLimit)) })}</h2>
                }

                <input className='my-6' type='search' onChange={handleSearch} placeholder={t('misc.search')} />

                <div className={`${COMPONENT_CLASS}__user-list`}>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th><span>{t('licenseUserRemoval.common.name')}</span></th>
                                <th><span>{t('licenseUserRemoval.common.group')}</span></th>
                                <th><span>{t('licenseUserRemoval.common.status')}</span></th>
                                <th>
                                    <label>
                                        <div>
                                            <CheckboxTypeInput
                                                id="selectAllUsers"
                                                registerHook={register}
                                                registerOptions={{
                                                    onChange: selectAllUsers,
                                                }}
                                                checked={isAllChecked} />
                                        </div>
                                        {t('licenseUserRemoval.deactivate_caps')}
                                    </label>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchedUsers.map((user) => {
                                return (
                                    <tr key={user.id} className={user.status === UserStatus.Disabled ? "deactivated" : ""}
                                        onClick={() => {
                                            if (user.id !== organization.accountHolderId && user.status !== UserStatus.Disabled) {
                                                handleRowClick(user.id)
                                            }
                                        }}>
                                        <td className={`${COMPONENT_CLASS}__user-photo`}>
                                            {user.profileImage && (
                                                <img
                                                    src={user.profileImage}
                                                    alt={`${user.firstName} ${user.lastName}`}
                                                />
                                            )}
                                            {!user.profileImage && (
                                                <UserCircleIcon aria-hidden="true" />
                                            )}
                                        </td>

                                        <td>
                                            {user.firstName != null && user.lastName != null && (
                                                <span>
                                                    <span className="font-bold">{`${user.firstName} ${user.lastName}`}</span>
                                                    <br />
                                                    <span className="email">{user.email}</span>
                                                </span>
                                            )}

                                            {(user.firstName == null || user.lastName == null) && (
                                                <span>{user.email}</span>
                                            )}
                                        </td>

                                        <td>{user.groupName || t('licenseUserRemoval.common.noGroup')}</td>

                                        <td>{UserStatus[user.status]}</td>

                                        <td>
                                            {user.status === UserStatus.Disabled
                                                ?
                                                (<div>
                                                    <CheckboxTypeInput
                                                        id={user.id ?? ""}
                                                        registerHook={register}
                                                        registerOptions={{
                                                            disabled: true,
                                                        }}
                                                    />
                                                </div>)
                                                :
                                                (user.id !== organization.accountHolderId
                                                    ?
                                                    <div className='flex flex-row items-center gap-2'>
                                                        <div>
                                                            <CheckboxTypeInput
                                                                id={user.id ?? ""}
                                                                registerHook={register}
                                                                registerOptions={{
                                                                    onChange: handleRemoveSelectAll
                                                                }}
                                                                checked={user.id && userIdsToDeactivate.length > 0 ? userIdsToDeactivate.includes(user.id) : false} />

                                                        </div>
                                                        {t('licenseUserRemoval.deactivate_low')}
                                                    </div>
                                                    :
                                                    <div><strong>{t('licenseUserRemoval.common.accountHolder')}</strong></div>
                                                )

                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                <div className='mt-16 mb-6 text-right' dangerouslySetInnerHTML={{ __html: t('licenseUserRemoval.usersToBeRemoved', { count: userIdsToDeactivate.length }) }}></div>
            </div>
        </Modal >
    );
};

export default UserDeactivationModal;